import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import { get } from 'lodash';
import React, { useState } from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { ConsentForm } from '../../Forms/Consent';
import RemovalModal from '../../Forms/RemovalModal';

const formName = 'ConsentForm';

export const Consents = (props) => {
  const [objectToRemove, setObjectToRemove] = useState({});

  const activeConsent = (logs) => logs.filter((l) => l.is_accepted && !l.withdrawal_date).sort((a, b) => a.id < b.id);
  const latestInactiveConsent = (logs) => logs.filter((l) => l.withdrawal_date).sort((a, b) => a.id < b.id);

  const consents = props.person && props.person.consents
    ? props.person.consents.map((consent) => ({
      id: consent.id,
      consent_template_id: consent.consent_template ? consent.consent_template.id : 'brak',
      consent_source_id: consent.consent_source ? consent.consent_source.id : 'brak',
      administrator_id: consent.administrator ? consent.administrator.id : 'brak',
      representator_id: consent.representator ? consent.representator.id : 'brak',
      data_inspector_id: consent.data_inspector ? consent.data_inspector.id : 'brak',
      created_date: 'brak',
      consent_logs: consent.consent_logs || [],
      date:
                        consent.consent_logs && activeConsent(consent.consent_logs).length
                          ? activeConsent(consent.consent_logs)[0].accepted_date
                          : 'brak',
      withdrawal_date:
                        consent.consent_logs && latestInactiveConsent(consent.consent_logs).length
                          ? latestInactiveConsent(consent.consent_logs)[0].withdrawal_date
                          : '-',
      ip: 'brak',
      is_active: consent.consent_logs[0].is_accepted
    }))
    : [{ consent_logs: [{}] }];

  return (
    <Card header='Ochrona danych' onAddClick={() => props.create(formName)}>
      <div className="collapse show" id="ochronaDanych">
        {consents.length === 0 && (
          <div className="row">
            <div className="col-sm-12 p-2">Brak danych.</div>
          </div>
        )}
        {consents.map((c, k) => (
          <div className="row">
            <div className="col-sm-12">
              <RowEditable isObjectToRemove itemId={c.id} onCancel={c.is_active ? () => setObjectToRemove(c) : null}>
                <div className="col-sm-8 p-2 br">
                  <a
                    role="button"
                    style={{ color: 'black' }}
                    className="collapsed"
                    data-toggle="collapse"
                    href={`#ochrona${k}`}
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i className="fa fa-sort-down mr-2" />
                    {props.templates && props.templates.length > 0
                      ? get(props.templates.find((i) => i.value === c.consent_template_id), 'name')
                      : 'brak'}
                  </a>
                </div>
                <div className="col-sm-2 p-2 br">
                  <a
                    role="button"
                    style={{ color: 'black' }}
                    className="collapsed"
                    data-toggle="collapse"
                    href={`#ochrona${k}`}
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    ID:
                    {' '}
                    {c.id}
                  </a>
                </div>
                <div className="col-sm-2 p-2">
                  <a
                    role="button"
                    style={{ color: 'black' }}
                    className="collapsed"
                    data-toggle="collapse"
                    href={`#ochrona${k}`}
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <BooleanCheck field={c.is_active} />

                  </a>
                </div>
              </RowEditable>
            </div>

            <div className="col-sm-12">
              <div className="collapse" id={`ochrona${k}`}>
                <div className="row bb">
                  <div className="col-sm-1 p-2 br grey">L.p.</div>
                  <div className="col-sm-2 p-2 br grey">Data czynności</div>
                  <div className="col-sm-1 p-2 br grey">Operator</div>
                  <div className="col-sm-1 p-2 br grey">Czynność</div>
                  <div className="col-sm-1 p-2 br grey">Źródło</div>
                  <div className="col-sm-1 p-2 br grey">Administrator</div>
                  <div className="col-sm-1 p-2 br grey">Przedstawiciel</div>
                  <div className="col-sm-1 p-2 br grey">Inspektor danych</div>
                  <div className="col-sm-1 p-2 br grey">IP</div>
                  <div className="col-sm-2 p-2 grey">Data utworzenia rekordu</div>
                </div>
                {c.consent_logs.map((log, lp) => (
                  <>
                    <div className="row bb">
                      <div className="col-sm-1 p-2 br ">{lp + 1}</div>
                      <div className="col-sm-2 p-2 br ">{log.is_accepted ? log.accepted_date : log.withdrawal_date}</div>
                      <div className="col-sm-1 p-2 br ">{log.user_creator ? log.user_creator.full_name : '-'}</div>
                      <div className="col-sm-1 p-2 br ">
                        {log.is_accepted ? (
                          <span style={{ color: 'green' }}>Zgoda</span>
                        ) : (
                          <span style={{ color: 'red' }}>Brak zgody</span>
                        )}
                      </div>
                      <div className="col-sm-1 p-2 br ">
                        {props.sources && props.sources.length > 0
                          ? get(props.sources.find((i) => i.value === c.consent_source_id), 'name')
                          : 'brak'}
                      </div>
                      <div className="col-sm-1 p-2 br ">
                        {props.administrators && props.administrators.length > 0
                          ? get(props.administrators.find((i) => i.id === c.administrator_id), 'name')
                          : 'brak'}
                      </div>
                      <div className="col-sm-1 p-2 br ">
                        {props.representators && props.representators.length > 0
                          ? get(props.representators.find((i) => i.id === c.representator_id), 'name')
                          : 'brak'}
                      </div>
                      <div className="col-sm-1 p-2 br ">
                        {props.inspectors && props.inspectors.length > 0
                          ? get(props.inspectors.find((i) => i.id === c.data_inspector_id), 'name')
                          : 'brak'}
                      </div>
                      <div className="col-sm-1 p-2 br ">{log.ip_address}</div>
                      <div className="col-sm-2 p-2 ">{log.created_date}</div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Ochrona danych</span>
          </div>
        )}
        footer="Aby zapisać zgodę wciśnij ZATWIERDŹ."
        onClickAway={() => props.toggleForm(formName)}
      >
        <ConsentForm
          sources={props.sources}
          templates={props.templates}
          administrators={props.administrators}
          representators={props.representators}
          inspectors={props.inspectors}
          form={props.forms[formName]}
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(formName)}
          formName={formName}
          person={props.person}
          withButtons
          submitForm={() => props.saveForm(formName, true)}
          onCancelButtonClick={() => props.toggleForm(formName)}
          toggleModal={props.toggleModal}
          isVisible={props.formVisible}
          buttonOnClick={() => props.clear(formName, 'date')}
        />
      </BaseModal>

      <RemovalModal
        submitButton="Wycofaj"
        header="Ochrona danych"
        footer="Aby wycofać dany rekord wciśnij Wycofaj."
        visible={objectToRemove && Object.keys(objectToRemove).length > 0}
        toggle={() => setObjectToRemove({})}
        remove={() => {
          props.onConsentCancel(objectToRemove);
          setObjectToRemove({});
        }}
      >
        {objectToRemove && (
          <div className="container-fluid">
            <div className="row bb bt">
              <div className="col-sm-10 p-2 br">
                {props.templates && props.templates.length > 0
                  ? get(props.templates.find((i) => i.value === objectToRemove.consent_template_id), 'name')
                  : 'brak'}
              </div>
              <div className="col-sm-2 p-2">
                ID:
                {objectToRemove.id}
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br">Data zgody</div>
              <div className="col-sm-2 p-2 br">Źródło zgody</div>
              <div className="col-sm-2 p-2 br">Zgoda wycofana</div>
              <div className="col-sm-2 p-2 br">Administrator danych</div>
              <div className="col-sm-2 p-2 br">Przedstawiciel administratora</div>
              <div className="col-sm-2 p-2">Inspektor danych</div>
            </div>
            <div className="row bb data-row">
              <div className="col-sm-2 p-2 br">{objectToRemove.consent_logs ? objectToRemove.consent_logs[0].accepted_date : '-'}</div>
              <div className="col-sm-2 p-2 br">
                {props.sources && props.sources.length > 0
                  ? get(props.sources.find((i) => i.value === objectToRemove.consent_source_id), 'name')
                  : 'brak'}
              </div>
              <div className="col-sm-2 p-2 br">
                {objectToRemove.consent_logs ? objectToRemove.consent_logs[0].withdrawal_date : '-'}
              </div>
              <div className="col-sm-2 p-2 br">
                {props.administrators && props.administrators.length > 0
                  ? get(props.administrators.find((i) => i.id === objectToRemove.administrator_id), 'name')
                  : 'brak'}
              </div>
              <div className="col-sm-2 p-2 br">
                {props.representators && props.representators.length > 0
                  ? get(props.representators.find((i) => i.id === objectToRemove.representator_id), 'name')
                  : 'brak'}
              </div>
              <div className="col-sm-2 p-2">
                {props.inspectors && props.inspectors.length > 0
                  ? get(props.inspectors.find((i) => i.id === objectToRemove.data_inspector_id), 'name')
                  : 'brak'}
              </div>
            </div>
            <div className="row bb data-row">
              <div className="col-sm-4 p-2 br" style={{ color: '#DFC06A' }}>
                IP komputera
              </div>
              <div className="col-sm-8 p-2">{objectToRemove.consent_logs ? objectToRemove.consent_logs[0].ip_address : '-'}</div>
            </div>
            <div className="row bb data-row">
              <div className="col-sm-4 p-2 br" style={{ color: '#DFC06A' }}>
                Data utworzenia rekordu
              </div>
              <div className="col-sm-8 p-2">{objectToRemove.consent_logs ? objectToRemove.consent_logs[0].created_date : '-'}</div>
            </div>
          </div>
        )}
      </RemovalModal>
    </Card>
  );
};
