import AutocompleteCore from '@material-ui/lab/Autocomplete';
import FormActions from 'actions/forms';
import clsx from 'clsx';
import { get } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function Autocomplete({ formName, errorName, name, options, onChange, ...props }) {
  const dispatch = useDispatch();
  const Forms = useSelector(state => state.Forms);

  const errName = errorName ?? name;
  const error = get(Forms.errors[formName], errName, '');
  const value = get(Forms.forms[formName], name, null);

  const handleOnChange = name => (event, newValue) => {
    dispatch(FormActions.storeInput(formName, name, newValue));
    dispatch(FormActions.clearErrors(formName));
    onChange && onChange(newValue);
  };

  return (
    <div>
      <AutocompleteCore
        value={String(value)}
        onInputChange={handleOnChange(name)}
        options={options}
        renderInput={(params) => (
          <div ref={params.InputProps.ref} className='form-group mb-0'>
            <input {...params.inputProps} type="number" className={clsx('form-control', error && 'border-error')} />
          </div>
        )}
        {...props}
      />
      <div className="form-control-feedback small min-h-15" style={{ color: 'red' }}>
        {error}
      </div>
    </div>
  );
}
