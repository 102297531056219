import { ColNameBig } from 'components/Forms/Col';
import Select from 'components/Forms/Select';
import React from 'react';

import { Buttons, Form } from '../../../components/Forms';

export const RolesForm = (props) => {
  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="t-row justify-center my-10">
            <ColNameBig>Rola biznesowa</ColNameBig>
            <Select formName={props.formName} options={props.roles} name="role" errorName='business_company_ids' />
          </div>
          {props.withButtons ? (
            <Buttons
              formName={props.formName}
              onCancel={props.onCancel}
              onSubmit={props.submitForm}
              onSubmitAndLeave={props.onSubmitAndLeave}
            />
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
