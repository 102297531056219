import Card from 'components/Layout/Card';
import React from 'react';

import { PersonForm } from '../../Forms/Main';

export const Metrics = (props) => {
  const formName = 'MainCreatorForm';

  return (
    <Card mode='creator' header='+ Metryka osoby' onSaveClick={() => props.submitForm(formName)}>
      <div/>
      <PersonForm
        personId={props.person ? props.person.id : 'brak'}
        preloadedForm
        visibleOnMount
        clear={props.clear}
        visible={props.formVisible(formName)}
        formName={formName}
        selectCompany={props.selectCompany}
      />
    </Card>
  );
};
