import React from 'react';
import { withRouter } from 'react-router-dom';

import { OldCard } from '../../../components/Layout';
import { formatNip, parsePhone } from '../../../utilities/helpers';
import RemovalModal from '../Forms/RemovalModal';
import Tooltip from 'components/Layout/Tooltip';

class AgreementSideBarView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { objectToRemove: {} };
  }

    setObjectToRemove = (object) => {
      if (this.state.objectToRemove.id) {
        this.setState({ objectToRemove: {} });
      } else {
        this.setState({ objectToRemove: object });
      }
    };

    render() {
      const L = this.props.claim || { slug: 'test' };
      const client = L.client || {};
      const debtor = L.debtor || {};

      return (
        <OldCard className="mt-3 pt-3 pb-3">
          <div className="row">
            <div className="col-sm-12">
              <h3 className="font-weight-bold text-themecolor">
                {L.identifier}
                <span className="float-right text-themecolor" style={{ cursor: 'pointer' }}>
                  <i className="fas fa-sign-in-alt mr-2" onClick={() => this.props.history.push(`/wierzytelnosci/${L.slug}`)} />

                  <i className="fa fa-trash" style={{ color: 'red' }} onClick={() => this.setObjectToRemove(L)} />
                </span>
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mt-3 mb-3">
              <button type="button" className="btn btn-sm btn-success mr-3">
                <i className="fa fa-envelope mr-2" />
                Wiadomość
              </button>
              <button type="button" className="btn btn-sm btn-success mr-3">
                <i className="fa fa-link mr-2" />
                Powiąż
              </button>
              <button type="button" className="btn btn-sm btn-success">
                <i className="fa fa-envelope mr-2" />
                Zadanie
              </button>
            </div>
          </div>

          <div className="rows-bordered mt-1">
            <div className="row p-1">
              <div className="col-6">
                <h4 className="text-themecolor mt-2 mb-2">Wierzytelność</h4>
              </div>

            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Numer wierzytelności</div>
              <div className="col-sm-6 text-right">{L.identifier}</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Data wykupu</div>
              <div className="col-sm-6 text-right">{L.purchase_date}</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Status wierzytelności</div>
              <div className="col-sm-6 text-right">
                <span className="badge badge-warning pt-1 pb-1 pl-2 pr-2">
                  {this.props.common?.claimData?.claim_statuses?.[L.status]}
                </span>
              </div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Numer umowy</div>
              <div className="col-sm-6 text-right"><Tooltip label={L.agreement?.identifier} /></div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Status umowy</div>
              <div className="col-sm-6 text-right">
                <span className="badge badge-warning pt-1 pb-1 pl-2 pr-2">
                  {this.props.common?.agreementData?.agreement_statuses?.[L.agreement?.status]}
                </span>
              </div>
            </div>

            <div className="row p-1">
              <div className="col-12">
                <h4 className="text-themecolor mt-2 mb-2">Faktorant</h4>
              </div>
            </div>
            <div className="row p-1">
              <div className="col-sm-4 text-left grey">Nazwa firmy</div>
              <div className="col-sm-8 text-right">{client.name}</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">NIP</div>
              <div className="col-sm-6 text-right">{formatNip(client.nip)}</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-4 text-left grey">Forma prawna</div>
              <div className="col-sm-8 text-right">{client.legal_form ? client.legal_form.name : '-'}</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Telefon</div>
              <div className="col-sm-6 text-right">{parsePhone(client.active_phone)}</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Email</div>
              <div className="col-sm-6 text-right">{client.active_email}</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-4 text-left grey">Adres siedziby</div>
              <div className="col-sm-8 text-right">
                {client.active_address ? `${client.active_address?.street ?? ''} ${client.active_address?.building_no ?? ''}/${client.active_address?.local_no ?? ''},  ${client.active_address?.zipcode ?? ''} ${client.active_address?.city ?? ''}` : 'brak'}
              </div>
            </div>

            <div className="row p-1">
              <div className="col-12">
                <h4 className="text-themecolor mt-2 mb-2">Dłużnik</h4>
              </div>
            </div>
            <div className="row p-1">
              <div className="col-sm-4 text-left grey">Nazwa firmy</div>
              <div className="col-sm-8 text-right">{debtor.name}</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">NIP</div>
              <div className="col-sm-6 text-right">{formatNip(debtor.nip)}</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-4 text-left grey">Forma prawna</div>
              <div className="col-sm-8 text-right">{debtor.legal_form ? debtor.legal_form.name : '-'}</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Telefon</div>
              <div className="col-sm-6 text-right">{parsePhone(debtor.active_phone)}</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Email</div>
              <div className="col-sm-6 text-right">{debtor.active_email}</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-4 text-left grey">Adres siedziby</div>
              <div className="col-sm-8 text-right">
                {debtor.active_address ? `${debtor.active_addres?.street ?? ''} ${debtor.active_addres?.building_no ?? ''}/${debtor.active_addres?.local_no ?? ''},  ${debtor.active_addres?.zipcode ?? ''} ${debtor.active_addres?.city ?? ''}` : 'brak'}
              </div>
            </div>
          </div>

          <RemovalModal
            visible={Object.keys(this.state.objectToRemove).length > 0}
            toggle={() => this.setObjectToRemove({})}
            remove={() => {
              this.props.remove(this.state.objectToRemove.id);
              this.setObjectToRemove({});
            }}
          >
            {this.state.objectToRemove && (<span>Czy na pewno chcesz usunąć tę wierzytelność?</span>)}
          </RemovalModal>
        </OldCard>
      );
    }
}

export default withRouter(AgreementSideBarView);
