import Decision from 'components/Format/Decision';
import DecisionString from 'components/Format/DecisionString';
import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import Tooltip from 'components/Layout/Tooltip';
import { isEditableProposal, STATUS_PROPOSAL } from 'containers/Proposals/Details/Index';
import { t } from 'lang/pl';
import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { formatValueToEurope } from 'utilities/helpers';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parseMoney } from '../../../../utilities/helpers';
import CreditDecision from '../../Forms/CreditDecision';
import DebtorsRiskForm from '../../Forms/DebtorsRisk';
import SecuresForm from '../../Forms/Secures';

const NoData = () => (
  <div className="row">
    <div className="col-12 p-2">Brak danych.</div>
  </div>
);

export default props => {
  const { rating_decisions } = useSelector(state => state.Common.proposalData);

  const formName = 'DebtorsRiskEditForm';

  const proposal = props.proposal ?? {};
  const version = props.version ?? {};
  const risks = props.proposal?.risks ?? [];
  const sublimits = d => [d.sublimits.business_intelligence, d.sublimits.msp_credit];

  const isEditable = proposal.status < STATUS_PROPOSAL.OCZEKUJE_NA_DECYZJE;

  const handleEditDebtor = debtor => {
    const object = { ...debtor };
    Object.keys(object).forEach(k => {
      if (object[k] && typeof object[k] === 'object' && k !== 'sublimits' && k !== 'securedocuments') {
        object[`${k}_id`] = object[k].id;
        // delete editable[k];
      }
    });
    if (object.sublimits.business_intelligence.selected) {
      object.has_business_intelligence_limit = 1;
      object.has_msp_credit_limit = 0;
    }
    if (object.sublimits.msp_credit.selected) {
      object.has_msp_credit_limit = 1;
      object.has_business_intelligence_limit = 0;
    }
    props.edit(object, formName);
  };

  return risks.map(item => (
    <Card
      key={item.id}
      header={`Ryzyko: ${item.debtor.name} ${formatValueToEurope(item.debtor.requested_sublimit, '', { withoutCents: true })}`}
      isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
      onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
      onEditClick={() => handleEditDebtor(item)}
      options={{ isCollapsed: false }}
    >
      <div className="collapse show" id="financialData">
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">Wnioskowany sublimit na dłużnika</div>
          <div className="col-sm-3 p-2 br">{formatValueToEurope(props.version?.limit_amount ?? item.debtor?.requested_sublimit)}</div>
          <div className="col-sm-3 p-2 br grey">Kategoria dłużnika</div>
          <div className="col-sm-3 p-2 ">{item.debtor?.category?.name ?? 'brak'}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">Dostępny sublimit / wykorzystany</div>
          <div className="col-sm-3 p-2 br">
            {parseMoney(item.available_sublimit)} / {parseMoney(item.used_sublimit)}
          </div>
          <div className="col-sm-3 p-2 br grey">Wiek firmy dłużnika</div>
          <div className="col-sm-3 p-2 ">{item.debtor?.company_age}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">Maksymalna ekspozycja D</div>
          <div className="col-sm-3 p-2 br">{formatValueToEurope(item.max_exposure_d)}</div>
          <div className="col-sm-3 p-2 br grey">Współpraca z dłużnikiem</div>
          <div className="col-sm-3 p-2 ">{item.debtor?.coop_in_months}</div>
        </div>

        <div className="row bb">
          <div className="col-12 px-2 pt-24 pb-10 text-themecolor">Dane finansowe</div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">Przychody dłużnika za ost. 12 mcy</div>
          <div className="col-sm-3 p-2 br">{parseMoney(item.last_revenues, 'nie określono')}</div>
          <div className="col-sm-3 p-2 br grey">Limit wywiadowni</div>
          <div className="col-sm-3 p-2 ">{formatValueToEurope(item.information_agency_limit)}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">Średnio miesięczny przychód</div>
          <div className="col-sm-3 p-2 br">{parseMoney(item.avg_monthly_revenue)}</div>
          <div className="col-sm-3 p-2 br grey">Wywiadownia / Rating / PMI</div>
          <div className="col-sm-3 p-2 ">
            {item.information_agency?.name ?? 'brak'} / {item.information_agency_class?.name ?? 'brak'} / {item.information_agency_pmi ?? 'brak'}
          </div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">{item.rating_type?.name ?? '-'}</div>
          <div className="col-sm-3 p-2 br">{item.rating_class?.name ?? '-'}</div>
          <div className="col-sm-3 p-2 br grey">Data raportu wywiadowni</div>
          <div className="col-sm-3 p-2 ">{item.information_agency_report_date ?? '-'}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">JST / Spółka skarbu państwa</div>
          <div className="col-sm-3 p-2 br">{item.is_jst_or_corporate_treasury ? 'tak' : 'nie'}</div>
          <div className="col-sm-3 p-2 br grey">Kryterium MŚP</div>
          <div className="col-sm-3 p-2 ">{item.msp_criteria?.name ?? '-'}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">Podmiot w upadłości lub likwidacji</div>
          <div className="col-sm-3 p-2 br">{item.is_bankruptcy_or_liquidation ? 'tak' : 'nie'}</div>
          <div className="col-sm-3 p-2 br grey">Ocena agencji ratingowej</div>
          <div className="col-sm-1 p-2 br ">{item.rating_agency_score?.name ?? '-'}</div>
          <div className="col-sm-2 p-2 ">{item.rating_agency?.name ?? '-'}</div>
        </div>

        <div className="row bb">
          <div className="col-12 px-2 pt-24 pb-10 text-themecolor">Sublimity na dłużnika</div>
        </div>
        <div className="row bb">
          <div className="col-sm-1 max-w-100 p-2 br grey">Wybrany</div>
          <div className="col-sm-3 p-2 grey br">Sposób wyznaczania sublimitu</div>
          <div className="col-sm-2 p-2 grey br grey">Planowany sublimit</div>
          <div className="col-sm-2 p-2 grey br">Maksymalny wg procedury</div>
          <div className="col-sm-2 p-2 br grey">Maksymalna dopuszczalna ekspozycja</div>
          <div className="col-sm-2 p-2 grey">Kwalifikacja auto</div>
        </div>
        {sublimits(item).map(s => (
          <div className="row bb">
            <div className="col-sm-1 max-w-100 p-2 br "><BooleanCheck field={s.selected} /></div>
            <div className="col-sm-3 p-2  br">{s.label ?? '-'}</div>
            <div className="col-sm-2 p-2  br ">{version.limit_amount ?? '-'}</div>
            <div className="col-sm-2 p-2  br">{item.max_per_procedure ?? '-'}</div>
            <div className="col-sm-2 p-2 br ">{item.avg_monthly_revenue ?? '-'}</div>
            <div className="col-sm-2 p-2 ">
              <Decision isPositive={s.auto_qualification} />
            </div>
          </div>
        ))}

        <div className="row bb">
          <div className="col-12 px-2 pt-24 pb-10 text-themecolor">Wizja</div>
        </div>
        <div className="row bb">
          <div className="col-sm-2 p-2 br grey">Data wizji</div>
          <div className="col-sm-2 p-2 grey br">Pracownik</div>
          <div className="col-sm-2 p-2 grey br grey">Ilość punktow</div>
          <div className="col-sm-2 p-2 grey br">Rekomendacja</div>
          <div className="col-sm-2 p-2 br grey">Data rekomendacji</div>
          <div className="col-sm-2 p-2 grey">Adres wizji</div>
        </div>
        <div className="row bb">
          <div className="col-sm-2 p-2 br ">{item.data_vision ?? '-'}</div>
          <div className="col-sm-2 p-2  br">{item.employee_vision?.full_name ?? '-'}</div>
          <div className="col-sm-2 p-2  br ">{item.score_vision ?? '-'}</div>
          <div className="col-sm-2 p-2  br">
            <BooleanCheck field={item.is_recommanded_vision} />
          </div>
          <div className="col-sm-2 p-2 br ">{item.data_vision ?? '-'}</div>
          <div className="col-sm-2 p-2 ">
            {item.address_vision
              ? `${item.address_vision.city}, ${item.address_vision.street} ${item.address_vision.building_no}${
                item.address_vision.local_no ? `/${item.address_vision.local_no}` : ''
              }`
              : '-'}
          </div>
        </div>
        <div className="row bb">
          <div className="col-12 p-2 grey">Uwagi</div>
        </div>
        <div className="row bb">
          <div className="col-12 p-2">{item.comment_vision ?? '-'}</div>
        </div>
        <div className="row bb">
          <div className="col-12 px-2 pt-24 pb-10 text-themecolor">Punktacja</div>
        </div>
        <div className="row bb">
          <div className="col-sm-2 p-2 br grey">Zakres</div>
          <div className="col-sm-2 p-2 grey br">Punkty scoring</div>
          <div className="col-sm-2 p-2 grey br grey">Waga</div>
          <div className="col-sm-2 p-2 grey br">Wynik</div>
          <div className="col-sm-4 p-2 grey " />
        </div>
        <div className="row bb">
          <div className="col-sm-2 p-2 br ">Automatyczna punktacja</div>
          <div className="col-sm-2 p-2 br"><Tooltip label='x' tip='Brak implementacji' /></div>
          <div className="col-sm-2 p-2 br"><Tooltip label='x' tip='Brak implementacji' /></div>
          <div className="col-sm-2 p-2 br"><Tooltip label='x' tip='Brak implementacji' /></div>
          <div className="col-sm-4 p-2 " />
        </div>
        <div className="row bb">
          <div className="col-sm-2 p-2 br ">Wizja lokalna</div>
          <div className="col-sm-2 p-2 br"><Tooltip label='x' tip='Brak implementacji' /></div>
          <div className="col-sm-2 p-2 br"><Tooltip label='x' tip='Brak implementacji' /></div>
          <div className="col-sm-2 p-2 br"><Tooltip label='x' tip='Brak implementacji' /></div>
          <div className="col-sm-4 p-2 " />
        </div>
        <div className="row bb">
          <div className="col-sm-2 p-2 br ">Razem</div>
          <div className="col-sm-2 p-2 br"><Tooltip label='x' tip='Brak implementacji' /></div>
          <div className="col-sm-2 p-2 br"><Tooltip label='x' tip='Brak implementacji' /></div>
          <div className="col-sm-2 p-2 br"><Tooltip label='x' tip='Brak implementacji' /></div>
          <div className="col-sm-4 p-2 " />
        </div>
        <div className="row bb">
          <div className="col-12 px-2 pt-24 pb-10 text-themecolor">
            Zabezpieczenia dłużnika
            <span className="float-right" style={{ cursor: 'pointer' }}>
              <i className="fa fa-plus ml-2"
                onClick={() => isEditable ? props.edit({ issuer: { ...item.debtor, id: item.debtor.company_id } }, 'Proposal_Secures') :
                  toast.error('Nie można wykonać tej operacji, ponieważ nie pozwala na to status.')}
              />
            </span>
          </div>
        </div>
        <div className="row bb">
          <div className="flex-20 p-2 br grey">Rodzaj zabezpieczenia</div>
          <div className="flex-15 max-w-200 p-2 br grey">Kwota zabezpieczenia</div>
          <div className="flex-15 max-w-200 p-2 br grey">Wartość zabezpieczenia</div>
          <div className="flex-12 max-w-150 p-2 br grey">Forma dokumentu</div>
          <div className="flex-30 p-2 br grey">Wystawiający</div>
          <div className="flex-12 max-w-150 p-2 br grey">Klauzula</div>
          <div className="flex-10 max-w-150 p-2 br grey">Wzór faktora</div>
          <div className="flex-9 max-w-120 p-2 grey">Moje punkty / max</div>
        </div>
        {item.securedocuments.length ? (
          item.securedocuments.map(i => (
            <RowEditable
              key={i.identifier}
              onEdit={() => {
                props.edit(
                  {
                    debtor_id: item.debtor.company_id,
                    ...i,
                    dts_type_id: i.dts_type.id,
                    dts_form_id: i.dts_form.id
                  },
                  'Proposal_Secures'
                );
              }}
              onRemove={() => {
                props.remove('secure_documents', i.id);
              }}>
              <div className="flex-20 p-2 br ">{i.dts_type?.name ?? '-'}</div>
              <div className="flex-15 max-w-200 p-2 br ">{parseMoney(i.current_debt)}</div>
              <div className="flex-15 max-w-200 p-2 br ">{parseMoney(i.collateral_value)}</div>
              <div className="flex-12 max-w-150 p-2 br ">{i.dts_form ? i.dts_form.name : '-'}</div>
              <div className="flex-30 p-2 br ">{i.issuer ? i.issuer.name : '-'}</div>
              <div className="flex-12 max-w-150 p-2 br ">{i.clauses ? i.clauses.map(c => c.name).join(', ') : '-'}</div>
              <div className="flex-10 max-w-150 p-2 br ">{i.is_factor_pattern ? 'tak' : 'nie'}</div>
              <div className="flex-9 max-w-120 p-2 ">
                {i.my_score}/{i.dts_type ? i.dts_type.score : '0'}
              </div>
            </RowEditable>
          ))
        ) : (
          <NoData />
        )}

        <div className="row bb">
          <div className="col-12 px-2 pt-24 pb-10" style={{ color: '#1DB393' }}>
            Decyzja kredytowa dłużnika
            <span className="float-right" style={{ cursor: 'pointer' }}>
              <i
                className="fa fa-pencil-alt ml-2"
                onClick={() => {
                  isEditable ? props.edit(
                    {
                      // ...d,
                      information_agency_class_id: item.information_agency_class?.id,
                      information_agency_id: item.information_agency?.id,
                      last_revenues: item.last_revenues,
                      msp_criteria_id: item.msp_criteria?.id,
                      rating_agency_id: item.rating_agency?.id,
                      rating_agency_score_id: item.rating_agency_score?.id,
                      rating_class_id: item.rating_class?.id,
                      rating_type_id: item.rating_type?.id,
                      credit_score_decition: item.credit_score_decition,
                      credit_score_comment: item.credit_score_comment,
                      riskId: item.id
                    },
                    `CreditDecision.${item.debtor.id}`
                  ) : toast.error(t.error.toast.statusWarning);
                }}
              />
            </span>
          </div>
        </div>
        <div className="row bb">
          <div className="flex-10 min-w-100 max-w-150 p-2 br grey">Data oceny</div>
          <div className="flex-15 min-w-150 p-2 br grey">Oceniający</div>
          <div className="flex-10 min-w-130 max-w-200 p-2 br grey">Kwalifikacja auto</div>
          <div className="flex-10 min-w-130 max-w-200 p-2 br grey">Decyzja kredytowa</div>
          <div className="flex-10 min-w-180 max-w-200 p-2 br grey">Data wniosku</div>
          <div className="flex-30 min-w-150 p-2 grey">Klient</div>
        </div>
        <div className="row bb">
          <div className="flex-10 min-w-100 max-w-150 p-2 br ">{item.credit_score_date ?? '-'}</div>
          <div className="flex-15 min-w-150 p-2 br ">{item.credit_score_user ? item.credit_score_user.full_name : '-'}</div>
          <div className="flex-10 min-w-130 max-w-200 p-2 br ">
            <Decision isPositive={item.credit_score_qualification} />
          </div>
          <div className="flex-10 min-w-130 max-w-200 p-2 br ">
            <DecisionString value={rating_decisions[item.credit_score_decition]} />
          </div>
          <div className="flex-10 min-w-180 max-w-200 p-2 br ">{proposal.created_date}</div>
          <div className="flex-30 min-w-150 p-2 ">{proposal.client.name}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-12 p-2 grey">Komentarz do decyzji</div>
        </div>
        <div className="row bb">
          <div className="col-sm-12 p-2 ">{item.credit_score_comment}</div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={
          <div className="d-flex align-items-center">
            <span>Edycja ryzyka dłużnika</span>
          </div>
        }
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}>
        <DebtorsRiskForm
          proposal={props.proposal}
          noSecures
          forms={props.forms}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible
          formVisible={props.formVisible}
          toggleForm={props.toggleForm}
          fetch={props.fetch}
          formName={formName}
          storeInput={props.storeInput}
          formData={props.formData}
          offerData={props.offerData}
          proposalData={props.proposalData}
          common={props.common}
          addField={props.addField}
          removeField={props.removeField}
          withButtons
          submitForm={() => props.saveForm(formName)}
          onCancelButtonClick={() => props.toggleForm(formName)}
        />
      </BaseModal>
      <BaseModal
        visible={props.formVisible('Proposal_Secures')}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" /> <span>Zabezpieczenia dłużnika</span>
          </div>
        }
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm('Proposal_Secures')}>
        <SecuresForm
          issuers={props.issuers}
          fetchIssuers={props.fetchIssuers}
          forms={props.forms}
          proposalData={props.proposalData}
          common={props.common}
          offerData={props.offerData}
          proposal={props.proposal}
          version={props.version}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible('Proposal_Secures')}
          formVisible={props.formVisible}
          onCancelButtonClick={() => props.toggleForm('Proposal_Secures')}
          toggleForm={props.toggleForm}
          formName="Proposal_Secures"
          withButtons
          submitForm={() => props.saveForm('Proposal_Secures')}
        />
      </BaseModal>
      <BaseModal
        visible={props.formVisible(`CreditDecision.${item.debtor.id}`)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" /> <span>Decyzja kredytowa dłużnika</span>
          </div>
        }
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(`CreditDecision.${item.debtor.id}`)}>
        <CreditDecision
          user={props.user}
          forms={props.forms}
          proposalData={props.proposalData}
          proposal={props.proposal}
          version={props.version}
          debtor={item}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(`CreditDecision.${item.debtor.id}`)}
          formVisible={props.formVisible}
          onCancelButtonClick={() => props.toggleForm(`CreditDecision.${item.debtor.id}`)}
          toggleForm={props.toggleForm}
          formName={`CreditDecision.${item.debtor.id}`}
          withButtons
          submitForm={() => props.saveForm(`CreditDecision.${item.debtor.id}`)}
        />
      </BaseModal>
    </Card>
  ));
};
