import Card from 'components/Layout/Card';
import React from 'react';

import { CompanyForm } from '../../Forms/Main';

export const Metrics = (props) => {
  const formName = 'MainCreatorForm';

  return (
    <Card mode='creator' header='+ Dane podstawowe' onSaveClick={() => props.submitForm(formName)}>
      <div/>
      <CompanyForm
        companyData={props.companyData}
        companyId={props.company ? props.company.id : 'brak'}
        populateForm={() => props.populateForm(formName)}
        preloadedForm
        visibleOnMount
        clear={props.clear}
        visible={props.formVisible(formName)}
        formName={formName}
        storeInput={props.storeInput}
      />
    </Card>
  );
};
