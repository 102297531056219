import Autocomplete from 'components/Forms/Autocomplete';
import React from 'react';

import { Form, Input, Select } from '../../../components/Forms';

export const days_after_payment_date = [
  { value: '15', name: '15' },
  { value: '30', name: '30' },
  { value: '45', name: '45' },
  { value: '60', name: '60' },
  { value: '120', name: '120' },
  { value: '365', name: '365' }
];

export const AdditionalRatesForm = (props) => {
  return (
    <Form
      initialForm={{ calculationId: props.calculationId }}
      visibleOnMount={props.visibleOnMount}
      className="form-horizontal"
      formName={props.formName}
    >
      {props.visible ? (
        <>
          <div className="row mt-3">
            <div className="col-sm-3 font-weight-bold grey">Nazwa stawki</div>
            <div className="col-sm-3 font-weight-bold grey">Typ stawki</div>
            <div className="col-sm-3 font-weight-bold grey">Przeterminowanie ponad (dni)</div>
            <div className="col-sm-3 font-weight-bold grey">Wysokość stawki</div>
          </div>
          <div className="row">
            <div className="col-sm-3 font-weight-bold grey">
              <Input formName={props.formName} type="text" name="name" />
            </div>
            <div className="col-sm-3 grey">
              <Select disabled={props.selectDisabled || false} formName={props.formName} options={props.bidTypes} name="bid_type" />
            </div>
            <div className="col-sm-3 font-weight-bold grey">
              <Autocomplete
                freeSolo
                formName={props.formName}
                name='days_after_payment_date'
                options={days_after_payment_date.map(item => item.name)}
              />
            </div>
            <div className="col-sm-3 font-weight-bold grey">
              <Input formName={props.formName} type="text" name="rate_amount" />
            </div>
          </div>

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancel}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}
                >
                  Anuluj
                </button>
                <button type="button" onClick={() => props.saveForm()} className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
