import { OptionsActions } from 'ducks/options';
import { uniq } from 'lodash';
import { toast } from 'react-toastify';

import AgreementService from '../services/agreement';
import ClaimService from '../services/claim';
import ProposalService from '../services/proposal';
import CommonActions from './common';
import FormActions from './forms';

const fetchDetails = (id, loader = true) => dispatch => {
  dispatch(OptionsActions.toggleLoader(loader));

  if (loader) {
    dispatch({ type: 'CONTENT_LOADING' });
    dispatch({ type: 'CONTENT_PREVIEW_LOADING' });
  }

  ClaimService.fetchDetails(id).then(async response => {
    const payload = response.data;
    payload.agreement = await ClaimService.fetchAgreement(payload.agreement?.id);
    payload.charges = await ClaimService.fetchBucketsForDetails(payload.id);

    dispatch({ type: loader ? 'CLAIM_DETAILS_FETCHED' : 'CLAIM_DETAILS_UPDATED', payload });
    dispatch({ type: 'CONTENT_LOADED' });

    if (loader) {
      dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
      dispatch(OptionsActions.toggleLoader(false));
    }
  });
};

const saveForm = (form, id, hide = false, callback, compensation = false) => (dispatch, getState) => {
  const data = {
    ...getState().Forms.forms[form]
  };

  if (form === 'CommitmentForm') {
    data.claim = getState().Claim.claim;
  }

  dispatch(FormActions.clearErrors(form));

  ClaimService.saveForm(form, data, id, compensation)
    .then(r => {
      if (callback) {
        return callback(r);
      }

      dispatch(CommonActions.toggleModal());

      dispatch(fetchDetails(id));

      document.getElementsByTagName('html')[0].style = '';

      const formsToBeLeftFilled = ['ClaimBidsForm', 'ClaimParamsForm', 'ClaimAdvanceForm'];
      let clearForm = true;

      formsToBeLeftFilled.forEach(e => {
        if (form.indexOf(e) !== -1) {
          clearForm = false;
        }
      });

      if (form === 'CommitmentForm') {
        dispatch(FormActions.toggleForm('CommitmentTransferForm'));
        clearForm = false;
      }
      if (form === 'RecalculateAdvance') {
        dispatch(FormActions.toggleForm('ClaimAdvanceForm'));
        clearForm = false;
      }

      if (clearForm) {
        dispatch(FormActions.clearForm(form));
      }

      if (hide) {
        dispatch(FormActions.toggleForm(form));
      }

      toast.success('Zaktualizowano dane.');
      return true;
    }).catch((errors) => dispatch(FormActions.handleErrors(form, errors?.response?.data)));
};

const fetchResults = filters => dispatch => {
  const f = filters || CommonActions.defaultFilters;
  ClaimService.fetch(f).then(response => {
    dispatch({ type: 'CLAIMS_FETCHED', payload: response ? response.data : [] });
    if (response?.data?.results?.length) {
      dispatch({ type: 'CLAIM_FOR_PREVIEW_FETCHED', payload: response.data?.results?.[0] });
    }
    dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
  });
};

export default {
  archive: (claimId, type, secureId) => dispatch => {
    ClaimService.archive(claimId, type, secureId)
      .then(() => {
        toast.success('Zarchiwizowano.');
        dispatch(fetchDetails(claimId));
      })
      .catch(e => {
        const msg = e?.response?.data;
        toast.error(msg.status);
      });
  },
  handleFile: (formName, type, attachmentId, action, del = false, update = false) => (dispatch, getState) => {
    const { Claim, Forms } = getState();
    const claimId = Claim.claim.id;
    const obj = Forms.forms?.[formName];

    ClaimService.handleFile(claimId, type, obj.id, attachmentId, action, del)
      .then(r => {
        const newAttachments = obj.attachments.map(a => {
          if (a.id === attachmentId) {
            return r.data;
          } else {
            return a;
          }
        });

        dispatch(FormActions.storeInput(formName, 'attachments', newAttachments));
        toast.success(`Dokument został ${del ? 'usunięty' : action === 'generate' ? 'wygenerowany' : 'zarejestrowany'}.`);

        if (update) {
          dispatch(fetchDetails(claimId));
        }
      })
      .catch(e => {
        toast.error(e?.response?.data?.status ?? 'Generowanie nie powiodło się.');
      });
  },
  preview: claim => dispatch => dispatch({ type: 'CLAIM_FOR_PREVIEW_FETCHED', payload: claim }),
  generateFinalPayment: (claimId, formName) => dispatch => {
    ClaimService.generateFinalPayment(claimId)
      .then(() => {
        dispatch(fetchDetails(claimId));
        dispatch(FormActions.toggleForm(formName));
        toast.success('Wygenerowano płatność końcową.');
      })
      .catch(e => {
        toast.error(e.response?.data?.status);
      });
  },
  compensate: (claimId, paymentId, chargeId, c) => dispatch => {
    ClaimService.compensate(paymentId, chargeId, c).then(() => {
      dispatch(fetchDetails(claimId));
      toast.success('Skompensowano należność.');
    }).catch(e => {
      toast.error(e?.response?.data?.status ?? 'Operacja nie powiodła się.');
    });
  },
  removeChargeFromPayment: (claimId, paymentId, chargeId) => dispatch => {
    ClaimService.removeChargeFromPayment(paymentId, chargeId).then(() => {
      dispatch(fetchDetails(claimId));
      toast.success('Usunięto powiązanie należności z przelewem przychodzącym.');
    }).catch(e => {
      toast.error(e?.response?.data?.income ?? 'Operacja nie powiodła się.');

    });
  },
  linkChargeWithPayment: (claimId, paymentId, chargeId, isDocument) => dispatch => {
    ClaimService.linkChargeWithPayment(paymentId, chargeId, isDocument)
      .then(() => {
        dispatch(fetchDetails(claimId));
        toast.success('Powiązano należność z przelewem przychodzącym.');
      })
      .catch(e => e);
  },
  fetchBuckets: params => (dispatch, getState) => {
    const claimId = getState().Claim?.claim?.id;

    ClaimService.fetchBuckets(claimId, params).then(r => {
      dispatch({ type: 'CLAIM_BUCKETS_FETCHED', payload: r.data });
    });
  },
  fetchDocuments: f => (dispatch, getState) => {
    const params = { ...getState().Forms?.forms?.[f] } ?? {};

    if (params.client_id && params.client_id.id) {
      params.client_id = params.client_id.id;
    }

    Object.keys(params).forEach(k => {
      if (params[k] === '' || params[k] === null) {
        delete params[k];
      }
    });

    ClaimService.fetchDocuments(params).then(r => {
      dispatch({ type: 'CLAIM_DOCUMENTS_FETCHED', payload: r.data });
    });
  },
  removeLink: (subpaymentId, paymentId, claimId) => dispatch => {
    ClaimService.removeLink(subpaymentId, paymentId).then(() => {
      dispatch(fetchDetails(claimId));
      toast.success('Powiązanie usunięte.');
    })
      .catch(e => {
        toast.error(e?.response?.data?.payment_id ?? 'Nie udało się usunąć powiązania.');
      });
  },
  fetch: fetchResults,
  // fetchDebtors: HttpClient.get(`/claims/${claimId}/${obj}/${objectId}/archive/`),
  resetDocumentsToBuy: claimId => dispatch => {
    ClaimService.resetDocumentsToBuy(claimId).then(() => {
      dispatch(fetchDetails(claimId));
      toast.success('Dokumenty do wykupu zresetowane.');
    });
  },
  fetchForForm: (resource, filters) => dispatch => {
    AgreementService.fetchForForm(resource, filters).then(response => {
      dispatch({ type: 'CLAIM_FORMDATA_FETCHED', payload: { data: response.data, resource } });
    });
  },
  fetchDetails,
  changeTab: tab => dispatch =>
    dispatch({
      type: 'CLAIM_TAB_CHANGED',
      payload: tab
    }),
  create: (formName, push, stay = false) => (dispatch, getState) => {
    const formData = getState().Forms.forms[formName];

    const data = {
      purchase_date: formData.purchase_date,
      client_id: formData.client?.id,
      agreement_id: formData.agreement?.id ?? formData.agreement?.id,
      debtor_id: formData.debtor?.id ?? formData.debtor?.id,
      document_no: formData.document_no,
      document_date: formData.document_date,
      due_date: formData.due_date,
      net_value: formData.net_value,
      net_value_currency_id: formData.net_value_currency_id,
      vat_value: formData.vat_value,
      vat_value_currency_id: formData.vat_value_currency_id,
      purchase_type: formData.purchase_type,
      purchase_currency_id: formData.purchase_currency_id,
      purchase_custom_value: formData.purchase_custom_value || 0,
      purchase_custom_value_currency_id: formData.purchase_custom_value_currency_id,
      purchase_custom_vat_value: formData.purchase_custom_vat_value || 0,
      exchange_rate: formData.exchange_rate,
      trade_supervisor_id: formData.trade_supervisor?.id,
      billing_manager_id: formData.billing_manager?.id
    };

    if(formData.purchase_custom_vat_value_currency_id) {
      data.purchase_custom_vat_value_currency_id = formData.purchase_custom_vat_value_currency_id;
    }

    ClaimService.create(data)
      .then(async response => {
        dispatch(FormActions.clearForm(formName));
        dispatch(FormActions.toggleForm(formName));

        if (stay) {
          dispatch(fetchResults());
        } else {
          dispatch(fetchDetails(response.data.id));
          push(`/wierzytelnosci/${response.data.slug}/kreator`);
        }
      })
      .catch(errors => dispatch(FormActions.handleErrors(formName, errors?.response?.data)));
  },
  saveForm,
  saveDocumentsToBuy: ({ formName, id }) => async (dispatch, getState) => {
    const formData = getState().Forms.forms[formName];

    dispatch(FormActions.clearErrors(formName));

    ClaimService.saveForm(formName, formData, id)
      .then(() => toast.success('Zaktualizowano dokument do wykupu.'))
      .catch((errors) => dispatch(FormActions.handleErrors(formName, errors?.response?.data)));
  },
  saveDebtors: (form, id, hide) => async (dispatch, getState) => {
    const debtorsInAgreement = getState().Agreement.version?.agreement_debtors ?? [];
    const newDebtors = getState().Forms.forms?.[form]?.debtors ?? [];

    if (debtorsInAgreement.length > 0) {
      Promise.all(
        debtorsInAgreement.map(d => {
          if (newDebtors.find(x => x.proposal_debtor_id === d.id)) {
            return null;
          }
          return ClaimService.remove(id, 'debtor', d.id, getState().Agreement?.version?.id);
        })
      ).then(() => dispatch(saveForm(form, id, hide)));
    } else {
      dispatch(saveForm(form, id, hide));
    }
  },
  fetchFactors: () => dispatch => {
    ClaimService.fetchForForm('factors')
      .then(res => {
        dispatch({
          type: 'CLAIM_FACTORS_FETCHED',
          payload: res.data.results
        });
      });
  },
  remove: (id, uri, objectId) => (dispatch, getState) =>
    ClaimService.remove(id, uri, objectId).then(() => {
      dispatch(CommonActions.toggleModal());
      dispatch(fetchDetails(id));

      if (uri.indexOf('attachments') !== -1) {
        const formname = 'DocumentsManageForm';
        const newAttachments = getState().Forms.forms[formname]?.attachments?.filter(a => a.id !== objectId);
        dispatch(FormActions.storeInput(formname, 'attachments', newAttachments));
      }

      document.getElementsByTagName('html')[0].style = '';
      toast.success('Usunięto dane.');
    }),
  removeTransfer: (id, claimId, compensate) => dispatch =>
    ClaimService.removeTransfer(id, compensate).then(() => {
      dispatch(CommonActions.toggleModal());
      dispatch(fetchDetails(claimId));

      document.getElementsByTagName('html')[0].style = '';
      toast.success('Usunięto przelew.');
    }).catch(errors => {
      const e = errors && errors.response ? errors?.response?.data : null;
      if (e) {
        const toasts = uniq(Object.keys(e).map(key => (typeof e[key] === 'object' ? e[key][0] : e[key])));

        toasts.forEach(t => toast.error(t));
      } else {
        toast.error('Wystąpił błąd.');
      }
    }),
  removeClaim: id => (dispatch, getState) =>
    ClaimService.removeClaim(id).then(() => {
      dispatch(CommonActions.toggleModal());
      dispatch(fetchResults());
      if (getState().Common.view === 'details') {
        window.location.href = '/wierzytelnosci';
      }

      document.getElementsByTagName('html')[0].style = '';
      toast.success('Usunięto dane.');
    }),
  simpleRemove: (id, uri, objectId) => dispatch =>
    ClaimService.remove(id, uri, objectId, null).then(() => {
      dispatch(CommonActions.toggleModal());
      dispatch(fetchDetails(id));

      document.getElementsByTagName('html')[0].style = '';
      toast.success('Usunięto dane.');
    }),
  selectVersion: payload => dispatch => dispatch({ type: 'CLAIM_VERSION_FETCHED', payload }),
  fetchGuarantors: () => (dispatch, getState) => {
    ProposalService.fetchForForm('guarantors', getState().Forms?.forms?.guarantor_filters)
      .then(res => {
        dispatch({
          type: 'CLAIM_GUARANTORS_FETCHED',
          payload: res.data
        });
      })
      .catch(() => toast.error('Wystąpił błąd.'));
  },
  fetchDebtorsDict: () => (dispatch, getState) => {
    ProposalService.fetchForForm('debtors', getState().Forms?.forms?.guarantor_filters)
      .then(res => {
        dispatch({
          type: 'CLAIM_DEBTORS_DICT_FETCHED',
          payload: res.data
        });
      })
      .catch(() => toast.error('Wystąpił błąd.'));
  },
  changeStatus: ({ id, status }) => dispatch => {
    ProposalService.changeStatus({ id, status }).then(() => {
      toast.success('Pomyślnie zmieniono status wierzytelności.');
      dispatch(fetchDetails(id));
    });
  }
};
