import BooleanCheck from 'components/BooleanCheck';
import Card from 'components/Layout/Card';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { BANK_ACCOUNTS } from 'utilities/enums';

import { Selectable } from '../../../../components/Forms';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parseBan } from '../../../../utilities/helpers';

// (0, "Rachunek Obciążeniowy (zaliczkowy)") (1, "Rachunek Uznaniowy (cesyjny)")
const AGREEMENT_ACCOUNT_TYPE = { ZALICZKOWY: 0, CESYJNY: 1 };

export default props => {
  const { agreement, version: agreementVersion } = useSelector(state => state.Agreement);
  const [isPlnAccountsActive, setPlnAccountsActive] = useState(agreement?.currency?.name === 'PLN');

  const columns = [
    { name: 'Numer rachunku', getter: 'number', sortable: 1 },
    { name: 'Waluta', getter: 'currency.name', sortable: 1 },
    { name: 'Typ rachunku', getter: 'bank_account_type.name', sortable: 1 },
    { name: 'Nazwa banku', getter: 'bank_name', sortable: 1 },
    { name: 'Data dodania', getter: 'created_date', sortable: 1 },
    { name: 'Dodał', getter: 'user_creator.full_name', sortable: 1 }
  ];

  const title = type => (
    <div className="row mt-2 align-items-center mb-2">
      <div className="col-sm-1 p-2 text-right grey" style={{ fontWeight: '600' }}>
        Faktor
      </div>
      <div className="col-sm-4 p-2 " style={{ fontWeight: '600' }}>
        {props.agreement?.factor?.name ?? '-'}
      </div>
      <div className="col-sm-1 p-2 grey" style={{ fontWeight: '600' }}>
        Rachunki
      </div>
      <div className="col-sm-2 p-2">{type === 1 ? 'Uznaniowy (cesyjny)' : 'Obciążeniowy'}</div>
      <div className="col-sm-4 p-2 text-right d-flex align-items-center justify-end">
        <span className="mr-3">Tylko PLN</span>
        <BooleanCheck field={isPlnAccountsActive} onClick={() => setPlnAccountsActive(prev => !prev)} />
      </div>
    </div>
  );

  const factorBankAccounts = props.agreement?.factor?.bank_accounts;

  const factorCessionBankAccounts = factorBankAccounts?.map(i => ({ ...i, number: parseBan(i) }))
    .filter(account => account?.bank_alias?.identifier === BANK_ACCOUNTS.TYPE.UZNANIOWY || account?.bank_alias?.identifier === BANK_ACCOUNTS.TYPE.WALUTOWY)
    .filter(account => isPlnAccountsActive ? account?.currency?.name === 'PLN' : account);

  const factorCreditBankAccounts = factorBankAccounts?.map(i => ({ ...i, number: parseBan(i) }))
    .filter(account => account?.bank_alias?.identifier === BANK_ACCOUNTS.TYPE.WALUTOWY || account?.bank_alias?.identifier === BANK_ACCOUNTS.TYPE.OBCIAZENIOWY)
    .filter(account => isPlnAccountsActive ? account?.currency?.name === 'PLN' : account);

  return (
    <Card mode='creator' header='+ Rachunki bankowe Faktora' options={{ isPosted: true }}>
      <div className="collapse show" id="financialData">
        <div className="row mt-2 align-items-center" style={{ fontWeight: '600', color: '#878F9A' }}>
          <div className="col-sm-2 p-2 text-right">Faktor</div>
          <div className="col-sm-10">
            <span className="mr-3" style={{ fontWeight: '600', color: '#4B4E55' }}>
              {props.agreement?.factor?.name ?? '-'}
            </span>
          </div>
        </div>
        <div className="row align-items-center mb-2" style={{ fontWeight: '600', color: '#878F9A' }}>
          <div className="col-sm-2 p-2 text-right">Rachunki</div>
          <div className="col-sm-10">
            <span className="mr-3" style={{ fontWeight: '300', color: '#4B4E55' }}>
              Uznaniowy (cesyjny)
            </span>

            <button type="button" className="ml-4" onClick={() => props.toggleForm('factor_bank_accounts_1')}>
              Dodaj
            </button>
          </div>
        </div>
        <div className="row bt bb">
          <div className="col-3 p-2 grey br">Numer rachunku</div>
          <div className="col-1 p-2 grey br">Waluta rachunku</div>
          <div className="col-2 p-2 grey br">Nazwa rachunku</div>
          <div className="col-2 p-2 grey br">Bank</div>
          <div className="col-1 p-2 grey br">Data</div>
          <div className="col-2 p-2 grey">Dodający</div>
        </div>
        {agreementVersion?.factor_bank_accounts?.[AGREEMENT_ACCOUNT_TYPE.CESYJNY]?.map(i => (
          <RowEditable onRemove={() => props.remove('factor_bank_accounts', i.id)}>
            <div className="col-sm-3 p-2 br">{parseBan(i)}</div>
            <div className="col-sm-1 p-2 br">{i.currency?.name}</div>
            <div className="col-sm-2 p-2 br">{i.bank_account_type?.name}</div>
            <div className="col-sm-2 p-2 br">{i.bank_name}</div>
            <div className="col-sm-1 p-2 br">{i.created_date}</div>
            <div className="col-sm-2 p-2">{i.user_creator?.full_name}</div>
          </RowEditable>
        ))}
        <div className="row align-items-center mb-2 mt-2" style={{ fontWeight: '600', color: '#878F9A' }}>
          <div className="col-sm-2 p-2 text-right">Rachunki</div>
          <div className="col-sm-10">
            <span className="mr-3" style={{ fontWeight: '300', color: '#4B4E55' }}>
              Obciążeniowy (zaliczkowy)
            </span>

            <button type="button" className="ml-4" onClick={() => props.toggleForm('factor_bank_accounts_0')}>
              Dodaj
            </button>
          </div>
        </div>
        <div className="row bt bb">
          <div className="col-3 p-2 grey br">Numer rachunku</div>

          <div className="col-1 p-2 grey br">Waluta rachunku</div>
          <div className="col-2 p-2 grey br">Nazwa rachunku</div>
          <div className="col-2 p-2 grey br">Bank</div>
          <div className="col-1 p-2 grey br">Data</div>
          <div className="col-2 p-2 grey">Dodający</div>
        </div>
        {agreementVersion?.factor_bank_accounts?.[AGREEMENT_ACCOUNT_TYPE.ZALICZKOWY]?.map(i => (
          <RowEditable onRemove={() => props.remove('factor_bank_accounts', i.id)}>
            <div className="col-sm-3 p-2 br">{parseBan(i)}</div>
            <div className="col-sm-1 p-2 br">{i.currency?.name}</div>
            <div className="col-sm-2 p-2 br">{i.bank_account_type?.name}</div>
            <div className="col-sm-2 p-2 br">{i.bank_name}</div>
            <div className="col-sm-1 p-2 br">{i.created_date}</div>
            <div className="col-sm-2 p-2">{i.user_creator?.full_name}</div>
          </RowEditable>
        ))}
      </div>

      <Selectable
        multiple
        fetch={() => {}}
        // fetch={props.fetch}
        visible={props.formVisible('factor_bank_accounts_1')}
        formName="factor_bank_accounts_1"
        field="factor_accounts"
        columns={columns}
        title={title(1)}
        options={factorCessionBankAccounts}
        onClickAway={() => props.toggleForm('factor_bank_accounts_1')}
        header="Wybierz rachunek uznaniowy faktora"
        footer="Aby zapisać wciśnij ZATWIERDŹ"
        onSelected={() => {
          props.saveForm('factor_bank_accounts_1', false);
          props.toggleForm('factor_bank_accounts_1');
          props.clearForm('factor_bank_accounts_1');
        }}
      />

      <Selectable
        multiple
        fetch={() => {}}
        // fetch={props.fetch}
        visible={props.formVisible('factor_bank_accounts_0')}
        formName="factor_bank_accounts_0"
        field="factor_accounts"
        columns={columns}
        title={title(0)}
        options={factorCreditBankAccounts}
        onClickAway={() => props.toggleForm('factor_bank_accounts_0')}
        header="Wybierz rachunek obciążeniowy faktora"
        footer="Aby zapisać wciśnij ZATWIERDŹ"
        onSelected={() => {
          props.saveForm('factor_bank_accounts_0', false);
          props.toggleForm('factor_bank_accounts_0');
          props.clearForm('factor_bank_accounts_0');
        }}
      />
    </Card>
  );
};
