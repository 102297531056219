import { BaseModal } from 'components/Layout/OldModal';
import React from 'react';
import { useSelector } from 'react-redux';

import { RowEditable } from '../../../../../components/Layout/RowEditable';
import { parseMoney } from '../../../../../utilities/helpers';
import LiabilitiesForm from '../../../Forms/Liabilities/Factoring';

export default (props) => {
  const modalFormName = 'FactoringLiabilitiesForm';
  const rows = props.version && props.version.factoring_obligations ? props.version.factoring_obligations : [];
  const { factor_companies } = useSelector(state => state.Common.proposalData);

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12 p-2 text-themecolor">
              Zobowiązania faktoringowe
              <span className="float-right" style={{ cursor: 'pointer' }}>
                <i className="fa fa-plus ml-2" onClick={() => props.toggleForm(modalFormName)} />
              </span>
            </div>
          </div>
          <div className="row bt bb">
            <div className="col-sm-3 p-2 br grey">Faktor</div>
            <div className="col-sm-3 p-2 br grey">Kwota limitu</div>
            <div className="col-sm-2 p-2 br grey">Aktualne zadłużenie</div>
            <div className="col-sm-2 p-2 br grey">Data wygaśnięcia</div>
            <div className="col-sm-2 p-2 grey">Cesja</div>
          </div>
          {rows.length ? rows.map((row) => (
            <RowEditable
              key={row.identifier}
              onEdit={() => {
                const editableRow = {
                  factor_id: row.factor ? row.factor.id : null,
                  id: row.id,
                  limit_amount: row.limit_amount,
                  current_debt: row.current_debt,
                  expiration_date: row.expiration_date,
                  transfer: row.transfer ? row.transfer.id : 0
                };
                delete editableRow.lessor;
                delete editableRow.leasing_subject;
                props.edit(editableRow, modalFormName);
              }}
              onRemove={() => {
                props.remove('factoring_obligation', row.id);
              }}
            >
              <div className="col-sm-3 p-2 br ">{row.factor ? row.factor.name : '-'}</div>
              <div className="col-sm-3 p-2 br ">{parseMoney(row.limit_amount)}</div>
              <div className="col-sm-2 p-2 br ">{parseMoney(row.current_debt)}</div>
              <div className="col-sm-2 p-2 br ">{row.expiration_date}</div>
              <div className="col-sm-2 p-2 ">{row.transfer ? row.transfer.name : '-'}</div>
            </RowEditable>
          )) : <div className="row bb"><div className="col p-2"><span>Brak danych.</span></div></div>}
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Zobowiązania faktoringowe</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}
      >
        <LiabilitiesForm
          withButtons
          factors={factor_companies}
          proposalData={props.proposalData}
          populate={() => props.populateForm(modalFormName)}
          forms={props.forms}
          currencies={props.currencies}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(modalFormName)}
          formName={modalFormName}
          submitForm={() => props.saveForm(modalFormName)}
          onCancelButtonClick={() => props.toggleForm(modalFormName)}
        />
      </BaseModal>
    </>
  );
};
