import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeInput } from 'reducers/forms';

import { Checkbox, Form, Input, Select, Selectable } from '../../../components/Forms';
import { formatNip } from '../../../utilities/helpers';
import ErrorMessage from 'components/ErrorMessage';

export default props => {
  const dispatch = useDispatch();
  const { agreement } = useSelector(state => state.Agreement);
  const { forms, errors } = useSelector(state => state.Forms);
  const form = forms[props.formName];
  const formErrors = errors[props.formName];

  const dtsForms = props.common.offerData?.dts_forms?.map(i => ({ ...i, value: i.id })) ?? [];
  const dtsTypes = props.common.offerData?.dts_types?.map(i => ({ ...i, value: i.id })) ?? [];

  const isClauseItem = form?.dts_type_id_select?.identifier === 'cesja-wierzytelnosci-f';

  useEffect(() => {
    const currentValue = get(forms[props.formName], 'dts_form_id', null);

    if(dtsForms?.length) {
      const defaultOption = dtsForms.find(option => option.name === 'oryginał (kl.c)');
      !currentValue && defaultOption?.id && dispatch(storeInput(props.formName, 'dts_form_id', defaultOption.id));
    }
  }, []);


  const columns = [{ name: 'Nazwa', getter: 'name' }, { name: 'Opis', getter: 'description' }];

  const issuerColumns = [{ name: 'Nazwa', getter: 'name' }, { name: 'NIP', getter: 'nip', mutator: formatNip }];

  const clauses =
    props.common.proposalData && props.common.proposalData.factor_secure_clauses_data ? props.common.proposalData.factor_secure_clauses_data : [];
  const f = props.forms?.[props.formName] ?? {};
  const issuers = props.issuers ?? [];

  const [max, setMax] = useState(f.dts_type?.score);

  const handleChangeSecurityTypes = id => {
    const currentItem = props.common.offerData?.dts_types.find(item => item.id === id);
    const isBlancoItem = currentItem?.name.includes('blanco');

    if(isBlancoItem) {
      const limitAmount = agreement?.limit_amount ?? props?.proposal?.limit_amount;
      const currentDebt = (limitAmount * 1.5).toFixed(2);
      dispatch(storeInput(props.formName, 'current_debt', currentDebt));
    } else {
      dispatch(storeInput(props.formName, 'current_debt', null));
    }
    setMax(props.common.offerData?.dts_types?.find(t => t.id === Number(id))?.score);
  };

  const handleSubmit = () => {
    if(!isClauseItem) {
      dispatch(storeInput(props.formName, 'clauses', []));
    }
    props.submitForm();
  };

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Rodzaj zabezpieczenia
            </label>
            <div className="col-sm-4">
              <Select
                formName={props.formName}
                options={dtsTypes}
                name="dts_type_id"
                onChange={handleChangeSecurityTypes}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Kwota zabezpieczenia
            </label>
            <div className="col-sm-4">
              <Input formName={props.formName} name="current_debt" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Forma dokumentu
            </label>
            <div className="col-sm-4">
              <Select
                formName={props.formName}
                options={dtsForms}
                name="dts_form_id"
              />
            </div>
          </div>
        </div>
      </div>
      {isClauseItem && (
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group row mb-0 align-items-center">
              <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
                Klauzula
              </label>
              <div className="col-sm-4">
                <ErrorMessage>{formErrors?.clauses}</ErrorMessage>
                {f.clauses?.map(c => c.name)?.join(', ')}
                <button
                  className="ml-1"
                  type="button"
                  onClick={() => {
                    props.toggleForm('clauses');
                  }}>
                  ...
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Wystawiający
            </label>
            <div className="col-sm-4">
              <ErrorMessage>{formErrors?.issuer_id}</ErrorMessage>
              {f.issuer?.name ?? (props.transactions ? null : props.proposal?.client?.name)}
              {props.transactions ? (
                <button
                  type="button"
                  className="ml-1"
                  onClick={() => {
                    props.toggleForm('issuer');
                    props.fetchIssuers('IssuerFilters');
                  }}>
                  ...
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Wzór faktora
            </label>
            <div className="col-sm-4">
              <Checkbox formName={props.formName} name="is_factor_pattern" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Moje punkty
            </label>
            <div className="col-sm-4">
              <Input formName={props.formName} name="my_score" number />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Max
            </label>
            <div className="col-sm-4">{max}</div>
          </div>
        </div>
      </div>

      {props.withButtons ? (
        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button
              type="button"
              onClick={props.onCancelButtonClick}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}>
              Anuluj
            </button>
            <button type="button" onClick={handleSubmit} className="btn btn-primary ml-2  font-weight-light">
              Zapisz
            </button>
          </div>
        </div>
      ) : null}

      <Selectable
        visible={props.formVisible('clauses')}
        formName={props.formName}
        field={'clauses'}
        onClickAway={() => props.toggleForm('clauses')}
        multiple
        columns={columns}
        options={clauses}
        header="Wybierz klauzulę"
        footer=""
      />

      <Selectable
        fetch={() => props.fetchIssuers('IssuerFilters')}
        filtersForm="IssuerFilters"
        visible={props.formVisible('issuer')}
        formName={props.formName}
        field={'issuer'}
        onClickAway={() => props.toggleForm('issuer')}
        columns={issuerColumns}
        options={issuers}
        header="Wybierz wystawiającego"
        footer=""
      />
    </Form>
  );
};
