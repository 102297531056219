import React from 'react';

export default (props) => {
  const mainClass = props.creator ? 'tab-button-creator-active' : 'tab-button-active';

  return (
    <div className="row align-items-center text-center">
      {props.steps.map((step) => (
        <div style={{ width: `${((1 / props.steps.length) * 100)}%`, padding: '1rem' }}>
          <button
            disabled={props.creator && step.id === 5}
            type="button"
            key={step.id}
            className={`btn tab-button ${props.tab === step.id ? `${mainClass}` : ''} btn-large pl-4 pr-4 pt-3 pb-3 w-100`}
            onClick={!props.creator || (props.creator && step.id !== 5) ? () => props.changeStep(step.id) : null}
          >
            {step.name}
          </button>
        </div>
      ))}
    </div>
  );
};
