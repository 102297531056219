import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isAnyEmpty } from 'utilities/helpers';

import BooleanCheck from '../../../components/BooleanCheck';
import { Checkbox, Datepicker, Form, Input, Select } from '../../../components/Forms';

export const CompanyForm = props => {
  const { company } = useSelector(state => state.Company);
  const [name, setName] = useState(isAnyEmpty(company?.krs) ? 'ceidg' : 'krs');

  useEffect(() => {
    if (props.populateForm) {
      props.populateForm();
    }
    // eslint-disable-next-line
  }, props);

  const data = props.companyData ? props.companyData : {};
  const mapForSelect = i => ({ ...i, value: i.id });

  const legalForms = data.legal_forms ? data.legal_forms.map(mapForSelect) : [];
  const representationIds = data.representations ? data.representations.map(mapForSelect) : [];
  const industryIds = data.industries ? data.industries.map(mapForSelect) : [];

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row mt-3">
            <div className="col-xs-12 col-sm-10">
              <div className="form-group row">
                <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Nazwa firmy
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="name" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-10">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Forma prawna
                </label>
                <div className="col-sm-6">
                  <Select disabled={props.selectDisabled || false} formName={props.formName} options={legalForms} name="legal_form_id" />{' '}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-10">
              <div className="form-group row">
                <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Sposób reprezentacji
                </label>
                <div className="col-sm-6">
                  <Select disabled={props.selectDisabled || false} formName={props.formName} options={representationIds} name="representation_id" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-10">
              <div className="form-group row">
                <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  NIP
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="nip" />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-xs-12 col-sm-10">
              <div className="form-group row">
                <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  REGON
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="regon" />
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ borderTop: '1px solid #DFE2EA' }}>
            <div className="col-xs-12 col-sm-12 mt-2">
              <div className="form-group row align-items-center">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Rejestr podmiotu
                </label>
                <div className="col-sm-6 d-flex align-items-center">
                  <BooleanCheck className="mr-2" field={name === 'krs'} asRadioButton onClick={() => setName('krs')} />
                  <span> KRS</span>
                  <BooleanCheck className="ml-3 mr-2" field={name === 'ceidg'} asRadioButton onClick={() => setName('ceidg')} />
                  <span> CEIDG / ID</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-xs-12 col-sm-10">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Numer rejestru
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name={name} />
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ borderTop: '1px solid #DFE2EA' }}>
            <div className="col-xs-12 col-sm-10 mt-2">
              <div className="form-group row align-items-center">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Podatnik VAT czynny
                </label>
                <div className="col-sm-6">
                  <Checkbox className="d-flex ml-4 mt-2" formName={props.formName} name="is_vat_payer" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-10">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Data sprawdzenia
                </label>
                <div className="col-sm-3">
                  <Datepicker
                    formName={props.formName}
                    name="vat_date_checked"
                    onChange={() => props.storeInput(props.formName, 'is_vat_payer', true)}
                  />
                  {/* <Input formName={props.formName} type="text" name="date_of_birth" /> */}
                </div>
              </div>
            </div>{' '}
          </div>

          <div className="row mt-2" style={{ borderTop: '1px solid #DFE2EA' }}>
            <div className="col-xs-12 col-sm-10 mt-2">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Branża
                </label>
                <div className="col-sm-6">
                  <Select number disabled={props.selectDisabled || false} formName={props.formName} options={industryIds} name="industry_id" />
                </div>
              </div>
            </div>
          </div>

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancel}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}>
                  Anuluj
                </button>
                <button type="button" onClick={props.onSubmit} className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
