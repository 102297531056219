const initialState = {
  indicators: [],
  settlements: {}
};

export default function Other(state = initialState, action) {
  switch (action.type) {
    case 'INDICATORS_FETCHED':
      return {
        ...state, indicators: action.payload
      };
    case 'SETTLEMENTS_FETCHED':
      return {
        ...state, settlements: action.payload
      };
    default:
      return state;
  }
}
