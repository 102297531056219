import OrderBy from 'components/Forms/OldOrderBy';
import ListFilters from 'components/OldListFilters';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CommonActions from '../../../actions/common';
import DueActions from '../../../actions/due';
import FormActions from '../../../actions/forms';
import BooleanCheck from '../../../components/BooleanCheck';
import { Form } from '../../../components/Forms';
import { Container, MainContainer } from '../../../components/Layout';
import { BaseModal } from 'components/Layout/OldModal';
import { OldPagination } from 'components/Layout/OldPagination';
import { RowEditable } from '../../../components/Layout/RowEditable';
import Loader from '../../../components/Loader';
import Constants from '../../../utilities/constants';
import { parseMoney, formatNip } from '../../../utilities/helpers';
import { Generate } from '../Forms/Generate';
import { GenerateNote } from '../Forms/GenerateNote';
import { Searchbar } from './Searchbar';

class TransferList extends React.Component {
  constructor(props) {
    super(props);

    this.filtersForm = 'DuesFilters';

    this.generateNote = 'GenerateNoteForm';
    this.generateForm = 'GenerateInvoiceForm';
    this.selectForExportForm = 'DuesSelectForExportForm';

    this.props.dispatch(CommonActions.changeView(Constants.Views.List));
    this.fetchResults();

    this.state = {
      mode: 1,
      dues: [],
      all: false
    };
  }

  componentDidMount() {
    this.props.dispatch(CommonActions.setContextMenu());
  }

  getFilters() {
    const currentFilters = this.props.forms.forms[this.filtersForm] || {};

    if (!currentFilters || Object.keys(currentFilters).length <= Object.keys(CommonActions.defaultFilters).length) {
      this.props.dispatch(FormActions.initialize(this.filtersForm, CommonActions.defaultFilters, {}));
    }

    return this.props.forms.forms[this.filtersForm];
  }

  changePage = page => {
    this.props.dispatch(FormActions.storeInput(this.filtersForm, 'page', page));
    this.fetchResults();
  };

  fetchResults() {
    this.props.dispatch(DueActions.fetch(this.getFilters()));
    this.setState({ dues: [] });
  }

  saveForm() {
    const { push } = this.props.history;
    this.props.dispatch(DueActions.create(this.createTransferForm, push));
  }

  remove(id) {
    this.props.dispatch(DueActions.remove(id));
  }

  toggleForm(f) {
    this.props.dispatch(FormActions.toggleForm(f));
  }

  formVisible(f) {
    return this.props.forms.options.visible.indexOf(f) !== -1;
  }

  isSelected(due) {
    return this.state.dues.find(d => d.id === due.id);
  }

  select(due) {
    const { dues } = this.state;
    this.setState({ dues: this.isSelected(due) ? dues.filter(i => i.id !== due.id) : [...dues, due] });
  }

  render() {
    const rows = this.props.due.list || [];

    return (
      <Container>
        <MainContainer className="col-sm-12">
          <Searchbar
            title="Należności do fakturowania"
            formName={this.filtersForm}
            options={[
              { value: null, name: 'Rozliczone i nierozliczone' },
              { value: 1, name: 'Tylko rozliczone' },
              { value: 0, name: 'Tylko nierozliczone' }
            ]}
            onSubmit={() => this.fetchResults()}
            records={this.props.due.count}
          />

          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item nav-link active">Należności</li>
          </ul>
          <div className="tab-content tabcontent-border" style={{ background: 'white' }}>
            {this.props.common.loading ? (
              <Loader />
            ) : (
              <>
                {' '}
                <div className="tab-pane active p-20" id="#" role="tabpanel">
                  <div className="row mb-4 mt-3 align-items-center">
                    <div className="col-sm-9">
                      <ListFilters formName={this.filtersForm} onSubmit={() => this.fetchResults()} />
                    </div>
                    <div className="col-sm-3 text-right">
                      <h4 className="text-themecolor" style={{ cursor: 'pointer' }}>
                        {/* <i className="fa fa-ellipsis-h mr-2" /> */}
                        {/* <i className="fa fa-plus" onClick={() => this.createTransfer()} /> */}
                      </h4>
                    </div>
                  </div>
                  <div className="row bt bb">
                    <div className="col-sm-12">
                      <div className="row grey " style={{ fontSize: '90%' }}>
                        <div className="col-sm-2 pl-2 pr-1 pt-2 pb-2 br ">
                          {/*<BooleanCheck className="mr-1" field={this.state.all} onClick={undefined} />*/}
                          <OrderBy
                            field="claim__identifier"
                            spanClassName="ml-0"
                            column="Numer wierzytelności"
                            formName={this.filtersForm}
                            onSubmit={() => this.fetchResults()}
                          />
                        </div>
                        <div className="col-sm-3 pl-2 pr-1 pt-2 pb-2 br">
                          <OrderBy
                            field="title"
                            spanClassName="ml-0"
                            column="Nazwa salda"
                            formName={this.filtersForm}
                            onSubmit={() => this.fetchResults()}
                          />
                        </div>

                        <div className="col-sm-1 pl-2 pr-1 pt-2 pb-2 br">
                          <OrderBy
                            field="gross_value"
                            spanClassName="ml-0"
                            column="Kwota brutto"
                            formName={this.filtersForm}
                            onSubmit={() => this.fetchResults()}
                          />
                        </div>
                        <div className="col-sm-1 pl-2 pr-1 pt-2 pb-2 br">
                          <OrderBy
                            field="left_to_pay_value"
                            spanClassName="ml-0"
                            column="Kwota rozliczona"
                            formName={this.filtersForm}
                            onSubmit={() => this.fetchResults()}
                          />
                        </div>
                        <div className="col-sm-1 pl-2 pr-1 pt-2 pb-2 br">
                          <OrderBy
                            field="currency"
                            spanClassName="ml-0"
                            column="VAT"
                            formName={this.filtersForm}
                            onSubmit={() => this.fetchResults()}
                          />
                        </div>
                        <div className="col-sm-2 pl-2 pr-1 pt-2 pb-2 br">
                          <OrderBy
                            field="client__name"
                            spanClassName="ml-0"
                            column="Kontrahent"
                            formName={this.filtersForm}
                            onSubmit={() => this.fetchResults()}
                          />
                        </div>
                        <div className="col-sm-1 pl-2 pr-1 pt-2 pb-2 br">
                          <OrderBy
                            field="client__nip"
                            spanClassName="ml-0"
                            column="NIP"
                            formName={this.filtersForm}
                            onSubmit={() => this.fetchResults()}
                          />
                        </div>
                        <div className="col-sm-1 pl-2 pr-1 pt-2 pb-2">
                          <OrderBy
                            field="is_settled"
                            spanClassName="ml-0"
                            column="Status"
                            formName={this.filtersForm}
                            onSubmit={() => this.fetchResults()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Form visibleOnMount style={{ padding: 0 }} formName={this.selectForExportForm}>
                    {rows.map(due => (
                      <RowEditable onClick={() => this.select(due)} onDoubleClick={undefined}>
                        <div className="col-sm-2 p-2 br d-flex align-items-center">
                          <BooleanCheck className="mr-2" field={this.isSelected(due)} onClick={() => this.select(due)} />
                          {due.claim?.identifier || '-'}
                        </div>
                        <div className="col-sm-3 p-2 br">{due.title || '-'}</div>
                        <div className="col-sm-1 p-2 br">{parseMoney(due?.gross_value)}</div>
                        <div className="col-sm-1 p-2 br">{parseMoney(due.paid_value)}</div>
                        <div className="col-sm-1 p-2 br">23%</div>
                        <div className="col-sm-2 p-2 br">{due.claim?.client?.name || '-'}</div>
                        <div className="col-sm-1 p-2 br">{formatNip(due.claim?.client?.nip)}</div>
                        <div className="col-sm-1 p-2">{due.is_settled ? 'Rozliczony' : 'Nierozliczony'}</div>
                      </RowEditable>
                    ))}
                  </Form>
                  <div className="row mt-4">
                    <div className="col-sm-12">
                      <button
                        className="btn-green"
                        type="button"
                        disabled={this.state.dues.length === 0}
                        onClick={() => {
                          this.setState({ mode: 3 }, () => {
                            this.toggleForm(this.generateForm);
                          });
                        }}>
                        Generuj notę
                      </button>
                      <button
                        className="ml-3 btn-green"
                        type="button"
                        disabled={this.state.dues.length === 0}
                        onClick={() => {
                          this.setState({ mode: 0 }, () => {
                            this.toggleForm(this.generateForm);
                          });
                        }}>
                        Generuj fakturę
                      </button>
                    </div>
                  </div>

                  <BaseModal
                    visible={this.formVisible(this.generateNote)}
                    header="Generowanie noty"
                    footer=""
                    onClickAway={() => this.toggleForm(this.generateNote)}>
                    <GenerateNote
                      withButtons
                      formName={this.generateNote}
                      clear={(f, v) => this.props.dispatch(FormActions.clearField(f, v))}
                      toggleForm={f => this.toggleForm(f)}
                      submitForm={() => {
                        this.props.dispatch(DueActions.generate(this.state.ids, 3));
                        this.toggleForm(this.generateNote);
                      }}
                    />
                  </BaseModal>

                  <BaseModal
                    visible={this.formVisible(this.generateForm)}
                    header={`Generowanie ${this.state.mode === 3 ? 'noty' : 'faktury'}`}
                    footer=""
                    onClickAway={() => this.toggleForm(this.generateForm)}>
                    <Generate
                      withButtons
                      mode={this.state.mode}
                      dues={this.state.dues}
                      formName={this.generateForm}
                      toggleForm={f => this.toggleForm(f)}
                      submitForm={() => {
                        this.props.dispatch(
                          DueActions.generate(this.state.dues, this.state.mode, this.props.forms.forms[this.generateForm].issue_date, () =>
                            this.toggleForm(this.generateForm)
                          )
                        );
                      }}
                    />
                  </BaseModal>
                </div>
              </>
            )}
          </div>

          <OldPagination
            onList
            currentPage={this.props.due.currentPage}
            changePage={this.changePage}
            links={this.props.due.links}
            count={this.props.due.count}
          />
        </MainContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  forms: state.Forms,
  common: state.Common,
  due: state.Due
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(TransferList)
);
