import AgreementActions from 'actions/agreement';
import DecisionString from 'components/Format/DecisionString';
import { BaseModal } from 'components/Layout/OldModal';
import Tooltip from 'components/Layout/Tooltip';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import BooleanCheck from '../../../../components/BooleanCheck';
import { Datepicker, Select, Textarea } from '../../../../components/Forms';
import { OldCard } from '../../../../components/Layout';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parsePhone } from '../../../../utilities/helpers';
import AnnexesForm from '../../Forms/Annexes';
import { TextLink } from 'containers/Companies/Details/Components/Notes';
import { NoteForm } from 'containers/Companies/Forms/Note';

export default props => {
  const dispatch = useDispatch();

  const [noteId, setNoteId] = useState('');
  const [type, setType] = useState('');
  const formName = 'AnnexesForm';
  const notesFormName = 'AnnexesNotesForm';
  const launchForm = 'AnnexesLaunchForm';
  const creditDecisionForm = 'AnnexesCreditDecisionForm';

  const header = c => (
    <a
      role="button"
      style={{ fontSize: '24px' }}
      className="collapsed "
      data-toggle="collapse"
      href="#warunkiDodatkowe"
      aria-expanded="false"
      aria-controls="collapseExample">
      Aneks: {c.identifier}
      <span className="ml-5" style={c.status ? { color: '#1DB393' } : {}}>
        {c.launched ? 'Uruchomiony' : props.common.agreementData?.complaints_statuses?.[c.status]?.replace(/.$/, 'y')}
      </span>
    </a>
  );

  const customCreateSection = c => (
    <>
      {!c.launched ? (
        <i
          className="fas fa-plus ml-2 mr-2 "
          onClick={() => {
            setNoteId(`${c.identifier}${props.common.agreementData?.complaints_statuses?.[c.status]}`);
            props.edit(notesFormName, {
              annexId: c.id
            });
          }}
        />
      ) : null}
      {c.status === 1 && !c.launched ?       <i className="fas fa-play ml-2 mr-2 " onClick={() => {
        props.launch(launchForm, c.id, false);
      }} /> : null}
      {c.launched ? null :<i className="fas fa-pencil-alt ml-2 mr-2 " onClick={() => props.edit(formName, c)} />}
      <i className="fas fa-question-circle ml-2 mr-2 " onClick={() => {}} />
    </>
  );

  const decide = (t, c) => {
    setType(t);
    props.edit(creditDecisionForm, {
      ...c,
      type: t === 'I' ? 1 : 2
    });
  };

  let roleTypes = props.common?.agreementData?.[type === 'I' ? 'roles_type_1': 'roles_type_2'];
  roleTypes = Object.keys(roleTypes).map(k => ({ value: Number(k), name: roleTypes[k] }));
  let decitions = props.common?.agreementData?.credit_score_decitions;
  decitions = Object.keys(decitions).map(k => ({ value: Number(k), name: decitions[k] }));

  const handleSubmitForm = () => {
    dispatch(AgreementActions.addAnnex(formName, props.agreement?.id));
  };

  return (
    <>
      {props.version?.annexes?.map(c => (
        <OldCard header={header(c)} id="financialData-card" showIcons customCreateSection={customCreateSection(c)}>
          <div className="collapse show" id="financialData">
            <div className="row bb" style={{ fontSize: '90%' }}>
              <div className="col-sm-1 p-2 grey br">Numer aneksu</div>
              <div className="col-sm-1 p-2 grey br">Data otwarcia</div>
              <div className="col-sm-2 p-2 grey br">Umowa</div>
              <div className="col-sm-2 p-2 grey br">Zgłaszający</div>
              <div className="col-sm-1 p-2 grey br">Nr telefonu</div>
              <div className="col-sm-1 p-2 grey br">Decyzja</div>
              <div className="col-sm-1 p-2 grey br">Zamknięty</div>
              <div className="col-sm-1 p-2 grey br">Uruchomiony</div>
              <div className="col-sm-1 p-2 grey">Data zawarcia</div>
            </div>
            <div className="row bb" style={{ fontSize: '90%' }}>
              <div className="col-sm-1 p-2  br">{c.identifier}</div>
              <div className="col-sm-1 p-2  br">{c.created_date}</div>
              <div className="col-sm-2 p-2  br">{props.agreement?.identifier ?? '-'}</div>
              <div className="col-sm-2 p-2  br">{c.person_in_company?.person?.full_name}</div>
              <div className="col-sm-1 p-2  br">{parsePhone(c.phone)}</div>
              <div className="col-sm-1 p-2  br">{props.common?.agreementData?.credit_score_decitions[c.credit_final_score] ?? '-'}</div>
              <div className="col-sm-1 p-2  br">
                <BooleanCheck field={c.status >=1} />
              </div>
              <div className="col-sm-1 p-2  br">
                <BooleanCheck field={c.launched} />

              </div>
              <div className="col-sm-1 p-2 ">
                {c.launch_date || '-'}
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br" style={{ color: '#006BFF' }}>
                Zakres aneksu
              </div>
              <div className="col-sm-10 p-2 ">
                <TextLink>{c.comment}</TextLink>
              </div>
            </div>
          </div>
          {c.status === 1 ? <>
            <div className="row bb">
              <div className="col-sm-2 p-2 grey br">Dodano</div>
              <div className="col-sm-2 p-2 grey br">Tab</div>
              <div className="col-sm-2 p-2 grey br">Sekcja</div>
              <div className="col-sm-2 p-2 grey br">Paramentr</div>
              <div className="col-sm-2 p-2 grey br">Jest</div>
              <div className="col-sm-2 p-2 grey">Było</div>
            </div>
            {c.history && c.history[0] && c.history[0].length > 0 ? <RowEditable>
              <div className="col-sm-2 p-2 br">-</div>
              <div className="col-sm-2 p-2 br ">Tab</div>
              <div className="col-sm-2 p-2 br ">Sekcja</div>
              <div className="col-sm-2 p-2 br ">Paramentr</div>
              <div className="col-sm-2 p-2 br ">Jest</div>
              <div className="col-sm-2 p-2">Było</div>
            </RowEditable> : <div className="row"><div className="col-sm-12 p-2 bb">Brak danych.</div></div>}

            <div className="row bb">
              <div className="col-sm-2 p-2 grey br">Usunięto</div>
              <div className="col-sm-2 p-2 grey br">Tab</div>
              <div className="col-sm-2 p-2 grey br">Sekcja</div>
              <div className="col-sm-2 p-2 grey br">Paramentr</div>
              <div className="col-sm-2 p-2 grey br">Jest</div>
              <div className="col-sm-2 p-2 grey">Było</div>
            </div>
            {c.history && c.history[2] && c.history[2].length > 0 ? <RowEditable>
              <div className="col-sm-2 p-2 br">-</div>
              <div className="col-sm-2 p-2 br ">Tab</div>
              <div className="col-sm-2 p-2 br ">Sekcja</div>
              <div className="col-sm-2 p-2 br ">Paramentr</div>
              <div className="col-sm-2 p-2 br ">Jest</div>
              <div className="col-sm-2 p-2">Było</div>
            </RowEditable> : <div className="row"><div className="col-sm-12 p-2 bb">Brak danych.</div></div>}

            <div className="row bb">
              <div className="col-sm-2 p-2 grey br">Zmodyfikowano</div>
              <div className="col-sm-2 p-2 grey br">Tab</div>
              <div className="col-sm-2 p-2 grey br">Sekcja</div>
              <div className="col-sm-2 p-2 grey br">Paramentr</div>
              <div className="col-sm-2 p-2 grey br">Jest</div>
              <div className="col-sm-2 p-2 grey">Było</div>
            </div>
            {c.history && c.history.length > 0 ? c.history.filter(h => !Array.isArray(h)).map(h =><RowEditable>
              <div className="col-sm-2 p-2 br">-</div>
              <div className="col-sm-2 p-2 br ">{h.tab}</div>
              <div className="col-sm-2 p-2 br ">{h.section}</div>
              <div className="col-sm-2 p-2 br ">{h.field_verbose_name}</div>
              <div className="col-sm-2 p-2 br ">{h.to_value}</div>
              <div className="col-sm-2 p-2">{h.from_value || '-'}</div>
            </RowEditable>) : <div className="row"><div className="col-sm-12 p-2 bb">Brak danych.</div></div>}
          </> : null}
          {c.notes.length > 0 ? (
            c.notes.map(note => (
              <RowEditable
                key={note.id}
                className="row data-row mt-2 mb-1"
                itemId={note.id}
                onEdit={() => {
                  setNoteId(`${c.identifier}${props.common.agreementData?.complaints_statuses?.[c.status]}`);
                  props.edit(notesFormName, { ...note, annexId: c.id });
                }}
                onRemove={() => props.removeNote(c, note)}>
                <div className="col-sm-2 text-center align-self-center">
                  <img src={note.avatar} className="img image rounded-circle" style={{ width: '80%' }} alt="" />
                  <div className="grey" style={{ fontSize: '80%' }}>
                    <span className="d-block mt-2">Notatka</span>
                    <span>{note.created_date}</span>
                  </div>
                </div>
                <div className="col-sm-10">
                  <a
                    role="button"
                    style={{ color: 'black' }}
                    className="collapsed"
                    data-toggle="collapse"
                    href={`#notatka${note.id}`}
                    aria-expanded="false"
                    aria-controls="collapseExample">
                    <i className="fa fa-sort-down mr-2" />
                    <span className="text-themecolor">{note.user_creator?.full_name}</span> dodał notatkę dla reklamacji{' '}
                    <span className="text-themecolor">{c.identifier}</span>
                  </a>

                  <div className="collapse show" id={`notatka${note.id}`} style={{ color: 'grey' }}>
                    <p className="small mb-1">
                      {note.created_date} |<span style={{ color: 'orange' }}>Temat:</span> {note.name}
                    </p>
                    <TextLink>{note.comment}</TextLink>

                    <div style={{ border: '1px solid lightgrey' }} className="p-2 small mt-2 mb-2">
                      <span className="d-block">
                        Dodał: <span className="text-themecolor">{note.user_creator?.full_name}</span>
                      </span>
                      <span className="d-block">
                        Data ostatniej modyfikacji:
                        {note.modified_date}
                      </span>
                      <span className="d-block">
                        Data utworzenia:
                        {note.created_date}
                      </span>
                    </div>
                  </div>
                </div>
              </RowEditable>
            ))
          ) : (
            <div className="row">
              <div className="col p-2">
                <span>Brak notatek.</span>
              </div>
            </div>
          )}
          {/*<div className="row bt bb">*/}
          {/*  <div className="col-sm-3 p-2" style={{ color: '#006BFF' }}>*/}
          {/*    Operatorzy zgłoszenia*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="row bb">*/}
          {/*  <div className="col-sm-2 p-2 br grey">Otworzył</div>*/}
          {/*  <div className="col-sm-2 p-2 br grey">Data otwarcia</div>*/}
          {/*  <div className="col-sm-2 p-2 br grey">Zamknął</div>*/}
          {/*  <div className="col-sm-2 p-2 grey">Data zamknięcia</div>*/}
          {/*</div>*/}
          {/*<div className="row bb">*/}
          {/*  <div className="col-sm-2 p-2 br">{c.user_opened?.full_name || '-'}</div>*/}
          {/*  <div className="col-sm-2 p-2 br">{c.created_date || '-'}</div>*/}
          {/*  <div className="col-sm-2 p-2 br">{c.user_closed?.full_name || '-'}</div>*/}
          {/*  <div className="col-sm-2 p-2">{c.closed_date || '-'}</div>*/}
          {/*</div>*/}
          <div className="row bt bb">
            <div className="col-sm-3 p-2" style={{ color: '#006BFF' }}>
              {c.status ? 'Decyzja kredytowa' : 'Zmiana decyzji kredytowej'}
            </div>
          </div>
          <div className="row bb" style={{ color: '#1DB393' }}>
            <div className="col-sm-12 p-2" >
              Etap I

              {c.status === 1 && !c.launched ? <span className="float-right" style={{ cursor: 'pointer' }}>
                <i style={{ color: '#1DB393' }} className="fas fa-pencil-alt ml-2" onClick={() => decide('I', c)} />
              </span> : null}
            </div>
          </div>
          {c.launched ? <>
            <div className="row bb">
              <div className="col-sm-2 p-2 br grey">Data oceny</div>
              <div className="col-sm-2 p-2 br grey">Oceniający</div>
              <div className="col-sm-2 p-2 br grey">Etap I</div>
              <div className="col-sm-2 p-2 br grey">Decyzja kredytowa</div>
              <div className="col-sm-2 p-2 grey">Nr aneksu</div>
            </div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br ">{c.credit_score_date_1}</div>
              <div className="col-sm-2 p-2 br ">{c.credit_score_user_1?.full_name}</div>
              <div className="col-sm-2 p-2 br ">{props.common?.agreementData?.roles_type_1[c.role_type_1] ?? '-'}</div>
              <div className="col-sm-2 p-2 br"><DecisionString value={props.common?.agreementData?.credit_score_decitions[c.credit_score_decition_1]}/></div>
              <div className="col-sm-2 p-2 ">{c.identifier}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 grey">
                Komentarz
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2">
                {c.credit_score_comment_1}
              </div>
            </div>
          </> : <>
            <div className="row bb">
              <div className="col-sm-2 p-2 br grey">Odstępstwa</div>
              <div className="col-sm-10 p-2">{c.credit_score_comment_1}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br grey">Decyzja</div>
              <div className="col-sm-10 p-2">
                {props.common?.agreementData?.credit_score_decitions[c.credit_score_decition_1] ?? <span style={{ color: '#C99300' }}>Brak decyzji</span>}
              </div>
            </div>
          </>}
          <div className="row bb" style={{ color: '#1DB393' }}>
            <div className="col-sm-12 p-2" >
              Etap II

              {c.status === 1 && !c.launched ? <span className="float-right" style={{ cursor: 'pointer' }}>
                <i style={{ color: '#1DB393' }} className="fas fa-pencil-alt ml-2" onClick={() => decide('II', c)} />
              </span> : null}
            </div>
          </div>
          {c.launched ? <>
            <div className="row bb">
              <div className="col-sm-2 p-2 br grey">Data oceny</div>
              <div className="col-sm-2 p-2 br grey">Oceniający</div>
              <div className="col-sm-2 p-2 br grey">Etap II</div>
              <div className="col-sm-2 p-2 br grey">Decyzja kredytowa</div>
              <div className="col-sm-2 p-2 grey">Nr aneksu</div>
            </div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br ">{c.credit_score_date_2}</div>
              <div className="col-sm-2 p-2 br ">{c.credit_score_user_2?.full_name}</div>
              <div className="col-sm-2 p-2 br ">{props.common?.agreementData?.roles_type_2[c.role_type_2] ?? '-'}</div>
              <div className="col-sm-2 p-2 br"><DecisionString value={props.common?.agreementData?.credit_score_decitions[c.credit_score_decition_2]}/></div>
              <div className="col-sm-2 p-2 ">{c.identifier}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 grey">
                Komentarz
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2">
                {c.credit_score_comment_2}
              </div>
            </div>
          </> : <>
            <div className="row bb">
              <div className="col-sm-2 p-2 br grey">Odstępstwa</div>
              <div className="col-sm-10 p-2">{c.credit_score_comment_2}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br grey">Decyzja</div>
              <div className="col-sm-10 p-2" >
                {props.common?.agreementData?.credit_score_decitions[c.credit_score_decition_2] ?? <span style={{ color: '#C99300' }}>Brak decyzji</span>}
              </div>
            </div>
          </>}

        </OldCard>
      ))}

      <BaseModal
        visible={props.formVisible(formName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Dodawanie aneksu</span>
          </div>
        }
        footer="Zapisz zmiany."
        onClickAway={() => props.toggleForm(formName)}>
        <AnnexesForm
          visibleOnMount
          forms={props.forms}
          errors={props.errors}
          version={props.version}
          agreement={props.agreement}
          common={props.common}
          preloadedForm
          clear={props.clear}
          visible={props.formVisible(formName)}
          formVisible={props.formVisible}
          onCancelButtonClick={() => props.toggleForm(formName)}
          toggleForm={props.toggleForm}
          formName={formName}
          withButtons
          storeInput={props.storeInput}
          submitForm={handleSubmitForm}
        />
      </BaseModal>

      <BaseModal
        visible={props.formVisible(notesFormName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Notatka do aneksu</span>
          </div>
        }
        footer="Aby zapisać notatkę wciśnij ZATWIERDŹ."
        onClickAway={() => props.toggleForm(notesFormName)}>
        <div className="pr-5">
          <NoteForm
            visibleOnMount
            clear={props.clear}
            visible={props.formVisible(notesFormName)}
            formName={notesFormName}
            withButtons
            submitForm={() => props.saveForm(notesFormName)}
            onCancelButtonClick={() => props.toggleForm(notesFormName)}
            identifier={noteId}
          />
        </div>
      </BaseModal>
      <BaseModal
        contentStyle={{ height: '330px' }}
        visible={props.formVisible(launchForm)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Uruchomienie aneksu</span>
          </div>
        }
        // footer="Aby zapisać notatkę wciśnij ZATWIERDŹ."
        onClickAway={() => {
          props.toggleForm(launchForm);
        }}>
        <div className='w-full h-full flex flex-col items-between justify-between'>
          <div className="w-full row d-flex align-items-center">
            <div className="col-sm-1 font-weight-bold grey text-right">Umowa</div>
            <div className="col-sm-2 mr-20">{props.agreement?.identifier}</div>
            <div className="col-sm-1 font-weight-bold grey text-right">Kierunek</div>
            <div className="col-sm-3">W trakcie aneksowania -> Aktywna</div>
            <div className="col-sm-2 font-weight-bold grey text-right">Data zawarcia</div>
            <div className="col-sm-2 mt-10">
              <Datepicker formName={launchForm} name="launch_date" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12 d-flex align-items-center justify-content-center">
              <button type="button" onClick={() => {
                props.toggleForm(launchForm);
              }} className="btn btn-primary-outline ml-2  font-weight-light">
                Anuluj            </button>
              <button type="button" onClick={() => props.launch(launchForm, null, true)} className="btn btn-primary ml-2  font-weight-light">
                Uruchom            </button>
            </div>
          </div>
        </div>
      </BaseModal>


      <BaseModal
        visible={props.formVisible(creditDecisionForm)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>
              Decyzja kredytowa
            </span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(creditDecisionForm)}
      >
        <div className="row d-flex align-items-center">
          <div className="col-sm-2 p-2 grey">Oceniający</div>
          <div className="col-sm-2 p-2 grey">Etap {type}</div>
          <div className="col-sm-2 p-2 grey">Data oceny</div>
          <div className="col-sm-2 p-2 grey">Nr umowy</div>
          <div className="col-sm-2 p-2 grey">Klient</div>
          <div className="col-sm-2 p-2 grey">Decyzja kredytowa</div>
        </div>
        <div className="row d-flex align-items-center">
          <div className="col-sm-2 p-2">{props.user.full_name}</div>
          <div className="col-sm-2 p-2">
            <Select formName={creditDecisionForm} name={`role_type_${type === 'I' ? 1 : 2}`} options={roleTypes} />
          </div>
          <div className="col-sm-2 p-2">{moment().format('DD.MM.YYYY HH:mm')}</div>
          <div className="col-sm-2 p-2"><Tooltip label={props.agreement?.identifier} /></div>
          <div className="col-sm-2 p-2">{props.agreement?.client?.name}</div>
          <div className="col-sm-2 p-2">
            <Select formName={creditDecisionForm} name={`credit_score_decition_${type === 'I' ? 1 : 2}`} options={decitions} />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-12 p-1">
            <Textarea name={`credit_score_comment_${type === 'I' ? 1 : 2}`} formName={creditDecisionForm} placeholder="Komentarz" />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12 d-flex align-items-center justify-content-center">
            <button type="button" onClick={() => {
              props.toggleForm(creditDecisionForm);
            }} className="btn btn-primary-outline ml-2  font-weight-light">
              Anuluj            </button>
            <button type="button" onClick={() => props.saveForm(creditDecisionForm)} className="btn btn-primary ml-2  font-weight-light">
              Zatwierdź            </button>
          </div>
        </div>
      </BaseModal>
    </>
  );
};
