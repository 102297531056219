import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import { isEditableProposal } from 'containers/Proposals/Details/Index';
import { t } from 'lang/pl';
import React from 'react';
import { toast } from 'react-toastify';

import { parseMoney } from '../../../../utilities/helpers';
import { AdministratorForm } from '../../Forms/Administrator';

export const Administrator = (props) => {
  const formName = 'AdministratorForm';

  const proposal = props.version || {};
  const proposalToEdit = {
    ...proposal,
    currency_id: proposal.currency ? proposal.currency.id : null,
    oa_lump_sum_currency: proposal.oa_lump_sum_currency || '',
    claim_limit_currency: proposal.claim_limit_currency || ''
  };
  delete proposalToEdit.currency;

  const freq = props.freqs && props.freqs.find((f) => f.id === proposal.oa_lump_sum_freq)
    ? props.freqs.find((f) => f.id === proposal.oa_lump_sum_freq).name
    : 'brak';

  return (
    <Card
      header='Administrator'
      isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
      onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
      onEditClick={() => props.edit(formName, proposalToEdit)}
    >
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Kwota ryczałtu netto</div>
              <div className="col-sm-9 p-2">
                {parseMoney(proposal.oa_lump_sum_amount)}
                {' '}
                {proposal.oa_lump_sum_amount && proposal.currency ? proposal.currency.name : ''}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3 p-2 br grey">Okres rozliczeniowy ryczałtu</div>
              <div className="col-sm-9 p-2">
                {freq}
                {' '}
              </div>
            </div>
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Administrator</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <AdministratorForm
          freqs={props.freqs}
          currencies={props.currencies}
          proposalId={proposal.id}
          proposal={proposal}
          preloadedForm
          visibleOnMount={false}
          clear={props.clear}
          visible={props.formVisible(formName)}
          formName={formName}
          submitForm={() => props.saveForm(formName)}
          onCancelButtonClick={() => props.toggleForm(formName)}
          withButtons
        />
      </BaseModal>
    </Card>
  );
};
