import { BaseModal } from 'components/Layout/OldModal';
import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { OldCard } from '../../../components/Layout';
import { SidebarNotes } from '../../../components/SidebarNotes';
import { parseMoney, formatNip, parsePhone } from '../../../utilities/helpers';
import RemovalModal from '../Forms/RemovalModal';

class CompanySidebarView extends React.Component {
  constructor() {
    super();
    this.state = { objectToRemove: {} };
  }

  setObjectToRemove = object => {
    if (this.state.objectToRemove.id) {
      this.setState({ objectToRemove: {} });
    } else {
      this.setState({ objectToRemove: object });
    }
  };

  render() {
    // const { offer, loading } = this.props;

    const offer = this.props.offer || {};
    const client = offer.client || {};
    const a = client.active_address && Object.keys(client.active_address).length > 0 ? client.active_address : null;

    return (
      <OldCard className="mt-3 pt-3 pb-3">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="font-weight-bold text-themecolor">
              {offer.identifier}
              <span className="float-right text-themecolor" style={{ cursor: 'pointer' }}>
                <i className="fas fa-sign-in-alt mr-2" onClick={() => this.props.history.push(`/oferty/${this.props.offer.slug}`)} />

                <i className="fa fa-trash" style={{ color: 'red' }} onClick={() => this.setObjectToRemove(this.props.offer)} />
              </span>
            </h3>
          </div>
        </div>
        <div className="mb-2">
          <div className="row">
            <div className="col-sm-12 text-left grey">
              Kwota limitu: {parseMoney(offer.active_limit_amount)} {offer.active_currency?.name}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 mt-3 mb-3">
            {offer.status === 0 ? (
              <button type="button" className="btn btn-sm btn-success" onClick={() => this.props.toggleForm('status')}>
                <i className="fa fa-link mr-2" />
                Zamknij
              </button>
            ) : (
              <button type="button" className="btn btn-sm btn-success mr-3" onClick={() => this.props.toggleForm('generatePdf')}>
                <i className="fa fa-link mr-2" />
                Generuj PDF
              </button>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h4 className="text-themecolor mt-2 mb-2">Opiekunowie</h4>
          </div>
        </div>
        <div className="row mb-4 mt-1">
          <div className="col d-flex align-items-center">
            <ReactTooltip />
            <img
              src="/user.png" width="50" height="50"
              alt=""
              className="img-circle mr-2"
              data-tip={`Administrator operacyjny: ${offer.operational_administrator?.name ?? '-'}`}
            />
            <img
              src="/user.png" width="50" height="50"
              alt=""
              className="img-circle mr-2"
              data-tip={`Opiekun ds. rozliczeń: ${offer.billing_manager?.full_name ?? '-'}`}
            />
            <img
              src="/user.png" width="50" height="50"
              alt=""
              className="img-circle"
              data-tip={`Opiekun handlowy: ${offer.trade_supervisor?.full_name ?? '-'}`}
            />
          </div>
        </div>

        <div className="rows-bordered mt-1">
          <div className="row p-1">
            <div className="col-6">
              <h4 className="text-themecolor mt-2 mb-2">Status oferty</h4>
            </div>
            <div className="col-sm-6 text-right">
              <span className="badge badge-warning mt-2 mb-2 pt-1 pb-1 pl-2 pr-2">{offer.status ? 'zatwierdzona' : 'niezatwierdzona'}</span>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Faktor</div>
            <div className="col-sm-6 text-right">{offer?.factor?.name ?? '-'}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Produkt</div>
            <div className="col-sm-6 text-right">{offer?.product?.name ?? '-'}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Procedura</div>
            <div className="col-sm-6 text-right">{offer?.procedure?.name ?? '-'}</div>
          </div>

          <div className="row p-1">
            <div className="col-12">
              <h4 className="text-themecolor mt-2 mb-2">Klient</h4>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Nazwa firmy</div>
            <div className="col-sm-6 text-right">{client.name}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">NIP</div>
            <div className="col-sm-6 text-right">{formatNip(client.nip)}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Forma prawna</div>
            <div className="col-sm-6 text-right">{client.legal_form ? client.legal_form.name : '-'}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Telefon</div>
            <div className="col-sm-6 text-right">{parsePhone(client.active_phone)}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Email</div>
            <div className="col-sm-6 text-right">{client.active_email}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Adres korespondencyjny</div>
            <div className="col-sm-6 text-right">
              {a ? `${a.street} ${a.local_no ? `${a.building_no}/${a.local_no}` : a.building_no}, ${a.city}` : '-'}
            </div>
          </div>
          <div className="row p-1">
            <div className="col-12">
              <h4 className="text-themecolor mt-2 mb-2">Notatki</h4>
              <SidebarNotes object={offer} />
            </div>
          </div>
        </div>

        {this.props?.options?.visible && (
          <>
            <RemovalModal
              visible={Object.keys(this.state.objectToRemove).length > 0}
              toggle={() => this.setObjectToRemove({})}
              remove={() => {
                this.props.remove(this.state.objectToRemove.id);
                this.setObjectToRemove({});
              }}>
              {this.state.objectToRemove && (
                <div className="container-fluid">
                  <div className="row">Czy na pewno chcesz usunąć tę ofertę?</div>
                </div>
              )}
            </RemovalModal>

            <BaseModal
              visible={this.props.formVisible('status')}
              header={
                <div className="d-flex align-items-center">
                  <i className="fas fa-pencil-alt mr-2" /> <span>Zmiana statusu oferty</span>
                </div>
              }
              onClickAway={() => this.props.toggleForm('status')}>
              <div className="row">
                <div className="col">Czy na pewno chcesz zmienić status oferty?</div>
              </div>
              <div className="row">
                <div className="mt-4 col-sm-12 text-center">
                  <button
                    type="button"
                    onClick={() => this.props.toggleForm('status')}
                    className="btn btn-primary-outline mr-2 font-weight-light"
                    style={{ color: '#4B4E55' }}>
                    Anuluj
                  </button>
                  <button type="button" onClick={() => this.props.changeStatus(offer.id)} className="btn btn-primary ml-2  font-weight-light">
                    Zatwierdź
                  </button>
                </div>
              </div>
            </BaseModal>

            <BaseModal
              visible={this.props.formVisible('generatePdf')}
              header={
                <div className="d-flex align-items-center">
                  <i className="fas fa-pencil-alt mr-2" />
                  <span>Generowanie PDF</span>
                </div>
              }
              onClickAway={() => this.props.toggleForm('generatePdf')}>
              <div className="row">
                <div className="col">Czy na pewno chcesz wygenerować PDF z wybranej oferty?</div>
              </div>
              <div className="row">
                <div className="mt-4 col-sm-12 text-center">
                  <button
                    type="button"
                    onClick={() => this.props.toggleForm('generatePdf')}
                    className="btn btn-primary-outline mr-2 font-weight-light"
                    style={{ color: '#4B4E55' }}>
                    Anuluj
                  </button>
                  <button
                    type="button"
                    onClick={() => this.props.generatePdf(offer.id, 'generatePdf')}
                    className="btn btn-primary ml-2  font-weight-light">
                    Generuj
                  </button>
                </div>
              </div>
            </BaseModal>
          </>
        )}
      </OldCard>
    );
  }
}

export default withRouter(CompanySidebarView);
