import Input from 'components/Forms/OldInput';
import OrderBy from 'components/Forms/OldOrderBy';
import { BaseModal } from 'components/Layout/OldModal';
import { OldPagination } from 'components/Layout/OldPagination';
import Tooltip from 'components/Layout/Tooltip';
import { get, isEqual } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import CommonActions from '../../actions/common';
import FormActions from '../../actions/forms';

export default connect(
  state => ({ forms: state.Forms }),
  dispatch => ({
    storeInput: (f, n, v) => dispatch(FormActions.storeInput(f, n, v)),
    initializeForm: (n, p = {}, r = {}) => dispatch(FormActions.initialize(n, p, r))
  })
)(props => {
  if (props.formName && !props.forms.forms[props.formName]) {
    props.initializeForm(props.formName);
  }

  const defaultValue = props.multiple ? [] : null;
  const currentValue = () => get(props.forms.forms[props.formName], props.field, defaultValue);
  const [locallySelected, selectLocally] = useState(currentValue());

  const columns = props.columns || [];

  let options = props.options || {};

  const { count, current_page, currentPage, links } = options;

  if (options.results && Object.keys(options.results).length > 0) {
    options.list = options.results;
    delete options.results;
  }

  if (options.list) {
    options = options.list;
  }

  if (!Array.isArray(options)) {
    options = [];
  }

  if (Array.isArray(options) && props.optionMapper) {
    options = options.map(props.optionMapper);
    options = options.filter(o => o && Object.keys(o).length > 0);
  }

  const save = () => {
    props.storeInput(props.formName, props.field, locallySelected);

    if (props.onSelected && typeof props.onSelected === 'function') {
      props.onSelected(locallySelected);
    }
  };

  const changePage = page => {
    props.storeInput(props.filtersForm, 'page', page);
    props.fetch();
  };

  const isSelected = option => {
    if (props.multiple) {
      return Array.isArray(locallySelected) && locallySelected.find(t => isEqual(t, option));
    }
    return isEqual(locallySelected, option);
  };

  const selectOption = option => {
    if (props.multiple) {
      const v = () => (currentValue().length === locallySelected.length ? currentValue() : locallySelected);
      const newList = v().filter(t => !isEqual(t, option));

      if (newList.length === v().length) {
        newList.push(option);
      }

      selectLocally(newList);
    } else {
      selectLocally(
        isEqual(locallySelected, option) ? null : option
      );
    }
  };

  if (props.filtersForm && !props.forms.forms[props.filtersForm]) {
    props.initializeForm(props.filtersForm, CommonActions.defaultFilters);
  }

  return (
    <BaseModal
      visible={props.visible}
      header={
        props.headerElement || (
          <div className="d-flex align-items-center">
            {props.icon || <i className="fas fa-pencil-alt mr-2" />}
            <span>{props.header}</span>
          </div>
        )
      }
      footer={props.footerElement || props.footer || null}
      onClickAway={props.onClickAway}>
      <div className="container" style={{ maxWidth: '100%' }}>
        {props.title || ''}
        {props.filtersForm && !props.noTermSearching ? (
          <div className="row mt-3">
            <div className="col-sm-12">
              <Input onSubmit={props.fetch} formName={props.filtersForm} type="text" name="term" placeholder="Wpisz szukaną frazę" />
            </div>
          </div>
        ) : null}
        <div className="row mt-3">
          <table className="selectable">
            <thead>
              <tr>
                {columns.map(item => (
                  <th>
                    {item.sortable ? (
                      <OrderBy formName={props.filtersForm} column={item.name} field={item.getter} onSubmit={props.fetch} />
                    ) : (
                      <span className="grey font-weight-light">{item.name || item}</span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {options?.map(option => (
                <tr>
                  {columns?.map(item => {
                    const text = item.mutator ? item.mutator(get(option, item.getter)) : get(option, item.getter);

                    return (
                      <td className={isSelected(option) ? 'companyRowSelected' : ''} onClick={() => selectOption(option)}>
                        <span className=" font-weight-light">
                          {item?.tooltip ? (
                            <Tooltip label={text} />
                          ) : text}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {links ? <OldPagination currentPage={current_page ?? currentPage} changePage={changePage} links={links} count={count} /> : null}

        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button
              id={`selectable-${props.field}-cancel`}
              type="button"
              onClick={() => {
                selectLocally(currentValue());
                props.onClickAway();
              }}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}>
              Anuluj
            </button>
            <button
              id={`selectable-${props.field}-submit`}
              type="button"
              onClick={() => {
                save();
                selectLocally(currentValue());
                props.onClickAway();
              }}
              className="btn btn-primary ml-2  font-weight-light">
              {props.submitButtonText || 'Zatwierdź'}
            </button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
});
