import 'twin.macro';

import { get } from 'lodash';
import React, { useRef } from 'react';
import { connect } from 'react-redux';

import FormActions from '../../actions/forms';

const OldInput = ({
  name, type, formName, forms, defaultValue, errorName, divClassName, style,
  disabled, placeholder, maxLength, className, storeInput, onBlur, onSubmit, onChange, ...props }) =>
{
  let value = get(forms.forms[formName], name, defaultValue ?? '');

  const errName = errorName ?? name;
  let error = get(forms.errors[formName], errName, null) || props.error;
  const refInput = useRef();

  const onKeyDown = event => {
    // to number input types
    if(type === 'number') {
      const isAllowedInput = event.key !== 'a' && event.key !== 'z' && event.key !== 'x' && event.key !== 'c' && event.key !== 'Backspace';
      const NUMBER_DOT_COMMA = /^[\d,.]*$/;
      const fieldValue = event.target.value;
      const hasCommaOrDot = fieldValue.includes('.') || fieldValue.includes(',');
      const isCommaOrDot = event.key === '.' || event.key === ',';
      event.target.value = fieldValue.replace(/([a-zA-Z ])/g, '');

      if (isAllowedInput && (!NUMBER_DOT_COMMA.test(event.key) || (isCommaOrDot && hasCommaOrDot))) {
        event.preventDefault();
        event.target.value = fieldValue.replace(',', '.');
      }
    }
    if (event.key === 'Enter' && typeof onSubmit !== 'undefined') {
      onSubmit();
    }
    return event.target.value;
  };

  const handleOnChange = e => {
    let val = type === 'file' ? e.target.files : e.target.value;
    let valInput = val;
    if(name === 'phone') {
      valInput = val?.replace(/([^\d]+)/g, '')?.replace(/(\d{3})/g, '$1 ').trim();
    }
    if(type === 'number') {
      valInput = onKeyDown(e);
    }
    storeInput(formName, name, valInput);

    if (onChange && typeof onChange === 'function') {
      onChange(valInput);
    }
  };

  return (
    <div className={`form-group mb-0 ${!props.fullWidth && 'max-w-250'} ${divClassName}`} style={style} {...props}>
      <input
        ref={refInput}
        key={formName + name}
        id={`input-${name}`}
        onKeyDown={onKeyDown}
        value={value ?? ''}
        disabled={disabled || false}
        className={`${className || ''} form-control ${error ? 'form-control-danger' : ''}`}
        onBlur={({ target: { value } }) => onBlur && onBlur(value)}
        // to number input types - onKeyDown logic
        type={(type === 'number' ? 'text' : type) || 'text'}
        onChange={handleOnChange}
        placeholder={placeholder || null}
        maxLength={maxLength}
        name={name}
      />
      <div className="form-control-feedback small min-h-15" style={{ color: 'red' }}>
        {error}
      </div>
    </div>
  );
};

OldInput.defaultProps = {
  parsedFunc: value => value
};

export default connect(
  state => ({ forms: state.Forms }),
  dispatch => ({
    storeInput: (f, n, v) => dispatch(FormActions.storeInput(f, n, v)),
    storeError: (f, n, v) => dispatch(FormActions.handleErrors(f, { [n]: [v] }))
  })
)(OldInput);
