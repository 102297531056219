import AgreementActions from 'actions/agreement';
import FormActions from 'actions/forms';
import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import Tooltip from 'components/Layout/Tooltip';
import React from 'react';
import { useDispatch } from 'react-redux';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import { formatNip, parseMoney } from '../../../../utilities/helpers';
import SecuresForm from '../../../Proposals/Forms/Secures';
import Secures from '../../Forms/Secures';

export const FORM_SECURES_MANAGE = 'SecuresManageForm';

export default props => {
  const dispatch = useDispatch();
  const formName = 'SecuresForm';

  const edit = i => {
    props.edit(FORM_SECURES_MANAGE, {
      ...i,
      dts_form_id: i.dts_form?.id,
      dts_type_id: i.dts_type?.id,
      issuer_id: i.issuer?.id,
      is_factor_pattern: Number(i.is_factor_pattern),
      indefinite_period_of_validity: Number(i.indefinite_period_of_validity)
    });
  };

  const secures = props.version?.secure_documents ?? { 0: [], 1: [], 2: [] };

  const handleCloseModal = () => {
    dispatch(AgreementActions.fetchDetails(props.agreement.id));
    dispatch(FormActions.toggleForm(FORM_SECURES_MANAGE));
  };

  return (
    <Card header='Zabezpieczenia umowy' onAddClick={() => props.edit(formName, {})}>
      <div className="collapse show" id="financialData">
        <div className="row bb">
          <div className="col-12 p-2" style={{ color: '#006BFF' }}>
            Faktorant zabezpieczenia
          </div>
        </div>
        <div className="row bb">
          <div className="col-2 p-2 br grey">Rodzaj zabezpieczenia</div>
          <div className="col-1 p-2 br grey">Kwota zabezpieczenia PLN</div>
          <div className="col-1 p-2 br grey">Wartość Zabezpieczenia PLN</div>
          <div className="col-2 p-2 br grey">Wystawiający</div>
          <div className="col-2 p-2 br grey">Nr zabezpieczenia</div>
          <div className="col-2 p-2 br grey">Status</div>

          <div className="col-2 p-2">
            {/* <button type="button" onClick={() => {}}> */}
            {/*  Zarządzaj */}
            {/* </button> */}
          </div>
        </div>
        {secures?.[0]?.map(i => (
          <RowEditable onRemove={() => props.remove('documents_to_secure', i.id)}>
            <div className="col-2 p-2 br">{i?.dts_type?.name}</div>
            <div className="col-1 p-2 br">{parseMoney(i?.current_debt)}</div>
            <div className="col-1 p-2 br">{parseMoney(i?.collateral_value)}</div>
            <div className="col-2 p-2 br">{i?.issuer?.name}</div>
            <div className="col-2 p-2 br"><Tooltip label={i?.identifier} /></div>
            <div className="col-2 p-2 br">{props.common.agreementData?.secure_document_statuses?.[i?.status]}</div>
            <div className="col-2 p-2">
              <button type="button" onClick={() => edit(i)}>
                Zarządzaj
              </button>
              {i.status === 1 ? (
                <button className="ml-1" type="button" onClick={() => props.archive('documents_to_secure', i.id)}>
                  Archiwizuj
                </button>
              ) : null}
            </div>
          </RowEditable>
        ))}
        <div className="row bb">
          <div className="col-12 p-2" style={{ color: '#006BFF' }}>
            Cesje wierzytelności
          </div>
        </div>
        <div className="row bb">
          <div className="col-2 p-2 br grey">Dłużnik</div>
          <div className="col-1 p-2 br grey">NIP</div>
          <div className="col-1 p-2 br grey">Wartość zabezpieczenia PLN</div>
          <div className="col-2 p-2 br grey">Wystawiający</div>
          <div className="col-2 p-2 br grey">Nr zabezpieczenia</div>
          <div className="col-2 p-2 br grey">Status</div>
          <div className="col-2 p-2">
            {/* <button type="button" onClick={() => {}}> */}
            {/*  Zarządzaj */}
            {/* </button> */}
          </div>
        </div>
        {secures?.[3]?.map(i => (
          <RowEditable>
            <div className="col-2 p-2 br">{i.debtor?.name}</div>
            <div className="col-1 p-2 br">{formatNip(i.debtor?.nip)}</div>
            <div className="col-1 p-2 br">{parseMoney(i.collateral_value)}</div>
            <div className="col-2 p-2 br">{i.issuer?.name}</div>
            <div className="col-2 p-2 br"><Tooltip label={i.identifier} /></div>
            <div className="col-2 p-2 br">{props.common.agreementData?.secure_document_statuses?.[i.status]}</div>
            <div className="col-2 p-2">
              <button type="button" onClick={() => edit(i)}>
                Zarządzaj
              </button>
              {i.status === 1 ? (
                <button className="ml-1" type="button" onClick={() => props.archive('documents_to_secure', i.id)}>
                  Archiwizuj
                </button>
              ) : null}
            </div>
          </RowEditable>
        ))}
        <div className="row bb">
          <div className="col-12 p-2" style={{ color: '#006BFF' }}>
            Dłużnicy zabezpieczenia
          </div>
        </div>
        <div className="row bb">
          <div className="col-2 p-2 br grey">Rodzaj zabezpieczenia</div>
          <div className="col-1 p-2 br grey">Kwota zabezpieczenia PLN</div>
          <div className="col-1 p-2 br grey">Wartość zabezpieczenia PLN</div>
          <div className="col-2 p-2 br grey">Wystawiający</div>
          <div className="col-2 p-2 br grey">Nr zabezpieczenia</div>
          <div className="col-2 p-2 br grey">Status</div>
          <div className="col-2 p-2">
            {/* <button type="button" onClick={() => {}}> */}
            {/*  Zarządzaj */}
            {/* </button> */}
          </div>
        </div>
        {secures?.[1]?.map(i => (
          <RowEditable onRemove={() => props.remove('documents_to_secure', i.id)}>
            <div className="col-2 p-2 br">{i.dts_type?.name || '-'}</div>
            <div className="col-1 p-2 br">{parseMoney(i.current_debt)}</div>
            <div className="col-1 p-2 br">{parseMoney(i.collateral_value)}</div>
            <div className="col-2 p-2 br">{i.issuer?.name}</div>
            <div className="col-2 p-2 br"><Tooltip label={i.identifier} /></div>
            <div className="col-2 p-2 br">{props.common.agreementData?.secure_document_statuses?.[i.status]}</div>
            <div className="col-2 p-2">
              <button type="button" onClick={() => edit(i)}>
                Zarządzaj
              </button>
              {i.status === 1 ? (
                <button className="ml-1" type="button" onClick={() => props.archive('documents_to_secure', i.id)}>
                  Archiwizuj
                </button>
              ) : null}
            </div>
          </RowEditable>
        ))}
        <div className="row bb">
          <div className="col-12 p-2" style={{ color: '#006BFF' }}>
            Transakcja zabezpieczenia
          </div>
        </div>
        <div className="row bb">
          <div className="col-2 p-2 br grey">Rodzaj zabezpieczenia</div>
          <div className="col-1 p-2 br grey">Kwota zabezpieczenia PLN</div>
          <div className="col-1 p-2 br grey">Wartość zabezpieczenia PLN</div>
          <div className="col-2 p-2 br grey">Wystawiający</div>
          <div className="col-2 p-2 br grey">Nr zabezpieczenia</div>
          <div className="col-2 p-2 br grey">Status</div>
          <div className="col-2 p-2">
            {/* <button type="button" onClick={() => {}}> */}
            {/*  Zarządzaj */}
            {/* </button> */}
          </div>
        </div>
        {secures?.[2]?.docs?.map(i => (
          <RowEditable onRemove={() => props.remove('documents_to_secure', i.id)}>
            <div className="col-2 p-2 br">{i.dts_type?.name || '-'}</div>
            <div className="col-1 p-2 br">{parseMoney(i.current_debt)}</div>
            <div className="col-1 p-2 br">{parseMoney(i.collateral_value)}</div>
            <div className="col-2 p-2 br">{i.issuer?.name}</div>
            <div className="col-2 p-2 br"><Tooltip label={i.identifier} /></div>
            <div className="col-2 p-2 br">{props.common.agreementData?.secure_document_statuses?.[i.status]}</div>
            <div className="col-2 p-2">
              <button type="button" onClick={() => edit(i)}>
                Zarządzaj
              </button>
              {i.status === 1 ? (
                <button className="ml-1" type="button" onClick={() => props.archive('documents_to_secure', i.id)}>
                  Archiwizuj
                </button>
              ) : null}
            </div>
          </RowEditable>
        ))}
      </div>

      <BaseModal
        visible={props.formVisible(FORM_SECURES_MANAGE)}
        size='extralarge'
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" /> <span>Zarządzanie zabezpieczeniem</span>
          </div>
        }
        onClickAway={handleCloseModal}>
        <Secures
          generate={props.generate}
          register={props.register}
          forms={props.forms}
          onClose={handleCloseModal}
          issuers={props.issuers}
          fetchIssuers={props.fetchIssuers}
          debtorsDict={props.debtorsDict}
          fetchDebtorsDict={props.fetchDebtorsDict}
          guarantors={props.guarantors}
          fetchGuarantors={props.fetchGuarantors}
          withButtons
          formVisible={props.formVisible}
          toggleForm={props.toggleForm}
          formName={FORM_SECURES_MANAGE}
          onCancelButtonClick={() => props.toggleForm(FORM_SECURES_MANAGE)}
          common={props.common}
          agreement={props.agreement}
          version={props.version}
          removeAttachment={props.removeAttachment}
          storeInput={props.storeInput}
          saveForm={props.saveForm}
          remove={props.remove}
          edit={props.edit}
        />
      </BaseModal>
      <BaseModal
        visible={props.formVisible(formName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Zabezpieczenia</span>
          </div>
        }
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}>
        <SecuresForm
          transactions
          issuers={props.issuers}
          fetchIssuers={props.fetchIssuers}
          forms={props.forms}
          common={props.common}
          proposal={props.proposal}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(formName)}
          formVisible={props.formVisible}
          onCancelButtonClick={() => props.toggleForm(formName)}
          toggleForm={props.toggleForm}
          formName={formName}
          withButtons
          submitForm={() => props.saveForm(formName)}
        />
      </BaseModal>
    </Card>
  );
};
