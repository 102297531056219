import * as R from 'ramda';
import React from 'react';

import { Maps } from '../../../Companies/Details/Components/Maps';
import CreditDecision from '../Components/CreditDecision';
import RiskMatrix from '../Components/RiskMatrix';
import Score from '../Components/Score';
import Secures from '../Components/Secures';

export default function Decisions(props) {
  const addressesRed = R.flatten([props.proposal?.client?.addresses?.map(x => ({ ...x, name: props.proposal?.client?.name }))].filter(Boolean));
  const addressesGreen = R.flatten([props.proposal?.debtors?.map(item => item?.debtor?.addresses.map(x => ({ ...x, name: item?.debtor?.name })))].filter(Boolean));
  const addressesBlue = R.flatten([props.proposal?.debtors?.map(item => item?.debtor?.persons?.map(detail => detail?.person?.addresses?.map(x => ({ ...x, name: `${detail?.person?.full_name} - ${item?.debtor?.name}` }))))].filter(Boolean));
  const addressesGrey = R.flatten([addressesRed.filter(address => !address?.is_active), addressesGreen.filter(address => !address?.is_active), addressesBlue.filter(address => !address?.is_active)].filter(Boolean));

  return (
    <>
      <Score proposal={props.proposal} version={props.version} proposalData={props.common.proposalData} offerData={props.common.offerData} />

      <CreditDecision
        proposal={props.proposal}
        version={props.version}
        clear={props.clear}
        forms={props.forms}
        toggleForm={props.toggleForm}
        formVisible={props.formVisible}
        saveForm={props.saveForm}
        edit={props.edit}
        remove={props.remove}
        common={props.common}
        user={props.user}
      />

      <RiskMatrix proposal={props.proposal} version={props.version} proposalData={props.common.proposalData} offerData={props.common.offerData} />

      <Maps
        addressesRed={addressesRed.filter(address => address?.is_active)}
        addressesGreen={addressesGreen.filter(address => address?.is_active)}
        addressesBlue={addressesBlue.filter(address => address?.is_active)}
        addressesGrey={addressesGrey}
        showLegend
      />

      <Secures
        issuers={props.issuers}
        fetchIssuers={props.fetchIssuers}
        clear={props.clear}
        proposal={props.proposal}
        version={props.version}
        forms={props.forms}
        toggleForm={props.toggleForm}
        formVisible={props.formVisible}
        saveForm={props.saveForm}
        edit={props.edit}
        common={props.common}
        storeInput={props.storeInput}
        remove={props.remove}
      />

      <Secures
        isReadOnly
        issuers={props.issuers}
        fetchIssuers={props.fetchIssuers}
        transactions
        clear={props.clear}
        proposal={props.proposal}
        version={props.version}
        forms={props.forms}
        toggleForm={props.toggleForm}
        formVisible={props.formVisible}
        saveForm={props.saveForm}
        edit={(object, formName) => props.edit(formName, object)}
        common={props.common}
        storeInput={props.storeInput}
        remove={props.remove}
      />
    </>
  );
}
