import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React from 'react';
import { useSelector } from 'react-redux';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { ContactsForm } from '../../Forms/Contacts';

export const Contacts = props => {
  const { ContactsCreatorForm: form } = useSelector(state => state.Forms.forms);

  const formName = 'ContactsCreatorForm';
  const modalFormName = 'ContactsCreatorEditForm';

  const selectedType = props.forms[formName] ? props.forms[formName].type : null;
  const modalSelectedType = props.forms[modalFormName] ? props.forms[modalFormName].type : null;

  const allowedContactTypes = ['email', 'phone', 'skype', 'weechat'];
  const contactTypes = props.contactTypes ? props.contactTypes.filter(i => allowedContactTypes.indexOf(i.value) !== -1) : [];

  const contacts = props.company && props.company.contacts ? props.company.contacts.filter(i => allowedContactTypes.indexOf(i.type) !== -1) : [];

  return (
    <Card mode='creator' header='+ Kontakty' onSaveClick={() => props.submitForm(formName)} isBlocked={!form?.value}>
      <ContactsForm
        countryCallingCodes={props.countryCallingCodes}
        visibleOnMount
        clear={props.clear}
        visible={props.formVisible(formName)}
        formName={formName}
        contactTypes={contactTypes}
        selectButtonDisabled={false}
        selectButtonValue={selectedType}
        typeSelected={selectedType}
        canBeActive={selectedType ? !['skype', 'weechat'].includes(selectedType) : true}
      />
      <div className="collapse show " id="kontakty">
        {contacts && contacts.length ? (
          <>
            <div className="collapse show" id="kontakty">
              <div className="row mt-3">
                <div className="col-sm-12  mb-4">
                  {contacts.map((c, i) => (
                    <RowEditable
                      index={i}
                      key={`${c.type}-${c.id}`}
                      itemId={c.id}
                      onEdit={() => props.edit(c, modalFormName)}
                      onRemove={() => {
                        props.remove(c.id);
                      }}>
                      <div className="col-sm-4 p-2 br text-left">
                        <span className="ml-5 grey mr-3 font-weight-light">Typ kontaktu:</span>
                        <span className="grey font-weight-bold" style={{ color: '#4B4E55', fontSize: '14px' }}>
                          {props.contactTypes && props.contactTypes.find(a => a.value === c.type)
                            ? props.contactTypes.find(a => a.value === c.type).name
                            : '-'}
                        </span>
                      </div>
                      <div className="col-sm-4 p-2 br grey">{c.value}</div>
                      {/* {c.type === "phone" || c.type === "email" ? ( */}
                      <div className="col-sm-4 p-2 d-flex align-items-center">
                        <BooleanCheck field={c.is_active} />
                        <span className="ml-3 grey font-weight-light">Czy aktywny</span>
                      </div>
                      {/* ) : null} */}
                    </RowEditable>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" /> <span>Kontakty</span>
          </div>
        }
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}>
        <ContactsForm
          countryCallingCodes={props.countryCallingCodes}
          clear={props.clear}
          visible={props.formVisible(modalFormName)}
          formName={modalFormName}
          contactTypes={contactTypes}
          selectButtonDisabled
          selectButtonValue={modalSelectedType}
          typeSelected={modalSelectedType}
          withButtons
          canBeActive={modalSelectedType ? !['skype', 'weechat'].includes(modalSelectedType) : true}
          submitForm={() => props.submitForm(modalFormName, true)}
          onCancelButtonClick={() => props.toggleForm(modalFormName)}
        />
      </BaseModal>
    </Card>
  );
};
