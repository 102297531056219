import { ColName } from 'components/Forms/Col';
import React, { useState } from 'react';

import {
  Checkbox,   Form, Input, Select, SelectButton
} from '../../../components/Forms';

export const CommissionForm = (props) => {
  const [isMounted, setMounted] = useState(true);

  const handleChangeCommissionType = () => {
    setMounted(prev => !prev);
    setTimeout(() => setMounted(prev => !prev));
    props.clear(props.formName, 'unit');
  };

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          {props.categories && props.categories.length > 0 ? (
            <div className="row mt-3">
              <div className="col-sm-6">
                <div className="form-group row align-items-center">
                  <ColName>Skonfiguruj prowizję dla</ColName>
                  <div className="col-sm-6">
                    <SelectButton
                      buttonOnClick={() => props.clear(props.formName, 'category')}
                      formName={props.formName}
                      options={props.categories}
                      value={props.categorySelected}
                      name="category"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row mt-3 mb-3">
              <div className="col-sm-12">
                <span>Brak zdefiniowanych ról systemowych, nie ma możliwości skonfigurowania prowizji.</span>
              </div>
            </div>
          )}
          {props.categorySelected ? (
            <>
              <div className="row mt-3">
                <div className="col-sm-2 font-weight-bold grey">Typ</div>
                <div className="col-sm-2 font-weight-bold grey">Jednostka</div>
                <div className="col-sm-2 font-weight-bold grey">Wartość maksymalna</div>
                <div className="col-sm-2 font-weight-bold grey">Waluta</div>
                <div className="col-sm-2 font-weight-bold grey">Domyślna w ofercie</div>
              </div>
              <div className="row">
                <div className="col-sm-2 grey">
                  <Select
                    disabled={props.selectDisabled || false}
                    formName={props.formName}
                    options={props.commission_types}
                    name="commission_type"
                    onChange={handleChangeCommissionType}
                  />
                </div>
                <div className="col-sm-2 grey">
                  {isMounted && (
                    <Select
                      noOptionsMessage='Wybierz typ'
                      disabled={props.selectDisabled || false}
                      formName={props.formName}
                      options={props.commission_types
                        ? props.commission_types.find((x) => Number(x.id) === Number(props.commissionTypeSelected))
                          ? props.commission_types.find((x) => Number(x.id) === Number(props.commissionTypeSelected)).units.map((v) => ({ value: v.id, name: v.name }))
                          : []
                        : []}
                      name="unit"
                    />
                  )}
                </div>
                <div className="col-sm-2 grey">
                  <Input formName={props.formName} type="text" name="max_value" />
                </div>
                <div className="col-sm-2 grey">
                  <Select
                    disabled={props.selectDisabled || false}
                    formName={props.formName}
                    options={props.currencies}
                    name="currency_id"
                  />
                </div>
                <div className="col-sm-2 grey d-flex align-items-center">
                  <Checkbox label=" " formName={props.formName} name="is_default_in_offer" />
                </div>
              </div>

              {props.withButtons ? (
                <div className="row">
                  <div className="mt-4 col-sm-12 text-center">
                    <button
                      type="button"
                      onClick={props.onCancelButtonClick}
                      className="btn btn-primary-outline mr-2 font-weight-light"
                      style={{ color: '#4B4E55' }}
                    >
                      Anuluj
                    </button>
                    <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                      Zapisz
                    </button>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
