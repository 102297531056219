import FormActions from 'actions/forms';
import Card from 'components/Layout/Card';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import RepresentationForm from '../../Forms/Representation';

export default props => {
  const dispatch = useDispatch();
  const formName = 'RepresentationCreatorForm';

  useEffect(() => {
    if (props.populateForm) {
      props.populateForm(formName);
    }
    // eslint-disable-next-line
  }, props);

  const canBeSubmitted = props.forms[formName]?.representation_id && props.forms[formName]?.people_in_company_ids?.length;
  const rows = props.version && props.version.representants ? props.version.representants : [];

  const save = hide => {
    if (rows.length) {
      props.storeInput(formName, 'id', rows[0].id);
    }
    props.saveForm(formName, hide);
  };

  const getRepresentants = r =>
    r
      ? // .filter((i) => i && i.roles.find((j) => j.name === 'Reprezentant'))
      r.map(i => ({
        value: i.id,
        name: i.person.full_name || '-',
        date: i.created_date || '-',
        roles: i.roles.map(j => j.name).join(', ')
      }))
      : [];

  const handleBackLightFields = () => {
    dispatch(FormActions.handleValidErrors(formName, ['representation_id', 'people_in_company_ids']));
  };

  return (
    <Card mode='creator' header='+ Reprezentacja' onSaveClick={() => save(true)} isBlocked={!canBeSubmitted} onBlockedMessage={handleBackLightFields}>
      <RepresentationForm
        populate={() => props.populateForm(formName)}
        forms={props.forms}
        representations={props.representations}
        representants={getRepresentants(props.representants)}
        preloadedForm
        visibleOnMount
        visible
        clear={props.clear}
        formName={formName}
        onSelect={() => save(true)}
        onCancelButtonClick={() => props.toggleForm(formName)}
        formVisible={props.formVisible}
        toggleForm={props.toggleForm}
      />

      {rows.length ? (
        <>
          <div className="row">
            <div className="col-sm-12">
              <div className="row bb">
                <div className="col-sm-3 p-2 grey">Imię i nazwisko</div>
                <div className="col-sm-3 p-2 grey">Rola biznesowa</div>
                <div className="col-sm-3 p-2 grey">Wprowadził</div>
                <div className="col-sm-3 p-2 grey">Data dodania</div>
              </div>
              {rows.map(row => (
                <>
                  {row.people_in_company.map(p => (
                    <RowEditable key={row.identifier}>
                      <div className="col-sm-3 p-2">{p.person ? p.person.full_name : '-'}</div>
                      <div className="col-sm-3 p-2">{p.roles ? p.roles.map(r => r.name).join(', ') : '-'}</div>
                      <div className="col-sm-3 p-2">{p.user_creator ? p.user_creator.full_name : '-'}</div>
                      <div className="col-sm-3 p-2">{p.created_date}</div>
                    </RowEditable>
                  ))}

                  <RowEditable onRemove={() => { props.remove('representants', row.id); }}>
                    <div className="col-sm-4 p-2 grey">Sposób reprezentacji</div>
                    <div className="col-sm-7 p-2">
                      <span
                        className="badge pl-3 pr-3 pt-2 pb-2"
                        style={{ backgroundColor: row.representation ? row.representation.background_color : 'white' }}>
                        {row.representation ? row.representation.name : 'brak'}
                      </span>
                    </div>
                  </RowEditable>
                </>
              ))}
            </div>
          </div>
        </>
      ) : null}
    </Card>
  );
};
