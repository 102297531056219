import Axios from 'axios';
import { cloneDeep, isObject } from 'lodash';
import { getParsedObject } from 'utilities/http';

import HttpClient from '../utilities/http';
import CommonService from './common';

const fetch = filters => HttpClient.get('/persons/', { params: filters });
const fetchPersonDetails = personId => HttpClient.get(`/persons/${personId}/`);

const mutate = data => {
  data = cloneDeep(data);
  Object.keys(data).forEach(key => {
    if (isObject(data[key])) {
      delete data[key];
    }
  });

  return data;
};

const saveForm = async (form, data, personId, forcePut = false) => {
  let u = '/persons/';
  data = cloneDeep(data);

  switch (form) {
    case 'PersonForm':
    case 'PersonsForm':
      if (data.id) {
        data = { ...data, system_roles: data.system_roles.map(s => s.id) };
        data = mutate(data);
      }
      break;
    case 'MainCreatorForm':
      if (data.id) {
        data = {
          ...data,
          id: personId,
          system_roles: data.system_roles.map(s => s.id)
        };
        data = mutate(data);
      }
      break;
    case 'AddressForm':
      u = `${u + personId}/addresses/`;
      break;

    case 'NotesForm':
    case 'NotesCreatorForm':
    case 'NotesCreatorEditForm':
      u = `${u + personId}/notes/`;
      break;
    case 'CommissionForm':
    case 'CommissionsCreatorForm':
    case 'CommissionsCreatorEditForm':
      u = `${u + personId}/commissions/`;
      break;
    case 'RolesForm':
      return HttpClient.post(`/companies/${data.company.id}/person/`, getParsedObject({ person_id: personId, roles_ids: data.roles_ids }));
    case 'SystemRolesForm':
      data = {
        id: data.id,
        first_name: data.first_name,
        last_name: data.last_name,
        system_roles: data.system_roles ? data.system_roles.map(i => i.id).concat([Number(data.role)]) : [Number(data.role)]
      };
      delete data.role;
      break;
    case 'ConsentForm':
    case 'ConsentsCreatorForm':
    case 'ConsentsCreatorEditForm':
      data = {
        date: data.date,
        consent_source_id: data.consent_source_id,
        consent_template_id: data.consent_template_id,
        administrator_id: data.administrator.id,
        representator_id: data.representator.id,
        data_inspector_id: data.data_inspector.id
      };
      data.ip_address = await CommonService.getClientIP();

      u = `${u + personId}/consents/`;
      break;

    case 'ContactForm':
    case 'SocialMediaForm':
    case 'SocialMediaCreatorForm':
    case 'ContactsCreatorForm':
    case 'ContactsCreatorEditForm':
    case 'SocialMediaEditForm':
    case 'SocialMediaCreatorEditForm':
      if (data.id) {
        data = {
          is_active: data.is_active,
          value: data.value,
          id: data.id,
          country_calling_code_id: data.country_calling_code_id
        };
      } else {
        data = {
          is_active: data.is_active,
          value: data.value,
          type: data.type,
          country_calling_code_id: data.country_calling_code_id
        };
      }

      u = `${u + personId}/contacts/`;
      break;

    case 'AddressesCreatorForm':
    case 'AddressesCreatorEditForm':
      u = `${u + personId}/addresses/`;
      break;

    case 'RolesCreatorForm':
    case 'RolesCreatorEditForm':
      return HttpClient.post(`/companies/${data.company.value}/person/`, getParsedObject({ person_id: personId, roles_ids: [data.role] }));
    default:
      break;
  }

  u = data.id ? `${u + data.id}/` : u;

  return data.id || forcePut ? HttpClient.put(u, data) : HttpClient.post(u, data);
};

const removePerson = id => HttpClient.delete(`/persons/${id}`);

const removeObject = (oId, pId, object) => HttpClient.delete(`/persons/${pId}/${object}/${oId}/`);

const removeSystemRole = (person, roleId) =>
  HttpClient.put(`/persons/${person.id}/`, {
    first_name: person.first_name,
    last_name: person.last_name,
    system_roles: person.system_roles.filter(i => i.id !== roleId).map(i => i.id)
  });
//
const toggleStatus = (personId, objectId, objectType) => HttpClient.patch(`/persons/${personId}/${objectType}/${objectId}/`);

const create = async data => {
  const request = {
    first_name: data.first_name,
    second_name: data.second_name,
    last_name: data.last_name,
    person_contact: {
      type: 'phone',
      value: data.phone,
      is_active: true,
      country_calling_code_id: data.country_calling_code_id
    },
    consent: {
      ip_address: await CommonService.getClientIP(),
      accepted_date: data.date,
      consent_source_id: Number(data.consent_source_id),
      consent_template_id: Number(data.consent_template_id),
      administrator_id: Number(data.administrator?.id),
      representator_id: Number(data.representator?.id),
      data_inspector_id: Number(data.data_inspector?.id)
    },
    system_roles_ids: data.system_roles_ids ? [data.system_roles_ids] : []
  };

  return HttpClient.post('/persons/creator/', request);
};

const changeConsentStatus = async (consent, personId) =>
  HttpClient.put(`/persons/${personId}/consents/${consent.id}/withdraw/`, { ip_address: await CommonService.getClientIP() });

const addAttachment = (personId, file) => {
  const client = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URI,
    headers: {
      post: {
        Authorization: `Bearer ${localStorage.getItem('sfsaat')}`,
        'Content-type': 'multipart/form-data',
        'Content-Type': 'multipart/form-data'
      }
    }
  });

  return client.post(`persons/${personId}/attachments/`, file);
};

const saveCommission = (personId, data) => {
  const method = data.id ? 'put' : 'post';
  const suffix = data.id ? `/${data.id}` : '';

  return HttpClient[method](`/persons/${personId}/commissions${suffix}/`, data);
};
const saveParentCommission = (personId, data) => {
  const method = data.id ? 'put' : 'post';
  const suffix = data.id ? `/${data.id}` : '';

  const request = {
    category_id: Number(data.category)
  };

  return HttpClient[method](`/persons/${personId}/parents_commissions${suffix}/`, request);
};
export default {
  create,
  fetch,
  fetchPersonDetails,
  saveForm,
  removePerson,
  removeObject,
  toggleStatus,
  removeSystemRole,
  changeConsentStatus,
  addAttachment,
  saveCommission,
  saveParentCommission
};
