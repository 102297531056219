import ClaimActions from 'actions/claim';
import { BaseModal } from 'components/Layout/OldModal';
import Tooltip from 'components/Layout/Tooltip';
import React from 'react';
import { useDispatch } from 'react-redux';

import { File } from '../../../../components/Forms';
import { OldCard } from '../../../../components/Layout';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import Documents from '../../Forms/Documents';
import CommonActions from 'actions/common';
import FormActions from 'actions/forms';

export default props => {
  const dispatch = useDispatch();
  const manageFormName = 'DocumentsManageForm';

  const header = (
    <a
      role="button"
      style={{ fontSize: '24px' }}
      className="collapsed creator__btn-icon"
      data-toggle="collapse"
      href="#warunkiDodatkowe"
      aria-expanded="false"
      aria-controls="collapseExample">
      + Dokumenty do wykupu
    </a>
  );

  const customCreateSection = (
    <>
      <i className="fas fa-plus ml-2 mr-2 creator__btn-icon" onClick={() => props.edit(manageFormName, {})} />
      <i className="fas fa-sync ml-2 mr-2 creator__btn-icon" onClick={() => props.resetDocs()} />
    </>
  );

  const generate = i => {
    const firstAttachment = i.attachments?.[0] ?? {};

    if (firstAttachment.id && !firstAttachment.generated_file) {
      props.edit(manageFormName, i, true);
      props.generate(manageFormName, 'document_to_buy', firstAttachment.id, 'generate', false, true);
    }
  };

  const edit = i => {
    props.edit(manageFormName, {
      ...i,
      dts_form_id: i.dts_form?.id,
      dts_type_id: i.dts_type?.id,
      delivered_dtb_form_id: i.delivered_dtb_form?.id,
      is_factor_pattern: Number(i.is_factor_pattern),
      indefinite_period_of_validity: Number(i.indefinite_period_of_validity)
    });
  };

  const d = props.claim?.documentstobuy ?? {};

  const handleCloseModal = () => {
    dispatch(ClaimActions.fetchDetails(props.claim.id));
    dispatch(FormActions.toggleForm(manageFormName));
  };

  return (
    <OldCard style={{ border: '1px solid #1DB393' }} header={header} id="financialData-card" showIcons customCreateSection={customCreateSection}>
      <div className="collapse show" id="financialData">
        <div className="row bb" style={{ color: '#1DB393' }}>
          <div className="col-12 p-2">Dokumenty wymagane</div>
        </div>
        <div className="row bb" style={{ fontSize: '95%' }}>
          <div className="col-2 p-2 br grey">Nazwa dokumentu</div>
          <div className="col-2 p-2 br grey">Oczekiwana forma dokumentu</div>
          <div className="col-2 p-2 br grey">Wymagania formalne</div>
          <div className="col-1 p-2 br grey">Odstępstwo</div>
          <div className="col-3 p-2 br grey">Nr dokumentu</div>
          <div className="col-2 p-2 grey">Status</div>
        </div>
        {d.docs?.map(i => (
          <RowEditable onRemove={() => props.remove('document_to_buy', i.id)}>
            <div className="col-2 p-2 br">{i.dtb_type?.name}</div>
            <div className="col-2 p-2 br">{i.expected_dtb_form?.name || '-'}</div>
            <div className="col-2 p-2 br">{i.formal_expectation?.name}</div>
            <div className="col-1 p-2 br">
              {i?.delivered_dtb_form?.id !== i?.expected_dtb_form?.id ? <span style={{ color: 'red' }}>tak</span> : 'nie'}
              {/*{i.is_deviated ? <span style={{ color: 'red' }}>tak</span> : 'nie'}*/}
            </div>
            <div className="col-3 p-2 br"><Tooltip label={i.identifier} /></div>
            <div className="col-2 p-2 br">
              {props.agreementData?.document_agreement_statuses?.[i.status]}
              <button type="button" onClick={() => edit(i)} className=" ml-3 mr-2">
                ...
              </button>
              {i.attachments?.[0].generated_file ? (
                <button
                  type="button"
                  className="mr-2"
                  onClick={() => {
                    window.open(process.env.REACT_APP_BACKEND_LINK + i.attachments?.[0].generated_file, '_blank');
                  }}>
                  Podgląd
                </button>
              ) : i.is_factor_pattern ? (
                <button type="button" onClick={() => generate(i)} className="mr-2">
                  Generuj
                </button>
              ) : null}
              {i.status === 1 ? (
                <button className="ml-1" type="button" onClick={() => props.archive('document_to_buy', i.id)}>
                  Archiwizuj
                </button>
              ) : null}
            </div>
          </RowEditable>
        ))}
        <div className="row bb" style={{ color: '#1DB393' }}>
          <div className="col-12 p-2">Dokumenty pozostałe</div>
        </div>
        <div className="row bb">
          <div className="col-4 p-2 br">Nazwa pliku</div>
          <div className="col-2 p-2 br">Autor</div>
          <div className="col-2 p-2 br">Data utworzenia</div>
          <div className="col-2 p-2">Akcje</div>
        </div>
        {d.attachments?.map(i => (
          <RowEditable>
            <div className="col-4 p-2 br">{i.name}</div>
            <div className="col-2 p-2 br">{i.author?.full_name}</div>
            <div className="col-2 p-2 br">{i.created_date}</div>
            <div className="col-2 p-2">
              <button
                type="button"
                className=""
                onClick={() => {
                  window.open(process.env.REACT_APP_BACKEND_LINK + i.file, '_blank');
                }}>
                Podgląd
              </button>
              <button
                type="button"
                className=" ml-1"
                onClick={() => {
                  props.remove('attachments', i.id);
                }}>
                Usuń
              </button>
            </div>
          </RowEditable>
        ))}

        <div className="row mt-3 mb-3">
          <div className="col-sm-12">
            <File
              onSuccess={() => {
                props.refresh();
              }}
              url={`claims/${props.claim?.id}/attachments/`}
              field="attachments"
              formName={props.formName}
            />
          </div>
        </div>
      </div>

      <BaseModal
        size="extralarge"
        visible={props.formVisible(manageFormName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" /> <span>Generowanie dokumentu</span>
          </div>
        }
        footer="Aby zapisać dokument wciśnij Zapisz."
        onClickAway={handleCloseModal}>
        <Documents
          forms={props.forms}
          onClose={handleCloseModal}
          withButtons
          formVisible={props.formVisible}
          toggleForm={props.toggleForm}
          formName={manageFormName}
          onCancelButtonClick={() => props.toggleForm(manageFormName)}
          proposalData={props.proposalData}
          offerData={props.offerData}
          claim={props.claim}
          version={props.version}
          attachmentCallback={() => {}}
          common={props.common}
          removeAttachment={props.remove}
          storeInput={props.storeInput}
          generate={props.generate}
        />
      </BaseModal>
    </OldCard>
  );
};
