import moment from 'moment';

const persons = service => ({
  getList: async ({ term, modifiedDate, orderBy, direction, page = 1, limit = 10 } = {}) =>
    service.get('persons/', {
      params: {
        page,
        limit,
        term,
        direction,
        order_by: orderBy,
        modified_date: modifiedDate
      }
    }),
  getPerson: async id => service.get(`persons/${id}/`),
  addPerson: async data => {
    const parsedData = {
      first_name: data.first_name,
      second_name: data.second_name,
      last_name: data.last_name,
      sex: data.gender.id,
      consent: {
        ip_address: '192.168.0.1',
        accepted_date: moment(data.accepted_date).format('DD.MM.YYYY'),
        administrator_id: Number(data.administrator),
        data_inspector_id: Number(data.data_inspector),
        representator_id: Number(data.representator),
        consent_source_id: data.consent_source.id,
        consent_template_id: data.consent_template.id
      },
      person_contact: {
        country_calling_code_id: data.country_calling_code_id.id,
        value: data.phone,
        type: 'phone'
      },
      system_roles_ids: data.system_roles_id ? [data.system_roles_id.id] : []
    };

    return service.post('persons/creator/', parsedData);
  },
  removePerson: async ({ id }) => service.delete(`persons/${id}/`)
});

export default persons;
