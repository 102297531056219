import React from 'react';

import { Form, Input, Select } from '../../../components/Forms';

export const AdministratorForm = (props) => {
  const freqs = props.freqs ? props.freqs.map((f) => ({ ...f, value: f.id })) : [];

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <span className="col-sm-3 col-form-label label-right mb-15">
                  Kwota ryczałtu netto
                </span>
                <div className="col-sm-3">
                  <Input formName={props.formName} type="text" name="oa_lump_sum_amount" />
                </div>
                <div className="col-sm-2 mb-15">
                  <span className="mr-3" style={{ fontWeight: '600', color: 'rgb(75, 78, 85)', fontSize: '14px' }}>
                    {props?.calculation?.currency?.name ?? 'brak'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <span className="col-sm-3 col-form-label label-right mb-15">
                  Okres rozliczeniowy ryczałtu
                </span>
                <div className="col-sm-3">
                  <Select formName={props.formName} options={freqs} name="oa_lump_sum_freq" />
                </div>
              </div>
            </div>
          </div>

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancelButtonClick}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}
                >
                  Anuluj
                </button>
                <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
