import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RowEditable } from '../../../../../components/Layout/RowEditable';
import { getGroupOfErrors, parseMoney } from '../../../../../utilities/helpers';
import LiabilitiesForm from '../../../Forms/Liabilities/Factoring';
import FormActions from 'actions/forms';

export default props => {
  const dispatch = useDispatch();
  const formName = 'FactoringLiabilitiesCreatorForm';
  const modalFormName = 'FactoringLiabilitiesCreatorEditForm';
  const { factor_companies } = useSelector(state => state.Common.proposalData);

  useEffect(() => {
    if (props.populateForm) {
      props.populateForm(formName);
    }
    // eslint-disable-next-line
  }, props);

  const canBeSubmitted =
    props.forms[formName] &&
    props.forms[formName].factor_id &&
    props.forms[formName].limit_amount &&
    props.forms[formName].current_debt &&
    props.forms[formName].expiration_date;

  const rows = props.version && props.version.factoring_obligations ? props.version.factoring_obligations : [];

  const handleBackLightFields = () => {
    dispatch(FormActions.handleValidErrors(formName, ['factor_id', 'limit_amount', 'current_debt', 'expiration_date']));
  };

  return (
    <Card mode='creator' header='+ Zobowiązania faktoringowe' onSaveClick={() => props.saveForm(formName, true)} isBlocked={!canBeSubmitted} onBlockedMessage={handleBackLightFields}>
      <LiabilitiesForm
        proposalData={props.proposalData}
        factors={factor_companies}
        populate={() => props.populateForm(formName)}
        forms={props.forms}
        currencies={props.currencies}
        preloadedForm
        visibleOnMount
        visible
        clear={props.clear}
        formName={formName}
        submitForm={() => props.saveForm(formName)}
        onCancelButtonClick={() => props.toggleForm(formName)}
      />

      {rows.length ? (
        <div className="row bt">
          <div className="col-sm-12">
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Faktor</div>
              <div className="col-sm-3 p-2 br grey">Kwota limitu</div>
              <div className="col-sm-2 p-2 br grey">Aktualne zadłużenie</div>
              <div className="col-sm-2 p-2 br grey">Data wygaśnięcia</div>
              <div className="col-sm-2 p-2 grey">Cesja</div>
            </div>
            {rows.map(row => (
              <RowEditable
                key={row.identifier}
                onEdit={() => {
                  const editableRow = {
                    factor_id: row.factor ? row.factor.id : null,
                    id: row.id,
                    limit_amount: row.limit_amount,
                    current_debt: row.current_debt,
                    expiration_date: row.expiration_date,
                    transfer: row.transfer ? row.transfer.id : 0
                  };
                  delete editableRow.lessor;
                  delete editableRow.leasing_subject;
                  props.edit(editableRow, modalFormName);
                }}
                onRemove={() => {
                  props.remove('factoring_obligation', row.id);
                }}>
                <div className="col-sm-3 p-2 br ">{row.factor ? row.factor.name : '-'}</div>
                <div className="col-sm-3 p-2 br ">{parseMoney(row.limit_amount)}</div>
                <div className="col-sm-2 p-2 br ">{row.current_debt}</div>
                <div className="col-sm-2 p-2 br ">{row.expiration_date}</div>
                <div className="col-sm-2 p-2 ">{row.transfer ? row.transfer.name : '-'}</div>
              </RowEditable>
            ))}
          </div>
        </div>
      ) : null}

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Zobowiązania faktoringowe</span>
          </div>
        }
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}>
        <LiabilitiesForm
          proposalData={props.proposalData}
          withButtons
          factors={factor_companies}
          populate={() => props.populateForm(modalFormName)}
          forms={props.forms}
          currencies={props.currencies}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(modalFormName)}
          formName={modalFormName}
          submitForm={() => props.saveForm(modalFormName)}
          onCancelButtonClick={() => props.toggleForm(modalFormName)}
        />
      </BaseModal>
    </Card>
  );
};
