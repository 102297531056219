import Card from 'components/Layout/Card';
import React, { useState } from 'react';

import { Selectable } from '../../../../components/Forms';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parseBan } from '../../../../utilities/helpers';

export default props => {
  const [active] = useState(true);

  const columns = [
    { name: 'Numer rachunku', getter: 'number', sortable: 1 },
    { name: 'Waluta', getter: 'currency.name', sortable: 1 },
    { name: 'Typ rachunku', getter: 'bank_account_type.name', sortable: 1 },
    { name: 'Nazwa banku', getter: 'bank_name', sortable: 1 },
    { name: 'Data dodania', getter: 'created_date', sortable: 1 },
    { name: 'Dodał', getter: 'user_creator.full_name', sortable: 1 }
  ];

  const title = (
    <div className="row mt-2 align-items-center">
      <div className="col-sm-1 p-2 text-right grey" style={{ fontWeight: '600' }}>
        Klient
      </div>
      <div className="col-sm-5 p-2" style={{ fontWeight: '600' }}>
        {props.agreement?.client?.name ?? '-'}
      </div>
      <div className="col-sm-3 p-2 text-right grey" />
    </div>
  );

  const bankAccounts = props.agreement?.client?.bank_accounts
    ?.filter(x => !props.version?.client_bank_accounts?.find(i => i.number === x.number))
    .filter(i => (active ? i.is_active : true))
    .map(i => ({ ...i, number: parseBan(i) }));

  return (
    <Card header='Rachunki klienta'>
      <div>
        <div className="row mt-2 align-items-center mb-2" style={{ fontWeight: '600', color: '#878F9A' }}>
          <div className="col-sm-1 p-2 text-right">Klient</div>
          <div className="col-sm-10">
            <span className="mr-3" style={{ fontWeight: '600', color: '#4B4E55' }}>
              {props.agreement?.client?.name ?? '-'}
            </span>
          </div>
        </div>
        <div className="row align-items-center mb-2" style={{ fontWeight: '600', color: '#878F9A' }}>
          <div className="col-sm-1 p-2 text-right">Rachunki</div>
          <div className="col-sm-10">
            <span className="mr-3" style={{ fontWeight: '300', color: '#4B4E55' }}>
              Rozliczeniowy
            </span>

            <button type="button" className="ml-4" onClick={() => props.toggleForm('client_accounts')}>
              Dodaj
            </button>
          </div>
        </div>
        <div className="row bt bb">
          <div className="col-3 p-2 grey br">Numer rachunku</div>
          <div className="col-1 p-2 grey br">Waluta rachunku</div>
          <div className="col-2 p-2 grey br">Nazwa rachunku</div>
          <div className="col-2 p-2 grey br">Bank</div>
          <div className="col-1 p-2 grey br">Data</div>
          <div className="col-2 p-2 grey">Dodający</div>
        </div>
        {props.version?.client_bank_accounts?.map(i => (
          <RowEditable onRemove={() => props.remove('client_bank_accounts', i.id)}>
            <div className="col-sm-3 p-2 br">{parseBan(i)}</div>
            <div className="col-sm-1 p-2 br">{i.currency?.name}</div>
            <div className="col-sm-2 p-2 br">{i.bank_alias?.name}</div>
            <div className="col-sm-2 p-2 br">{i.bank_name}</div>
            <div className="col-sm-1 p-2 br">{i.created_date}</div>
            <div className="col-sm-2 p-2">{i.user_creator?.full_name}</div>
          </RowEditable>
        ))}
      </div>

      <Selectable
        multiple
        fetch={() => {}}
        // fetch={props.fetch}
        visible={props.formVisible('client_accounts')}
        formName="client_accounts"
        field="client_accounts"
        columns={columns}
        title={title}
        options={bankAccounts}
        onClickAway={() => props.toggleForm('client_accounts')}
        header="Wybierz rachunek klienta"
        footer="Aby zapisać wciśnij ZATWIERDŹ"
        onSelected={() => {
          props.saveForm('client_accounts', false);
          props.toggleForm('client_accounts');
        }}
      />
    </Card>
  );
};
