import FormActions from 'actions/forms';
import { ColName } from 'components/Forms/Col';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Checkbox, Datepicker,
  Form, Input, Select } from '../../../components/Forms';

const mapper = (i) => (i ? i.map((x) => ({ value: x.id, name: x.name })) : []);

export default (props) => {
  const dispatch = useDispatch();
  const { proposal, version } = useSelector(state => state.Proposal);

  const d = props.proposalData || {};
  const form = props.forms[props.formName] || {};

  const ratingTypes = mapper(d.rating_types);
  const ratingClasses = form.rating_type_id && d.rating_types.find((i) => i.id === form.rating_type_id) ? mapper(d.rating_types.find((i) => i.id === form.rating_type_id).rating_classes) : [];

  const informationTypes = mapper(d.information_agencies_data);
  const informationClasses = form.information_agency_id && d.information_agencies_data.find((i) => i.id === form.information_agency_id)
    ? mapper(d.information_agencies_data.find((i) => i.id === form.information_agency_id).information_agency_classes)
    : [];

  const ratingAgencies = mapper(d.rating_agencies_data);
  const ratingAgencyScores = form.rating_agency_id && d.rating_agencies_data.find((i) => i.id === form.rating_agency_id)
    ? mapper(d.rating_agencies_data.find((i) => i.id === form.rating_agency_id).rating_agency_scores)
    : [];

  const pmis = Array
    .from({ length: 100 }, (v, o) => o + 1)
    .map((i) => ({ value: i, name: i }));

  const categories = mapper(d.factor_categories);

  useEffect(() => {
    if(version?.category?.id) {
      dispatch(FormActions.storeInput(props.formName, 'category_id', version?.category?.id));
      dispatch(FormActions.storeInput(props.formName, 'category_id_select', version?.category));
    } else if(proposal?.client?.category?.id) {
      dispatch(FormActions.storeInput(props.formName, 'category_id', proposal?.client?.category?.id));
      dispatch(FormActions.storeInput(props.formName, 'category_id_select', proposal?.client?.category));
    }
  }, [version]);

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row mt-33">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <div className="col-sm-3">
                  <Select formName={props.formName} options={ratingTypes} name="rating_type_id" number onChange={() => props.clear(props.formName, 'rating_class_id')} />
                </div>
                <div className="col-sm-2">
                  <Select formName={props.formName} options={ratingClasses} name="rating_class_id" number />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <ColName className='col-sm-3 text-right'>Wywiadownia / Rating / PMI</ColName>
                <div className="col-sm-3">
                  <Select formName={props.formName} options={informationTypes} name="information_agency_id" number />
                </div>
                <div className="col-sm-2">
                  <Select formName={props.formName} options={informationClasses} name="information_agency_class_id" number />
                </div>
                <div className="col-sm-2">
                  <Select formName={props.formName} options={pmis} name="information_agency_pmi" number />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <ColName className='col-sm-3 text-right'>Limit wywiadowni gospodarczej</ColName>
                <div className="col-sm-3">
                  <Input formName={props.formName} type="number" name="information_agency_limit" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <ColName className='col-sm-3 text-right'>Data raportu wywiadowni</ColName>
                <div className="col-sm-3">
                  <Datepicker formName={props.formName} name="information_agency_report_date" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <ColName className='col-sm-3 text-right'>Kategoria faktoranta</ColName>
                <div className="col-sm-3">
                  <Select formName={props.formName} options={categories} name="category_id" number />
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="row mt-15">
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <ColName className='col-sm-6 text-right'>Nazwa agencji ratingowej</ColName>
                <div className="col-sm-6">
                  <Select formName={props.formName} options={ratingAgencies} name="rating_agency_id" number />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <ColName className='col-sm-10 text-right'>JST / spółka skarbu państwa min 25%</ColName>
                <div className="col-sm-2">
                  <Checkbox label=" " formName={props.formName} name="is_jst_or_corporate_treasury" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <ColName className='col-sm-6 text-right'>Ocena agencji ratingowej</ColName>
                <div className="col-sm-6">
                  <Select formName={props.formName} options={ratingAgencyScores} name="rating_agency_score_id" number />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <ColName className='col-sm-10 text-right'>Podmiot w upadłości lub likwidacji</ColName>
                <div className="col-sm-2">
                  <Checkbox label=" " formName={props.formName} name="is_bankruptcy_or_liquidation" />
                </div>
              </div>
            </div>
          </div>

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancelButtonClick}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}
                >
                  Anuluj
                </button>
                <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
