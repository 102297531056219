import ErrorMessageTemp from 'components/ErrorMessageTemp';
import React from 'react';

export default function TextareaLab({ formName, name, error, onChange, ...props }) {
  return (
    <div className="form-group mb-0">
      <textarea
        name={name}
        className={`form-control ${error ? 'form-control-danger' : ''}`}
        onChange={onChange}
        {...props}
      />
      <ErrorMessageTemp error={error} />
    </div>
  );
}

TextareaLab.defaultProps = {
  placeholder: 'Wpisz komentarz',
  rows: 2
};
