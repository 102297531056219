import React from 'react';

import BooleanCheck from '../../../components/BooleanCheck';
import { Form, Select, Selectable } from '../../../components/Forms';
import { BaseModal } from 'components/Layout/OldModal';
import { RowEditable } from '../../../components/Layout/RowEditable';
import { parseMoney } from '../../../utilities/helpers';
import { TransferForm } from './Transfer';

const editChargeForm = 'SettleIncomeEditChargeForm';
const docsForm = 'SettleIncomeDocsForm';
// const docsFiltersForm = 'SettleIncomeDocsFiltersForm';
const documentsForm = 'SettleIncomeDocumentsForm';
const bucketsForm = 'SettleIncomeBucketsForm';
const bucketFilters = 'SettleIncomeBucketsFiltersForm';

const columns = [
  { name: 'Tytuł', getter: 'title', sortable: 1 },
  { name: 'Termin płatności', getter: 'commitment_date', sortable: 1 },
  {
    name: 'Kategoria',
    getter: 'title',
    sortable: 1,
    mutator: () => 'Nal.'
  },
  { name: 'Wartość brutto', getter: 'gross_value', sortable: 1 },
  { name: 'Do rozliczenia', getter: 'to_pay_value', sortable: 1 },
  { name: 'Rozliczono', getter: 'paid_value', sortable: 1 },
  {
    name: 'Status',
    getter: 'status',
    sortable: 1,
    mutator: x => (x ? 'Rozliczony' : 'Nierozliczony')
  }
];

export const SettleIncomeForm = props => {
  const f = props.forms?.[props.formName] ?? {};
  const payment = props.claim?.subpayments?.find(x => x.id === f?.id) ?? {};

  // const docsColumns = [
  //   { name: 'Identyfikator', getter: 'identifier', sortable: 1 },
  //   { name: 'Termin płatności', getter: 'payment_date', sortable: 1 },
  //   {
  //     name: 'Kategoria',
  //     getter: 'category',
  //     sortable: 1,
  //     mutator: x => props.common.invoiceData.categories[x]
  //   },
  //   { name: 'Wartość brutto', getter: 'gross_value', sortable: 1, mutator: parseMoney },
  //   { name: 'Do rozliczenia', getter: 'to_pay_value', sortable: 1 },
  //   {
  //     name: 'Status',
  //     getter: 'status',
  //     sortable: 1,
  //     mutator: x => props.common.invoiceData.statuses[x]
  //   }
  // ];

  const title = (
    <div className="row d-flex align-items-center">
      <div className="col-sm-6">
        <div className="form-group row mb-0 align-items-center">
          <label htmlFor="example-text-input " className="col-sm-2 col-form-label label-right">
            Klient
          </label>
          <div className="col-sm-10">{props.claim?.client?.name}</div>
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group row mb-0 align-items-center">
          <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
            Wierzytelność
          </label>
          <div className="col-sm-9">{props.claim?.identifier}</div>
        </div>
      </div>
      {/* <div className="col-sm-2"> */}
      {/*  <span className="mr-3"> */}
      {/*    Tylko nierozliczone */}
      {/*  </span> */}
      {/*  <BooleanCheck */}
      {/*    field={props.forms?.[bucketFilters]?.exclude_settled} */}
      {/*    onClick={() => { */}
      {/*      props.storeInput(bucketFilters, 'exclude_settled', props.forms?.[bucketFilters]?.exclude_settled === 1 ? 0 : 1); */}
      {/*      props.fetchBuckets(bucketFilters); */}
      {/*    }} */}
      {/*  /> */}
      {/* </div> */}
    </div>
  );

  const getIncomes = o => {
    let data = o.incomes || [];

    if (o.compensation) {
      data.push(o.compensation);
    }

    return data;
  };

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
              Kwota wpływu
            </label>
            <div className="col-sm-6">
              {parseMoney(payment.amount)} {payment.amount_currency?.name}
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-5 col-form-label label-right">
              Kwota wykupu (VAT)
            </label>
            <div className="col-sm-7">
              {parseMoney(props.claim?.gross_value)} ({parseMoney(props.claim?.vat_value)})
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
              Tytuł wpływu
            </label>
            <div className="col-sm-6">{payment.outgoing_payment_title || payment.identifier}</div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-5 col-form-label label-right">
              Waluta wykupu / VAT
            </label>
            <div className="col-sm-7">
              {props.claim?.purchase_custom_value_currency?.name || props.claim?.purchase_currency?.name}
              {' / '}
              {props.claim?.purchase_custom_vat_value_currency?.name || props.claim?.vat_value_currency?.name}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
              MPP
            </label>
            <div className="col-sm-6">
              <BooleanCheck field={props.claim?.mpp} />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-5 col-form-label label-right">
              Nr wierzytelności / status
            </label>
            <div className="col-sm-7">
              {props.claim?.identifier} {props.common?.claimData?.claim_statuses?.[props.claim?.status]}
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right pl-0">
              Metoda kalkulacji wydatku
            </label>
            <div className="col-sm-6  d-flex align-items-center">
              <label className="custom-control custom-radio d-inline-flex align-items-center">
                <input
                  id="radio-0-1"
                  name="radio-0-1"
                  type="radio"
                  className="custom-control-input mr-3"
                  onChange={() => {}}
                  disabled
                  checked={false}
                />
                <span className="custom-control-label radio-label"> auto</span>
              </label>
              <label className="custom-control custom-radio d-inline-flex align-items-center ml-4">
                <input
                  id="radio-1-2"
                  name="radio-1-2"
                  type="radio"
                  className="custom-control-input mr-3"
                  onChange={() => {}}
                  disabled
                  checked={false}
                />
                <span className="custom-control-label  radio-label"> auto bez potrąceń</span>
              </label>
              <label className="custom-control custom-radio d-inline-flex align-items-center ml-4">
                <input id="radio-1-2" name="radio-1-2" type="radio" className="custom-control-input mr-3" onChange={() => {}} disabled checked />
                <span className="custom-control-label  radio-label"> własna</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
              Metoda kalkulacji VAT
            </label>

            <div className="col-sm-6  d-flex align-items-center">
              <label className="custom-control custom-radio d-inline-flex align-items-center">
                <input id="radio-0-1" name="radio-0-1" type="radio" className="custom-control-input mr-3" onChange={() => {}} disabled checked />
                <span className="custom-control-label radio-label"> proporcjonalnie</span>
              </label>
              <label className="custom-control custom-radio d-inline-flex align-items-center ml-4">
                <input
                  id="radio-1-2"
                  name="radio-1-2"
                  type="radio"
                  className="custom-control-input mr-3"
                  onChange={() => {}}
                  disabled
                  checked={false}
                />
                <span className="custom-control-label  radio-label"> Tylko VAT</span>
              </label>
              <label className="custom-control custom-radio d-inline-flex align-items-center ml-4">
                <input
                  id="radio-1-2"
                  name="radio-1-2"
                  type="radio"
                  className="custom-control-input mr-3"
                  onChange={() => {}}
                  disabled
                  checked={false}
                />
                <span className="custom-control-label  radio-label"> bez VAT</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row bt bb mt-3  align-items-center">
        <div className="col-2 p-2 br grey">Podstawa wpływu / rozliczenia wpływu</div>
        <div className="col-1 p-2 br grey">Termin płatności</div>
        <div className="col-1 p-2 br grey">Do rozliczenia</div>
        <div className="col-1 p-2 br grey">Rozliczono</div>
        <div className="col-1 p-2 br grey">Saldo / kwota w walucie</div>
        <div className="col-1 p-2 br grey">Waluta dokumentu</div>
        <div className="col-1 p-2 br grey">Kurs</div>
        <div className="col-1 p-2 br grey">Data rozliczenia</div>
        <div className="col-1 p-2 br grey">Metoda płatności</div>
        <div className="col-2 p-2 grey">Status</div>
      </div>
      <div className="row bb ">
        <div className="col-2 p-2 br text-themecolor">{payment.outgoing_payment_title || payment.identifier || '-'}</div>
        <div className="col-1 p-2 br text-themecolor">{payment.invoice_date}</div>
        <div className="col-1 p-2 br text-themecolor">{parseMoney(payment.to_pay_value)}</div>
        <div className="col-1 p-2 br text-themecolor">{parseMoney(payment.paid_value)}</div>
        <div className="col-1 p-2 br text-themecolor">-</div>
        <div className="col-1 p-2 br text-themecolor">{payment.gross_transfer_value_currency?.name}</div>
        <div className="col-1 p-2 br text-themecolor">-</div>
        <div className="col-1 p-2 br text-themecolor">-</div>
        <div className="col-1 p-2 br text-themecolor">-{/* <button type="button" onClick={() => {}}>Skompensuj</button> */}</div>
        <div className="col-2 p-2">{props.common?.paymentData?.statuses?.[payment.status]}</div>
      </div>
      {getIncomes(payment).map(p => (
        <RowEditable key={p.id} onRemove={() => props.removeChargeFromPayment(payment.id, p.id)}>
          <div className="col-2 p-2 br ">{p.charge?.title ?? p.identifier}</div>
          <div className="col-1 p-2 br ">{p.due_date || p.created_date}</div>
          <div className="col-1 p-2 br "> </div>
          <div className="col-1 p-2 br ">{parseMoney(p.paid_value)}</div>
          <div className="col-1 p-2 br ">
            {parseMoney(p.amount || p.income_value)}

            {p.is_settled ? (
              ''
            ) : (
              <button
                type="button"
                className="ml-2"
                onClick={() => {
                  props.edit(editChargeForm, { net_transfer_value: (p.income_value ?? p.amount), compensate: p.identifier?.[0] === 'K', chargeId: p.id, paymentId: payment.id, subpaymentId:  p.identifier?.[0] === 'K' ? p.id : payment.id });
                }}>
                ...
              </button>
            )}
          </div>
          <div className="col-1 p-2 br ">{p.income_value_currency?.name ?? p.amount_currency?.name}</div>
          <div className="col-1 p-2 br ">{props.claim?.exchange_rate}</div>
          <div className="col-1 p-2 br ">{p.created_date}</div>
          <div className="col-1 p-2 br ">
            {p.is_settled ? (
              '-'
            ) : (
              <button type="button" onClick={() => props.compensate(payment.id, p.id, p.identifier?.[0] === 'K')}>
                Skompensuj
              </button>
            )}
          </div>
          <div className="col-2 p-2">{p.is_settled ? 'Rozliczony' : 'Nierozliczony'}</div>
        </RowEditable>
      ))}

      <div className="row mt-3">
        <div className="col-sm-4">
          {/*<button type="button" onClick={() => props.toggleForm(docsForm)}>Dokument do rozliczenia</button>*/}
          <button
            type="button"
            onClick={() => {
              props.storeInput(props.documentsFilters, 'client_id', props.claim?.client);

              props.fetchDocuments(props.documentsFilters);
              props.toggleForm(documentsForm);
            }}
            className="ml-2">
            Dokument do rozliczenia
          </button>
          {payment.identifier?.[0] === 'K' ? <button
            type="button"
            onClick={() => {
              props.storeInput(props.docsFilters, 'client_id', f.linked?.buyer);
              props.storeInput(props.docsFilters, 'is_settled', 0);

              props.fetchDocs();
              props.toggleForm(docsForm);
            }}
            className="ml-2">
            Kompensata
          </button> : null}
          <button
            type="button"
            onClick={() => {
              const params = payment.is_vat ? { is_vat: 1 } : {};
              props.fetchBuckets(params);
              props.toggleForm(bucketsForm);
            }}
            className="ml-2">
            Kubeczki
          </button>
        </div>
        <div className="col-sm-6">
          <label htmlFor="example-text-input " className=" label-right">
            Nadwyżka do rozliczenia:{' '}
            <span style={{ color: 'black' }}>
              {parseMoney(payment.to_pay_value)} {payment.commitment_value_currency?.name}
            </span>
          </label>
        </div>
      </div>

      {props.withButtons ? (
        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button type="button" onClick={() => props.onCancelButtonClick()} className="btn btn-primary ml-2  font-weight-light">
              Zamknij
            </button>
          </div>
        </div>
      ) : null}

      <Selectable
        noTermSearching
        filtersForm={props.docsFilters}
        fetch={props.fetchDocs}
        visible={props.formVisible(docsForm)}
        formName={docsForm}
        field="docs"
        title={
          (
            <div className="row mt-1 align-items-center mb-1">
              <div className="col-sm-1 p-2 text-right grey">Klient</div>
              <div className="col-sm-9 p-2 d-flex align-items-center">
                {props.forms?.[props.docsFilters]?.client_id?.name ?? '-'}
                <button type="button" className="ml-2" onClick={() => {
                  props.fetchClients();
                  props.toggleForm('settlements_client');
                }}>Wybierz</button>
              </div>
              <div className="col-sm-2 p-2 text-right grey d-flex align-items-center">
                <Select className="w-100" formName={props.docsFilters} options={[
                  { value: null, name: 'Wszystkie' },
                  { value: 1, name: 'Tylko rozliczone' },
                  { value: 0, name: 'Tylko nierozliczone' }
                ]} name="is_settled"
                onChange={() => {props.fetchDocs();}}
                />
                {/*Tylko nierozliczone*/}
                {/*<BooleanCheck className="ml-2" field={!props.forms?.[props.docsFilters]?.is_settled} onClick={() => {*/}
                {/*  props.storeInput(props.docsFilters, 'is_settled', !props.forms?.[props.docsFilters]?.is_settled);*/}
                {/*  props.fetchDocs();*/}
                {/*}}/>*/}
              </div>
            </div>
          )
        }
        columns={[
          { name: 'Tytuł', getter: 'identifier', sortable: 1 },
          { name: 'Dokument', getter: 'test', sortable: 1 },
          // { name: 'Kategoria', getter: 'number', sortable: 1 },
          {
            name: 'Termin płatności',
            getter: 'payment_date',
            sortable: 1
          },
          // { name: 'Rozliczono', getter: 'number', sortable: 1 },
          { name: 'Kategoria', getter: 'context', sortable: 1 },
          {
            name: 'Wartość brutto',
            getter: 'gross_value',
            mutator: parseMoney,
            sortable: 1
          }, {
            name: 'Do rozliczenia',
            getter: 'to_pay_value',
            mutator: parseMoney,
            sortable: 1
          }, {
            name: 'Status',
            getter: 'status',
            sortable: 1
          }
        ]}
        options={props.docs}
        onClickAway={() => props.toggleForm(docsForm)}
        header="Wybierz dokument do rozliczenia"
        onSelected={() => props.sendCompensation(docsForm)}
      />
      <Selectable
        noTermSearching
        filtersForm={props.documentsFilters}
        fetch={props.fetchDocuments}
        visible={props.formVisible(documentsForm)}
        formName={documentsForm}
        field="docs"
        title={
          (
            <div className="row mt-1 align-items-center mb-1">
              <div className="col-sm-1 p-2 text-right grey">Klient</div>
              <div className="col-sm-9 p-2 d-flex align-items-center">
                {props.forms?.[props.documentsFilters]?.client_id?.name ?? '-'}
                <button type="button" className="ml-2" onClick={() => {
                  props.fetchClients();
                  props.toggleForm('documents_client');
                }}>Wybierz</button>
              </div>
              {/*<div className="col-sm-2 p-2 text-right grey d-flex align-items-center">*/}
              {/*  <Select className="w-100" formName={props.documentsFilters} options={[*/}
              {/*    {value: null, name: "Wszystkie"},*/}
              {/*    {value: 1, name: "Tylko rozliczone"},*/}
              {/*    {value: 0, name: "Tylko nierozliczone"}*/}
              {/*  ]} name="is_settled"*/}
              {/*          onChange={() => {props.fetchDocuments(props.documentsFilters)}}*/}
              {/*  />*/}
              {/*  /!*Tylko nierozliczone*!/*/}
              {/*  /!*<BooleanCheck className="ml-2" field={!props.forms?.[props.docsFilters]?.is_settled} onClick={() => {*!/*/}
              {/*  /!*  props.storeInput(props.docsFilters, 'is_settled', !props.forms?.[props.docsFilters]?.is_settled);*!/*/}
              {/*  /!*  props.fetchDocs();*!/*/}
              {/*  /!*}}/>*!/*/}
              {/*</div>*/}
            </div>
          )
        }
        columns={[
          { name: 'Identyfikator', getter: 'identifier', sortable: 1 },
          // { name: 'Dokument', getter: 'test', sortable: 1 },
          // { name: 'Kategoria', getter: 'number', sortable: 1 },
          {
            name: 'Termin płatności',
            getter: 'payment_date',
            sortable: 1
          },
          // { name: 'Rozliczono', getter: 'number', sortable: 1 },
          { name: 'Kategoria', getter: 'category', sortable: 1, mutator: x => {
            return props.common?.invoiceData?.categories?.[x];
          } },
          {
            name: 'Wartość brutto',
            getter: 'gross_value',
            mutator: parseMoney,
            sortable: 1
          }, {
            name: 'Do rozliczenia',
            getter: 'to_pay_value',
            mutator: parseMoney,
            sortable: 1
          }, {
            name: 'Status',
            getter: 'status',
            sortable: 1,
            mutator: x => {
              return props.common?.invoiceData?.statuses?.[x];
            }
          }
        ]}
        options={props.documents}
        onClickAway={() => props.toggleForm(documentsForm)}
        header="Wybierz dokument do rozliczenia"
        onSelected={() => {
          const s = props.forms?.[documentsForm];
          if (s.docs) {
            props.linkChargeWithPayment(payment.id, s.docs.id, true);
          }
        }}
      />

      <Selectable
        filtersForm={props.clientFilters}
        fetch={props.fetchClients}
        visible={props.formVisible('documents_client')}
        formName={props.documentsFilters}
        field="client_id"
        columns={[{ name: 'Nazwa', getter: 'name', sortable: 1 }]}
        options={props.clients}
        onClickAway={() => props.toggleForm('documents_client')}
        header="Wybierz klienta"
        onSelected={() => {
          props.fetchDocuments(props.documentsFilters);
        }}
      />

      <Selectable
        filtersForm={props.clientFilters}
        fetch={props.fetchClients}
        visible={props.formVisible('settlements_client')}
        formName={props.docsFilters}
        field="client_id"
        columns={[{ name: 'Nazwa', getter: 'name', sortable: 1 }]}
        options={props.clients}
        onClickAway={() => props.toggleForm('settlements_client')}
        header="Wybierz klienta"
        onSelected={() => {
          props.fetchDocs(props.docsFilters);
        }}
      />

      <Selectable
        noTermSearching
        filtersForm={bucketFilters}
        fetch={() => props.fetchBuckets(bucketFilters)}
        visible={props.formVisible(bucketsForm)}
        formName={bucketsForm}
        field="charge"
        columns={columns}
        title={title}
        options={props.buckets}
        onClickAway={() => props.toggleForm(bucketsForm)}
        header="Wybierz należność"
        footer="Aby zapisać wciśnij ZATWIERDŹ."
        onSelected={() => {
          const s = props.forms?.[bucketsForm];
          if (s.charge) {
            props.linkChargeWithPayment(payment.id, s.charge.id);
          }
        }}
      />

      <BaseModal
        visible={props.formVisible(editChargeForm)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" /> <span>Edycja przelewu</span>
          </div>
        }
        footer="Aby zapisać dokument wciśnij Zapisz."
        onClickAway={() => props.toggleForm(editChargeForm)}>
        <TransferForm
          noCurrency
          submitForm={() => props.submitForm(editChargeForm, true)}
          withButtons
          formVisible={props.formVisible}
          toggleForm={props.toggleForm}
          formName={editChargeForm}
          forms={props.forms}
          onCancelButtonClick={() => props.toggleForm(editChargeForm)}
          common={props.common}
          clear={props.clear}
          saveText="Zapisz"
        />
      </BaseModal>
    </Form>
  );
};
