import React from 'react';
import { Modal } from 'react-overlays';

const getMaxWidth = size => {
  switch (size) {
    case 'small':
      return '300px';
    case 'medium':
      return '650px';
    case 'large':
      return '900px';
    case 'extralarge':
      return '1500px';
    default:
      return '1200px';
  }
};

export const BaseModal = props => {
  const modalStyle = () => ({
    position: 'fixed',
    width: '100%',
    maxWidth: getMaxWidth(props.size),
    zIndex: 1040,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #7E6F41',
    backgroundColor: 'white'
  });

  const renderBackdrop = p => (
    <div
      {...p}
      style={{
        position: 'fixed',
        zIndex: 1040,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#000',
        opacity: 0.5
      }}
    />
  );

  const h = t => (
    <div className="d-flex align-items-center">
      <i className="fas fa-pencil-alt mr-2" /> <span>{t}</span>
    </div>
  );

  return (
    <Modal onHide={props.onClickAway} style={modalStyle()} aria-labelledby="modal-label" show={props.visible} renderBackdrop={renderBackdrop}>
      <div>
        {props.noHeader ? null : (
          <div className="modal__header d-flex justify-content-between align-items-center">
            <span className="modal__header_span float-left text-left">{props.header || h(props.headerText)}</span>
            <i className="fa fa-question-circle" style={{ fontSize: '28px' }} onClick={() => {}} />
          </div>
        )}
        <div className="modal__content" style={props.contentStyle}>
          {props.children}
        </div>
        <div className="modal__footer">{props.footer}</div>
      </div>
    </Modal>
  );
};
