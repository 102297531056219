import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

export default function LoaderBackdrop() {
  const { isLoader } = useSelector(state => state.Options);
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={isLoader}>
      {/*<CircularProgress color="inherit" />*/}
      <div className='rotate-infinite'>
        <svg xmlns="http://www.w3.org/2000/svg" width="35.749" height="37.801" viewBox="0 0 107.749 109.801"><g transform="translate(0.002 -1.436)"><path fill="#5cc3b2" d="M30.951,237.338c13.511,6.058,32.466-6.8,56.1-23.431,9.36-6.586,16.8-12.555,21.926-16.859a17.67,17.67,0,0,0,5.839-9.151c2.082-8.626-3.428-18.016-10.619-20.921a16.5,16.5,0,0,0-10-.457c-14.941,3.486-25.874,12.9-39.7,22.794,0,0-33.557,23.4-43.417,17.447a5.379,5.379,0,0,0-1.568-.608.334.334,0,0,0-.27.032c-.45.329.152,2.079.363,2.682A60.976,60.976,0,0,0,21.031,228.72C25.543,233.523,27.8,235.924,30.951,237.338Z" transform="translate(-7.512 -136.114)"/><path fill="#5cc3b2" d="M309.287,422.435c-1.935-1.552-8.068,2.827-18.362,10.432-18.664,13.765-33.2,23.807-44.248,24.01a.389.389,0,0,0-.089.766,54.758,54.758,0,0,0,39.671-5.078C302.667,443.051,312.288,424.845,309.287,422.435Z" transform="translate(-203.78 -348.067)"/><path fill="#5cc3b2" d="M.558,46.02c-3.151,19.992,6.729,19.74,36.465-.957C57.5,30.815,69.538,23.427,82.583,19.942c11.406-3.967-1.411-11.708-6.792-14.109C55.64-3.147,47.438.984,18.054,24.2c-2.414,1.908-10.612,9.508-12.019,8.29-.547-.473.277-2.071-.191-2.22-.307-.1-1.317,1.992-2.456,5.209A72.064,72.064,0,0,0,.558,46.02Z" transform="translate(0 0)"/></g></svg>
      </div>
    </Backdrop>
  );
}
