import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import { getIdentifierByType, getKeyByType } from 'containers/Offers/actions';
import React from 'react';
import { useSelector } from 'react-redux';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import { CommissionForm } from '../../Forms/Commissions';

export const Commissions = (props) => {
  const { CommmissionsCreatorForm: form } = useSelector(state => state.Forms.forms);

  const formName = 'CommmissionsCreatorForm';
  const modalFormName = 'CommmissionsEditForm';

  const calculation = props.calculation || {};
  const commissions = calculation.commissions || {};

  const getCommissionsNameByType = (type) => {
    switch (type) {
      case 'intermediaries':
        return 'Pośrednik';
      case 'brokers':
        return 'Broker';
      case 'operational_administrator':
        return 'Administrator operacyjny';
      case 'trade_supervisor':
        return 'Opiekun handlowy';
      case 'billing_manager':
        return 'Opiekun ds. rozliczeń';
      case 'client':
        return 'Klient';
      case 'decision_makers':
        return 'Decydent';
      default:
        return '';
    }
  };

  const getCommissionType = (type) => {
    const foundType = props.types.find((t) => t.id === type);
    return foundType ? foundType.name : 'brak';
  };
  const getCommissionUnit = (type, unit) => {
    const foundType = props.types.find((t) => t.id === type);
    if (foundType) {
      const foundUnit = foundType.units.find((u) => u.id === unit);
      return foundUnit ? foundUnit.name : 'brak';
    }
    return '-';
  };

  const getTypeByParentName = (object, type) => {
    const i = object.business_company ? object.business_company.find((z) => z.name === getKeyByType(type)) : [];
    return i ? i.id : 0;
  };

  const edit = (commission, f, type) => {
    const commissionToEdit = {
      type: commission.role ? commission.role.id : getTypeByParentName(commission.object, type),
      role: getKeyByType(type),
      role_identifier: getIdentifierByType(type),
      beneficiary: commission.object,
      commission_type: commission.commission_type,
      unit: commission.unit,
      id: commission.id,
      max_value: commission.max_value
    };

    props.edit(commissionToEdit, f);
  };

  return (
    <Card mode='creator' header='+ Prowizje' onSaveClick={() => props.saveForm(formName)} isBlocked={!form?.beneficiary}>
      <div className='pr-5 pt-3'>
        <CommissionForm
          objectTypes={props.objectTypes}
          types={props.types}
          fetch={() => props.fetchBeneficiaries(formName)}
          clearBeneficiaries={props.clearBeneficiaries}
          beneficiaries={props.beneficiaries}
          formName={formName}
          offer={props.offer}
          preloadedForm
          visible
          visibleOnMount
          clear={props.clear}
          formVisible={props.formVisible}
          saveForm={() => props.saveForm(formName)}
          onCancel={() => props.onCancel(formName)}
          errors={props.errors[formName]}
          form={props.forms[formName]}
          toggleForm={props.toggleForm}
        />
      </div>
      <div className="mt-3">
        <div className="row">
          <div className="col-sm-12">
            {Object.keys(commissions).map((type) => {
              if (commissions[type].length > 0) {
                return (
                  <>
                    <div className="row bb">
                      <div className="col-sm-12 p-2" style={{ color: '#006BFF' }}>
                        Rola:
                        {' '}
                        {getCommissionsNameByType(type)}
                      </div>
                    </div>
                    <div className="row bb">
                      <div className="col-sm-4 p-2 br grey">Beneficjent</div>
                      <div className="col-sm-3 p-2 br grey">Typ</div>
                      <div className="col-sm-3 p-2 br grey">Sposób liczenia</div>
                      <div className="col-sm-2 p-2 grey">Wysokość</div>
                      {/* <div className="col-sm-1 p-2 grey">Kwota</div> */}
                      {/* <div className="col-sm-1 p-2 grey">Waluta</div> */}
                    </div>
                    {commissions[type].map((commission) => (
                      <RowEditable
                        onEdit={() => edit(commission, modalFormName, type)}
                        onRemove={() => {
                          props.remove(commission);
                        }}
                      >
                        <div className="col-sm-4 p-2 br">{commission && commission.object ? commission.object.name || commission.object.full_name : 'brak'}</div>
                        <div className="col-sm-3 p-2 br">{getCommissionType(commission.commission_type)}</div>
                        <div className="col-sm-3 p-2 br">{getCommissionUnit(commission.commission_type, commission.unit)}</div>
                        <div className="col-sm-2 p-2">{commission.max_value}</div>

                      </RowEditable>
                    ))}
                  </>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Ustawienia prowizji</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}
      >
        <CommissionForm
          isEditState
          objectTypes={props.objectTypes}
          types={props.types}
          fetch={() => props.fetchBeneficiaries(formName)}
          clearBeneficiaries={props.clearBeneficiaries}
          beneficiaries={props.beneficiaries}
          formName={modalFormName}
          offer={props.offer}
          preloadedForm
          visibleOnMount={false}
          clear={props.clear}
          visible={props.formVisible(modalFormName)}
          formVisible={props.formVisible}
          saveForm={() => props.saveForm(modalFormName, true)}
          onCancel={() => props.onCancel(modalFormName)}
          withButtons
          errors={props.errors[modalFormName]}
          form={props.forms[modalFormName]}
          toggleForm={props.toggleForm}
        />
      </BaseModal>
    </Card>
  );
};
