import React from 'react';

import { Selectable } from '../../../../components/Forms';
import { OldCard } from '../../../../components/Layout';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parsePhone } from '../../../../utilities/helpers';

export default (props) => {
  const a = props.claim || {};

  const header = (
    <a
      role="button"
      style={{ fontSize: '24px' }}
      className="collapsed "
      data-toggle="collapse"
      href="#warunkiDodatkowe"
      aria-expanded="false"
      aria-controls="collapseExample"
    >
      Osoby powiązane z umową
    </a>
  );

  const customCreateSection = (
    <>
      {/* <i className="fas fa-save ml-2 mr-2" onClick={() => props.saveForm(formName, false)} /> */}
      <i className="fas fa-question-circle" onClick={() => {}} />
    </>
  );

  const client = {
    data: {
      name: props.claim?.client?.name
    },
    representation: props.claim?.client_representation?.people,
    title: (
      <div className="row mt-2 align-items-center mb-2" style={{ fontWeight: '600' }}>
        <div className="col-sm-1 p-2 text-right grey">
          Klient
        </div>
        <div className="col-sm-1 p-2 text-right">
          {props.claim?.client?.name}
        </div>
      </div>
    ),
    options: props.claim?.client_representation?.persons?.filter(
        (x) => !props.version?.client_representation?.people.find(
            (y) => y.person_in_company?.id === x.id
        )
    )
  };

  const administrator = {
    data: {
      name: props.claim?.agreement?.operational_administrator?.name
    },
    representation: props.claim?.oa_representation?.people,
    title: (
      <div className="row mt-2 align-items-center mb-2" style={{ fontWeight: '600' }}>
        <div className="col-sm-1 p-2 text-right grey">
          Administrator
        </div>
        <div className="col-sm-1 p-2 text-right">
          {props.claim?.agreement?.operational_administrator?.name}
        </div>
      </div>
    ),
    options: props.claim.operational_administrator?.people?.filter(
        (x) => !props.version?.oa_representation?.people.find(
            (y) => y.person_in_company?.id === x.id
        )
    )
  };

  const columns = [
    { name: 'Imię i nazwisko', getter: 'person.full_name', sortable: 1 },
    { name: 'E-mail', getter: 'person.active_email', sortable: 1 },
    {
      name: 'Telefon', getter: 'person.active_phone', sortable: 1, mutator: parsePhone
    },
    {
      name: 'Rola biznesowa',
      getter: 'roles',
      sortable: 1,
      mutator: (g) => g.map((i) => i.name).join()
    },
    { name: 'Ostatnia modyfikacja', getter: 'modified_date', sortable: 1 }
  ];

  return (
    <OldCard
      header={header}
      id="financialData-card"
      showIcons
      customCreateSection={customCreateSection}
    >
      <div className="collapse show" id="financialData">
        <div className="row mt-2 align-items-center mb-2" style={{ fontWeight: '600', color: '#878F9A', fontSize: '90%' }}>
          <div className="col-sm-2 p-2 text-right">
            Klient
          </div>
          <div className="col-sm-10">
            <span className="mr-3" style={{ fontWeight: '600', color: '#4B4E55' }}>
              {client.data.name || '-'}
            </span>
          </div>
        </div>
        <div className="row bb bt">
          <div className="col-sm-2 p-2 grey br">Imię i nazwisko</div>
          <div className="col-sm-2 p-2 grey br">Rola biznesowa</div>
          <div className="col-sm-2 p-2 grey br">Telefon</div>
          <div className="col-sm-2 p-2 grey br">E-mail</div>
          <div className="col-sm-2 p-2 grey br">Powiązane od</div>
          <div className="col-sm-2 p-2 grey">Powiązał</div>
        </div>
        {client.representation?.map((i) => (
          <RowEditable>
            <div className="col-sm-2 p-2 br">{i.person_in_company?.person?.full_name}</div>
            <div className="col-sm-2 p-2 br">{i.person_in_company?.roles?.map((r) => r.name).join(', ')}</div>
            <div className="col-sm-2 p-2 br">{parsePhone(i.person_in_company?.person?.active_phone)}</div>
            <div className="col-sm-2 p-2 br">{i.person_in_company?.person?.active_email}</div>
            <div className="col-sm-2 p-2 br">{i.created_date}</div>
            <div className="col-sm-2 p-2">{i.user_creator?.full_name}</div>
          </RowEditable>
        ))}
        <div className="row mt-3 align-items-center mb-2">
          <div className="col-sm-2 p-2 text-right" style={{ fontWeight: '600', color: '#878F9A', fontSize: '90%' }}>
            Administrator operacyjny
          </div>
          <div className="col-sm-10">
            <span className="mr-3" style={{ fontWeight: '600', color: '#4B4E55' }}>
              {administrator.data.name || '-'}
            </span>

            {/* <button type="button" className="ml-4" onClick={() => props.toggleForm('administrator_people')}> */}
            {/*  Zarządzaj */}
            {/* </button> */}
          </div>
        </div>
        <div className="row bb bt">
          <div className="col-sm-2 p-2 grey br">Imię i nazwisko</div>
          <div className="col-sm-2 p-2 grey br">Rola biznesowa</div>
          <div className="col-sm-2 p-2 grey br">Telefon</div>
          <div className="col-sm-2 p-2 grey br">E-mail</div>
          <div className="col-sm-2 p-2 grey br">Powiązane od</div>
          <div className="col-sm-2 p-2 grey">Powiązał</div>
        </div>
        {administrator.representation?.map((i) => (
          <RowEditable>
            <div className="col-sm-2 p-2 br">{i.person_in_company?.person?.full_name}</div>
            <div className="col-sm-2 p-2 br">{i.person_in_company?.roles?.map((r) => r.name).join(', ')}</div>
            <div className="col-sm-2 p-2 br">{parsePhone(i.person_in_company?.person?.active_phone)}</div>
            <div className="col-sm-2 p-2 br">{i.person_in_company?.person?.active_email}</div>
            <div className="col-sm-2 p-2 br">{i.created_date}</div>
            <div className="col-sm-2 p-2">{i.user_creator?.full_name}</div>
          </RowEditable>
        ))}
      </div>

      <Selectable
        multiple
        fetch={() => {}}
        // fetch={props.fetch}
        visible={props.formVisible('client_people')}
        formName="client_people"
        field="client_people"
        columns={columns}
        title={client.title}
        options={client.options}
        onClickAway={() => props.toggleForm('client_people')}
        header="Wybierz osoby powiązane z umową"
        footer="Aby zapisać wciśnij ZATWIERDŹ"
        onSelected={() => {
          props.saveForm('client_people', false);
          props.toggleForm('client_people');
          props.clearForm('client_people');
        }}
      />
      <Selectable
        multiple
        fetch={() => {}}
        // fetch={props.fetch}
        visible={props.formVisible('administrator_people')}
        formName="administrator_people"
        field="administrator_people"
        columns={columns}
        title={administrator.title}
        options={administrator.options}
        onClickAway={() => props.toggleForm('administrator_people')}
        header="Wybierz osoby powiązane z umową"
        footer="Aby zapisać wciśnij ZATWIERDŹ"
        onSelected={() => {
          props.saveForm('administrator_people', false);
          props.toggleForm('administrator_people');
          props.clearForm('administrator_people');
        }}
      />
    </OldCard>
  );
};
