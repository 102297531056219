import CompanyActions from 'actions/companies';
import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import { formatNip } from '../../../../utilities/helpers';
import { RolesForm } from '../../Forms/Roles';

export const ROLES_CREATOR_FORM = 'RolesCreatorForm';

export const Roles = (props) => {
  const dispatch = useDispatch();
  const { person } = useSelector(state => state.Person);
  const { RolesCreatorForm: form } = useSelector(state => state.Forms.forms);

  const companies = props.person && props.person.companies ? props.person.companies : [];

  const formName = ROLES_CREATOR_FORM;
  const companyModal = 'CompanyModal';

  const companySelected = props.forms[formName] ? props.forms[formName].company : null;

  const selectCompany = (c) => {
    if (companySelected && companySelected.value === c.value) {
      props.selectCompany(formName, null);
    } else {
      props.selectCompany(formName, c);
    }
  };

  const handleSaveForm = () => {
    dispatch(CompanyActions.addBusinessRoleToCompany(form.company.id, person.id, form.role));
  };

  return (
    <Card mode='creator' header='+ Role' onSaveClick={handleSaveForm} isBlocked={!form?.company?.id || !person?.id || !form?.role}>
      <RolesForm
        companySelected={companySelected}
        fetchCompanies={props.fetchCompanies}
        filtersForm={props.filtersForm}
        person={props.person}
        companies={props.companies}
        preloadedForm
        visibleOnMount
        clear={props.clear}
        visible={props.formVisible(formName)}
        formVisible={props.formVisible}
        formName={formName}
        submitForm={() => props.saveForm(formName)}
        onCancel={() => props.onCancel(formName)}
        roles={props.roles}
        errors={props.errors[formName]}
        forms={props.forms}
        toggleForm={props.toggleForm}
        selectCompany={props.selectCompany}
      />

      {companies.length ? (
        <>
          <div className="row">
            <div className="col-sm-12">
              <div className="row bb bt grey">
                <div className="col-sm-3 p-2 br">Rola biznesowa</div>
                <div className="col-sm-2 p-2 br">Nazwa firmy</div>
                <div className="col-sm-3 p-2 br">Rola firmy</div>
                <div className="col-sm-2 p-2 br">Powiązane od</div>
                <div className="col-sm-2 p-2">Powiązał</div>
              </div>
            </div>
          </div>
          {companies.map((c) => c.roles.map((r) => (
            <div className="row" key={c.name}>
              <div className="col-sm-12">
                <RowEditable itemId={c.id} onRemove={() => props.remove(r, c)}>
                  <div className="col-sm-3 p-2 br">{r.name}</div>
                  <div className="col-sm-2 p-2 br">{c.company.name}</div>
                  <div className="col-sm-3 p-2 br">{c.business_company_roles.map((i) => i.name).join(', ')}</div>
                  <div className="col-sm-2 p-2 br">{c.created_date}</div>
                  <div className="col-sm-2 p-2">{c.user_creator.full_name}</div>
                </RowEditable>
              </div>
            </div>
          )))}
        </>
      ) : null}

      <BaseModal
        visible={props.formVisible(companyModal)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>
              Czy chcesz powiązać
              {props.person ? props.person.full_name : 'brak'}
              {' '}
              z firmą:
            </span>
          </div>
        )}
        footer="Aby zapisać powiązanie osoby z firmą wciśnij ZAPISZ."
        onClickAway={() => props.toggleForm(companyModal)}
      >
        <div className="container" style={{ maxWidth: '100%' }}>
          <div className="row mt-3">
            <div className="col-sm-4 p-2 br bt bb text-left">
              <span className="ml-5 grey mr-3 font-weight-light">Nazwa firmy</span>
            </div>
            <div className="col-sm-4 p-2 br bt bb text-left">
              <span className="ml-5 grey mr-3 font-weight-light">NIP</span>
            </div>
            <div className="col-sm-4 p-2  bt bb text-left">
              <span className="ml-5 grey mr-3 font-weight-light">E-mail</span>
            </div>
          </div>
          {props.companies
            ? props.companies.map((c) => (
              <div
                onClick={() => selectCompany(c)}
                className={`row companyRow ${companySelected && companySelected.value === c.value ? 'companyRowSelected' : ''}`}
              >
                <div className="col-sm-4 p-2 br bb text-left">
                  <span className="ml-5 mr-3 font-weight-light">{c.name}</span>
                </div>
                <div className="col-sm-4 p-2 br bb text-left">
                  <span className="ml-5 mr-3 font-weight-light">{formatNip(c.nip)}</span>
                </div>
                <div className="col-sm-4 p-2  bb text-left">
                  <span className="ml-5 mr-3 font-weight-light">{c.active_email || 'brak'}</span>
                </div>
              </div>
            )) : ''}

          <div className="row">
            <div className="mt-4 col-sm-12 text-center">
              <button
                type="button"
                onClick={() => props.toggleForm(companyModal)}
                className="btn btn-primary-outline mr-2 font-weight-light"
                style={{ color: '#4B4E55' }}
              >
                Anuluj
              </button>
              <button type="button" onClick={() => props.toggleForm(companyModal)} className="btn btn-primary ml-2  font-weight-light">
                Zapisz
              </button>
            </div>
          </div>
        </div>
      </BaseModal>
    </Card>
  );
};
