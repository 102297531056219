import FormActions from 'actions/forms';
import { get } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'twin.macro';

const StyledCheckboxLabel = styled.div`
  display: flex;
  cursor: pointer;
  margin: 0.5rem;
`;

const Img = styled.img`
  max-width: initial;
  display: initial;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
`;

export default function CheckboxLabel({ formName, name, label, error, onChange, ...props }) {
  const { forms } = useSelector(state => state.Forms);
  const dispatch = useDispatch();

  const checked = get(forms[formName], name, false);

  const handleToggleCheckbox = () => {
    dispatch(FormActions.storeInput(formName, name, !checked));
    onChange && onChange();
  };

  return (
    <StyledCheckboxLabel onClick={handleToggleCheckbox} {...props}>
      <span className='font-bold text-grey-8'>{label}</span>

      <div className='mx-10'>
        <Img src={checked ? '/checkbox-checked.svg' : '/checkbox-unchecked.svg'} alt="checkbox" />
        {error && (<div className="form-control-feedback small" style={{ color: 'red' }}>{error}</div>)}
      </div>
    </StyledCheckboxLabel>
  );
}
