import { optionsBoolean } from 'components/Forms/Select';
import React from 'react';

import {
  Datepicker,   Form, Input, Select
} from '../../../components/Forms';

export const suppliersOptions = [
  { value: 1, name: '1' },
  { value: 5, name: '5' },
  { value: 10, name: '10' },
  { value: 20, name: '20' },
  { value: 50, name: '50' },
  { value: 100, name: '100' },
  { value: 500, name: '500' },
  { value: 1000, name: '1000' },
  { value: 5000, name: '5000' },
  { value: 10000, name: '10000' }
];

export const recipientsOptions = [
  { value: 1, name: '1' },
  { value: 5, name: '5' },
  { value: 10, name: '10' },
  { value: 20, name: '20' },
  { value: 50, name: '50' },
  { value: 100, name: '100' },
  { value: 500, name: '500' },
  { value: 1000, name: '1000' },
  { value: 5000, name: '5000' },
  { value: 10000, name: '10000' }
];

export const ScoringForm = (props) => {
  const data = props.companyData ? props.companyData : {};
  const mapForSelect = (i) => ({ ...i, value: i.id });

  const pkds = data.pkds ? data.pkds.map(mapForSelect) : [];
  const accounting_types = data.accounting_types ? data.accounting_types.map(mapForSelect) : [];
  const accounting_supports = data.accounting_supports ? data.accounting_supports.map(mapForSelect) : [];
  const office_ownerships = data.office_ownerships ? data.office_ownerships.map(mapForSelect) : [];
  const activity_ranges = data.activity_ranges ? data.activity_ranges.map(mapForSelect) : [];

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row mt-3">
            <div className="col-xs-12 col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Data rozpoczęcia działalności
                </label>
                <div className="col-sm-6">
                  <Datepicker noDefault formName={props.formName} name="company_started_date" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Data pierwszej faktury
                </label>
                <div className="col-sm-6">
                  <Datepicker noDefault formName={props.formName} name="first_invoice_date" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Liczba zatrudnionych
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="number" name="no_employees" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Nr głównego PKD
                </label>
                <div className="col-sm-6">
                  <Select
                    number
                    disabled={props.selectDisabled || false}
                    formName={props.formName}
                    options={pkds}
                    name="main_pkd_id"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Ilość dostawców
                </label>
                <div className="col-sm-6">
                  <Select formName={props.formName} name="suppliers_number" options={suppliersOptions} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Ilość odbiorców
                </label>
                <div className="col-sm-6">
                  <Select formName={props.formName} name="recipients_number" options={recipientsOptions} />
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ borderTop: '1px solid #DFE2EA' }}>
            <div className="col-xs-12 col-sm-6 mt-3 mb-3">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Sposób rozliczenia
                </label>
                <div className="col-sm-6">
                  <Select
                    number
                    disabled={props.selectDisabled || false}
                    formName={props.formName}
                    options={accounting_supports}
                    name="accounting_support_id"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-6  mb-3">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Rodzaj księgowości
                </label>
                <div className="col-sm-6">
                  <Select
                    number
                    disabled={props.selectDisabled || false}
                    formName={props.formName}
                    options={accounting_types}
                    name="accounting_type_id"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ borderTop: '1px solid #DFE2EA' }}>
            <div className="col-xs-12 col-sm-6 mt-3">
              <div className="form-group row ">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Zasięg działania
                </label>
                <div className="col-sm-6">
                  <Select
                    number
                    disabled={props.selectDisabled || false}
                    formName={props.formName}
                    options={activity_ranges}
                    name="activity_range_id"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="form-group row ">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Własność siedziby
                </label>
                <div className="col-sm-6">
                  <Select
                    number
                    disabled={props.selectDisabled || false}
                    formName={props.formName}
                    options={office_ownerships}
                    name="office_ownership_id"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="form-group row ">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Ubezpieczenia należności
                </label>
                <div className="col-sm-6">
                  <Select
                    formName={props.formName}
                    options={optionsBoolean}
                    name="insurance_claims"
                  />
                </div>
              </div>
            </div>
          </div>

          {props.withButtons ? (
            <div className="row">
              <div className="mt-3 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancel}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}
                >
                  Anuluj
                </button>
                <button type="button" onClick={props.onSubmit} className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
