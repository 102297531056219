import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useState } from 'react';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import { MultilevelRatesForm } from '../../Forms/MultilevelRates';
import RemovalModal from '../../Forms/RemovalModal';

export const MultilevelRates = (props) => {
  const [objectToRemove, setObjectToRemove] = useState({});

  const formName = 'MultilevelRatesForm';
  const calculation = props.calculation || {};
  const bids = calculation.multilevel_bids ? calculation.multilevel_bids.map((b) => ({ ...b, type: b.bid_type ? 'brak' : '!' })) : [];
  const dtbs = props.offerData && props.offerData.dtb_types ? props.offerData.dtb_types : [];

  const edit = (bid) => {
    const editableBid = { ...bid, calculationId: calculation.id };
    props.edit(editableBid, formName);
  };

  return (
    <Card header='Stawki wielopoziomowe' onAddClick={() => props.create(formName)}>
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row ">
              <div className="col-sm-2 p-2 br grey">Nazwa stawki</div>
              <div className="col-sm-2 p-2 br grey">Poziom</div>
              <div className="col-sm-2 p-2 br grey">Max wysokość zaliczki</div>
              <div className="col-sm-2 p-2 br grey">Max termin płatności</div>
              <div className="col-sm-2 p-2 br grey">Stawka</div>
              <div className="col-sm-2 p-2 grey">Nazwa dokumentu</div>
            </div>
            {bids.map((bid) => (
              <RowEditable
                key={bid.id}
                isObjectToRemove
                onEdit={() => edit(bid)}
                onRemove={() => {
                  setObjectToRemove(bid);
                }}
              >
                {bid.levels.map((level, key) => (
                  <div className="col-sm-12">
                    <div className="row bt">
                      <div className="col-sm-2 p-2 br">{key === 0 ? bid.name : ''}</div>
                      <div className="col-sm-2 p-2 br">{level.level_no}</div>
                      <div className="col-sm-2 p-2 br">{level.max_advance_payment}</div>
                      <div className="col-sm-2 p-2 br">{bid.max_payment_date}</div>
                      <div className="col-sm-2 p-2 br">{bid.multilevel_flat_rate}</div>
                      <div className="col-sm-2 p-2">{level.document_to_buy_types.map((d) => d.name).join(', ')}</div>
                    </div>
                  </div>
                ))}
              </RowEditable>
            ))}
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Stawki wielopoziomowe</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <MultilevelRatesForm
          getMultilevelFlatRate={props.getMultilevelFlatRate}
          dtbs={dtbs}
          formName={formName}
          offer={props.offer}
          preloadedForm
          visibleOnMount={false}
          clear={props.clear}
          visible={props.formVisible(formName)}
          formVisible={props.formVisible}
          saveForm={() => props.saveForm(formName)}
          onCancel={() => props.onCancel(formName)}
          withButtons
          errors={props.errors[formName]}
          forms={props.forms}
          toggleForm={props.toggleForm}
        />
      </BaseModal>

      <RemovalModal
        visible={Object.keys(objectToRemove).length > 0}
        toggle={() => setObjectToRemove({})}
        remove={() => {
          props.remove(objectToRemove);
          setObjectToRemove({});
        }}
      >
        {objectToRemove && (
          <div className="container-fluid">
            <div className="row bb bt">
              <div className="col-sm-2 p-2 br grey">Nazwa stawki</div>
              <div className="col-sm-1 p-2 br grey">Poziom</div>
              <div className="col-sm-1 p-2 br grey">Max wysokość zaliczki</div>
              <div className="col-sm-2 p-2 br grey">Max termin płatności</div>
              <div className="col-sm-2 p-2 br grey">Stawka flat</div>
              <div className="col-sm-2 p-2 br grey">Nazwa dokumentu</div>
              <div className="col-sm-2 p-2 grey">ID powiązanego dokumentu</div>
            </div>
            <div className="row bb">
              {objectToRemove && Array.isArray(objectToRemove.levels)
                ? objectToRemove.levels.map((level, key) => (
                  <div className="col-sm-12">
                    <div className="row bt">
                      <div className="col-sm-2 p-2 br">{key === 0 ? objectToRemove.name : ''}</div>
                      <div className="col-sm-1 p-2 br">{level.level_no}</div>
                      <div className="col-sm-1 p-2 br">{level.max_advance_payment}</div>
                      <div className="col-sm-2 p-2 br">{objectToRemove.max_payment_date}</div>
                      <div className="col-sm-2 p-2 br">{objectToRemove.multilevel_flat_rate}</div>
                      <div className="col-sm-2 p-2 br">{level.document_to_buy_types.map((d) => d.name).join(', ')}</div>
                      <div className="col-sm-2 p-2">-</div>
                    </div>
                  </div>
                ))
                : null}
            </div>
          </div>
        )}
      </RemovalModal>
    </Card>
  );
};
