import { toast } from 'react-toastify';

import DueService from '../services/due';
import CommonActions from './common';
import FormActions from './forms';
import { OptionsActions } from 'ducks/options';

const fetch = filters => dispatch => {
  dispatch(OptionsActions.toggleLoader(true));
  dispatch({ type: 'CONTENT_LOADING' });

  const f = filters ?? { ...CommonActions.defaultFilters };
  f.invoices = 1;

  Object.keys(f).forEach(k => {
    if (f[k] === '' || f[k] === null) {
      delete f[k];
    }
  });

  DueService.fetch(f).then(response => {
    dispatch({ type: 'DUES_FETCHED', payload: response ? response.data : [] });
    dispatch({ type: 'CONTENT_LOADED' });
    dispatch(OptionsActions.toggleLoader(false));
  });
};

const fetchDetails = (id, loader = true) => dispatch => {
  if (loader) {
    dispatch({ type: 'CONTENT_LOADING' });
    dispatch({ type: 'CONTENT_PREVIEW_LOADING' });
    dispatch(OptionsActions.toggleLoader(loader));
  }

  DueService.fetchDetails(id).then(async response => {
    dispatch({ type: loader ? 'TRANSFER_DETAILS_FETCHED' : 'TRANSFER_DETAILS_UPDATED', payload: response.data });
    dispatch({ type: 'CONTENT_LOADED' });

    if (loader) {
      dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
      dispatch(OptionsActions.toggleLoader(false));
    }
  });
};

const saveForm = (form, id, hide = false) => (dispatch, getState) => {
  dispatch(FormActions.clearErrors(form));

  DueService.saveForm(form, { ...getState().Forms.forms[form] }, id)
    .then(() => {
      dispatch(CommonActions.toggleModal());

      if (id) {
        dispatch(fetchDetails(id));
      } else {
        dispatch(fetch({ page: 1, limit: 10, status: 0 }));
      }

      document.getElementsByTagName('html')[0].style = '';

      let clearForm = true;

      if (clearForm) {
        dispatch(FormActions.clearForm(form));
      }

      if (hide) {
        dispatch(FormActions.toggleForm(form));
      }

      toast.success('Zaktualizowano dane.');
    }).catch((errors) => dispatch(FormActions.handleErrors(form, errors?.response?.data)));
};

export default {
  fetch,
  fetchDetails,
  generate: (dues, category, issue_date, onSuccess) => dispatch => {
    const ids = dues.map(due => due.id);
    DueService.generate(ids, category, issue_date)
      .then(res => {
        const r = res.data;
        toast.success(`Wygenerowano fakturę ${r.identifier} na kwotę ${r.gross_total} ${r.currency?.name}`);
        dispatch(fetch());
        onSuccess();
      })
      .catch(e => {
        dispatch(FormActions.handleErrors('GenerateInvoiceForm', e?.response?.data));
        const msg = e?.response?.data?.charge_ids?.[0];
        toast.error(msg ?? 'Generowanie faktury nie powiodło się.');
      });
  },
  saveForm
};
