import { BaseModal } from 'components/Layout/OldModal';
import { OldPagination } from 'components/Layout/OldPagination';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CommonActions from '../../../actions/common';
import FormActions from '../../../actions/forms';
import TransferActions from '../../../actions/transfer';
import BooleanCheck from '../../../components/BooleanCheck';
import { Selectable, SelectButton } from '../../../components/Forms';
import { Container, MainContainer, SidebarContainer } from '../../../components/Layout';
import { RowEditable } from '../../../components/Layout/RowEditable';
import Loader from '../../../components/Loader';
import { parseMoney } from '../../../utilities/helpers';
import { ConnectForm } from '../Forms/Connect';
import { ImportExportForm } from '../Forms/ImportExport';
import TransferForm from '../Forms/Transfer';
import { Searchbar } from './Searchbar';
import TransferSidebarView from './Sidebar';
import OldListFilters from 'components/OldListFilters';

const Modes = {
  Incoming: 'przychodzace',
  Outgoing: 'wychodzace'
};

const Tabs = {
  [Modes.Incoming]: {
    7: { name: 'Import przychodzące', display: true },
    8: { name: 'Poczekalnia przychodzące', display: true },
    9: { name: 'Otrzymane powiązane', display: true },
    10: { name: 'Inne', display: true },
    11: { name: 'Rozliczone', display: false },
    12: { name: 'Wszystkie', display: true }
  },
  [Modes.Outgoing]: {
    0: { name: 'W buforze', display: true },
    1: { name: 'Autoryzowane', display: true },
    2: { name: 'Wysłane do banku', display: true },
    3: { name: 'Zrealizowane', display: true },
    4: { name: 'Odrzucone', display: true },
    5: { name: 'Oczekujące', display: true },
    6: { name: 'Zaksięgowane', display: true },
    7: { name: 'Wszystkie', display: true }
  }
};

class TransfersList extends React.Component {
  filtersForm = 'TransfersFiltersForm';
  importForm = 'TransfersImportForm';
  exportForm = 'TransfersExportForm';
  selectForExportForm = 'TransfersSelectForExportForm';
  connectForm = 'TransfersConnectForm';
  cancelAuthorizationForm = 'CancelAuthorizationForm';

  createTransferForm = 'CreateTransferForm';
  deleteTransferForm = 'DeleteTransferForm';

  simpleConnectForm = 'TransfersSimpleConnectForm';
  simpleConnectFilters = 'TransfersSimpleConnectFilters';

  connectClaimFilters = 'TransfersSimpleConnectClaimsFiltersForm';
  connectInvoiceFilters = 'TransfersSimpleConnectInvoicesFiltersForm';
  factorFilters = 'TransfersFactorFiltersForm';

  constructor(props) {
    super(props);

    const url = new URLSearchParams(this.props.location.search);
    const mode = url.get('mode') ?? Modes.Outgoing;
    const tab = url.get('tab') ? Number(url.get('tab')) : Number(Object.keys(Tabs[mode])[0]);
    this.state = { mode, tab, ids: [], isRowsChecked: false };

    this.fetchResults();

    this.columns = {
      [Modes.Incoming]: this.state.tab === 7 ? [
        { name: 'Uznanie', sortBy: '', size: 2, field: 'gross_transfer_value' },
        { name: 'Identyfikator przelewu', sortBy: '', size: 2, field: 'identifier' },
        { name: 'Rozliczono', sortBy: '', size: 1, field: 'paid_value' },
        { name: 'Do rozliczenia', sortBy: '', size: 1, field: 'to_pay_value' },
        { name: 'Tytuł przelewu', sortBy: '', size: 2, field: 'payment_title' },
        { name: 'Zleceniodawca', sortBy: '', size: 2, field: 'sender_details' },
        { name: 'Data księgowania', sortBy: '', size: 1, field: 'posting_date' },
        { name: 'Obiekt powiązany', sortBy: '', size: 1, field: 'actions' }
      ] : [
        { name: 'Uznanie', sortBy: '', size: 2, field: 'gross_transfer_value' },
        { name: 'Identyfikator przelewu', sortBy: '', size: 2, field: 'identifier' },
        { name: 'Tytuł przelewu', sortBy: '', size: 2, field: 'payment_title' },
        { name: 'Zleceniodawca', sortBy: '', size: 2, field: 'sender_details' },
        { name: 'Data księgowania', sortBy: '', size: 2, field: 'posting_date' },
        { name: 'Obiekt powiązany', sortBy: '', size: 2, field: 'actions' }
      ],
      [Modes.Outgoing]: [
        { name: 'Kwota/waluta przelewu', sortBy: '', size: 2, field: 'gross_transfer_value' },
        { name: 'Tytuł przelewu', sortBy: '', size: 2, field: 'payment_title' },
        { name: 'Umowa', sortBy: '', size: 2, field: 'agreement' },
        { name: 'Zleceniobiorca', sortBy: '', size: 2, field: 'recipient' },
        { name: 'Data realizacji', sortBy: '', size: 2, field: 'invoice_date' },
        { name: 'Obiekt powiązany', sortBy: '', size: 2, field: 'actions' }
      ]
    };
  }

  componentDidMount() {
    this.props.dispatch(CommonActions.setContextMenu());
  }

  toggleForm(f) {
    this.props.dispatch(FormActions.toggleForm(f));
  }

  formVisible(f) {
    return this.props.forms.options.visible.indexOf(f) !== -1;
  }

  getFilters() {
    const { mode, tab } = this.state;
    const currentFilters = this.props.forms.forms[this.filtersForm] || {};

    if (!currentFilters || Object.keys(currentFilters).length <= Object.keys(CommonActions.defaultFilters).length) {
      this.props.dispatch(FormActions.initialize(this.filtersForm, CommonActions.defaultFilters, {}));
    }

    return {
      ...this.props.forms.forms[this.filtersForm],
      status: tab,
      category: mode === Modes.Incoming ? 1 : 0
    };
  }

  changePage(page) {
    this.props.dispatch(FormActions.storeInput(this.filtersForm, 'page', page));
    this.fetchResults();
  }

  fetchResults() {
    this.props.dispatch(TransferActions.fetch(this.getFilters()));
  }

  getTabs() {
    const { mode } = this.state;

    return Object.keys(Tabs[mode])
      .map(key => {
        const obj = Tabs[mode][key];
        if (obj.display) {
          return { value: Number(key), name: obj.name };
        }
        return null;
      })
      .filter(t => t);
  }

  isChecked(element) {
    const { ids } = this.state;

    return ids.find(i => i === element);
  }

  check(element) {
    const { transfer } = this.props;
    const { ids } = this.state;
    let newIds = [];

    if (element) {
      const alreadyExists = ids.find(i => i === element);
      newIds = alreadyExists ? ids.filter(i => i !== element) : [...ids, element];
    } else {
      newIds = transfer.list.map(i => i.id);
    }

    !this.state.isRowsChecked ? this.setState({ ids: newIds }) : this.setState({ ids: [] });
  }

  getDataRows() {
    const { mode, tab } = this.state;
    const { transfer } = this.props;

    let mapper = transfer => transfer;

    switch (mode) {
      case Modes.Incoming:
        mapper = transfer => ({
          id: transfer.id,
          identifier: transfer.identifier,
          paid_value: parseMoney(transfer.paid_value),
          to_pay_value: parseMoney(transfer.to_pay_value),
          gross_transfer_value: `${parseMoney(transfer.gross_transfer_value)} ${transfer.gross_transfer_value_currency?.name ||
            transfer.vat_transfer_value_currency?.name}`,
          payment_title: transfer.payment_title || '-',
          sender_details: transfer.sender_details,
          posting_date: transfer.posting_date,
          actions:
            tab === 7 ? (
              <>
                <button
                  type="button"
                  className="ml-1 mr-1"
                  onClick={() => {
                    this.props.dispatch(FormActions.initialize(this.connectForm, { transfer, claims: [{}] }));
                    this.toggleForm(this.connectForm);
                  }}>
                  /
                </button>
                <button
                  type="button"
                  className="mr-1"
                  onClick={() => {
                    if (!this.props.clients.length) {
                      this.props.dispatch(TransferActions.fetchClientsForConnect());
                    }
                    this.props.dispatch(TransferActions.fetchClaimsForConnect(this.simpleConnectFilters));
                    this.props.dispatch(FormActions.storeInput(this.simpleConnectForm, 'payment', transfer));
                    this.toggleForm(this.simpleConnectForm);
                  }}>
                  Wybierz
                </button>
              </>
            ) : (
              transfer.claims?.map(c => (
                <span
                  style={{ display: 'block' }}
                  onClick={() => {
                    this.props.history.push(`/wierzytelnosci/${c?.claim?.id}`);
                  }}>
                  {c?.claim?.identifier}
                </span>
              ))
            )
        });
        break;
      case Modes.Outgoing:
        mapper = transfer => ({
          id: transfer.id,
          gross_transfer_value: `${parseMoney(transfer.gross_transfer_value)} ${transfer.gross_transfer_value_currency?.name ||
            transfer.vat_transfer_value_currency?.name}`,
          payment_title: transfer.payment_title,
          agreement: transfer?.agreement?.identifier,
          recipient: transfer.recipient?.name,
          invoice_date: transfer.invoice_date,
          actions: (
            <span
              onClick={() => {
                this.props.history.push(`/wierzytelnosci/${transfer.claim?.id}`);
              }}>
              {transfer.claim?.identifier}
            </span>
          )
        });
        break;
      default:
        break;
    }

    return transfer.list.map(mapper);
  }

  renderActionButtons() {
    const { mode, tab, ids } = this.state;

    switch (mode) {
      case Modes.Outgoing:
        return (
          <>
            {tab === 0 && (
              <button
                className="btn-green"
                type="button"
                disabled={!ids.length}
                onClick={() => {
                  this.props.dispatch(TransferActions.authorizeMany(ids));
                }}>
                Autoryzuj
              </button>
            )}
            {tab === 1 ? (
              <>
                <button
                  className="btn-green"
                  type="button"
                  disabled={!ids.length}
                  onClick={() => {
                    this.props.dispatch(TransferActions.sendToBank(ids, this.exportForm, () => this.toggleForm(this.exportForm)));
                  }}>
                  Wyślij do banku
                </button>
                <button
                  className="btn-green ml-2"
                  type="button"
                  disabled={!ids.length}
                  onClick={() => {
                    this.props.dispatch(TransferActions.cancelAuthorization(ids));
                  }}>
                  Cofnij autoryzację
                </button>
              </>
            ) : null}
          </>
        );
      case Modes.Incoming:
        return (
          <>
            <button type="button" className=" btn-green" onClick={() => {}}>
              Zatwierdź powiązanie
            </button>
            <button className="ml-3 btn-green" type="button" onClick={() => {}}>
              Wyczyść
            </button>
            <button
              className="ml-3 btn-green float-right"
              type="button"
              onClick={() => {
                this.props.dispatch(FormActions.initialize(this.importForm, {}, {}));
                this.toggleForm(this.importForm);
              }}>
              Nowy import
            </button>
            <button disabled className="ml-3 float-right" type="button" onClick={() => this.toggleForm(this.importForm)}>
              Do poczekalni
            </button>
          </>
        );
      default:
        return null;
    }
  }

  edit(transfer) {
    const t = this.props.transfer.list.find(x => x.id === transfer.id);
    this.props.dispatch(TransferActions.prepareForEdit(t, this.createTransferForm));
  }

  render() {
    const { tab, mode } = this.state;
    const { common } = this.props;

    const invoiceColumns = [
      { name: 'Nr dokumentu', getter: 'identifier', sortable: 1 },
      { name: 'Termin płatności', getter: 'payment_date', sortable: 1 },
      // { name: 'Kategoria', getter: 'number', sortable: 1 },
      {
        name: 'Wartość brutto',
        getter: 'gross_value',
        sortable: 1,
        mutator: parseMoney
      },
      // { name: 'Rozliczono', getter: 'number', sortable: 1 },
      { name: 'Klient', getter: 'buyer.name', sortable: 1 },
      {
        name: 'Status',
        getter: 'status',
        sortable: 1,
        mutator: v => <span className="badge badge-warning mr-3 pt-1 pb-1 pl-2 pr-2">{this.props.common?.invoiceData?.statuses?.[v]}</span>
      }
    ];
    const columns = [
      { name: 'Nr dokumentu', getter: 'identifier', sortable: 1 },
      { name: 'Termin płatności', getter: 'due_date', sortable: 1 },
      // { name: 'Kategoria', getter: 'number', sortable: 1 },
      {
        name: 'Wartość brutto',
        getter: 'gross_value',
        sortable: 1,
        mutator: parseMoney
      },
      // { name: 'Rozliczono', getter: 'number', sortable: 1 },
      { name: 'Klient', getter: 'client.name', sortable: 1 },
      {
        name: 'Status',
        getter: 'status',
        sortable: 1,
        mutator: v => <span className="badge badge-warning mr-3 pt-1 pb-1 pl-2 pr-2">{this.props.common?.claimData?.claim_statuses?.[v]}</span>
      }
    ];

    const title = (
      <div className="row d-flex align-items-center">
        <div className="col-sm-7">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-2 col-form-label label-right">
              Klient
            </label>
            <div className="col-sm-10">
              <SelectButton
                formName={this.simpleConnectFilters}
                name="client_id"
                options={this.props.clients?.map(c => ({ value: c.id, name: c.name }))}
                buttonOnClick={() => this.props.dispatch(FormActions.clearField(this.simpleConnectFilters, 'client_id'))}
                selectOnChange={() => this.props.dispatch(TransferActions.fetchClaimsForConnect(this.simpleConnectFilters))}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-3 d-flex align-items-center">
          <span className="mr-2">Przekaż dalej poza faktoringiem</span>
          <BooleanCheck field={false} />
        </div>
        <div className="col-sm-2 d-flex align-items-center">
          <span className="mr-2">Tylko nierozliczone</span>
          <BooleanCheck
            field={this.props.forms?.forms?.[this.simpleConnectFilters]?.exclude_settled}
            onClick={() => {
              this.props.dispatch(
                FormActions.storeInput(
                  this.simpleConnectFilters,
                  'exclude_settled',
                  this.props.forms?.forms?.[this.simpleConnectFilters]?.exclude_settled === 1 ? 0 : 1
                )
              );
              this.props.dispatch(TransferActions.fetchClaimsForConnect(this.simpleConnectFilters));
            }}
          />
        </div>
      </div>
    );

    const handleSwitchTab = item => () => {
      this.props.history.push(`/przelewy?mode=${mode}&tab=${item.value}`);
    };

    return (
      <Container>
        <MainContainer>
          <Searchbar
            title="Filtruj rachunki"
            formName={this.filtersForm}
            onSubmit={() => this.fetchResults()}
            records={[1, 2, 3].length}
            selectOptions={[]}
          />
          <ul className="nav nav-tabs" role="tablist">
            {this.getTabs().map(t => (
              <li
                onClick={handleSwitchTab(t)}
                className={`nav-item nav-link ${tab === t.value ? 'active' : ''}`}>
                {t.name}
              </li>
            ))}
          </ul>

          <div className="tab-content tabcontent-border" style={{ background: 'white' }}>
            {common.loading ? (
              <Loader />
            ) : (
              <div className="tab-pane active p-20" id="#" role="tabpanel">
                <div className="row mb-4 mt-3 align-items-center">
                  <div className="col-sm-10">
                    <OldListFilters formName={this.filtersForm} onSubmit={() => this.fetchResults()} />
                  </div>
                  <div className="col-sm-2 text-right">
                    <h4 className="text-themecolor" style={{ cursor: 'pointer' }}>
                      <i className="fa fa-ellipsis-h mr-2" />
                      {/* <i className="fa fa-plus" onClick={() => this.createTransfer()} /> */}
                    </h4>
                  </div>
                </div>
                <div className="row bt bb">
                  {this.columns[mode].map((column, k) => (
                    <div
                      className={`col-sm-${column.size} p-2 grey ${k === this.columns[mode].length - 1 ? '' : 'br'}  ${
                        k === 0 ? 'd-flex align-items-center' : ''
                      }`}>
                      {k === 0 ? <BooleanCheck field={this.state.isRowsChecked} onClick={() => {
                        this.setState({ isRowsChecked: !this.state.isRowsChecked });
                        this.check(null);
                      }} /> : null}
                      <span className={k === 0 ? 'ml-2' : ''}>{column.name}</span>
                    </div>
                  ))}
                </div>
                {this.getDataRows().map(row => (
                  <RowEditable
                    // onEdit={this.state.tab === 0 ? () => this.edit(row) : undefined}
                    onPreview={() => this.edit(row)}
                    onClick={() => this.props.dispatch(TransferActions.selectForPreview(this.props.transfer.list.find(i => i.id === row.id)))}
                  >
                    {this.columns[mode].map((column, k) => (
                      <div
                        className={`col-sm-${column.size} p-2 ${k === this.columns[mode].length - 1 ? '' : 'br'} ${
                          k === 0 ? 'd-flex align-items-center' : ''
                        }`}>
                        {k === 0 ? <BooleanCheck field={!!this.isChecked(row.id)} onClick={() => this.check(row.id)} /> : null}
                        <span className={k === 0 ? 'ml-2' : ''}> {row[column.field]}</span>
                      </div>
                    ))}
                  </RowEditable>
                ))}

                <div className="row mt-4">
                  <div className="col-sm-12">{this.renderActionButtons()}</div>
                </div>
              </div>
            )}
          </div>

          <OldPagination
            onList
            currentPage={this.props.transfer.currentPage}
            changePage={this.changePage}
            links={this.props.transfer.links}
            count={this.props.transfer.count}
          />

          <BaseModal
            visible={this.formVisible(this.createTransferForm)}
            header={'Podgląd przelewu'}
            // header={this.state.tab !== 0 ? 'Podgląd przelewu' : 'Dodanie przelewu'}
            onClickAway={() => this.toggleForm(this.createTransferForm)}>
            <TransferForm
              onList
              details
              // details={this.state.tab !== 0}
              errors={this.props.forms.errors[this.createTransferForm]}
              forms={this.props.forms.forms}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              toggleForm={form => this.toggleForm(form)}
              formName={this.createTransferForm}
              withButtons
              submitForm={() => this.saveForm()}
              onCancel={() => this.toggleForm(this.createTransferForm)}
              currencies={this.props.common.currencies}
              fetch={(t, f) => this.props.dispatch(TransferActions.fetchForForm(t, f))}
              formData={this.props.transfer.formData}
              storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
              common={this.props.common}
              removeTransfer={id => this.removeTransfer(id)}
              formVisible={f => this.formVisible(f)}
            />
          </BaseModal>

          <BaseModal
            visible={this.formVisible(this.exportForm)}
            header="Kreator eksportu przelewów do banku"
            footer=""
            onClickAway={() => this.props.dispatch(TransferActions.handleExport(this.exportForm, false))}>
            <ImportExportForm
              fetchFactors={() => this.props.dispatch(TransferActions.fetchFactorsForImport(this.factorFilters))}
              formVisible={v => this.formVisible(v)}
              factors={[]}
              common={this.props.common}
              type="export"
              withButtons
              formName={this.exportForm}
              forms={this.props.forms?.forms}
              toggleForm={f => this.toggleForm(f)}
              handle={shouldExport => this.props.dispatch(TransferActions.handleExport(this.exportForm, shouldExport))}
              storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            />
          </BaseModal>

          <BaseModal
            visible={this.formVisible(this.importForm)}
            header="Kreator importu przelewów z banku"
            footer=""
            onClickAway={() => this.toggleForm(this.importForm)}>
            <ImportExportForm
              fetchFactors={() => this.props.dispatch(TransferActions.fetchFactorsForImport(this.factorFilters))}
              formVisible={v => this.formVisible(v)}
              factors={this.props.factors}
              clear={(f, v) => this.props.dispatch(FormActions.clearField(f, v))}
              common={this.props.common}
              type="import"
              withButtons
              formName={this.importForm}
              forms={this.props.forms?.forms}
              toggleForm={f => this.toggleForm(f)}
              storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
              handle={() => this.props.dispatch(TransferActions.import(this.importForm))}
            />
          </BaseModal>

          <BaseModal
            visible={this.formVisible(this.connectForm)}
            header="Wybierz wierzytelność do powiązania"
            footer=""
            onClickAway={() => this.toggleForm(this.connectForm)}>
            <ConnectForm
              forms={this.props.forms?.forms}
              errors={this.props.forms?.errors}
              withButtons
              formName={this.connectForm}
              toggleForm={f => this.toggleForm(f)}
              storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
              clear={(f, v) => this.props.dispatch(FormActions.clearField(f, v))}
              columns={columns}
              invoiceColumns={invoiceColumns}
              options={this.props.claims}
              invoices={this.props.invoices}
              fetch={() => this.props.dispatch(TransferActions.fetchClaimsForConnect(this.connectClaimFilters))}
              claimsFilters={this.connectClaimFilters}
              fetchInvoices={() => this.props.dispatch(TransferActions.fetchInvoicesForConnect(this.connectInvoiceFilters))}
              invoicesFilters={this.connectInvoiceFilters}
              submitForm={() => {
                const f = this.props.forms?.forms?.[this.connectForm];
                const data =
                  f?.claims?.map(c => {
                    const common = { claim_id: c.claim?.id, amount: c.amount, is_vat: false };

                    if (c.claim) {
                      common.claim_id = c.claim.id;
                    }
                    if (c.document) {
                      common.invoice_id = c.document.id;
                    }

                    return common;
                  }) ?? [];

                if (f.claim_vat) {
                  data.push({ claim_id: f.claim_vat.claim?.id, amount: f.claim_vat.amount, is_vat: true });
                }

                this.props.dispatch(TransferActions.connectWithClaim(f.transfer, data, this.connectForm, () =>  this.toggleForm(this.connectForm)));
              }}
            />
          </BaseModal>

          <Selectable
            fetch={() => this.props.dispatch(TransferActions.fetchClaimsForConnect(this.simpleConnectFilters))}
            filtersForm={this.simpleConnectFilters}
            noTermSearching
            // fetch={props.fetch}
            visible={this.formVisible(this.simpleConnectForm)}
            formName={this.simpleConnectForm}
            field="claim"
            columns={columns}
            options={this.props.claims}
            title={title}
            onClickAway={() => this.toggleForm(this.simpleConnectForm)}
            header="Wybierz wierzytelność do powiązania"
            footer="Aby zapisać wciśnij ZATWIERDŹ"
            onSelected={() => {
              const x = this.props.forms?.forms?.[this.simpleConnectForm];

              this.props.dispatch(
                TransferActions.connectWithClaim(x.payment, [
                  {
                    amount: x.payment?.gross_transfer_value,
                    is_vat: false,
                    claim_id: x.claim?.id
                  }
                ])
              );
            }}
          />
        </MainContainer>
        <SidebarContainer>
          <TransferSidebarView remove={this.remove} transfer={this.props.transfer.transfer} loading={false} common={this.props.common} />
        </SidebarContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  common: state.Common,
  forms: state.Forms,
  transfer: state.Transfer,
  claims: state.Transfer.claims,
  invoices: state.Transfer.invoices,
  factors: state.Transfer.factors,
  clients: state.Transfer.clients
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(TransfersList)
);
