import 'twin.macro';

import FormActions from 'actions/forms';
import api from 'api';
import { CREATE_OFFER_FORM } from 'containers/Offers/List/Index';
import useData from 'hooks/useData';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeInput } from 'reducers/forms';

import {
  Buttons, Form, Input, Required, Select,
  Selectable, SelectButton
} from '../../../components/Forms';

export const CreateOfferForm = (props) => {
  const { user } = useSelector(state => state.Authorization);
  const { currencies } = useSelector(state => state.Common);
  const dispatch = useDispatch();
  const { sendRequest } = useData();

  const data = props.offerData ? props.offerData : {};
  const mapper = (i) => ({ value: i.id, name: i.name || i.full_name || 'brak' });

  const columns = [{ name: 'Nazwa', getter: 'name' }];

  const e = props.errors || {};

  const factorFiltersForm = 'FactorFiltersForm';
  const oaFiltersForm = 'OaFiltersForm';
  const clientFiltersForm = 'ClientFiltersForm';
  const tsFiltersForm = 'TsFiltersForm';
  const bmFiltersForm = 'BmFiltersForm';

  const actors = {
    clients: props.actors.clients || { results: [] },
    operational_administrators: props.actors.operational_administrators || { results: [] },
    billing_managers: props.actors.billing_managers || { results: [] },
    trade_supervisors: props.actors.trade_supervisors || { results: [] },
    factors: props.actors.factors || { results: [] }
  };

  useEffect(() => {
    const defaultCurrency = currencies?.find(currency => currency.name === 'PLN');
    dispatch(FormActions.storeSelect(props.formName, 'currency_id', defaultCurrency));
  }, []);

  useEffect(() => {
    sendRequest(api.offers.getCompaniesOrPeopleList({ role_name: 'faktor' }), ({ results }) => {
      const defaultField = results.find(item => item.id === user?.factor?.id);
      dispatch(storeInput(CREATE_OFFER_FORM, 'factor', defaultField));
    });
    sendRequest(api.offers.getCompaniesOrPeopleList({ role_name: 'handlowy' }), ({ results }) => {
      const defaultField = results.find(item => item.id === user?.trade_supervisor?.id);
      dispatch(storeInput(CREATE_OFFER_FORM, 'trade_supervisor', defaultField));
    });
    sendRequest(api.offers.getCompaniesOrPeopleList({ role_name: 'rozliczenia' }), ({ results }) => {
      const defaultField = results.find(item => item.id === user?.billing_manager?.id);
      dispatch(storeInput(CREATE_OFFER_FORM, 'billing_manager', defaultField));
    });
    // maybe it will be fucking useful someday :)
    sendRequest(api.offers.getCompaniesOrPeopleList({ role_name: 'administrator operacyjny' }), ({ results }) => {
      const defaultField = results.find(item => item.id === user?.operational_administrator?.id);
      dispatch(storeInput(CREATE_OFFER_FORM, 'operational_administrator', defaultField));
    });
    // eslint-disable-next-line
  }, []);

  if(!props.form) return null;

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row pr-5">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Faktor
                  <Required />
                </label>
                <div className="col-sm-6 d-flex align-items-center">
                  <span className="mr-3" style={{ fontWeight: '600', color: e.factor_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                    {props?.form?.factor?.name ?? 'nie wybrano'}
                  </span>
                  <button
                    id="btn-factor"
                    type="button"
                    onClick={() => {
                      props.fetch('factors', factorFiltersForm);
                      props.toggleForm('factor');
                    }}
                  >
                    Wybierz
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Administrator operacyjny
                </label>
                <div className="col-sm-6 d-flex align-items-center">
                  <span
                    className="mr-3"
                    style={{ fontWeight: '600', color: e.operational_administrator_id ? 'red' : '#4B4E55', fontSize: '14px' }}
                  >
                    {props.form.operational_administrator ? props.form.operational_administrator.name : 'nie wybrano'}
                  </span>
                  <button
                    type="button"
                    id="btn-oa"
                    onClick={() => {
                      props.fetch('operational_administrators', oaFiltersForm);
                      props.toggleForm('operational_administrator');
                    }}
                  >
                    Wybierz
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row pr-5">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Produkt
                  <Required />
                </label>
                <div className="col-sm-6">
                  <SelectButton
                    buttonOnClick={() => props.clear(props.formName, 'product_id')}
                    buttonDisabled={props.selectButtonDisabled}
                    formName={props.formName}
                    options={data.products.map(mapper)}
                    name="product_id"
                    value={props.selectButtonValue}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row pr-5">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Procedura
                  <Required />
                </label>
                <div className="col-sm-6">
                  <SelectButton
                    buttonOnClick={() => props.clear(props.formName, 'procedure_id')}
                    buttonDisabled={props.selectButtonDisabled}
                    formName={props.formName}
                    options={data.procedures.map(mapper)}
                    name="procedure_id"
                    value={props.selectButtonValue}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row pr-5">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Klient
                  <Required />
                </label>
                <div className="col-sm-6 d-flex align-items-center">
                  <span className="mr-3" style={{ fontWeight: '600', color: e.client_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                    {props.form.client ? props.form.client.name : 'nie wybrano'}
                  </span>
                  <button
                    type="button"
                    id="btn-client"
                    onClick={() => {
                      props.fetch('clients', clientFiltersForm);
                      props.toggleForm('client');
                    }}
                  >
                    Wybierz
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row pr-5">
            <div className="col-sm-12">
              <div className="form-group row">
                <div className="col-sm-3 pt-10 label-right col-form-label">
                  Kwota limitu
                  <Required />
                </div>
                <div className="col-sm-3">
                  <Input type="text" name="limit_amount" formName={props.formName} />
                </div>
                <div className="col-sm-3 flex items-center">
                  <Select
                    tw='w-80'
                    isClearable={false}
                    formName={props.formName}
                    options={props.currencies ? props.currencies.map(mapper) : []}
                    name="currency_id"
                    placeholder='waluta'
                  />
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="row pr-5">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Opiekun handlowy<Required/>
                </label>
                <div className="col-sm-6 d-flex align-items-center">
                  <span
                    className="mr-3"
                    style={{ fontWeight: '600', color: e.trade_supervisor_id ? 'red' : '#4B4E55', fontSize: '14px' }}
                  >
                    {props.form.trade_supervisor ? props.form.trade_supervisor.name : 'nie wybrano'}
                  </span>
                  <button
                    type="button"
                    id="btn-ts"
                    onClick={() => {
                      props.fetch('trade_supervisors', tsFiltersForm);
                      props.toggleForm('trade_supervisor');
                    }}
                  >
                    Wybierz
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row pr-5">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Opiekun ds. rozliczeń
                </label>
                <div className="col-sm-6 d-flex align-items-center">
                  <span
                    className="mr-3"
                    style={{ fontWeight: '600', color: e.billing_manager_id ? 'red' : '#4B4E55', fontSize: '14px' }}
                  >
                    {props.form.billing_manager ? props.form.billing_manager.name : 'nie wybrano'}
                  </span>
                  <button
                    type="button"
                    id="btn-bm"
                    onClick={() => {
                      props.fetch('billing_managers', bmFiltersForm);
                      props.toggleForm('billing_manager');
                    }}
                  >
                    Wybierz
                  </button>
                </div>
              </div>
            </div>
          </div>

          {props.withButtons ? (
            <Buttons
              formName={props.formName}
              onCancel={props.onCancel}
              onSubmit={props.submitForm}
              onSubmitAndLeave={props.onSubmitAndLeave}
              submitButton="Utwórz kalkulację"
              submitButtonClassNames="btn btn-primary btn-primary-blue ml-2 font-weight-light"
            />
          ) : null}

          <Selectable
            filtersForm={factorFiltersForm}
            fetch={() => props.fetch('factors', factorFiltersForm)}
            visible={props.isVisible('factor')}
            formName={props.formName}
            field="factor"
            columns={columns}
            options={actors.factors}
            onClickAway={() => props.toggleForm('factor')}
            header="Wybierz faktora"
          />
          <Selectable
            filtersForm={oaFiltersForm}
            fetch={() => props.fetch('operational_administrators', oaFiltersForm)}
            visible={props.isVisible('operational_administrator')}
            formName={props.formName}
            field="operational_administrator"
            columns={columns}
            options={actors.operational_administrators}
            onClickAway={() => props.toggleForm('operational_administrator')}
            header="Wybierz administratora operacyjnego"
          />
          <Selectable
            filtersForm={clientFiltersForm}
            fetch={() => props.fetch('clients', clientFiltersForm)}
            visible={props.isVisible('client')}
            formName={props.formName}
            field="client"
            columns={columns}
            options={actors.clients}
            onClickAway={() => props.toggleForm('client')}
            header="Wybierz klienta"
          />
          <Selectable
            filtersForm={tsFiltersForm}
            fetch={() => props.fetch('trade_supervisors', tsFiltersForm)}
            visible={props.isVisible('trade_supervisor')}
            formName={props.formName}
            field="trade_supervisor"
            columns={columns}
            options={actors.trade_supervisors}
            onClickAway={() => props.toggleForm('trade_supervisor')}
            header="Wybierz opiekuna handlowego"
          />
          <Selectable
            filtersForm={bmFiltersForm}
            fetch={() => props.fetch('billing_managers', bmFiltersForm)}
            visible={props.isVisible('billing_manager')}
            formName={props.formName}
            field="billing_manager"
            columns={columns}
            options={actors.billing_managers}
            onClickAway={() => props.toggleForm('billing_manager')}
            header="Wybierz opiekuna ds. rozliczeń"
          />
        </>
      ) : null}
    </Form>
  );
};
