import ClaimActions from 'actions/claim';
import Tooltip from 'components/Layout/Tooltip';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import BooleanCheck from '../../../components/BooleanCheck';
import { Datepicker, File, Form, Select, Textarea } from '../../../components/Forms';

export default props => {
  const dispatch = useDispatch();

  const f = props.forms?.forms?.[props.formName] ?? {};
  const a = props.claim?.agreement ?? {};
  const debtor = a?.agreement_versions?.[0]?.agreement_debtors?.find(x => x.id === props.claim?.debtor?.id) ?? {};
  const dtbTypes = props.common?.offerData?.dtb_types?.map(x => ({ value: x.id, name: x.name })) ?? [];
  const formalExpectations = props.common?.proposalData?.formal_expectations_data?.map(x => ({ ...x, value: x.id })) ?? [];

  const handleSave = () => {
    dispatch(ClaimActions.saveDocumentsToBuy({ formName: props.formName, id: props.claim.id }));
  };

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      <div className="row mb-2 align-items-center">
        <div className="col-sm-2 grey text-right" style={{ fontWeight: 600 }}>
          Nazwa dokumentu
        </div>
        <div className="col-sm-3 " style={f.dtb_type ? { fontWeight: 600 } : {}}>
          {f.dtb_type?.name ?? <Select number name="dtb_type_id" formName={props.formName} options={dtbTypes} />}
        </div>
        <div className="col-sm-2 grey  text-right" style={{ fontWeight: 600 }}>
          Nr dokumentu / Status
        </div>
        <div className="col-sm-3 ">
          {f.identifier || '-'} /{f.status || '-'}
        </div>
      </div>
      <div className="row mb-2 align-items-center">
        <div className="col-sm-2 grey  text-right" style={{ fontWeight: 600 }}>
          Produkt / Procedura
        </div>
        <div className="col-sm-3 grey">{`${a.product?.name} / ${a.procedure?.name}`}</div>

        <div className="col-sm-2 grey  text-right" style={{ fontWeight: 600 }}>
          Okres obowiązywania
        </div>
        <div className="col-sm-4 d-flex align-items-center">
          <BooleanCheck asRadioButton field={!!f.indefinite_period_of_validity} onClick={() => {
            props.storeInput(props.formName, 'indefinite_period_of_validity', true);
            props.storeInput(props.formName, 'period_of_validity_from', null);
            props.storeInput(props.formName, 'period_of_validity_to', null);
          }} />
          <span className="ml-1 mr-1">bezterm.</span>
          <BooleanCheck asRadioButton field={!f.indefinite_period_of_validity} onClick={() => props.storeInput(props.formName, 'indefinite_period_of_validity', false)} />
          <span className="ml-1 mr-1">od</span>
          <Datepicker disabled={f.indefinite_period_of_validity} formName={props.formName} name="period_of_validity_from" />
          <span className="ml-1 mr-1">do</span>
          <Datepicker disabled={f.indefinite_period_of_validity} formName={props.formName} name="period_of_validity_to" />
        </div>
        {/*<div className="col-sm-3">*/}
        {/*  <Select*/}
        {/*    number*/}
        {/*    disabled={props.selectDisabled || false}*/}
        {/*    formName={props.formName}*/}
        {/*    options={[{ value: 1, name: 'bezterminowo' }, { value: 0, name: 'od / do' }]}*/}
        {/*    name="indefinite_period_of_validity"*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <div className="row mb-2 align-items-center">
        <div className="col-sm-2 grey  text-right" style={{ fontWeight: 600 }}>
          Kwota wykupu
        </div>
        <div className="col-sm-3 grey">
          {`${props.claim?.purchase_gross_value || props.claim?.purchase_custom_value || '-'} ${props.claim?.purchase_currency?.name ||
            props.claim?.purchase_custom_value_currency?.name ||
            '-'}`}
        </div>
        <div className="col-sm-2 grey  text-right" style={{ fontWeight: 600 }}>
          Wzór Faktora
        </div>
        <div className="col-sm-2 grey">
          <Select
            number
            disabled={props.selectDisabled || false}
            formName={props.formName}
            options={[{ value: 1, name: 'tak' }, { value: 0, name: 'nie' }]}
            name="is_factor_pattern"
          />
        </div>
      </div>
      <div className="row mb-2 align-items-center">
        <div className="col-sm-2 grey  text-right" style={{ fontWeight: 600 }}>
          Nr umowy
        </div>
        <div className="col-sm-3 grey"><Tooltip label={a.identifier} /></div>
        <div className="col-sm-2 grey  text-right" style={{ fontWeight: 600 }}>
          Forma dokumentu
          {/*{f.expected_dtb_form ? '/ oczekiwana' : ''}*/}
        </div>
        <div className="col-sm-2 grey d-flex align-items-center">
          <Select
            number
            disabled={props.selectDisabled || false}
            formName={props.formName}
            options={props.common?.offerData?.dtb_forms?.map(i => ({ ...i, value: i.id })) ?? []}
            name="delivered_dtb_form_id"
          />
        </div>
        <div className="col-sm-3 grey d-flex align-items-center">
          {f.expected_dtb_form ? <>/ {f.expected_dtb_form?.name || '-'}</> : ''}
        </div>
      </div>
      <div className="row mb-2 align-items-center">
        <div className="col-sm-2 grey  text-right" style={{ fontWeight: 600 }}>
          Nr wierzytelności
        </div>
        <div className="col-sm-3 grey"><Tooltip label={props.claim?.identifier} /></div>
        <div className="col-sm-2 grey  text-right" style={{ fontWeight: 600 }}>
          Odstępstwo
        </div>
        <div className="col-sm-3 grey">
          <BooleanCheck field={f?.delivered_dtb_form_id !== f?.expected_dtb_form?.id} />
        </div>
      </div>

      <div className="row align-items-center pt-2 bt">
        <div className="col-sm-6 grey">Faktorant</div>
        <div className="col-sm-6 grey">Dłużnik</div>
      </div>
      <div className="row align-items-center pb-2 pt-0 bb">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-12">{props.claim?.agreement?.client?.name}</div>
            <div className="col-sm-12">{props.claim?.agreement?.client?.active_address?.street} {props.claim?.agreement?.client?.active_address?.building_no}
              {props.claim?.agreement?.client?.active_address?.local_no ? `/${props.claim?.agreement?.client?.active_address?.local_no}` : ''}</div>
            <div className="col-sm-12">   {props.claim?.agreement?.client?.active_address?.zipcode} {props.claim?.agreement?.client?.active_address?.city}</div>
          </div>
        </div>
        <div className="col-sm-2">
          <div className="row">
            <div className="col-sm-12">NIP <span className="float-right">{props.claim?.agreement?.client?.nip ?? '-'}</span> </div>
            <div className="col-sm-12">REGON <span className="float-right">{props.claim?.agreement?.client?.regon ?? '-'}</span></div>
            <div className="col-sm-12">KRS/CEIDG <span className="float-right">{props.claim?.agreement?.client?.ceidg ?? props.claim?.agreement?.client?.krs ?? '-'}</span></div>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-12">{debtor?.name}</div>
            <div className="col-sm-12">{debtor?.active_address?.street} {debtor?.active_address?.building_no}
              {debtor?.active_address?.local_no ? `/${debtor?.active_address?.local_no}` : ''}</div>
            <div className="col-sm-12">   {debtor?.active_address?.zipcode} {debtor?.active_address?.city}</div>
          </div>
        </div>
        <div className="col-sm-2">
          <div className="row">
            <div className="col-sm-12">NIP <span className="float-right">{debtor?.nip ?? '-'}</span> </div>
            <div className="col-sm-12">REGON <span className="float-right">{debtor?.regon ?? '-'}</span></div>
            <div className="col-sm-12">KRS/CEIDG <span className="float-right">{debtor?.ceidg ?? debtor?.krs ?? '-'}</span></div>
          </div>
        </div>
        {/*{getActorInfo(props.claim?.agreement?.client)}*/}
        {/*{getActorInfo(debtor)}*/}
      </div>

      <div className="row mt-3 mb-3">
        <div className="col-sm-6">
          <Textarea formName={props.formName} name="comment" placeholder="Komentarz..." />
        </div>
        <div className="col-sm-6 d-inline-flex align-items-center">
          <span className="grey mr-3" style={{ fontWeight: 600 }}>
            Wymagania formalne
          </span>
          <div className="w-50">
            {f.formal_expectation?.name || <Select number name="formal_expectation_id" formName={props.formName} options={formalExpectations} />}
          </div>
        </div>
      </div>

      <div className="row bb bt mt-4">
        <div className="col-2 grey p-2 br ">Dokument</div>
        <div className="col-2 grey p-2 br">Wygenerowano</div>
        <div className="col-2 grey p-2 br">Zarejestrowano</div>
        <div className="col-2 grey p-2 br">Nr szablonu / dokumentu</div>
        <div className="col-2 grey p-2 br">Zarejestrował</div>
        <div className="col-2 grey p-2">Data rejestracji</div>
      </div>
      {f.attachments?.map(a => (
        <div className="row bb">
          <div className="col-2 p-2 br ">{a.name === '' ? f.document_name?.name : a.name}</div>
          <div className="col-2 p-2 br">
            {a.generated_file ? (
              <>
                <button
                  type="button"
                  className="mr-2"
                  onClick={() => {
                    window.open(process.env.REACT_APP_BACKEND_LINK + a.generated_file, '_blank');
                  }}>
                  Pobierz
                </button>
                <button type="button" onClick={() => props.generate(props.formName, 'document_to_buy', a.id, 'generate', true)}>
                  Usuń
                </button>
              </>
            ) : a.template && f.is_factor_pattern ? (
              <button type="button" onClick={() => {
                props.generate(props.formName, 'document_to_buy', a.id, 'generate');
              }}>
                Generuj
              </button>
            ) : null}
          </div>
          <div className="col-2 p-2 br">
            {a.registered_file ? (
              <>
                <button
                  type="button"
                  className="mr-2"
                  onClick={() => {
                    window.open(process.env.REACT_APP_BACKEND_LINK + a.registered_file, '_blank');
                  }}>
                  Pobierz
                </button>{' '}
                <button
                  type="button"
                  onClick={() =>
                    a.template
                      ? props.generate(props.formName, 'document_to_buy', a.id, 'register', true)
                      : props.removeAttachment(`document_to_buy/${f.id}/attachments`, a.id)
                  }>
                  Usuń
                </button>
              </>
            ) : a.generated_file || !f.is_factor_pattern ? (
              <File
                text="Rejestruj"
                url={`claims/${props.claim?.id}/document_to_buy/${f.id}/attachments/${a.id}/register/`}
                objectId={a.id}
                field="attachments"
                formName={props.formName}
                noToast
                onSuccess={() => {
                  dispatch(ClaimActions.fetchDetails(props.claim?.id));
                  toast.success('Dokument zarejestrowany.');
                }}
                method="PATCH"
              />
            ) : null}
          </div>
          <div className="col-2 p-2 br">{a.identifier}</div>
          <div className="col-2 p-2 br">{a.author?.full_name}</div>
          <div className="col-2 p-2">{a.registered_date}</div>
        </div>
      ))}
      <div className="row mt-3">
        <div className="col-12 p-2 ">
          <File
            url={`claims/${props.claim?.id}/document_to_buy/${f.id}/attachments/`}
            field="attachments"
            fileName="registered_file"
            formName={props.formName}
          />
        </div>
      </div>

      {/*<div className="row bb bt mt-4">*/}
      {/*  <div className="col-1 grey p-2 br ">Dokument</div>*/}
      {/*  <div className="col-2 grey p-2 br">Nazwa pliku</div>*/}
      {/*  <div className="col-2 grey p-2 br">Zarejestrowano</div>*/}
      {/*  <div className="col-2 grey p-2 br">Nr dokumentu</div>*/}
      {/*  <div className="col-1 grey p-2 br">Forma</div>*/}
      {/*  <div className="col-2 grey p-2 br">Zarejestrował</div>*/}
      {/*  <div className="col-2 grey p-2">Data rejestracji</div>*/}
      {/*</div>*/}
      {/*<div className="row bb">*/}
      {/*  <div className="col-1 p-2 br ">{f.dtb_type?.name || dtbTypes.find(x => x.value === f.dtb_type_id)?.name || '-'}</div>*/}
      {/*  <div className="col-2 p-2 br">{f.attachment?.name ?? '-'}</div>*/}
      {/*  <div className="col-2 p-2 br">*/}
      {/*    {f.attachment ? (*/}
      {/*      <>*/}
      {/*        <button*/}
      {/*          type="button"*/}
      {/*          className=""*/}
      {/*          onClick={() => {*/}
      {/*            window.open(process.env.REACT_APP_BACKEND_LINK + f.attachment?.file, '_blank');*/}
      {/*          }}>*/}
      {/*          Podgląd*/}
      {/*        </button>*/}
      {/*        <button*/}
      {/*          type="button"*/}
      {/*          className=" ml-1"*/}
      {/*          onClick={() => {*/}
      {/*            props.removeAttachment(`document_to_buy/${f.id}/attachments`, a.id);*/}
      {/*            props.storeInput(props.formName, 'attachment', null);*/}
      {/*          }}>*/}
      {/*          Usuń*/}
      {/*        </button>*/}
      {/*      </>*/}
      {/*    ) : (*/}
      {/*      <File*/}
      {/*        single*/}
      {/*        text="Rejestruj"*/}
      {/*        url={`claims/${props.claim?.id}/document_to_buy/${f.id}/attachments/`}*/}
      {/*        field="attachment"*/}
      {/*        formName={props.formName}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*  <div className="col-2 p-2 br">{f.attachment?.id ?? '-'}</div>*/}
      {/*  <div className="col-1 p-2 br">{f.attachment?.form ?? '-'}</div>*/}
      {/*  <div className="col-2 p-2 br">{f.attachment?.author?.full_name ?? '-'}</div>*/}
      {/*  <div className="col-2 p-2">{f.attachment?.registered_date ?? '-'}</div>*/}
      {/*</div>*/}

      {props.withButtons ? (
        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button type="button" onClick={handleSave} className="btn btn-primary font-weight-light mr-4">
              Zapisz
            </button>

            <button
              type="button"
              onClick={props.onClose}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}>
              Zamknij
            </button>
          </div>
        </div>
      ) : null}
    </Form>
  );
};
