import React from 'react';

import { Checkbox, Datepicker,   Form, Input, Required, Select } from '../../../components/Forms';

export const RatesForm = (props) => {
  const f = props.forms && props.formName ? props.forms[props.formName] : {};

  const handleSaveForm = () => {
    props.saveForm();
  };

  return (
    <Form
      initialForm={{ calculationId: props.calculationId, margin_based_on_tran_risk: true, min_margin_control: true }}
      visibleOnMount={props.visibleOnMount}
      className="form-horizontal"
      formName={props.formName}
    >
      {props.visible ? (
        <>
          <div className="row  ">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <div className="col-sm-3 col-form-label label-right">Marża wg ryzyka transakcji</div>
                <div className="col-sm-6">
                  <Checkbox
                    onChange={() => props.getFlatRate(props.formName)}
                    className="custom-control custom-checkbox d-flex"
                    label=""
                    formName={props.formName}
                    name="margin_based_on_tran_risk"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row  ">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <div className="col-sm-3 col-form-label label-right">Kontrola marży minimalnej</div>
                <div className="col-sm-6">
                  <Checkbox
                    className="custom-control custom-checkbox d-flex"
                    label=""
                    formName={props.formName}
                    name="min_margin_control"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-2 font-weight-bold grey">
              Max wysokość zaliczki
              <Required />
            </div>
            <div className="col-sm-2 font-weight-bold grey">
              Max termin płatności
              <Required />
            </div>
            <div className="col-sm-1 font-weight-bold grey">
              Stawka
              <Required />
            </div>
            <div className="col-sm-1 font-weight-bold grey">Marża odsetkowa?</div>
            {f.is_intrest_margin ? (
              <>
                <div className="col-sm-2 font-weight-bold grey">Data stopy</div>
                <div className="col-sm-2 font-weight-bold grey">Stopa</div>
                <div className="col-sm-1 font-weight-bold grey">Czy zmienna</div>
                <div className="col-sm-1 font-weight-bold grey">Marża odsetkowa</div>
              </>
            ) : null}
          </div>
          <div className="row">
            <div className="col-sm-2 font-weight-bold grey">
              <Input
                formName={props.formName}
                type="number"
                name="max_advance_payment"
                number
                onChange={() => props.getFlatRate(props.formName)}
              />
            </div>
            <div className="col-sm-2 font-weight-bold grey">
              <Input
                formName={props.formName}
                type="number"
                name="max_payment_date"
                number
                onChange={() => props.getFlatRate(props.formName)}
              />
            </div>
            <div className="col-sm-1 font-weight-bold grey">
              <Input
                formName={props.formName}
                type="text"
                name="flat_rate"
                disabled={f.margin_based_on_tran_risk && props.hasRiskCategory}
              />
            </div>
            <div className="col-sm-1 font-weight-bold grey align-items-center">
              <Checkbox label=" " formName={props.formName} name="is_intrest_margin" />
            </div>
            {f.is_intrest_margin ? (
              <>
                <div className="col-sm-2 grey">
                  <Datepicker
                    onChange={() => {
                      props.clear(props.formName, 'intrest_margin.intrest_rate_id');
                      props.getIntrestRates(props.formName);
                    }}
                    formName={props.formName}
                    name="intrest_margin.date_of_rate"
                  />
                </div>
                <div className="col-sm-2 grey">
                  <Select
                    disabled={props.intrestRates.length === 0 || (f.intrest_margin && !f.intrest_margin.date_of_rate)}
                    formName={props.formName}
                    options={props.intrestRates}
                    number
                    name="intrest_margin.intrest_rate_id"
                  />
                </div>
                <div className="col-sm-1 font-weight-bold grey">
                  <Checkbox label=" " formName={props.formName} name="intrest_margin.is_variable" />
                </div>
                <div className="col-sm-1 font-weight-bold grey">
                  <Input formName={props.formName} type="text" name="intrest_margin.value" />
                </div>
              </>
            ) : null}
          </div>

          {f.is_intrest_margin && f.intrest_margin && f.intrest_margin.date_of_rate && props.intrestRates.length === 0 ? (
            <div className="row p-2">
              <div className="text-center text-danger">
                <p>
                  Nie określono stopy referencyjej dla wybranej daty.
                  {' '}
                  <u style={{ cursor: 'pointer' }} onClick={props.setLastAvailableDateOfRate}>
                    Czy wstawić ostatnią, która jest dostępna?
                  </u>
                </p>
              </div>
            </div>
          ) : null}

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancel}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}
                >
                  Anuluj
                </button>
                <button type="button" onClick={handleSaveForm} className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
