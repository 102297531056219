const proposal = service => ({
  update: async ({ term, modifiedDate, orderBy, direction, page = 1, limit = 10 } = {}) =>
    service.post('proposal/', {
      params: {
        page,
        limit,
        term,
        direction,
        order_by: orderBy,
        modified_date: modifiedDate
      }
    })
});

export default proposal;
