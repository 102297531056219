import FormActions from 'actions/forms';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { optionsCommon } from 'utilities/helpers';

import { Checkbox, Form, Input, Select, SelectButton, Textarea } from '../../../components/Forms';

export const AddressesForm = props => {
  const dispatch = useDispatch();
  const { forms } = useSelector(state => state.Forms);
  const { countryNames } = useSelector(state => state.Common);

  useEffect(() => {
    const form = forms[props.formName];
    if(!form?.country) {
      dispatch(FormActions.storeInput(props.formName, 'country', 'Polska'));
    }
  }, []);

  const addresses = props.addresses
    ? props.addresses.map(i => ({
      value: i.id,
      name: `${i.name} (${i.street} ${i.building_no}/${i.local_no || '-'}, ${i.zipcode} ${i.city})`
    }))
    : [];

  const populate = () => {
    const address = props.addresses.find(i => i.id === props.form.copyFrom);

    if (address) {
      props.populate(address);
    }
  };

  return (
    <Form initialForm={{ is_active: 1 }} visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row mt-3   align-items-center">
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <div className={'col-sm-4 col-form-label label-right mb-20'}>
                  Typ adresu
                </div>
                <div className={'col-sm-8'}>
                  <SelectButton
                    buttonOnClick={props.buttonOnClick}
                    buttonDisabled={props.selectButtonDisabled}
                    formName={props.formName}
                    options={props.addressTypes}
                    name="address_type"
                    value={props.selectButtonValue}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="form-group row  align-items-center">
                <div className="col-sm-12">
                  <Checkbox label="Aktywny?" formName={props.formName} name="is_active" />
                </div>
              </div>
            </div>
            <div className="col-sm-4 ">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Skopiuj z
                </label>
                <div className="col-sm-6">
                  <Select formName={props.formName} options={addresses} name="copyFrom" number onChange={populate} />
                </div>
              </div>
            </div>
          </div>
          {props.typeSelected === 2 ? (
            <div className="row ">
              <div className="col-sm-12">
                <div className="form-group row">
                  <label htmlFor="example-text-input" className="col-sm-2 label-right col-form-label">
                    Nazwa
                  </label>
                  <div className="col-sm-5 ">
                    <Input formName={props.formName} type="textarea" rows="2" name="name" />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="row ">
            <div className="col-sm-4">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Ulica
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="street" />
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-4">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Nr budynku
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="building_no" />
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Nr lokalu
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="local_no" />
                </div>
              </div>
            </div>
            <div className="col-sm-4 ">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Kod pocztowy
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="zipcode" />
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-4">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Miasto
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="city" />
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Województwo
                </label>
                <div className="col-sm-6">
                  <Select formName={props.formName} options={props.voivodeships} name="voivodeship" />
                </div>
              </div>
            </div>
            <div className="col-sm-4 ">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Kraj
                </label>
                <div className="col-sm-6">
                  <Select formName={props.formName} options={optionsCommon(countryNames, 'name')} name="country" />
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-4">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Powiat
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="county" />
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Gmina
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="commune" />
                </div>
              </div>
            </div>
            <div className="col-sm-4 ">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Poczta
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="post_office" />
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-12">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-2 label-right col-form-label">
                  Komentarz
                </label>
                <div className="col-sm-10 ">
                  <Textarea formName={props.formName} name="comment" />
                </div>
              </div>
            </div>
          </div>

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancelButtonClick}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}>
                  Anuluj
                </button>
                <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
