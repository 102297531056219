import React from 'react';

import Advance from '../../Creator/Components/Advance';
import Bids from '../Components/Bids';
import Params from '../Components/Params';

export default (props) => (
  <>
    <Params
      storeInput={props.storeInput}
      clear={props.clear}
      errors={props.errors}
      forms={props.forms.forms}
      common={props.common}
      formVisible={props.formVisible}
      claim={props.claim}
      edit={props.edit}
      toggleForm={props.toggleForm}
      saveForm={props.saveForm}
      recalculate={props.recalculate}
    />
    <Bids
      storeInput={props.storeInput}
      errors={props.errors}
      forms={props.forms.forms}
      common={props.common}
      formVisible={props.formVisible}
      claim={props.claim}
      edit={props.edit}
      toggleForm={props.toggleForm}
      saveForm={props.saveForm}
    />
    <Advance
      details
      recalculateAdvance={props.recalculateAdvance}
      clear={props.clear}
      errors={props.errors}
      forms={props.forms}
      common={props.common}
      formVisible={props.formVisible}
      claim={props.claim}
      edit={props.edit}
      toggleForm={props.toggleForm}
      saveForm={props.saveForm}
      storeInput={props.storeInput}
    />
  </>
);
