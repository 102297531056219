// import { cloneDeep } from 'lodash';

const initialState = {
  list: [],
  links: {},
  count: 0,
  currentPage: 1,
  formData: {},
  currentTab: 1,
  claim: {},
  version: {},
  preview: {},
  guarantors: {},
  debtorsDict: {},
  buckets: [],
  documents: {}
};

export default function Proposals(state = initialState, action) {
  switch (action.type) {
    case 'CLAIMS_FETCHED':
      return {
        ...state,
        list: action.payload.results,
        links: action.payload.links,
        currentPage: action.payload.current_page,
        count: action.payload.count
      };
    case 'CLAIM_FORMDATA_FETCHED':
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.resource]: action.payload.data
        }
      };
    case 'CLAIM_FOR_PREVIEW_FETCHED':
      return { ...state, preview: action.payload };
    case 'CLAIM_DETAILS_FETCHED':
    case 'CLAIM_DETAILS_UPDATED':
      return { ...state, claim: action.payload };
    case 'CLAIM_VERSION_FETCHED':
      return { ...state, version: action.payload };
    case 'CLAIM_TAB_CHANGED':
      return { ...state, currentTab: action.payload };
    case 'CLAIM_GUARANTORS_FETCHED':
      return { ...state, guarantors: action.payload };
    case 'CLAIM_DEBTORS_DICT_FETCHED':
      return { ...state, debtorsDict: action.payload };
    case 'CLAIM_BUCKETS_FETCHED':
      return { ...state, buckets: action.payload };
    case 'CLAIM_DOCUMENTS_FETCHED':
      return { ...state, documents: action.payload };
    default:
      return state;
  }
}
