import Card from 'components/Layout/Card';
import React from 'react';

import { Form, Selectable, SelectButton } from '../../../../components/Forms';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parsePhone } from '../../../../utilities/helpers';

export default (props) => {
  const field = `${props.prefix}_people`;
  const representationField = `${props.prefix}_representation_id`;

  const columns = [
    { name: 'Imię i nazwisko', getter: 'person.full_name', sortable: 1 },
    { name: 'E-mail', getter: 'person.active_email', sortable: 1 },
    {
      name: 'Telefon', getter: 'person.active_phone', sortable: 1, mutator: parsePhone
    },
    {
      name: 'Rola biznesowa',
      getter: 'roles',
      sortable: 1,
      mutator: (g) => g.map((i) => i.name).join()
    },
    { name: 'Ostatnia modyfikacja', getter: 'modified_date', sortable: 1 }
  ];

  const representationFormFor = (
    <Form formName={props.formName} asDiv>
      <div className="row align-items-center">

        <div htmlFor="example-text-input " className="col-sm-2 col-form-label label-right mb-15">
              Sposób reprezentacji
        </div>
        <div className="col-sm-3   d-flex align-items-center">
          <SelectButton
            buttonOnClick={() => props.clear(props.formName, representationField)}
            formName={props.formName}
            options={props.types?.map((r) => ({ ...r, value: r.id })) ?? []}
            name={representationField}
            number
          />
        </div>
      </div>
    </Form>

  );

  const options = props.options?.filter((o) => !props.representation?.find((r) => r.person_in_company?.id === o.id));

  return (
    <Card
      header={`Reprezentacja ${props.actorTitle}`}
      onEditClick={() => props.edit(props.formName, {
        [representationField]: props.representationType?.id
      })}
    >
      <div>
        <div className="row bb">
          <div className="col-sm-2 p-2 grey br">Imię i nazwisko</div>
          <div className="col-sm-2 p-2 grey br">Rola biznesowa</div>
          <div className="col-sm-2 p-2 grey br">Dodane do umowy</div>
          <div className="col-sm-2 p-2 grey">Dodał</div>
        </div>
        {
          props.representation?.length > 0 ? props.representation?.map((r) => (
            <RowEditable
              onRemove={() => props.remove('related_persons', r.id)}
            >
              <div className="col-sm-2 p-2 br">{r.person_in_company?.person?.full_name}</div>
              <div className="col-sm-2 p-2 br">{r.person_in_company?.roles?.map((x) => x.name).join(', ')}</div>
              <div className="col-sm-2 p-2 br">{r.created_date}</div>
              <div className="col-sm-2 p-2">{r.user_creator?.full_name}</div>
            </RowEditable>
          )) : (
            <div className="row bb">
              <div className="col-sm-12 p-2 ">Brak danych.</div>
            </div>
          )
        }
        <div className="row">
          <div className="col-sm-2 grey p-2 br">
            Sposób reprezentacji
          </div>
          <div className="col-sm-9 p-2">
            <span style={{
              padding: '0.5rem',
              borderRadius: '8px',
              width: '150px',
              height: '10px',
              background: `${props.representationType?.background_color ?? ''} 0% 0% no-repeat padding-box`,
              opacity: 1
            }}
            >
              {props.representationType?.name}
            </span>
          </div>
        </div>
      </div>

      <Selectable
        fetch={() => {}}
        visible={props.formVisible(props.formName)}
        formName={props.formName}
        field={field}
        columns={columns}
        options={options}
        onClickAway={() => props.toggleForm(props.formName)}
        header={`Wybierz reprezentantów ${props.actorTitle}`}
        multiple
        title={representationFormFor}
        onSelected={props.onSelected}
      />
    </Card>
  );
};
