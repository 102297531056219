import React from 'react';
import { Link } from 'react-router-dom';
import Constants from 'utilities/constants';

const Tab = props => (
  <>
    <ul className="nav nav-tabs" role="tablist">
      {Object.keys(Constants.Tabs).map(tab => (
        <li className="nav-item" key={tab} id={`tab-${Constants.Tabs[tab].name}`}>
          <Link className={props.name === Constants.Tabs[tab].name ? 'nav-link active' : 'nav-link'} to={Constants.Tabs[tab].link}>
            {Constants.Tabs[tab].icon && <i className={`${Constants.Tabs[tab].icon} mr-2`} />}
            {Constants.Tabs[tab].name}
          </Link>
        </li>
      ))}
    </ul>

    <div className="tab-content tabcontent-border" style={{ background: 'white' }}>
      <div className="tab-pane active p-20" id="#" role="tabpanel">
        {props.children}
      </div>
    </div>
  </>
);

export default Tab;
