import FormActions from 'actions/forms';
import { BaseModal } from 'components/Layout/OldModal';
import { isEditableProposal } from 'containers/Proposals/Details/Index';
import { t } from 'lang/pl';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import BooleanCheck from '../../../../../components/BooleanCheck';
import LiabilitiesForm from '../../../Forms/Liabilities/Public';
import { formatValueToEurope } from 'utilities/helpers';

const formName = 'PublicLiabilitiesForm';

export default (props) => {
  const dispatch = useDispatch();

  const p = props.version || {};

  const handleEdit = () => {
    if(!isEditableProposal(props.proposal.status, props.version.status)) {
      toast.error(t.error.toast.statusWarning);
    } else {
      dispatch(FormActions.initialize(formName, {
        no_social_security_arrears: p.no_social_security_arrears,
        social_security_arrears_debt: p.social_security_arrears_debt,
        no_tax_arrears: p.no_tax_arrears,
        tax_arrears_debt: p.tax_arrears_debt
      }, {}));
      dispatch(FormActions.toggleForm(formName));
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12 p-2 text-themecolor">
              Zobowiązania publicznoprawne
              <span className="float-right" style={{ cursor: 'pointer' }}>
                <i className="fas fa-pencil-alt ml-2" onClick={handleEdit} />
              </span>
            </div>
          </div>
          <div className="row bt">
            <div className="col-sm-3 p-2 br">Brak zaległości podatkowych</div>
            <div className="col-sm-1 p-2 grey">
              <BooleanCheck field={p.no_tax_arrears} />

            </div>
            {!p.no_tax_arrears && (
              <div className="col-sm-3 p-2 ">
                {p.tax_arrears_debt}
                {' '}
                {p.currency?.name}
              </div>
            )}
          </div>
          <div className="row bt bb">
            <div className="col-sm-3 p-2 br ">Brak zaległości z tytułu składek na ZUS</div>
            <div className="col-sm-1 p-2 grey">
              <BooleanCheck field={p.no_social_security_arrears} />
            </div>
            {!p.no_social_security_arrears && (
              <div className="col-sm-3 p-2 ">
                {formatValueToEurope(p.social_security_arrears_debt, p.currency?.name)}
              </div>
            )}
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Zobowiązania pozostałe</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <LiabilitiesForm
          populate={() => props.populateForm(formName)}
          forms={props.forms}
          currencies={props.currencies}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(formName)}
          formName={formName}
          submitForm={() => props.saveForm(formName)}
          onCancelButtonClick={() => props.toggleForm(formName)}
          withButtons
        />

      </BaseModal>
    </>
  );
};
