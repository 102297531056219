import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import React, { createContext, useState } from 'react';
import { setProperty } from 'utilities/helpers';

const defaultState = {
  theme: {
    overrides: {
      MuiOutlinedInput: {
        input: {
          padding: '7px'
        }
      }
      // MuiTooltip: {
      //   popper: {
      //     zIndex: '99999999999999 !important'
      //   }
      // },
      // MuiPopover: {
      //   root: {
      //     zIndex: '9999999999999 !important'
      //   }
      // }
      // MuiDialog: { From component PopupContainer }
    },
    typography: {
      'fontFamily': '"Segoe UI Light", sans-serif'
    },
    palette: {
      primary: { main: '#3b81b9' },
      secondary: { main: '#25848d' }
    }
  },
  setTheme: () => {}
};

const Context = createContext(defaultState);

function ThemeProvider({ children }) {
  const [theme, updateTheme] = useState(createMuiTheme(defaultState.theme));

  const setTheme = object => {
    updateTheme(createMuiTheme(object));
  };

  const setPrimaryColor = value => {
    setProperty('--color-primary', value);

    const newTheme = {
      ...defaultState.theme,
      palette: {
        ...defaultState.theme.palette,
        primary: { main: value }
      }
    };

    updateTheme(createMuiTheme(newTheme));
  };

  return (
    <Context.Provider
      value={{
        theme,
        setTheme,
        setPrimaryColor
      }}
    >
      <MuiThemeProvider theme={theme}>
        {children}
      </MuiThemeProvider>
    </Context.Provider>
  );
}

export { ThemeProvider };

export default Context;
