import 'twin.macro';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { formatString } from 'utilities/helpers';

export default function Tooltip({ path, badgeCls, spanCls, className, tip, label, ...props }) {
  const history = useHistory();

  const handleSlug = () => {
    path && !path.includes('undefined') && history.push(path);
  };

  return (
    <div {...props}>
      {tip && <ReactTooltip id={label} />}
      <span className={clsx(badgeCls, spanCls, className)} data-tip={tip} data-for={label} onClick={handleSlug}>
        {formatString(label)}
      </span>
    </div>
  );
}

Tooltip.propTypes = {
  label: PropTypes.string.isRequired,
  tip: PropTypes.string
};

Tooltip.defaultProps = {
  badgeCls: 'badge text-0.82 pl-2 pr-2',
  spanCls: 'badge-secondary'
};
