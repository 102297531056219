import { t } from 'lang/pl';
import { get } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { isAnyEmpty } from 'utilities/helpers';

function getDefaultMessage(error) {
  switch (error?.type) {
    case 'required': return t.error.field.default;
    case 'minLength': return t.error.field.length;
    case 'maxLength': return t.error.field.maxLength;
    default: return t.error.field.default;
  }
}

export default function ErrorMessageTemp({ error, ...props }) {
  const customMessage = error?.message;

  return (
    <div className="form-control-feedback small min-h-15" style={{ color: 'red' }} {...props}>
      {isAnyEmpty(customMessage) ? getDefaultMessage(error) : customMessage}
    </div>
  );
}

export function ErrorMessageR({ formName, name, ...props }) {
  const { errors } = useSelector(state => state.Forms);
  const error = get(errors[formName], name, '');

  return (
    <div className="form-control-feedback small min-h-10 mt-05" style={{ color: 'red' }} {...props}>
      {error}
    </div>
  );
}
