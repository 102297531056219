import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import { t } from 'lang/pl';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import { BuyoutDocsForm } from '../../Forms/BuyoutDocs';
import RemovalModal from '../../Forms/RemovalModal';

export const BuyoutDocs = (props) => {
  const { BuyoutDocsForm: form } = useSelector(state => state.Forms.forms);
  const [objectToRemove, setObjectToRemove] = useState({});

  const formName = 'BuyoutDocsForm';

  const docs = props.calculation && props.calculation.documents_to_buy ? props.calculation.documents_to_buy : [];

  const handleSaveForm = () => {
    if(!form?.dtb_form_id || !form?.dtb_type?.id) {
      toast.warn(t.error.toast.fieldWarning);
    } else {
      props.saveForm(formName);
    }
  };

  return (
    <Card header='Dokumenty do wykupu' onAddClick={() => props.create(formName)}>
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row bb grey">
              <div className="col-sm-3 p-2 br">Rodzaj dokumentu</div>
              <div className="col-sm-3 p-2">Oczekiwana forma dokumentu</div>
            </div>
          </div>
          <div className="col-sm-12">
            {docs.map((doc) => (
              <RowEditable
                isObjectToRemove
                itemId={doc.id}
                onRemove={() => {
                  setObjectToRemove(doc);
                }}
              >
                <div className="col-sm-3 p-2 br">{doc.dtb_type ? doc.dtb_type.name : '-'}</div>
                <div className="col-sm-3 p-2">{doc.dtb_form ? doc.dtb_form.name : '-'}</div>
              </RowEditable>
            ))}
          </div>
        </div>
      </div>
      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Dokumenty do wykupu</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <BuyoutDocsForm
          // companySelected={companySelected}
          filtersForm={props.filtersForm}
          offer={props.offer}
          dtb_type={props.forms[formName] ? props.forms[formName].dtb_type : null}
          documents={props.documents}
          expectedForms={props.expectedForms}
          preloadedForm
          visibleOnMount={false}
          clear={props.clear}
          visible={props.formVisible(formName)}
          formVisible={props.formVisible}
          formName={formName}
          submitForm={handleSaveForm}
          onCancel={() => props.onCancel(formName)}
          withButtons
          errors={props.errors[formName]}
          forms={props.forms}
          toggleForm={props.toggleForm}
          fetch={() => {}}
        />
      </BaseModal>

      <RemovalModal
        visible={Object.keys(objectToRemove).length > 0}
        toggle={() => setObjectToRemove({})}
        remove={() => {
          props.remove(objectToRemove);
          setObjectToRemove({});
        }}
      >
        {objectToRemove && (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="row bb grey">
                  <div className="col-sm-3 p-2 br">Rodzaj dokumentu</div>
                  <div className="col-sm-3 p-2">Oczekiwana forma dokumentu</div>
                </div>
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br">{objectToRemove.dtb_type ? objectToRemove.dtb_type.name : '-'}</div>
              <div className="col-sm-3 p-2">{objectToRemove.dtb_form ? objectToRemove.dtb_form.name : '-'}</div>
            </div>
          </div>
        )}
      </RemovalModal>
    </Card>
  );
};
