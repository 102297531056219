import OrderBy from 'components/Forms/OldOrderBy';
import ListFilters from 'components/OldListFilters';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CommonActions from '../../../actions/common';
import FormActions from '../../../actions/forms';
import InvoiceActions from '../../../actions/invoice';
import BooleanCheck from '../../../components/BooleanCheck';
import { Form } from '../../../components/Forms';
import { Container, MainContainer } from '../../../components/Layout';
import { BaseModal } from 'components/Layout/OldModal';
import { OldPagination } from 'components/Layout/OldPagination';
import { RowEditable } from '../../../components/Layout/RowEditable';
import Loader from '../../../components/Loader';
import Constants from '../../../utilities/constants';
import { parseMoney, formatNip } from '../../../utilities/helpers';
import { AcceptInvoice } from '../Forms/AcceptInvoice';
import { CreateInvoice } from '../Forms/CreateInvoice';
import { Searchbar } from './Searchbar';

class TransferList extends React.Component {
  constructor(props) {
    super(props);

    this.filtersForm = 'TransfersFilters';
    this.acceptInvoice = 'AcceptInvoiceForm';
    this.f = 'InvoiceForm';

    this.props.dispatch(CommonActions.changeView(Constants.Views.List));
    // this.fetchResults();

    this.state = {
      tab: 1,
      ids: [],
      status: 2,
      modalTab: 1
    };
  }

  componentDidMount() {
    this.props.dispatch(CommonActions.setContextMenu());

    const { search } = this.props.location;
    const tab = search?.split('=')?.[1] || 1;

    this.changeTab(Number(tab));
  }

  getFilters() {
    const currentFilters = this.props.forms.forms[this.filtersForm] || {};

    if (!currentFilters || Object.keys(currentFilters).length <= Object.keys(CommonActions.defaultFilters).length) {
      this.props.dispatch(FormActions.initialize(this.filtersForm, CommonActions.defaultFilters, {}));
    }

    return { ...CommonActions.defaultFilters, ...this.props.forms.forms[this.filtersForm] };
  }

  changePage = page => {
    this.props.dispatch(FormActions.storeInput(this.filtersForm, 'page', page));
    this.fetchResults();
  };

  fetchResults() {
    this.props.dispatch(InvoiceActions.fetch(this.getFilters()));
  }

  toggleForm(f) {
    this.props.dispatch(FormActions.toggleForm(f));
  }

  formVisible(f) {
    return this.props.forms.options.visible.indexOf(f) !== -1;
  }

  edit(name, obj) {
    this.props.dispatch(FormActions.initialize(name, obj, {}));
    this.toggleForm(name);
  }

  changeTab(tab) {
    switch (tab) {
      case 1:
        this.props.dispatch(FormActions.storeInput(this.filtersForm, 'sales', 1));
        this.props.dispatch(FormActions.clearField(this.filtersForm, 'correction'));
        this.props.dispatch(FormActions.clearField(this.filtersForm, 'category'));
        break;
      case 2:
        this.props.dispatch(FormActions.storeInput(this.filtersForm, 'correction', 1));
        this.props.dispatch(FormActions.clearField(this.filtersForm, 'status'));
        this.props.dispatch(FormActions.clearField(this.filtersForm, 'sales'));
        break;
      case 3:
        this.props.dispatch(FormActions.storeInput(this.filtersForm, 'category', 2));
        this.props.dispatch(FormActions.clearField(this.filtersForm, 'sales'));
        this.props.dispatch(FormActions.clearField(this.filtersForm, 'correction'));
        break;
      default:
        break;
    }

    this.setState({ tab }, () => {
      this.fetchResults();
    });
  }

  selectForExport(i) {
    const { ids } = this.state;
    const newIds = ids.includes(i) ? ids.filter(y => y !== i) : [...ids, i];

    this.setState({
      ids: newIds
      // all: isEqual(newIds.sort(), this.props.due.list.map((t) => t.id).sort()),
    });
  }

  isSelectedForExport(transferId) {
    return this.state.ids.includes(transferId);
  }

  createInvoice(category = 0) {
    this.props.dispatch(FormActions.initialize(this.f, { category }, {}));
    this.toggleForm(this.f);
  }

  showInvoice(invoice, mode = undefined) {
    this.props.dispatch(InvoiceActions.showDetails(invoice.id, this.f, mode));
  }

  changeStatus(status) {
    this.setState(
      {
        status
      },
      () => {
        this.toggleForm(this.acceptInvoice);
      }
    );
  }

  remove(invoice) {
    this.props.dispatch(InvoiceActions.remove(invoice.id));
  }

  getTitleByMode(invoice) {
    switch (invoice?.mode) {
      case 'details':
        return 'Podgląd dokumentu sprzedaży (Faktura VAT)';
      case 'edit':
        return 'Edycja dokumentu sprzedaży (Faktura VAT)';
      default:
        return 'Wystawianie dokumentu sprzedaży (Faktura VAT)';
    }
  }

  selectedRow() {
    if (this.state.ids.length === 0) return undefined;

    return this.props.invoice?.list?.find(x => x.id === this.state.ids?.[0]);
  }

  render() {
    const rows = this.props.invoice.list || [];
    const { tab } = this.state;

    return (
      <Container>
        <MainContainer className="col-sm-12">
          <Searchbar
            title="Dokumenty sprzedaży"
            name="is_settled"
            formName={this.filtersForm}
            options={[
              { value: null, name: 'Rozliczone i nierozliczone' },
              { value: 1, name: 'Tylko rozliczone' },
              { value: 0, name: 'Tylko nierozliczone' }
            ]}
            onSubmit={() => this.fetchResults()}
            records={this.props.invoice.count}
          />

          <ul className="nav nav-tabs" role="tablist">
            <li onClick={() => this.props.history.push('/faktury?tab=1')} className={`nav-item nav-link ${tab === 1 ? 'active' : ''}`}>
              Dokumenty sprzedaży
            </li>
            <li onClick={() => this.props.history.push('/faktury?tab=2')} className={`nav-item nav-link ${tab === 2 ? 'active' : ''}`}>
              Korekty
            </li>
            <li onClick={() => this.props.history.push('/faktury?tab=3')} className={`nav-item nav-link ${tab === 3 ? 'active' : ''}`}>
              Dokumenty zakupu
            </li>
          </ul>

          <div className="tab-content tabcontent-border" style={{ background: 'white' }}>
            {this.props.common.loading ? (
              <Loader />
            ) : (
              <div className="tab-pane active p-20" id="#" role="tabpanel">
                <div className="row mb-4 mt-3 align-items-center">
                  <div className="col-sm-9">
                    <ListFilters formName={this.filtersForm} onSubmit={() => this.fetchResults()} />
                  </div>
                  <div className="col-sm-3 text-right">
                    <h4 className="text-themecolor" style={{ cursor: 'pointer' }}>
                      {/* <i className="fa fa-ellipsis-h mr-2" /> */}
                      {/* <i className="fa fa-plus" onClick={() => this.createTransfer()} /> */}
                    </h4>
                  </div>
                </div>
                <div className="row bt bb">
                  <div className="col-sm-12">
                    <div className="row grey " style={{ fontSize: '90%' }}>
                      <div className="col-sm-2 pl-2 pr-1 pt-2 pb-2 br ">
                        {/* <BooleanCheck field={false} /> */}
                        <OrderBy
                          field="identifier"
                          spanClassName="ml-0"
                          column="Numer faktury"
                          formName={this.filtersForm}
                          onSubmit={() => this.fetchResults()}
                        />
                      </div>
                      <div className="col-sm-1 pl-1 pr-0 pt-2 pb-2 br">
                        <OrderBy
                          field="issue_date"
                          spanClassName="ml-0"
                          column="Data wystawienia"
                          formName={this.filtersForm}
                          onSubmit={() => this.fetchResults()}
                        />
                      </div>

                      <div className="col-sm-1 pl-2 pr-1 pt-2 pb-2 br">
                        <OrderBy
                          field="sale_date"
                          spanClassName="ml-0"
                          column="Data sprzedaży"
                          formName={this.filtersForm}
                          onSubmit={() => this.fetchResults()}
                        />
                      </div>
                      <div className="col-sm-1 pl-2 pr-1 pt-2 pb-2 br">
                        <OrderBy
                          field="gross_total"
                          spanClassName="ml-0"
                          column="Kwota brutto"
                          formName={this.filtersForm}
                          onSubmit={() => this.fetchResults()}
                        />
                      </div>
                      <div className="col-sm-1 pl-2 pr-1 pt-2 pb-2 br">
                        <OrderBy field="vat" spanClassName="ml-0" column="VAT" formName={this.filtersForm} onSubmit={() => this.fetchResults()} />
                      </div>
                      <div className="col-sm-2 pl-2 pr-1 pt-2 pb-2 br">
                        <OrderBy
                          field="buyer__name"
                          spanClassName="ml-0"
                          column="Kontrahent"
                          formName={this.filtersForm}
                          onSubmit={() => this.fetchResults()}
                        />
                      </div>
                      <div className="col-sm-1 pl-2 pr-1 pt-2 pb-2 br">
                        <OrderBy field="nip" spanClassName="ml-0" column="NIP" formName={this.filtersForm} onSubmit={() => this.fetchResults()} />
                      </div>
                      <div className="col-sm-1 pl-2 pr-1 pt-2 pb-2 br">
                        <OrderBy
                          field="is_settled"
                          spanClassName="ml-0"
                          column="Czy rozliczona"
                          formName={this.filtersForm}
                          onSubmit={() => this.fetchResults()}
                        />
                      </div>
                      <div className="col-sm-1 pl-2 pr-1 pt-2 pb-2 br">
                        <OrderBy
                          field="has_correction"
                          spanClassName="ml-0"
                          column="Czy posiada korektę?"
                          formName={this.filtersForm}
                          onSubmit={() => this.fetchResults()}
                        />
                      </div>
                      <div className="col-sm-1 pl-2 pr-1 pt-2 pb-2">
                        <OrderBy
                          field="status"
                          spanClassName="ml-0"
                          column="Status"
                          formName={this.filtersForm}
                          onSubmit={() => this.fetchResults()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Form visibleOnMount style={{ padding: 0 }} formName={this.selectForExportForm}>
                  {rows.map(i => (
                    <RowEditable
                      onClick={() => this.selectForExport(i.id)}
                      onDoubleClick={() => this.showInvoice(i)}
                      onEdit={i.status < 2 ? () => this.showInvoice(i, 'edit') : undefined}
                      onRemove={i.status < 2 ? () => this.remove(i) : undefined}>
                      <div className="col-sm-2 p-2 br d-flex align-items-center">
                        <BooleanCheck className="mr-2" field={this.isSelectedForExport(i.id)} onClick={() => this.selectForExport(i.id)} />
                        {i.identifier || '-'}
                      </div>
                      <div className="col-sm-1 p-2 br">{i.issue_date || '-'}</div>
                      <div className="col-sm-1 p-2 br">{i.sale_date || '-'}</div>
                      <div className="col-sm-1 p-2 br">
                        {parseMoney(i.gross_total)} {i.currency?.name}
                      </div>
                      <div className="col-sm-1 p-2 br">
                        {parseMoney(i.vat_total)} {i.currency?.name}
                      </div>
                      <div className="col-sm-2 p-2 br">{i.buyer?.name || '-'}</div>
                      <div className="col-sm-1 p-2 br">{formatNip(i.buyer?.nip)}</div>
                      <div className="col-sm-1 p-2 br">{i.is_settled ? 'tak' : 'nie'}</div>
                      <div className="col-sm-1 p-2 br">{i.has_correction ? 'tak' : 'nie'}</div>
                      <div className="col-sm-1 p-2">{this.props.common?.invoiceData?.statuses?.[i.status]}</div>
                    </RowEditable>
                  ))}
                </Form>
                <div className="row mt-4">
                  <div className="col-sm-8">
                    <button disabled={!this.selectedRow()} className="btn-green" type="button" onClick={() => this.changeStatus(2)}>
                      Zatwierdź fakturę
                    </button>
                    <button disabled={!this.selectedRow() || this.selectedRow()?.status < 1} className="ml-2 btn-green" type="button" onClick={() => this.changeStatus(-1)}>
                      Rozlicz
                    </button>
                    <button disabled={!this.selectedRow() || this.selectedRow()?.status < 2} className="ml-2 btn-green" type="button" onClick={() => this.changeStatus(3)}>
                      Zaksięguj
                    </button>
                    <button
                      disabled={!this.selectedRow() || this.selectedRow().status !== 3 || this.selectedRow().has_correction}
                      className="ml-2 btn-green"
                      type="button"
                      onClick={
                        this.selectedRow() && this.selectedRow().status === 3 && !this.selectedRow()?.has_correction ?
                          () => this.showInvoice(this.selectedRow(), 'correction') : undefined
                      }>
                      Wystaw korektę
                    </button>
                    <button
                      disabled={!this.selectedRow() || this.selectedRow()?.status >= 2}
                      className="ml-2 btn-green"
                      type="button"
                      onClick={() => {
                        this.props.dispatch(InvoiceActions.print(this.state.ids[0], null));
                      }}>
                      Drukuj
                    </button>
                  </div>
                  <div className="col-sm-4 text-right">
                    <button className="btn-green" type="button" onClick={() => this.createInvoice(3)}>
                      Wystaw notę
                    </button>
                    <button className="ml-2 btn-green" type="button" onClick={() => this.createInvoice()}>
                      Wystaw fakturę
                    </button>
                  </div>
                </div>

                {/* <BaseModal */}
                {/*  visible={this.formVisible(this.generateNote)} */}
                {/*  header="Generowanie noty" */}
                {/*  footer="" */}
                {/*  onClickAway={() => this.toggleForm(this.generateNote)} */}
                {/* > */}
                {/*  <GenerateNote */}
                {/*    withButtons */}
                {/*    formName={this.generateNote} */}
                {/*    clear={(f, v) => this.props.dispatch(FormActions.clearField(f, v))} */}
                {/*    toggleForm={(f) => this.toggleForm(f)} */}
                {/*  /> */}
                {/* </BaseModal> */}

                <BaseModal
                  visible={this.formVisible(this.acceptInvoice)}
                  header="Zatwierdzanie faktury"
                  footer=""
                  onClickAway={() => this.toggleForm(this.acceptInvoice)}>
                  <AcceptInvoice
                    withButtons
                    formName={this.acceptInvoice}
                    toggleForm={f => this.toggleForm(f)}
                    settling={this.state.status === -1}
                    status={this.props.common?.invoiceData?.statuses?.[this.state.status]}
                    ids={this.state.ids}
                    submitForm={() =>
                      this.props.dispatch(
                        InvoiceActions.acceptInvoices(this.state.ids, this.getFilters(), this.acceptInvoice, this.state.status, () =>
                          this.setState({ ids: [] })
                        )
                      )
                    }
                  />
                </BaseModal>

                <BaseModal
                  size="extralarge"
                  visible={this.formVisible(this.f)}
                  footer=""
                  onClickAway={() => this.toggleForm(this.f)}
                  noHeader
                  contentStyle={{ padding: 0 }}>
                  <CreateInvoice
                    clear={(f,n) => this.props.dispatch(FormActions.clearField(f,n))}
                    edit={(name, obj) => this.edit(name, obj)}
                    header={this.getTitleByMode(this.props.forms?.forms?.[this.f])}
                    common={this.props.common}
                    agreements={this.props.invoice.agreements}
                    agreementFilters={'af'}
                    fetchAgreements={() => this.props.dispatch(InvoiceActions.fetchAgreements('af'))}
                    docs={this.props.invoice.docs}
                    docsFilters={'df'}
                    fetchDocs={() => this.props.dispatch(InvoiceActions.fetchDocs('df'))}
                    factors={this.props.invoice.factors}
                    factorFilters={'ff'}
                    fetchFactors={() => this.props.dispatch(InvoiceActions.fetchFactors('ff'))}
                    clients={this.props.invoice.clients}
                    clientFilters={'cf'}
                    fetchClients={() => this.props.dispatch(InvoiceActions.fetchClients('cf'))}
                    accounts={this.props.invoice.accounts}
                    fetchAccounts={() => this.props.dispatch(InvoiceActions.fetchAccounts(this.f))}
                    forms={this.props.forms?.forms}
                    withButtons
                    formName={this.f}
                    storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
                    toggleForm={f => this.toggleForm(f)}
                    formVisible={f => this.formVisible(f)}
                    submitForm={(f = this.f) => this.props.dispatch(InvoiceActions.saveForm(f, null, true))}
                    print={id => this.props.dispatch(InvoiceActions.print(id, this.f))}
                    user={this.props.user}
                    initialize={(f, o) => this.props.dispatch(FormActions.initialize(f, o, null))}
                    removeItem={(invoiceId, itemId) => this.props.dispatch(InvoiceActions.removeItem(invoiceId, itemId))}
                    removeIncome={(fvId, income) => this.props.dispatch(InvoiceActions.removeIncome(fvId, income))}
                  />
                </BaseModal>
              </div>
            )}
          </div>

          <OldPagination
            onList
            currentPage={this.props.invoice?.currentPage}
            changePage={this.changePage}
            links={this.props.invoice?.links}
            count={this.props.invoice?.count}
          />
        </MainContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  forms: state.Forms,
  common: state.Common,
  invoice: state.Invoice,
  user: state.Authorization.user
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(TransferList)
);
