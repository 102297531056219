import AuthorizationService from '../services/authorizarion';
import { SF_ACCESS_TOKEN, SF_REFRESH_TOKEN } from '../utilities/http';
import FormActions from './forms';
import { AUTHORIZATION_USER_DATA_FETCHED } from 'reducers/authorization';

const login = (formName, push) => (dispatch, getState) => {
  const data = getState().Forms.forms[formName];
  dispatch(FormActions.clearErrors(formName));

  return AuthorizationService.login(data)
    .then(response => {
      localStorage.setItem(SF_ACCESS_TOKEN, response.data.access_token);
      localStorage.setItem(SF_REFRESH_TOKEN, response.data.refresh_token);

      return AuthorizationService.getUserInfo().then(res => {
        dispatch({
          type: AUTHORIZATION_USER_DATA_FETCHED,
          payload: res.data
        });

        return push('/osoby');
      });
    })
    .catch(errors => dispatch(FormActions.handleErrors(formName, errors?.response?.data)));
};

const logout = () => dispatch =>
  AuthorizationService.logout().then(() => {
    dispatch({ type: 'LOGOUT' });
    dispatch({ type: 'COMMON_CLEAR_DICTIONARIES' });
    window.location.href = '/';
  });

export default {
  login,
  logout
};
