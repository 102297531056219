import FormActions from 'actions/forms';
import React from 'react';
import { connect } from 'react-redux';

export default connect(
  (state) => ({ forms: state.Forms }),
  (dispatch) => ({
    raiseErrors: (f, e) => dispatch(FormActions.handleErrors(f, e))
  })
)((props) => {
  const validateForm = () => {
    const form = props.formName ? props.forms.forms[props.formName] : null;
    const rules = props.formName && props.forms.rules ? props.forms.rules[props.formName] : null;
    const errors = {};

    if (rules) {
      Object.keys(rules).forEach((field) => {
        rules[field].forEach((rule) => {
          if (!rule(form[field])) {
            const msg = 'Błędna wartość pola.';
            errors[field] = [msg];
          }
        });
      });
    }

    return errors;
  };

  const saveForm = () => {
    const errors = validateForm();

    if (errors && Object.keys(errors).length > 0) {
      props.raiseErrors(props.formName, errors);
    } else {
      props.onSubmit();
    }
  };

  const saveAndLeaveForm = () => {
    const errors = validateForm();

    if (errors && Object.keys(errors).length > 0) {
      props.raiseErrors(props.formName, errors);
    } else {
      props.onSubmitAndLeave();
    }
  };

  return (
    <div className={`row ${props.className}`} style={props.style}>
      <div className="mt-4 col-sm-12 text-center" style={props.containerStyle}>
        {props.onCancel && (
          <button type="button" onClick={props.onCancel} className="btn btn-primary-outline mr-2 font-weight-light" style={{ color: '#4B4E55' }}>
            {props.cancelButton || 'Anuluj'}
          </button>
        )}
        {props.isSubmitVisible && props.onSubmit && (
          <button type="button" onClick={saveForm} className={props.submitButtonClassNames || 'btn btn-primary ml-2 font-weight-light'}>
            {props.submitButton || 'Zapisz'}
          </button>
        )}
        {props.onRemove && (
          <button type="button" onClick={props.onRemove} className="btn btn-danger ml-2 pl-5 pr-5  font-weight-light">
            {props.removeButton || 'Usuń'}
          </button>
        )}
        {props.onSubmitAndLeave && (
          <button type="button" onClick={saveAndLeaveForm} className="btn btn-primary ml-2  font-weight-light">
            {props.submitAndLeaveButton || 'Zapisz i zamknij'}
          </button>
        )}
      </div>
    </div>
  );
});
