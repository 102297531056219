import { cloneDeep } from 'lodash';
import { updateObject } from 'utilities/helpers';

const PERSONS_UPDATE = 'person/UPDATE';

const initialState = {
  list: [],
  links: {},
  count: 0,
  currentPage: 1,
  person: {}
};

export default function Persons(state = initialState, action) {
  switch (action.type) {
    case 'PERSONS_FETCHED':
      return {
        ...state,
        list: action.payload.results,
        links: action.payload.links,
        currentPage: action.payload.current_page,
        count: action.payload.count,
        person: action.payload.results[0] || {}
      };
    case 'PERSONS_SELECTED_FOR_PREVIEW':
    case 'PERSONS_DETAILS_FETCHED':
      return { ...state, person: action.payload };
    case 'PERSONS_DETAILS_UPDATED':
      state.person = Object.assign(state.person, action.payload);
      return cloneDeep(state);
    case PERSONS_UPDATE:
      return updateObject(state, { value: action.payload });
    default:
      return state;
  }
}

export const updatePersons = state => {
  return { type: PERSONS_UPDATE, payload: state };
};
