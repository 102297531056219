import FormActions from 'actions/forms';
import Card from 'components/Layout/Card';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined } from 'utilities/helpers';

import LiabilitiesForm from '../../../Forms/Liabilities/Public';

export default props => {
  const dispatch = useDispatch();
  const { PublicLiabilitiesCreatorForm: form } = useSelector(state => state.Forms.forms);
  const formName = 'PublicLiabilitiesCreatorForm';

  useEffect(() => {
    if (props.populateForm) {
      props.populateForm(formName);
    }
    // eslint-disable-next-line
  }, props);

  const isBlocked = isUndefined(form?.no_tax_arrears) || isUndefined(form?.no_social_security_arrears);

  const handleBackLightFields = () => {
    dispatch(FormActions.handleValidErrors(formName, ['no_tax_arrears', 'no_social_security_arrears']));
  };

  return (
    <Card mode='creator' header='+ Zobowiązania publicznoprawne' onSaveClick={() => props.saveForm(formName, true)} isBlocked={isBlocked} onBlockedMessage={handleBackLightFields}>
      <div/>
      <LiabilitiesForm
        populate={() => props.populateForm(formName)}
        forms={props.forms}
        currencies={props.currencies}
        preloadedForm
        visibleOnMount
        visible
        clear={props.clear}
        formName={formName}
        submitForm={() => props.saveForm(formName)}
        onCancelButtonClick={() => props.toggleForm(formName)}
      />
    </Card>
  );
};
