// import { cloneDeep } from 'lodash';

const initialState = {
  list: [],
  links: {},
  count: 0,
  currentPage: 1,
  formData: {},
  currentTab: 1,
  proposal: {},
  version: {},
  preview: {},
  factors: []
};

export default function Proposals(state = initialState, action) {
  switch (action.type) {
    case 'PROPOSALS_FETCHED':
      return {
        ...state,
        list: action.payload.results,
        links: action.payload.links,
        currentPage: action.payload.current_page,
        count: action.payload.count
      };
    case 'PROPOSAL_FORMDATA_FETCHED':
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.resource]: action.payload.data
        }
      };
    case 'PROPOSAL_FOR_PREVIEW_FETCHED':
      return { ...state, preview: action.payload };
    case 'PROPOSAL_DETAILS_FETCHED':
      return { ...state, proposal: action.payload };
    case 'PROPOSAL_VERSION_FETCHED':
      return { ...state, version: action.payload };
    case 'PROPOSAL_DETAILS_UPDATED':
      return { ...state, proposal: action.payload };
    case 'PROPOSAL_TAB_CHANGED':
      return { ...state, currentTab: action.payload };
    case 'PROPOSAL_FACTORS_FETCHED':
      return { ...state, factors: action.payload };
    default:
      return state;
  }
}
