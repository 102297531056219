import Constants from '../utilities/constants';

const initialState = {
  downloaded: false, // flaga odpowiedzialna za ponowne pobieranie danych słownikowych
  contextMenu: [], // aktualnie przechowywane pozycje w górnej (niebieskiej) belce w aplikacji
  loading: false, // sprawdzenie, czy główna treść na ekran została wczytana
  loadingPreview: true, // sprawdzenie, czy pasek boczny został wczytany (prawdopodobnie już nieużywane)
  view: Constants.Views.List, // tryb widoku, jaki jest aktywny
  modal: null, // aktywny na ekranie modal (prawdopodobnie już nieużywane)
  systemRoles: [], // role systemowe dla osoby, używane w kreatorze osoby oraz w prowizjach
  businessRoles: [], // możliwe role biznesowe osoby w firmie, używane obiektach osoby i firmy (np. Pracownik, Akcjonariusz, Prezes etc)
  currencies: [], // lista walut zdefiniowanych w PA
  commissions: [], // lista typów prowizji dostępnych w systemie wraz z ich jednostkami, wykorzystywane w prowizjach dla osoby/firmy
  bankAccounts: [], // dane kategoryczne dotyczące rachunkó∑ bankowych w systemie - aktualnie ich typy oraz aliasy
  companyData: [], // dane słownikowe wykorzystywane w obiekcie firmy
  countryCallingCodes: [], // przedrostki do numerów telefonu, np. +48
  countryNames: [], // nazwy państw
  consentData: [], // dane słownikowe do zgód (RODO) - szablony, źródła etc
  contactTypes: [], // typy kontaktów dostępne w systemie (telefon, email, skype, facebook, etc)
  offerData: {}, // dane słownikowe wykorzystywane w obiekcie oferty
  proposalData: {}, // dane słownikowe wykorzystywane w obiekcie wniosku      -\
  agreementData: {}, // dane słownikowe wykorzystywane w obiekcie umowy       -\
  claimData: {}, // dane słownikowe wykorzystywane w obiekcie wierzytelnosci  - > ważne są tutaj statusy, bo na nich opiera się dużo operacji, a zwracane są jako inty (może warto w przyszłości zwracać statusy jako obiekty?)
  paymentData: {}, // dane słownikowe wykorzystywane w obiekcie przelewu      -/
  invoiceData: {}, // dane słownikowe wykorzystywane w obiekcie faktury      -/
  commissionRoles: [], // dane do roli
  commonData: [], // ogólne dane t.j. województwa i typy adresów
  banks: [], // lista banków dodana w PA
  vat: [], // stawki VAT dostępne w systemie - powstały na potrzeby faktur, ale nie wiadomo, czy nie przydadzą się również w innych miejscachj, dlatego są wyniesione poza klucz invoiceData
  taxProcedureCodes: [], // procedury podatkowe wykorzystywane w fakturowaniu
  gtuCodes: [], // kody gtu wykorzystywane w fakturowaniu
  invoiceTypes: [] // lista pozycji, jakie można dodać na fakturę poprzez opcję "Dodaj z listy" - definiowalne w PA
};

export default function common(state = initialState, action) {
  switch (action.type) {
    case 'COMMON_DICTIONARIES_DOWNLOADED':
      return { ...state, downloaded: true };
    case 'CONTEXT_MENU_SET':
      return { ...state, contextMenu: action.payload };
    case 'CONTENT_LOADING':
      return { ...state, loading: true };
    case 'CONTENT_LOADED':
      return { ...state, loading: false };
    case 'CONTENT_PREVIEW_LOADING':
      return { ...state, loadingPreview: true };
    case 'CONTENT_PREVIEW_LOADED':
      return { ...state, loadingPreview: false };
    case 'COMMON_CHANGE_VIEW':
      return { ...state, view: action.payload };
    case 'COMMON_TOGGLE_MODAL':
      return { ...state, modal: action.payload };
    case 'COMMON_SYSTEM_ROLES_FETCHED':
      return { ...state, systemRoles: action.payload };
    case 'COMMON_BUSINESS_ROLES_FETCHED':
      return { ...state, businessRoles: action.payload };
    case 'COMMON_CURRENCIES_FETCHED':
      return { ...state, currencies: action.payload };
    case 'COMMON_CONTACT_TYPES_FETCHED':
      return { ...state, contactTypes: action.payload };
    case 'COMMON_COMMISSIONS_FETCHED':
      return { ...state, commissions: action.payload.commissions };
    case 'COMMON_BANK_ACCOUNTS_FETCHED':
      return { ...state, bankAccounts: action.payload };
    case 'COMMON_COMPANY_DATA_FETCHED':
      return { ...state, companyData: action.payload };
    case 'COMMON_CONSENT_DATA_FETCHED':
      return { ...state, consentData: action.payload };
    case 'COMMON_COUNTRY_CALLING_CODES_FETCHED':
      return { ...state, countryCallingCodes: action.payload };
    case 'COMMON_COUNTRY_NAMES_FETCHED':
      return { ...state, countryNames: action.payload };
    case 'COMMON_OFFER_DATA_FETCHED':
      return { ...state, offerData: action.payload };
    case 'COMMON_PROPOSAL_DATA_FETCHED':
      return { ...state, proposalData: action.payload };
    case 'COMMON_AGREEMENT_DATA_FETCHED':
      return { ...state, agreementData: action.payload };
    case 'COMMON_CLAIM_DATA_FETCHED':
      return { ...state, claimData: action.payload };
    case 'COMMON_PAYMENT_DATA_FETCHED':
      return { ...state, paymentData: action.payload };
    case 'COMMON_INVOICE_DATA_FETCHED':
      return { ...state, invoiceData: action.payload };
    case 'COMMON_BANKS_FETCHED':
      return { ...state, banks: action.payload };
    case 'COMMON_VAT_FETCHED':
      return { ...state, vat: action.payload };
    case 'COMMON_TAX_PROCEDURES_FETCHED':
      return { ...state, taxProcedureCodes: action.payload };
    case 'COMMON_GTU_CODES_FETCHED':
      return { ...state, gtuCodes: action.payload };
    case 'COMMON_INVOICE_TYPES_FETCHED':
      return { ...state, invoiceTypes: action.payload };
    case 'COMMON_COMMISSION_ROLES_FETCHED':
      return { ...state, commissionRoles: action.payload };
    case 'COMMON_DATA_FETCHED':
      return { ...state, commonData: action.payload };
    case 'LOGOUT':
    case 'COMMON_CLEAR_DICTIONARIES':
      return initialState;
    default:
      return state;
  }
}
