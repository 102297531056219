const agreements = service => ({
  getList: async ({ term, modified_date, order_by, direction, page = 1, limit = 20 } = {}) =>
    service.get('agreements/', {
      params: {
        page,
        limit,
        term,
        direction,
        order_by,
        modified_date
      }
    }),
  addDebtorBankAccounts: async ({ id_agreement, id_version, id_debtor, bank_account_ids }) =>
    service.post(`agreements/${id_agreement}/agreement_versions/${id_version}/debtor/${id_debtor}/techdebtor_bank_accounts/`, {
      bank_account_ids
    }),
  updateStatusContain: async ({ id_agreement }) => service.patch(`agreements/${id_agreement}/conclude/`)
});

export default agreements;
