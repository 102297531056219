import { cloneDeep } from 'lodash';

const initialState = {
  list: [],
  links: {},
  count: 0,
  currentPage: 1,
  company: {},
  preview: {},
  additionalData: {},
  people: []
};

export default function Persons(state = initialState, action) {
  switch (action.type) {
    case 'COMPANIES_FETCHED':
      return {
        ...state,
        list: action.payload.results,
        links: action.payload.links,
        currentPage: action.payload.current_page,
        count: action.payload.count,
        preview: action.payload.results[0] || {}
      };
    case 'COMPANY_SELECTED_FOR_PREVIEW':
      return { ...state, preview: action.payload };
    case 'COMPANY_DETAILS_FETCHED':
      return { ...state, company: action.payload };
    case 'COMPANY_DETAILS_UPDATED':
      state.company = Object.assign(state.company, action.payload);
      return cloneDeep(state);
    case 'COMPANY_PEOPLE_FETCHED':
      return { ...state, people: action.payload };
    default:
      return state;
  }
}
