import Card from 'components/Layout/Card';
import { isEditableProposal } from 'containers/Proposals/Details/Index';
import { t } from 'lang/pl';
import React from 'react';
import { toast } from 'react-toastify';

import FinancialData from './FinancialData';
import CreditLiabilities from './Liabilities/Credit';
import FactoringLiabilities from './Liabilities/Factoring';
import LeasingLiabilities from './Liabilities/Leasing';
import OtherLiabilities from './Liabilities/Other';
import PublicLiabilities from './Liabilities/Public';

export default (props) => {
  return (
    <Card
      header='Faktorant ocena ilościowa'
      isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
      onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
    >
      <div>
        <FinancialData
          clear={props.clear}
          proposal={props.proposal}
          version={props.version}
          forms={props.forms}
          toggleForm={props.toggleForm}
          formVisible={props.formVisible}
          saveForm={props.saveForm}
          edit={props.edit}
          remove={props.remove}
        />
        <PublicLiabilities
          clear={props.clear}
          proposal={props.proposal}
          version={props.version}
          forms={props.forms}
          toggleForm={props.toggleForm}
          formVisible={props.formVisible}
          saveForm={props.saveForm}
          edit={props.edit}
          remove={props.remove}
        />
        <CreditLiabilities
          clear={props.clear}
          proposal={props.proposal}
          version={props.version}
          forms={props.forms}
          toggleForm={props.toggleForm}
          formVisible={props.formVisible}
          saveForm={props.saveForm}
          edit={props.edit}
          remove={props.remove}
          proposalData={props.common.proposalData}
          banks={props.common.banks}
        />
        <LeasingLiabilities
          clear={props.clear}
          proposal={props.proposal}
          version={props.version}
          forms={props.forms}
          toggleForm={props.toggleForm}
          formVisible={props.formVisible}
          saveForm={props.saveForm}
          edit={props.edit}
          remove={props.remove}
          proposalData={props.common.proposalData}
        />
        <FactoringLiabilities
          clear={props.clear}
          factors={props.factors}
          proposal={props.proposal}
          version={props.version}
          forms={props.forms}
          toggleForm={props.toggleForm}
          formVisible={props.formVisible}
          saveForm={props.saveForm}
          edit={props.edit}
          remove={props.remove}
          proposalData={props.common.proposalData}
        />
        <OtherLiabilities
          clear={props.clear}
          proposal={props.proposal}
          version={props.version}
          forms={props.forms}
          toggleForm={props.toggleForm}
          formVisible={props.formVisible}
          saveForm={props.saveForm}
          edit={props.edit}
          remove={props.remove}
          proposalData={props.common.proposalData}
        />
      </div>
    </Card>
  );
};
