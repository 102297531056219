import CompanyActions from 'actions/companies';
import LoaderBackdrop from 'components/Layout/LoaderBackdrop';
import { SidebarDetails } from 'components/SidebarDetails';
import { Notes } from 'containers/Companies/Details/Components/Notes';
import { Versions } from 'containers/Proposals/Details/Components/Versions';
import { t } from 'lang/pl';
import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import CommonActions from '../../../actions/common';
import FormActions from '../../../actions/forms';
import OfferActions from '../../../actions/offer';
import ProposalActions from '../../../actions/proposal';
import { Container, MainContainer } from '../../../components/Layout';
import Constants from '../../../utilities/constants';
import Tabs from '../Tabs';
import Header from './Components/Header';
import ClientAssessment from './Tabs/ClientAssessment';
import Debtors from './Tabs/Debtors';
import DebtorsRisk from './Tabs/DebtorsRisk';
import Decisions from './Tabs/Decisions';
import Misc from './Tabs/Misc';

export const STATUS_PROPOSAL = {
  UTWORZONY: 0,
  ZATWIERDZONY: 1,
  OCZEKUJE_NA_DECYZJE: 2,
  ZGODY_UDZIELONE: 3,
  AKTYWNY: 4,
  ARCHIWALNY: 5,
  ANULOWANY: 6
};

export const STATUS_CREDIT_DECISION = {
  WYMAGA_DECYZJI: -1,
  NEGATYWNA: 0,
  POZYTYWNA: 1,
  POZYTYWNA_WARUNKOWA: 2
};

export const STATUS_VERSION = {
  NIEZATWIERDZONA: 0,
  ZATWIERDZONA: 1
};

export const getProposalStatus = status => {
  switch (status) {
    case STATUS_PROPOSAL.UTWORZONY: return 'Utworzony';
    case STATUS_PROPOSAL.ZATWIERDZONY: return 'Zatwierdzony';
    case STATUS_PROPOSAL.OCZEKUJE_NA_DECYZJE: return 'Oczekuje na decyzje';
    case STATUS_PROPOSAL.ZGODY_UDZIELONE: return 'Zgody udzielone';
    case STATUS_PROPOSAL.AKTYWNY: return 'Aktywny';
    case STATUS_PROPOSAL.ARCHIWALNY: return 'Archiwalny';
    case STATUS_PROPOSAL.ANULOWANY: return 'Anulowany';
    default: return 'Utworzony';
  }
};

export const isEditableProposal = (proposalStatus, versionStatus) => {
  const isVersionApproved = versionStatus === STATUS_VERSION.ZATWIERDZONA;
  return !isVersionApproved && proposalStatus < STATUS_PROPOSAL.OCZEKUJE_NA_DECYZJE;
};

class Index extends React.Component {
  steps = [
    { id: 1, name: 'Klient' },
    { id: 2, name: 'Warunki handlowe' },
    { id: 3, name: 'Dłużnicy' },
    { id: 4, name: 'Pozostałe' },
    { id: 5, name: 'Decyzje' }
  ];

  constructor(props) {
    super(props);
    this.props.dispatch(CommonActions.changeView(Constants.Views.Details));
  }

  componentDidMount() {
    this.fetchData();

    const proposal = this.props.proposal || {};

    if (this.props.factors.length === 0) {
      this.props.dispatch(ProposalActions.fetchFactors());
    }

    this.props.dispatch(
      CommonActions.setContextMenu([
        { name: 'Lista wniosków', link: '/wnioski' },
        {
          name: 'Wniosek',
          options: [
            { name: 'Zatwierdź', onClick: () => this.props.dispatch(ProposalActions.approve(this.props.proposal.id)) },
            { name: 'Nowa wersja', onClick: () => this.toggleForm('acceptance') },
            { name: 'Kopiuj wniosek', onClick: () => this.toggleForm('acceptance') },
            { name: 'Generuj umowę', onClick: () => this.edit('ConsentForm', {}) },
            { name: 'Ustaw status', onClick: () => this.edit('ConsentForm', {}) }
          ]
        },
        {
          name: 'Klient',
          options: [
            {
              name: 'Reprezentacja',
              onClick: () => {
                this.changeTab(1); this.showForm('RepresentationForm', {
                  id: proposal?.representants?.[0]?.id ?? null
                });
              }
            },
            {
              name: 'Ryzyko faktoranta',
              onClick: () => {
                this.changeTab(1); this.showForm('RiskForm', {
                  rating_type_id: proposal?.rating_type?.id,
                  rating_class_id: proposal?.rating_class?.id,
                  information_agency_id: proposal?.information_agency?.id,
                  information_agency_class_id: proposal?.information_agency_class?.id,
                  information_agency_limit: proposal?.information_agency_limit,
                  information_agency_report_date: proposal?.information_agency_report_date,
                  rating_agency_id: proposal?.rating_agency?.id,
                  rating_agency_score_id: proposal?.rating_agency_score?.id,
                  is_jst_or_corporate_treasury: proposal?.is_jst_or_corporate_treasury,
                  is_bankruptcy_or_liquidation: proposal?.is_jst_or_corporate_treasury,
                  information_agency_pmi: proposal?.information_agency_pmi,
                  category_id: proposal?.category?.id
                });
              }
            },
            {
              name: 'Dane scoringowe',
              onClick: () => {
                this.changeTab(1); this.showForm('ScoringForm', {
                  ...this.props.proposal,
                  legal_form_id: proposal?.legal_form?.id,
                  industry_id: proposal?.industry?.id,
                  representation_id: proposal?.representation?.id,
                  main_pkd_id: proposal?.main_pkd?.id,
                  activity_range_id: proposal?.activity_range?.id,
                  accounting_support_id: proposal?.accounting_support?.id,
                  accounting_type_id: proposal?.accounting_type?.id,
                  office_ownership_id: proposal?.office_ownership?.id,
                  insurance_claims: this.props.proposal?.insurance_claims
                });
              }
            },
            {
              name: 'Ocena jakościowa',
              onClick: () => {
                this.changeTab(1); this.showForm('QualityForm', {});
              }
            },
            {
              name: 'Ocena fraud i AML',
              onClick: () => {
                this.changeTab(1); this.showForm('FraudForm', {});
              }
            },
            {
              name: 'Zabezpieczenia Faktoranta',
              onClick: () => {
                this.changeTab(1); this.showForm('SecuresForm', { secures: [{}] });
              }
            },
            {
              name: 'Zabezpieczenia Transakcji',
              onClick: () => {
                this.changeTab(4); this.showForm('ConsentForm', {});
              }
            }
          ]
        },
        {
          name: 'Dodaj dłużnika',
          onClick: () => {
            const isDebtorFormEditable = this.props.proposal.status < STATUS_PROPOSAL.ZATWIERDZONY;
            if(isDebtorFormEditable) {
              this.changeTab(2);
              this.toggleForm('DebtorsForm');
            } else {
              toast.error('Nie można dodać dłużnika, ponieważ nie pozwala na to status.');
            }
          }
        },
        {
          name: 'Notatka',
          onClick: () => {
            this.changeTab(4); this.showForm('NotesForm', {});
          }
        },
        {
          name: 'Pozostałe',
          options: [
            {
              name: 'Warunki dodatkowe',
              onClick: () => {
                this.changeTab(4); this.showForm('ConditionsForm', {
                  ...this.props.proposal,
                  currency_id: this.props.proposal.currency?.id,
                  oa_lump_sum_currency: this.props.proposal.oa_lump_sum_currency || '',
                  claim_limit_currency: this.props.proposal.claim_limit_currency || ''
                });
              }
            },
            {
              name: 'Gotowość',
              onClick: () => {
                this.changeTab(4); this.showForm('ReadinessForm', {
                  ...this.props.proposal,
                  currency_id: this.props.proposal.currency?.id,
                  oa_lump_sum_currency: this.props.proposal.oa_lump_sum_currency || '',
                  claim_limit_currency: this.props.proposal.claim_limit_currency || ''
                });
              }
            }
          ]
        },
        {
          name: 'Przejdź do',
          options: [
            { name: 'Klient', link: `/firmy/${proposal.client?.id}` },
            { name: 'Oferta', link: `/oferty/${proposal.offer}` },
            { name: 'Umowa', link: `/umowy?proposal_id=${this.props.proposal.id}` }
          ]
        },
        {
          name: 'Decyzje',
          options: [
            { name: 'Kredytowa Etap1', onClick: () => this.showForm('Decyzja', {}) },
            { name: 'Kredytowa Etap2', onClick: () => this.showForm('Decyzja', {}) }
          ]
        },
        { name: 'Opiekunowie', onClick: () => this.showForm('CommissionForm', {}) },
        {
          name: 'Dokumenty',
          onClick: () => {}
        }
      ])
    );
  }

  async fetchData() {
    const { slug } = this.props.match.params;
    const id = slug ? slug.split('-').slice(-1)[0] : null;

    const proposal = await this.props.dispatch(ProposalActions.fetchDetails(id)).then(data => {
      this.props.dispatch(OfferActions.fetchOfferDetails(data.offer_id));
      return data;
    });

    const approvedVersion = proposal?.proposal_versions?.find(item => item.status >= STATUS_PROPOSAL.ZATWIERDZONY);

    this.props.dispatch(ProposalActions.selectVersion(approvedVersion ? approvedVersion : proposal?.proposal_versions[0]));
  }

  componentWillUnmount() {
    this.props.dispatch(FormActions.clearAllForms(['CreateAgreementForm']));
  }

  getMainComponent() {
    const proposal = this.props.proposal || {};
    const isEditable = isEditableProposal(proposal.status, this.props.version.status);

    switch (this.props.tab) {
      case 1:
        return (
          <ClientAssessment
            factors={this.props.factors}
            populateForm={(f, d) => this.props.dispatch(FormActions.initialize(f, { ...d }, {}))}
            forms={this.props.forms.forms}
            errors={this.props.forms.errors}
            toggleForm={(f) => isEditable ? this.toggleForm(f) : toast.error(t.error.toast.statusWarning)}
            edit={(obj, modal) => isEditable ? this.edit(obj, modal) : toast.error(t.error.toast.statusWarning)}
            remove={(object, id) => isEditable ? this.remove(object, id) : toast.error(t.error.toast.statusWarning)}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            create={(f) => this.edit(f, { calculationId: this.props.calculation.id })}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            saveForm={(f, h) => this.save(f, h)}
            onCancel={(f) => this.toggleForm(f)}
            common={this.props.common}
            proposal={this.props.proposal}
            version={this.props.version}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            updateAmlDecision={(formName) => this.props.dispatch(ProposalActions.updateAmlDecision(formName))}
            fetchIssuers={(filtersForm) => this.props.dispatch(
              CompanyActions.fetch(this.props.forms?.forms?.[filtersForm], true)
            )}
            issuers={this.props.companies}
          />
        );
      case 2:
        return (
          <Debtors
            debtors={this.props.debtors}
            populateForm={(f, d) => this.props.dispatch(FormActions.initialize(f, { ...d }, {}))}
            forms={this.props.forms.forms}
            errors={this.props.forms.errors}
            toggleForm={(f) => isEditable ? this.toggleForm(f) : toast.error(t.error.toast.statusWarning)}
            edit={(modal, obj) => isEditable ? this.edit(obj, modal) : toast.error(t.error.toast.statusWarning)}
            remove={(object, id) => isEditable ? this.remove(object, id) : toast.error(t.error.toast.statusWarning)}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            create={(f) => this.edit(f, { calculationId: this.props.calculation.id })}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            saveForm={(f, h) => this.save(f, h)}
            onCancel={(f) => this.toggleForm(f)}
            common={this.props.common}
            proposal={this.props.proposal}
            version={this.props.version}
            fetch={(t, f) => this.props.dispatch(ProposalActions.fetchForForm(t, f))}
            formData={this.props.formData}
            addField={(field, form) => this.addField(field, form)}
            removeField={(field, form) => this.removeField(field, form)}
            offerData={this.props.common.offerData}
            proposalData={this.props.common.proposalData}
            getFlatRate={(formName) => {
              const t = this.props.proposal?.transaction_risk_category ? this.props.proposal?.transaction_risk_category.id : null;
              this.props.dispatch(FormActions.storeInput(formName, 'transaction_risk_category_id', t));
              this.props.dispatch(OfferActions.getFlatRate(formName));
            }}
            getMultilevelFlatRate={(formName) => {
              const t = this.props.proposal?.transaction_risk_category.id;
              this.props.dispatch(FormActions.storeInput(formName, 'transaction_risk_category_id', t));
              this.props.dispatch(OfferActions.getMultilevelFlatRate(formName));
            }}
            getIntrestRates={(formName) => this.props.dispatch(OfferActions.getIntrestRates(formName))}
            setLastAvailableDateOfRate={(formName, field) => this.props.dispatch(OfferActions.setLastAvailableDateOfRate(formName, field))}
            intrestRates={this.props.intrestRates}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            fetchFromOffer={(debtorId, object) => this.props.dispatch(ProposalActions.fetchFromOffer(debtorId, object))}
            fetchIssuers={(filtersForm) => this.props.dispatch(
              CompanyActions.fetch(this.props.forms?.forms?.[filtersForm], true)
            )}
            issuers={this.props.companies}
          />
        );
      case 3:

        return (
          <DebtorsRisk
            forms={this.props.forms.forms}
            errors={this.props.forms.errors}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            create={(f) => this.edit(f, { calculationId: this.props.calculation.id })}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            toggleForm={(f) => isEditable ? this.toggleForm(f) : toast.error(t.error.toast.statusWarning)}
            edit={(modal, obj) => isEditable ? this.edit(obj, modal) : toast.error(t.error.toast.statusWarning)}
            remove={(object, id) => isEditable ? this.remove(object, id) : toast.error(t.error.toast.statusWarning)}
            saveForm={(f, h) => this.save(f, h)}
            onCancel={(f) => this.toggleForm(f)}
            common={this.props.common}
            proposal={this.props.proposal}
            version={this.props.version}
            fetch={(t, f) => this.props.dispatch(ProposalActions.fetchForForm(t, f))}
            formData={this.props.formData}
            addField={(field, form) => this.addField(field, form)}
            removeField={(field, form) => this.removeField(field, form)}
            offerData={this.props.common.offerData}
            proposalData={this.props.common.proposalData}
            intrestRates={this.props.intrestRates}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            fetchFromOffer={(debtorId, object) => this.props.dispatch(ProposalActions.fetchFromOffer(debtorId, object))}
            fetchIssuers={(filtersForm) => this.props.dispatch(
              CompanyActions.fetch(this.props.forms?.forms?.[filtersForm], true)
            )}
            issuers={this.props.companies}
            user={this.props.user}
          />
        );
      case 4:

        return (
          <Misc
            forms={this.props.forms.forms}
            errors={this.props.forms.errors}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            create={(f) => this.edit(f, { calculationId: this.props.calculation.id })}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            toggleForm={(f) => isEditable ? this.toggleForm(f) : toast.error(t.error.toast.statusWarning)}
            edit={(modal, obj) => isEditable ? this.edit(obj, modal) : toast.error(t.error.toast.statusWarning)}
            remove={(object, id) => isEditable ? this.remove(object, id) : toast.error(t.error.toast.statusWarning)}
            saveForm={(f, h) => this.save(f, h)}
            onCancel={(f) => this.toggleForm(f)}
            common={this.props.common}
            proposal={this.props.proposal}
            version={this.props.version}
            fetch={(t, f) => this.props.dispatch(ProposalActions.fetchForForm(t, f))}
            formData={this.props.formData}
            addField={(field, form) => this.addField(field, form)}
            removeField={(field, form) => this.removeField(field, form)}
            offerData={this.props.common.offerData}
            proposalData={this.props.common.proposalData}
            intrestRates={this.props.intrestRates}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            fetchFromOffer={(debtorId, object) => this.props.dispatch(ProposalActions.fetchFromOffer(debtorId, object))}
            objectTypes={[]}
            clearBeneficiaries={() => this.props.dispatch({ type: 'OFFER_CLEAR_BENEFICIARIES' })}
            fetchBeneficiRepresentationaries={(f) => this.props.dispatch(OfferActions.fetchBeneficiaries(f))}
            beneficiaries={this.props.beneficiaries}
            types={this.props.common.commissions ? this.props.common.commissions.map((i) => ({ ...i, value: i.id })) : []}
            roles={
              this.props.common.systemRoles
                ? this.props.common.systemRoles.filter((r) => r.is_used_for_commissions).map((i) => ({ ...i, value: i.id }))
                : []
            }
            fetchIssuers={(filtersForm) => this.props.dispatch(
              CompanyActions.fetch(this.props.forms?.forms?.[filtersForm], true)
            )}
            issuers={this.props.companies}
          />
        );
      case 5:
        return (
          <Decisions
            forms={this.props.forms.forms}
            errors={this.props.forms.errors}
            toggleForm={(f) => this.toggleForm(f)}
            edit={(obj, modal) => this.edit(obj, modal)}
            remove={(object, id) => this.remove(object, id)}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            create={(f) => this.edit(f, {})}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            saveForm={(f, h) => this.save(f, h)}
            onCancel={(f) => this.toggleForm(f)}
            common={this.props.common}
            proposal={this.props.proposal}
            version={this.props.version}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            fetchIssuers={(filtersForm) => this.props.dispatch(
              CompanyActions.fetch(this.props.forms?.forms?.[filtersForm], true)
            )}
            issuers={this.props.companies}
            user={this.props.user}
          />
        );
      default:
        return null;
    }
  }

  showForm(formName, object) {
    this.edit(object, formName);
  }

  addField(field, form) {
    const f = this.props.forms.forms[form] || {};
    const v = get(f, field, []);

    this.props.dispatch(FormActions.storeInput(form, `${field}.${v.length}`, {}));
  }

  removeField(field, form) {
    const f = this.props.forms.forms[form] || {};
    const ff = field.split('.');
    const v = get(f, ff[0], []);

    v.splice(ff[1], 1);

    this.props.dispatch(FormActions.storeInput(form, ff[0], v));
  }

  create(name) {
    this.props.dispatch(FormActions.initialize(name, {}, {}));
  }

  edit(object, name) {
    this.props.dispatch(FormActions.initialize(name, object, {}));
    this.props.dispatch(FormActions.toggleForm(name));
  }

  toggleForm(formName) {
    this.props.dispatch(FormActions.toggleForm(formName));
  }

  remove(object, id) {
    this.props.dispatch(ProposalActions.remove(this.props.proposal?.id, object, id));
  }

  changeTab(tab) {
    this.props.dispatch(ProposalActions.changeTab(tab));
  }

  save(formName, hide = true) {
    this.props.dispatch(ProposalActions.saveForm(formName, this.props.proposal?.id, this.props.version?.id, hide));
  }

  generateAgreement() {
    const { proposal, history, dispatch } = this.props;

    dispatch(
      FormActions.initialize('CreateAgreementForm', {
        proposal,
        limit_amount: proposal.limit_amount,
        currency_id: proposal.currency?.id,
        operational_administrator: proposal.operational_administrator,
        trade_supervisor: proposal.trade_supervisor,
        billing_manager: proposal.billing_manager,
        client: proposal.client,
        client_people: proposal.client.people,
        factor: proposal.factor,
        factor_people: proposal.factor.people,
        client_representation_id: proposal.client?.representation?.id,
        factor_representation_id: proposal.factor?.representation?.id,
        procedure: proposal.procedure,
        product: proposal.product
      }, {})
    );
    this.toggleForm('CreateAgreementForm');

    history.push('/umowy');
  }

  render() {
    return (
      <Container className="mt-3">
        <LoaderBackdrop />

        <MainContainer>
          <Header
            proposal={this.props.proposal}
            version={this.props.version}
            copy={() => this.props.dispatch(ProposalActions.copy(this.props.history.push))}
            createNewVersion={() => this.props.dispatch(ProposalActions.createNewVersion())}
            approve={() => this.props.dispatch(ProposalActions.approve(this.props.proposal.id))}
            sendForDecision={() => this.props.dispatch(ProposalActions.sendForDecision(this.props.proposal.id))}
            statuses={this.props.common.proposalData?.proposal_statuses}
            generateAgreement={() => this.generateAgreement()}
            forms={this.props.forms.forms}
            errors={this.props.forms.errors}
            currencies={this.props.common.currencies}
            toggleForm={(f) => this.toggleForm(f)}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            edit={(obj, modal) => this.edit(obj, modal)}
            saveForm={f => this.save(f)}
            fetch={(t, f) => this.props.dispatch(ProposalActions.fetchForForm(t, f))}
            formData={this.props.formData}
          />

          <Tabs tab={this.props.tab} changeStep={(tab) => this.changeTab(tab)} steps={this.steps} />

          <Versions
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            proposal={this.props.proposal}
            version={this.props.version}
            selectVersion={v => this.props.dispatch(ProposalActions.selectVersion(v))}
            removeVersion={v => this.props.dispatch(ProposalActions.deleteProposalVersion(this.props.proposal?.id, v.id))}
          />
          {this.getMainComponent()}
        </MainContainer>

        <SidebarDetails
          Notes={
            <Notes
              name={this.props.proposal?.identifier}
              notes={
                this.props.proposal?.notes?.map((n) => ({
                  created_date: n.created_date,
                  modified_date: n.modified_date,
                  avatar: n.user_creator ? n.user_creator.avatar_url : '',
                  creator: n.user_creator ? n.user_creator.full_name : 'brak',
                  comment: n.comment,
                  name: n.name,
                  id: n.id
                })) ?? []
              }
              formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
              forms={this.props.forms.forms}
              toggleForm={(f) => this.toggleForm(f)}
              showForm={(f) => this.toggleForm(f)}
              create={(f) => this.showForm(f, {})}
              saveForm={(f) => this.save(f)}
              edit={(f, o) => this.showForm(f, o)}
              onCancel={(f) => this.toggleForm(f)}
              remove={id => this.props.dispatch(ProposalActions.removeNote(this.props.proposal?.id, id))}
            />
          }
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  forms: state.Forms,
  factors: state.Proposal.factors,
  proposal: state.Proposal.proposal,
  version: state.Proposal.version,
  formData: state.Proposal.formData,
  common: state.Common,
  tab: state.Proposal.currentTab,
  intrestRates: state.Offer.intrestRates,
  beneficiaries: state.Offer.beneficiaries,
  companies: state.Company,
  user: state.Authorization.user
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Index)
);
