import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { isBoolean } from 'utilities/helpers';

export default function Decision({ isPositive }) {
  const isDeclared = isBoolean(isPositive);

  return (
    <span className={clsx(isDeclared ? (isPositive ? 'text-green-0' : 'text-red-0') : 'text-accent-0')}>
      {isDeclared ? (isPositive ? 'Pozytywna' : 'Negatywna') : 'Brak'}
    </span>
  );
}

Decision.propTypes = {
  isPositive: PropTypes.bool.isRequired
};
