import ProposalActions from 'actions/proposal';
import DecisionString from 'components/Format/DecisionString';
import Button from 'components/Forms/Button';
import { BaseModal } from 'components/Layout/OldModal';
import Tooltip from 'components/Layout/Tooltip';
import { getProposalStatus, isEditableProposal, STATUS_PROPOSAL } from 'containers/Proposals/Details/Index';
import Create from 'containers/Proposals/Forms/Create';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { t } from 'lang/pl';

import BooleanCheck from '../../../../components/BooleanCheck';
import { OldCard } from '../../../../components/Layout';
import { formatString, isEnabledOnStatuses, parseMoney } from '../../../../utilities/helpers';

export const PROPOSAL_EDIT_FORM = 'ProposalEditForm';
const createProposalForm = PROPOSAL_EDIT_FORM;

export default props => {
  const { proposal, version } = props;
  const dispatch = useDispatch();
  const [isOptionsModal, setOptionsModal] = useState(false);
  const [isCancelModal, setCancelModal] = useState(false);

  const header = (
    <span className="mb-0 font-weight-light" style={{ color: '#006BFF', fontSize: '32px' }}>
      Wniosek {proposal.identifier}
    </span>
  );

  const Participants = () =>
    Object.keys(proposal).map(k => {
      let tip;

      if (!proposal[k]) {
        return null;
      }

      switch (k) {
        case 'trade_supervisor':
          tip = `Opiekun handlowy: ${proposal[k].full_name}`;
          break;
        case 'operational_administrator':
          tip = `Administrator operacyjny: ${proposal[k].name}`;
          break;
        case 'proposal_keeper':
          tip = `Opiekun oferty: ${proposal[k].full_name}`;
          break;
        case 'client':
          tip = `Klient: ${proposal[k].name}`;
          break;
        case 'billing_manager':
          tip = `Opiekun ds. rozliczeń: ${proposal[k].full_name}`;
          break;
        case 'factor':
          tip = `Faktor: ${proposal[k].name}`;
          break;
        default:
          return null;
      }

      return (
        <>
          <ReactTooltip id={k} />
          <img alt=""  src="/user.png" width="25" height="25" className="img-circle mr-2" data-tip={tip} data-for={k} />
        </>
      );
    });

  const handleShowOptions = () => {
    setOptionsModal(true);
  };

  const handleHideOptions = () => {
    setOptionsModal(false);
  };

  const handleShowCancel = () => {
    setCancelModal(true);
  };

  const handleHideCancel = () => {
    setCancelModal(false);
  };

  const handleCreateNewVersion = () => {
    props.createNewVersion();
    handleHideOptions();
  };

  const handleProposalToEdit = () => {
    dispatch(ProposalActions.changeProposalStatus(proposal.id, STATUS_PROPOSAL.UTWORZONY));
  };

  const handleProposalToCancel = () => {
    dispatch(ProposalActions.changeProposalStatus(proposal.id, STATUS_PROPOSAL.ANULOWANY));
    handleHideCancel();
  };

  const handleEditProposal = () => {
    if(isEditableProposal(proposal.status, version.status)) {
      props.edit(proposal, createProposalForm);
    } else {
      toast.error(t.error.toast.statusWarning);
    }
  };

  return (
    <OldCard header={header} contextMenu={handleEditProposal}>
      <div className="row mb-1 mt-3">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Status wniosku</div>
            <div className="col-sm-6">
              <span className="badge badge-warning mr-3 pt-1 pb-1 pl-2 pr-2">{getProposalStatus(props.proposal?.status)}</span>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Wersja</div>
            <div className="col-sm-6">{version.version}</div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6  text-right grey">Uczestnicy</div>
            <div className="col-sm-6 d-flex align-items-center">
              <Participants />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Numer wniosku</div>
            <div className="col-sm-6"><Tooltip label={proposal?.identifier} /></div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Utworzył</div>
            <div className="col-sm-6">{proposal.user_creator ? proposal.user_creator.full_name : '-'}</div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Dłużników</div>
            <div className="col-sm-6">{formatString(version.debtors_count)}</div>
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Wnioskowany limit</div>
            <div className="col-sm-6">
              {parseMoney(proposal.limit_amount)} {proposal.currency?.name ?? ''}
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Utworzony dnia</div>
            <div className="col-sm-6">{proposal.created_date || '-'}</div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Sublimity</div>
            <div className="col-sm-6">
              {parseMoney(version.sublimits_sum)} {proposal.currency?.name ?? ''}
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Klient</div>
            <div className="col-sm-6">
              <Tooltip tip={proposal.client?.name} label={proposal.client?.identifier}  path={`/firmy/${proposal?.client?.slug}`} />
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Ostatnia aktualizacja</div>
            <div className="col-sm-6">{proposal.modified_date || '-'}</div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Nr umowy</div>
            <div className="col-sm-6">
              <Tooltip label={proposal?.agreement?.identifier} path={`/umowy/${proposal?.agreement?.slug}`} />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Czas trwania umowy</div>
            <div className="col-sm-6">{proposal.duration_of_the_contract || '-'}</div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Identyfikator oferty</div>
            <div className="col-sm-6"><Tooltip label={proposal.offer_identifier} path={`/oferty/${proposal?.offer_slug}`} /></div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Ocena AML</div>
            <div className="col-sm-6"><DecisionString value={version?.aml_decisions?.length && version.aml_decisions[0].decision}/></div>
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Regres</div>
            <div className="col-sm-6">
              <BooleanCheck field={proposal.recourse} />
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Produkt / Procedura</div>
            <div className="col-sm-6">{`${proposal.product ? proposal.product.name : '-'} / ${
              proposal.procedure ? proposal.procedure.name : '-'
            }`}</div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Odstępstwo</div>
            <div className="col-sm-6">{proposal.asd || '-'}</div>
          </div>
        </div>
      </div>
      <div className="row mt-4 mb-3">
        <div className="col-sm-12">
          <button type="button" className="btn-green mr-3" onClick={handleShowOptions} disabled={isEnabledOnStatuses([STATUS_PROPOSAL.UTWORZONY, STATUS_PROPOSAL.ZATWIERDZONY, STATUS_PROPOSAL.OCZEKUJE_NA_DECYZJE, STATUS_PROPOSAL.ZGODY_UDZIELONE], proposal?.status)}>
            Nowa wersja
          </button>
          <button type="button" className="btn-green mr-3" onClick={props.approve} disabled={isEnabledOnStatuses([STATUS_PROPOSAL.UTWORZONY], proposal?.status)}>
            Zatwierdź wniosek
          </button>
          <button type="button" className="btn-green mr-3" onClick={props.sendForDecision} disabled={isEnabledOnStatuses([STATUS_PROPOSAL.ZATWIERDZONY], proposal?.status)}>
            Wyślij do decyzji
          </button>
          <button type="button" className="btn-green mr-3" onClick={handleProposalToEdit} disabled={isEnabledOnStatuses([STATUS_PROPOSAL.ZATWIERDZONY, STATUS_PROPOSAL.OCZEKUJE_NA_DECYZJE], proposal?.status)}>
            Do edycji
          </button>
          <button type="button" className="btn-green mr-3" onClick={props.generateAgreement} disabled={isEnabledOnStatuses([STATUS_PROPOSAL.ZGODY_UDZIELONE], proposal?.status)}>
            Generuj umowę
          </button>
          <button type="button" className="btn-green mr-3" onClick={handleShowCancel} disabled={isEnabledOnStatuses([STATUS_PROPOSAL.UTWORZONY, STATUS_PROPOSAL.ZATWIERDZONY, STATUS_PROPOSAL.OCZEKUJE_NA_DECYZJE, STATUS_PROPOSAL.ZGODY_UDZIELONE], proposal?.status)}>
            Anuluj
          </button>
        </div>
      </div>

      <BaseModal visible={isOptionsModal} header="Tworzenie nowej wersji" onClickAway={handleHideOptions}>
        {proposal?.status >= STATUS_PROPOSAL.ZGODY_UDZIELONE && (
          <div className='pt-10 pb-20'>
            <p className='text-red-0 text-center font-bold'>Czy na pewno chcesz utworzyć nową wersję wniosku?</p>
            <p className='text-red-0 text-center'>Spowoduje to zmianę statusu wniosku na utworzony i usunięcie decyzji kredytowych o ile podjęte.</p>
          </div>
        )}
        <div className="flex justify-center items-center">
          <Button onClick={handleHideOptions}>Anuluj</Button>
          <Button mode='primary' onClick={props.copy}>Czysta wersja</Button>
          <Button mode='primary' onClick={handleCreateNewVersion} style={{ width: 'initial' }}>Na podstawie ostatniej wersji</Button>
        </div>
      </BaseModal>

      <BaseModal visible={isCancelModal} header="Anulowanie wniosku" onClickAway={handleHideCancel}>
        <div className='pt-10 pb-20'>
          <p className='text-red-0 text-center font-bold'>Operacja jest nieodwracalna.</p>
          <p className='text-red-0 text-center'>Czy na pewno chcesz anulować?</p>
        </div>
        <div className="flex justify-center items-center">
          <Button onClick={handleHideCancel}>Nie</Button>
          <Button mode='primary' onClick={handleProposalToCancel}>Tak</Button>
        </div>
      </BaseModal>

      <BaseModal visible={props.formVisible(createProposalForm)} header="Edycja wniosku" onClickAway={() => props.toggleForm(createProposalForm)}>
        <Create
          details
          errors={props.errors[createProposalForm]}
          forms={props.forms}
          form={props.forms[createProposalForm]}
          clear={props.clear}
          visibleOnMount={false}
          visible={props.formVisible(createProposalForm)}
          toggleForm={props.toggleForm}
          isVisible={props.formVisible}
          formName={createProposalForm}
          withButtons
          submitForm={() => props.saveForm(createProposalForm, true)}
          onCancel={() => props.toggleForm(createProposalForm)}
          currencies={props.currencies}
          fetch={props.fetch}
          formData={props.formData}
          storeInput={props.storeInput}
        />
      </BaseModal>
    </OldCard>
  );
};
