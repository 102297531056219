import { ColName } from 'components/Forms/Col';
import React from 'react';

import {
  Datepicker,
  Form, Input, Select } from '../../../../components/Forms';

export default (props) => (
  <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
    {props.visible ? (
      <>
        <div className="row mt-3">
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <ColName className='col-sm-4 text-right'>Faktor</ColName>
              <div className="col-sm-6">
                <Select formName={props.formName} options={props.factors ? props.factors.map((f) => ({ value: f.id, name: f.name })) : []} name="factor_id" number />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <ColName className='col-sm-4 text-right'>
                Kwota limitu
              </ColName>
              <div className="col-sm-6">
                <Input formName={props.formName} type="text" name="limit_amount" />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <ColName className='col-sm-4 text-right'>
                Aktualne zadłużenie
              </ColName>
              <div className="col-sm-6">
                <Input formName={props.formName} type="text" name="current_debt" />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <ColName className='col-sm-4 text-right'>
                Data zakończenia umowy
              </ColName>
              <div className="col-sm-6">
                <Datepicker formName={props.formName} name="expiration_date" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <ColName className='col-sm-4 text-right'>Cesja</ColName>
              <div className="col-sm-6">
                <Select formName={props.formName} options={props.proposalData && props.proposalData.transfers_data ? props.proposalData.transfers_data.map((f) => ({ value: f.id, name: f.name })) : []} name="transfer" number />
              </div>
            </div>
          </div>
        </div>

        {props.withButtons ? (
          <div className="row">
            <div className="mt-4 col-sm-12 text-center">
              <button
                type="button"
                onClick={props.onCancelButtonClick}
                className="btn btn-primary-outline mr-2 font-weight-light"
                style={{ color: '#4B4E55' }}
              >
                Anuluj
              </button>
              <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                Zapisz
              </button>
            </div>
          </div>
        ) : null}
      </>
    ) : null}
  </Form>
);
