import React from 'react';

import { OldCard } from '../../../../components/Layout';
import { BaseModal } from 'components/Layout/OldModal';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parseMoney } from '../../../../utilities/helpers';
import { SettleForm } from '../../Forms/Settle';

export default props => {
  const formName = 'CommitmentForm';

  const header = (
    <a
      role="button"
      style={{ fontSize: '24px' }}
      className="collapsed "
      data-toggle="collapse"
      href="#warunkiDodatkowe"
      aria-expanded="false"
      aria-controls="collapseExample">
      Wydatki do rozliczenia
    </a>
  );

  const customCreateSection = (
    <>
      <i className="fas fa-question-circle ml-2 mr-2 " onClick={() => {}} />
    </>
  );

  const bankAccounts = props.claim?.agreement?.agreement_versions?.find(x => x.status === 1)?.client_bank_accounts ?? [];

  const edit = e => {
    props.edit(formName, {
      commitment_id: e.id,
      commitment: e,
      bank_account: bankAccounts.length === 1 ? bankAccounts[0] : {}
    });
  };

  return (
    <OldCard header={header} id="financialData-card" showIcons customCreateSection={customCreateSection}>
      <div className="collapse show" id="financialData">
        <div className="row bb">
          <div className="col-sm-2 p-2 grey br">Tytuł</div>
          <div className="col-sm-2 p-2 grey br">Podstawa wydatku</div>
          <div className="col-sm-1 p-2 grey br">Data wydatku</div>
          <div className="col-sm-1 p-2 grey br">Kwota wydatku</div>
          <div className="col-sm-1 p-2 grey br">Waluta</div>
          <div className="col-sm-1 p-2 grey br">Rozliczono</div>
          <div className="col-sm-1 p-2 grey br">Do rozliczenia</div>
          <div className="col-sm-1 p-2 grey">Status</div>
        </div>
      </div>
      {props.claim?.commitments
        ?.filter(e => !e.is_settled)
        .map(e => (
          <RowEditable>
            <div className="col-sm-2 p-2 br">{e.title}</div>
            <div className="col-sm-2 p-2 br">{e.document_no}</div>
            <div className="col-sm-1 p-2 br">{e.commitment_date}</div>
            <div className="col-sm-1 p-2 br">{parseMoney(e.gross_value)}</div>
            <div className="col-sm-1 p-2 br">{e.currency?.name}</div>
            <div className="col-sm-1 p-2 br"> {parseMoney(e.paid_value)}</div>
            <div className="col-sm-1 p-2 br"> {parseMoney(e.to_pay_value)}</div>
            <div className="col-sm-1 d-flex p-2">
              {e.is_settled ? 'Rozliczony' : 'Nierozliczony'}
              {e.is_settled ? null : (
                <button type="button" className="ml-2" onClick={() => edit(e)}>
                  Rozlicz
                </button>
              )}
            </div>
          </RowEditable>
        ))}

      <BaseModal
        visible={props.formVisible(formName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" /> <span>Generowanie płatności zaliczkowej {props.claim?.identifier}</span>
          </div>
        }
        footer="Aby zapisać dokument wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}>
        <SettleForm
          docs={props.docs}
          docsFilters={props.docsFilters}
          fetchDocs={props.fetchDocs}
          clients={props.clients}
          clientFilters={props.clientFilters}
          fetchClients={props.fetchClients}
          forms={props.forms}
          edit={props.edit}
          submitForm={(f, h = false) => props.saveForm(f, h)}
          withButtons
          formVisible={props.formVisible}
          toggleForm={props.toggleForm}
          formName={formName}
          onCancelButtonClick={() => props.toggleForm(formName)}
          claim={props.claim}
          storeInput={props.storeInput}
          common={props.common}
          clear={props.clear}
          generateTransfer={(id, compensate) => {
            props.saveForm(`GenerateTransfer.${id}`, false, false, compensate);
          }}
          removeTransfer={props.removeTransfer}
          sendCompensation={f => props.sendCompensation(f, formName)}
        />
      </BaseModal>
    </OldCard>
  );
};
