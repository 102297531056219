import React from 'react';

import { Conditions } from '../../../Proposals/Details/Components/Conditions';
import Emails from '../Components/Emails';
import People from '../Components/People';

export default (props) => (
  <>
    <People
      remove={props.remove}
      saveForm={props.saveForm}
      version={props.version}
      claim={props.claim}
      formVisible={props.formVisible}
      toggleForm={props.toggleForm}
      clearForm={props.clearForm}
    />
    <Emails
      remove={props.remove}
      saveForm={props.saveForm}
      version={props.version}
      claim={props.claim}
      formVisible={props.formVisible}
      toggleForm={props.toggleForm}
      clearForm={props.clearForm}
    />
    <Conditions
      isEditable={false}
      clear={props.clear}
      currencies={props.common ? props.common.currencies : []}
      proposal={props.claim}
      version={props.version}
      forms={props.forms ? props.forms.forms : {}}
      toggleForm={props.toggleForm}
      formVisible={(props.formVisible)}
      saveForm={props.saveForm}
      // edit={props.edit}
      offerData={props.offerData}
      mpp={props.claim?.mpp}
    />

    {/* <Readiness */}
    {/*  countingMethods={props.offerData && props.offerData.counting_method ? props.offerData.counting_method.map((i) => ({ value: i.id, name: i.name })) : []} */}
    {/*  clear={props.clear} */}
    {/*  currencies={props.common ? props.common.currencies : []} */}
    {/*  proposal={props.version} */}
    {/*  version={props.version} */}
    {/*  forms={props.forms} */}
    {/*  toggleForm={props.toggleForm} */}
    {/*  formVisible={(props.formVisible)} */}
    {/*  saveForm={props.saveForm} */}
    {/*  edit={props.edit} */}
    {/*  storeInput={props.storeInput} */}
    {/*  removeField={props.removeField} */}
    {/* /> */}
    {/* <Commissions */}
    {/*  objectTypes={[]} */}
    {/*  clearBeneficiaries={props.clearBeneficiaries} */}
    {/*  fetchBeneficiaries={props.fetchBeneficiaries} */}
    {/*  beneficiaries={props.beneficiaries} */}
    {/*  types={props.types} */}
    {/*  roles={props.roles} */}
    {/*  clear={props.clear} */}
    {/*  calculation={props.version} */}
    {/*  offer={props.claim} */}
    {/*  forms={props.forms} */}
    {/*  errors={props.errors} */}
    {/*  toggleForm={props.toggleForm} */}
    {/*  formVisible={props.formVisible} */}
    {/*  create={(f) => props.edit(f, { calculationId: 1 })} */}
    {/*  edit={(o, f) => props.edit(f, o)} */}
    {/*  saveForm={props.saveForm} */}
    {/*  onCancel={(f) => props.toggleForm(f)} */}
    {/*  remove={(obj) => props.remove('commissions', obj.id)} */}
    {/* /> */}
    {/* <Representation */}
    {/*  actorTitle="Faktoranta" */}
    {/*  representationType={props.version?.client_representation?.representation_type} */}
    {/*  representation={props.version?.client_representation?.people} */}
    {/*  remove={props.remove} */}
    {/*  saveForm={props.saveForm} */}
    {/*  formVisible={props.formVisible} */}
    {/*  toggleForm={props.toggleForm} */}
    {/*  types={props.representationTypes} */}
    {/*  prefix="client" */}
    {/*  clear={props.clear} */}
    {/*  formName="ClientRepresentation" */}
    {/*  edit={props.edit} */}
    {/*  options={props.claim?.client?.persons} */}
    {/*  onSelected={() => props.updateRepresentation('ClientRepresentation')} */}
    {/* /> */}
    {/* <Representation */}
    {/*  actorTitle="Faktora" */}
    {/*  representationType={props.version?.factor_representation?.representation_type} */}
    {/*  representation={props.version?.factor_representation?.people} */}
    {/*  remove={props.remove} */}
    {/*  saveForm={props.saveForm} */}
    {/*  formVisible={props.formVisible} */}
    {/*  toggleForm={props.toggleForm} */}
    {/*  types={props.representationTypes} */}
    {/*  prefix="factor" */}
    {/*  clear={props.clear} */}
    {/*  formName="FactorRepresentation" */}
    {/*  edit={props.edit} */}
    {/*  options={props.claim?.factor?.persons} */}
    {/*  onSelected={() => props.updateRepresentation('FactorRepresentation')} */}
    {/* /> */}
    {/* <Representation */}
    {/*  actorTitle="Administratora operacyjnego" */}
    {/*  representationType={props.version?.oa_representation?.representation_type} */}
    {/*  representation={props.version?.oa_representation?.people} */}
    {/*  remove={props.remove} */}
    {/*  saveForm={props.saveForm} */}
    {/*  formVisible={props.formVisible} */}
    {/*  toggleForm={props.toggleForm} */}
    {/*  types={props.representationTypes} */}
    {/*  prefix="oa" */}
    {/*  clear={props.clear} */}
    {/*  formName="OaRepresentation" */}
    {/*  edit={props.edit} */}
    {/*  options={props.claim?.operational_administrator?.people} */}
    {/*  onSelected={() => props.updateRepresentation('OaRepresentation')} */}
    {/* /> */}
  </>
);
