import React from 'react';

import DebtorsRisks from '../Components/DebtorsRisks';

export default props => (
  <>
    <DebtorsRisks
      user={props.user}
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      errors={props.errors}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={(obj, form) => props.edit(form, obj)}
      storeInput={props.storeInput}
      fetch={props.fetch}
      formData={props.formData}
      addField={props.addField}
      removeField={props.removeField}
      offerData={props.offerData}
      proposalData={props.proposalData}
      getFlatRate={props.getFlatRate}
      getMultilevelFlatRate={props.getMultilevelFlatRate}
      getIntrestRates={props.getIntrestRates}
      setLastAvailableDateOfRate={props.setLastAvailableDateOfRate}
      remove={props.remove}
      fetchFromOffer={props.fetchFromOffer}
      intrestRates={props.intrestRates}
      issuers={props.issuers}
      fetchIssuers={props.fetchIssuers}
      common={props.common}
    />
  </>
);
