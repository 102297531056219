import { cloneDeep } from 'lodash';
import { getGroupOfErrors } from 'utilities/helpers';

const clearErrors = (form) => (dispatch) => dispatch({ type: 'FORM_ERRORS_CLEARED', payload: form });

const storeInput = (form, field, value) => (dispatch, getState) => {
  const hasErrors = getState().Forms.errors[form] ? Object.keys(getState().Forms.errors[form]).filter((key) => key !== 'non_field_errors').length > 0 : false;
  if (hasErrors) {
    dispatch(clearErrors(form));
  }

  dispatch({
    type: 'FORM_STORE_INPUT',
    payload: { form, field, value }
  });
};

const storeSelect = (form, field, object) => (dispatch) => {
  dispatch(storeInput(form, field, object.id));
  dispatch(storeInput(form, `${field}_select`, object));
};

const initialize = (name, preloadedForm, rules) => (dispatch) => dispatch({
  type: 'FORM_INITIALIZED',
  payload: { name, preloadedForm: cloneDeep(preloadedForm), rules }
});

const handleErrors = (form, errors) => (dispatch) => dispatch({ type: 'FORM_ERRORS_RAISED', payload: { form, errors } });

const handleValidErrors = (formName, errorsArray, errorMessage) => (dispatch, getState) => {
  const { forms } = getState().Forms;
  const form = forms[formName];

  const errors = getGroupOfErrors(errorsArray, form, errorMessage);
  return dispatch({ type: 'FORM_ERRORS_RAISED', payload: { form: formName, errors } });
};

const clearForm = (form) => (dispatch) => dispatch({ type: 'FORM_CLEARED', payload: form });
const clearField = (form, field) => (dispatch) => dispatch({ type: 'FORM_FIELD_CLEARED', payload: { form, field } });

const clearAllForms = (excludeForms = []) => (dispatch, getState) => {
  const { forms } = getState().Forms;
  Object.keys(forms).forEach((form) => {
    if (!excludeForms.includes(form)) {
      dispatch(clearForm((form)));
    }
  });
};

const deleteField = (form, field) => (dispatch) => dispatch({ type: 'FORM_FIELD_DELETED', payload: { form, field } });

const deleteForm = (form) => (dispatch) => dispatch({ type: 'FORM_DELETED', payload: form });

const toggleForm = (form) => (dispatch) => dispatch({ type: 'FORM_TOGGLED', payload: form });

const showForm = (form) => (dispatch) => dispatch({ type: 'FORM_SHOWED', payload: form });

const hideAllForms = () => (dispatch) => dispatch({ type: 'FORM_HIDDEN' });

export default {
  storeInput,
  storeSelect,
  initialize,
  handleErrors,
  handleValidErrors,
  clearErrors,
  clearForm,
  clearField,
  toggleForm,
  showForm,
  deleteField,
  deleteForm,
  clearAllForms,
  hideAllForms
};
