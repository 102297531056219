import { RemoveModalContent } from 'components/Layout/ModalOnRemove';
import { BaseModal } from 'components/Layout/OldModal';
import { t } from 'lang/pl';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

export const RowEditable = ({
  className, itemId, isRemoveHidden, messageOnRemove,
  onPreview, onEdit, onRemove, onCancel, onClick, onDoubleClick, onBlockedMessage, ...props
}) => {
  const [isRemoveAcceptModal, setRemoveAcceptModal] = useState(false);
  const [displayButtons, setDisplayButtons] = useState(0);

  const handleEdit = event => {
    props.isBlocked ? onBlockedMessage() : onEdit(event);
  };

  const handleRemove = event => {
    props.isBlocked ? onBlockedMessage() : onRemove(event);
    setRemoveAcceptModal(false);
  };

  const handleObjectToRemove = event => {
    if(props.isObjectToRemove) {
      handleRemove(event);
    } else {
      setRemoveAcceptModal(true);
    }
  };

  const handleRemoveModal = event => {
    props.isBlocked ? onBlockedMessage() : handleObjectToRemove(event);
  };

  return (
    <div>
      <div
        key={props.key}
        className={`${className || (props.index === 0 ? 'bt data-row bb' : 'data-row bb')} row`}
        // className={`${className || (props.index === 0 ? 'bt data-row bb' : 'data-row bb')} row align-items-center`}
        style={{ position: 'relative', ...props.style }}
        // className={selectedObjectId === address.id ? "row bb data-row data-row-active" : "row bb data-row"}
        // onClick={() => setId(address.id)}
        onClick={typeof onClick !== 'undefined' ? onClick : null}
        onDoubleClick={typeof onDoubleClick !== 'undefined' ? onDoubleClick : null}
        onMouseOver={() => setDisplayButtons(itemId)}
        onMouseOut={() => setDisplayButtons(0)}
      >
        <div style={{ visibility: displayButtons === itemId ? 'visible' : 'hidden ', position: 'absolute', top: '0.6em', right: 0, zIndex: 1000 }}>
          {onCancel && <i className="fas fa-minus-circle mr-2" style={{ cursor: 'pointer', color: 'red' }} onClick={onCancel} />}
          {onEdit && typeof onEdit !== 'undefined' ? (
            <i className="fas fa-pencil-alt mr-2" style={{ cursor: 'pointer', color: '#006BFF', fontSize: '1.25rem' }} onClick={handleEdit} />
          ) : null}
          {onPreview && typeof onPreview !== 'undefined' ? (
            <i className="fas fa-eye mr-2" style={{ cursor: 'pointer', color: '#006BFF', fontSize: '1.25rem' }} onClick={onPreview} />
          ) : null}
          {!isRemoveHidden && onRemove && <i className="fas fa-times-circle text-danger mr-1" style={{ cursor: 'pointer', color: 'red', fontSize: '1.25rem' }} onClick={handleRemoveModal} />}
        </div>
        {props.children}
      </div>

      <BaseModal
        visible={isRemoveAcceptModal}
        headerText="Potwierdzenie usunięcia"
        onClickAway={() => setRemoveAcceptModal(false)}
      >
        <RemoveModalContent
          message={messageOnRemove}
          onRemove={handleRemove}
          onCancel={() => setRemoveAcceptModal(false)}
        />
      </BaseModal>
    </div>
  );
};

RowEditable.defaultProps = {
  onBlockedMessage: () => toast.error(t.error.toast.statusWarning)
};
