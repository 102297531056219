import { optionsBoolean } from 'components/Forms/Select';
import React from 'react';

import { Form, Input, Select } from '../../../../components/Forms';
import { isBoolean } from 'utilities/helpers';
import { ColName } from 'components/Forms/Col';

export default (props) => {

  const no_tax_arrears = props.forms[props.formName]?.no_tax_arrears;
  const no_social_security_arrears = props.forms[props.formName]?.no_social_security_arrears;

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row mt-3">
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <ColName className="col-sm-8 text-right">
                  Brak zaległości podatkowych
                </ColName>
                <div className="col-sm-4">
                  <Select
                    formName={props.formName}
                    options={optionsBoolean}
                    name="no_tax_arrears"
                  />
                </div>
              </div>
            </div>
            {isBoolean(no_tax_arrears) && !no_tax_arrears ? (
              <div className="col-sm-6">
                <div className="form-group row  align-items-center">
                  <ColName className='col-sm-4 text-right'>Kwota</ColName>
                  <div className="col-sm-6">
                    <Input formName={props.formName} type="text" name="tax_arrears_debt" />
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <ColName className="col-sm-8 text-right">
                  Brak zaległości z tytułu składek ZUS
                </ColName>
                <div className="col-sm-4">
                  <Select
                    formName={props.formName}
                    options={optionsBoolean}
                    name="no_social_security_arrears"
                  />
                </div>
              </div>
            </div>
            {isBoolean(no_social_security_arrears) && !no_social_security_arrears ? (
              <div className="col-sm-6">
                <div className="form-group row  align-items-center">
                  <ColName className='col-sm-4 text-right'>Kwota</ColName>
                  <div className="col-sm-6">
                    <Input formName={props.formName} type="text" name="social_security_arrears_debt" />
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancelButtonClick}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}
                >
                  Anuluj
                </button>
                <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
