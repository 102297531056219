import OrderBy from 'components/Forms/OrderBy';
import Tooltip from 'components/Layout/Tooltip';
import Loader from 'components/Loader';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { parsePhone } from 'utilities/helpers';

export default function SortableTable({ loading, results, orderedColumn, onItemClick, onSortingTable }) {
  const history = useHistory();

  return (
    <div>
      <div className="row bt bb">
        <div className="col-sm-12">
          <div className="row grey" style={{ fontSize: '90%' }}>
            <OrderBy isOrdering={orderedColumn === 5} column="ID" onClick={() => onSortingTable('identifier', 5)} className="col-sm-1 p-2 br" />
            <OrderBy isOrdering={orderedColumn === 1} column="Imię i nazwisko" onClick={() => onSortingTable('full_name', 1)} />
            <OrderBy isOrdering={orderedColumn === 2} column="E-mail" onClick={() => onSortingTable('active_email', 2)} />
            <OrderBy isOrdering={orderedColumn === 3} column="Telefon" onClick={() => onSortingTable('active_phone', 3)} />
            <div className="col-sm-2 p-2 br f90">Rola biznesowa</div>
            <OrderBy
              isOrdering={orderedColumn === 6}
              column="Ostatnia modyfikacja"
              onClick={() => onSortingTable('modified_date', 6)}
              className="col-sm-2 p-2"
            />
          </div>
        </div>
      </div>
      <div>
        {results?.length ? (
          results?.map(({ id, slug, full_name, active_email, active_phone, companies, identifier, modified_date }) => (
            <div key={id} className="row" onDoubleClick={() => history.push(`/osoby/${slug}`)} onClick={() => onItemClick(id)}>
              <div className="col-sm-12">
                <div className="row bb data-row">
                  <div className="col-sm-1 p-2 br"><Tooltip label={identifier} /></div>
                  <div className="col-sm-2 p-2 br">{full_name}</div>
                  <div className="col-sm-2 p-2 br">{active_email ?? 'brak'}</div>
                  <div className="col-sm-2 p-2 br">{parsePhone(active_phone)}</div>
                  <div className="col-sm-2 p-2 br">
                    {companies.map(company => company?.roles.map(role => (
                      <span key={role.id} className="badge badge-warning p-1">
                        {role.name}
                      </span>
                    )))}
                  </div>
                  <div className="col-sm-2 p-2">{modified_date}</div>
                </div>
              </div>
            </div>
          ))
        ) : loading ? (
          <Loader />
        ) : (
          <div className="row">
            <div className="col-sm-12 mt-3">Brak wyników.</div>
          </div>
        )}
      </div>
    </div>
  );
}
