import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React from 'react';
import { useSelector } from 'react-redux';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { CommissionForm } from '../../Forms/Commission';

export const Commissions = (props) => {
  const { CommissionsCreatorForm: form } = useSelector(state => state.Forms.forms);

  const formName = 'CommissionsCreatorForm';
  const modalFormName = 'CommissionsCreatorEditForm';

  const commissionTypeSelected = props.forms && props.forms[formName] ? Number(props.forms[formName].commission_type) : null;
  const commissionTypeModalSelected = props.forms && props.forms[modalFormName] ? Number(props.forms[modalFormName].commission_type) : null;
  const parents = props.person.commission_parents || [];
  const ct = props.commission_types || [];

  const getCommissionType = (c) => {
    const type = ct.find((i) => i.id === c.commission_type);
    return type || {};
  };

  const getCommissionUnit = (c) => {
    const type = getCommissionType(c);

    if (type) {
      const units = type.units || [];
      return units.find((u) => u.id === c.unit) || {};
    }

    return {};
  };

  const categories = props.person.system_roles ? props.person.system_roles
    .filter((r) => r.is_used_for_commissions)
    .map((r) => ({ ...r, value: r.id })) : [];

  const editCommission = (commission, parent) => props.edit(
    {
      id: commission.id,
      category: parent.category.id,
      commission_type: commission.commission_type,
      unit: commission.unit,
      max_value: commission.max_value,
      currency_id: commission.currency.id,
      max_records: commission.max_records,
      is_default_in_offer: commission.is_default_in_offer
    },
    modalFormName
  );

  return (
    <Card mode='creator' header='+ Prowizje' onSaveClick={() => props.submitForm(formName)} isBlocked={!form?.category}>
      <CommissionForm
        categories={categories}
        currencies={props.currencies}
        commission_types={props.commission_types}
        commissionTypeSelected={commissionTypeSelected}
        visible
        visibleOnMount
        clear={props.clear}
        formName={formName}
        categorySelected={props.forms[formName] && props.forms[formName].category ? props.forms[formName].category : null}
        showMaxRecordsInCategory={props.forms[formName] && !parents.find((p) => p.id === props.forms[formName].category)}
      />

      {parents.length > 0 ? (
        parents.map((parent) => (
          <>
            <div className="row bb">
              <div className="col-sm-12 p-2 text-themecolor">
                <span>
                  Rola:
                  {parent.category.name}
                </span>
              </div>
            </div>

            <div className="row bb grey">
              <div className="col-sm-2 p-2 br">Typ</div>
              <div className="col-sm-2 p-2 br">Kod</div>
              <div className="col-sm-2 p-2 br">Jednostka</div>
              <div className="col-sm-2 p-2 br">Wartość max.</div>
              <div className="col-sm-2 p-2 br">Waluta</div>
              <div className="col-sm-2 p-2">Domyślna w ofercie</div>
            </div>

            {parent.commissions.length > 0 ? (
              parent.commissions.map((c) => (
                <RowEditable key={c.id} itemId={c.id} onEdit={() => editCommission(c, parent)} onRemove={() => props.remove(c)}>
                  <div className="col-sm-2 p-2 br">{getCommissionType(c).name}</div>
                  <div className="col-sm-2 p-2 br">{c.code || '-'}</div>
                  <div className="col-sm-2 p-2 br">{getCommissionUnit(c).name}</div>
                  <div className="col-sm-2 p-2 br">{c.max_value || '-'}</div>
                  <div className="col-sm-2 p-2 br">{c.currency ? c.currency.name : '-'}</div>
                  <div className="col-sm-2 p-2">
                    <BooleanCheck field={c.is_default_in_offer} />

                  </div>
                </RowEditable>
              ))
            ) : (
              <div className="row">
                <div className="col">Brak rekordów.</div>
              </div>
            )}
          </>
        ))
      ) : (
        <div className="row">
          <div className="col mt-3 mb-3">Brak rekordów.</div>
        </div>
      )}

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Ustawienia prowizji</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}
      >
        <CommissionForm
          selectButtonDisabled
          categories={categories}
          currencies={props.currencies}
          commission_types={props.commission_types}
          commissionTypeSelected={commissionTypeModalSelected}
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(modalFormName)}
          formName={modalFormName}
          categorySelected={props.forms[modalFormName] && props.forms[modalFormName].category ? props.forms[modalFormName].category : null}
          showMaxRecordsInCategory={props.forms[formName] && !parents.find((p) => p.id === props.forms[formName].category)}
          withButtons
          submitForm={() => props.submitForm(modalFormName, true)}
          onCancelButtonClick={() => props.toggleForm(modalFormName)}
        />
      </BaseModal>
    </Card>
  );
};
