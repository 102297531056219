import { BaseModal } from 'components/Layout/OldModal';
import Tooltip from 'components/Layout/Tooltip';
import React from 'react';

import { Form, Input, Required, Selectable, Textarea } from '../../../components/Forms';
import { parsePhone } from '../../../utilities/helpers';

export default props => {
  const creatorFormName = 'ComplaintCreator';
  const claimSelectable = 'ComplaintClaimSelector';
  const f = props.forms?.[props.formName] ?? {};
  const e = props.errors?.[props.formName] ?? {};

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      <div className="row p-2 bb">
        <div className="col-sm-6">
          <div className=" row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Umowa
            </label>
            <div className="col-sm-6">
              <Tooltip label={props.agreement?.identifier} />
            </div>
          </div>
        </div>
      </div>

      <div className="row p-2 bb">
        <div className="col-sm-6">
          <div className=" row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Numer / Status
            </label>
            <div className="col-sm-6">
              {f.identifier || 'Brak / '}
              {props.common?.agreementData?.complaints_statuses?.[f.status || 0]}
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className=" row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Produkt
            </label>
            <div className="col-sm-6">
              {props.agreement?.product?.name} / {props.agreement?.procedure?.name}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-sm-12">
          <div className="form-group row  align-items-center">
            <div className="col-sm-12">
              <Textarea formName={props.formName} type="text" name="comment" placeholder="Wpisz planowany zakres aneksu" />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
                  Klient / zgłaszający
            </label>
            <div className="col-sm-6 d-flex align-items-center" style={e.person_in_company_id ? { color: 'red' } : {}}>
              <span className="mr-3 font-weight-bold">
                <Tooltip label={props.agreement?.client?.identifier} tip={props.agreement?.client?.name} />
              </span>
              <span className="mr-3">{f.person_in_company?.person?.full_name ?? '-'}</span>
              <button type="button" onClick={() => props.toggleForm(creatorFormName)} style={{ maxHeight: '50px' }}>
                    Wybierz osobę
              </button>
            </div>
          </div>
        </div>
      </div>
      {e.person_in_company_id ? (
        <div className="row mb-2" style={{ color: 'red', fontSize: '90%', marginTop: '-0.5rem' }}>
          <div className="col-sm-3" />
          <div className="col-sm-9">{e.person_in_company_id[0]}</div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Telefon
            </label>
            <div className="col-sm-6">
              <Input formName={props.formName} type="text" name="phone" />
            </div>
          </div>
        </div>
      </div>
      <div className="row bb pb-2">
        <div className="col-sm-6">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Adres e-mail
            </label>
            <div className="col-sm-6">
              <Input formName={props.formName} type="text" name="email" />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-sm-6">
          <div className=" row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Opiekun handlowy
              <Required />
            </label>
            <div className="col-sm-6">{props.agreement?.trade_supervisor?.full_name ?? 'brak'}</div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className=" row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Otworzył
            </label>
            <div className="col-sm-6">{f.user_opened?.full_name || '-'}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className=" row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Opiekun ds. Rozliczeń
              <Required />
            </label>
            <div className="col-sm-6">{props.agreement?.billing_manager?.full_name ?? 'brak'}</div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className=" row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Zamknął
            </label>
            <div className="col-sm-6">{f.user_closed?.full_name || '-'}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className=" row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Opiekun ds. Windykacji
            </label>
            <div className="col-sm-6">{props.agreement?.vindicator?.full_name ?? 'brak'}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="mt-4 col-sm-12 text-center">
          <button
            type="button"
            onClick={props.onCancelButtonClick}
            className="btn btn-primary-outline mr-2 font-weight-light"
            style={{ color: '#4B4E55' }}>
                  Anuluj
          </button>
          {f.status === 0 ? null : <button
            type="button"
            onClick={() => {
              if (f.status === 1) {
                props.toggleForm('ReopenConfirmation');
              } else {
                // props.storeInput(props.formName, 'open_it', true);
                props.submitForm();
              }
            }}
            className="btn btn-primary btn-primary-blue ml-2  font-weight-light">
                  Otwórz
          </button>}
          {(f.status ===1 || typeof f.status === 'undefined') ? null : <>
            <button
              type="button"
              onClick={() => {
                props.storeInput(props.formName, 'close_it', true);
                props.submitForm();
              }}
              className="btn btn-primary btn-primary-blue ml-2  font-weight-light">
                    Zamknij
            </button>
            <button
              type="button"
              onClick={() => {
                props.submitForm();
              }}
              className="btn btn-primary ml-2  font-weight-light">
                    Zapisz
            </button></>
          }
        </div>
      </div>

      <Selectable
        fetch={() => {}}
        visible={props.formVisible(creatorFormName)}
        formName={props.formName}
        field="person_in_company"
        columns={[{ name: 'Imię i nazwisko', getter: 'person.full_name' }]}
        options={props.agreement?.client?.persons}
        onClickAway={() => props.toggleForm(creatorFormName)}
        header="Wybierz zgłaszającego"
        onSelected={() => {
          props.storeInput(props.formName, 'email', f.person_in_company?.person?.active_email);
          props.storeInput(props.formName, 'phone', parsePhone(f.person_in_company?.person?.active_phone));
        }}
      />

      <Selectable
        filtersForm={props.claimsFilters}
        fetch={props.fetchClaims}
        visible={props.formVisible(claimSelectable)}
        formName={props.formName}
        field="claim"
        columns={[{ name: 'Identyfikator', getter: 'identifier' }]}
        options={props.claims}
        onClickAway={() => props.toggleForm(claimSelectable)}
        header="Wybierz wierzytelność"
      />


      <BaseModal
        visible={props.formVisible('ReopenConfirmation')}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Uwaga!</span>
          </div>
        }
        onClickAway={() => props.toggleForm('ReopenConfirmation')}>
        <div className="row mt-1">
          <div className="col-sm-12">
            Ponowne otwarcie wniosku będzie skutkowało wyczyszczeniem dotychczasowej decyzji kredytowej. Czy kontynuować?
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12 d-flex align-items-center justify-content-center">

            <button
              type="button"
              onClick={() => {
                props.toggleForm('ReopenConfirmation');
              }}
              className="btn btn-primary-outline  font-weight-light">
              Anuluj
            </button>
            <button
              type="button"
              onClick={() => {
                props.storeInput(props.formName, 'open_it', true);
                props.submitForm();
              }}
              className="btn btn-primary btn-primary-blue ml-2  font-weight-light">
              Otwórz
            </button>
          </div>
        </div>
      </BaseModal>
    </Form>
  );
};
