import { BaseModal } from 'components/Layout/OldModal';
import { STATUS_AGREEMENT_VERSION } from 'containers/Agreements/Details/Index';
import React from 'react';

import { OldCard } from '../../../../components/Layout';
import { ClaimBidsForm } from '../../Forms/Bids';

export default (props) => {
  const formName = 'ClaimBidsForm';

  const debtor = props.claim?.agreement?.agreement_versions
    ?.find(item => item.status === STATUS_AGREEMENT_VERSION.ZATWIERDZONY)
    .agreement_debtors?.find((x) => x.id === props.claim?.debtor?.id) ?? {};

  const header = (
    <a
      role="button"
      style={{ fontSize: '24px' }}
      className="collapsed "
      data-toggle="collapse"
      href="#warunkiDodatkowe"
      aria-expanded="false"
      aria-controls="collapseExample"
    >
      Stawka
    </a>
  );

  const customCreateSection = (
    <>
      <i className="fas fa-pencil-alt ml-2 mr-2 " onClick={() => props.edit(formName, { ...props.claim })} />
      <i className="fas fa-question-circle" onClick={() => { }} />
    </>
  );

  return (
    <OldCard
      header={header}
      id="financialData-card"
      showIcons
      customCreateSection={customCreateSection}
    >
      <div className="collapse show" id="financialData">
        <ClaimBidsForm debtor={debtor} common={props.common} details claimData={props.common?.claimData} form={props.forms?.[formName]} storeInput={props.storeInput} formName={formName} claim={props.claim} />
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        headerText="Edycja stawki"
        footer=""
        onClickAway={() => props.toggleForm(formName)}
      >
        <ClaimBidsForm debtor={debtor} editMode submitForm={() => props.saveForm(formName)} onCancel={() => props.toggleForm(formName)} withButtons common={props.common} claimData={props.common?.claimData} form={props.forms?.[formName]} storeInput={props.storeInput} formName={formName} claim={props.claim} />
      </BaseModal>
    </OldCard>
  );
};
