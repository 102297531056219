import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import { isEditableProposal } from 'containers/Proposals/Details/Index';
import { t } from 'lang/pl';
import React from 'react';
import { toast } from 'react-toastify';
import { formatValueToEurope } from 'utilities/helpers';

import RiskForm from '../../Forms/Risk';

export default (props) => {
  const formName = 'RiskForm';

  const version = props.version && props.version ? props.version : {};

  const edit = () => props.edit({
    rating_type_id: version.rating_type ? version.rating_type.id : null,
    rating_class_id: version.rating_class ? version.rating_class.id : null,
    information_agency_id: version.information_agency ? version.information_agency.id : null,
    information_agency_class_id: version.information_agency_class ? version.information_agency_class.id : null,
    information_agency_limit: version.information_agency_limit,
    information_agency_report_date: version.information_agency_report_date,
    rating_agency_id: version.rating_agency ? version.rating_agency.id : null,
    rating_agency_score_id: version.rating_agency_score ? version.rating_agency_score.id : null,
    is_jst_or_corporate_treasury: version.is_jst_or_corporate_treasury,
    is_bankruptcy_or_liquidation: version.is_jst_or_corporate_treasury,
    information_agency_pmi: version.information_agency_pmi,
    category_id: version.category ? version.category.id : null
  }, formName);

  return (
    <Card
      header='Ryzyko faktoranta'
      isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
      onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
      onEditClick={edit}
      options={{ isCollapsed: false }}
    >
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Rating własny pełny</div>
              <div className="col-sm-6 p-2 ">
                {version.rating_class ? version.rating_class.name : 'brak'}
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Wywiadownia / Rating / PMI</div>
              <div className="col-sm-6 p-2 ">
                {version.information_agency ? version.information_agency.name : 'brak'}
                {' '}
                /
                {' '}
                {version.information_agency_class ? version.information_agency_class.name : 'brak'}
                {' '}
                /
                {' '}
                {version.information_agency_pmi ?? 'brak'}

              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Limit wywiadowni</div>
              <div className="col-sm-6 p-2 ">{formatValueToEurope(version.information_agency_limit)}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Data raportu wywiadowni</div>
              <div className="col-sm-6 p-2 ">
                {version.information_agency_report_date || 'brak'}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3 p-2 br grey">Kategoria faktoranta</div>
              <div className="col-sm-6 p-2 ">
                {version?.category?.name ?? '-'}
              </div>
            </div>
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Ryzyko faktoranta</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <RiskForm
          populate={() => props.populateForm(formName)}
          forms={props.forms}
          proposalData={props.proposalData}
          banks={props.banks}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(formName)}
          formName={formName}
          submitForm={() => props.saveForm(formName)}
          onCancelButtonClick={() => props.toggleForm(formName)}
          withButtons
        />
      </BaseModal>
    </Card>
  );
};
