import 'twin.macro';

import api from 'api';
import Button from 'components/Forms/Button';
import Col, { ColName } from 'components/Forms/Col';
import SelectHook from 'components/Forms/SelectHook';
import { BaseModal } from 'components/Layout/OldModal';
import useData from 'hooks/useData';
import { t } from 'lang/pl';
import moment from 'moment';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export default function DueStatus({ isOpen, onClose, claim }) {
  const { claimData } = useSelector(state => state.Common);
  const { control, handleSubmit, errors } = useForm();
  const { sendDispatchRequest } = useData();

  const onSubmit = ({ selectDue }) => {
    sendDispatchRequest(api.claims.changeDueStatus, { id: claim.id, due_status: selectDue.id }, () => {
      toast.success('status został zmieniony.');
    });
    onClose();
  };

  const options = Object.values(claimData?.due_claim_statuses)
    .map((item, key) => ({ id: key, name: item }))
    .filter(item => item.id >= 5);

  return (
    <BaseModal
      visible={isOpen}
      headerText="Zmiana statusu wymagalności"
      footer="Aby zapisać wciśnij Zmień."
      onClickAway={onClose}
    >
      <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between mt-10 mb-120">
          <ColName>Identyfikator wierzytelności:</ColName>
          <Col>{claim?.identifier}</Col>

          <div className='flex flex-1'>
            <ColName>Z statusu:</ColName>
            <Col>{claimData?.due_claim_statuses[claim?.due_status]}</Col>

            <ColName>Na status:</ColName>
            <SelectHook
              control={control}
              rules={{ required: t.error.field.select }}
              error={errors.selectDue}
              name='selectDue'
              options={options}
            />
          </div>

          <ColName>Data zawarcia:</ColName>
          <Col>{moment().format('DD.MM.YYYY')}</Col>
        </div>

        <div className='flex items-center justify-center mt-10'>
          <Button onClick={onClose}>Anuluj</Button>
          <Button mode='primary' type='submit'>Zmień</Button>
        </div>
      </form>
    </BaseModal>
  );
}
