import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import { isEditableProposal } from 'containers/Proposals/Details/Index';
import { t } from 'lang/pl';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import RepresentationForm from '../../Forms/Representation';
import { formatString } from 'utilities/helpers';

export default props => {
  const formName = 'RepresentationForm';

  useEffect(() => {
    if (props.populateForm) {
      props.populateForm(formName);
    }
    // eslint-disable-next-line
  }, props);

  const getRepresentants = values => values?.map(i => ({
    value: i.id,
    name: i.person.full_name || '-',
    date: i.created_date || '-',
    roles: i.roles.map(j => j.name).join(', ')
  })) ?? [];

  const rows = props.version && props.version.representants ? props.version.representants : [];

  const handleShowEditForm = () => {
    props.edit({ id: rows.length ? rows[0].id : null }, formName);
  };

  return (
    <Card
      header='Reprezentacja'
      onEditClick={handleShowEditForm}
      isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
      onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
    >
      <div className="collapse show" id="financialData">
        {rows.length ? (
          <>
            <div className="row">
              <div className="col-sm-12">
                <div className="row bb">
                  <div className="col-sm-3 p-2 grey">Imię i nazwisko</div>
                  <div className="col-sm-3 p-2 grey">Rola biznesowa</div>
                  <div className="col-sm-3 p-2 grey">E-mail</div>
                  <div className="col-sm-3 p-2 grey">Wprowadził</div>
                </div>
                {rows.map(row => (
                  <>
                    {row.people_in_company.map(p => (
                      <RowEditable key={row.identifier}>
                        <div className="col-sm-3 p-2">{formatString(p?.person?.full_name)}</div>
                        <div className="col-sm-3 p-2">{p.roles ? p.roles.map(r => r.name).join(', ') : '-'}</div>
                        <div className="col-sm-3 p-2">{formatString(p?.person?.active_email)}</div>
                        <div className="col-sm-3 p-2">{formatString(row?.user_creator?.full_name)}</div>
                      </RowEditable>
                    ))}

                    <div className="row">
                      <div className="col-sm-3 p-2 grey">Sposób reprezentacji</div>
                      <div className="col-sm-9 p-2">
                        <span
                          className="badge pl-3 pr-3 pt-2 pb-2"
                          style={{ backgroundColor: row.representation ? row.representation.background_color : 'white' }}>
                          {row.representation ? row.representation.name : 'brak'}
                        </span>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className="row">
            <div className="col p-2">
              <span>Brak wyników.</span>
            </div>
          </div>
        )}
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Reprezentacja</span>
          </div>
        }
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}>
        <RepresentationForm
          populate={() => props.populateForm(formName)}
          forms={props.forms}
          representations={props.representations}
          representants={getRepresentants(props.representants)}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(formName)}
          formName={formName}
          submitForm={() => props.saveForm(formName)}
          onCancelButtonClick={() => props.toggleForm(formName)}
          formVisible={props.formVisible}
          toggleForm={props.toggleForm}
          withButtons
        />
      </BaseModal>
    </Card>
  );
};
