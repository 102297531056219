import React from 'react';
import { withRouter } from 'react-router-dom';

import { OldCard } from '../../../components/Layout';
import { SidebarNotes } from '../../../components/SidebarNotes';
import { formatNip, parsePhone } from '../../../utilities/helpers';
import RemovalModal from '../Forms/RemovalModal';

class CompanySidebarView extends React.Component {
  constructor() { super(); this.state = { objectToRemove: {} }; }

    setObjectToRemove = (object) => {
      if (this.state.objectToRemove.id) {
        this.setState({ objectToRemove: {} });
      } else {
        this.setState({ objectToRemove: object });
      }
    };

    render() {
      let company = this.props.company ?? {};
      const { loading } = this.props;

      if (loading || !company) {
        return <span>Loading...</span>;
      }

      company = { ...company, active_correspondence_address: {}, active_headquarters_address: {} };

      return (
        <OldCard className="mt-3 pt-3 pb-3">
          <div className="row">
            <div className="col-sm-12">
              <h3 className="font-weight-bold">
                {company.name}
                <span className="float-right text-themecolor" style={{ cursor: 'pointer' }}>
                  <i className="fas fa-sign-in-alt mr-2" onClick={() => this.props.history.push(`/firmy/${company.slug}`)} />

                  <i className="fa fa-trash" style={{ color: 'red' }} onClick={() => this.setObjectToRemove(company)} />
                </span>
              </h3>
            </div>
          </div>
          <div className=" mt-2 mb-2">
            <div className="row bt bb p-1">
              <div className="col-sm-4 text-left grey">Forma prawna</div>
              <div className="col-sm-8 text-right">{company.legal_form?.name}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mt-3 mb-3">
              <button type="button" className="btn btn-sm btn-success mr-3">
                <i className="fa fa-envelope mr-2" />
                Wiadomość
              </button>
              <button type="button" className="btn btn-sm btn-success mr-3">
                <i className="fa fa-link mr-2" />
                Powiąż
              </button>
              <button type="button" className="btn btn-sm btn-success">
                <i className="fa fa-envelope mr-2" />
                Zadanie
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12">
              <h3 className="card-title text-themecolor">Role biznesowe firmy</h3>
              {company.business_company?.length > 0 ? (
                company.business_company?.map((x) => (
                  <span key={x} className="badge badge-warning mr-2 pl-3 pr-3 pt-2 pb-2">
                    {x.name}
                  </span>
                ))
              ) : (
                <span>brak</span>
              )}
            </div>
          </div>
          <div className="rows-bordered row mt-3">
            <div className="col-sm-12">
              <h3 className="card-title text-themecolor">Osoby powiązane</h3>
              {company.related_people?.length ? company.related_people?.map((p) => (
                <div key={p.id} className="d-block-inline m-auto text-center mb-3">
                  <div style={{ float: 'left' }}>{p.person?.full_name}</div>
                  <div style={{ float: 'right' }}>
                    {p.roles.map((r) => (
                      <span className="badge badge-warning mr-2 pl-3 pr-3 pt-2 pb-2">{r.name}</span>
                    ))}
                  </div>
                  <div style={{ clear: 'both' }} />
                </div>
              )) : (
                <div className="mb-3">
                  <span>brak</span>
                </div>
              )}
            </div>
          </div>

          <div className="rows-bordered mt-1">
            <div className="row">
              <div className="col-12">
                <h4 className="text-themecolor mt-2 mb-2">Dane</h4>
              </div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">ID firmy</div>
              <div className="col-sm-6 text-right">{company.id}</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">NIP</div>
              <div className="col-sm-6 text-right">{formatNip(company.nip)}</div>
            </div>
            {/* <div className="row">
                        <div className="col-sm-6 text-left grey">KRS</div>
                        <div className="col-sm-6 text-right">{company.krs}</div>
                    </div> */}
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Telefon</div>
              <div className="col-sm-6 text-right">{parsePhone(company.active_phone)}</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Email</div>
              <div className="col-sm-6 text-right">{company.active_email}</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Adres korespondencyjny</div>
              <div className="col-sm-6 text-right">
                {company.active_correspondence_address ? `${company.active_correspondence_address?.street ?? ''} ${company.active_correspondence_address?.building_no ?? ''}/${company.active_correspondence_address?.local_no ?? ''} ${company.active_correspondence_address?.zipcode ?? ''} ${company.active_correspondence_address?.city ?? ''}` : 'brak'}

              </div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Adres rejestrowy</div>
              <div className="col-sm-6 text-right">
                {company.active_headquarters_address ? `${company.active_headquarters_address?.street ?? ''} ${company.active_headquarters_address?.building_no ?? ''}/${company.active_headquarters_address?.local_no ?? ''} ${company.active_headquarters_address?.zipcode ?? ''} ${company.active_headquarters_address?.city ?? ''}` : 'brak'}
              </div>
            </div>
            <div className="row p-1">
              <div className="col-12">
                <h4 className="text-themecolor mt-2 mb-2">Współpraca</h4>
              </div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Kategoria faktoranta</div>
              <div className="col-sm-6 text-right">?</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Ilość obsługiwanych umów</div>
              <div className="col-sm-6 text-right">?</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Rozpoczęcie współpracy</div>
              <div className="col-sm-6 text-right">?</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Zagrożone i wypowiedziane umowy</div>
              <div className="col-sm-6 text-right">?</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Dłużników zgłoszonych / obsługiwanych</div>
              <div className="col-sm-6 text-right">?</div>
            </div>
            <div className="row p-1">
              <div className="col-12">
                <h4 className="text-themecolor mt-2 mb-2">Obroty</h4>
              </div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Wierzytelności spłacone</div>
              <div className="col-sm-6 text-right">?</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Wierzytelności niespłacone</div>
              <div className="col-sm-6 text-right">?</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Wierzytelności przeterminowane</div>
              <div className="col-sm-6 text-right">?</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Ilościowo spł./niespł./przeter.</div>
              <div className="col-sm-6 text-right">?</div>
            </div>
            <div className="row p-1">
              <div className="col-12">
                <h4 className="text-themecolor mt-2 mb-2">Zaangażowanie</h4>
              </div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Aktualne</div>
              <div className="col-sm-6 text-right">?</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Planowane</div>
              <div className="col-sm-6 text-right">?</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Całkowite</div>
              <div className="col-sm-6 text-right">?</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Wykorzystanie limitu całkowite</div>
              <div className="col-sm-6 text-right">?</div>
            </div>
            <div className="row p-1">
              <div className="col-sm-6 text-left grey">Deklarowane wykorzystanie</div>
              <div className="col-sm-6 text-right">?</div>
            </div>
            <div className="row p-1">
              <div className="col-12">
                <h4 className="text-themecolor mt-2 mb-2">Notatki</h4>
                <SidebarNotes object={company} />
              </div>
            </div>
          </div>

          <RemovalModal
            visible={Object.keys(this.state.objectToRemove).length > 0}
            toggle={() => this.setObjectToRemove({})}
            remove={() => {
              this.props.remove(this.state.objectToRemove.id);
              this.setObjectToRemove({});
            }}
          >
            {this.state.objectToRemove && (
              <div className="container-fluid">
                <div className="row">Czy na pewno chcesz usunąć tę firmę?</div>
              </div>
            )}
          </RemovalModal>
        </OldCard>
      );
    }
}

export default withRouter(CompanySidebarView);
