import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useState } from 'react';

import RemovalModal from '../../Forms/RemovalModal';
import { RolesForm } from '../../Forms/Roles';

export const Roles = (props) => {
  const [objectToRemove, setObjectToRemove] = useState({});
  const system_roles = props.company.business_company || [];

  const formName = 'RoleForm';

  return (
    <Card header='Role biznesowe' onAddClick={() => props.create(formName)}>
      <div>
        <div className="mt-3 mb-3">
          {system_roles.map((r) => (
            <span onClick={() => setObjectToRemove(r)} key={r.name} className="badge badge-warning mr-3 pt-2 pb-2 pl-3 pr-3">
              {r.name}
            </span>
          ))}
          {system_roles.length === 0 && <p>Brak danych.</p>}
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>
              Role biznesowe
            </span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <RolesForm
          visibleOnMount={false}
          visible={props.formVisible(formName)}
          formName={formName}
          withButtons
          submitForm={() => props.saveForm(formName)}
          onCancel={() => props.toggleForm(formName)}
          roles={props.companyRoles}
          selectPerson={props.selectPerson}
        />
      </BaseModal>

      <RemovalModal
        visible={Object.keys(objectToRemove).length > 0}
        toggle={() => setObjectToRemove({})}
        remove={() => {
          props.remove(objectToRemove.id);
          setObjectToRemove({});
        }}
      >
        {objectToRemove && (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 text-center mt-3">
                <span className="badge badge-warning pt-2 pb-2 pl-3 pr-3">{objectToRemove.name}</span>
              </div>
            </div>
          </div>
        )}
      </RemovalModal>
    </Card>
  );
};
