import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import FormActions from '../../actions/forms';

export default connect(
  state => ({ forms: state.Forms }),
  dispatch => ({
    storeInput: (f, n, v) => dispatch(FormActions.storeInput(f, n, v))
  })
)(props => {
  const value = get(props.forms.forms[props.formName], props.name, null);

  if (value === null) {
    props.storeInput(props.formName, props.name, false);
  }

  return (
    <div>
      <input
        type="checkbox"
        id={props.formName + props.name}
        key={props.formName + props.name}
        checked={value}
        className="filled-in"
        onChange={() => {
          props.storeInput(props.formName, props.name, !value);

          if (props.onChange && typeof props.onChange === 'function') {
            props.onChange();
          }
        }}
      />
      <label htmlFor={props.formName + props.name}>{props.label}</label>
    </div>
    // <div className="pretty p-default">
    //   <input
    //     type="checkbox"
    //     checked={value}
    //     onChange={() => {
    //       props.storeInput(props.formName, props.name, !value);
    //
    //       if (props.onChange && typeof props.onChange === 'function') {
    //         props.onChange();
    //       }
    //     }}
    //   />
    //     <div className="state">
    //         <label>Default</label>
    //     </div>
    // </div>
    // <label
    //   // style={props.style || null}
    //   // className={props.className || 'custom-control custom-checkbox'}
    //   // className={props.className || 'custom-control custom-checkbox d-flex justify-content-center align-items-center'}
    // >
    //   <input
    //     type="checkbox"
    //     checked={value}
    //     className="custom-control-input"
    //     onChange={() => {
    //       props.storeInput(props.formName, props.name, !value);
    //
    //       if (props.onChange && typeof props.onChange === 'function') {
    //         props.onChange();
    //       }
    //     }}
    //   />
    //   <span className="custom-control-label custom-control-label grey" style={{ fontWeight: '200' }}>
    //     {props.label}
    //   </span>
    // </label>
  );
});
