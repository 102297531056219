import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { parseMoney } from '../../../../utilities/helpers';
import { BasicDataForm } from '../../Forms/BasicData';

export const BasicData = (props) => {
  const formName = 'CalculationsEditForm';

  let calculation = props.calculation || {};
  calculation = {
    ...calculation,
    currencyName: calculation.currency ? calculation.currency.name : null,
    currency: calculation.currency ? calculation.currency.id : null,
    transaction_risk_category_id: calculation.transaction_risk_category ? calculation.transaction_risk_category.id : null
  };

  const suff = (i) => {
    if (i === 1) return 'miesiąc';
    if (i < 5) return 'miesiące';
    if (i >= 5) return 'miesięcy';
    return '?';
  };

  const getPrepCommissionType = () => {
    const i = props.offerData && props.offerData ? props?.offerData?.preparation_commission_type?.find((x) => x?.id === calculation?.preparation_commission_type) : {};
    return i ? i.name : '';
  };

  return (
    <Card header='Dane podstawowe' onEditClick={() => props.edit(calculation, formName)}>
      <div className="collapse show" id="danepodstawowe">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">ID kalkulacji</div>
              <div className="col-sm-6 p-2 br">{calculation.id}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Kwota limitu</div>
              <div className="col-sm-6 p-2 br font-weight-bold">
                {`${parseMoney(calculation.limit_amount)} ${calculation.currencyName}`}
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Planowany okres umowy</div>
              <div className="col-sm-6 p-2 br">
                {`${calculation.duration_of_the_contract} ${suff(calculation.duration_of_the_contract)}`}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 p-2 br grey">Kategoria ryzyka transakcji</div>
              <div className="col-sm-6 p-2 br">
                {calculation.transaction_risk_category ? calculation.transaction_risk_category.name : '-'}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Czy zatwierdzona</div>
              <div className="col-sm-6 p-2">
                <BooleanCheck field={calculation.status} />
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Wielu dłużników</div>
              <div className="col-sm-6 p-2">
                <BooleanCheck field={calculation.many_debtors} />

              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Regres</div>
              <div className="col-sm-6 p-2">
                <BooleanCheck field={calculation.recourse} />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 p-2 br grey">Prowizja przygotowawcza</div>
              <div className="col-sm-6 p-2">{calculation.preparation_commission ? `${calculation.preparation_commission} ${getPrepCommissionType()}` : '-'}</div>
            </div>
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Dane podstawowe</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <BasicDataForm
          forms={props.forms}
          currencies={props.currencies}
          offerData={props.offerData}
          calculationId={calculation.id}
          calculation={calculation}
          offer={props.offer}
          preloadedForm
          visibleOnMount={false}
          clear={props.clear}
          visible={props.formVisible(formName)}
          formName={formName}
          saveForm={() => props.saveForm(formName, 1)}
          onCancel={() => props.toggleForm(formName)}
          withButtons
        />
      </BaseModal>
    </Card>
  );
};
