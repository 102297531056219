import React from 'react';

import { Form, Input, Select } from '../../../components/Forms';
import { OldCard } from '../../../components/Layout';

export const Searchbar = (props) => (
  <OldCard className="mt-3 pt-3 pb-3">
    <Form className="form-horizontal" formName={props.formName}>
      <div className="row">
        <div className="col-sm-12">
          <h4 className="card-title">
            <span className=" text-themecolor">Filtruj rachunki</span>
            {/* <p className="small d-inline-flex float-right text-black"> */}
            {/*  Plik importu: 20-20-09.imp | mBank SA | Ilość rachunków: 3 */}
            {/* </p> */}
          </h4>
          {props.subtitle && <h6 className="card-subtitle">{props.subtitle}</h6>}
        </div>
      </div>
      <div className="row d-flex align-items-center">
        <div className="col-sm-5">
          <Select
            formName={props.formName}
            options={[
              { id: null, value: '', name: 'Wszystkie rachunki' },
              ...props.selectOptions
            ]}
            name="sender_bank_account_number"
            placeholder="Wszystkie rachunki"
          />
        </div>
        <div className="col-sm-5">
          <Input formName={props.formName} type="text" name="term" placeholder="Wpisz szukaną frazę" />
        </div>
        <div className="col-sm-2">
          <button type="button" onClick={props.onSubmit} className="btn btn-primary p-1 font-weight-light">
            Zatwierdź
          </button>
        </div>
      </div>
    </Form>
  </OldCard>
);
