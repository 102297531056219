import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CommonActions from '../../../actions/common';
import CompanyActions from 'actions/companies';
import FormActions from '../../../actions/forms';
import OtherActions from '../../../actions/other';
import BooleanCheck from '../../../components/BooleanCheck';
import {
  Form, Input, Selectable, Textarea
} from '../../../components/Forms';
import {
  OldCard, Container, MainContainer
} from '../../../components/Layout';

class Notifications extends React.Component {
  constructor() {
    super();

    this.state = {
      way: 1
    };

    this.formName = 'NotificationsForm';
    this.recipientFilters = 'NotificationsRecipientFilters';
    this.columns = [
      { name: 'ID', getter: 'id' },
      { name: 'Nazwa', getter: 'name' },
      { name: 'E-mail', getter: 'active_email' }
    ];
  }

  componentDidMount() {
    this.props.dispatch(CommonActions.setContextMenu());
  }

  fetchCompanies = () => {
    this.props.dispatch(CompanyActions.fetch(this.getCompanyFilters(), true));
  };

  getCompanyFilters = () => {
    const currentFilters = this.props.forms.forms[this.recipientFilters] || {};

    if (!currentFilters || Object.keys(currentFilters).length <= Object.keys(CommonActions.defaultFilters).length) {
      this.props.dispatch(FormActions.initialize(this.recipientFilters, CommonActions.defaultFilters, {}));
    }

    return this.props.forms.forms[this.recipientFilters];
  };

  submit() {
    this.props.dispatch(
      OtherActions.sendNotification(this.formName)
    );
  }

  toggleForm(f) {
    this.props.dispatch(FormActions.toggleForm(f));
  }

  formVisible(f) {
    return this.props.forms.options.visible.indexOf(f) !== -1;
  }

  updateWay(state) {
    // this.props.dispatch(
    //   FormActions.storeInput(this.formName, 'way', state.way),
    // );
    this.setState(state, () => {
      const { way } = state;

      switch (way) {
        case 1:
          this.props.dispatch(FormActions.clearField(this.formName, 'operator_email'));
          this.props.dispatch(FormActions.clearField(this.formName, 'recipient_email'));
          break;
        case 2:
          this.props.dispatch(FormActions.clearField(this.formName, 'operator_email'));
          this.props.dispatch(FormActions.clearField(this.formName, 'recipient'));
          this.props.dispatch(FormActions.clearField(this.formName, 'recipient_id'));
          break;
        case 3:
          this.props.dispatch(FormActions.clearField(this.formName, 'recipient'));
          this.props.dispatch(FormActions.clearField(this.formName, 'recipient_id'));
          this.props.dispatch(FormActions.clearField(this.formName, 'recipient_email'));
          break;
        default:
          break;
      }
    });
  }

  render() {
    const header = (
      <>
        <span className="mb-0 font-weight-light" style={{ color: '#006BFF', fontSize: '24px' }}>
          Moduł powiadomień systemowych oraz email
        </span>
      </>
    );

    const { recipient } = this.props.forms?.forms?.[this.formName] ?? {};
    const { way } = this.state;
    return (
      <Container>
        <MainContainer className="col-sm-12">
          <OldCard className="mt-5" header={header}>
            <Form formName={this.formName} asDiv>
              <div className="row bb ">
                <div className="col-sm-1 pl-2 pr-2 pt-3 pb-3 br text-center">
                  1
                </div>
                <div className="col-sm-11 pl-3 pr-2 pt-3 pb-3 br">
                  <div className="row align-items-center align-self-center">
                    <div className="col-sm-2 p-2 ">
                      <BooleanCheck asRadioButton field={way === 1} onClick={() => this.updateWay({ way: 1 })} className="ml-3" />
                      <span className="ml-3">Odbiorca</span>
                    </div>
                    <div className="col-sm-8 p-2">
                      {recipient && (
                        <span className="mr-3">
                          {recipient?.name}
                          {' '}
                          (
                          {recipient?.active_email}
                          )
                        </span>
                      )}
                      <button
                        type="button"
                        disabled={way !== 1}
                        onClick={() => {
                          this.fetchCompanies();
                          this.toggleForm('recipient');
                        }}
                      >
                        ...
                      </button>
                    </div>
                  </div>
                  <div className="row align-items-center align-self-center">
                    <div className="col-sm-2 p-2 ">
                      <BooleanCheck asRadioButton field={way === 2} onClick={() => this.updateWay({ way: 2 })} className="ml-3" />
                      <span className="ml-3">Odbiorca email</span>
                    </div>
                    <div className="col-sm-2 p-2">
                      <Input disabled={way !== 2} formName={this.formName} name="recipient_email" />
                    </div>
                  </div>
                  <div className="row align-items-center align-self-center">
                    <div className="col-sm-2 p-2 ">
                      <BooleanCheck asRadioButton field={way === 3} onClick={() => this.updateWay({ way: 3 })} className="ml-3" />
                      <span className="ml-3">Operator email</span>
                    </div>
                    <div className="col-sm-2 p-2">
                      <Input disabled={way !== 3} formName={this.formName} name="operator_email" />
                    </div>
                  </div>

                </div>
              </div>
              <div className="row bb ">
                <div className="col-sm-1 pl-2 pr-2 pt-3 pb-3 br text-center">
                  2
                </div>
                <div className="col-sm-11 pl-3 pr-2 pt-3 pb-3 br">
                  <div className="row align-items-center">
                    <label htmlFor="example-text-input " className="col-sm-2 col-form-label label-right">
                      Temat
                    </label>
                    <div className="col-sm-2 p-2">
                      <Input formName={this.formName} name="title" />
                    </div>
                  </div>
                  <div className="row mt-2 ">
                    <label htmlFor="example-text-input " className="col-sm-2 col-form-label label-right">
                      Treść
                    </label>
                    <div className="col-sm-4  p-2">
                      <Textarea formName={this.formName} name="message" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-sm-12 pl-2 pr-2 pt-3 pb-3 br text-center">
                  <button type="button" className="btn btn-primary btn-primary-blue" onClick={() => this.submit()}>
                    Wyślij powiadomienie
                  </button>
                </div>
              </div>
            </Form>
          </OldCard>

          <Selectable
            filtersForm={this.recipientFilters}
            fetch={() => this.fetchCompanies()}
            visible={this.formVisible('recipient')}
            formName={this.formName}
            field="recipient"
            columns={this.columns}
            options={this.props.companies}
            onClickAway={() => this.toggleForm('recipient')}
            header="Wybierz odbiorcę"
          />
        </MainContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.Common,
  forms: state.Forms,
  companies: state.Company
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Notifications)
);
