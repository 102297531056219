import AgreementActions from 'actions/agreement';
import Card from 'components/Layout/Card';
import { RowEditable } from 'components/Layout/RowEditable';
import { DebtorsCreatorForm } from 'containers/Agreements/Creator/Components/DebtorsProposal';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import BooleanCheck from '../../../../components/BooleanCheck';
import { formatValueToEurope, formatNip } from '../../../../utilities/helpers';

export default props => {
  const { agreement, version } = useSelector(state => state.Agreement);
  const { forms } = useSelector(state => state.Forms);
  const dispatch = useDispatch();

  const f = forms[DebtorsCreatorForm] ?? {};

  const handleRemoveAll = () => {
    dispatch(AgreementActions.removeAgreementDebtors(agreement?.id, version, f?.debtors));
  };

  const handleRemove = idDebtor => () => {
    dispatch(AgreementActions.removeAgreementDebtor(agreement?.id, version?.id, idDebtor));
  };

  return (
    <Card
      mode='creator' header='+ Wybrani dłużnicy w umowie' options={{ isPosted: true }}
      isBlocked={!version.agreement_debtors?.length}
      onBlockedMessage={() => toast.warn('Brak dłużników w umowie')}
      messageRemove='To spowoduje usunięcie wszystkich poniższych dłużników wraz z rachunkami uznaniowymi faktora.'
      onRemoveClick={handleRemoveAll}
    >
      <div>
        <div className="row bb">
          <div className="flex-30 p-2 grey br">Nazwa firmy</div>
          <div className="flex-20 max-w-150 p-2 grey br">NIP</div>
          <div className="flex-10 p-2 grey br">Wnioskowany limit</div>
          <div className="flex-10 p-2 grey br">Kategoria</div>
          <div className="flex-10 max-w-80 p-2 grey br">Zgoda na cesję</div>
          <div className="flex-12 p-2 grey br">Czy generować potwierdzenie cesji?</div>
          <div className="flex-30 max-w-220 p-2 grey text-center">Sposób wykupu</div>
        </div>
        {version.agreement_debtors?.map((agreementDebtor, key) => {
          return (
            <RowEditable
              index={key}
              key={agreementDebtor.id}
              itemId={agreementDebtor.id}
              onRemove={handleRemove(agreementDebtor.id)}
            >
              <div className="flex-30 p-2 br d-flex align-items-center">
                <span className="ml-2">{agreementDebtor?.name}</span>
              </div>
              <div className="flex-20 p-2 max-w-150 br">{formatNip(agreementDebtor?.nip)}</div>
              <div className="flex-10 p-2  br">{formatValueToEurope(agreementDebtor.requested_sublimit)}</div>
              <div className="flex-10 p-2  br">{agreementDebtor.category?.name}</div>
              <div className="flex-10 max-w-80 p-2  br">
                <BooleanCheck field={true} disabled />
              </div>
              <div className="flex-12 p-2  br">
                <BooleanCheck field={agreementDebtor.generate_assignment} disabled />
              </div>

              <div className="flex-30 max-w-220 p-2 text-center">
                <span className="mr-2">{agreementDebtor.purchase_method === 0 ? 'Brutto' : 'Netto'}</span>
              </div>
            </RowEditable>
          );
        })}
      </div>
    </Card>
  );
};
