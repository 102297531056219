import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import parse from 'html-react-parser';
import React, { useState } from 'react';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import { NoteForm } from '../../Forms/Note';
import RemovalModal from '../../Forms/RemovalModal';

export function TextLink({ children }) {
  const urlRegexHttps = /(www[^\s]+|https?:\/\/[^\s]+)/g;
  const parsedTextHtml = children.replace(urlRegexHttps, url => '<a class="text-primary" href="' + url + '">' + url + '</a>');
  return parse(`<span>${parsedTextHtml}</span>`);
}

export const Notes = ({ name, notes, ...props }) => {
  const [objectToRemove, setObjectToRemove] = useState({});
  const formName = 'NotesForm';

  return (
    <Card header='Notatki' onAddClick={() => props.create(formName)}>
      <div>
        {notes.length === 0 ? <p className='py-15'>Brak notatek.</p> : null}
        {notes.map(note => (
          <RowEditable
            isObjectToRemove
            key={note.id}
            className="row data-row mb-3"
            itemId={note.id}
            onEdit={() => props.edit(formName, note)}
            onRemove={() => setObjectToRemove(note)}
          >
            <div className="col-sm-2 text-center">
              <img src={note.avatar} className="img image rounded-circle" style={{ width: '40%' }} alt="" />
              <div className="grey" style={{ fontSize: '80%' }}>
                <span className="d-block mt-2">Notatka</span>
                <span>{note.created_date}</span>
              </div>
            </div>
            <div className="col-sm-10">
              <a
                role="button"
                style={{ color: 'black' }}
                className="collapsed"
                data-toggle="collapse"
                href={`#notatka${note.id}`}
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <i className="fa fa-sort-down mr-2" />
                <span className="text-themecolor">{note.creator}</span>
                {' '}
                dodał notatkę dla
                {' '}
                <span className="text-themecolor">{name ?? 'brak'}</span>
              </a>

              <div className="collapse show" id={`notatka${note.id}`} style={{ color: 'grey' }}>
                <p className="small">
                  {note.created_date}
                  {' '}
                  |
                  <span style={{ color: 'orange' }}>Temat:</span>
                  {' '}
                  {note.name}
                </p>
                <TextLink>{note.comment}</TextLink>

                <div style={{ border: '1px solid lightgrey' }} className="p-2 small mt-2 mb-2">
                  <span className="d-block">
                    Dodał:
                    {' '}
                    <span className="text-themecolor">{note.creator}</span>
                  </span>
                  <span className="d-block">
                    Data ostatniej modyfikacji:
                    {note.modified_date}
                  </span>
                  <span className="d-block">
                    Data utworzenia:
                    {note.created_date}
                  </span>
                </div>
              </div>
            </div>
          </RowEditable>
        ))}
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>
              Notatki
            </span>
          </div>
        )}
        footer="Aby zapisać notatkę wciśnij ZATWIERDŹ."
        onClickAway={() => props.toggleForm(formName)}
      >
        <div className="pr-5">
          <NoteForm
            visibleOnMount
            clear={props.clear}
            visible={props.formVisible(formName)}
            formName={formName}
            company={props.company}
            withButtons
            submitForm={() => props.saveForm(formName)}
            onCancelButtonClick={() => props.toggleForm(formName)}
          />
        </div>
      </BaseModal>

      <RemovalModal
        visible={Object.keys(objectToRemove).length > 0}
        toggle={() => setObjectToRemove({})}
        remove={() => {
          props.remove(objectToRemove.id);
          setObjectToRemove({});
        }}
      >
        {objectToRemove && (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-2 text-center">
                <img src={objectToRemove.avatar} className="img image rounded-circle" style={{ width: '40%' }} alt="" />
                <div className="grey" style={{ fontSize: '80%' }}>
                  <span className="d-block mt-2">Notatka</span>
                  <span>{objectToRemove.date}</span>
                </div>
              </div>
              <div className="col-sm-10">
                <a
                  role="button"
                  style={{ color: 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#notatka${objectToRemove.id}`}
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <i className="fa fa-sort-down mr-2" />
                  <span className="text-themecolor">{objectToRemove.creator}</span>
                  {' '}
                  dodał notatkę dla
                  {' '}
                  <span className="text-themecolor">{name ?? 'brak'}</span>
                </a>

                <div className="collapse show" id={`notatka${objectToRemove.id}`} style={{ color: 'grey' }}>
                  <p className="small">
                    {objectToRemove.date}
                    {' '}
                    | Temat:
                    {objectToRemove.name}
                  </p>
                  <TextLink>{objectToRemove.comment}</TextLink>
                </div>
              </div>
            </div>
          </div>
        )}
      </RemovalModal>
    </Card>
  );
};
