import Button from 'components/Forms/Button';
import Icon from 'components/Forms/Icon';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useState } from 'react';

export const RemoveModalContent = ({ message, onCancel, onRemove }) => (
  <div>
    {message && <p className='text-red-0 font-bold text-center mt-10 mb-30'>{message}</p>}
    <div className="flex items-center justify-center">
      <Button onClick={onCancel}>Anuluj</Button>
      <Button mode='primary' onClick={onRemove}>Usuń</Button>
    </div>
  </div>
);

export default function ModalOnRemove({ isBlocked, onBlocked, onRemove, message, ...props }) {
  const [isRemoveAcceptModal, setRemoveAcceptModal] = useState(false);

  const handleShowModal = () => {
    isBlocked ? onBlocked() : setRemoveAcceptModal(true);
  };

  const handleHideModal = () => {
    isBlocked ? onBlocked() : setRemoveAcceptModal(false);
  };

  const handleRemove = () => {
    if(isBlocked) {
      onBlocked();
    } else {
      setRemoveAcceptModal(false);
      onRemove();
    }
  };

  return (
    <>
      <Icon mode='trash' onClick={handleShowModal} />

      <BaseModal
        visible={isRemoveAcceptModal}
        headerText="Potwierdzenie usunięcia"
        onClickAway={handleHideModal}
        {...props}
      >
        <RemoveModalContent
          message={message}
          onCancel={handleHideModal}
          onRemove={handleRemove}
        />
      </BaseModal>
    </>
  );
}
