import React from 'react';

import {
  Datepicker,
  Form, Input, Select } from '../../../../components/Forms';
import { ColName } from 'components/Forms/Col';

const mapper = (data) => (data ? data.map((item) => ({ ...item, value: item.id })) : []);

export default (props) => (
  <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
    {props.visible ? (
      <>
        <div className="row mt-3">
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <ColName className='col-sm-4 text-right'>Rodzaj zadłużenia</ColName>
              <div className="col-sm-6">
                <Select formName={props.formName} options={mapper(props.proposalData.type_of_depts_data)} name="type_of_dept_id" number />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <ColName className='col-sm-4 text-right'>Wierzyciel</ColName>
              <div className="col-sm-6">
                <Input formName={props.formName} type="text" name="creditor" />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <ColName className='col-sm-4 text-right'>Aktualne zadłużenie</ColName>
              <div className="col-sm-6">
                <Input formName={props.formName} type="text" name="current_debt" />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <ColName className='col-sm-4 text-right'>Data ostatecznej spłaty</ColName>
              <div className="col-sm-6">
                <Datepicker formName={props.formName} name="expiration_date" />
              </div>
            </div>
          </div>
        </div>

        {props.withButtons ? (
          <div className="row">
            <div className="mt-4 col-sm-12 text-center">
              <button
                type="button"
                onClick={props.onCancelButtonClick}
                className="btn btn-primary-outline mr-2 font-weight-light"
                style={{ color: '#4B4E55' }}
              >
                Anuluj
              </button>
              <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                Zapisz
              </button>
            </div>
          </div>
        ) : null}
      </>
    ) : null}
  </Form>
);
