import { useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function useModals() {
  const history = useHistory();
  const [instance, setInstance] = useState(null);

  const handleOpenModal = modal => () => {
    setInstance(modal);
  };

  const handleCloseModal = () => {
    history?.location?.hash && history.replace(history.location.pathname);
    setInstance(null);
  };

  return {
    instance,
    handleOpenModal,
    handleCloseModal
  };
}
