import FormActions from 'actions/forms';
import { ErrorMessageR } from 'components/ErrorMessageTemp';
import Button from 'components/Forms/Button';
import { Modal } from 'components/Layout/Modal';
import { Pagination } from 'components/Layout/Pagination';
import Loader from 'components/Loader';
import useData from 'hooks/useData';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function Selectable({ title, columns, initialData, names, name, filter, formName, errorName, onChange, onClose, apiFunction, textChooseButton, children, ...props }) {
  const { forms } = useSelector(state => state.Forms);
  const { sendRequest, data, loading } = useData({ data: initialData });
  const dispatch = useDispatch();

  const [previewOption, setPreviewOption] = useState(null);
  const [isOpen, setOpen] = useState(false);

  const selectedOption = get(forms[formName], name, null);
  const filterValue = get(forms[formName], filter, null);

  useEffect(() => {
    if(data) {
      const option = data?.results[0];
      setPreviewOption(option);
    }
    // eslint-disable-next-line
  }, [data]);

  const handleModalOpen = () => {
    sendRequest(apiFunction({ role_name: filterValue }));
    setOpen(true);
  };

  const handleModalClose = () => {
    onClose && onClose();
    setOpen(false);
  };

  const handlePreviewOption = item => () => {
    setPreviewOption(item);
  };

  const handleSubmit = () => {
    handleModalClose();

    dispatch(FormActions.clearErrors(formName));
    dispatch(FormActions.storeInput(formName, name, previewOption));

    onChange && onChange(previewOption);
  };

  const handleChangePage = (page, limit) => {
    sendRequest(apiFunction({ page, limit, role_name: filterValue }));
  };

  const handleOrderTable = key => () => {
    // sendRequest(apiFunction({ limit, order_by: names[key] }));
  };

  return (
    <div {...props}>
      <Modal
        header={title}
        isOpen={isOpen}
        onClose={handleModalClose}
      >
        {children && children}
        {loading ? <div className='py-100'><Loader/></div> : data && (
          <table className="selectable">
            <thead>
              <tr>
                {columns.map((column, key) => (
                  <th key={column} onClick={handleOrderTable(key)}>
                    <span className="grey font-weight-light pointer">{column}</span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.results?.map(result =>
                <tr key={result.id} onClick={handlePreviewOption(result)}>
                  {names.map(name => (
                    <td key={name} className={result === previewOption ? 'companyRowSelected' : ''}>
                      <span className="font-weight-light">
                        {typeof result[name] === 'boolean' ? (result[name] ? 'TAK' : 'NIE') : result[name]}
                      </span>
                    </td>
                  ))}
                </tr>
              )}
            </tbody>
          </table>
        )}

        {data && (
          <Pagination
            count={data.count}
            currentPage={data.current_page}
            onChangePage={handleChangePage}
          />
        )}

        <div className='flex justify-center mt-10'>
          <Button onClick={handleModalClose}>Anuluj</Button>
          <Button mode='primary' onClick={handleSubmit}>Zatwierdź</Button>
        </div>
      </Modal>

      <div className="flex items-center">
        {!props.isEditState && (
          <button type="button" className="float-right" onClick={handleModalOpen}>
            {textChooseButton}
          </button>
        )}
        <span className="ml-3" style={{ fontWeight: '600', color: '#4B4E55', fontSize: '14px' }}>
          {selectedOption?.name ?? selectedOption?.full_name ?? 'nie wybrano'}
        </span>
      </div>
      <ErrorMessageR formName={formName} name={errorName ?? name} />
    </div>
  );
}

Selectable.propTypes = {
  title: PropTypes.string.isRequired
};

Selectable.defaultProps = {
  textChooseButton: 'Wybierz'
};
