export const ENUM_DOKUMENTY_ZABEZPIECZEN = {
  CESJA_INNA: 'cesja-inna',
  CESJA_PRAW_Z_POLISY_F: 'cesja-praw-z-polisy-f',
  CESJA_WIERZYTELNOSCI_F: 'cesja-wierzytelnosci-f',
  DEKLARACJA_WEKSLOWA: 'deklaracja-wekslowa',
  GWARANCJA_ZAPLATY: 'gwarancja-zaplaty',
  PODDANIE_SIE_EGZEKUCJI: 'poddanie-sie-egzekucji-kpc777',
  PORECZENIE_WEKSLOWE: 'poreczenie-wekslowe',
  PORECZENIE_WG_PRAWA_CYWILNEGO: 'poreczenie-wg-prawa-cywilnego',
  POTWIERDZENIE_CESJI: 'potwierdzenie-cesji',
  WEKSEL_IN_BLANCO_F: 'weksel-in-blanco-f'
};
