import PersonActions from 'actions/person';
import { ROLES_CREATOR_FORM } from 'containers/Persons/Creator/Components/Roles';
import { OptionsActions } from 'ducks/options';
import { toast } from 'react-toastify';
import HttpClient from 'utilities/http';

import CompanyService from '../services/company';
import CommonActions from './common';
import FormActions from './forms';

const preview = (Company) => (dispatch) => {
  dispatch({ type: 'COMPANY_SELECTED_FOR_PREVIEW', payload: Company });
  dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
};

const fetch = (filters, shallowFetch = false) => (dispatch) => {
  dispatch(OptionsActions.toggleLoader(true));

  const f = filters || CommonActions.defaultFilters;
  CompanyService.fetch(f).then((response) => {
    dispatch({ type: 'COMPANIES_FETCHED', payload: response.data });

    if (response.data.results.length > 0 && !shallowFetch) {
      dispatch(preview(response.data.results[0]));
    }

    dispatch({ type: 'CONTENT_LOADED' });
    dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
    dispatch(OptionsActions.toggleLoader(false));
  });
};

const createCompany = (form, stayOnList = false, push) => async (dispatch, getState) => {
  let formData = getState().Forms.forms[form];
  formData = { ...formData, business_company_ids: [formData.business_company_ids] };

  dispatch(FormActions.clearErrors(form));

  try {
    const { data } = await CompanyService.create(formData);

    dispatch(FormActions.clearForm(form));
    dispatch(FormActions.toggleForm(form));

    if (stayOnList) {
      toast.success('Obiekt został utworzony.');
      dispatch(fetch(null));
    } else {
      dispatch({ type: 'COMPANY_DETAILS_FETCHED', payload: data });
      push(`/firmy/${data.slug}/kreator`);
    }
  } catch (e) {
    dispatch(FormActions.handleErrors(form, e?.response?.data));
  }
};

const fetchCompanyDetails = (CompanyId, loader = true) => (dispatch) => {
  dispatch(OptionsActions.toggleLoader(loader));

  if (loader) {
    dispatch({ type: 'CONTENT_LOADING' });
    dispatch({ type: 'CONTENT_PREVIEW_LOADING' });
  }

  CompanyService.fetchCompanyDetails(CompanyId).then((response) => {
    dispatch({ type: loader ? 'COMPANY_DETAILS_FETCHED' : 'COMPANY_DETAILS_UPDATED', payload: response.data });

    if (loader) {
      dispatch({ type: 'CONTENT_LOADED' });
      dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
      dispatch(OptionsActions.toggleLoader(false));
    } else {
      toast.success('Zaktualizowano dane.');
    }
  });
};

const saveBusinessRoles = (form, data, companyId, hide) => (dispatch) => {
  data = { business_company_ids: data };

  CompanyService.saveForm(form, data, companyId)
    .then(() => {
      dispatch(fetchCompanyDetails(companyId, false));
      dispatch(FormActions.clearForm(form));

      if (hide) {
        dispatch(FormActions.toggleForm(form));
      }
    }).catch((errors) => dispatch(FormActions.handleErrors(form, errors?.response?.data)));
};

const updateBusinessRoles = (form, hide = false) => (dispatch, getState) => {
  const data = getState().Forms.forms[form];
  const { company } = getState().Company;

  let businessRoles = company.business_company ? company.business_company.map((i) => i.id) : [];
  businessRoles.push(data.role);
  businessRoles = [...new Set(businessRoles)].map((i) => Number(i));

  dispatch(saveBusinessRoles(form, businessRoles, company.id, hide));
};

const removeBusinessRole = (roleId, company) => (dispatch) => {
  let businessRoles = company.business_company ? company.business_company.map((i) => i.id) : [];
  businessRoles = businessRoles.filter((r) => r !== roleId);

  dispatch(saveBusinessRoles('RoleForm', businessRoles, company.id, false));
};

const saveForm = (form, companyId = null, hide = false) => (dispatch, getState) => {
  const data = getState().Forms.forms[form];

  Object.keys(data).forEach((key) => {
    if (data[key] === null) {
      delete data[key];
    }
  });

  CompanyService.saveForm(form, data, companyId)
    .then(() => {
      dispatch(fetchCompanyDetails(companyId || data.id, false));

      document.getElementsByTagName('html')[0].style = '';

      if (form !== 'MainCreatorForm' && form !== 'ScoringCreatorForm') {
        dispatch(FormActions.clearForm(form));
      }

      if (hide) {
        dispatch(FormActions.toggleForm(form));
      }
    })
    .catch((errors) => dispatch(FormActions.handleErrors(form, errors?.response?.data)));
};

const removeCompany = (id) => (dispatch) => CompanyService.removeCompany(id)
  .then(() => {
    dispatch(fetch([]));
    toast.success('Usunięto obiekt.');
  }).catch((e) => e);

const removeObject = (objectId, companyId, object) => (dispatch) => CompanyService
  .removeObject(objectId, companyId, object).then(() => {
    dispatch(CommonActions.toggleModal());
    dispatch(fetchCompanyDetails(companyId, false));

    document.getElementsByTagName('html')[0].style = '';
  });

const fetchCompanyData = () => (dispatch, getState) => {
  if (Object.keys(getState().Company.additionalData).length === 0) {
    CompanyService.fetchCompanyData().then((data) => dispatch({
      type: 'COMPANY_ADDITIONAL_DATA_FETCHED', payload: data
    }));
  }
};

const toggleStatus = (companyId, objectId, objectType) => (dispatch) => CompanyService
  .toggleStatus(companyId, objectId, objectType).then(() => dispatch(
    fetchCompanyDetails(companyId, false)
  ));

const fetchPeopleForCompany = () => (dispatch, getState) => {
  CompanyService
    .fetchPeopleForCompany(getState().Company.company.id)
    .then((response) => dispatch({ type: 'COMPANY_PEOPLE_FETCHED', payload: response.data }));
};

const addPerson = (form, hide = true) => (dispatch, getState) => {
  const { company } = getState().Company;
  const formData = getState().Forms.forms[form];

  const personId = formData?.person?.id;
  const personRoles = formData?.role ? [Number(formData?.role)] : null;

  CompanyService.addPerson(personId, personRoles, company.id)
    .then(() => {
      dispatch(fetchCompanyDetails(company.id, false));

      dispatch(FormActions.clearForm(form));

      if (hide) {
        dispatch(FormActions.toggleForm(form));
      }
    })
    .catch((errors) => {
      dispatch(FormActions.handleErrors(form, errors?.response?.data));
    });
};

const detachPerson = (personId) => (dispatch, getState) => {
  const { company } = getState().Company;

  CompanyService.detachPerson(personId, company.id).then(() => {
    dispatch(fetchCompanyDetails(company.id, false));
  });
};

const saveCommission = (formName, hide = true) => (dispatch, getState) => {
  const form = getState().Forms.forms[formName];
  const { company } = getState().Company;
  const parent = company.commission_parents?.find(
    (p) => p.category.id === Number(form.category)
  ) ?? null;

  const handleResponse = () => {
    dispatch(fetchCompanyDetails(company.id, false));
    dispatch(FormActions.clearForm(formName));

    if (hide) {
      dispatch(FormActions.toggleForm(formName));
    }
  };

  if (parent || form.id) {
    if (parent.id) {
      form.commission_parent_id = parent.id;
    }
    CompanyService.saveCommission(company.id, form)
      .then(handleResponse)
      .catch((e) => dispatch(FormActions.handleErrors(formName, e?.response?.data)));
  } else {
    CompanyService.saveParentCommission(company.id, form)
      .then((r) => {
        form.commission_parent_id = r.data.id;

        return CompanyService.saveCommission(company.id, form)
          .then(handleResponse)
          .catch((e) => dispatch(FormActions.handleErrors(formName, e?.response?.data)));
      })
      .catch((e) => dispatch(FormActions.handleErrors(formName, e?.response?.data)));
  }
};

//  refactored endpoints
const addBusinessRoleToCompany = (company_id, person_id, role_id) => (dispatch) => {
  HttpClient.post(`companies/${company_id}/person/`, {
    person_id: person_id,
    roles_ids: [role_id]
  }).then(() => {
    dispatch(PersonActions.fetchPersonDetails(person_id, false));
  }).catch((e) => dispatch(FormActions.handleErrors(ROLES_CREATOR_FORM, e?.response?.data)));
};

export default {
  fetch,
  preview,
  fetchCompanyDetails,
  saveForm,
  removeCompany,
  removeObject,
  removeBusinessRole,
  fetchCompanyData,
  toggleStatus,
  createCompany,
  updateBusinessRoles,
  fetchPeopleForCompany,
  addPerson,
  detachPerson,
  saveCommission,
  //  refactored endpoints
  addBusinessRoleToCompany
};
