import React from 'react';

import BooleanCheck from '../../../components/BooleanCheck';
import { Form, Input, Selectable, Textarea } from '../../../components/Forms';
import { parseBan, parseMoney, formatNip } from '../../../utilities/helpers';
import Tooltip from 'components/Layout/Tooltip';

const columns = [
  { name: 'Numer rachunku', getter: 'number', sortable: 1 },
  { name: 'Waluta', getter: 'currency.name', sortable: 1 },
  { name: 'Typ rachunku', getter: 'bank_account_type.name', sortable: 1 },
  { name: 'Nazwa banku', getter: 'bank_name', sortable: 1 },
  { name: 'Data dodania', getter: 'created_date', sortable: 1 },
  { name: 'Dodał', getter: 'user_creator.full_name', sortable: 1 }
];

const getActorInfo = actor => (
  <div className="col-sm-4 br">
    <div className="row">
      <div className="col-sm-12">{actor?.name || '-'}</div>
    </div>
    <div className="row">
      <div className="col-sm-12">
        {formatNip(actor?.nip)} {actor?.nip ? '(NIP)' : ''}
      </div>
    </div>
    <div className="row">
      <div className="col-sm-12">
        {actor?.active_address?.street || ''} {actor?.active_address?.building_no || ''}
        {actor?.active_address?.local_no ? `/${actor?.active_address?.local_no}` : ''}
        {actor?.active_address?.zipcode ? `, ${actor?.active_address?.zipcode}` : ''} {actor?.active_address?.city || ''}
      </div>
    </div>
  </div>
);

const TransferForm = props => {
  const f = props.forms[props.formName] ?? {};

  const [type, setType] = React.useState(f.order_type || 0);
  const [system, setSystem] = React.useState(f.system_billing || 0);

  const orderTypes =
    Object.keys(props.common?.paymentData?.order_types).map(k => ({ id: Number(k), name: props.common?.paymentData?.order_types?.[k] })) ?? [];
  const billingSystems =
    Object.keys(props.common?.paymentData?.billing_systems).map(k => ({ id: Number(k), name: props.common?.paymentData?.billing_systems?.[k] })) ??
    [];

  const filteredSystems = billingSystems.filter(s => {
    switch (f.order_type) {
      case 0:
        return f.mpp ? s.name !== 'Swift' && s.name !== 'Brak' : s.name !== 'Brak';
      default:
        break;
    }

    return true;
  });

  const senderAccsFromAgreement = f?.agreement?.agreement_versions?.find(y => y.status === 1)?.factor_bank_accounts;
  const senderAccs = senderAccsFromAgreement ? [...senderAccsFromAgreement[0], ...senderAccsFromAgreement[1]] : [];
  const recipientAccs = f.agreement?.agreement_versions?.find(y => y.status === 1)?.client_bank_accounts ?? [];

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      <div className="row">
        <div className="col-sm-4">
          <span className="grey">Nr umowy</span>
          <span style={{ float: 'right' }}><Tooltip label={f?.agreement?.identifier} /></span>
        </div>
        <div className="col-sm-4">
          <span className="grey">Nr przelewu</span>
          <span style={{ float: 'right' }}>{f.identifier || f.idc_identifier}</span>
        </div>
        <div className="col-sm-4">
          <span className="grey">ID banku</span>
          <span style={{ float: 'right' }}>{f.bank || '-'}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <span className="grey">Nr wierzytelności</span>
          <span style={{ float: 'right' }}><Tooltip label={f.claim?.identifier} /></span>
        </div>
        <div className="col-sm-4">
          <span className="grey">Data utworzenia</span>
          <span style={{ float: 'right' }}>{f.created_date}</span>
        </div>
        <div className="col-sm-4">
          <span className="grey">Data księgowania</span>
          <span style={{ float: 'right' }}>{f.posting_date}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <span className="grey">Podstawa wydatku</span>
          <span style={{ float: 'right' }}>{f.commitment?.title || f.txt_identifier || f.payment_title || '-'}</span>
        </div>
        <div className="col-sm-4">
          <span className="grey">Status przelewu</span>
          <span style={{ float: 'right' }}>{props.common?.paymentData?.payment_statuses?.[f.status]}</span>
        </div>
        <div className="col-sm-4">
          <span className="grey">Status w banku</span>
          <span style={{ float: 'right' }}></span>
        </div>
      </div>

      <hr />
      <div className="row align-items-center mt-4 mb-4">
        <label htmlFor="example-text-input" className="ml-3 mr-2 label-right col-form-label">
          Typ zlecenia
        </label>
        {orderTypes
          .filter(t => t.name !== 'brak' && t.name !== 'Brak')
          .map(t => (
            <div className="ml-2">
              <label className="custom-control custom-radio d-inline-flex align-items-center">
                <input
                  disabled={props.details}
                  id={`order-${t.id}`}
                  name={`order-${t.id}`}
                  type="radio"
                  className="custom-control-input mr-3"
                  onChange={() => {
                    setType(t.id);
                    props.storeInput(props.formName, 'order_type', t.id);
                  }}
                  checked={type === t.id}
                />
                <span className="custom-control-label radio-label">{t.name}</span>
              </label>
            </div>
          ))}
        <label htmlFor="example-text-input" className="ml-5 label-right col-form-label">
          Kurs rozliczeniowy
        </label>
        <div className="ml-2 col-sm-1">
          <Input disabled={props.details} type="text" name="test" formName={props.formName} />
        </div>
        <div className="ml-3">
          <button type="button" disabled={props.details}>
            NBP
          </button>
          <button type="button" className="ml-2" disabled={props.details}>
            mBank
          </button>
        </div>
        <div className="ml-5 d-flex align-items-center">
          <label htmlFor="example-text-input" className="mr-3 label-right col-form-label">
            MPP
          </label>
          <BooleanCheck field={f?.claim?.mpp} />
        </div>
      </div>

      <hr />

      <div className="row bb"  style={{ fontSize: '90%' }}>
        <div className="col-sm-1 p-2 grey br" />
        <div className="col-sm-2 p-2 grey br">Kontrahent</div>
        <div className="col-sm-3 p-2 grey br">Numer rachunku</div>
        <div className="col-sm-1 p-2 grey br">Waluta</div>
        <div className="col-sm-1 p-2 grey br">Typ rachunku</div>
        <div className="col-sm-4 p-2 grey">Nazwa banku</div>
      </div>
      <div className="row bb"  style={{ fontSize: '90%' }}>
        <div className="col-sm-1 p-2 grey br">Nadawca</div>
        <div className="col-sm-2 p-2 br">
          <strong>{f.sender?.name}</strong>
        </div>
        <div className="col-sm-3 p-2 br">
          {parseBan(f.sender_bank_account)}
          {props.details ? null : (
            <button onClick={() => props.toggleForm('sender_bank_account')} type="button" style={{ float: 'right' }}>
              ...
            </button>
          )}
        </div>
        <div className="col-sm-1 p-2 br">{f.sender_bank_account?.currency?.name}</div>
        <div className="col-sm-1 p-2 br">{f.sender_bank_account?.bank_account_type?.name}</div>
        <div className="col-sm-4 p-2">{f.sender_bank_account?.bank_name}</div>
      </div>
      <div className="row bb"  style={{ fontSize: '90%' }}>
        <div className="col-sm-1 p-2 grey br">Odbiorca</div>
        <div className="col-sm-2 p-2 br">
          <strong>{f.recipient?.name}</strong>
        </div>
        <div className="col-sm-3 p-2 br">
          {f.recipient_bank_account ? parseBan(f.recipient_bank_account) : f.recipient_bank_account_number}
          {props.details ? null : (
            <button onClick={() => props.toggleForm('recipient_bank_account')} type="button" style={{ float: 'right' }}>
              ...
            </button>
          )}
        </div>
        <div className="col-sm-1 p-2 br">{f.recipient_bank_account?.currency?.name}</div>
        <div className="col-sm-1 p-2 br">{f.recipient_bank_account?.bank_account_type?.name}</div>
        <div className="col-sm-4 p-2">{f.recipient_bank_account?.bank_name}</div>
      </div>

      <div className="row align-items-center mt-4">
        <div className="col-sm-4 grey br">Klient</div>
        <div className="col-sm-4 grey br">Faktor</div>
        <div className="col-sm-4 grey br">Administrator operacyjny</div>
      </div>

      <div className="row align-items-center mb-4">
        {getActorInfo(f.agreement?.client)}
        {getActorInfo(f.agreement?.factor)}
        {getActorInfo(f.agreement?.operational_administrator)}
        {/* {props.agreement?.operational_administrator ? getActorInfo(props.agreement?.operational_administrator, props.version?.oa_representation) */}
        {/*    : null} */}
      </div>

      <hr />

      <div className="row mt-2">
        <div className="col-sm-4">
          <div className="row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 grey col-form-label label-right">
              Kwota brutto
            </label>
            <div className="col-sm-6 d-inline-flex align-items-center">
              {props.details ? (
                <span className="font-weight-bold">{parseMoney(f.gross_transfer_value)}</span>
              ) : (
                <Input disabled={props.details} formName={props.formName} type="text" name="gross_transfer_value" />
              )}
              <span className="ml-1">{f.gross_transfer_value_currency?.name}</span>
            </div>
          </div>
          <div className="row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 grey col-form-label label-right">
              Nr Faktury VAT
            </label>
            <div className="col-sm-6">{f.invoice_number || '-'}</div>
          </div>
          <div className="row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 grey col-form-label label-right">
              Data realizacji
            </label>
            <div className="col-sm-6">{f.invoice_date}</div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <label htmlFor="example-text-input " className="col-sm-6 grey col-form-label label-right">
              W tym VAT
            </label>
            <div className="col-sm-6 d-inline-flex align-items-center">
              {props.details ? (
                <span className="font-weight-bold">{parseMoney(f.vat_transfer_value)}</span>
              ) : (
                <Input formName={props.formName} type="text" name="vat_transfer_value" />
              )}
              <span className="ml-1">{f.gross_transfer_value_currency?.name}</span>
            </div>
          </div>
          <div className="row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 grey col-form-label label-right">
              Opis faktury
            </label>
            <div className="col-sm-6">-</div>
          </div>
          <div className="row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 grey col-form-label label-right">
              Referencje
            </label>
            <div className="col-sm-6">-</div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-12">
              <Textarea disabled={props.details} name="comments" formName={props.formName} placeholder="Wpisz komentarz.." />
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-sm-8">
          <div className="row align-items-center mt-2 mb-2">
            <label htmlFor="example-text-input" className="col-sm-3 label-right col-form-label">
              System rozliczeniowy
            </label>
            {f.order_type === 0 ? (
              filteredSystems.map(t => (
                <div className="mr-2">
                  <label className="custom-control custom-radio d-inline-flex align-items-center">
                    <input
                      disabled={props.details}
                      id={`billing-${t.id}`}
                      name={`billing-${t.id}`}
                      type="radio"
                      className="custom-control-input mr-3"
                      onChange={() => {
                        setSystem(t.id);
                        props.storeInput(props.formName, 'system_billing', t.id);
                      }}
                      checked={system === t.id}
                    />
                    <span className="custom-control-label radio-label">{t.name}</span>
                  </label>
                </div>
              ))
            ) : (
              <div>
                <span>Brak możliwości wyboru dla przelewów zagranicznych.</span>
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row  align-items-center">
            <div className="col-sm-12  mb-1">
              <span className="grey font-weight-bold">Autoryzował</span>
              <span className="ml-3" style={{ float: 'right' }}>
                {f.user_authorized?.name ?? '-'}
              </span>
            </div>
          </div>
          <div className="row  align-items-center">
            <div className="col-sm-12 mb-1">
              <span className="grey font-weight-bold">Wysłał do banku</span>
              <span className="ml-3" style={{ float: 'right' }}>
                {f.user_sent_to_bank?.name ?? '-'}
              </span>
            </div>
          </div>
          <div className="row  align-items-center">
            <div className="col-sm-12  mb-1">
              <span className="grey font-weight-bold">Rozliczył import</span>
              <span className="ml-3" style={{ float: 'right' }}>
                {f.user_settled_import?.name ?? '-'}
              </span>
            </div>
          </div>
        </div>
      </div>

      <hr />

      {/* {props.withButtons && !props.details ? ( */}
      {props.withButtons ? (
        <div className="row mt-5">
          <div className="col-sm-12 text-center">
            {props.details ? null : (
              <button
                type="button"
                onClick={() => {
                  props.toggleForm(props.formName);
                  props.removeTransfer(f.id);
                }}
                className="btn btn-primary-outline mr-2 font-weight-light"
                style={{ color: '#4B4E55' }}>
                Usuń
              </button>
            )}
            <button
              type="button"
              onClick={() => props.toggleForm(props.formName)}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}>
              Anuluj
            </button>
            {props.onList ? (
              props.details ? null : (
                <button type="button" onClick={() => props.submitForm()} className="btn btn-primary btn-brown ml-2  font-weight-light">
                  Zapisz
                </button>
              )
            ) : (
              <>
                {props.register ? (
                  <button type="button" onClick={() => props.authorizeTransfer()} className="btn btn-primary ml-2  font-weight-light">
                    Autoryzuj
                  </button>
                ) : null}
                {props.details ? null : (
                  <button
                    type="button"
                    onClick={() => {
                      props.storeInput(props.formName, 'shouldUpdate', 1);
                      props.authorizeTransfer();
                    }}
                    className="btn btn-primary ml-2 font-weight-light">
                    Edytuj
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="row mt-5">
          <div className="col-sm-12 text-center">
            <button
              type="button"
              onClick={() => props.toggleForm(props.formName)}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}>
              Anuluj
            </button>
          </div>
        </div>
      )}

      <Selectable
        fetch={() => {}}
        // fetch={props.fetch}
        visible={props.formVisible('sender_bank_account')}
        formName={props.formName}
        field="sender_bank_account"
        columns={columns}
        options={senderAccs}
        onClickAway={() => props.toggleForm('sender_bank_account')}
        header="Wybierz rachunek zleceniodawcy"
        footer="Aby zapisać wciśnij ZATWIERDŹ"
      />
      <Selectable
        fetch={() => {}}
        // fetch={props.fetch}
        visible={props.formVisible('recipient_bank_account')}
        formName={props.formName}
        field="recipient_bank_account"
        columns={columns}
        options={recipientAccs}
        onClickAway={() => props.toggleForm('recipient_bank_account')}
        header="Wybierz rachunek odbiorcy"
        footer="Aby zapisać wciśnij ZATWIERDŹ"
      />
    </Form>
  );
};

export default TransferForm;
