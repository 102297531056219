import React from 'react';
import { connect } from 'react-redux';

import LoginForm from './LoginForm';
import WithLayout from './WithLayout';

const WithAuthorization = (WrappedComponent) => {
  const WithAuth = (props) => {
    const { user } = props.authorization;
    if (user) {
      const Component = WithLayout(WrappedComponent);
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Component {...props} />;
    }
    return <LoginForm />;
  };

  const stateToProps = (state) => ({
    authorization: state.Authorization
  });

  return connect(
    stateToProps,
    null
  )(WithAuth);
};

export default WithAuthorization;
