import { t } from 'lang/pl';
import React from 'react';
import { toast } from 'react-toastify';

export default function IconSync({ isBlocked, onBlockedMessage, onClick }) {
  const handleOnClick = event => {
    isBlocked ? onBlockedMessage() : onClick(event);
  };

  return (
    <i className="fa fa-sync ml-2" onClick={handleOnClick} />
  );
}

IconSync.defaultProps = {
  onBlockedMessage: () => toast.error(t.error.toast.statusWarning)
};
