import { BaseModal } from 'components/Layout/OldModal';
import { isEditableProposal } from 'containers/Proposals/Details/Index';
import { t } from 'lang/pl';
import React from 'react';
import { toast } from 'react-toastify';

import { RowEditable } from '../../../../../components/Layout/RowEditable';
import { parseMoney } from '../../../../../utilities/helpers';
import LiabilitiesForm from '../../../Forms/Liabilities/Credit';

export default (props) => {
  const modalFormName = 'CreditLiabilitiesForm';
  const rows = props.version && props.version.credit_obligations ? props.version.credit_obligations : [];

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12 p-2 text-themecolor">
              Zobowiązania kredytowe

              <span className="float-right" style={{ cursor: 'pointer' }}>
                <i className="fa fa-plus ml-2" onClick={() => props.toggleForm(modalFormName)} />
              </span>
            </div>
          </div>
          <div className="row bt bb">
            <div className="col-sm-3 p-2 br grey">Nazwa banku</div>
            <div className="col-sm-3 p-2 br grey">Rodzaj kredytu</div>
            <div className="col-sm-2 p-2 br grey">Aktualne zadłużenie</div>
            <div className="col-sm-2 p-2 br grey">Data spłaty</div>
            <div className="col-sm-2 p-2 grey">Zabezpieczenie</div>
          </div>
          {rows.length ? rows.map((row) => (
            <RowEditable
              isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
              onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
              key={row.identifier}
              onEdit={() => {
                const editableRow = {
                  bank_id: row.bank ? row.bank.id : null,
                  type_of_loan_id: row.type_of_loan ? row.type_of_loan.id : null,
                  id: row.id,
                  current_debt: row.current_debt,
                  collateral_id: row.collateral.id,
                  data_charges: row.data_charges
                };
                delete editableRow.type_of_debt;
                props.edit(editableRow, modalFormName);
              }}
              onRemove={() => {
                props.remove('credit_obligations', row.id);
              }}
            >
              <div className="col-sm-3 p-2 br ">{row.bank ? row.bank.name : '-'}</div>
              <div className="col-sm-3 p-2 br ">{row.type_of_loan ? row.type_of_loan.name : '-'}</div>
              <div className="col-sm-2 p-2 br ">{parseMoney(row.current_debt)}</div>
              <div className="col-sm-2 p-2 br ">{row.data_charges}</div>
              <div className="col-sm-2 p-2 ">{row.collateral ? row.collateral.name : '-'}</div>
            </RowEditable>
          )) : <div className="row bb"><div className="col p-2"><span>Brak danych.</span></div></div>}
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Zobowiązania kredytowe</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}
      >
        <LiabilitiesForm
          withButtons
          populate={() => props.populateForm(modalFormName)}
          forms={props.forms}
          proposalData={props.proposalData}
          banks={props.banks}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(modalFormName)}
          formName={modalFormName}
          submitForm={() => props.saveForm(modalFormName)}
          onCancelButtonClick={() => props.toggleForm(modalFormName)}
        />
      </BaseModal>
    </>
  );
};
