import AgreementActions from 'actions/agreement';
import Form from 'components/Forms/Form';
import Card from 'components/Layout/Card';
import { getAgreementDebtor } from 'containers/Agreements/Creator/Index';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import BooleanCheck from '../../../../components/BooleanCheck';
import { formatValueToEurope, formatNip } from '../../../../utilities/helpers';

export const DebtorsCreatorForm = 'DebtorsCreatorForm';

export default props => {
  const version = props.version || {};
  const f = props.forms?.forms?.[DebtorsCreatorForm] ?? {};

  useEffect(() => {
    if (version.proposal_debtors) {
      for (const debtor of version?.proposal_debtors) {
        let k = version?.proposal_debtors.indexOf(debtor);
        props.storeInput(DebtorsCreatorForm, `debtors.${k}.assignment_consent`, debtor.assignment_consent);
        props.storeInput(DebtorsCreatorForm, `debtors.${k}.assignment_consent_initial`, debtor.assignment_consent);
        props.storeInput(DebtorsCreatorForm, `debtors.${k}.generate_assignment`, true);
        props.storeInput(DebtorsCreatorForm, `debtors.${k}.proposal_debtor_id`, debtor.id);
        props.storeInput(DebtorsCreatorForm, `debtors.${k}.debtor`, debtor);


        if(debtor.mpp) {
          props.storeInput(DebtorsCreatorForm, `debtors.${k}.purchase_method`, 1);
        } else {
          props.storeInput(DebtorsCreatorForm, `debtors.${k}.purchase_method`, 0);
        }
      }
    }
    // eslint-disable-next-line
  }, [version.proposal_debtors]);

  const handleSave = () => {
    const validator = f.debtors
      .filter(x => x.debtor)
      .map(x => typeof x.purchase_method !== 'undefined')
      .indexOf(false);

    if (validator === -1) {
      props.saveDebtors(DebtorsCreatorForm, false);
    }
  };

  return (
    <Card mode='creator' header='+ Dłużnicy we wniosku' onSaveClick={handleSave}>
      <div>
        <div className="row bb">
          <div className="flex-30 p-2 grey br">Nazwa firmy</div>
          <div className="flex-20 max-w-150 p-2 grey br">NIP</div>
          <div className="flex-10 p-2 grey br">Wnioskowany limit</div>
          <div className="flex-10 p-2 grey br">Kategoria</div>
          <div className="flex-10 max-w-80 p-2 grey br">Zgoda na cesję</div>
          <div className="flex-12 p-2 grey br">Czy generować potwierdzenie cesji?</div>
          <div className="flex-30 max-w-220 p-2 grey text-center">Sposób wykupu</div>
        </div>
        <Form asDiv formName={DebtorsCreatorForm}>
          {version.proposal_debtors?.map((i, k) => {
            // const agreementDebtor = getAgreementDebtor(i?.debtor?.name, agreement, version);
            return (
              <div className="row bb">
                <div className="flex-30 p-2 br d-flex align-items-center">
                  <BooleanCheck
                    field={!!f?.debtors?.[k]?.debtor}
                    onClick={() => {
                      props.storeInput(DebtorsCreatorForm, `debtors.${k}.proposal_debtor_id`, i.id);
                      props.storeInput(DebtorsCreatorForm, `debtors.${k}.debtor`, !f?.debtors?.[k]?.debtor);
                    }}
                  />
                  <span className="ml-2">{i.debtor?.name}</span>
                </div>
                <div className="flex-20 p-2 max-w-150 br">{formatNip(i.debtor?.nip)}</div>
                <div className="flex-10 p-2  br">{formatValueToEurope(i.requested_sublimit)}</div>
                <div className="flex-10 p-2  br">{i.category?.name}</div>
                <div className="flex-10 max-w-80 p-2  br">
                  <BooleanCheck
                    field={f?.debtors?.[k]?.assignment_consent}
                    onClick={() => {
                      props.storeInput(DebtorsCreatorForm, `debtors.${k}.assignment_consent`, !f?.debtors?.[k]?.assignment_consent);
                    }}
                  />
                </div>
                <div className="flex-12 p-2  br">
                  <BooleanCheck
                    field={f?.debtors?.[k]?.generate_assignment}
                    onClick={() => {
                      props.storeInput(DebtorsCreatorForm, `debtors.${k}.generate_assignment`, !f?.debtors?.[k]?.generate_assignment);
                    }}
                  />
                </div>

                <div className="flex-30 max-w-220 p-2 text-center">
                  <BooleanCheck
                    className="mr-2"
                    field={f?.debtors?.[k]?.purchase_method === 0}
                    asRadioButton
                    onClick={() => {
                      props.storeInput(DebtorsCreatorForm, `debtors.${k}.purchase_method`, 0);
                    }}
                  />
                  <span className="mr-2">Od brutto</span>
                  <BooleanCheck
                    className="mr-2"
                    field={f?.debtors?.[k]?.purchase_method === 1}
                    asRadioButton
                    onClick={() => {
                      props.storeInput(DebtorsCreatorForm, `debtors.${k}.purchase_method`, 1);
                    }}
                  />
                  <span className="mr-2">Od netto</span>
                </div>
              </div>
            );
          })}
        </Form>
      </div>
    </Card>
  );
};
