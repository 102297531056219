const initialState = {
  list: [],
  links: {},
  count: 0,
  currentPage: 1,
  agreements: {},
  factors: {},
  clients: {},
  accounts: {},
  docs: {}
};

export default function Proposals(state = initialState, action) {
  switch (action.type) {
    case 'INVOICES_FETCHED':
      return {
        ...state,
        list: action.payload.results,
        links: action.payload.links,
        currentPage: action.payload.current_page,
        count: action.payload.count
      };
    case 'INVOICE_AGREEMENTS_FETCHED':
      return { ...state, agreements: action.payload };
    case 'INVOICE_FACTORS_FETCHED':
      return { ...state, factors: action.payload };
    case 'INVOICE_CLIENTS_FETCHED':
      return { ...state, clients: action.payload };
    case 'INVOICE_ACCOUNTS_FETCHED':
      return { ...state, accounts: action.payload };
    case 'INVOICE_DOCS_FETCHED':
      return { ...state, docs: action.payload };
    default:
      return state;
  }
}
