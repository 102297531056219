import FormActions from 'actions/forms';
import Autocomplete from 'components/Forms/Autocomplete';
import { days_after_payment_date } from 'containers/Offers/Forms/AdditionalRates';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  Checkbox,   Form, Input, Selectable
} from '../../../components/Forms';

export const MultilevelRatesForm = (props) => {
  const dispatch = useDispatch();
  const f = props.forms && props.formName
    ? props.forms[props.formName]
    : {
      levels: [],
      margin_based_on_tran_risk:
                        props.forms[props.formName] && typeof props.forms[props.formName].margin_based_on_tran_risk !== 'undefined'
                          ? props.forms[props.formName].margin_based_on_tran_risk
                          : props.margin_based_on_tran_risk
    };
  const noOfLevelsChanged = () => {
    if (!f.no_of_levels || f.no_of_levels === '' || f.no_of_levels < 1) {
      return;
    }
    if (!f.levels || f.levels.length === 0) {
      f.levels = [...Array(Number(f.no_of_levels)).keys()].map((key) => ({
        level_no: key + 1,
        document_to_buy_types: [],
        max_advance_payment: null
      }));
    } else if (f.levels.length < f.no_of_levels) {
      f.levels = f.levels.concat(
        [...Array(Number(f.no_of_levels - f.levels.length)).keys()].map((key) => ({
          level_no: f.levels.length + key + 1,
          document_to_buy_types: [],
          max_advance_payment: null
        }))
      );
    } else if (f.levels.length > f.no_of_levels) {
      f.levels = f.levels.slice(0, f.no_of_levels);
    }
    props.getMultilevelFlatRate(props.formName);
  };
  const columns = [
    { name: 'Nazwa dokumentu', getter: 'name', sortable: 1 }
    // { name: 'ID dokumentu', getter: 'nip' },
    // { name: 'Wzór faktora', getter: 'active_email' },
  ];

  useEffect(() => {
    dispatch(FormActions.storeInput(props.formName, 'margin_based_on_tran_risk', true));
    dispatch(FormActions.storeInput(props.formName, 'min_margin_control', true));
  }, []);

  return (
    <>
      <Form
        initialForm={{ calculationId: props.calculationId }}
        visibleOnMount={props.visibleOnMount}
        className="form-horizontal"
        formName={props.formName}
      >
        {props.visible ? (
          <>
            <div className="row  ">
              <div className="col-sm-12">
                <div className="form-group row  align-items-center">
                  <div className="col-sm-3 col-form-label label-right">Marża wg ryzyka transakcji</div>
                  <div className="col-sm-2">
                    <Checkbox
                      onChange={() => props.getMultilevelFlatRate(props.formName)}
                      className="custom-control custom-checkbox d-flex"
                      label=""
                      formName={props.formName}
                      name="margin_based_on_tran_risk"
                    />
                  </div>
                  <div className="col-sm-2 col-form-label label-right mb-15">Ilość poziomów</div>
                  <div className="col-sm-3">
                    <Input formName={props.formName} type="number" name="no_of_levels" onChange={noOfLevelsChanged} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row  ">
              <div className="col-sm-12">
                <div className="form-group row  align-items-center">
                  <div className="col-sm-3 col-form-label label-right">Kontrola marży minimalnej</div>
                  <div className="col-sm-2">
                    <Checkbox
                      className="custom-control custom-checkbox d-flex"
                      label=""
                      formName={props.formName}
                      name="min_margin_control"
                    />
                  </div>
                  <div className="col-sm-2 col-form-label label-right">Termin płatności</div>
                  <div className="col-sm-3">
                    <Autocomplete
                      freeSolo
                      formName={props.formName}
                      name='max_payment_date'
                      options={days_after_payment_date.map(item => item.name)}
                      onChange={() => props.getMultilevelFlatRate(props.formName)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {f.levels && f.levels.length > 0 ? (
              <>
                <div className="row mt-3">
                  <div className="col-sm-1 font-weight-bold grey">Poziom</div>
                  <div className="col-sm-3 font-weight-bold grey">Max wysokość zaliczki</div>
                  <div className="col-sm-8 font-weight-bold grey">Nazwa dokumentu</div>
                </div>
                {f.levels.map((level, key) => (
                  <div className="row mt-2 align-items-center">
                    <div className="col-sm-1">{level.level_no}</div>
                    <div className="col-sm-3">
                      <Input
                        onChange={() => props.getMultilevelFlatRate(props.formName)}
                        formName={props.formName}
                        type="number"
                        name={`levels.${key}.max_advance_payment`}
                      />
                    </div>
                    <div className="col-sm-8">
                      {level.document_to_buy_types ? level.document_to_buy_types.map((d) => d.name).join(', ') : ''}
                      <button
                        type="button"
                        className="ml-3 mb-15"
                        onClick={() => {
                          props.toggleForm(`levels.${key}.document_to_buy_types`);
                        }}
                      >
                        Wybierz
                      </button>

                      <Selectable
                        multiple
                        filtersForm={props.filtersForm}
                        fetch={props.fetch}
                        visible={props.formVisible(`levels.${key}.document_to_buy_types`)}
                        formName={props.formName}
                        field={`levels.${key}.document_to_buy_types`}
                        columns={columns}
                        options={props.dtbs}
                        onClickAway={() => props.toggleForm(`levels.${key}.document_to_buy_types`)}
                        header="Wybierz rodzaj dokumentu"
                        footer=""
                      />
                    </div>
                  </div>
                ))}
              </>
            ) : null}

            <div className="row mt-5">
              <div className="col-sm-12">
                <div className="form-group row  align-items-center">
                  <div className="col-sm-3 col-form-label label-right mb-15">Stawka wielopoziomowa</div>
                  <div className="col-sm-3">
                    <Input
                      formName={props.formName}
                      type="number"
                      name="multilevel_flat_rate"
                      disabled={f.margin_based_on_tran_risk}
                    />
                  </div>
                </div>
              </div>
            </div>

            {props.withButtons ? (
              <div className="row">
                <div className="mt-4 col-sm-12 text-center">
                  <button
                    type="button"
                    onClick={props.onCancel}
                    className="btn btn-primary-outline mr-2 font-weight-light"
                    style={{ color: '#4B4E55' }}
                  >
                    Anuluj
                  </button>
                  <button type="button" onClick={() => props.saveForm()} className="btn btn-primary ml-2  font-weight-light">
                    Zapisz
                  </button>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </Form>
    </>
  );
};
