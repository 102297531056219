import CompanyActions from 'actions/companies';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import { Attachments } from "./Components/Attachments";
// import { SectionNavigation } from "./Components/SectionNavigation";
import CommonActions from '../../../actions/common';
import FormActions from '../../../actions/forms';
// import Loader from "./../../../components/Loader";
import PersonActions from '../../../actions/person';
// import _ from "lodash";
import { Buttons } from '../../../components/Forms';
import { Container, MainContainer } from '../../../components/Layout';
import Constants from '../../../utilities/constants';
import { Addresses } from './Components/Addresses';
import { Commissions } from './Components/Commissions';
import { Consents } from './Components/Consents';
import { Contacts } from './Components/Contacts';
import { Header } from './Components/Header';
import { Metrics } from './Components/Metrics';
import { Roles } from './Components/Roles';
import { SocialMedia } from './Components/SocialMedia';
import Loader from 'components/Loader';
import { Notes } from 'containers/Companies/Creator/Components/Notes';
import { optionsCountries } from 'utilities/helpers';

class Index extends React.Component {
  constructor(props) {
    super(props);
    const { slug } = this.props.match.params;

    const id = slug.split('-').slice(-1)[0];
    this.props.dispatch(PersonActions.fetchPersonDetails(id));
    this.props.dispatch(CommonActions.changeView(Constants.Views.Creator));
    this.companiesFiltersForm = 'CompanyFiltersForm';
  }

  componentWillUnmount() {
    this.props.dispatch(FormActions.clearAllForms());
  }

    create = (name, object = {}) => {
      this.props.dispatch(FormActions.initialize(name, object, {}));
    };

    edit = (object, name) => {
      this.props.dispatch(FormActions.initialize(name, object, {}));
      this.props.dispatch(FormActions.toggleForm(name));
    };

    remove = (modal) => this.props.dispatch(CommonActions.toggleModal(modal));

    toggleStatus = (id, type) => this.props.dispatch(PersonActions.toggleStatus(this.props.person.id, id, type));

    getCompanyFilters = () => {
      const currentFilters = this.props.forms.forms[this.companiesFiltersForm] || {};

      if (!currentFilters || Object.keys(currentFilters).length <= Object.keys(CommonActions.defaultFilters).length) {
        this.props.dispatch(FormActions.initialize(this.companiesFiltersForm, CommonActions.defaultFilters, {}));
      }

      return this.props.forms.forms[this.companiesFiltersForm];
    };

    fetchCompanies = () => {
      this.props.dispatch(CompanyActions.fetch(this.getCompanyFilters(), true));
    };

    render() {
      const isLoadingState = !this.props.person?.id;

      if(isLoadingState) {
        return <Loader />;
      }

      const { common } = this.props;

      const commission_types = common.commissions ? common.commissions.map((i) => ({ ...i, value: i.id })) : [];
      const currencies = common.currencies ? common.currencies.map((i) => ({ ...i, value: i.id })) : [];

      const roles = common.businessRoles ? common.businessRoles.map((i) => ({ ...i, value: i.id })) : [];
      // let systemRoles = common.systemRoles ? common.systemRoles.map(i => ({ ...i, value: i.id })) : [];
      const sources = common.consentData && common.consentData.consent_sources
        ? common.consentData.consent_sources.map((i) => ({ value: i.id, name: i.name }))
        : [];
      const templates = common.consentData && common.consentData.consent_templates
        ? common.consentData.consent_templates.map((i) => ({ value: i.id, name: i.text }))
        : [];
      const administrators = common.consentData ? common.consentData.consent_administrators : [];
      const representators = common.consentData ? common.consentData.consent_representators : [];
      const inspectors = common.consentData ? common.consentData.consent_data_inspectors : [];

      const addressTypes = common?.commonData?.person_address_types ?
        Object.entries(common?.commonData?.person_address_types)?.map(c => ({ value: String(c[0]), name: c[1] })) : [];
      const voivodeships = common?.commonData?.voivodeships ?
        Object.entries(common?.commonData?.voivodeships)?.map(c => ({ value: String(c[0]), name: c[1] })) : [];
      const contactTypes = this.props.common.contactTypes
        ? Object.entries(this.props.common.contactTypes).map((c) => ({ value: String(c[0]), name: c[1] })) : [];

      const commissionCategories = [{ value: '0', name: 'Rola handlowa' }, { value: '1', name: 'Rola rozliczeniowa' }];
      const countryCallingCodes = optionsCountries(this.props.common.countryCallingCodes);

      return (
        <Container className="mt-3">
          <MainContainer className='w-full max-w-1400 mx-auto'>
            <Header person={this.props.person} />

            <Metrics
              person={this.props.person}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              submitForm={(formName) => {
                this.props.dispatch(PersonActions.saveForm(formName, this.props.person.id, false));
              }}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            />

            <Roles
              selectCompany={(f, c) => this.props.dispatch(FormActions.storeInput(f, 'company', c))}
              companies={this.props.companies}
              roles={roles}
              forms={this.props.forms.forms}
              errors={this.props.forms.errors}
              submitForm={(formName, hide) => this.props.dispatch(PersonActions.addBusinessRole(formName, hide))}
              edit={(contact, modal) => this.edit(contact, modal)}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              person={this.props.person}
              remove={(role, company) => this.props.dispatch(PersonActions.removeBusinessRole(role, company))}
              toggleStatus={(id, type) => this.toggleStatus(id, type)}
              fetchCompanies={this.fetchCompanies}
              filtersForm={this.companiesFiltersForm}
            />

            <Addresses
              forms={this.props.forms.forms}
              person={this.props.person}
              create={(form) => this.create(form, { is_active: true })}
              edit={(obj, modal) => this.edit({ ...obj, type: obj.address_type }, modal)}
              remove={(objectId) => this.props.dispatch(PersonActions.removeObject(objectId, this.props.person.id, 'addresses'))}
              toggleStatus={(addressId) => this.toggleStatus(addressId, 'addresses')}
              addressTypes={addressTypes}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
              submitForm={(formName, hide) => {
                this.props.dispatch(PersonActions.saveForm(formName, this.props.person.id, hide));
              }}
              voivodeships={voivodeships}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              populate={(form, address) => {
                const original = this.props.forms.forms[form] || {};
                this.props.dispatch(FormActions.initialize(form, Object.assign(original, address), {}));
              }}
            />

            <Contacts
              countryCallingCodes={countryCallingCodes}
              contactTypes={contactTypes}
              forms={this.props.forms.forms}
              submitForm={(formName, hide) => {
                this.props.dispatch(PersonActions.saveForm(formName, this.props.person.id, hide));
              }}
              edit={(o, modal) => this.edit({ ...o, country_calling_code_id: o.country_calling_code?.id }, modal)}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              person={this.props.person}
              remove={(objectId) => this.props.dispatch(PersonActions.removeObject(objectId, this.props.person.id, 'contacts'))}
            />

            <SocialMedia
              contactTypes={contactTypes}
              forms={this.props.forms.forms}
              submitForm={(formName, hide) => {
                this.props.dispatch(PersonActions.saveForm(formName, this.props.person.id, hide));
              }}
              edit={(contact, modal) => this.edit(contact, modal)}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              person={this.props.person}
              remove={(objectId) => this.props.dispatch(PersonActions.removeObject(objectId, this.props.person.id, 'contacts'))}
            />

            <Notes
              notes={this.props.person?.notes ?? []}
              forms={this.props.forms.forms}
              submitForm={(formName, hide) => {
                this.props.dispatch(PersonActions.saveForm(formName, this.props.person.id, hide));
              }}
              edit={(contact, modal) => this.edit(contact, modal)}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              remove={(objectId) => this.props.dispatch(PersonActions.removeObject(objectId, this.props.person.id, 'notes'))}
            />

            <Consents
              forms={this.props.forms.forms}
              submitForm={(formName, hide) => {
                this.props.dispatch(PersonActions.saveForm(formName, this.props.person.id, hide));
                this.props.dispatch(FormActions.clearField(formName, 'consent_template_id'));
                this.props.dispatch(FormActions.clearField(formName, 'consent_source_id'));
              }}
              edit={(contact, modal) => this.edit(contact, modal)}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              person={this.props.person}
              remove={(objectId) => this.props.dispatch(PersonActions.removeObject(objectId, this.props.person.id, 'consents'))}
              sources={sources}
              templates={templates}
              administrators={administrators}
              representators={representators}
              inspectors={inspectors}
              onConsentCancel={(consent) => this.props.dispatch(PersonActions.changeConsentStatus(consent))}
            />

            <Commissions
              person={this.props.person}
              forms={this.props.forms.forms}
              toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              remove={(objectId) => this.props.dispatch(PersonActions.removeObject(objectId, this.props.person.id, 'commissions'))}
              create={(f) => this.showForm(f, {})}
              submitForm={(f, h) => this.props.dispatch(PersonActions.saveCommission(f, h))}
              onCancel={(f) => this.toggleForm(f)}
              edit={(object, modal) => this.edit(object, modal)}
              currencies={currencies}
              commission_types={commission_types}
              categories={commissionCategories}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            />

            <Buttons
              className="mb-3"
              onCancel={() => {
                (window.location.href = '/osoby/');
              }}
              onSubmit={() => {
                (window.location.href = `/osoby/${this.props.person.slug}`);
              }}
              submitButton="Dalej"
              cancelButton="Lista osób"
              // onSubmitAndLeave={() => (window.location.href = '/osoby')}
            />
          </MainContainer>
        </Container>
      );
    }
}

const mapStateToProps = (state) => ({
  forms: state.Forms,
  person: state.Person.person,
  common: state.Common,
  companies: state.Company.list
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Index)
);
