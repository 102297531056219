import AgreementActions from 'actions/agreement';
import { Notes } from 'containers/Companies/Details/Components/Notes';
import React from 'react';
import { useDispatch } from 'react-redux';

import { SidebarContainer } from './Layout';


export const SidebarDetails = ({ Notes, section = null, person = false }) => {
  return (
    <SidebarContainer>
      <div className="card">
        {person ? null : (
          <ul className="nav nav-tabs customtab justify-content-between" role="tablist">
            <li className="nav-item">
              <a className="nav-link active show" data-toggle="tab" href="#home2" role="tab" aria-selected="true">
                <span className="hidden-sm-up">
                  <i className="ti-home" />
                </span>
                <span className="hidden-xs-down">Podsumowanie</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link show" data-toggle="tab" href="#profile2" role="tab" aria-selected="false">
                <span className="hidden-sm-up">
                  <i className="ti-user" />
                </span>
                <span className="hidden-xs-down">Wierzytelności</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link show" data-toggle="tab" href="#messages2" role="tab" aria-selected="false">
                <span className="hidden-sm-up">
                  <i className="ti-email" />
                </span>
                <span className="hidden-xs-down">Sprzedaż</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link show" data-toggle="tab" href="#messages3" role="tab" aria-selected="false">
                <span className="hidden-sm-up">
                  <i className="ti-email" />
                </span>
                <span className="hidden-xs-down">Rozrachunki</span>
              </a>
            </li>
          </ul>
        ) }
        <div className="tab-content">
          <div className="tab-pane active show" id="home2" role="tabpanel">
            {section}
            {Notes}
          </div>
          <div className="tab-pane p-20 show" id="profile2" role="tabpanel">
            Brak wierzytelności do wyświetlenia.
          </div>
          <div className="tab-pane p-20 show" id="messages2" role="tabpanel">
            Brak dokumentów sprzedaży do wyświetlenia.
          </div>
          <div className="tab-pane p-20 show" id="messages3" role="tabpanel">
            Brak transakcji płatniczych do wyświetlenia.
          </div>
        </div>
      </div>
    </SidebarContainer>
  );
};
