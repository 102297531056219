import Loader from 'components/Loader';
import { Notes } from 'containers/Companies/Creator/Components/Notes';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CommonActions from '../../../actions/common';
import FormActions from '../../../actions/forms';
import OfferActions from '../../../actions/offer';
// import _ from "lodash";
import { Buttons } from '../../../components/Forms';
import { Container, MainContainer } from '../../../components/Layout';
import Constants from '../../../utilities/constants';
import { AdditionalRates } from './Components/AdditionalRates';
import { Administrator } from './Components/Administrator';
import { BasicData } from './Components/BasicData';
import { BuyoutDocs } from './Components/BuyoutDocs';
import { Commissions } from './Components/Commissions';
import { Conditions } from './Components/Conditions';
import { Header } from './Components/Header';
import { MultilevelRates } from './Components/MultilevelRates';
import { Protections } from './Components/Protections';
import { Rates } from './Components/Rates';
import { Readiness } from './Components/Readiness';

class Index extends React.Component {
  constructor(props) {
    super(props);
    const { slug } = this.props.match.params;
    const id = slug ? slug.split('-').slice(-1)[0] : null;

    this.props.dispatch(OfferActions.fetchOfferDetails(id));
    this.props.dispatch(CommonActions.changeView(Constants.Views.Creator));
  }

  componentWillUnmount() {
    this.props.dispatch(FormActions.clearAllForms());
  }

    create = (name) => {
      this.props.dispatch(FormActions.initialize(name, {}, {}));
    };

    edit = (object, name) => {
      this.props.dispatch(FormActions.initialize(name, object, {}));
      this.props.dispatch(FormActions.toggleForm(name));
    };

    toggleForm = (formName) => this.props.dispatch(FormActions.toggleForm(formName));

    remove = (oId, object, extraData = '') => {
      this.props.dispatch(OfferActions.removeObject(oId, this.props.offer.id, object, extraData));

      if (object === 'calculations' && oId === this.props.calculation.id && extraData === '') {
        const ncs = this.props.offer.calculations.filter((i) => i.id !== oId);
        const nc = ncs.length > 0 ? ncs[0] : {};
        this.props.dispatch(OfferActions.selectCalculation(nc));
      }
    };

    render() {
      let populatedCalculation = this.props.calculation || {};
      populatedCalculation = {
        ...populatedCalculation,
        currency: populatedCalculation.currency ? populatedCalculation.currency.id : null,
        transaction_risk_category_id: populatedCalculation?.transaction_risk_category?.id
      };

      Object.keys(populatedCalculation).forEach((key) => {
        if (populatedCalculation[key] === null) {
          delete populatedCalculation[key];
        }
      });

      const offerData = this.props.common.offerData || {};

      // values is never used - TODO: to remove
      const objectTypes = {
        person: 19,
        company: 33
      };

      const isLoadingState = !this.props.calculation?.id;

      if(isLoadingState) {
        return <Loader />;
      }

      return (
        <Container className="mt-3">
          <MainContainer className='w-full max-w-1400 mx-auto'>
            <Header offer={this.props.offer} calculation={this.props.calculation} />

            <BasicData
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              currencies={this.props.common.currencies}
              offerData={this.props.common.offerData}
              offer={this.props.offer}
              forms={this.props.forms.forms}
              create={(form) => this.create(form)}
              edit={(obj, modal) => this.edit(obj, modal)}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
              saveForm={(formName, hide) => {
                this.props.dispatch(OfferActions.saveForm(formName, this.props.offer.id, hide));
              }}
              calculation={this.props.calculation}
            />

            <Rates
              margin_based_on_tran_risk={offerData.offer_setup ? offerData.offer_setup.global_min_margin_control_basicbid : false}
              setLastAvailableDateOfRate={(formName) => this.props.dispatch(OfferActions.setLastAvailableDateOfRate(formName))}
              getFlatRate={(formName) => this.props.dispatch(OfferActions.getFlatRate(formName))}
              getIntrestRates={(formName) => this.props.dispatch(OfferActions.getIntrestRates(formName))}
              intrestRates={this.props.intrestRates}
              offerData={offerData}
              calculation={this.props.calculation}
              offer={this.props.offer}
              forms={this.props.forms.forms}
              errors={this.props.forms.errors}
              toggleForm={this.toggleForm}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              create={(f) => this.showForm(f, { calculationId: this.props.calculation.id })}
              edit={(obj, modal) => this.edit(obj, modal)}
              saveForm={(formName, hide) => {
                this.props.dispatch(OfferActions.saveForm(formName, this.props.offer.id, hide)).then(() => {
                  this.props.dispatch(FormActions.deleteField(formName, 'max_payment_date'));
                  this.props.dispatch(FormActions.deleteField(formName, 'max_advance_payment'));
                  this.props.dispatch(FormActions.deleteField(formName, 'flat_rate'));
                  this.props.dispatch(FormActions.deleteField(formName, 'intrest_margin'));
                  this.props.dispatch(FormActions.deleteField(formName, 'is_intrest_margin'));
                  this.props.dispatch(FormActions.deleteField(formName, 'transaction_risk_category_id'));
                });
              }}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              onCancel={(f) => this.toggleForm(f)}
              remove={(obj) => this.remove(this.props.calculation.id, 'calculations', `basic_bid/${obj.id}/`)}
            />

            <Commissions
              objectTypes={objectTypes}
              clearBeneficiaries={() => this.props.dispatch({ type: 'OFFER_CLEAR_BENEFICIARIES' })}
              fetchBeneficiaries={(f) => this.props.dispatch(OfferActions.fetchBeneficiaries(f))}
              beneficiaries={this.props.beneficiaries}
              types={this.props.common.commissions ? this.props.common.commissions.map((i) => ({ ...i, value: i.id })) : []}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              calculation={this.props.calculation}
              offer={this.props.offer}
              forms={this.props.forms.forms}
              errors={this.props.forms.errors}
              toggleForm={this.toggleForm}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              create={(f) => this.showForm(f, { calculationId: this.props.calculation.id })}
              edit={(obj, modal) => this.edit(obj, modal)}
              saveForm={(formName, hide) => {
                this.props.dispatch(OfferActions.saveForm(formName, this.props.offer.id, hide));
              }}
              onCancel={(f) => this.toggleForm(f)}
              remove={(obj) => this.remove(this.props.calculation.id, 'calculations', `commissions/${obj.id}/`)}
            />

            <Conditions
              offerData={this.props.common.offerData}
              populateForm={(f) => this.props.dispatch(FormActions.initialize(f, populatedCalculation, {}))}
              calculation={this.props.calculation}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              currencies={this.props.common.currencies}
              offer={this.props.offer}
              forms={this.props.forms.forms}
              toggleForm={this.toggleForm}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              saveForm={(formName, hide) => {
                this.props.dispatch(OfferActions.saveForm(formName, this.props.offer.id, hide));
              }}
              edit={(f, o) => this.showForm(f, o)}
            />

            <Notes
              notes={this.props.offer?.notes ?? []}
              forms={this.props.forms.forms}
              submitForm={(formName, hide) => {
                this.props.dispatch(OfferActions.saveForm(formName, this.props.offer.id, hide));
              }}
              edit={(contact, modal) => this.edit(contact, modal)}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              remove={(objectId) => this.props.dispatch(OfferActions.removeObject(objectId, this.props.offer.id, 'notes'))}
            />

            <Readiness
              storeInput={(a, b, c) => this.props.dispatch(FormActions.storeInput(a, b, c))}
              countingMethods={offerData.counting_method ? offerData.counting_method.map((i) => ({ value: i.id, name: i.name })) : []}
              populateForm={(f) => this.props.dispatch(FormActions.initialize(f, populatedCalculation, {}))}
              calculation={this.props.calculation}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              currencies={this.props.common.currencies}
              offer={this.props.offer}
              forms={this.props.forms.forms}
              errors={this.props.forms.errors}
              toggleForm={this.toggleForm}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              create={(f) => this.showForm(f, {})}
              saveForm={(formName, hide) => {
                this.props.dispatch(OfferActions.saveForm(formName, this.props.offer.id, hide));
              }}
              onCancel={(f) => this.toggleForm(f)}
              remove={(objectId) => this.props.dispatch(OfferActions.removeObject(objectId, this.props.offer.id, 'notes'))}
            />

            <Administrator
              freqs={offerData.lump_sum_req}
              populateForm={(f) => this.props.dispatch(FormActions.initialize(f, populatedCalculation, {}))}
              calculation={this.props.calculation}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              currencies={this.props.common.currencies}
              offer={this.props.offer}
              forms={this.props.forms.forms}
              toggleForm={this.toggleForm}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              saveForm={(formName, hide) => {
                this.props.dispatch(OfferActions.saveForm(formName, this.props.offer.id, hide));
              }}
              edit={(f, o) => this.showForm(f, o)}
            />

            <BuyoutDocs
              calculation={this.props.calculation}
              offer={this.props.offer}
              forms={this.props.forms.forms}
              errors={this.props.forms.errors}
              toggleForm={this.toggleForm}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              create={(f) => this.showForm(f, { calculationId: this.props.calculation.id })}
              saveForm={(formName, hide) => {
                this.props.dispatch(OfferActions.saveForm(formName, this.props.offer.id, hide));
              }}
              storeInput={(a, b, c) => this.props.dispatch(FormActions.storeInput(a, b, c))}
              onCancel={(f) => this.toggleForm(f)}
              remove={(obj) => this.props.dispatch(
                OfferActions.removeObject(
                  this.props.calculation.id,
                  this.props.offer.id,
                  'calculations',
                  `documents_to_buy/${obj.id}/`
                )
              )}
              filtersForm={this.companiesFiltersForm}
              documents={offerData.dtb_types ? offerData.dtb_types.map((i) => ({ ...i, value: i.id })) : []}
              expectedForms={offerData.dtb_forms ? offerData.dtb_forms.map((i) => ({ ...i, value: i.id })) : []}
            />

            <Protections
              calculation={this.props.calculation}
              offer={this.props.offer}
              forms={this.props.forms.forms}
              errors={this.props.forms.errors}
              toggleForm={this.toggleForm}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              create={(f) => this.showForm(f, { calculationId: this.props.calculation.id })}
              saveForm={(formName, hide) => {
                this.props.dispatch(OfferActions.saveForm(formName, this.props.offer.id, hide));
              }}
              onCancel={(f) => this.toggleForm(f)}
              remove={(obj) => this.props.dispatch(
                OfferActions.removeObject(
                  this.props.calculation.id,
                  this.props.offer.id,
                  'calculations',
                  `documents_to_secure/${obj.id}/`
                )
              )}
              filtersForm={this.companiesFiltersForm}
              documents={offerData.dts_types ? offerData.dts_types.map((i) => ({ ...i, value: i.id })) : []}
              expectedForms={offerData.dts_forms ? offerData.dts_forms.map((i) => ({ ...i, value: i.id })) : []}
            />

            <MultilevelRates
              margin_based_on_tran_risk={offerData.offer_setup ? offerData.offer_setup.global_min_margin_control_multilevelbid : false}
              getMultilevelFlatRate={(formName) => this.props.dispatch(OfferActions.getMultilevelFlatRate(formName))}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              calculation={this.props.calculation}
              offer={this.props.offer}
              offerData={offerData}
              forms={this.props.forms.forms}
              errors={this.props.forms.errors}
              toggleForm={this.toggleForm}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              create={(f) => this.showForm(f, { calculationId: this.props.calculation.id })}
              edit={(obj, modal) => this.edit(obj, modal)}
              saveForm={(formName, hide) => {
                this.props.dispatch(OfferActions.saveForm(formName, this.props.offer.id, hide));
              }}
              onCancel={(f) => this.toggleForm(f)}
              remove={(obj) => this.remove(this.props.calculation.id, 'calculations', `multilevel_bid/${obj.id}/`)}
            />

            <AdditionalRates
              calculation={this.props.calculation}
              offer={this.props.offer}
              forms={this.props.forms.forms}
              errors={this.props.forms.errors}
              toggleForm={this.toggleForm}
              w
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              create={(f) => this.showForm(f, { calculationId: this.props.calculation.id })}
              edit={(obj, modal) => this.edit(obj, modal)}
              saveForm={(formName, hide) => {
                this.props.dispatch(OfferActions.saveForm(formName, this.props.offer.id, hide));
              }}
              onCancel={(f) => this.toggleForm(f)}
              offerData={this.props.common.offerData}
              remove={(obj) => this.remove(this.props.calculation.id, 'calculations', `additional_bid/${obj.id}/`)}
            />

            <Buttons
              className="mb-3"
              onCancel={() => { (window.location.href = '/oferty/'); }}
              onSubmit={() => { (window.location.href = `/oferty/${this.props.offer.slug}`); }}
              submitButton="Dalej"
              cancelButton="Lista ofert"
              // onSubmitAndLeave={() => (window.location.href = '/oferty')}
            />
          </MainContainer>
        </Container>
      );
    }
}

const mapStateToProps = (state) => ({
  forms: state.Forms,
  offer: state.Offer.offer,
  calculation: state.Offer.calculation,
  beneficiaries: state.Offer.beneficiaries,
  intrestRates: state.Offer.intrestRates,
  common: state.Common,
  companies: state.Company.list
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Index)
);
