import Card from 'components/Layout/Card';
import { isEditableProposal } from 'containers/Proposals/Details/Index';
import { t } from 'lang/pl';
import React from 'react';
import { toast } from 'react-toastify';

import BooleanCheck from '../../../../components/BooleanCheck';
import { parseMoney } from '../../../../utilities/helpers';
import DecisionString from 'components/Format/DecisionString';
import { TextLink } from 'containers/Companies/Details/Components/Notes';

export default (props) => {
  const p = props.version || {};
  const fr = p.risk_matrix ? p.risk_matrix.factoree : {};
  const dr = p.risk_matrix ? p.risk_matrix.debtors : [];

  return (
    <Card
      header='Macierz ryzyka'
      isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
      onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
    >
      <div>
        <div className="row bb">
          <div className="flex-5 p-2 br grey">Faktorant</div>
          <div className="flex-3 max-w-300 p-2 br grey">Ocena ważona Faktoranta</div>
          <div className="flex-3 max-w-300 p-2 br grey">Ocena zabezpieczeń Faktoranta</div>
          <div className="flex-2 max-w-100 p-2 br grey">Regres</div>
          <div className="flex-2 max-w-300 p-2 br grey">Wnioskowany limit</div>
          <div className="flex-3 max-w-300 p-2 grey">Decyzja kredytowa</div>
        </div>
        <div className="row bb">
          <div className="flex-5 p-2 br ">{fr.name}</div>
          <div className="flex-3 max-w-300 p-2 br ">{fr.weighted_rating}</div>
          <div className="flex-3 max-w-300 p-2 br ">{fr.security_rating}</div>
          <div className="flex-2 max-w-100 p-2 br "><BooleanCheck field={fr.recourse} /></div>
          <div className="flex-2 max-w-300 p-2 br ">{parseMoney(fr.limit_amount)}</div>
          <div className="flex-3 max-w-300 p-2"><DecisionString value={fr.factoree_credit_decision} /></div>
        </div>

        <div className="row bb bt mt-4">
          <div className="flex-5 p-2 br grey">Nazwa dłużnika</div>
          <div className="flex-3 max-w-300 p-2 br grey">Ocena ważona dłużnika</div>
          <div className="flex-3 max-w-300 p-2 br grey">Ocena zabezpieczeń dłużnika</div>
          <div className="flex-3 max-w-300 p-2 br grey">Ocena zabezpieczeń Transakcji</div>
          <div className="flex-2 max-w-300 p-2 br grey">Łączna punktacja</div>
          <div className="flex-2 max-w-300 br p-2 grey">Sublimit</div>
          <div className="flex-4 max-w-300 p-2 grey">Decyzja kredytowa</div>
        </div>
        {dr.map((r) => (
          <>
            <div className="row bb">
              <div className="flex-5 p-2 br ">{r.name}</div>
              <div className="flex-3 max-w-300 p-2 br ">{r.weighted_rating}</div>
              <div className="flex-3 max-w-300 p-2 br ">{r.security_rating}</div>
              <div className="flex-3 max-w-300 p-2 br ">{r.security_transaction}</div>
              <div className="flex-2 max-w-300 p-2 br ">{r.total_score}</div>
              <div className="flex-2 max-w-300 br p-2">{parseMoney(r.requested_sublimit)}</div>
              <div
                className="flex-4 max-w-300 p-2"
                style={{
                  color: r.debtor_credit_decision === 0 ? 'red' : r.debtor_credit_decision === 1 ? 'rgb(29, 179, 147)' : 'black'
                }}
              >
                <DecisionString value={props.proposalData?.credit_decisions?.[r.debtor_credit_decision]} />
              </div>
            </div>
            {r.comment ? (
              <div className="row bb">
                <div className="col-sm-12 p-2">
                  <TextLink>{r.comment}</TextLink>
                </div>
              </div>
            ) : null}
          </>
        ))}
      </div>
    </Card>
  );
};
