// import { cloneDeep } from 'lodash';

export const AGREEMENT_UPDATE_ATTACHMENTS = 'AGREEMENT_UPDATE_ATTACHMENTS';

const initialState = {
  list: [],
  links: {},
  count: 0,
  currentPage: 1,
  formData: {},
  currentTab: 1,
  agreement: {},
  version: {},
  preview: {},
  guarantors: {},
  claims: {},
  debtorsDict: {}
};

export default function Proposals(state = initialState, action) {
  switch (action.type) {
    case 'AGREEMENTS_FETCHED':
      return {
        ...state,
        list: action.payload.results,
        links: action.payload.links,
        currentPage: action.payload.current_page,
        count: action.payload.count
      };
    case 'AGREEMENT_FORMDATA_FETCHED':
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.resource]: action.payload.data
        }
      };
    case 'AGREEMENT_DETAILS_FETCHED':
      return { ...state, agreement: action.payload };
    case 'AGREEMENT_DETAILS_UPDATED':
      return { ...state, agreement: action.payload };
    case 'AGREEMENT_FOR_PREVIEW_SELECTED':
      return { ...state, preview: action.payload };
    case 'AGREEMENT_VERSION_FETCHED':
      console.log('action.payload', action.payload);
      return { ...state, version: action.payload };
    case AGREEMENT_UPDATE_ATTACHMENTS:
      return { ...state, version: { ...state.version, attachments: action.payload } };
    case 'AGREEMENT_TAB_CHANGED':
      return { ...state, currentTab: action.payload };
    case 'AGREEMENT_GUARANTORS_FETCHED':
      return { ...state, guarantors: action.payload };
    case 'AGREEMENT_CLAIMS_FETCHED':
      return { ...state, claims: action.payload };
    case 'AGREEMENT_DEBTORS_DICT_FETCHED':
      return { ...state, debtorsDict: action.payload };
    default:
      return state;
  }
}
