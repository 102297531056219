import Card from 'components/Layout/Card';
import React from 'react';

import { ScoringForm } from '../../Forms/Scoring';

export const Scoring = (props) => {
  const formName = 'ScoringCreatorForm';

  return (
    <Card mode='creator' header='+ Dane scoringowe' onSaveClick={() => props.submitForm(formName)}>
      <div/>
      <ScoringForm
        companyData={props.companyData}
        companyId={props.company ? props.company.id : 'brak'}
        visibleOnMount
        visible={props.formVisible(formName)}
        formName={formName}
      />
    </Card>
  );
};
