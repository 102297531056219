import React from 'react';

import {
  Datepicker,
  Form
} from '../../../components/Forms';
import { parseMoney } from '../../../utilities/helpers';

export const Generate = (props) => (
  <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
    <div className="row">
      <div className="col-sm-12 p-3">
        Czy chcesz wygenerować {props.mode === 0 ? 'fakturę' : 'notę'} dla zaznaczonych należności?
      </div>
    </div>

    <div className="row">
      <div className="col-sm-6">
        <div className="form-group row  align-items-center">
          <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                      Data wystawienia
          </label>
          <div className="col-sm-6">
            <Datepicker formName={props.formName} name="issue_date" />
          </div>
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group row  align-items-center">
          <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
                      Ilość pozycji
          </label>
          <div className="col-sm-6">
            {props.dues.length}
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-12 pl-3 pr-3">
        <table className="table w-100">
          <thead>
            <tr>
              <td>Nazwa salda</td>
              <td>Kwota brutto</td>
              <td>Kontrahent</td>
              <td>NIP</td>
            </tr>
          </thead>
          <tbody>
            {props.dues.map(due => <tr>
              <td>{due.title}</td>
              <td>{parseMoney(due.gross_value)}</td>
              <td>{due.claim?.client?.name}</td>
              <td>{due.claim?.client?.nip}</td>
            </tr>)}
          </tbody>
        </table>
      </div>
    </div>
    {props.withButtons ? (
      <div className="row">
        <div className="mt-4 col-sm-12 text-center">
          <button
            type="button"
            onClick={() => props.toggleForm(props.formName)}
            className="btn btn-primary-outline mr-2 font-weight-light"
            style={{ color: '#4B4E55' }}
          >
            Anuluj
          </button>
          <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
            Generuj
          </button>
        </div>
      </div>
    ) : null}
  </Form>
);
