import 'twin.macro';

import api from 'api';
import Required from 'components/Forms/Required';
import useData from 'hooks/useData';
import React from 'react';
import { cutEmptySpaces } from 'utilities/helpers';

import { Buttons, Form, Input, Select } from '../../../components/Forms';

export const CreateCompanyForm = props => {
  const { sendRequest, errorText } = useData();

  const data = props.companyData ? props.companyData : {};
  const mapForSelect = i => ({ ...i, value: i.id });

  const businessCompanyIds = data.company_roles ? data.company_roles.map(mapForSelect) : [];
  const legalForms = data.legal_forms ? data.legal_forms.map(mapForSelect) : [];
  const representationIds = data.representations ? data.representations.map(mapForSelect) : [];
  const industryIds = data.industries ? data.industries.map(mapForSelect) : [];

  const handleCompanyNameChange = value => {
    const v = value ? String(value).toUpperCase() : value;
    const identifier = cutEmptySpaces(v);
    props.onNameChange(v);

    if (identifier?.length === 6) {
      sendRequest(api.companies.checkId({ identifier }));
    }
  };

  const handleIdentifierChange = value => {
    sendRequest(api.companies.checkId({ identifier: value }));
  };

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-4 label-right col-form-label">
                  Nazwa firmy<Required/>
                </label>
                <div className="col-sm-8">
                  <Input formName={props.formName} type="text" name="name" onChange={handleCompanyNameChange} />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-4 label-right col-form-label">
                  Identyfikator firmy<Required/>
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="identifier" error={errorText} onChange={handleIdentifierChange} maxLength="6" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-4 label-right col-form-label">
                  Forma prawna<Required/>
                </label>
                <div className="col-sm-6">
                  <Select disabled={props.selectDisabled || false} formName={props.formName} options={legalForms} name="legal_form_id" />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-4 label-right col-form-label">
                  Reprezentacja
                </label>
                <div className="col-sm-6">
                  <Select disabled={props.selectDisabled || false} formName={props.formName} options={representationIds} name="representation_id" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-4 label-right col-form-label">
                  Telefon<Required/>
                </label>
                <div className="col-sm-2">
                  <Select isClearable={false} tw='w-70 min-w-70' number formName={props.formName} options={props.countryCallingCodes} name="country_calling_code_id" placeholder='' />
                </div>
                <div className="col-sm-4 pl-0">
                  <Input formName={props.formName} type="text" name="phone" />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-4 label-right col-form-label">
                  E-mail
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="value" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-4 label-right col-form-label">
                  NIP
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="nip" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-4 label-right col-form-label">
                  Branża
                </label>
                <div className="col-sm-6">
                  <Select number disabled={props.selectDisabled || false} formName={props.formName} options={industryIds} name="industry_id" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-4 label-right col-form-label">
                  Rola biznesowa<Required/>
                </label>
                <div className="col-sm-6">
                  <Select
                    number
                    disabled={props.selectDisabled || false}
                    formName={props.formName}
                    options={businessCompanyIds}
                    name="business_company_ids"
                  />
                </div>
              </div>
            </div>
          </div>

          {props.withButtons ? (
            <Buttons formName={props.formName} onCancel={props.onCancel} onSubmit={props.submitForm} onSubmitAndLeave={props.onSubmitAndLeave} />
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
