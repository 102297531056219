import Select from 'components/Forms/Select';
import { genderOptions } from 'containers/Persons/List/Create';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { populateForm } from 'reducers/forms';

import { Buttons, Datepicker, Form, Input } from '../../../components/Forms';

export const PersonForm = props => {
  const { person } = useSelector(state => state.Person);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(populateForm(props.formName, person));
  }, [person]);

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row mt-3">
            <div className="col-sm-6">
              <div className="form-group row align-items-center">
                <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  ID osoby
                </label>
                <div className="col-sm-6">{props.personId}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Imię
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="first_name" />
                </div>
              </div>
            </div>
            <div className="col-sm-6 pr-5">
              <div className="form-group row">
                <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Drugie imię
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="second_name" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Nazwisko
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="last_name" />
                </div>
              </div>
            </div>{' '}
            <div className="col-sm-6 pr-5">
              <div className="form-group row">
                <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Płeć
                </label>
                <div className="col-sm-6">
                  <Select formName={props.formName} options={genderOptions} name="sex" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Data urodzenia
                </label>
                <div className="col-sm-6">
                  <Datepicker formName={props.formName} name="date_of_birth" />
                  {/* <Input formName={props.formName} type="text" name="date_of_birth" /> */}
                </div>
              </div>
            </div>{' '}
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Obywatelstwo
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="citizenship" />
                </div>
              </div>
            </div>{' '}
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  PESEL
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="pesel" />
                </div>
              </div>
            </div>
            <div className="col-sm-6 pr-5">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  NIP
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="nip" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Dowód osobisty
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="id_card" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6  mb-3">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Paszport
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="passport" />
                </div>
              </div>
            </div>
          </div>

          {props.withButtons ? (
            <Buttons formName={props.formName} onCancel={props.onCancel} onSubmit={props.submitForm} onSubmitAndLeave={props.onSubmitAndLeave} />
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
