import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const ContextNavigation = ({ menu }) => {
  const history = useHistory();

  return menu?.length ? (
    <aside className="left-sidebar contextSidebar">
      <div className="scroll-sidebar">
        <nav className="sidebar-nav">
          <ul id="sidebarnav">
            <li>
              <Link to='#' onClick={history.goBack}>Powrót</Link>
            </li>
            {menu.map(element => (
              <li key={element.name}>
                {element.options ? (
                  <>
                    <Link to={element.link} onClick={element.onClick} aria-expanded="false">
                      <i className="fas fa-chevron-down" />
                      <span className="hide-menu">{element.name}</span>
                    </Link>
                    <ul aria-expanded="false" className="collapse">
                      {element.options.map(option => (
                        <li key={option.name}>
                          {option.link ? (
                            <Link to={option.link}>{option.name}</Link>
                          ) : (
                            <Link to={option?.link} onClick={option.onClick} aria-expanded="false">
                              {option.name}
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </>
                ) : element.link ? (
                  <Link to={element.link} onClick={element.onClick}>
                    <span className="hide-menu">{element.name}</span>
                  </Link>
                ) : (
                  <Link to='#' onClick={element.onClick} aria-expanded="false">
                    {' '}
                    <span className="hide-menu">{element.name}</span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  ) : (
    <div style={{ marginTop: '-50px' }} />
  );
};

export default ContextNavigation;
