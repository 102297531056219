import CompanyActions from 'actions/companies';
import ProposalActions from 'actions/proposal';
import LoaderBackdrop from 'components/Layout/LoaderBackdrop';
import { Notes } from 'containers/Companies/Details/Components/Notes';
import { t } from 'lang/pl';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEnabledOnStatuses } from 'utilities/helpers';

import AgreementActions from '../../../actions/agreement';
import CommonActions from '../../../actions/common';
import FormActions from '../../../actions/forms';
import OfferActions from '../../../actions/offer';
import { Container, MainContainer } from '../../../components/Layout';
import { SidebarDetails } from '../../../components/SidebarDetails';
import Constants from '../../../utilities/constants';
import Tabs from '../Tabs';
import Header from './Components/Header';
import { Versions } from './Components/Versions';
import Accounts from './Tabs/Accounts';
import Complaints from './Tabs/Complaints';
import Debtors from './Tabs/Debtors';
import Documents from './Tabs/Documents';
import Params from './Tabs/Params';

export const STATUS_AGREEMENT = {
  UTWORZONA: 0,
  ZATWIERDZONA: 1,
  ZAWARTA: 2,
  AKTYWNA: 3,
  W_TRAKCIE_ANEKSOWANIA: 4,
  ZAKONCZONA: 5,
  PRZETERMINOWANA: 6,
  WINDYKACJA: 7,
  ROZLICZONA: 8,
  ANULOWANA: 9,
  RESTRUKTURYZACJA: 10
};

export const STATUS_AGREEMENT_VERSION = {
  NIEZATWIERDZONY: 0,
  ZATWIERDZONY: 1
};

class Index extends React.Component {
  steps = [
    { id: 1, name: 'Komunikacja' },
    { id: 2, name: 'Dłużnicy' },
    { id: 3, name: 'Bankowe' },
    { id: 4, name: 'Dokumenty i zabezpieczenia' },
    { id: 5, name: 'Aneksowanie i reklamacje' }
  ];

  constructor(props) {
    super(props);
    const { slug } = this.props.match.params;
    const id = slug ? slug.split('-').slice(-1)[0] : null;
    this.props.dispatch(AgreementActions.fetchDetails(id));
    this.props.dispatch(CommonActions.changeView(Constants.Views.Details));
  }

  // handle header menu
  handleNewVersion() {
    const isDisabled = isEnabledOnStatuses([STATUS_AGREEMENT.UTWORZONA, STATUS_AGREEMENT.ZATWIERDZONA, STATUS_AGREEMENT.ZAWARTA], this.props.agreement?.status);
    if(!isDisabled) {
      toast.error(t.error.toast.notImplemented);
    } else {
      toast.error(t.error.toast.statusWarning);
    }
  }
  handleToEdit() {
    const isDisabled = isEnabledOnStatuses([STATUS_AGREEMENT.ZATWIERDZONA, STATUS_AGREEMENT.ZAWARTA], this.props.agreement?.status);
    if(!isDisabled) {
      this.props.dispatch(AgreementActions.updateStatusToEdit(this.props.agreement.id));
    } else {
      toast.error(t.error.toast.statusWarning);
    }
  }
  handleApprove() {
    const isDisabled = isEnabledOnStatuses([STATUS_AGREEMENT.UTWORZONA], this.props.agreement?.status);
    if(!isDisabled) {
      this.props.dispatch(AgreementActions.accept(this.props.agreement.id, this.props.version.id));
    } else {
      toast.error(t.error.toast.statusWarning);
    }
  }
  handleConclude() {
    const isDisabled = isEnabledOnStatuses([STATUS_AGREEMENT.ZATWIERDZONA], this.props.agreement?.status);
    if(!isDisabled) {
      this.props.dispatch(AgreementActions.updateStatusConclude(this.props.agreement.id));
    } else {
      toast.error(t.error.toast.statusWarning);
    }
  }
  handleActive() {
    const isDisabled = isEnabledOnStatuses([STATUS_AGREEMENT.ZAWARTA, STATUS_AGREEMENT.PRZETERMINOWANA, STATUS_AGREEMENT.WINDYKACJA], this.props.agreement?.status);
    if(!isDisabled) {
      this.props.dispatch(AgreementActions.active(this.props.agreement.id));
    } else {
      toast.error(t.error.toast.statusWarning);
    }
  }
  handleAnnex() {
    const isDisabled = isEnabledOnStatuses([STATUS_AGREEMENT.AKTYWNA, STATUS_AGREEMENT.ZAKONCZONA, STATUS_AGREEMENT.PRZETERMINOWANA, STATUS_AGREEMENT.WINDYKACJA], this.props.agreement?.status);
    if(!isDisabled) {
      this.showForm('AnnexesForm', {});
    } else {
      toast.error(t.error.toast.statusWarning);
    }
  }
  handleRestructure() {
    const isDisabled = isEnabledOnStatuses([STATUS_AGREEMENT.PRZETERMINOWANA, STATUS_AGREEMENT.WINDYKACJA], this.props.agreement?.status);
    if(!isDisabled) {
      this.props.dispatch(AgreementActions.updateStatusRestructure(this.props.agreement.id));
    } else {
      toast.error(t.error.toast.statusWarning);
    }
  }
  handleCancel() {
    const isDisabled = isEnabledOnStatuses([STATUS_AGREEMENT.UTWORZONA, STATUS_AGREEMENT.ZATWIERDZONA, STATUS_AGREEMENT.ZAWARTA], this.props.agreement?.status);
    if(!isDisabled) {
      toast.error(t.error.toast.notImplemented);
    } else {
      toast.error(t.error.toast.statusWarning);
    }
  }
  handleAddRedemption() {
    const isDisabled = isEnabledOnStatuses([STATUS_AGREEMENT.AKTYWNA, STATUS_AGREEMENT.W_TRAKCIE_ANEKSOWANIA, STATUS_AGREEMENT.PRZETERMINOWANA], this.props.agreement?.status);
    if(!isDisabled) {
      this.generateClaim();
    } else {
      toast.error(t.error.toast.statusWarning);
    }
  }

  componentDidMount() {
    this.props.dispatch(
      CommonActions.setContextMenu([
        { name: 'Lista umów', link: '/umowy' },
        {
          name: 'Umowa',
          options: [
            { name: 'Nowa wersja', onClick: () => this.handleNewVersion() },
            { name: 'Do edycji', onClick: () => this.handleToEdit() },
            { name: 'Zatwiedź', onClick: () => this.handleApprove() },
            { name: 'Zawarta', onClick: () => this.handleConclude() },
            { name: 'Aktywuj', onClick: () => this.handleActive() },
            { name: 'Aneksuj', onClick: () => this.handleAnnex() },
            { name: 'Restrukturyzuj', onClick: () => this.handleRestructure() },
            { name: 'Anuluj', onClick: () => this.handleCancel() },
            { name: 'Dodaj wykup', onClick: () => this.handleAddRedemption() }
            // { name: 'Zatwierdź', onClick: () => this.props.dispatch(AgreementActions.approve(this.props.agreement.id)) },
            // { name: 'Aktywuj', onClick: () => this.props.dispatch(AgreementActions.active(this.props.agreement.id)) },
            // { name: 'Do aneksu', onClick: () => this.props.dispatch(AgreementActions.annex(this.props.agreement.id)) },
            // { name: 'Nowa wersja', onClick: () => {} },
            // { name: 'Anuluj', onClick: () => this.props.dispatch(AgreementActions.cancel(this.props.agreement.id)) },
            // { name: 'Ustaw status' }
          ]
        },
        {
          name: 'Dodaj',
          options: [
            {
              name: 'Osoby powiązane klienta',
              onClick: () => {
                this.changeTab(1);
                this.showForm('client_people', {});
              }
            },
            {
              name: 'Autoryzowany email klienta',
              onClick: () => {
                this.changeTab(1);
                this.showForm('emails', {});
              }
            },
            {
              name: 'Zabezpieczenia',
              onClick: () => {
                this.changeTab(4);
                this.showForm('SecuresForm', { secures: [{}] });
              }
            },
            {
              name: 'Dokumenty do wykupu',
              onClick: () => {
                this.changeTab(4);
                this.showForm('DocumentsForm', {});
              }
            },
            {
              name: 'Reklamację',
              onClick: () => {
                this.changeTab(5);
                this.showForm('ComplaintsForm', {});
              }
            },
            {
              name: 'Zabezpieczenie umowy',
              onClick: () => {
                this.changeTab(4);
                this.showForm('SecuresForm', { secures: [{}] });
              }
            }
          ]
        },
        { name: 'Dodaj wykup' },
        {
          name: 'Notatka',
          onClick: () => {
            this.changeTab(1);
            this.showForm('NotesForm', {});
          }
        },
        {
          name: 'Pozostałe',
          options: [
            {
              name: 'Warunki dodatkowe',
              onClick: () => {
                this.changeTab(1);
                this.showForm('ConditionsForm', {});
              }
            },
            {
              name: 'Gotowość',
              onClick: () => {
                this.changeTab(1);
                this.showForm('ReadinessForm', {});
              }
            }
          ]
        },
        {
          name: 'Przejdź do',
          options: [
            { name: 'Klienta', link: `/firmy/${this.props.agreement?.client?.id}` },
            { name: 'Oferty', link: `/oferty/${this.props.agreement?.proposal?.offer_id}` },
            { name: 'Wniosku', link: `/wnioski/${this.props.agreement?.proposal?.id}` },
            { name: 'Wierzytelności', link: `/wierzytelnosci?agreement_id=${this.props.agreement.id}` },
            { name: 'Przelewy wychodzące', link: '/przelewy?mode=wychodzace' }
          ]
        },
        {
          name: 'Bankowe',
          options: [
            {
              name: 'Dodaj rachunek klienta',
              onClick: () => {
                this.changeTab(3);
                this.showForm('client_accounts', {});
              }
            },
            {
              name: 'Dodaj rachunek Faktora',
              onClick: () => {
                this.changeTab(3);
                this.showForm('factor_bank_accounts_1', {});
              }
            }
          ]
        },
        { name: 'Opiekunowie' },
        {
          name: 'Dokumenty umowy',
          options: [
            { name: 'Dodaj załącznik' },
            {
              name: 'Dodaj z szablonu',
              onClick: () => {
                this.changeTab(4);
                this.showForm('DocumentsForm', {});
              }
            }
          ]
        },
        {
          name: 'Aneksowanie i reklamacje',
          options: [
            {
              name: 'Otwórz reklamację',
              onClick: () => {
                this.changeTab(5);
                this.showForm('ComplaintsForm', {});
              }
            },
            {
              name: 'Aneksuj',
              onClick: () => {
                this.changeTab(5);
                this.showForm('AnnexesForm', {});
              }
            }
          ]
        },
        { name: 'Raport umowy', onClick: () => this.props.dispatch(AgreementActions.generateRaport(this.props.agreement.id)) },
        { name: 'Do windykacji', onClick: () => this.props.dispatch(AgreementActions.recover(this.props.agreement.id)) }
      ])
    );
  }

  componentWillUnmount() {
    this.props.dispatch(FormActions.clearAllForms(['CreateClaimForm']));
  }

  launch(formName, annexId, send = false) {
    if (send) {
      this.save(formName);
    } else {
      this.props.dispatch(FormActions.initialize(formName, { annexId }, {}));
      this.toggleForm(formName);
    }

  }

  getMainComponent() {
    switch (this.props.tab) {
      case 1:
        return (
          <Params
            edit={(n, o) => this.edit(o, n)}
            offerData={this.props.common?.offerData}
            version={this.props.version}
            saveForm={formName => this.save(formName, true)}
            remove={(uri, id) => this.remove(uri, id)}
            agreement={this.props.agreement}
            toggleForm={f => this.toggleForm(f)}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            selectVersion={v => this.props.dispatch(AgreementActions.selectVersion(v))}
            representationTypes={this.props.common?.companyData?.representations ?? []}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            updateRepresentation={formName => this.updateRepresentation(formName)}
            clearForm={f => this.props.dispatch(FormActions.clearForm(f))}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            forms={this.props.forms?.forms}
            errors={this.props.forms?.errors}
            types={this.props.common.commissions ? this.props.common.commissions.map(i => ({ ...i, value: i.id })) : []}
            clearBeneficiaries={() => this.props.dispatch({ type: 'OFFER_CLEAR_BENEFICIARIES' })}
            fetchBeneficiaries={f => this.props.dispatch(OfferActions.fetchBeneficiaries(f))}
            beneficiaries={this.props.beneficiaries}
            objectTypes={[]}
            roles={
              this.props.common.systemRoles
                ? this.props.common.systemRoles.filter(r => r.is_used_for_commissions).map(i => ({ ...i, value: i.id }))
                : []
            }
          />

        );
      case 2:
        return (
          <>
            <Debtors
              debtors={this.props.version?.agreement_debtors}
              populateForm={(f, d) => this.props.dispatch(FormActions.initialize(f, { ...d }, {}))}
              forms={this.props.forms.forms}
              errors={this.props.forms.errors}
              version={this.props.version}
              toggleForm={f => this.toggleForm(f)}
              formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
              create={f => this.showForm(f, { calculationId: this.props.calculation.id })}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              edit={(modal, obj) => this.edit(obj, modal)}
              saveForm={(f, h) => this.save(f, h)}
              onCancel={f => this.toggleForm(f)}
              common={this.props.common}
              remove={(object, id) => this.remove(object, id)}
              proposal={this.props.agreement}
              fetch={(t, f) => this.props.dispatch(ProposalActions.fetchForForm(t, f))}
              formData={this.props.proposalFormData}
              addField={(field, form) => this.addField(field, form)}
              removeField={(field, form) => this.removeField(field, form)}
              offerData={this.props.common.offerData}
              proposalData={this.props.common.proposalData}
              getFlatRate={formName => {
                this.props.dispatch(
                  FormActions.storeInput(formName, 'transaction_risk_category_id', this.props.agreement?.proposal?.transaction_risk_category?.id)
                );
                this.props.dispatch(OfferActions.getFlatRate(formName));
              }}
              getMultilevelFlatRate={formName => {
                this.props.dispatch(
                  FormActions.storeInput(formName, 'transaction_risk_category_id', this.props.agreement?.proposal?.transaction_risk_category?.id)
                );
                this.props.dispatch(OfferActions.getMultilevelFlatRate(formName));
              }}
              getIntrestRates={formName => this.props.dispatch(OfferActions.getIntrestRates(formName))}
              setLastAvailableDateOfRate={(formName, field) => this.props.dispatch(OfferActions.setLastAvailableDateOfRate(formName, field))}
              intrestRates={this.props.intrestRates}
              storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
              fetchFromOffer={(debtorId, object) => this.props.dispatch(ProposalActions.fetchFromOffer(debtorId, object))}
            />
          </>
        );
      case 3:
        return (
          <Accounts
            version={this.props.version}
            saveForm={formName => this.save(formName, true)}
            remove={(uri, id) => this.remove(uri, id)}
            agreement={this.props.agreement}
            toggleForm={f => this.toggleForm(f)}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
          />
        );
      case 4:
        return (
          <Documents
            refresh={() => this.props.dispatch(AgreementActions.fetchDetails(this.props.agreement.id))}
            reset={() => this.props.dispatch(AgreementActions.resetDocuments())}
            generate={(formName, obj, attachmentId, action, deleting, update) => {
              return this.props.dispatch(AgreementActions.handleFile(formName, obj, attachmentId, action, deleting, update));
            }}
            version={this.props.version}
            saveForm={formName => this.save(formName, true)}
            remove={(uri, id) => this.remove(uri, id)}
            agreement={this.props.agreement}
            toggleForm={f => this.toggleForm(f)}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            edit={(n, o, p) => this.edit(o, n, p)}
            forms={this.props.forms.forms}
            common={this.props.common}
            fetchIssuers={filtersForm => this.props.dispatch(CompanyActions.fetch(this.props.forms?.forms?.[filtersForm], true))}
            issuers={this.props.companies}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            guarantors={this.props.guarantors}
            fetchGuarantors={filtersFormName => this.props.dispatch(AgreementActions.fetchGuarantors(filtersFormName))}
            debtorsDict={this.props.debtorsDict}
            fetchDebtorsDict={filtersFormName => this.props.dispatch(AgreementActions.fetchDebtorsDict(filtersFormName))}
            archive={(type, id) => this.props.dispatch(AgreementActions.archive(this.props.agreement.id, this.props.version.id, type, id))}
          />
        );
      case 5:
        return (
          <Complaints
            common={this.props.common}
            version={this.props.version}
            agreement={this.props.agreement}
            saveForm={formName => this.save(formName, true)}
            remove={(uri, id) => this.remove(uri, id)}
            toggleForm={f => this.toggleForm(f)}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            edit={(n, o) => this.edit(o, n)}
            forms={this.props.forms}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            fetchClaims={f => this.props.dispatch(AgreementActions.fetchClaimsForAgreement(f))}
            claims={this.props.claims}
            launch={(formName, annexId, send = false) => this.launch(formName, annexId, send)}
            user={this.props.user}
          />
        );
      default:
        return null;
    }
  }

  create(name) {
    this.props.dispatch(FormActions.initialize(name, {}, {}));
  }

  showForm(formName, object) {
    // this.edit(object, formName);
    this.props.dispatch(FormActions.initialize(formName, object, {}));
    this.props.dispatch(FormActions.toggleForm(formName));
  }

  edit(object, name, background) {
    this.props.dispatch(FormActions.initialize(name, object, {}));
    if (background) return;
    this.props.dispatch(FormActions.toggleForm(name));
  }

  removeNotes(note) {
    this.props.dispatch(AgreementActions.removeNote(this.props.agreement?.id, note.id));
  }

  toggleForm(formName) {
    this.props.dispatch(FormActions.toggleForm(formName));
  }

  remove(uri, id) {
    this.props.dispatch(AgreementActions.remove(this.props.agreement.id, uri, id));
  }

  changeTab(tab) {
    this.props.dispatch(AgreementActions.changeTab(tab));
  }

  save(formName, hide = true) {
    this.props.dispatch(AgreementActions.saveForm(formName, this.props.agreement.id, hide));
  }

  updateRepresentation(formName) {
    const f = this.props.forms.forms?.[formName];
    const updateVersionForm = 'UpdateAgreementVersion';
    let field;
    let makeRequest = false;

    switch (formName) {
      case 'OaRepresentation':
        field = 'oa_representation_id';
        makeRequest = f.oa_people?.length > 0;
        break;
      case 'ClientRepresentation':
        field = 'client_representation_id';
        makeRequest = f.client_people?.length > 0;
        break;
      case 'FactorRepresentation':
        field = 'factor_representation_id';
        makeRequest = f.factor_people?.length > 0;
        break;
      default:
        break;
    }

    if (f[field]) {
      this.props.dispatch(
        FormActions.initialize(updateVersionForm, {
          [field]: f[field]
        })
      );
      this.save(updateVersionForm, false);
    }

    if (makeRequest) {
      this.save(formName, false);
    }
  }

  generateClaim() {
    const { agreement, version, history, dispatch } = this.props;
    const preloadedForm = {
      agreement,
      client: agreement.client,
      debtors: version.agreement_debtors,
      trade_supervisor: agreement.trade_supervisor,
      operational_administrator: agreement.operational_administrator,
      billing_manager: agreement.billing_manager,
      purchase_currency_id: agreement.currency?.id
    };
    dispatch(
      FormActions.initialize(
        'CreateClaimForm',
        preloadedForm,
        {}
      )
    );
    this.toggleForm('CreateClaimForm');
    history.push('/wierzytelnosci');
  }

  render() {
    if (
      (!this.props.version || !this.props.version.id) &&
      this.props.agreement.agreement_versions &&
      this.props.agreement.agreement_versions.length > 0
    ) {
      const version = this.props.agreement.agreement_versions.find(x => x.status === 1) ?? this.props.agreement.agreement_versions.slice(-1)[0];
      this.props.dispatch(AgreementActions.selectVersion(version));
    }

    return (
      <Container className="mt-3">
        <LoaderBackdrop />

        <MainContainer>
          <Header
            agreement={this.props.agreement}
            version={this.props.version}
            statuses={this.props.common.agreementData?.agreement_statuses ?? {}}
            accept={() => this.props.dispatch(AgreementActions.accept(this.props.agreement.id, this.props.version.id))}
            approve={() => this.props.dispatch(AgreementActions.approve(this.props.agreement.id))}
            active={() => this.props.dispatch(AgreementActions.active(this.props.agreement.id))}
            generateClaim={() => this.generateClaim()}
            onShowAnnexesForm={() => this.showForm('AnnexesForm', {})}

            onClickRestructure={() => this.props.dispatch(AgreementActions.updateStatusRestructure(this.props.agreement.id))}
            onClickConclude={() => this.props.dispatch(AgreementActions.updateStatusConclude(this.props.agreement.id))}
            onClickToEdit={() => this.props.dispatch(AgreementActions.updateStatusToEdit(this.props.agreement.id))}
          />

          <Versions
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            agreement={this.props.agreement}
            version={this.props.version}
            selectVersion={v => this.props.dispatch(AgreementActions.selectVersion(v))}
            removeVersion={v => this.props.dispatch(AgreementActions.removeVersion(this.props.agreement?.id, 'agreement_versions', v.id))}
          />

          <Tabs tab={this.props.tab} changeStep={tab => this.changeTab(tab)} steps={this.steps} />

          {this.getMainComponent()}
        </MainContainer>
        <SidebarDetails
          Notes={
            <Notes
              name={this.props.agreement?.identifier}
              notes={
              this.props.agreement?.notes?.map((n) => ({
                created_date: n.created_date,
                modified_date: n.modified_date,
                avatar: n.user_creator ? n.user_creator.avatar_url : '',
                creator: n.user_creator ? n.user_creator.full_name : 'brak',
                comment: n.comment,
                name: n.name,
                id: n.id
              })) ?? []
              }
              forms={this.props.forms?.forms}
              toggleForm={f => this.toggleForm(f)}
              formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
              saveForm={formName => this.save(formName, true)}
              create={(n, o) => this.edit(o, n)}
              edit={(n, o) => this.edit(o, n)}
              remove={this.removeNotes}
            />
          }
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  forms: state.Forms,
  options: state.Options,
  factors: state.Agreement.factors,
  formData: state.Agreement.formData,
  agreement: state.Agreement.agreement,
  version: state.Agreement.version,
  common: state.Common,
  tab: state.Agreement.currentTab,
  intrestRates: state.Offer.intrestRates,
  beneficiaries: state.Offer.beneficiaries,
  proposalFormData: state.Proposal.formData,
  companies: state.Company,
  guarantors: state.Agreement.guarantors,
  claims: state.Agreement.claims,
  debtorsDict: state.Agreement.debtorsDict,
  user: state.Authorization.user
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Index)
);
