import CommonActions from 'actions/common';
import FormActions from 'actions/forms';
import { OldCard } from 'components/Layout';
import Loader from 'components/Loader';
import { SidebarNotes } from 'components/SidebarNotes';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parsePhone } from 'utilities/helpers';

import RemovalModal from '../Forms/RemovalModal';

class SidebarView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { objectToRemove: {} };
  }

  editPerson = () => {
    this.props.dispatch(FormActions.initialize('PersonsForm', this.props.person, {}));
    this.props.dispatch(CommonActions.toggleModal('PersonsForm'));
  };

  removePerson = () => {
    this.props.dispatch(CommonActions.toggleModal('removePerson'));
  };

  setObjectToRemove = object => {
    if (this.state.objectToRemove.id) {
      this.setState({ objectToRemove: {} });
    } else {
      this.setState({ objectToRemove: object });
    }
  };

  render() {
    const { loading, person } = this.props;
    if (loading) return <Loader />;
    if (!person) return <div />;

    return (
      <OldCard className="mt-3 pt-3 pb-3">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="font-weight-bold">
              {person.full_name}
              <span className="float-right text-themecolor" style={{ cursor: 'pointer' }}>
                <i className="fas fa-sign-in-alt mr-2" onClick={() => this.props.history.push(`/osoby/${person.slug}`)} />
                <i className="fa fa-trash" style={{ color: 'red' }} onClick={() => this.setObjectToRemove(this.props.person)} />
              </span>
            </h3>
          </div>
          <div className="col-sm-12 mt-3 mb-3">
            <button type="button" className="btn btn-sm btn-success mr-3">
              <i className="fa fa-envelope mr-2" />
              Wiadomość
            </button>
            <button type="button" className="btn btn-sm btn-success mr-3">
              <i className="fa fa-link mr-2" />
              Powiąż
            </button>
            <button type="button" className="btn btn-sm btn-success">
              <i className="fa fa-envelope mr-2" />
              Zadanie
            </button>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-12">
            <h3 className="card-title text-themecolor">Firmy powiązane</h3>

            {person.companies?.length === 0 ? (
              <span>Brak powiązanych firm.</span>
            ) : (
              person.companies?.map((c, k) => (
                <div key={k} className=" justify-content-between align-items-center mb-1">
                  <div key={c.id} className="d-block-inline">
                    <div style={{ float: 'left' }}>{c.company?.name}</div>
                    <div style={{ float: 'right' }}>
                      {c.roles.map(r => (
                        <span key={r} className="badge badge-warning mr-2 pl-3 pr-3 pt-2 pb-2">
                          {r.name}
                        </span>
                      ))}
                    </div>
                    <div style={{ clear: 'both' }} />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            {!!person?.system_roles?.length && <h3 className="card-title text-themecolor">Rola systemowa</h3>}
            <div>
              {person.system_roles?.map(s => (
                <span key={s} className="badge badge-warning mr-2 pl-3 pr-3 pt-2 pb-2 mb-3">
                  {s.name}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className="rows-bordered">
          <div className="row p-1">
            <div className="col-sm-6 text-left">Telefon</div>
            <div className="col-sm-6 text-right">{parsePhone(person.active_phone)}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left">Email</div>
            <div className="col-sm-6 text-right">{person.active_email}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left">Adres korespondencyjny</div>
            <div className="col-sm-6 text-right">
              {person.active_correspondence_address
                ? `${person.active_correspondence_address?.street ?? ''} ${person.active_correspondence_address?.building_no ?? ''}/${person
                  .active_correspondence_address?.local_no ?? ''} ${person.active_correspondence_address?.zipcode ?? ''} ${person
                    .active_correspondence_address?.city ?? ''}`
                : 'brak'}
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-sm-12">
            <h3 className="card-title text-themecolor">Notatki</h3>
            <SidebarNotes object={this.props.person} />
          </div>
        </div>

        <RemovalModal
          visible={Object.keys(this.state.objectToRemove).length > 0}
          toggle={() => this.setObjectToRemove({})}
          remove={() => {
            this.props.remove(this.state.objectToRemove.id);
            this.setObjectToRemove({});
          }}>
          {this.state.objectToRemove && (
            <div className="container-fluid">
              <div className="row">Czy na pewno chcesz usunąć tę osobę?</div>
            </div>
          )}
        </RemovalModal>
      </OldCard>
    );
  }
}

export default withRouter(connect()(SidebarView));
