import moment from 'moment';

const claims = service => ({
  getClaimsWithActiveAgreements: ({ client_id, page = 1, limit = 20 } = {}) => async () =>
    service.get('claims/clients_with_active_agreements/', {
      params: {
        page,
        limit,
        client_id
      }
    }),
  changeDueStatus: ({ id, due_status }) => async () =>
    service.put(`claims/${id}/due_status/`, {
      params: {
        due_status
      }
    }),
  exchangeRate: ({ value, currency_id, is_nbp = false, is_mbank = false }) => async () =>
    service.post('/commons/exchange_rate/', {
      value,
      date: moment().format('DD.MM.YYYY'),
      currency_id,
      is_nbp,
      is_mbank
    })
});

export default claims;
