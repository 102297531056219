import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AgreementActions from '../../../actions/agreement';
import ClaimActions from '../../../actions/claim';
import CommonActions from '../../../actions/common';
import FormActions from '../../../actions/forms';
import { Buttons } from '../../../components/Forms';
import { Container, MainContainer, SidebarContainer } from '../../../components/Layout';
import Constants from '../../../utilities/constants';
import { SidebarNotes } from '../SidebarNotes';
import Advance from './Components/Advance';
import Bids from './Components/Bids';
import Documents from './Components/Documents';
import Params from './Components/Params';
import Loader from 'components/Loader';

class Index extends React.Component {
  steps = [{ id: 1, name: 'Parametry umowy' }, { id: 2, name: 'Wybór dłużników' }, { id: 3, name: 'Generowanie dokumentów' }];

  paramsFormName = 'ClaimParamsForm';

  bidsFormName = 'ClaimBidsForm';

  constructor(props) {
    super(props);
    const { slug } = this.props.match.params;
    const id = slug ? slug.split('-').slice(-1)[0] : null;
    this.props.dispatch(ClaimActions.fetchDetails(id));

    this.props.dispatch(CommonActions.changeView(Constants.Views.Creator));

    this.props.dispatch(FormActions.initialize(this.bidsFormName, { bids: [] }, {}));
  }

  componentWillUnmount() {
    this.props.dispatch(FormActions.clearAllForms());
  }

  create(name) {
    this.props.dispatch(FormActions.initialize(name, {}, {}));
  }

  edit(object, name, background) {
    this.props.dispatch(FormActions.initialize(name, object, {}));
    if (background) return;
    this.props.dispatch(FormActions.toggleForm(name));
  }

  toggleForm(formName) {
    this.props.dispatch(FormActions.toggleForm(formName));
  }

  remove(uri, id) {
    this.props.dispatch(ClaimActions.remove(this.props.claim.id, uri, id));
  }

  changeTab(tab) {
    this.props.dispatch(AgreementActions.changeTab(tab));
  }

  save(formName, hide = true) {
    this.props.dispatch(ClaimActions.saveForm(formName, this.props.claim.id, hide));
  }

  saveDebtors(formName, hide = true) {
    this.props.dispatch(AgreementActions.saveDebtors(formName, this.props.claim.id, hide));
  }

  formVisible(f) {
    return this.props.forms.options.visible.indexOf(f) !== -1;
  }

  render() {
    const isLoadingState = !this.props.claim?.id;

    if(isLoadingState) {
      return <Loader />;
    }

    return (
      <Container className="mt-3">
        <MainContainer className='w-full max-w-1400 mx-auto'>
          <Params
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            common={this.props.common}
            claim={this.props.claim}
            forms={this.props.forms}
            formName={this.paramsFormName}
            saveForm={formName => this.save(formName)}
            populateForm={(f, d) => this.props.dispatch(FormActions.initialize(f, { ...d }, {}))}
          />

          {/* Wybierz stawkę */}
          <Bids
            claimData={this.props.common?.claimData}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            formVisible={() => false}
            claim={this.props.claim}
            forms={this.props.forms.forms}
            saveForm={formName => this.save(formName)}
            formName={this.bidsFormName}
            form={this.props.forms.forms[this.bidsFormName]}
            populateForm={(f, d) => this.props.dispatch(FormActions.initialize(f, { ...d }, {}))}
          />

          <Advance
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            formVisible={f => this.formVisible(f)}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            claim={this.props.claim}
            forms={this.props.forms}
            formName={this.paramsFormName}
            saveForm={(formName, h) => this.save(formName, h)}
            populateForm={(f, d) => this.props.dispatch(FormActions.initialize(f, { ...d }, {}))}
            toggleForm={f => this.toggleForm(f)}
            common={this.props.common}
          />

          <Documents
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            remove={(u, id) => this.remove(u, id)}
            edit={(n, o, p) => this.edit(o, n, p)}
            toggleForm={f => this.toggleForm(f)}
            visible={f => this.formVisible(f)}
            formVisible={f => this.formVisible(f)}
            claim={this.props.claim}
            forms={this.props.forms}
            saveForm={formName => this.save(formName, false)}
            common={this.props.common}
            agreementData={this.props.common?.agreementData}
            refresh={() => this.props.dispatch(ClaimActions.fetchDetails(this.props.claim.id))}
            resetDocs={() => this.props.dispatch(ClaimActions.resetDocumentsToBuy(this.props.claim.id))}
            generate={(formName, obj, attachmentId, action, deleting, update) =>
              this.props.dispatch(ClaimActions.handleFile(formName, obj, attachmentId, action, deleting, update))
            }
          />

          <Buttons
            className="mb-3"
            // onCancel={() => (window.location.href = '/oferty/')}
            // onRemove={() => this.remove(null,null)}
            onCancel={() => this.changeTab(this.props.tab < 3 ? this.props.tab + 1 : this.props.tab - 1)}
            cancelButton={this.props.tab < 3 ? 'Dalej' : 'Wstecz'}
            onSubmit={() => {
              window.location.href = `/wierzytelnosci/${this.props.claim.slug}`;
            }}
            submitButton="Dalej"
          />
        </MainContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  forms: state.Forms,
  common: state.Common,
  claim: state.Claim.claim
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Index)
);
