import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { OldCard } from '../../../components/Layout';
import { parseMoney, formatNip, parsePhone } from '../../../utilities/helpers';
import RemovalModal from '../Forms/RemovalModal';
import { SidebarNotes } from '../SidebarNotes';
import Tooltip from 'components/Layout/Tooltip';

class AgreementSideBarView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { objectToRemove: {} };
  }

  setObjectToRemove = object => {
    if (this.state.objectToRemove.id) {
      this.setState({ objectToRemove: {} });
    } else {
      this.setState({ objectToRemove: object });
    }
  };

  render() {
    const preview = this.props.agreement.preview || {};
    // const { offer, loading } = this.props;
    const client = preview.client || {};
    const a = client.active_address && Object.keys(client.active_address).length > 0 ? client.active_address : null;

    return (
      <OldCard className="mt-3 pt-3 pb-3">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="font-weight-bold text-themecolor">
              {preview.identifier}
              <span className="float-right text-themecolor" style={{ cursor: 'pointer' }}>
                <i className="fas fa-sign-in-alt mr-2" onClick={() => this.props.history.push(`/umowy/${preview.slug}`)} />

                <i className="fa fa-trash" style={{ color: 'red' }} onClick={() => this.setObjectToRemove(preview)} />
              </span>
            </h3>
          </div>
        </div>
        <div className="mb-2">
          <div className="row">
            <div className="col-sm-12 text-left grey">
              Kwota limitu: {parseMoney(preview.limit_amount)} {preview.currency?.name}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 mt-3 mb-3">
            {/*<button type="button" className="btn btn-sm btn-success" onClick={() => this.props.generateHtml(agreement.id)}>*/}
            {/*  Generuj HTML*/}
            {/*</button>*/}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h4 className="text-themecolor mt-2 mb-2">Opiekunowie</h4>
          </div>
        </div>
        <div className="row mb-4 mt-1">
          <div className="col d-flex align-items-center">
            <ReactTooltip />
            <img
              src="/user.png" width="50" height="50"
              alt=""
              className="img-circle mr-2"
              data-tip={`Administrator operacyjny: ${preview.operational_administrator?.name ?? '-'}`}
            />
            <img
              src="/user.png" width="50" height="50"
              alt=""
              className="img-circle mr-2"
              data-tip={`Opiekun ds. rozliczeń: ${preview.billing_manager?.full_name ?? '-'}`}
            />
            <img
              src="/user.png" width="50" height="50"
              alt=""
              className="img-circle"
              data-tip={`Opiekun handlowy: ${preview.trade_supervisor?.full_name ?? '-'}`}
            />
          </div>
        </div>

        <div className="rows-bordered mt-1">
          <div className="row p-1">
            <div className="col-6">
              <h4 className="text-themecolor mt-2 mb-2">Status umowy</h4>
            </div>
            <div className="col-sm-6 text-right">
              <span className="badge badge-warning mt-2 mb-2 pt-1 pb-1 pl-2 pr-2">{preview.status ? 'zatwierdzona' : 'niezatwierdzona'}</span>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Faktor</div>
            <div className="col-sm-6 text-right">{preview.factor ? preview.factor.name : '-'}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Produkt</div>
            <div className="col-sm-6 text-right">{preview.product ? preview.product.name : '-'}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Procedura</div>
            <div className="col-sm-6 text-right">{preview.procedure ? preview.procedure.name : '-'}</div>
          </div>

          <div className="row p-1">
            <div className="col-6">
              <h4 className="text-themecolor mt-2 mb-2">Wniosek</h4>
            </div>
            {/* <div className="col-sm-6 text-right"> */}
            {/*  <span className="badge badge-warning mt-2 mb-2 pt-1 pb-1 pl-2 pr-2"> */}
            {/*    {agreement.status ? 'zatwierdzona' : 'niezatwierdzona'} */}
            {/*  </span> */}
            {/* </div> */}
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Numer wniosku</div>
            <div className="col-sm-6 text-right"><Tooltip label={preview?.proposal?.identifier} /></div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Wersja wniosku</div>
            <div className="col-sm-6 text-right">1</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Status wniosku</div>
            <div className="col-sm-6 text-right">
              <span className="badge badge-warning mt-2 mb-2 pt-1 pb-1 pl-2 pr-2">
                zatwierdzony
                {/* {agreement?.proposal?.status ? 'zatwierdzony' : 'niezatwierdzony'} */}
              </span>
            </div>
          </div>

          <div className="row p-1">
            <div className="col-12">
              <h4 className="text-themecolor mt-2 mb-2">Klient</h4>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-sm-4 text-left grey">Nazwa firmy</div>
            <div className="col-sm-8 text-right">{client.name}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">NIP</div>
            <div className="col-sm-6 text-right">{formatNip(client.nip)}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-4 text-left grey">Forma prawna</div>
            <div className="col-sm-8 text-right">{client.legal_form ? client.legal_form.name : '-'}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Telefon</div>
            <div className="col-sm-6 text-right">{parsePhone(client.active_phone)}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Email</div>
            <div className="col-sm-6 text-right">{client.active_email}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-4 text-left grey">Adres siedziby</div>
            <div className="col-sm-8 text-right">
              {a ? `${a.street} ${a.local_no ? `${a.building_no}/${a.local_no}` : a.building_no}, ${a.city}` : '-'}
            </div>
          </div>

          <div className="row p-1">
            <div className="col-12">
              <h4 className="text-themecolor mt-2 mb-2">Bieżąca umowa</h4>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Wierzytelności spłacone</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Wierzytelności niespłacone</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Wierzytelności terminowe</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Wierzytelności przeterminowane</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Dłużników</div>
            <div className="col-sm-6 text-right">0</div>
          </div>

          <div className="row p-1">
            <div className="col-12">
              <h4 className="text-themecolor mt-2 mb-2">Zaangażowanie</h4>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Zaangażowanie aktualne</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Według produktów</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Zaangażowanie w przychody</div>
            <div className="col-sm-6 text-right">0</div>
          </div>

          <div className="row p-1">
            <div className="col-12">
              <h4 className="text-themecolor mt-2 mb-2">Współpraca</h4>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Kategoria faktoranta</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Rozpoczęcie współpracy</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Aktywne umowy</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Ilość zagrożonych umów</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Ilość umów w windykacji</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Wierzytelności spłacone</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Wierzytelności niespłacone</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Wierzytelności przeterminowane</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Nowych dłużników</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Obsługiwanych dłużników</div>
            <div className="col-sm-6 text-right">0</div>
          </div>

          <div className="row p-1">
            <div className="col-12">
              <h4 className="text-themecolor mt-2 mb-2">Rentowność</h4>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Dane na dzień</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">NPV</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Marża brutto</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Zrealizowany obrót</div>
            <div className="col-sm-6 text-right">0</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">CLV</div>
            <div className="col-sm-6 text-right">0</div>
          </div>

          <div className="row p-1">
            <div className="col-12">
              <h4 className="text-themecolor mt-2 mb-2">Notatki</h4>
              <SidebarNotes offer={preview} />
            </div>
          </div>
        </div>

        <RemovalModal
          visible={Object.keys(this.state.objectToRemove).length > 0}
          toggle={() => this.setObjectToRemove({})}
          remove={() => {
            this.props.remove(this.state.objectToRemove.id);
            this.setObjectToRemove({});
          }}>
          {this.state.objectToRemove && (
            <div className="container-fluid">
              <div className="row">Czy na pewno chcesz usunąć tę ofertę?</div>
            </div>
          )}
        </RemovalModal>
      </OldCard>
    );
  }
}

export default withRouter(AgreementSideBarView);
