import { DatepickerButton, Selectable, SelectButton } from 'components/Forms';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeInput } from 'reducers/forms';

const columns = [{ name: 'Imię i nazwisko', getter: 'name' }];

export const ConsentForm = props => {
  const { user } = useSelector(state => state.Authorization);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(storeInput(props.formName, 'administrator', user?.factor?.administrator));
    dispatch(storeInput(props.formName, 'representator', user?.factor?.representator));
    dispatch(storeInput(props.formName, 'data_inspector', user?.factor?.data_inspector));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="form-horizontal">
      <div className="row mt-3">
        <div className="col-sm-12">
          <div className="form-group row ">
            <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
              Data zgody
            </label>
            <div className="col-sm-7 d-flex align-items-center">
              <DatepickerButton buttonOnClick={props.buttonOnClick} formName={props.formName} name="date" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row">
            <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
              Rodzaj zgody
            </label>
            <div className="col-sm-3 d-flex align-items-center">
              <SelectButton
                buttonOnClick={() => props.clear(props.formName, 'consent_template_id')}
                buttonDisabled={props.selectButtonDisabled}
                formName={props.formName}
                options={props.templates}
                name="consent_template_id"
                value={props.form ? props.form.consent_template_id : null}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row">
            <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
              Źródło zgody
            </label>
            <div className="col-sm-3 d-flex align-items-center">
              <SelectButton
                buttonOnClick={() => props.clear(props.formName, 'consent_source_id')}
                buttonDisabled={props.selectButtonDisabled}
                formName={props.formName}
                options={props.sources}
                name="consent_source_id"
                value={props.form ? props.form.consent_source_id : null}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
              Administrator danych
            </label>
            <div className="col-sm-7 d-flex align-items-center">
              <span className="mr-3" style={{ fontWeight: '600', color: '#4B4E55', fontSize: '14px' }}>
                {props?.form?.administrator?.name ?? 'nie wybrano'}
              </span>
              <button id="btn-oa" type="button" className="float-right" onClick={() => props.toggleModal('administrator')}>
                Zmień
              </button>
            </div>
          </div>
        </div>
      </div>
      {props.simple ? null : (
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group row">
              <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
                Przedstawiciel administratora
              </label>
              <div className="col-sm-7  d-flex align-items-center">
                <span className="mr-3" style={{ fontWeight: '600', color: '#4B4E55', fontSize: '14px' }}>
                  {props?.form?.representator?.name ?? 'nie wybrano'}
                </span>
                <button id="btn-representator" type="button" className="float-right" onClick={() => props.toggleModal('representator')}>
                  Zmień
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.simple ? null : (
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group row">
              <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
                Inspektor danych
              </label>
              <div className="col-sm-7 d-flex align-items-center">
                <span className="mr-3" style={{ fontWeight: '600', color: '#4B4E55', fontSize: '14px' }}>
                  {props?.form?.data_inspector?.name ?? 'nie wybrano'}
                </span>
                <button id="btn-di" type="button" className="float-right" onClick={() => props.toggleModal('data_inspector')}>
                  Zmień
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {props.withButtons ? (
        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button
              type="button"
              onClick={props.onCancelButtonClick}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}>
              Anuluj
            </button>
            <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
              Zapisz
            </button>
          </div>
        </div>
      ) : null}

      <Selectable
        visible={props.isVisible('administrator')}
        formName={props.formName}
        field="administrator"
        columns={columns}
        options={props.administrators}
        onClickAway={() => props.toggleModal('administrator')}
        header="Wybierz administratora danych"
        footer=""
      />
      <Selectable
        visible={props.isVisible('representator')}
        formName={props.formName}
        field="representator"
        columns={columns}
        options={props.representators}
        onClickAway={() => props.toggleModal('representator')}
        header="Wybierz przedstawiciela administratora"
        footer=""
      />
      <Selectable
        visible={props.isVisible('data_inspector')}
        formName={props.formName}
        field="data_inspector"
        columns={columns}
        options={props.inspectors}
        onClickAway={() => props.toggleModal('data_inspector')}
        header="Wybierz inspektora danych"
        footer=""
      />
    </div>
  );
};
