import { BaseModal } from 'components/Layout/OldModal';
import { isEditableProposal } from 'containers/Proposals/Details/Index';
import React from 'react';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import { formatValueToEurope } from '../../../../utilities/helpers';
import FinancialDataForm from '../../Forms/FinancialData';

export default (props) => {
  const modalFormName = 'FinancialDataCreatorEditForm';
  const rows = props.version && props.version.financial_data ? props.version.financial_data : [];

  return (
    <>
      <div className="row mb-1">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12 p-2 text-themecolor">
              Dane finansowe

              <span className="float-right" style={{ cursor: 'pointer' }}>
                <i className="fa fa-plus ml-2" onClick={() => props.toggleForm(modalFormName)} />
              </span>
            </div>
          </div>
          <div className="row bb bt">
            <div className="col-sm-3 p-2 br grey">Rok</div>
            <div className="col-sm-3 p-2 br grey">Ilość miesięcy</div>
            <div className="col-sm-3 p-2 br grey">Przychód</div>
            <div className="col-sm-3 p-2 grey">Dochód (-strata)</div>
          </div>
          {rows.length ? rows.map((row) => (
            <RowEditable
              key={row.identifier}
              isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
              onEdit={() => {
                props.edit(row, modalFormName);
              }}
              onRemove={() => {
                props.remove('financial_data', row.id);
              }}
            >
              <div className="col-sm-3 p-2 br ">{row.year}</div>
              <div className="col-sm-3 p-2 br ">{row.months_no}</div>
              <div className="col-sm-3 p-2 br ">{formatValueToEurope(row.income)}</div>
              <div className="col-sm-3 p-2 ">{formatValueToEurope(row.revenue)}</div>
            </RowEditable>
          )) : <div className="row bb"><div className="col p-2"><span>Brak danych.</span></div></div>}
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Dane finansowe</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}
      >
        <FinancialDataForm
          withButtons
          populate={() => props.populateForm(modalFormName)}
          forms={props.forms}
          currencies={props.currencies}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(modalFormName)}
          formName={modalFormName}
          submitForm={() => props.saveForm(modalFormName)}
          onCancelButtonClick={() => props.toggleForm(modalFormName)}
        />
      </BaseModal>
    </>
  );
};
