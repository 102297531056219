import { useEffect, useState } from 'react';

export default function useContainers(data) {
  const [currentObject, setCurrentObject] = useState(null);

  const [orderedColumn, setOrderedColumn] = useState(null);
  const [orderByAsc, setOrderByAsc] = useState(true);

  useEffect(() => {
    setCurrentObject(data?.results?.find((item, key) => key === 0));
    // update Redux to maintain backward compatibility
    // dispatch(updatePersons(data));
  }, [data]);

  const handleShowPreview = id => {
    setCurrentObject(data?.results?.find(item => item.id === id));
  };

  const handleSortTable = columnNumber => {
    setOrderedColumn(columnNumber);
    setOrderByAsc(prev => !prev);
  };

  return {
    currentObject,
    orderedColumn,
    orderByAsc,
    handleShowPreview,
    handleSortTable
  };
}
