import React from 'react';

import Documents from '../Components/Documents';

export default props => (
  <>
    <Documents
      forms={props.forms}
      remove={props.remove}
      saveForm={props.saveForm}
      version={props.version}
      claim={props.claim}
      edit={props.edit}
      offerData={props.offerData}
      proposalData={props.common?.proposalData}
      agreementData={props.common?.agreementData}
      claimData={props.common?.claimData}
      common={props.common}
      formVisible={props.formVisible}
      toggleForm={props.toggleForm}
      removeAttachment={props.remove}
      storeInput={props.storeInput}
      refresh={props.refresh}
      resetDocs={props.resetDocs}
      generate={props.generate}
    />
  </>
);
