import FormActions from 'actions/forms';
import Card from 'components/Layout/Card';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RiskForm from '../../Forms/Risk';

export default props => {
  const dispatch = useDispatch();
  const { version } = useSelector(state => state.Proposal);
  const formName = 'RiskCreatorForm';

  useEffect(() => {
    dispatch(FormActions.initialize(formName, {
      rating_type_id: version?.rating_type?.id,
      rating_class_id: version?.rating_class?.id,
      information_agency_id: version?.information_agency?.id,
      information_agency_class_id: version?.information_agency_class?.id,
      information_agency_pmi: version?.information_agency_pmi,
      information_agency_limit: version?.information_agency_limit,
      information_agency_report_date: version?.information_agency_report_date,
      category_id: version?.category?.id,
      rating_agency_id: version?.rating_agency?.id,
      is_jst_or_corporate_treasury: version?.is_jst_or_corporate_treasury,
      rating_agency_score_id: version?.rating_agency_score?.id,
      is_bankruptcy_or_liquidation: version?.is_bankruptcy_or_liquidation
    }));
  }, []);

  return (
    <Card mode='creator' header='+ Ryzyko faktoranta' onSaveClick={() => props.saveForm(formName, true)}>
      <div />
      <div className='pt-20'>
        <RiskForm
          populate={() => props.populateForm(formName)}
          forms={props.forms}
          proposalData={props.proposalData}
          banks={props.banks}
          preloadedForm
          visible
          visibleOnMount
          clear={props.clear}
          formName={formName}
          submitForm={() => props.saveForm(formName)}
          onCancelButtonClick={() => props.toggleForm(formName)}
        />
      </div>
    </Card>
  );
};
