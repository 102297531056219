import React from 'react';

import { OldCard } from '../../../../components/Layout';
import { toast } from 'react-toastify';
import { t } from 'lang/pl';

export const Header = (props) => {
  const company = props.company || {};

  const header = (
    <span className="mb-0 font-weight-light" style={{ color: '#006BFF', fontSize: '32px' }}>
      {`${company.name}`}
    </span>
  );

  return (
    <OldCard header={header} contextMenu={() => toast.error(t.error.toast.notImplemented)}>
      <div className="row mb-1 mt-3">
        <div className="col-sm-6 col-md-3 text-right grey">ID obiektu</div>
        <div className="col-sm-6 col-md-8">{company.identifier}</div>
      </div>

      <div className="row mt-3 mb-1 pt-2" style={{ borderTop: '1px solid #DFE2EA' }}>
        <div className="col-sm-6 col-md-3 text-right grey">Utworzył</div>
        <div className="col-sm-6 col-md-8">{company.user_creator ? company.user_creator.full_name : '-'}</div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-6 col-md-3 text-right grey">Utworzony dnia</div>
        <div className="col-sm-6 col-md-8">{company.created_date}</div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-6 col-md-3 text-right grey">Ostatnia aktualizacja</div>
        <div className="col-sm-6 col-md-8">{company.modified_date}</div>
      </div>
    </OldCard>
  );
};
