import { ColName } from 'components/Forms/Col';
import React from 'react';

import {
  Checkbox,   Form, Input, Select
} from '../../../components/Forms';

const ConditionsForm = (props) => (
  <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
    {props.visible ? (
      <>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="form-group row  align-items-center">
              <ColName className='col-sm-3 text-right'>
                Max termin wymagalności
              </ColName>
              <div className="col-sm-6">
                <Input formName={props.formName} type="text" name="max_maturity" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group row  align-items-center">
              <ColName className='col-sm-3 text-right'>
                Max okres finansowania
              </ColName>
              <div className="col-sm-6">
                <Input formName={props.formName} type="text" name="max_financing_period" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group row  align-items-center">
              <ColName className='col-sm-3 text-right'>
                Limit na pojedynczą wierzytelność
              </ColName>
              <div className="col-sm-4">
                <Input formName={props.formName} type="text" name="claim_limit" />
              </div>
              {/* <div className="col-sm-2"> */}
              {/*  <span className="mr-3" style={{ fontWeight: '600', color: 'rgb(75, 78, 85)', fontSize: '14px' }}> */}
              {/*    {props.calculation.currency ? props.calculation.currency.name : 'brak'} */}
              {/*  </span> */}
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group row  align-items-center">
              <ColName className='col-sm-3 text-right'>
                Metoda spłaty kapitału
              </ColName>
              <div className="col-sm-6">
                <Select formName={props.formName} options={props.repaymentOfCapital} name="repayment_of_capital" number />
              </div>
            </div>
          </div>
        </div>
        <div className="row  ">
          <div className="col-sm-12">
            <div className="form-group row  align-items-center">
              <div className="col-sm-3 col-form-label label-right">Podzielona płatność</div>
              <div className="col-sm-6">
                <Checkbox
                  className="custom-control custom-checkbox d-flex"
                  label=""
                  formName={props.formName}
                  name="split_payment"
                />
              </div>
            </div>
          </div>
        </div>

        {props.withButtons ? (
          <div className="row">
            <div className="mt-4 col-sm-12 text-center">
              <button
                type="button"
                onClick={props.onCancelButtonClick}
                className="btn btn-primary-outline mr-2 font-weight-light"
                style={{ color: '#4B4E55' }}
              >
                Anuluj
              </button>
              <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                Zapisz
              </button>
            </div>
          </div>
        ) : null}
      </>
    ) : null}
  </Form>
);

export default ConditionsForm;
