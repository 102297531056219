import React from 'react';

import { Commissions } from '../../../Offers/Creator/Components/Commissions';
// import Commissions from '../Components/Commissions';
import Administrator from '../Components/Administrator';
import Conditions from '../Components/Conditions';
// import TransactionSecures from '../Components/TransactionSecures';
import Readiness from '../Components/Readiness';
import Secures from '../Components/Secures';
import { Notes } from 'containers/Companies/Creator/Components/Notes';

const populateSecures = (f, data, store) => {
  const secures = data.factor_secure_documents || [];

  if (secures.length) {
    store(f, 'secure_action', 1);
    store(f, 'secures', secures.map(s => ({ ...s, dts_type_id: s.dts_type.id, dts_form_id: s.dts_form.id })));
  }
};

export default props => (
  <>
    <Conditions
      clear={props.clear}
      currencies={props.common.currencies}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
      offerData={props.offerData}
    />

    <Readiness
      countingMethods={
        props.offerData && props.offerData.counting_method ? props.offerData.counting_method.map(i => ({ value: i.id, name: i.name })) : []
      }
      clear={props.clear}
      currencies={props.common.currencies}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
      storeInput={props.storeInput}
      removeField={props.removeField}
    />

    <Administrator
      freqs={props.offerData && props.offerData.lump_sum_req ? props.offerData.lump_sum_req : []}
      clear={props.clear}
      currencies={props.common.currencies}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
    />

    <Commissions
      clearBeneficiaries={props.clearBeneficiaries}
      fetchBeneficiaries={props.fetchBeneficiaries}
      beneficiaries={props.beneficiaries}
      types={props.types}
      clear={props.clear}
      calculation={props.version}
      offer={props.offer}
      forms={props.forms}
      errors={props.errors}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      create={f => props.edit(f, { calculationId: 1 })}
      edit={props.edit}
      saveForm={props.saveForm}
      onCancel={f => props.toggleForm(f)}
      remove={obj => props.remove('commissions', obj.id)}
    />

    <Notes
      notes={props.proposal?.notes ?? []}
      forms={props.forms}
      toggleForm={props.toggleForm}
      showForm={props.toggleForm}
      formVisible={props.formVisible}
      create={props.edit}
      submitForm={props.saveForm}
      edit={props.edit}
      onCancel={f => props.toggleForm(f)}
      remove={id => props.removeNote(props.proposal?.id, id)}
    />

    <Secures
      transactions
      issuers={props.issuers}
      fetchIssuers={props.fetchIssuers}
      populateForm={formName => populateSecures(formName, props.proposal, props.storeInput)}
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      remove={props.remove}
      edit={props.edit}
      common={props.common}
      storeInput={props.storeInput}
    />
  </>
);
