import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import { isEditableProposal } from 'containers/Proposals/Details/Index';
import { t } from 'lang/pl';
import React from 'react';
import { toast } from 'react-toastify';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parseMoney } from '../../../../utilities/helpers';
import SecuresForm from '../../Forms/Secures';

export default props => {
  const formName = 'SecuresForm';

  const create = () => props.edit({ issuer: props.transactions ? {} : props.version.client }, formName);
  const edit = row => {
    props.edit(
      {
        ...row,
        dts_type_id: row.dts_type.id,
        dts_form_id: row.dts_form.id
      },
      formName
    );
  };

  const rows = props.transactions ? props.version?.transaction_secure_documents : props.version?.factorer_secure_documents;

  return (
    <Card
      header={props.transactions ? 'Transakcja zabezpieczenia' : 'Faktorant zabezpieczenia'}
      isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
      onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
      onAddClick={props.isReadOnly ? null : create}
    >
      <div>
        <div className="row bb">
          <div className="flex-30 p-2 br grey">Rodzaj</div>
          <div className="flex-10 max-w-150 p-2 br grey">Kwota</div>
          <div className="flex-10 max-w-150 p-2 br grey">Wartość</div>
          <div className="flex-12 max-w-150 p-2 br grey">Forma dokumentu</div>
          {props.transactions ? <div className="flex-30 p-2 br grey">Wystawiający</div> : null}
          <div className="flex-12 max-w-150 p-2 br grey">Klauzula</div>
          <div className="flex-10 max-w-150 p-2 br grey">Wzór faktoranta</div>
          <div className="flex-9 max-w-120 p-2 grey">Punkty / Max</div>
        </div>
        {rows?.map(row => (
          <RowEditable
            key={row.identifier}
            isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
            onEdit={props.isReadOnly ? null : () => edit(row)}
            onRemove={props.isReadOnly ? null : () => {
              props.remove('secure_documents', row.id);
            }}>
            <div className="flex-30 p-2 br ">
              {row.dts_type ? row.dts_type.name : '-'} {row.proposal_debtor ? ` - ${row.proposal_debtor.name}` : ''}
            </div>
            <div className="flex-10 max-w-150 p-2 br ">{parseMoney(row.current_debt)}</div>
            <div className="flex-10 max-w-150 p-2 br ">{parseMoney(row.collateral_value)}</div>
            <div className="flex-12 max-w-150 p-2 br ">{row.dts_form ? row.dts_form.name : ' -'}</div>
            {props.transactions ? <div className="flex-30 p-2 br ">{row.issuer?.name}</div> : null}
            <div className="flex-12 max-w-150 p-2 br ">{row.clauses ? row.clauses.map(c => c.name).join(', ') : '-'}</div>
            <div className="flex-10 max-w-150 p-2 br ">
              <BooleanCheck field={row.is_factor_pattern} />
            </div>
            <div className="flex-9 max-w-120 p-2 ">{`${row.my_score} / ${row.dts_type ? row.dts_type.score : 1}`}</div>
          </RowEditable>
        ))}
      </div>
      <BaseModal
        visible={props.formVisible(formName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Zabezpieczenia</span>
          </div>
        }
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}>
        <SecuresForm
          transactions={props.transactions}
          issuers={props.issuers}
          fetchIssuers={props.fetchIssuers}
          forms={props.forms}
          common={props.common}
          proposal={props.proposal}
          version={props.version}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(formName)}
          formVisible={props.formVisible}
          onCancelButtonClick={() => props.toggleForm(formName)}
          toggleForm={props.toggleForm}
          formName={formName}
          withButtons
          submitForm={() => props.saveForm(formName)}
        />
      </BaseModal>
    </Card>
  );
};
