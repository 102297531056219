import OrderBy from 'components/Forms/OldOrderBy';
import { BaseModal } from 'components/Layout/OldModal';
import { Pagination } from 'components/Layout/Pagination';
import Tooltip from 'components/Layout/Tooltip';
import ListFilters from 'components/OldListFilters';
import { OldSearchBar } from 'containers/OldSearchBar';
import { t } from 'lang/pl';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import CommonActions from '../../../actions/common';
import FormActions from '../../../actions/forms';
import ProposalActions from '../../../actions/proposal';
import { Container, MainContainer, SidebarContainer } from '../../../components/Layout';
import Constants from '../../../utilities/constants';
import { parseMoney } from '../../../utilities/helpers';
import Tab from '../../Tab';
import CreateProposalForm from '../Forms/Create';
import ProposalSidebarView from './Sidebar';

export const PROPOSALS_FILTERS = 'ProposalsFilters';
export const CREATE_PROPOSAL_FORM = 'CreateProposalForm';
export const DELETE_PROPOSAL_FORM = 'DeleteProposalForm';

class ProposalList extends React.Component {
  constructor(props) {
    super(props);

    this.filtersForm = PROPOSALS_FILTERS;
    this.createProposalForm = CREATE_PROPOSAL_FORM;
    this.deleteProposalForm = DELETE_PROPOSAL_FORM;

    this.props.dispatch(CommonActions.changeView(Constants.Views.List));
    this.props.dispatch(ProposalActions.fetch({ page: 1, limit: this.props.options.limit }));
  }

  componentDidMount() {
    this.props.dispatch(CommonActions.setContextMenu());
  }

  getFilters = () => {
    const currentFilters = this.props.forms.forms[this.filtersForm] || {};
    let params = {};
    let form = {};

    if (this.props.history.location.search) {
      const urlParams = new URLSearchParams(this.props.history.location.search.replace('?', ''));
      params = Object.fromEntries(urlParams);
    }

    if (!currentFilters || Object.keys(currentFilters).length <= Object.keys(CommonActions.defaultFilters).length) {
      form = { ...CommonActions.defaultFilters, ...params, ...currentFilters };
    } else {
      form = { ...currentFilters, ...params };
      if (!params.status && form.status) {
        delete form.status;
      }
    }

    this.props.dispatch(FormActions.initialize(this.filtersForm, form, {}));
    return form;
  };

  handleChangePage = (page, limit) => {
    this.props.dispatch(FormActions.storeInput(this.filtersForm, 'page', page));
    this.props.dispatch(FormActions.storeInput(this.filtersForm, 'limit', limit));
    this.fetchResults();
  };

  fetchResults = () => {
    this.props.dispatch(ProposalActions.fetch(this.getFilters()));
  };

  createProposal() {
    this.props.dispatch(FormActions.clearAllForms());
    this.props.dispatch(FormActions.initialize(this.createProposalForm, {}, {}));
    this.props.dispatch(FormActions.toggleForm(this.createProposalForm));
  }

  selectProposalForPreview(proposalVersion) {
    this.props.dispatch(ProposalActions.selectForPreview(proposalVersion));
  }

  saveForm() {
    const { push } = this.props.history;
    this.props.dispatch(ProposalActions.create(this.createProposalForm, push));
  }

  remove(id) {
    this.props.dispatch(ProposalActions.removeProposal(id));
  }

  toggleForm(f) {
    this.props.dispatch(FormActions.toggleForm(f));
  }

  formVisible(f) {
    return this.props.forms.options.visible.indexOf(f) !== -1;
  }

  render() {
    const rows = this.props.proposal.list || [];

    return (
      <Container>
        <MainContainer>
          <OldSearchBar title="Lista wniosków" formName={this.filtersForm} onSubmit={() => this.fetchResults()} records={rows.length} />

          <Tab name={Constants.Tabs.Proposals.name}>
            <div className="row mb-4 mt-3 align-items-center">
              <div className="col-sm-9">
                <ListFilters formName={this.filtersForm} onSubmit={() => this.fetchResults()} />
              </div>
              <div className="col-sm-3 text-right">
                <h4 className="text-themecolor" style={{ cursor: 'pointer' }}>
                  <i className="fa fa-ellipsis-h mr-2" onClick={() => toast.error(t.error.toast.notImplemented)} />
                  <i className="fa fa-plus" onClick={() => this.createProposal()} />
                </h4>
              </div>
            </div>
            <div className="row bt bb">
              <div className="col-sm-12">
                <div className="row grey " style={{ fontSize: '90%' }}>
                  <div className="flex-3 max-w-150 pl-2 pr-1 pt-2 pb-2 br ">
                    <OrderBy field="identifier" spanClassName="ml-0" column="ID" formName={this.filtersForm} onSubmit={this.fetchResults} />
                  </div>
                  <div className="flex-6 pl-2 pr-1 pt-2 pb-2 br">
                    <OrderBy field="client" spanClassName="ml-0" column="Klient" formName={this.filtersForm} onSubmit={this.fetchResults} />
                  </div>

                  <div className="flex-2 max-w-70 pl-2 pr-1 pt-2 pb-2 br">
                    <OrderBy field="procedure" spanClassName="ml-0" column="Dł." formName={this.filtersForm} onSubmit={this.fetchResults} />
                  </div>
                  <div className="flex-2 pl-2 pr-1 pt-2 pb-2 br">
                    <OrderBy field="limit_amount" spanClassName="ml-0" column="Limit" formName={this.filtersForm} onSubmit={this.fetchResults} />
                  </div>
                  <div className="flex-2 pl-2 pr-1 pt-2 pb-2 br">
                    <OrderBy field="amount" spanClassName="ml-0" column="Sublimity" formName={this.filtersForm} onSubmit={this.fetchResults} />
                  </div>
                  <div className="flex-2 max-w-70 p-2 br f90">Waluta</div>
                  <div className="flex-2 pl-2 pr-1 pt-2 pb-2 br">
                    <OrderBy field="status" spanClassName="ml-0" column="Status" formName={this.filtersForm} onSubmit={this.fetchResults} />
                  </div>
                  <div className="flex-3 f90 px-10">Zatwierdził</div>
                </div>
              </div>
            </div>
            {rows.length > 0 ? (
              rows.map(row => (
                <div
                  key={row.id}
                  className="row"
                  onDoubleClick={() => {
                    this.props.dispatch(ProposalActions.changeTab(1));
                    this.props.history.push(`/wnioski/${row.slug}`);
                  }}
                  onClick={() => this.selectProposalForPreview(row)}>
                  <div className="col-sm-12">
                    <div className="row bb data-row">
                      <div className="flex-3 max-w-150 pl-2 pr-1 pt-2 pb-2 br"><Tooltip label={row.identifier} /></div>
                      <div className="flex-6 pl-2 pr-1 pt-2 pb-2 br">{row.client?.name ?? '-'}</div>
                      <div className="flex-2 max-w-70 pl-2 pr-1 pt-2 pb-2 br">{row.debtors_count}</div>
                      <div className="flex-2 pl-2 pr-1 pt-2 pb-2 br">{parseMoney(row.limit_amount)}</div>
                      <div className="flex-2 pl-2 pr-1 pt-2 pb-2 br">{parseMoney(row.sublimits_sum)}</div>
                      <div className="flex-2 max-w-70 pl-2 pr-1 pt-2 pb-2 br">{row.currency?.name ?? 'brak'}</div>
                      <div className="flex-2 pl-2 pr-1 pt-2 pb-2 br">
                        <span className="badge badge-warning pt-1 pb-1 pl-2 pr-2">
                          {this.props.common?.proposalData?.proposal_statuses?.[row.status]}
                        </span>
                      </div>
                      <div className="flex-3 pt-2 pb-2 px-10">{row.user_approving?.full_name ?? 'brak'}</div>
                      {/* <div className='col-sm-2 pl-2 pr-1 pt-2 pb-2'>?</div> */}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="row">
                <div className="col-sm-12 mt-3">Brak wyników.</div>
              </div>
            )}
          </Tab>

          {rows && (
            <Pagination
              withSelect
              count={this.props.proposal.count}
              currentPage={this.props.proposal.currentPage}
              onChangePage={this.handleChangePage}
            />
          )}

          <BaseModal
            visible={this.formVisible(this.createProposalForm)}
            header="Kreator dodawania wniosku krok 1 z 2"
            footer={'W celu utworzenia wniosku wciśnij "Utwórz wniosek".'}
            onClickAway={() => this.toggleForm(this.createProposalForm)}>
            <CreateProposalForm
              errors={this.props.forms.errors[this.createProposalForm]}
              form={this.props.forms.forms[this.createProposalForm]}
              forms={this.props.forms.forms}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              visibleOnMount={false}
              visible={this.formVisible(this.createProposalForm)}
              toggleForm={form => this.toggleForm(form)}
              isVisible={form => this.formVisible(form)}
              formName={this.createProposalForm}
              withButtons
              submitForm={() => this.saveForm()}
              onCancel={() => this.toggleForm(this.createProposalForm)}
              currencies={this.props.common.currencies}
              fetch={(t, f) => this.props.dispatch(ProposalActions.fetchForForm(t, f))}
              formData={this.props.proposal.formData}
              storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            />
          </BaseModal>
        </MainContainer>
        <SidebarContainer>
          <ProposalSidebarView remove={id => this.remove(id)} proposal={this.props.proposal.preview} common={this.props.common} loading={false} />
        </SidebarContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  forms: state.Forms,
  common: state.Common,
  options: state.Options,
  proposal: state.Proposal
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(ProposalList)
);
