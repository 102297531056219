import { getParsedObject } from 'utilities/http';

import HttpClient from '../utilities/http';

export const getBankAccountData = (ban) => HttpClient.get(`/companies/bank_data/${ban}/`);

const fetch = (filters) => HttpClient.get('/companies/', { params: filters });

const fetchCompanyDetails = (CompanyId) => HttpClient.get(`/companies/${CompanyId}/`);

const saveForm = (form, data, companyId, forcePut = false) => {
  let u = '/companies/';

  switch (form) {
    case 'CompanyForm':
    case 'MainCreatorForm':
      break;
    case 'ScoringCreatorForm':
      u += companyId;
      forcePut = true;
      break;
    case 'AddressForm':
    case 'AddressesCreatorForm':
    case 'AddressesCreatorEditForm':
      u = `${u + companyId}/addresses/`;
      break;
    case 'ContactForm':
    case 'SocialMediaForm':
    case 'SocialMediaCreatorForm':
    case 'ContactsCreatorForm':
    case 'ContactsCreatorEditForm':
    case 'SocialMediaEditForm':
    case 'SocialMediaCreatorEditForm':
      if (data.id) {
        data = {
          is_active: data.is_active,
          value: data.value,
          id: data.id,
          country_calling_code_id: data.country_calling_code_id
        };
      } else {
        data = {
          is_active: data.is_active,
          value: data.value,
          type: data.type,
          country_calling_code_id: data.country_calling_code_id
        };
      }

      u = `${u + companyId}/contacts/`;
      break;

    case 'NotesForm':
    case 'NotesCreatorForm':
    case 'NotesCreatorEditForm':
      u = `${u + companyId}/notes/`;
      break;
    case 'CommissionForm':
    case 'CommissionsCreatorForm':
    case 'CommissionsCreatorEditForm':
      u = `${u + companyId}/commissions/`;
      break;
    case 'BankAccountsForm':
    case 'BankAccountsCreatorForm':
    case 'BankAccountsCreatorEditForm':
      data.is_active = data.is_active === '' ? false : data.is_active;
      u = `${u + companyId}/bank_accounts/`;
      break;
    case 'BusinessCompaniesForm':
      u += companyId;
      break;
    case 'RoleForm':
    case 'RoleCreatorForm':
      u += companyId;
      forcePut = true;
      break;
    case 'PersonForm':
      u = `${u + companyId}/person/`;
      break;
    default:
      break;
  }

  return data.id || forcePut ? HttpClient.put(`${u + (data.id || '')}/`, data) : HttpClient.post(u, data);
};

const removeCompany = (id) => HttpClient.delete(`/companies/${id}`);
const removeObject = (oId, pId, object) => HttpClient.delete(`/companies/${pId}/${object}/${oId}/`);

const fetchCompanyData = () => HttpClient.get('/companies/company_data/').then((r) => r.data);

const toggleStatus = (companyId, objectId, objectType) => HttpClient.patch(`/companies/${companyId}/${objectType}/${objectId}/`);

const create = (data) => {
  const request = { ...data, ...getParsedObject(data) };
  return HttpClient.post('/companies/creator/', request);
};

const fetchPeopleForCompany = () => HttpClient.get('/persons/');


const addPerson = (personIds, rolesIds, companyId) => {
  const data = getParsedObject({ person_id: personIds, roles_ids: rolesIds });

  return HttpClient.post(`/companies/${companyId}/person/`, data);
};

const detachPerson = (personId, companyId) => HttpClient.delete(`/companies/${companyId}/person/${personId}/`);

const saveCommission = (companyId, data) => {
  const method = data.id ? 'put' : 'post';
  const suffix = data.id ? `/${data.id}` : '';

  return HttpClient[method](`/companies/${companyId}/commissions${suffix}/`, data);
};
const saveParentCommission = (companyId, data) => {
  const method = data.id ? 'put' : 'post';
  const suffix = data.id ? `/${data.id}` : '';

  const request = {
    category_id: Number(data.category)
  };

  return HttpClient[method](`/companies/${companyId}/parents_commissions${suffix}/`, request);
};

export default {
  fetch,
  fetchCompanyDetails,
  saveForm,
  removeCompany,
  removeObject,
  fetchCompanyData,
  toggleStatus,
  create,
  fetchPeopleForCompany,
  addPerson,
  detachPerson,
  saveCommission,
  saveParentCommission
};
