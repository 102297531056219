import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useEffect } from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parseMoney } from '../../../../utilities/helpers';
import SecuresForm from '../../Forms/Secures';

export default props => {
  const formName = 'SecuresCreatorForm';
  const modalFormName = 'SecuresCreatorEditForm';

  useEffect(() => {
    if (props.populateForm) {
      props.populateForm(formName);
    }
    // eslint-disable-next-line
  }, [props.version]);

  const rows = props.transactions ? props.version?.transaction_secure_documents : props.version?.factorer_secure_documents;

  const handleSubmit = () => {
    props.saveForm(formName);
  };

  return (
    <Card mode='creator' header={props.transactions ? '+ Transakcja zabezpieczenia' : '+ Faktorant zabezpieczenia'} onSaveClick={handleSubmit} isBlocked={false}>
      <div className='py-10'>
        <SecuresForm
          transactions={props.transactions}
          issuers={props.issuers}
          fetchIssuers={props.fetchIssuers}
          proposal={props.proposal}
          forms={props.forms}
          common={props.common}
          preloadedForm
          visible
          visibleOnMount
          clear={props.clear}
          formVisible={props.formVisible}
          onCancelButtonClick={() => props.toggleForm(formName)}
          toggleForm={props.toggleForm}
          formName={formName}
          submitForm={() => props.saveForm(formName)}
        />
      </div>

      <div className="row bb">
        <div className="col-sm-2 p-2 br grey">Rodzaj</div>
        <div className="col-sm-1 p-2 br grey">Kwota</div>
        <div className="col-sm-1 p-2 br grey">Wartość</div>
        <div className="col-sm-2 p-2 br grey">Forma dokumentu</div>
        {props.transactions ? <div className="col-sm-2 p-2 br grey">Wystawiający</div> : null}
        <div className="col-sm-2 p-2 br grey">Klauzula</div>
        <div className="col-sm-1 p-2 br grey">Wzór faktoranta</div>
        <div className="col-sm-1 p-2 grey">Punkty / Max</div>
      </div>
      {rows?.map(row => (
        <RowEditable
          key={row.identifier}
          onEdit={() => {
            props.edit({
              ...row,
              dts_type_id: row.dts_type.id,
              dts_form_id: row.dts_form.id
            },
            modalFormName);
          }}
          onRemove={() => {
            props.remove('secure_documents', row.id);
          }}>
          <div className="col-sm-2 p-2 br ">
            {row.dts_type ? row.dts_type.name : '-'} {row.proposal_debtor ? ` - ${row.proposal_debtor.name}` : ''}
          </div>
          <div className="col-sm-1 p-2 br ">{parseMoney(row.current_debt)}</div>
          <div className="col-sm-1 p-2 br ">{parseMoney(row.collateral_value)}</div>
          <div className="col-sm-2 p-2 br ">{row.dts_form ? row.dts_form.name : ' -'}</div>
          {props.transactions ? <div className="col-sm-2 p-2 br ">{row.issuer?.name}</div> : null}
          <div className="col-sm-2 p-2 br ">{row.clauses ? row.clauses.map(c => c.name).join(', ') : '-'}</div>
          <div className="col-sm-1 p-2 br ">
            <BooleanCheck field={row.is_factor_pattern} />
          </div>
          <div className="col-sm-1 p-2 ">{`${row.my_score} / ${row.dts_type ? row.dts_type.score : 1}`}</div>
        </RowEditable>
      ))}

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Zabezpieczenia</span>
          </div>
        }
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}>
        <SecuresForm
          transactions={props.transactions}
          issuers={props.issuers}
          fetchIssuers={props.fetchIssuers}
          proposal={props.proposal}
          forms={props.forms}
          common={props.common}
          preloadedForm
          clear={props.clear}
          visible={props.formVisible(modalFormName)}
          formVisible={props.formVisible}
          onCancelButtonClick={() => props.toggleForm(modalFormName)}
          toggleForm={props.toggleForm}
          formName={modalFormName}
          withButtons
          submitForm={() => props.saveForm(modalFormName)}
        />
      </BaseModal>
    </Card>
  );
};
