import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { OldCard } from '../../../components/Layout';
import { SidebarNotes } from '../../../components/SidebarNotes';
import { parseMoney, formatNip, parsePhone } from '../../../utilities/helpers';
import RemovalModal from '../Forms/RemovalModal';

class CompanySidebarView extends React.Component {
  constructor() {
    super();
    this.state = { objectToRemove: {} };
  }

  setObjectToRemove = object => {
    if (this.state.objectToRemove.id) {
      this.setState({ objectToRemove: {} });
    } else {
      this.setState({ objectToRemove: object });
    }
  };

  render() {
    const proposal = this.props.proposal ?? {};
    const client = proposal?.client || {};

    return (
      <OldCard className="mt-3 pt-3 pb-3">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="font-weight-bold text-themecolor">
              {proposal.identifier}
              <span className="float-right text-themecolor" style={{ cursor: 'pointer' }}>
                <i className="fas fa-sign-in-alt mr-2" onClick={() => this.props.history.push(`/wnioski/${this.props.proposal.slug}`)} />

                <i className="fa fa-trash" style={{ color: 'red' }} onClick={() => this.setObjectToRemove(this.props.proposal)} />
              </span>
            </h3>
          </div>
        </div>
        <div className="mb-2">
          <div className="row">
            <div className="col-sm-12 text-left grey">
              Kwota limitu: {parseMoney(proposal.limit_amount)} {proposal.currency?.name}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 mt-3 mb-3">
            <button type="button" className="btn btn-sm btn-success mr-3">
              <i className="fa fa-envelope mr-2" />
              Wiadomość
            </button>
            <button type="button" className="btn btn-sm btn-success mr-3">
              <i className="fa fa-link mr-2" />
              Powiąż
            </button>
            <button type="button" className="btn btn-sm btn-success">
              <i className="fa fa-envelope mr-2" />
              Zadanie
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h4 className="text-themecolor mt-2 mb-2">Opiekunowie</h4>
          </div>
        </div>
        <div className="row mb-4 mt-1">
          <div className="col d-flex align-items-center">
            <ReactTooltip />
            <img
              src="/user.png" width="50" height="50"
              alt=""
              className="img-circle mr-2"
              data-tip={`Administrator operacyjny: ${proposal.operational_administrator?.name ?? '-'}`}
            />
            <img
              src="/user.png" width="50" height="50"
              alt=""
              className="img-circle mr-2"
              data-tip={`Opiekun ds. rozliczeń: ${proposal.billing_manager?.full_name ?? '-'}`}
            />
            <img
              src="/user.png" width="50" height="50"
              alt=""
              className="img-circle"
              data-tip={`Opiekun handlowy: ${proposal.trade_supervisor?.full_name ?? '-'}`}
            />
          </div>
        </div>

        <div className="rows-bordered mt-1">
          <div className="row p-1">
            <div className="col-6">
              <h4 className="text-themecolor mt-2 mb-2">Status wniosku</h4>
            </div>
            <div className="col-sm-6 text-right">
              <span className="badge badge-warning mt-2 mb-2 pt-1 pb-1 pl-2 pr-2">{this?.props?.common?.proposalData?.proposal_statuses[proposal?.status]}</span>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Faktor</div>
            <div className="col-sm-6 text-right">{proposal.factor ? proposal.factor.name : '-'}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Produkt</div>
            <div className="col-sm-6 text-right">{proposal.product ? proposal.product.name : '-'}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Procedura</div>
            <div className="col-sm-6 text-right">{proposal.procedure ? proposal.procedure.name : '-'}</div>
          </div>

          <div className="row p-1">
            <div className="col-12">
              <h4 className="text-themecolor mt-2 mb-2">Klient</h4>
            </div>
          </div>
          <div className="row p-1">
            <div className="col-sm-4 text-left grey">Nazwa firmy</div>
            <div className="col-sm-8 text-right">{client.name}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">NIP</div>
            <div className="col-sm-6 text-right">{formatNip(client.nip)}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-4 text-left grey">Forma prawna</div>
            <div className="col-sm-8 text-right">{client.legal_form ? client.legal_form.name : '-'}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Telefon</div>
            <div className="col-sm-6 text-right">{parsePhone(client.active_phone)}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-6 text-left grey">Email</div>
            <div className="col-sm-6 text-right">{client.active_email}</div>
          </div>
          <div className="row p-1">
            <div className="col-sm-5 text-left grey">Adres korespondencyjny</div>
            <div className="col-sm-7 text-right">
              {client.active_address
                ? `${client.active_address?.street ?? ''} ${client.active_address?.building_no ?? ''}/${client.active_address?.local_no ??
                    ''} ${client.active_address?.zipcode ?? ''} ${client.active_address?.city ?? ''}`
                : 'brak'}
            </div>
          </div>
          <div className="row p-1">
            <div className="col-12">
              <h4 className="text-themecolor mt-2 mb-2">Notatki</h4>
              <SidebarNotes object={proposal} />
            </div>
          </div>
        </div>

        <RemovalModal
          visible={Object.keys(this.state.objectToRemove).length > 0}
          toggle={() => this.setObjectToRemove({})}
          remove={() => {
            this.props.remove(this.state.objectToRemove.id);
            this.setObjectToRemove({});
          }}>
          {this.state.objectToRemove && (
            <div className="container-fluid">
              <div className="row">Czy na pewno chcesz usunąć tę ofertę?</div>
            </div>
          )}
        </RemovalModal>
      </OldCard>
    );
  }
}

export default withRouter(CompanySidebarView);
