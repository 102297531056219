import { toast } from 'react-toastify';
import { DefaultMenu } from 'utilities/constants';
import HttpClient from 'utilities/http';

import CommonService from '../services/common';
import { AUTHORIZATION_USER_DATA_FETCHED } from 'reducers/authorization';

const changeView = view => dispatch => dispatch({ type: 'COMMON_CHANGE_VIEW', payload: view });

const toggleModal = (modal = null) => dispatch => dispatch({ type: 'COMMON_TOGGLE_MODAL', payload: modal });

const fetchUserInfo = () => dispatch => HttpClient.get('/user/profile/').then((response) => {
  return dispatch({ type: AUTHORIZATION_USER_DATA_FETCHED, payload: response.data });
});

const fetchSystemRoles = () => dispatch =>
  CommonService.fetchSystemRoles().then(r => dispatch({ type: 'COMMON_SYSTEM_ROLES_FETCHED', payload: r.data }));

const fetchBusinessRoles = () => dispatch =>
  CommonService.fetchBusinessRoles().then(r => dispatch({ type: 'COMMON_BUSINESS_ROLES_FETCHED', payload: r.data }));

const fetchCommissions = () => dispatch =>
  CommonService.fetchCommissions().then(r => dispatch({ type: 'COMMON_COMMISSIONS_FETCHED', payload: r.data }));

const fetchCurrencies = () => dispatch => CommonService.fetchCurrencies().then(r => dispatch({ type: 'COMMON_CURRENCIES_FETCHED', payload: r.data }));

const fetchContactTypes = () => dispatch =>
  CommonService.fetchContactTypes().then(r => dispatch({ type: 'COMMON_CONTACT_TYPES_FETCHED', payload: r.data }));

const fetchBankAccounts = () => dispatch =>
  CommonService.fetchBankAccounts().then(r => dispatch({ type: 'COMMON_BANK_ACCOUNTS_FETCHED', payload: r.data }));

const fetchCompanyData = () => dispatch =>
  CommonService.fetchCompanyData().then(r => dispatch({ type: 'COMMON_COMPANY_DATA_FETCHED', payload: r.data }));

const fetchConsentData = () => dispatch =>
  CommonService.fetchConsentData().then(r => dispatch({ type: 'COMMON_CONSENT_DATA_FETCHED', payload: r.data }));

const fetchOfferData = () => dispatch => CommonService.fetchOfferData().then(r => dispatch({ type: 'COMMON_OFFER_DATA_FETCHED', payload: r.data }));

const fetchProposalData = () => dispatch =>
  CommonService.fetchProposalData().then(r => dispatch({ type: 'COMMON_PROPOSAL_DATA_FETCHED', payload: r.data }));

const fetchAgreementData = () => dispatch =>
  CommonService.fetchAgreementData().then(r => dispatch({ type: 'COMMON_AGREEMENT_DATA_FETCHED', payload: r.data }));

const fetchClaimData = () => dispatch => CommonService.fetchClaimData().then(r => dispatch({ type: 'COMMON_CLAIM_DATA_FETCHED', payload: r.data }));

const fetchPaymentData = () => dispatch =>
  CommonService.fetchPaymentData().then(r => dispatch({ type: 'COMMON_PAYMENT_DATA_FETCHED', payload: r.data }));

const fetchInvoiceData = () => dispatch =>
  CommonService.fetchInvoiceData().then(r => dispatch({ type: 'COMMON_INVOICE_DATA_FETCHED', payload: r.data }));

const fetchCountryCallingCodes = () => dispatch =>
  CommonService.fetchCountryCallingCodes().then(r => dispatch({ type: 'COMMON_COUNTRY_CALLING_CODES_FETCHED', payload: r.data }));

const fetchCountryNames = () => dispatch =>
  CommonService.fetchCountryNames().then(r => dispatch({ type: 'COMMON_COUNTRY_NAMES_FETCHED', payload: r.data }));

const fetchBanks = () => dispatch => CommonService.fetchBanks().then(r => dispatch({ type: 'COMMON_BANKS_FETCHED', payload: r.data }));

const fetchVat = () => dispatch => CommonService.fetchVat().then(r => dispatch({ type: 'COMMON_VAT_FETCHED', payload: r.data }));

const fetchTaxProcedureCodes = () => dispatch =>
  CommonService.fetchTaxProcedureCodes().then(r => dispatch({ type: 'COMMON_TAX_PROCEDURES_FETCHED', payload: r.data }));

const fetchGtuCodes = () => dispatch => CommonService.fetchGtuCodes().then(r => dispatch({ type: 'COMMON_GTU_CODES_FETCHED', payload: r.data }));

const fetchInvoiceItemTypes = () => dispatch =>
  CommonService.fetchInvoiceItemTypes().then(r => dispatch({ type: 'COMMON_INVOICE_TYPES_FETCHED', payload: r.data }));

const fetchCommissionRoles = () => dispatch => {
  CommonService.fetchCommissionRoles().then(r => dispatch({ type: 'COMMON_COMMISSION_ROLES_FETCHED', payload: r.data }));
};

const fetchCommonData = () => dispatch => {
  CommonService.fetchCommonData().then(r => dispatch({ type: 'COMMON_DATA_FETCHED', payload: r.data }));
};

const fetchDictionaries = () => (dispatch, getState) => {
  const t = getState().Common;

  if (!t.downloaded) {
    dispatch(fetchSystemRoles());
    dispatch(fetchBusinessRoles());
    dispatch(fetchCommissions());
    dispatch(fetchCurrencies());
    dispatch(fetchContactTypes());
    dispatch(fetchBankAccounts());
    dispatch(fetchCompanyData());
    dispatch(fetchConsentData());
    dispatch(fetchCountryCallingCodes());
    dispatch(fetchCountryNames());
    dispatch(fetchOfferData());
    dispatch(fetchProposalData());
    dispatch(fetchAgreementData());
    dispatch(fetchClaimData());
    dispatch(fetchPaymentData());
    dispatch(fetchInvoiceData());
    dispatch(fetchBanks());
    dispatch(fetchVat());
    dispatch(fetchTaxProcedureCodes());
    dispatch(fetchGtuCodes());
    dispatch(fetchInvoiceItemTypes());
    dispatch(fetchCommissionRoles());
    dispatch(fetchCommonData());
    dispatch(fetchUserInfo());
    dispatch({ type: 'COMMON_DICTIONARIES_DOWNLOADED' });
  }
};

const setContextMenu = menu => dispatch => {
  const m = menu || DefaultMenu;
  dispatch({ type: 'CONTEXT_MENU_SET', payload: m });
};

const regenerateDictionaries = () => dispatch => {
  dispatch({ type: 'COMMON_CLEAR_DICTIONARIES' });
  dispatch(fetchDictionaries());
  toast.success('  Dane słownikowe zostały pobrane.');
};

export default {
  changeView,
  toggleModal,
  fetchDictionaries,
  setContextMenu,
  regenerateDictionaries,
  // TODO: remove
  defaultFilters: {
    page: 1,
    limit: 20
  }
};
