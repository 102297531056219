import HttpClient, { SF_ACCESS_TOKEN } from '../utilities/http';

const fetchSystemRoles = () => HttpClient.get('/persons/system_roles/');
const fetchBusinessRoles = () => HttpClient.get('/companies/business_roles/');
const fetchCommissions = () => HttpClient.get('/commons/commissions/');
const fetchCurrencies = () => HttpClient.get('/commons/currencies/');
const fetchContactTypes = () => HttpClient.get('/commons/contact_types/');
const fetchBankAccounts = () => HttpClient.get('/companies/bank_account_data/');
const fetchCompanyData = () => HttpClient.get('/companies/company_data/');
const fetchConsentData = () => HttpClient.get('/persons/consent_data/');
const fetchOfferData = () => HttpClient.get('/offers/offer_data/');
const fetchProposalData = () => HttpClient.get('/proposals/proposal_data/');
const fetchAgreementData = () => HttpClient.get('/agreements/agreement_data/');
const fetchClaimData = () => HttpClient.get('/claims/claim_data/');
const fetchPaymentData = () => HttpClient.get('/payments/payment_data/');
const fetchInvoiceData = () => HttpClient.get('/invoices/invoice_data/');
const fetchCountryCallingCodes = () => HttpClient.get('/commons/country_calling_codes/');
const fetchCountryNames = () => HttpClient.get('/commons/country_names/');
const fetchBanks = () => HttpClient.get('/commons/banks/');
const fetchVat = () => HttpClient.get('/commons/vat/');
const fetchTaxProcedureCodes = () => HttpClient.get('/commons/tax_procedure_codes/');
const fetchGtuCodes = () => HttpClient.get('/commons/gtu_codes/');
const fetchInvoiceItemTypes = () => HttpClient.get('/invoices/invoice_item_types/');
const fetchCommissionRoles = () => HttpClient.get('/commons/commission_roles/');
const fetchCommonData = () => HttpClient.get('/commons/common_data/');

// const getClientIP = () => fetch('https://api.ipify.org/?format=json')
//   .then((r) => r.json())
//   .then((r) => r.ip);

const getClientIP = () => Promise.resolve('192.168.0.1');

const uploadAttachment = (file, url, extraData = {}, method = 'POST', fileName = 'file') => {
  const formData = new FormData();
  formData.append(fileName, file);

  Object.keys(extraData).forEach((key) => {
    formData.append(key, extraData[key]);
  });

  return fetch(process.env.REACT_APP_BACKEND_URI + url, {
    method,
    body: formData,
    headers: {
      // 'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem(SF_ACCESS_TOKEN)}`
    }
  });
};

export default {
  fetchSystemRoles,
  fetchBusinessRoles,
  fetchCommissions,
  fetchCurrencies,
  fetchBankAccounts,
  fetchCompanyData,
  fetchConsentData,
  fetchContactTypes,
  fetchOfferData,
  fetchCountryCallingCodes,
  fetchCountryNames,
  getClientIP,
  fetchProposalData,
  fetchAgreementData,
  fetchClaimData,
  fetchPaymentData,
  fetchInvoiceData,
  fetchBanks,
  fetchVat,
  fetchTaxProcedureCodes,
  fetchGtuCodes,
  fetchCommissionRoles,
  fetchCommonData,
  fetchInvoiceItemTypes,
  uploadAttachment
};
