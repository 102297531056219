import React from 'react';

import {
  Form, Input
} from '../../../components/Forms';
import { parseMoney } from '../../../utilities/helpers';

export default (props) => {
  const f = props.forms?.[props.formName];

  const selectProvider = (providerId) => {
    props.storeInput(props.formName, 'is_nbp', providerId === 1);
    props.storeInput(props.formName, 'is_mbank', providerId === 2);
  };

  const purchaseCurrency = props.claim?.purchase_currency || props.claim?.purchase_custom_value_currency || { name: '?' };

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>

      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Przed przeliczeniem
            </label>
            <div className="col-sm-6">
              {parseMoney(f.amount)}
              {' '}
              {f.amount_currency?.name}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Kurs
              {' '}
              {f.amount_currency?.name}
              /
              {purchaseCurrency?.name}
            </label>
            <div className="col-sm-3">
              <Input formName={props.formName} type="text" name="average_rate" disabled />
            </div>
            <div className="col-sm-1">
              <button type="button" className={f.is_nbp ? 'btn-green' : ''} onClick={() => selectProvider(1)}>NBP</button>
            </div>
            <div className="col-sm-1">
              <button type="button" className={f.is_mbank ? 'btn-green' : ''} onClick={() => selectProvider(2)}>mBank</button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Po przeliczeniu
            </label>
            <div className="col-sm-3">
              {parseMoney(f.calc_value, 0)}
              {' '}
              {purchaseCurrency?.name}
            </div>
          </div>
        </div>
      </div>

      {props.withButtons ? (
        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button
              type="button"
              onClick={props.onCancelButtonClick}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}
            >
              Anuluj
            </button>
            <button type="button" onClick={props.recalculate} className="btn btn-primary ml-2  font-weight-light">
              Przelicz
            </button>
            {f.calc_value ? (
              <button
                type="button"
                onClick={props.accept}
                className="btn btn-primary ml-2  font-weight-light"
              >
                Akceptuj
              </button>
            ) : null}
          </div>
        </div>
      ) : null}
    </Form>
  );
};
