const Constants = {
  Views: {
    List: 'list',
    Form: 'create_form',
    Details: 'details',
    Creator: 'creator'
  },
  Tabs: {
    Person: {
      name: 'Osoby',
      link: '/osoby',
      icon: 'fas fa-user'
    },
    Companies: {
      name: 'Firmy',
      link: '/firmy',
      icon: 'fas fa-building'
    },
    Offers: {
      name: 'Oferty',
      link: '/oferty',
      icon: 'fas fa-file-invoice'
    },
    Proposals: {
      name: 'Wnioski',
      link: '/wnioski',
      icon: 'fas fa-file-alt'
    },
    Agreements: {
      name: 'Umowy',
      link: '/umowy',
      icon: 'fas fa-file-signature'
    },
    Claims: {
      name: 'Wierzytelności',
      link: '/wierzytelnosci',
      icon: 'fas fa-file-contract'
    }
  }
};

export const DefaultMenu = [
  { name: 'Osoby', link: '/osoby?page=1' },
  { name: 'Firmy', link: '/firmy?page=1' },
  {
    name: 'Oferty',
    options: [
      { name: 'Przeglądaj wszystkie', link: '/oferty?page=1' },
      { name: 'Otwarte', link: '/oferty?status=0&page=1' },
      { name: 'Zamknięte', link: '/oferty?status=1&page=1' }
    ]
  },
  {
    name: 'Wnioski',
    options: [
      { name: 'Przeglądaj wszystkie', link: '/wnioski?page=1' },
      { name: 'Utworzony', link: '/wnioski?status=0&page=1' },
      { name: 'Zatwierdzony', link: '/wnioski?status=1&page=1' },
      { name: 'Oczekuje na edycję', link: '/wnioski?status=2&page=1' },
      { name: 'Zgody udzielone', link: '/wnioski?status=3&page=1' },
      { name: 'Aktywny', link: '/wnioski?status=4&page=1' },
      { name: 'Archiwalny', link: '/wnioski?status=5&page=1' },
      { name: 'Anulowany', link: '/wnioski?status=6&page=1' }
    ]
  },
  {
    name: 'Umowy',
    options: [
      { name: 'Przeglądaj wszystkie', link: '/umowy?page=1' },
      { name: 'Utworzona', link: '/umowy?status=0&page=1' },
      { name: 'Zatwierdzona', link: '/umowy?status=1&page=1' },
      { name: 'Zawarta', link: '/umowy?status=2&page=1' },
      { name: 'Aktywna', link: '/umowy?status=3&page=1' },
      { name: 'W trakcie aneksowania', link: '/umowy?status=4&page=1' },
      { name: 'Rozliczona', link: '/umowy?status=5&page=1' },
      { name: 'Przeterminowana', link: '/umowy?status=6&page=1' },
      { name: 'Windykacja', link: '/umowy?status=7&page=1' },
      { name: 'Restrukturyzacja', link: '/umowy?status=10&page=1' },
      { name: 'Archiwum', link: '/umowy?status=8&page=1' },
      { name: 'Anulowana', link: '/umowy?status=9&page=1' }
    ]
  },
  {
    name: 'Wierzytelności',
    options: [
      { name: 'Przeglądaj wszystkie', link: '/wierzytelnosci?page=1' },
      { name: 'Wprowadzona', link: '/wierzytelnosci?status=0&page=1' },
      { name: 'Do zatwierdzenia', link: '/wierzytelnosci?status=1&page=1' },
      { name: 'Zatwierdzona', link: '/wierzytelnosci?status=2&page=1' },
      { name: 'Zatwierdzona odstępstwo', link: '/wierzytelnosci?status=3&page=1' },
      { name: 'Odrzucona', link: '/wierzytelnosci?status=4&page=1' },
      { name: 'Akcept', link: '/wierzytelnosci?status=5&page=1' },
      { name: 'Wygenerowano zaliczkę', link: '/wierzytelnosci?status=8&page=1' },
      { name: 'Wypłacono zaliczkę', link: '/wierzytelnosci?status=10&page=1' },
      { name: 'Powiązana', link: '/wierzytelnosci?status=11&page=1' },
      { name: 'W rozliczeniu', link: '/wierzytelnosci?status=12&page=1' },
      { name: 'Rozliczony kapitał', link: '/wierzytelnosci?status=13&page=1' },
      { name: 'Rozliczono wyn. podstawowe', link: '/wierzytelnosci?status=14&page=1' },
      { name: 'Rozliczono wyn. dodatkowe', link: '/wierzytelnosci?status=15&page=1' },
      { name: 'Rozliczona', link: '/wierzytelnosci?status=16&page=1' },
      { name: 'Zaksięgowana', link: '/wierzytelnosci?status=17&page=1' },
      { name: 'Anulowana', link: '/wierzytelnosci?status=6&page=1' }
    ]
  },
  {
    name: 'Przelewy',
    options: [
      { name: 'Importuj przelewy z banku', link: '/przelewy?mode=przychodzace&tab=7' },
      { name: 'Eksportuj przelewy do banku', link: '/przelewy?mode=wychodzace&tab=0' },
      { name: 'Poczekalnia', link: '/przelewy?mode=wychodzace?tab=8' },
      { name: 'Poza faktoringiem', link: '/przelewy?mode=wychodzace?tab=9' },
      { name: 'Do autoryzacji', link: '/przelewy?mode=wychodzace&tab=0' },
      { name: 'Wysłane do banku', link: '/przelewy?mode=wychodzace&tab=2' },
      { name: 'Odrzucone', link: '/przelewy?mode=wychodzace&tab=4' },
      { name: 'Wszystkie przychodzące', link: '/przelewy?mode=przychodzace' },
      { name: 'Wszystkie wychodzące', link: '/przelewy?mode=wychodzace' }
    ]
  },
  {
    name: 'Fakturowanie',
    options: [
      { name: 'Należności do fakturowania', link: '/naleznosci' },
      { name: 'Dokumenty sprzedaży', link: '/faktury' },
      { name: 'Noty obciążeniowe', link: '/faktury?status=1' },
      { name: 'Korekty sprzedaży', link: '/faktury?status=1' },
      { name: 'Sprzedaż nierozliczona', link: '/faktury?status=1' }
    ]
  },
  // {name: 'Reklamacje', link: '', options: []},
  // {name: 'Zabezpieczenia', link: '', options: []},
  { name: 'Powiadomienia', link: '/powiadomienia' },
  { name: 'Raporty', link: '/raporty' },
  { name: 'Wskaźniki', link: '/wskazniki' },
  { name: 'Rozrachunki', link: '/rozrachunki' }
  // {name: 'Windykacja', link: '', options: []},
];

export default Constants;
