import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import { TextLink } from 'containers/Companies/Details/Components/Notes';
import React from 'react';
import { useSelector } from 'react-redux';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import { NoteForm } from '../../Forms/Note';

export const Notes = ({ notes, ...props }) => {
  const { NotesCreatorForm: form } = useSelector(state => state.Forms.forms);

  const formName = 'NotesCreatorForm';
  const modalFormName = 'NotesCreatorEditForm';

  return (
    <Card mode='creator' header='+ Notatki' onSaveClick={() => props.submitForm(formName)} isBlocked={!form?.name || !form?.comment}>
      <div className="pr-5 bb mb-3">
        <NoteForm
          visibleOnMount
          clear={props.clear}
          formName={formName}
          visible
        />
      </div>

      {notes.map((note) => (
        <div key={note.id} className="row">
          <div className="col-sm-12">
            <RowEditable
              key={note.id}
              className="row data-row"
              itemId={note.id}
              onEdit={() => props.edit(note, modalFormName)}
              onRemove={() => props.remove(note.id)}
            >
              <div className="col-sm-2 p-2 text-center">
                <img src={note.user_creator.avatar_url} className="img image rounded-circle" style={{ width: '40%' }} alt="" />
                <div className="grey" style={{ fontSize: '80%' }}>
                  <span className="d-block mt-2">Notatka</span>
                  <span>{note.created_date}</span>
                </div>
              </div>
              <div className="col-sm-10 p-2">
                <a
                  role="button"
                  style={{ color: 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#notatka${note.id}`}
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <i className="fa fa-sort-down mr-2" />
                  <span className="text-themecolor">{note.user_creator.full_name}</span>
                  {' '}
                    dodał notatkę dla
                  {' '}
                  <span className="text-themecolor">{note.name}</span>
                </a>

                <div className="collapse show" id={`notatka${note.id}`} style={{ color: 'grey' }}>
                  <p className="small">
                    {note.created_date}
                    {' '}
                      | Temat:
                    {note.name}
                  </p>
                  <div className="pr-3">
                    <TextLink>{note.comment}</TextLink>
                  </div>
                </div>
              </div>
            </RowEditable>
          </div>
        </div>
      ))}

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Notatki</span>
          </div>
        )}
        footer="Aby zapisać notatkę wciśnij ZATWIERDŹ."
        onClickAway={() => props.toggleForm(modalFormName)}
      >
        <div className="pr-5">
          <NoteForm
            visibleOnMount
            clear={props.clear}
            visible={props.formVisible(modalFormName)}
            formName={modalFormName}
            withButtons
            submitForm={() => props.submitForm(modalFormName, true)}
            onCancelButtonClick={() => props.toggleForm(modalFormName)}
          />
        </div>
      </BaseModal>
    </Card>
  );
};
