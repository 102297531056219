import 'twin.macro';

import FormActions from 'actions/forms';
import Button from 'components/Forms/Button';
import Checkbox from 'components/Forms/Checkbox';
import Form from 'components/Forms/Form';
import Select, { optionsBoolean, optionsGrossNet } from 'components/Forms/Select';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatString, formatValueToEurope } from 'utilities/helpers';

export default function DebtorForm({ debtors, onSubmit, ...props }) {
  const dispatch = useDispatch();

  const { version } = useSelector(state => state.Agreement);
  const { forms } = useSelector(state => state.Forms);
  const formName = DebtorForm.name;
  const form = forms[formName];

  useEffect(() => {
    if (version.proposal_debtors) {
      for (const debtor of version?.proposal_debtors) {
        let k = version?.proposal_debtors.indexOf(debtor);
        dispatch(FormActions.storeInput(formName, `debtors.${k}.assignment_consent`, debtor.assignment_consent));
        dispatch(FormActions.storeInput(formName, `debtors.${k}.generate_assignment`, true));

        if(debtor.mpp) {
          dispatch(FormActions.storeInput(formName, `debtors.${k}.purchase_method`, 1));
        } else {
          dispatch(FormActions.storeInput(formName, `debtors.${k}.purchase_method`, 0));
        }
      }
    }
  }, []);

  const handleSave = () => {
    const checkedDebtors = form?.debtors?.filter(debtor => debtor.isChecked);
    onSubmit(checkedDebtors);
  };

  return (
    <Form formName={formName} {...props}>
      <div className='grid grid-cols-7-auto gap-20 py-10 mr-100'>
        <div className='col-name-left'>Nazwa firmy</div>
        <div className='col-name-left'>NIP</div>
        <div className='col-name-left'>Wnioskowany limit</div>
        <div className='col-name-left'>Kategoria</div>
        <div className='col-name-left'>Zgoda na cesję</div>
        <div className='col-name-left'>Czy generować potwierdzenie cesji?</div>
        <div className='col-name-left'>Sposób wykupu</div>

        {debtors?.map((item, key) => (
          <>
            <div className='flex'>
              <Checkbox
                formName={formName}
                name={`debtors.${key}.isChecked`}
                onChange={() => dispatch(FormActions.storeInput(formName, `debtors.${key}.proposal_debtor_id`, item.id))}
              />
              {item?.debtor?.name}
            </div>
            <div>{formatString(item?.debtor?.nip)}</div>
            <div>{formatValueToEurope(item?.requested_sublimit)}</div>
            <div>{formatString(item?.category?.name)}</div>
            <Select tw='w-100' formName={formName} options={optionsBoolean} name={`debtors.${key}.assignment_consent`} />
            <Select tw='w-100' formName={formName} options={optionsBoolean} name={`debtors.${key}.generate_assignment`} />
            <Select tw='w-100' formName={formName} options={optionsGrossNet} name={`debtors.${key}.purchase_method`} />
          </>
        ))}
      </div>


      <div className='flex items-center justify-center'>
        <Button onClick={() => dispatch(FormActions.toggleForm(DebtorForm.name))}>Anuluj</Button>
        <Button mode='primary' onClick={handleSave}>Zapisz</Button>
      </div>
    </Form>
  );
}
