import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import { MultilevelRatesForm } from '../../Forms/MultilevelRates';
import RemovalModal from '../../Forms/RemovalModal';

export const MultilevelRates = (props) => {
  const { MultilevelRatesCreatorForm: form } = useSelector(state => state.Forms.forms);

  const [objectToRemove, setObjectToRemove] = useState({});

  const formName = 'MultilevelRatesCreatorForm';
  const modalFormName = 'MultilevelRatesEditForm';
  const calculation = props.calculation || {};
  const bids = calculation.multilevel_bids ? calculation.multilevel_bids.map((b) => ({ ...b, type: b.bid_type ? 'brak' : '!' })) : [];
  const dtbs = props.offerData && props.offerData.dtb_types ? props.offerData.dtb_types : [];

  const edit = (bid) => {
    const editableBid = { ...bid, calculationId: calculation.id };
    props.edit(editableBid, modalFormName);
  };

  return (
    <Card mode='creator' header='+ Stawki wielopoziomowe' onSaveClick={() => props.saveForm(formName)} isBlocked={!form?.no_of_levels}>
      <div className="pr-5 pt-3">
        <MultilevelRatesForm
          margin_based_on_tran_risk={props.margin_based_on_tran_risk}
          getMultilevelFlatRate={props.getMultilevelFlatRate}
          calculationId={calculation.id}
          dtbs={dtbs}
          formName={formName}
          offer={props.offer}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(formName)}
          formVisible={props.formVisible}
          saveForm={() => props.saveForm(formName)}
          onCancel={() => props.onCancel(formName)}
          errors={props.errors[formName]}
          forms={props.forms}
          toggleForm={props.toggleForm}
        />
      </div>

      <div className="row mt-2">
        <div className="col-sm-12">
          <div className="row bt">
            <div className="col-sm-2 p-2 br grey">Nazwa stawki</div>
            <div className="col-sm-1 p-2 br grey">Poziom</div>
            <div className="col-sm-1 p-2 br grey">Max wysokość zaliczki</div>
            <div className="col-sm-2 p-2 br grey">Max termin płatności</div>
            <div className="col-sm-2 p-2 br grey">Stawka</div>
            <div className="col-sm-2 p-2 br grey">Nazwa dokumentu</div>
            <div className="col-sm-2 p-2 grey">ID powiązanego dokumentu</div>
          </div>
          {bids.map((bid) => (
            <RowEditable
              onEdit={() => edit(bid)}
              onRemove={() => {
                props.remove(bid);
              }}
            >
              {bid.levels.map((level, key) => (
                <div className="col-sm-12">
                  <div className="row bt">
                    <div className="col-sm-2 p-2 br">{key === 0 ? bid.name : ''}</div>
                    <div className="col-sm-1 p-2 br">{level.level_no}</div>
                    <div className="col-sm-1 p-2 br">{level.max_advance_payment}</div>
                    <div className="col-sm-2 p-2 br">{bid.max_payment_date}</div>
                    <div className="col-sm-2 p-2 br">{bid.multilevel_flat_rate}</div>
                    <div className="col-sm-2 p-2 br">{level.document_to_buy_types.map((d) => d.name).join(', ')}</div>
                    <div className="col-sm-2 p-2">-</div>
                  </div>
                </div>
              ))}
            </RowEditable>
          ))}
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Stawki wielopoziomowe</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}
      >
        <MultilevelRatesForm
          getMultilevelFlatRate={props.getMultilevelFlatRate}
          dtbs={dtbs}
          formName={modalFormName}
          offer={props.offer}
          preloadedForm
          visibleOnMount={false}
          clear={props.clear}
          visible={props.formVisible(modalFormName)}
          formVisible={props.formVisible}
          saveForm={() => props.saveForm(modalFormName, true)}
          onCancel={() => props.onCancel(modalFormName)}
          withButtons
          errors={props.errors[modalFormName]}
          forms={props.forms}
          toggleForm={props.toggleForm}
        />
      </BaseModal>

      <RemovalModal
        visible={Object.keys(objectToRemove).length > 0}
        toggle={() => setObjectToRemove({})}
        remove={() => {
          props.remove(objectToRemove);
          setObjectToRemove({});
        }}
      >
        {objectToRemove && (
          <div className="container-fluid">
            <div className="row bb bt">
              <div className="col-sm-2 p-2 br grey">Nazwa stawki</div>
              <div className="col-sm-1 p-2 br grey">Poziom</div>
              <div className="col-sm-1 p-2 br grey">Max wysokość zaliczki</div>
              <div className="col-sm-2 p-2 br grey">Max termin płatności</div>
              <div className="col-sm-2 p-2 br grey">Stawka flat</div>
              <div className="col-sm-2 p-2 br grey">Nazwa dokumentu</div>
              <div className="col-sm-2 p-2 grey">ID powiązanego dokumentu</div>
            </div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br">{objectToRemove.name}</div>
              <div className="col-sm-1 p-2 br">{objectToRemove.no_of_levels}</div>
              <div className="col-sm-1 p-2 br">?</div>
              <div className="col-sm-2 p-2 br ">{objectToRemove.payment_deadline_days}</div>
              <div className="col-sm-2 p-2 br ">{objectToRemove.multilevel_flat_rate}</div>
              <div className="col-sm-2 p-2 br ">?</div>
              <div className="col-sm-2 p-2 ">?</div>
            </div>
          </div>
        )}
      </RemovalModal>
    </Card>
  );
};
