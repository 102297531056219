import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import { FormattedAddress } from 'containers/Companies/Creator/Components/Addresses';
import React, { useState } from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { AddressesForm } from '../../Forms/Address';
import RemovalModal from '../../Forms/RemovalModal';
import { TextLink } from 'containers/Companies/Details/Components/Notes';

export const Addresses = (props) => {
  const [objectToRemove, setObjectToRemove] = useState({});

  const formName = 'AddressForm';

  const addresses = props.person.addresses || [];
  const modalSelectedType = props.forms[formName] ? props.forms[formName].address_type : null;

  return (
    <Card header='Adresy' onAddClick={() => props.create(formName)}>
      <div className="collapse show" id="adresy">
        {addresses.length === 0 && (
          <div className="row">
            <div className="col-sm-12 p-2">Brak danych.</div>
          </div>
        )}
        {addresses.map((address, k) => (
          <div key={address.id} className="row">
            <div className="col-sm-12">
              <RowEditable isObjectToRemove itemId={address.id} onEdit={() => props.edit(formName, address)} onRemove={() => setObjectToRemove(address)}>
                <div className="col-sm-4 p-2 br">
                  <a
                    role="button"
                    style={{ color: 'black' }}
                    className="collapsed"
                    data-toggle="collapse"
                    href={`#adres${k}`}
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i className="fa fa-sort-down mr-2" />
                    {address.name}
                  </a>
                </div>
                <div className="col-sm-6 p-2">
                  <a
                    role="button"
                    style={{ color: 'black' }}
                    className="collapsed"
                    data-toggle="collapse"
                    href={`#adres${k}`}
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <FormattedAddress address={address} />
                  </a>
                </div>
                <div className="col-sm-2 p-2">
                  <BooleanCheck field={address.is_active} />
                </div>
              </RowEditable>
            </div>

            <div className="col-sm-12">
              <div className="collapse" id={`adres${k}`}>
                <div className="row bb">
                  <div className="col-sm-12 p-2">
                    Gmina:
                    {' '}
                    {address.commune}
                    , powiat:
                    {' '}
                    {address.county}
                    , województwo:
                    {' '}
                    {address.voivodeship}
                    , kraj:
                    {' '}
                    {address.country}
                  </div>
                </div>
                <div className="row bb grey">
                  <div className="col-sm-4 p-2 br">Wprowadził</div>
                  <div className="col-sm-4 p-2 br">Deaktywował</div>
                  <div className="col-sm-4 p-2">Ważny do</div>
                </div>
                <div className="row bb">
                  <div className="col-sm-4 p-2 br">{address.user_creator ? address.user_creator.full_name : '-'}</div>
                  <div className="col-sm-4 p-2 br">{address.user_deactivator ? address.user_deactivator.full_name : '-'}</div>
                  <div className="col-sm-4 p-2">{address.valid_until_date || '-'}</div>
                </div>
                <div className="row bb">
                  <div className="col-sm-4 p-2 br grey">Komentarz</div>
                  <div className="col-sm-8 p-2">
                    <TextLink>{address.comment}</TextLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>
              Adresy
            </span>
          </div>
        )}
        footer="Aby zakończyć wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <AddressesForm
          visibleOnMount={false}
          visible={props.formVisible(formName)}
          formName={formName}
          addressTypes={props.addressTypes}
          selectButtonDisabled={0}
          selectButtonValue={modalSelectedType || String(modalSelectedType)}
          typeSelected={modalSelectedType || String(modalSelectedType)}
          withButtons
          submitForm={() => props.saveForm(formName)}
          onCancelButtonClick={() => props.toggleForm(formName)}
          voivodeships={props.voivodeships}
          populate={(address) => {
            delete address.id;
            delete address.address_type;

            props.populate(formName, address);
          }}
          buttonOnClick={() => props.clear(formName, 'address_type')}
          addresses={addresses}
          form={props.forms[formName]}
        />
      </BaseModal>

      <RemovalModal
        visible={Object.keys(objectToRemove).length > 0}
        toggle={() => setObjectToRemove({})}
        remove={() => {
          props.remove(objectToRemove.id);
          setObjectToRemove({});
        }}
      >
        {objectToRemove && (
          <div className="container-fluid">
            <div className="col-sm-12">
              <div className="row bb bt">
                <div className="col-sm-4 p-2 br">{objectToRemove.name}</div>
                <div className="col-sm-6 p-2">
                  {`${objectToRemove.street} ${objectToRemove.building_no}/${objectToRemove.local_no}, ${objectToRemove.zipcode} ${
                    objectToRemove.city
                  }`}
                </div>
                <div className="col-sm-2 p-2">
                  <BooleanCheck field={objectToRemove.is_active} />
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="row bb">
                <div className="col-sm-12 p-2">
                  Gmina:
                  {' '}
                  {objectToRemove.commune}
                  , powiat:
                  {' '}
                  {objectToRemove.county}
                  , województwo:
                  {' '}
                  {objectToRemove.voivodeship}
                  , kraj:
                  {' '}
                  {objectToRemove.country}
                </div>
              </div>
              <div className="row bb grey">
                <div className="col-sm-4 p-2 br">Wprowadził</div>
                <div className="col-sm-4 p-2 br">Deaktywował</div>
                <div className="col-sm-4 p-2">Ważny do</div>
              </div>
              <div className="row bb">
                <div className="col-sm-4 p-2 br">{objectToRemove.user_creator ? objectToRemove.user_creator.full_name : '-'}</div>
                <div className="col-sm-4 p-2 br">
                  {objectToRemove.user_deactivator ? objectToRemove.user_deactivator.full_name : '-'}
                </div>
                <div className="col-sm-4 p-2">{objectToRemove.valid_until_date || '-'}</div>
              </div>
              <div className="row bb">
                <div className="col-sm-4 p-2 br grey">Komentarz</div>
                <div className="col-sm-8 p-2">{objectToRemove.comment}</div>
              </div>
            </div>
          </div>
        )}
      </RemovalModal>
    </Card>
  );
};
