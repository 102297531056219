import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import Tooltip from 'components/Layout/Tooltip';
import React, { useEffect, useState } from 'react';
import { formatValueToEurope } from 'utilities/helpers';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parseMoney } from '../../../../utilities/helpers';
import { BasicDataForm } from '../../Forms/BasicData';
import RemovalModal from '../../Forms/RemovalModal';

export const Calculations = props => {
  const calculations = props.offer && props.offer.calculations ? props.offer.calculations : [];

  useEffect(() => {
    if ((!props.calculation || !props.calculation.id) && calculations.length > 0) {
      props.selectCalculation(calculations[0]);
    }
    // eslint-disable-next-line
  }, props);

  const formName = 'CalculationsForm';
  const [objectToRemove, setObjectToRemove] = useState({});

  return (
    <Card header='Kalkulacje' onAddClick={() => props.create(formName)}>
      <div>
        <div className="row bb">
          <div className="col-sm-2 grey p-2 br">Identyfikator kalkulacji</div>
          <div className="col-sm-2 grey p-2 br">Data utworzenia</div>
          <div className="col-sm-2 grey p-2 br">Przygotował</div>
          <div className="col-sm-2 grey p-2 br">Data zamknięcia</div>
          <div className="col-sm-2 grey p-2 br">Kwota</div>
          <div className="col-sm-2 grey p-2">Zatwierdzona</div>
        </div>
        {calculations.length === 0 && (
          <div className="row">
            <div className="col-sm-12 p-2">Brak danych.</div>
          </div>
        )}
        {calculations.map(calc => (
          <RowEditable
            isObjectToRemove
            key={calc.id}
            className={props.calculation && props.calculation.id === calc.id ? 'selectedCalculation' : ''}
            onClick={() => props.selectCalculation(calc)}
            itemId={calc.id}
            onRemove={calculations.length === 1 ? null : () => {
              setObjectToRemove(calc);
            }}>
            <div className="col-sm-2 p-2 br"><Tooltip label={calc.identifier} /></div>
            <div className="col-sm-2 p-2 br">{calc.created_date}</div>
            <div className="col-sm-2 p-2 br">{calc.user_creator ? calc.user_creator.full_name : 'brak'}</div>
            <div className="col-sm-2 p-2 br">{calc.closed_date || 'brak'}</div>
            <div className="col-sm-2 p-2 br">{`${parseMoney(calc.limit_amount)} ${calc.currency?.name}`}</div>
            <div className="col-sm-2 p-2">
              <BooleanCheck field={calc.status} />
            </div>
          </RowEditable>
        ))}
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" /> <span>Kalkulacja</span>
          </div>
        }
        footer="Aby dodać obiekt wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}>
        <BasicDataForm
          calculationsCount={props.offer && props.offer.calculations ? props.offer.calculations.length : 0}
          forms={props.forms}
          currencies={props.currencies}
          offerData={props.offerData}
          preloadedForm
          visibleOnMount={false}
          clear={props.clear}
          visible={props.formVisible(formName)}
          formName={formName}
          saveForm={() => props.saveForm(formName, 1)}
          onCancel={() => props.toggleForm(formName)}
          withButtons
        />
      </BaseModal>

      <RemovalModal
        visible={Object.keys(objectToRemove).length > 0}
        toggle={() => setObjectToRemove({})}
        remove={() => {
          props.remove(objectToRemove.id);
          setObjectToRemove({});
        }}>
        {objectToRemove && (
          <div className="container-fluid">
            <div className="row bb">
              <div className="col-sm-2 grey p-2 br">Identyfikator kalkulacji</div>
              <div className="col-sm-2 grey p-2 br">Data utworzenia</div>
              <div className="col-sm-2 grey p-2 br">Przygotował</div>
              <div className="col-sm-2 grey p-2 br">Data zamknięcia</div>
              <div className="col-sm-2 grey p-2 br">Kwota</div>
              <div className="col-sm-2 grey p-2">Zatwierdzona</div>
            </div>
            <div className="row bb">
              <div className="col-sm-2  p-2 br"><Tooltip label={objectToRemove.identifier} /></div>
              <div className="col-sm-2  p-2 br">{objectToRemove.created_date}</div>
              <div className="col-sm-2  p-2 br">{objectToRemove.user_creator ? objectToRemove.user_creator.full_name : '-'}</div>
              <div className="col-sm-2  p-2 br">{objectToRemove.closed_date || 'brak'}</div>
              <div className="col-sm-2  p-2 br">
                {formatValueToEurope(objectToRemove.limit_amount, objectToRemove.currency?.name)}
              </div>
              <div className="col-sm-2  p-2">
                <BooleanCheck field={objectToRemove.status} />
              </div>
            </div>
          </div>
        )}
      </RemovalModal>
    </Card>
  );
};
