import React from 'react';
import { formatString } from 'utilities/helpers';

import {
  Checkbox,   Form, Input, Select, Selectable
} from '../../../../components/Forms';
import { RowEditable } from '../../../../components/Layout/RowEditable';

export default (props) => {
  const columns = [
    { name: 'Nazwa', getter: 'name' },
    { name: 'Opis', getter: 'description' }

  ];
  const secureTypes = props.offerData && props.offerData.dts_types ? props.offerData.dts_types.map(((i) => ({ ...i, value: i.id }))) : [];
  const secureDocTypes = props.offerData && props.offerData.dts_forms ? props.offerData.dts_forms.map(((i) => ({ ...i, value: i.id }))) : [];
  const clauses = props.proposalData && props.proposalData.factor_secure_clauses_data ? props.proposalData.factor_secure_clauses_data : [];
  const form = props.form || {};

  const getMax = (s) => (secureTypes.find((i) => i.id === s.dts_type_id) ? secureTypes.find((i) => i.id === s.dts_type_id).score : '-');

  return (
    <Form noErrors visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      <div className="row mb-2">
        <div className="flex-20 max-w-200 mx-05 grey">
          Rodzaj zabezpieczenia
        </div>
        <div className="flex-20 max-w-120 mx-05 grey">
          Kwota zabezpieczenia
        </div>
        <div className="flex-20 grey">
          Wystawiający
        </div>
        <div className="flex-20 grey">
          Forma dokumentu
        </div>
        <div className="flex-20 grey text-center">
          Klauzula
        </div>
        <div className="flex-10 grey text-center mr-20">
          Wzór faktora
        </div>
        <div className="flex-10 grey">
          Moje punkty
        </div>
        <div className="flex-10 grey text-right pr-40">
          Max
        </div>
      </div>
      {form.securedocuments.map((d, k) => (
        <RowEditable
          className="row mb-1"
          key={d.id}
          onRemove={() => props.removeField(`securedocuments.${k}`, props.formName)}
        >
          <div className="flex-20 max-w-200 mx-05 grey">
            <Select
              number
              disabled={props.selectDisabled || false}
              formName={props.formName}
              options={secureTypes}
              name={`securedocuments.${k}.dts_type_id`}
            />
          </div>
          <div className="flex-20 max-w-120 mx-05 grey">
            <Input
              formName={props.formName}
              type="text"
              name={`securedocuments.${k}.current_debt`}
            />
          </div>
          <div className="flex-20 grey pt-05">
            {props?.isRiskComponent ? formatString(props?.form?.debtor?.name) : (
              <Select
                number
                disabled={props.selectDisabled || false}
                formName={props.formName}
                options={[{ value: 1, name: '1' }]}
                name={`securedocuments.${k}.issuer_id`}
              />
            )}
          </div>
          <div className="flex-20 grey">
            <Select
              number
              disabled={props.selectDisabled || false}
              formName={props.formName}
              options={secureDocTypes}
              name={`securedocuments.${k}.dts_form_id`}
            />
          </div>
          <div className="flex-20 grey text-center">
            {form?.securedocuments[k].clauses?.map(c => c.name)?.join(', ')}
            <button
              className='ml-05'
              type="button"
              onClick={() => {
                props.toggleForm('clauses');
              }}
            >
              ...
            </button>

            <Selectable
              multiple
              visible={props.formVisible('clauses')}
              formName={props.formName}
              field={`securedocuments.${k}.clauses`}
              columns={columns}
              options={clauses}
              onClickAway={() => props.toggleForm('clauses')}
              header="Wybierz klauzulę"
              footer=""
            />
          </div>
          <div className="flex-10 grey text-center">
            <Checkbox
              label=" "
              formName={props.formName}
              name={`securedocuments.${k}.is_factor_pattern`}
            />

          </div>
          <div className="flex-10 grey">
            <Input
              formName={props.formName}
              type="text"
              name={`securedocuments.${k}.my_score`}
            />
          </div>
          <div className="flex-10 grey text-right pr-40">
            {getMax(d)}
          </div>
        </RowEditable>
      ))}
      <button type="button" onClick={() => props.addField('securedocuments', props.formName)} className="btn btn-sm mb-15">+ Dodaj</button>
    </Form>
  );
};
