import Card from 'components/Layout/Card';
import Tooltip from 'components/Layout/Tooltip';
import { STATUS_AGREEMENT } from 'containers/Agreements/Details/Index';
import React from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parseMoney } from '../../../../utilities/helpers';

export const Versions = (props) => {
  const agreement = props.agreement ?? [];
  const versions = agreement?.agreement_versions ?? [];

  return (
    <Card header='Wersja umowy' options={{ isCollapsed: false }}>
      <div>
        <div className="row bb">
          <div className="flex-15 max-w-120 grey p-2 br">Wersja</div>
          <div className="flex-20 min-w-160 grey p-2 br">Data utworzenia</div>
          <div className="flex-40 min-w-150 grey p-2 br">Utworzył</div>
          <div className="flex-20 min-w-150 grey p-2 br">Nr wniosku</div>
          <div className="flex-20 min-w-150 grey p-2 br">Kwota</div>
          <div className="flex-35 max-w-180 grey p-2 br">Data zatwierdzenia</div>
          <div className="flex-30 max-w-150 grey p-2 br">Zatwierdzona?</div>
          <div className="flex-20 max-w-100 grey p-2">Aktywna?</div>
        </div>
        {versions.length === 0 && (
          <div className="row">
            <div className="col-sm-12 p-2">Brak danych.</div>
          </div>
        )}
        {versions.map((version) => (
          <RowEditable
            key={version.id}
            className={props.version?.id === version.id ? 'selectedCalculation' : ''}
            onClick={() => props.selectVersion(version)}
            onRemove={versions.length === 1 ? undefined : () => props.removeVersion(version)}
            itemId={version.id}
          >
            <div className="flex-15 max-w-120 p-2 br">{version.version}</div>
            <div className="flex-20 min-w-160 p-2 br">{version.created_date}</div>
            <div className="flex-40 min-w-150 p-2 br">{version.user_creator?.full_name ?? ''}</div>
            <div className="flex-20 min-w-150 p-2 br"><Tooltip label={props.agreement?.proposal?.identifier} /></div>
            <div className="flex-20 min-w-150 p-2 br">{parseMoney(props.agreement?.limit_amount)}</div>
            <div className="flex-35 max-w-180 p-2 br">{version.approval_date ?? '-'}</div>
            <div className="flex-30 max-w-150 p-2 br"><BooleanCheck field={version.status >= 1} /></div>
            <div className="flex-20 max-w-100 p-2"><BooleanCheck field={agreement?.status >= STATUS_AGREEMENT.AKTYWNA} /></div>
          </RowEditable>
        ))}
      </div>
    </Card>
  );
};
