import { cloneDeep, isObject, omit, pick } from 'lodash';

import HttpClient from '../utilities/http';

export default {
  fetchClaimsForAgreement: (agreementId, params) => HttpClient.get(`/claims/?agreement_id=${agreementId}`, { params }),
  archive: (agreementId, versionId, obj, objectId) =>
    HttpClient.patch(`/agreements/${agreementId}/agreement_versions/${versionId}/${obj}/${objectId}/archive/`),
  generateHtml: id => HttpClient.patch(`/agreements/${id}/generate/`),
  handleFile: (agreementId, versionId, obj, objectId, attachmentId, action, del) =>
    HttpClient[del ? 'delete' : 'patch'](
      `/agreements/${agreementId}/agreement_versions/${versionId}/${obj}/${objectId}/attachments/${attachmentId}/${action}/`
    ),
  removeAgreement: id => HttpClient.delete(`/agreements/${id}/`),
  fetch: params => HttpClient.get('/agreements/', { params }),
  fetchForForm: (resource, filters = {}) => {
    switch (resource) {
      case 'clients_for_agreements':
        return HttpClient.get('/claims/clients/');
      case 'clients_with_agreements':
        return HttpClient.get('/claims/clients_with_active_agreements/', { params: { ...filters } });
      case 'proposals':
        return HttpClient.get('/proposals/', { params: { ...filters, status: 3 } });
      case 'agreements':
        return HttpClient.get('/agreements/', { params: { ...filters } });
      case 'factors':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'faktor'
          }
        });
      case 'debtors':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'dłużnik'
          }
        });
      case 'clients':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'client'
          }
        });
      case 'trade_supervisors':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'handlowy'
          }
        });
      case 'operational_administrators':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'administrator operacyjny'
          }
        });
      case 'billing_managers':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'rozliczenia'
          }
        });
      case 'vindicators':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'windykacja'
          }
        });
      default:
        return Promise.resolve([]);
    }
  },
  create: data => HttpClient.post('/agreements/creator/', data),
  saveForm: (form, data, id, version) => {
    let forcePut = false;
    data = cloneDeep(data);
    let path = '/agreements/';

    const mutate = (x, excludedKeys = []) => {
      x = { ...x };
      Object.keys(x).forEach(key => {
        if (excludedKeys.indexOf(key) === -1 && (isObject(x[key]) || x[key] === null)) {
          delete x[key];
        }
      });

      return x;
    };

    if(!form) return Promise.reject();
    if (form.indexOf('.') !== -1) {
      const [a, b] = form.split('.');
      data.formId = b;
      form = a;
    }

    switch (form) {
      case 'client_people':
      case 'ClientRepresentation':
        path = `${path + id}/agreement_versions/${version}/related_persons/`;
        data = {
          company_id: data.client?.id,
          person_in_company_ids: data.client_people?.map(i => i.id)
        };
        break;
      case 'factor_people':
      case 'FactorRepresentation':
        path = `${path + id}/agreement_versions/${version}/related_persons/`;
        data = {
          company_id: data.factor?.id,
          person_in_company_ids: data.factor_people?.map(i => i.id)
        };
        break;
      case 'administrator_people':
      case 'OaRepresentation':
        path = `${path + id}/agreement_versions/${version}/related_persons/`;
        data = {
          company_id: data.operational_administrator?.id,
          person_in_company_ids: data.administrator_people?.map(i => i.id)
        };
        break;
      case 'client_emails':
        path = `${path + id}/agreement_versions/${version}/client_authorized_emails/`;
        data = {
          emails: data.client_emails?.map(i => i.email)
        };
        break;
      case 'client_accounts':
        path = `${path + id}/agreement_versions/${version}/client_bank_accounts/`;
        data = {
          bank_account_ids: data.client_accounts?.map(i => i.id)
        };
        break;
      case 'debtor_accounts':
        path = `${path + id}/agreement_versions/${version}/debtor/${data.formId}/techdebtor_bank_accounts/`;
        data = {
          bank_account_ids: data.debtor_accounts?.map(i => i.id)
        };
        break;
      case 'factor_bank_accounts_0':
        path = `${path + id}/agreement_versions/${version}/factor_bank_accounts/`;
        data = {
          bank_account_ids: data.factor_accounts?.map(i => i.id),
          factor_account_type: 0
        };
        break;
      case 'factor_bank_accounts_1':
        path = `${path + id}/agreement_versions/${version}/factor_bank_accounts/`;
        data = {
          bank_account_ids: data.factor_accounts?.map(i => i.id),
          factor_account_type: 1
        };
        break;
      case 'DebtorsCreatorForm':
        path = `${path + id}/agreement_versions/${version}/debtor/`;
        data.debtors = data.debtors.filter(i => i.debtor);
        break;
      case 'Proposal_BasicBids':
        path = `${path + id}/agreement_versions/${version}/debtor/${data.debtor_id}/basic_bid/`;
        data = {
          id: data.id,
          max_advance_payment: data.max_advance_payment,
          max_payment_date: data.max_payment_date,
          flat_rate: data.flat_rate,
          is_intrest_margin: data.is_intrest_margin,
          date_of_rate: data.intrest_margin ? data.intrest_margin.date_of_rate : null,
          intrest_rate_id: data.intrest_margin ? data.intrest_margin.intrest_rate_id : null,
          is_variable: data.intrest_margin ? data.intrest_margin.is_variable : null,
          value: data.intrest_margin ? data.intrest_margin.value : null
        };

        data = mutate(data);
        break;
      case 'Proposal_MultilevelBids':
        path = `${path + id}/agreement_versions/${version}/debtor/${data.debtor_id}/multilevel_bid/`;
        delete data.debtor_id;

        if (data.levels && data.levels.length > 0) {
          data.levels = data.levels.map(level => ({
            level_no: level.level_no,
            max_advance_payment: Number(level.max_advance_payment),
            document_to_buy_types_ids: level.document_to_buy_types.map(i => i.id)
          }));
        }
        break;
      case 'Proposal_AdditionalBids':
        path = `${path + id}/agreement_versions/${version}/debtor/${data.debtor_id}/additional_bid/`;
        delete data.debtor_id;

        break;
      case 'SecuresForm':
        path = `${path + id}/agreement_versions/${version}/documents_to_secure/`;
        data = {
          ...data,
          issuer_id: data.issuer?.id,
          clauses: data.clauses?.map(c => c.id),
          is_factor_pattern: data.is_factor_pattern || false
        };
        data = mutate(data, ['clauses']);
        break;
      case 'SecuresManageForm':
        path = `${path + id}/agreement_versions/${version}/documents_to_secure/`;
        data = {
          ...data,
          clauses: data.clauses?.map(c => c.id),
          is_factor_pattern: Boolean(data.is_factor_pattern),
          indefinite_period_of_validity: Boolean(data.indefinite_period_of_validity),
          tech_debtor_bank_accounts: data.tech_debtor_bank_accounts?.map(x => x.id),
          debtor_id: data.debtor?.id
        };
        data = mutate(data, ['clauses', 'tech_debtor_bank_accounts']);
        break;
      case 'Proposal_Documents':
        path = `${path + id}/agreement_versions/${version}/debtor/${data.debtor_id}/document_to_buy/`;
        data = mutate(data.documentstobuy[0]);
        break;
      case 'DocumentsForm':
        path = `${path + id}/agreement_versions/${version}/generated_documents/`;
        data = {
          template_id: data.document.id
        };
        break;
      case 'DocumentsManageForm':
        path = `${path + id}/agreement_versions/${version}/generated_documents/`;
        data = {
          ...data,
          clauses: data.clauses?.map(c => c.id),
          is_factor_pattern: Boolean(data.is_factor_pattern),
          indefinite_period_of_validity: Boolean(data.indefinite_period_of_validity)
        };
        data = mutate(data, ['clauses']);
        break;
      case 'DebtorsEditForm':
        path = `${path + id}/agreement_versions/${version}/debtor/`;
        data = mutate(data);
        break;
      case 'ConditionsForm':
        path = `${path + id}/agreement_versions/${version}/`;
        forcePut = true;
        data = pick(data, ['max_maturity', 'max_financing_period', 'claim_limit', 'repayment_of_capital', 'split_payment']);
        data = mutate(data);
        break;
      case 'ReadinessForm':
        forcePut = true;
        path = `${path + id}/agreement_versions/${version}/`;
        data = pick(data, [
          'declared_use',
          'preparation_commission_type',
          'unused_amount_commission',
          'percentage_unused_amount_commission',
          'limit_commission',
          'start_up_contract',
          'billing_period',
          'counting_method'
        ]);
        data = mutate(data);
        break;
      case 'UpdateAgreementVersion':
        forcePut = true;
        path = `${path + id}/agreement_versions/${version}/`;
        data = mutate(data);
        break;
      case 'NotesForm':
        path = `${path + id}/notes/`;
        break;
      case 'ComplaintsForm':
        path = `${path + id}/agreement_versions/${version}/complaints/`;
        data = {
          id: data.id,
          person_in_company_id: data.person_in_company?.id,
          claim_id: data.claim?.id,
          phone: data.phone,
          email: data.email,
          comment: data.comment,
          close_it: data.close_it
        };
        data = mutate(data);
        break;
      case 'AnnexesForm':
        path = `${path + id}/agreement_versions/${version}/annexes/`;

        data = {
          id: data.id,
          person_in_company_id: data.person_in_company?.id,
          claim_id: data.claim?.id,
          phone: data.phone,
          email: data.email,
          comment: data.comment,
          close_it: data.close_it,
          open_it: data.open_it
        };

        data = mutate(data);
        break;
      case 'ComplaintsNotesForm':
        path = `${path + id}/agreement_versions/${version}/complaints/${data.complaintId}/notes/`;
        break;
      case 'AnnexesNotesForm':
        path = `${path + id}/agreement_versions/${version}/annexes/${data.annexId}/notes/`;
        break;
      case 'AnnexesLaunchForm':
        path = `${path + id}/agreement_versions/${version}/annexes/${data.annexId}/launch/`;
        return HttpClient.patch(path, { launch_date: data.launch_date });
      case 'AnnexesCreditDecisionForm':
        path = `${path + id}/agreement_versions/${version}/annexes/`;

        data = data.type === 1 ? {
          'id': data.id,
          'role_type_1': data.role_type_1,
          'credit_score_comment_1': data.credit_score_comment_1,
          'credit_score_decition_1': data.credit_score_decition_1,
          person_in_company_id: data.person_in_company?.id,
          comment: data.comment
        } : {
          'id': data.id,
          'role_type_2': data.role_type_2,
          'credit_score_comment_2': data.credit_score_comment_2,
          'credit_score_decition_2': data.credit_score_decition_2,
          person_in_company_id: data.person_in_company?.id,
          comment: data.comment
        };
        break;
      case 'CommmissionsForm':
      case 'CommmissionsCreatorForm':
      case 'CommmissionsEditForm':
        path = `${path + id}/agreement_versions/${version}/commissions/`;

        data = {
          ...data,
          content_type_id: data.beneficiary.content_type_id,
          object_id: data.beneficiary.id,
          commission_type: data.commission_type,
          unit: data.unit,
          max_value: data.max_value,
          currency_id: 1,
          // currency_id: data.currency_id,
          id: data.id
        };
        break;
      case 'GuarantorForm':
        path = `${path + id}/agreement_versions/${version}/documents_to_secure/${data.secureId}/guarantors/`;
        //TODO: important check content_type_id GuarantorForm
        data = {
          content_type_id: data.guarantor?.content_type_id,
          object_id: data.guarantor?.id,
          role_id: data.guarantor?.role ?? 1
        };
        break;
      case 'InsuranceClaimForm':
        path = `${path + id}/agreement_versions/${version}/documents_to_secure/${data.secureId}/insurancelimits/`;
        data.insured_id = data.insured?.id;
        data = mutate(data);
        break;
      default:
        break;
    }

    delete data.client;
    delete data.operational_administrator;
    delete data.factor;

    Object.keys(data).forEach(key => {
      if (data[key] === null) {
        delete data[key];
      }
    });

    path = data.id ? `${path + data.id}/` : path;

    return data.id || forcePut ? HttpClient.put(path, omit(data, 'id')) : HttpClient.post(path, data);
  },
  fetchDetails: id => HttpClient.get(`/agreements/${id}/`),
  fetchDetailsDebtors: async id => HttpClient.get(`/agreements/${id}/debtors/`).then(r => r.data),
  fetchDetailsDebtorsRisks: async id => HttpClient.get(`/agreements/${id}/risks/`).then(r => r.data),
  fetchFactor: id => HttpClient.get(`/companies/${id}/`).then(r => r.data),
  fetchClient: id => HttpClient.get(`/companies/${id}/`).then(r => r.data),
  fetchClientEmails: id => HttpClient.get(`/companies/${id}/contacts/emails/`).then(r => r.data),
  fetchProposalDebtors: (id, idVersion) => HttpClient.get(`/proposals/${id}/proposal_versions/${idVersion}/debtors/`).then(r => r?.data),
  remove: (agreementId, uri, objectId, version) => HttpClient.delete(`/agreements/${agreementId}/${version ? `agreement_versions/${version}/` : ''}${uri}/${objectId}/`),
  fetchFromOffer: (proposalId, debtor, object) => {
    const request = {
      requested_sublimit: debtor.requested_sublimit,
      mpp: debtor.mpp,
      deducted_rates: debtor.deducted_rates,
      [object]: 1
    };

    return HttpClient.put(`/agreements/${proposalId}/debtor/${debtor.id}/`, request);
  },
  updateAmlDecision: (proposalId, decisionId) => HttpClient.put(`/agreements/${proposalId}/aml_decision/${decisionId}/`),
  accept: (agreementId, versionId) => HttpClient.patch(`/agreements/${agreementId}/agreement_versions/${versionId}/accept/`),
  approve: id => HttpClient.patch(`/agreements/${id}/approve/`),
  active: id => HttpClient.patch(`/agreements/${id}/active/`),
  annex: id => HttpClient.patch(`/agreements/${id}/annex/`),
  cancel: id => HttpClient.patch(`/agreements/${id}/cancel/`),
  recover: id => HttpClient.patch(`/agreements/${id}/recover/`),
  restructure: id => HttpClient.patch(`/agreements/${id}/restructure/`),
  generateRaport: id =>
    HttpClient.get(`/reports/agreement/${id}/pdf/`, {
      responseType: 'blob'
    })
};
