import FormActions from 'actions/forms';
import Button from 'components/Forms/Button';
import { Modal } from 'components/Layout/Modal';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function SelectableSmall({ formName, title, options, columns, name, multi, onChange, onClose, textChooseButton, children, ...props }) {
  const { forms, errors } = useSelector(state => state.Forms);
  const dispatch = useDispatch();

  const [previewOptions, setPreviewOptions] = useState([]);
  const [isOpen, setOpen] = useState(false);

  const error = get(errors[formName], name, '');
  const selectedOptions = get(forms[formName], name, null);

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    onClose && onClose();
    setOpen(false);
  };

  const handlePreviewOption = item => () => {
    const wasSelected = previewOptions?.find(previewOption => previewOption?.value === item?.value);
    let newArray;

    if(wasSelected) {
      newArray = previewOptions.filter(previewOption => previewOption?.value !== item?.value);
    } else {
      newArray = [...previewOptions, item];
    }
    setPreviewOptions(newArray);
  };

  const handleSubmit = () => {
    handleModalClose();

    dispatch(FormActions.clearErrors(formName));
    dispatch(FormActions.storeInput(formName, name, previewOptions));

    onChange && onChange(previewOptions);
  };

  const handleOrderTable = key => () => {
  };

  return (
    <div {...props}>
      <Modal
        header={title}
        isOpen={isOpen}
        onClose={handleModalClose}
      >
        {children && children}
        <table className="selectable">
          <thead>
            <tr>
              {columns.map((column, key) => (
                <th key={column.name} onClick={handleOrderTable(key)}>
                  <span className="grey font-weight-light pointer">{column.name}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {options?.map(option =>
              <tr key={option.name} onClick={handlePreviewOption(option)}>
                {columns.map(column => (
                  <td key={column.property} className={previewOptions?.find(previewOption => previewOption?.value === option?.value) ? 'companyRowSelected' : ''}>
                    <span className="font-weight-light">
                      {typeof option[column.property] === 'boolean' ? (option[column.property] ? 'TAK' : 'NIE') : option[column.property]}
                    </span>
                  </td>
                ))}
              </tr>
          )}
          </tbody>
        </table>

        <div className='flex justify-center mt-10'>
          <Button onClick={handleModalClose}>Anuluj</Button>
          <Button mode='primary' onClick={handleSubmit}>Zatwierdź</Button>
        </div>
      </Modal>

      <div className="flex items-center">
        <button type="button" className="float-right" onClick={handleModalOpen}>
          {textChooseButton}
        </button>
        <span className="ml-3" style={{ fontWeight: '600', color: '#4B4E55', fontSize: '14px' }}>
          {selectedOptions?.map(selectedOption => `${selectedOption?.name} `)}
          {!selectedOptions?.length && 'nie wybrano'}
        </span>
      </div>
      <div className="form-control-feedback small min-h-15 text-center" style={{ color: 'red' }}>
        {error}
      </div>
    </div>
  );
}

SelectableSmall.propTypes = {
  formName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired
};

SelectableSmall.defaultProps = {
  textChooseButton: 'Wybierz',
  multi: true
};
