import ModalOnRemove from 'components/Layout/ModalOnRemove';
import React from 'react';
import { toast } from 'react-toastify';
import { t } from 'lang/pl';

const OldCard = props => {
  const handleOnAddClick = event => {
    if(props.isBlocked) {
      toast.error(t.error.toast.statusWarning);
    } else {
      props.create(event);
    }
  };

  const handleOnEditClick = event => {
    if(props.isBlocked) {
      toast.error(t.error.toast.statusWarning);
    } else {
      props.edit(event);
    }
  };

  return (
    <div style={props.style || {}} className={`card ${props.className || ''}`} id={props.id || ''}>
      {props.header && (
        <div className="card-header">
          <span className="card-head justify-content-center align-items-center mb-0">
            {props.header}

            <span className={`float-right ${props.creator ? 'card-link' : ''}`} style={{ cursor: 'pointer', fontSize: '24px' }}>
              {props.contextMenu && (
                <i
                  onClick={typeof props.contextMenu === 'function' ? props.contextMenu : undefined}
                  className={`fa fa-ellipsis-h ${props.creator ? 'creator__btn-icon' : 'details__btn-icon'}`}
                  style={props.contextStyle}
                />
              )}
              {props.create ? (
                <i className={`fa fa-plus ml-2 ${props.creator ? 'creator__btn-icon' : 'details__btn-icon'}`} onClick={handleOnAddClick} />
              ) : null}
              {props.save ? (
                <i className={`fa fa-save ml-2 ${props.creator ? 'creator__btn-icon' : 'details__btn-icon'}`} onClick={props.save} />
              ) : null}
              {!props.hideCustomCreateSection && props.customCreateSection}
              {props.showIcons && props.edit && (
                <i className={`fas fa-pencil-alt ml-2 ${props.creator ? 'creator__btn-icon' : 'details__btn-icon'}`} onClick={handleOnEditClick} />
              )}
              {props.showIcons && props.remove && (
                <ModalOnRemove onRemove={props.remove} />
              )}
            </span>
          </span>
        </div>
      )}
      <div className="card-body" style={props.bodyStyle || {}}>
        {props.title && <h3 className="card-title">{props.title}</h3>}
        {props.subtitle && <h6 className="card-subtitle">{props.subtitle}</h6>}

        {props.children}
      </div>
    </div>
  );
};

export default OldCard;
