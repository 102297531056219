import BooleanCheck from 'components/BooleanCheck';
import Card from 'components/Layout/Card';
import { getAgreementDebtor } from 'containers/Agreements/Creator/Index';
import React, { Fragment, useState } from 'react';
import { BANK_ACCOUNTS } from 'utilities/enums';

import { Selectable } from '../../../../components/Forms';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parseBan } from '../../../../utilities/helpers';
import { useSelector } from 'react-redux';

export default props => {
  const { agreement, version: agreementVersion } = useSelector(state => state.Agreement);
  const [isPlnAccountsActive, setPlnAccountsActive] = useState(agreement?.currency?.name === 'PLN');

  const columns = [
    { name: 'Numer rachunku', getter: 'number', sortable: 1 },
    { name: 'Waluta', getter: 'currency.name', sortable: 1 },
    { name: 'Typ rachunku', getter: 'bank_account_type.name', sortable: 1 },
    { name: 'Nazwa banku', getter: 'bank_name', sortable: 1 },
    { name: 'Data dodania', getter: 'created_date', sortable: 1 },
    { name: 'Dodał', getter: 'user_creator.full_name', sortable: 1 }
  ];

  const title = d => (
    <div className="row mt-2 align-items-center mb-2">
      <div className="col-sm-1 p-2 text-right grey" style={{ fontWeight: '600' }}>
        Dłużnik
      </div>
      <div className="col-sm-5 p-2 " style={{ fontWeight: '600' }}>
        {d.name}
      </div>
      <div className="col-sm-3 p-2 text-right grey" />
      <div className="col-sm-3 p-2 text-right d-flex align-items-center">
        <span className="mr-3">Tylko PLN</span>
        <BooleanCheck field={isPlnAccountsActive} onClick={() => setPlnAccountsActive(!isPlnAccountsActive)} />
      </div>
    </div>
  );

  // Only cession and credit bank accounts
  const factorBankAccounts = agreement?.factor?.bank_accounts?.map(i => ({ ...i, number: parseBan(i) }))
    .filter(account => account?.bank_alias?.identifier === BANK_ACCOUNTS.TYPE.UZNANIOWY || account?.bank_alias?.identifier === BANK_ACCOUNTS.TYPE.WALUTOWY)
    .filter(account => isPlnAccountsActive ? account?.currency?.name === 'PLN' : account);

  return (
    <Card header='Rachunki uznaniowe faktora (techniczne dla dłużników)'>
      <div>
        {agreementVersion?.proposal_debtors?.length > 0 ? (
          agreementVersion?.proposal_debtors.map(item => {
            const agreementDebtor = getAgreementDebtor(item?.debtor?.name, agreement, agreementVersion);

            return agreementDebtor ? (
              <>
                <div className="row mt-2 align-items-center mb-2" style={{ fontWeight: '600', color: '#878F9A' }}>
                  <div className="col-sm-1 p-2 text-right">Dłużnik</div>
                  <div className="col-sm-10">
                    <span className="mr-3" style={{ fontWeight: '600', color: '#4B4E55' }}>
                      {agreementDebtor?.name}
                    </span>
                    <button type="button" className="ml-4" onClick={() => props.toggleForm(`debtor_accounts.${agreementDebtor.id}`)}>
                      Zarządzaj
                    </button>
                  </div>
                </div>
                <div className="row bt bb">
                  <div className="col-3 p-2 grey br">Numer rachunku</div>
                  <div className="col-1 p-2 grey br">Waluta rachunku</div>
                  <div className="col-2 p-2 grey br">Nazwa rachunku</div>
                  <div className="col-2 p-2 grey br">Bank</div>
                  <div className="col-1 p-2 grey br">Data</div>
                  <div className="col-2 p-2 grey">Dodający</div>
                </div>
                {agreementDebtor?.bank_accounts?.map(i => (
                  <Fragment key={i.id}>
                    <RowEditable onRemove={() => props.remove(`debtor/${agreementDebtor.id}/techdebtor_bank_accounts`, i.id)}>
                      <div className="col-sm-3 p-2 br">{parseBan(i)}</div>
                      <div className="col-sm-1 p-2 br">{i.currency?.name}</div>
                      <div className="col-sm-2 p-2 br">{i.bank_alias?.name}</div>
                      <div className="col-sm-2 p-2 br">{i.bank_name}</div>
                      <div className="col-sm-1 p-2 br">{i.created_date}</div>
                      <div className="col-sm-2 p-2">{i.user_creator?.full_name}</div>
                    </RowEditable>
                  </Fragment>
                ))}

                <Selectable
                  multiple
                  fetch={() => {}}
                  visible={props.formVisible(`debtor_accounts.${agreementDebtor?.id}`)}
                  formName={`debtor_accounts.${agreementDebtor?.id}`}
                  field="debtor_accounts"
                  columns={columns}
                  title={title(agreementDebtor?.name)}
                  options={factorBankAccounts}
                  onClickAway={() => props.toggleForm(`debtor_accounts.${agreementDebtor?.id}`)}
                  header="Wybierz rachunek dłużnika"
                  footer="Aby zapisać wciśnij ZATWIERDŹ"
                  onSelected={() => {
                    props.saveForm(`debtor_accounts.${agreementDebtor?.id}`, false);
                    props.toggleForm(`debtor_accounts.${agreementDebtor?.id}`);
                  }}
                />
              </>
            ) : null;
          })
        ) : (
          <div className="row">
            <div className="col-12 p-2">Nie dodano dłużników.</div>
          </div>
        )}
      </div>
    </Card>
  );
};
