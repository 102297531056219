import { get } from 'lodash';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';

import FormActions from '../../actions/forms';

export default connect(
  (state) => ({ forms: state.Forms }),
  (dispatch) => ({
    storeInput: (f, n, v) => {
      v = moment(v).format('DD.MM.YYYY');
      dispatch(FormActions.storeInput(f, n, v));
    }
  })
)((props) => {
  let error = null;
  const errors = props.forms.errors[props.formName];

  if (errors && errors[props.name]) {
    error = errors[props.name];
  }

  let value = get(props.forms.forms[props.formName], props.name, null);

  if ((!value && value !== '')) {
    props.storeInput(props.formName, props.name, new Date());

    if (props.onChange) {
      props.onChange();
    }
  }

  value = value ? moment(value, 'DD.MM.YYYY') : value;

  return value && typeof value !== 'undefined' ? (
    <div className="d-flex align-items-center">
      <span
        className="mr-3"
        style={{
          fontWeight: '600'
          // color: '#4B4E55', fontSize: '14px'
        }}
      >
        {value.format('DD.MM.YYYY')}
      </span>
      {!props.buttonDisabled ? (
        <button type="button" className="ml-3" onClick={props.buttonOnClick}>
          Zmień
        </button>
      ) : null}
    </div>
  ) : (
    <div className="form-group mb-0">
      <DatePicker
        showMonthDropdown
        showYearDropdown
        selected={value ? value.toDate() : value}
        disabled={props.disabled || false}
        dateFormat="dd.MM.yyyy"
        className={`${props.className || ''} form-control ${error ? 'form-control-danger' : ''}`}
        onChange={(date) => {
          props.storeInput(props.formName, props.name, date);

          if (props.onChange) {
            props.onChange();
          }
        }}
      />
      <div className="form-control-feedback small min-h-15" style={{ color: 'red' }}>
        {error}
      </div>
    </div>
  );
});
