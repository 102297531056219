import 'twin.macro';

import Button from 'components/Forms/Button';
import { BaseModal } from 'components/Layout/OldModal';
import Tooltip from 'components/Layout/Tooltip';
import { STATUS_AGREEMENT } from 'containers/Agreements/Details/Index';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { isEnabledOnStatuses } from 'utilities/helpers';

import BooleanCheck from '../../../../components/BooleanCheck';
import { OldCard } from '../../../../components/Layout';
import { formatString, parseMoney } from '../../../../utilities/helpers';

export default props => {
  const { agreement, version } = props;
  const [isEditModal, setEditModal] = useState(false);

  const header = (
    <span className="mb-0 font-weight-light" style={{ color: '#006BFF', fontSize: '32px' }}>
      Umowa {agreement.identifier}
    </span>
  );

  const handleToEdit = event => {
    props.onClickToEdit();
    setEditModal(false);
  };

  const Participants = () =>
    Object.keys(agreement).map(k => {
      let tip;

      if (!agreement[k]) {
        return null;
      }

      switch (k) {
        case 'trade_supervisor':
          tip = `Opiekun handlowy: ${agreement[k].full_name}`;
          break;
        case 'operational_administrator':
          tip = `Administrator operacyjny: ${agreement[k].name}`;
          break;
        case 'agreement_keeper':
          tip = `Opiekun oferty: ${agreement[k].full_name}`;
          break;
        case 'client':
          tip = `Klient: ${agreement[k].name}`;
          break;
        case 'billing_manager':
          tip = `Opiekun ds. rozliczeń: ${agreement[k].full_name}`;
          break;
        case 'factor':
          tip = `Faktor: ${agreement[k].name}`;
          break;
        default:
          return null;
      }

      return (
        <>
          <ReactTooltip id={k} />
          <img alt=""  src="/user.png" width="25" height="25" className="img-circle mr-1" data-tip={tip} data-for={k} />
        </>
      );
    });

  return (
    <OldCard header={header} contextMenu>
      <BaseModal visible={isEditModal} header="Anulowanie wniosku" onClickAway={() => setEditModal(false)}>
        <div className='pt-10 pb-20'>
          <p className='text-red-0 text-center font-bold'>{'Zmiana statusu umowy na "Utworzona" spowoduje usunięcie wszystkich zarejestrowanych dokumentów.'}</p>
          <p className='text-center'>Czy na pewno chcesz to zrobić?</p>
        </div>
        <div className="flex justify-center items-center">
          <Button onClick={() => setEditModal(false)}>Nie</Button>
          <Button mode='primary' onClick={handleToEdit}>Tak</Button>
        </div>
      </BaseModal>

      <div className="row mb-1 mt-3">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Status umowy</div>
            <div className="col-sm-6">
              <span className="badge badge-warning mr-3 pt-1 pb-1 pl-2 pr-2">{props.statuses[agreement.status]}</span>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Utworzył</div>
            <div className="col-sm-6">{agreement?.user_creator?.full_name}</div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-4 text-right grey">Uczestnicy</div>
            <div className="col-sm-8 d-flex align-items-center">
              <Participants />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Produkt / Procedura</div>
            <div className="col-sm-6">
              {agreement.product?.name || '-'} / {agreement.procedure?.name || '-'}
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Utworzono dnia</div>
            <div className="col-sm-6">{agreement.user_creator?.full_name ?? '-'}</div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-4 text-right grey">Dłużników</div>
            <div className="col-sm-8">{formatString(version.debtors_count)}</div>
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Kwota limitu</div>
            <div className="col-sm-6">
              {parseMoney(agreement.limit_amount)} {agreement.currency?.name}
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Ostatnia aktualizacja</div>
            <div className="col-sm-6">{agreement.modified_date || '-'}</div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-4 text-right grey">Sublimity</div>
            <div className="col-sm-8">{parseMoney(agreement.sublimits_sum)}</div>
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Klient</div>
            <div className="col-sm-6">
              <Tooltip id='agreement-client-identifier' tip={agreement.client?.name} label={agreement.client?.identifier} path={`/firmy/${agreement.client?.slug}`} />
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Data zawarcia</div>
            <div className="col-sm-6">{formatString(agreement?.contract_conclusion_date)}</div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-4 text-right grey">Nr wniosku</div>
            <div className="col-sm-8"><Tooltip label={agreement.proposal?.identifier} path={`/wnioski/${agreement.proposal?.slug}`} /></div>
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Regres</div>
            <div className="col-sm-6">
              <BooleanCheck field={agreement.proposal?.recourse} />
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Data wygaśnięcia</div>
            <div className="col-sm-6">{formatString(agreement?.contract_expire_date)}</div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-4 text-right grey">Odstępstwo</div>
            <div className="col-sm-8">{agreement.asd || '-'}</div>
          </div>
        </div>
      </div>
      <div className="row mt-3 mb-3">
        <div className="col-sm-12 flex w-full items-center justify-between">
          <div>
            <button
              type="button"
              className="btn-green mr-3"
              disabled={isEnabledOnStatuses([STATUS_AGREEMENT.UTWORZONA, STATUS_AGREEMENT.ZATWIERDZONA, STATUS_AGREEMENT.ZAWARTA], agreement?.status)}
              onClick={() => toast.error('Brak implementacji.')}
            >
                Nowa wersja
            </button>
            <button
              type="button"
              className="btn-green mr-3"
              disabled={isEnabledOnStatuses([STATUS_AGREEMENT.ZATWIERDZONA, STATUS_AGREEMENT.ZAWARTA], agreement?.status)}
              onClick={() => setEditModal(true)}
            >
                Do edycji
            </button>
            <button
              type="button"
              className="btn-green mr-3"
              disabled={isEnabledOnStatuses([STATUS_AGREEMENT.UTWORZONA], agreement?.status)}
              onClick={props.accept}
            >
                Zatwierdź
            </button>
            <button
              type="button"
              className="btn-green mr-3"
              disabled={isEnabledOnStatuses([STATUS_AGREEMENT.ZATWIERDZONA], agreement?.status)}
              onClick={props.onClickConclude}
            >
                Zawarta
            </button>
            <button
              type="button"
              className="btn-green mr-3"
              disabled={isEnabledOnStatuses([STATUS_AGREEMENT.ZAWARTA, STATUS_AGREEMENT.PRZETERMINOWANA, STATUS_AGREEMENT.WINDYKACJA], agreement?.status)}
              onClick={props.active}
            >
                Aktywuj
            </button>
            <button
              type="button"
              className="btn-green mr-3"
              disabled={isEnabledOnStatuses([STATUS_AGREEMENT.AKTYWNA, STATUS_AGREEMENT.ZAKONCZONA, STATUS_AGREEMENT.PRZETERMINOWANA, STATUS_AGREEMENT.WINDYKACJA], agreement?.status)}
              onClick={props.onShowAnnexesForm}
            >
                Aneksuj
            </button>
            <button
              type="button"
              className="btn-green mr-3"
              disabled={isEnabledOnStatuses([STATUS_AGREEMENT.PRZETERMINOWANA, STATUS_AGREEMENT.WINDYKACJA], agreement?.status)}
              onClick={props.onClickRestructure}
            >
                Restrukturyzuj
            </button>
            <button
              type="button"
              className="btn-green mr-3"
              disabled={isEnabledOnStatuses([STATUS_AGREEMENT.UTWORZONA, STATUS_AGREEMENT.ZATWIERDZONA, STATUS_AGREEMENT.ZAWARTA], agreement?.status)}
              onClick={() => toast.error('Brak implementacji.')}
            >
                Anuluj
            </button>
          </div>
          <button
            type="button"
            className="btn-green mr-3"
            disabled={isEnabledOnStatuses([STATUS_AGREEMENT.AKTYWNA, STATUS_AGREEMENT.W_TRAKCIE_ANEKSOWANIA, STATUS_AGREEMENT.PRZETERMINOWANA], agreement?.status)}
            onClick={props.generateClaim}
          >
              Dodaj wykup
          </button>
        </div>
      </div>
    </OldCard>
  );
};
