import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useState } from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { RatesForm } from '../../Forms/Rates';
import RemovalModal from '../../Forms/RemovalModal';

export const Rates = (props) => {
  const [objectToRemove, setObjectToRemove] = useState({});

  const formName = 'BasicRatesForm';
  const calculation = props.calculation || {};
  const bids = calculation.basic_bids ? calculation.basic_bids : [];

  const maxPaymentDates = [];
  const intrestRates = props.intrestRates || [];
  return (
    <Card header='Stawki podstawowe' onAddClick={() => props.create(formName)}>
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row bb">
              <div className="col-sm-2 p-2 br grey">Nazwa stawki</div>
              <div className="col-sm-1 p-2 br grey">Max wysokość zaliczki</div>
              <div className="col-sm-1 p-2 br grey">Max termin płatności</div>
              <div className="col-sm-1 p-2 br grey">Stawka</div>
              <div className="col-sm-2 p-2 br grey">Czy marża odsetkowa</div>
              <div className="col-sm-1 p-2 br grey">Data stopy</div>
              <div className="col-sm-1 p-2 br grey">Stopa</div>
              <div className="col-sm-1 p-2 br grey">Czy zmienna</div>
              <div className="col-sm-1 p-2 grey">Marża odsetkowa</div>
            </div>
            {bids.map((bid) => (
              <RowEditable
                isObjectToRemove
                key={bid.id}
                onEdit={() => {
                  const editableBid = { ...bid, calculationId: calculation.id };
                  if (!editableBid.intrest_margin) {
                    delete editableBid.intrest_margin;
                  } else {
                    editableBid.intrest_margin.intrest_rate_id = editableBid.intrest_margin.intrest_rate
                      ? editableBid.intrest_margin.intrest_rate.id
                      : 1;
                  }

                  delete editableBid.currency;
                  props.edit(editableBid, formName);
                }}
                onRemove={() => {
                  setObjectToRemove(bid);
                }}
              >
                <div className="col-sm-2 p-2 br">{bid.name}</div>
                <div className="col-sm-1 p-2 br">{bid.max_advance_payment}</div>
                <div className="col-sm-1 p-2 br">{`${bid.max_payment_date} ${bid.max_payment_date === 1 ? 'dzień' : 'dni'}`}</div>
                <div className="col-sm-1 p-2 br">{bid.flat_rate}</div>
                <div className="col-sm-2 p-2 br">
                  <BooleanCheck field={bid.intrest_margin} />
                </div>
                <div className="col-sm-1 p-2 br">{bid.intrest_margin ? bid.intrest_margin.date_of_rate : '-'}</div>
                <div className="col-sm-1 p-2 br">
                  {bid.intrest_margin && bid.intrest_margin.intrest_rate ? bid.intrest_margin.intrest_rate.name : '-'}
                </div>
                <div className="col-sm-1 p-2 br">
                  <BooleanCheck field={bid.intrest_margin && bid.intrest_margin.is_variable} />
                </div>
                <div className="col-sm-1 p-2">{bid.intrest_margin ? bid.intrest_margin.value : 'brak'}</div>
              </RowEditable>
            ))}
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Stawki podstawowe</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <RatesForm
          margin_based_on_tran_risk={props.margin_based_on_tran_risk}
          setLastAvailableDateOfRate={() => props.setLastAvailableDateOfRate(formName)}
          getFlatRate={props.getFlatRate}
          getIntrestRates={props.getIntrestRates}
          intrestRates={intrestRates}
          maxPaymentDates={maxPaymentDates}
          formName={formName}
          offer={props.offer}
          preloadedForm
          visibleOnMount={false}
          clear={props.clear}
          visible={props.formVisible(formName)}
          formVisible={props.formVisible}
          saveForm={() => props.saveForm(formName)}
          onCancel={() => props.onCancel(formName)}
          withButtons
          errors={props.errors[formName]}
          forms={props.forms}
          toggleForm={props.toggleForm}
          hasRiskCategory={calculation.transaction_risk_category}
        />
      </BaseModal>

      <RemovalModal
        visible={Object.keys(objectToRemove).length > 0}
        toggle={() => setObjectToRemove({})}
        remove={() => {
          props.remove(objectToRemove);
          setObjectToRemove({});
        }}
      >
        {objectToRemove && (
          <div className="container-fluid">
            <div className="row bb bt">
              <div className="col-sm-2 p-2 br grey">Nazwa stawki</div>
              <div className="col-sm-1 p-2 br grey">Max wysokość zaliczki</div>
              <div className="col-sm-1 p-2 br grey">Max termin płatności</div>
              <div className="col-sm-1 p-2 br grey">Stawka flat</div>
              <div className="col-sm-2 p-2 br grey">Czy marża odsetkowa</div>
              <div className="col-sm-1 p-2 br grey">Data stopy</div>
              <div className="col-sm-1 p-2 br grey">Stopa</div>
              <div className="col-sm-1 p-2 br grey">Czy zmienna</div>
              <div className="col-sm-1 p-2 grey">Marża odsetkowa</div>
            </div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br">{objectToRemove.name}</div>
              <div className="col-sm-1 p-2 br">{objectToRemove.max_advance_payment}</div>
              <div className="col-sm-1 p-2 br">{objectToRemove.max_payment_date}</div>
              <div className="col-sm-1 p-2 br">{objectToRemove.flat_rate}</div>
              <div className="col-sm-2 p-2 br">
                <BooleanCheck field={objectToRemove.intrest_margin} />

              </div>
              <div className="col-sm-1 p-2 br">{objectToRemove.intrest_margin ? objectToRemove.intrest_margin.date_of_rate : '-'}</div>
              <div className="col-sm-1 p-2 br">
                {objectToRemove.intrest_margin && objectToRemove.intrest_margin.intrest_rate
                  ? objectToRemove.intrest_margin.intrest_rate.name
                  : 'brak'}
              </div>
              <div className="col-sm-1 p-2 br">
                <BooleanCheck field={objectToRemove.intrest_margin && objectToRemove.intrest_margin.is_variable} />

              </div>
              <div className="col-sm-1 p-2">{objectToRemove.intrest_margin ? objectToRemove.intrest_margin.value : 'brak'}</div>
            </div>
          </div>
        )}
      </RemovalModal>
    </Card>
  );
};
