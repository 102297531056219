import { cloneDeep, isEqual, uniqWith } from 'lodash';

const initialState = {
  list: [],
  links: {},
  count: 0,
  currentPage: 1,
  offer: {},
  preview: {},
  calculation: {},
  beneficiaries: [],
  intrestRates: [],
  intrestRatesDate: null
};

export default function Offers(state = initialState, action) {
  switch (action.type) {
    case 'OFFERS_FETCHED':
      return {
        ...state,
        list: action.payload.results,
        links: action.payload.links,
        currentPage: action.payload.current_page,
        count: action.payload.count,
        calculation: {}
      };
    case 'OFFER_SELECTED_FOR_PREVIEW':
      return { ...state, preview: action.payload, calculation: {} };
    case 'OFFER_DETAILS_FETCHED':
      return { ...state, offer: action.payload, calculation: {} };
    case 'OFFER_DETAILS_UPDATED':
      state.offer = Object.assign(state.offer, action.payload);
      return cloneDeep(state);
    case 'OFFER_PEOPLE_FETCHED':
      return { ...state, people: action.payload };
    case 'OFFER_CALCULATION_SELECTED':
      return { ...state, calculation: action.payload };
    case 'OFFER_BENEFICIARIES_FETCHED':
      return { ...state, beneficiaries: action.payload };
    case 'OFFER_CLEAR_BENEFICIARIES':
      return { ...state, beneficiaries: [] };
    case 'OFFER_INTREST_RATES_FETCHED':
      return { ...state, intrestRates: uniqWith(state.intrestRates.concat(action.payload), isEqual) };
    case 'OFFER_INTREST_RATES_DATE_AVAILABLE':
      return { ...state, intrestRatesDate: action.payload };
    default:
      return state;
  }
}
