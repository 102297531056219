import React from 'react';

import BooleanCheck from '../../../components/BooleanCheck';
import {
  Datepicker,
  Form, Input
} from '../../../components/Forms';

export const OtherExpenseForm = (props) => (
  <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
    {props.visible ? (
      <>
        <div className="row mt-3">
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
                Tytuł wydatku
              </label>
              <div className="col-sm-6">
                <Input formName={props.formName} type="text" name="oa_lump_sum_amount" />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
                MPP
              </label>
              <div className="col-sm-6">
                <BooleanCheck field={false} />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
                Kwota
              </label>
              <div className="col-sm-6">
                <Input formName={props.formName} type="text" name="oa_lump_sum_amount" />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
                Termin płatności
              </label>
              <div className="col-sm-6">
                <Datepicker formName={props.formName} name="test" />
              </div>
            </div>
          </div>
        </div>

        {props.withButtons ? (
          <div className="row">
            <div className="mt-4 col-sm-12 text-center">
              <button
                type="button"
                onClick={props.onCancelButtonClick}
                className="btn btn-primary-outline mr-2 font-weight-light"
                style={{ color: '#4B4E55' }}
              >
                Anuluj
              </button>
              <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2 wider font-weight-light">
                {props.submitButtonText || 'Zapisz'}
              </button>
            </div>
          </div>
        ) : null}
      </>
    ) : null}
  </Form>
);
