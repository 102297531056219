import clsx from 'clsx';
import ErrorMessageTemp from 'components/ErrorMessageTemp';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import tw from 'twin.macro';
import { styled } from 'twin.macro';

const StyledInput = styled.input`
  ${tw`h-38`};

  &:focus {
    border-width: 2px;
    box-shadow: none;
  }
`;

export function TextFieldNumber({ name, control, error, className, thousandSeparator, defaultValue, ...props }) {
  return (
    <div className={className}>
      <Controller
        as={
          <NumberFormat
            customInput={TextField}
            thousandSeparator={thousandSeparator}
          />
        }
        name={name}
        error={error}
        variant="outlined"
        control={control}
        defaultValue={defaultValue}
        {...props}
      />
    </div>
  );
}

TextFieldNumber.propTypes = {

};

TextFieldNumber.defaultProps = {
  thousandSeparator: ' ',
  defaultValue: null
};

export default function TextField({ className, name, register, error, ...props }) {
  return (
    <div className={className}>
      <div className='w-full'>
        <StyledInput
          className={clsx('form-control rounded', error && 'border-error')}
          ref={register}
          name={name}
          {...props}
        />
        <ErrorMessageTemp error={error} />
      </div>
    </div>
  );
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired
};

TextField.defaultProps = {
  type: 'text'
};
