import 'twin.macro';

import React from 'react';

import {
  Checkbox,   Form, Input, SelectButton, Textarea
} from '../../../components/Forms';

export const BankAccountsForm = (props) => {
  const t = props.types.find(t => t.name === 'OBCY');
  const c = props.f?.bank_account_type_id === t.value?.toString() ? props.currencies.filter(c => c.name !== 'PLN') : props.currencies;

  return (
    <Form initialForm={{ is_active: 1 }} visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row mt-3   ">
            <div className="col-sm-12">
              <div className="form-group row  d-flex align-items-center ">
                <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right pb-20">
                  Typ
                </label>
                <div className="col-sm-8">
                  <SelectButton
                    tw='max-w-350'
                    buttonOnClick={() => props.selectButtonOnClick(props.formName, 'bank_account_type_id')}
                    formName={props.formName}
                    options={props.types}
                    name="bank_account_type_id"
                    value={props.f.bank_account_type_id}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row   ">
            <div className="col-sm-12">
              <div className="form-group row  d-flex align-items-center">
                <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right pb-20">
                  Waluta
                </label>
                <div className="col-sm-8">
                  <SelectButton
                    tw='max-w-350'
                    buttonOnClick={() => props.selectButtonOnClick(props.formName, 'currency_id')}
                    formName={props.formName}
                    options={c}
                    name="currency_id"
                    value={props.f.currency_id}

                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row   ">
            <div className="col-sm-12">
              <div className="form-group row align-items-center ">
                <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right pb-20">
                  Alias
                </label>
                <div className="col-sm-9">
                  <SelectButton
                    tw='max-w-350'
                    buttonOnClick={() => props.selectButtonOnClick(props.formName, 'bank_alias_id')}
                    formName={props.formName}
                    options={props.bankAccountAliases}
                    name="bank_alias_id"
                    value={props.f.alias}

                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row  ">
            <div className="col-sm-12">
              <div className="form-group row  ">
                <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right pb-20">
                  Numer rachunku
                </label>
                <div className="col-sm-8">
                  <Input style={{ maxWidth: '350px' }} formName={props.formName} name="number" onChange={props.checkBankAccount} />
                </div>
              </div>
            </div>
          </div>
          <div className="row  ">
            <div className="col-sm-12">
              <div className="form-group row  ">
                <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right pb-20">
                  Kod BIC/SWIFT
                </label>
                <div className="col-sm-8">
                  <Input style={{ maxWidth: '350px' }} formName={props.formName} name="swift_or_bic" />
                </div>
              </div>
            </div>
          </div>
          <div className="row  ">
            <div className="col-sm-12">
              <div className="form-group row align-items-center ">
                <div className="col-sm-3 col-form-label label-right pb-30">
                  Nazwa banku
                </div>
                <div className="col-sm-8">
                  {props.f.bank_name && !Number(props.f.bank_name) ? (
                    <div className='pb-24' style={{ fontWeight: '600' }}>{props.f.bank_name}</div>
                  ) : (
                    <SelectButton
                      tw='max-w-350'
                      buttonOnClick={() => props.selectButtonOnClick(props.formName, 'bank_name')}
                      formName={props.formName}
                      options={props.banks}
                      name="bank_name"
                      value={props.f.bank_name}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row  ">
            <div className="col-sm-12">
              <div className="form-group row  ">
                <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right pb-20">
                  Komentarz
                </label>
                <div className="col-sm-8">
                  <Textarea formName={props.formName} name="comment"/>
                </div>
              </div>
            </div>
          </div>
          <div className="row  ">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <div className="col-sm-3 col-form-label label-right pb-20">Aktywny</div>
                <div className="col-sm-3">
                  <Checkbox
                    className="custom-control custom-checkbox d-flex"
                    label=""
                    formName={props.formName}
                    name="is_active"
                  />
                </div>
              </div>
            </div>
          </div>

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancelButtonClick}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}
                >
                  Anuluj
                </button>
                <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
