import { PROPOSAL_EDIT_FORM } from 'containers/Proposals/Details/Components/Header';
import {
  cloneDeep, isObject, omit, pick
} from 'lodash';
import { toast } from 'react-toastify';

import HttpClient from '../utilities/http';

export const GENERATE_TYPE = { CLEAN_VERSION: 1, LAST_VERSION: 2 };

export default {
  changeStatus: ({ id, status }) => HttpClient.put(`claims/${id}/change_status/`, { status }),
  fetch: (params) => HttpClient.get('/proposals/', { params }),
  fetchForForm: (resource, filters = {}) => {
    switch (resource) {
      case 'offers':
        return HttpClient.get('/offers/', { params: { ...filters, status: 1 } });
      case 'factors':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'faktor'
          }
        });
      case 'debtors':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'dłużnik'
          }
        });
      case 'clients':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'client'
          }
        });
      case 'trade_supervisors':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'handlowy'
          }
        });
      case 'operational_administrators':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'administrator operacyjny'
          }
        });
      case 'billing_managers':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'rozliczenia'
          }
        });
      case 'guarantors':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters
          // role_name: 'rozliczenia',
          }
        });
      default:
        return Promise.resolve([]);
    }
  },
  create: (data) => HttpClient.post('/proposals/creator/', data),
  saveForm: (form, data, id, idVersion) => {
    let forcePut = false;
    data = cloneDeep(data);

    let path = `/proposals/${id}/proposal_versions/`;

    const mutate = (x, excludedKeys = []) => {
      x = { ...x };
      Object.keys(x).forEach((key) => {
        if (excludedKeys.indexOf(key) === -1 && (isObject(x[key]) || x[key] === null)) {
          delete x[key];
        }
      });

      return x;
    };

    if (form.indexOf('.') !== -1) {
      const [a, b] = form.split('.');
      data.formId = b;
      form = a;
    }

    const dataEdit = data.id;

    switch (form) {
      case 'DebtorsCreatorForm':
      case 'DebtorsForm':
        path = `${path + idVersion}/debtor/`;

        data = { ...data, debtor_id: data.debtor ? data.debtor.id : null };
        delete data.debtor;

        if (data.basicbids_with_intrestmargins && data.basicbids_with_intrestmargins.length > 0) {
          data.basicbids_with_intrestmargins = data.basicbids_with_intrestmargins.map((b) => {
            const obj = {
              max_advance_payment: b.max_advance_payment,
              max_payment_date: b.max_payment_date,
              flat_rate: b.flat_rate,
              is_intrest_margin: b.is_intrest_margin,
              date_of_rate: b.intrest_margin ? b.intrest_margin.date_of_rate : null,
              intrest_rate_id: b.intrest_margin ? b.intrest_margin.intrest_rate_id : null,
              is_variable: b.intrest_margin ? b.intrest_margin.is_variable : null,
              value: b.intrest_margin ? b.intrest_margin.value : null
            };
            return mutate(obj);
          });
        }

        if (data.multilevelbids && data.multilevelbids.length > 0) {
          data.multilevelbids = data.multilevelbids.map((d) => ({
            ...d,
            levels: d.levels.map((l) => ({
              ...l,
              document_to_buy_types_ids: l.document_to_buy_types.map((x) => x.id)
            }))
          }));
        }

        if (data.securedocuments) {
          data.securedocuments = data.securedocuments.map((d) => {
            d.clauses = d.clauses ? d.clauses.map((c) => c.id) : [];
            d.issuer_id = d.issuer?.id;
            return d;
          });
        }
        break;
      case 'FinancialDataForm':
      case 'FinancialDataCreatorForm':
      case 'FinancialDataCreatorEditForm':
        path = `${path + idVersion}/financial_data/`;
        break;
      case 'LeasingLiabilitiesForm':
      case 'LeasingLiabilitiesCreatorForm':
      case 'LeasingLiabilitiesCreatorEditForm':
        path = `${path + idVersion}/leasing_liabilities/`;
        break;
      case 'CreditLiabilitiesForm':
      case 'CreditLiabilitiesCreatorForm':
      case 'CreditLiabilitiesCreatorEditForm':
        path = `${path + idVersion}/credit_obligations/`;
        break;
      case 'FactoringLiabilitiesForm':
      case 'FactoringLiabilitiesCreatorForm':
      case 'FactoringLiabilitiesCreatorEditForm':
        path = `${path + idVersion}/factoring_obligation/`;
        break;
      case 'OtherLiabilitiesForm':
      case 'OtherLiabilitiesCreatorForm':
      case 'OtherLiabilitiesCreatorEditForm':
        path = `${path + idVersion}/other_obligation/`;
        break;
      case 'SecuresCreatorForm':
      case 'SecuresCreatorEditForm':
      case 'SecuresForm':
      case 'Proposal_Secures':
        path = `${path + idVersion}/secure_documents/`;
        data = mutate({
          ...data,
          clauses: data.clauses?.map((c) => c.id),
          issuer_id: data.issuer?.id ?? data.issuer_id,
          is_factor_pattern: data.is_factor_pattern || false
        }, ['clauses']);

        break;
      case 'Proposal_Documents':
        path = `${path + idVersion}/debtor/${data.debtor_id}/document_to_buy/`;
        data = mutate(data.documentstobuy[0]);
        break;
      case 'QualityCreatorForm':
      case 'FraudCreatorForm':
        path = `${path + idVersion}/criteria/`;

        return Promise.all(
          data.qualities.flatMap((q) => q.definitions).map((def) => mutate(def))
            .map(async def => {
              await def;

              if (def.evaluated_date) {
                return await HttpClient.put(`${path}${def.id}/`, def);
              }
              return await HttpClient.post(path, def);
            })
        );
      case 'QualityForm':
      case 'FraudForm':
        path = `${path + idVersion}/criteria/`;
        data = data?.qualities?.map((q) => q.definitions)?.flat();
        if(data) data = mutate(data[0]);
        if (data && !dataEdit) delete data.id;
        break;
      case 'ScoringForm':
        data = {
          id: data.id,
          company_started_date: data.company_started_date,
          first_invoice_date: data.first_invoice_date,
          no_employees: data.no_employees,
          main_pkd: data.main_pkd_id,
          suppliers_number: data.suppliers_number,
          recipients_number: data.recipients_number,
          accounting_type: data.accounting_type_id,
          accounting_support: data.accounting_support_id,
          activity_range: data.activity_range_id,
          office_ownership: data.office_ownership_id,
          insurance_claims: data.insurance_claims
        };
        break;
      case 'ScoringCreatorForm':
        path = `${path + idVersion}/`;
        return HttpClient.put(path, data);
      case 'RepresentationForm':
      case 'RepresentationCreatorForm':
        path = `${path + idVersion}/representants/`;

        data = {
          id: data.id,
          representation_id: Number(data.representation_id),
          people_in_company_ids: data.people_in_company_ids?.map((i) => i.value)
        };

        break;
      case 'RiskForm':
      case 'RiskCreatorForm':
        data.id = idVersion;
        break;
      case 'PublicLiabilitiesForm':
      case 'PublicLiabilitiesCreatorForm':
        if (data.no_tax_arrears) data.tax_arrears_debt = 0;
        if (data.no_social_security_arrears) data.social_security_arrears_debt = 0;
        data.id = idVersion;
        break;
      case 'DebtorsEditForm':
        path = `${path + idVersion}/debtor/`;
        data = mutate(data);
        break;
      case 'Proposal_BasicBids':
        path = `${path + idVersion}/debtor/${data.debtor_id}/basic_bid/`;
        data = {
          id: data.id,
          max_advance_payment: data.max_advance_payment,
          max_payment_date: data.max_payment_date,
          flat_rate: data.flat_rate,
          is_intrest_margin: data.is_intrest_margin,
          date_of_rate: data.intrest_margin ? data.intrest_margin.date_of_rate : null,
          intrest_rate_id: data.intrest_margin ? data.intrest_margin.intrest_rate_id : null,
          is_variable: data.intrest_margin ? data.intrest_margin.is_variable : null,
          value: data.intrest_margin ? data.intrest_margin.value : null
        };

        data = mutate(data);
        break;
      case 'Proposal_MultilevelBids':
        path = `${path + idVersion}/debtor/${data.debtor_id}/multilevel_bid/`;
        delete data.debtor_id;

        if (data.levels && data.levels.length > 0) {
          data.levels = data.levels.map((level) => ({
            level_no: level.level_no,
            max_advance_payment: Number(level.max_advance_payment),
            document_to_buy_types_ids: level.document_to_buy_types.map((i) => i.id)
          }));
        }
        break;
      case 'Proposal_AdditionalBids':
        path = `${path + idVersion}/debtor/${data.debtor_id}/additional_bid/`;
        delete data.debtor_id;

        break;
      case 'DebtorsRiskForm':
        forcePut = true;
        path = `${path + idVersion}/debtor/${data.debtor.id}/`;
        delete data.formId;
        return HttpClient.put(`${path}risk/`, mutate(data)).then(() => {
          toast.success('Zaktualizowano dane.');
          return data.securedocuments
            .forEach((s) => HttpClient[s.id ? 'put' : 'post'](
              `${path}document_to_buy/${s.id ? `${s.id}/` : ''}`,
              mutate({ ...s, clauses: s.clauses.map((i) => i.id), issuer_id: s.issuer?.id }, ['clauses'])
            //  Problem with promises in this Redux
            )).then(() => {});
        });
      case 'DebtorsRiskEditForm':
        forcePut = true;
        path = `${path + idVersion}/debtor/${data.debtor.id}/risk/`;
        delete data.formId;
        delete data.id;
        data = mutate(data);
        break;
      case 'DebtorsRiskBackgroundForm':
        path = `${path + idVersion}/debtor/${data.formId}/risk/`;
        delete data.formId;
        break;
      case 'ReadinessForm':
      case 'DebtorsReadinessForm':
        forcePut = true;
        path = `${path + idVersion}/`;
        data = pick(data, [
          'declared_use',
          'preparation_commission_type',
          'unused_amount_commission',
          'percentage_unused_amount_commission',
          'limit_commission',
          'start_up_contract',
          'billing_period',
          'counting_method'
        ]);
        data = mutate(data);
        break;
      case 'ConditionsForm':
      case 'DebtorsConditionsForm':
        path = `${path + idVersion}/`;
        forcePut = true;
        data = pick(data, ['max_maturity', 'max_financing_period', 'claim_limit', 'repayment_of_capital', 'split_payment']);
        data = mutate(data);
        break;
      case 'AdministratorForm':
        path = `${path + idVersion}/`;
        forcePut = true;
        data = pick(data, ['oa_lump_sum_amount', 'oa_lump_sum_freq']);
        data = mutate(data);
        break;
      case 'NotesForm':
      case 'NotesCreatorForm':
      case 'NotesCreatorEditForm':
        path = `/proposals/${id}/notes/`;
        break;
      case 'CommmissionsForm':
      case 'CommmissionsCreatorForm':
      case 'CommmissionsEditForm':
        path = `${path + idVersion}/commissions/`;

        data = {
          ...data,
          content_type_id: data.beneficiary.content_type_id,
          object_id: data.beneficiary.id,
          commission_type: data.commission_type,
          unit: data.unit,
          max_value: data.max_value,
          currency_id: 1,
          // currency_id: data.currency_id,
          id: data.id
        };
        break;
      case 'CreditDecision':
        forcePut = true;
        const idDebtor = data.formId;
        data = {
          credit_score_comment: data.credit_score_comment,
          credit_score_decition: data.credit_score_decition
        };
        path = `${path + idVersion}/debtor/${idDebtor}/risk/`;
        break;
      case 'FactorCreditDecision':
        forcePut = true;
        data = pick(data, [
          'credit_decision_1',
          'credit_decision_2',
          'credit_decision_comment_1',
          'credit_decision_comment_2',
          'credit_decision_date_1',
          'credit_decision_date_2',
          'credit_decision_evaluative_1',
          'credit_decision_evaluative_2',
          'credit_decision_role_1',
          'credit_decision_role_2'
        ]);
        path = `${path + idVersion}/credit_decision/`;
        break;
      case PROPOSAL_EDIT_FORM:
        path = `/proposals/${id}/`;
        data = {
          id: data.id,
          limit_amount: data.limit_amount,
          duration_of_the_contract: Number(data.duration_of_the_contract),
          recourse: data.recourse,
          factor_id: data.factor?.id,
          operational_administrator_id: data.operational_administrator?.id,
          trade_supervisor_id: data.trade_supervisor?.id,
          billing_manager_id: data.billing_manager?.id
        };
        return HttpClient.put(path, omit(data, 'id'));
      default:
        break;
    }

    if(data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === null) {
          delete data[key];
        }
      });

      path = data.id ? `${path + data.id}/` : path;
      return data.id || forcePut ? HttpClient.put(path, omit(data, 'id')) : HttpClient.post(path, data);
    }

    return Promise.reject();
  },
  fetchDetails: (id) => HttpClient.get(`/proposals/${id}/`),
  fetchDetailsDebtors: async (id, idVersion) => HttpClient.get(`/proposals/${id}/proposal_versions/${idVersion}/debtors/`).then((r) => r?.data),
  fetchDetailsDebtorsRisks: async (id, idVersion) => HttpClient.get(`/proposals/${id}/proposal_versions/${idVersion}/risks/`).then((r) => r?.data),
  fetchFactor: (id) => HttpClient.get(`/companies/${id}/`).then((r) => r.data),
  fetchClient: (id) => HttpClient.get(`/companies/${id}/`).then((r) => r.data),
  remove: (id, object, objectId, idVersion) => HttpClient.delete(`/proposals/${id}/proposal_versions/${idVersion}/${object || ''}${object && objectId ? `/${objectId}/` : ''}`),
  fetchFromOffer: (proposalId, debtor, object, idVersion) => {
    const request = {
      requested_sublimit: debtor.requested_sublimit,
      mpp: debtor.mpp,
      deducted_rates: debtor.deducted_rates,
      [object]: 1
    };

    return HttpClient.put(`/proposals/${proposalId}/proposal_versions/${idVersion}/debtor/${debtor.id}/`, request);
  },
  updateAmlDecision: (proposalId, decisionId, idVersion) => HttpClient.put(`/proposals/${proposalId}/proposal_versions/${idVersion}/aml_decision/${decisionId}/`),
  copy: (proposalId, type = GENERATE_TYPE.CLEAN_VERSION) => HttpClient.post(`/proposals/${proposalId}/new_version/`, { type }),
  approve: (id, idVersion) => HttpClient.patch(`/proposals/${id}/proposal_versions/${idVersion}/approve/`),
  sendForDecision: (id, idVersion) => HttpClient.patch(`/proposals/${id}/proposal_versions/${idVersion}/send_for_decision/`),

  removeNote: async (proposalId, noteId) => HttpClient.delete(`/proposals/${proposalId}/notes/${noteId}/`)
};
