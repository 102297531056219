import ListWrapper from 'containers/Offers/List/ListWrapper';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CommonActions from '../../../actions/common';

export const FILTERS_FORM = 'OffersFilters';
export const CREATE_OFFER_FORM = 'CreateOfferForm';
export const DELETE_OFFER_FORM = 'DeleteOfferForm';

class OfferList extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.dispatch(CommonActions.setContextMenu());
  }

  render() {
    const rows = this.props.offer.list || [];

    return (
      <ListWrapper
        history={this.props.history}
        offer={this.props.offer}
        actors={this.props.actors}
        common={this.props.common}
        rows={rows}
      />
    );
  }
}

const mapStateToProps = state => ({
  forms: state.Forms,
  common: state.Common,
  offer: state.Offer,
  options: state.Options,
  actors: state.Proposal.formData
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(OfferList)
);
