import Card from 'components/Layout/Card';
import Tooltip from 'components/Layout/Tooltip';
import { isEditableProposal, STATUS_PROPOSAL, STATUS_VERSION } from 'containers/Proposals/Details/Index';
import { t } from 'lang/pl';
import React from 'react';
import { toast } from 'react-toastify';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parseMoney } from '../../../../utilities/helpers';

export const Versions = (props) => {
  const proposal = props.proposal;
  const version = props.version;
  const versions = proposal?.proposal_versions ?? [];

  const handleSelectVersion = item => () => {
    if(versions.length > 1) {
      props.selectVersion(item);
    }
  };

  const handleRemoveVersion = item => () => {
    if(versions.length > 1) {
      props.removeVersion(item);
    }
  };

  return versions.length === 0 ? (
    <div className="row">
      <div className="col-sm-12 p-2">Wersja wniosku została zaakceptowana.</div>
    </div>
  ) : (
    <Card
      header='Wersja wniosku'
      isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
      onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
      isOpen={version?.status !== STATUS_VERSION.ZATWIERDZONA}
      options={{ isCollapsed: false }}
    >
      <div>
        <div className="row bb">
          <div className="flex-10 min-w-130 grey p-2 br">Wersja wniosku</div>
          <div className="flex-20 min-w-160 grey p-2 br">Data utworzenia</div>
          <div className="flex-40 min-w-150 grey p-2 br">Utworzył</div>
          <div className="flex-20 min-w-150 grey p-2 br">Identyfikator wersji</div>
          <div className="flex-20 min-w-150 grey p-2 br">Wnioskowany limit</div>
          <div className="flex-40 min-w-150 grey p-2 br">Zatwierdził</div>
          <div className="flex-20 min-w-160 grey p-2 br">Data zatwierdzenia</div>
          <div className="flex-10 min-w-120 grey p-2">Zatwierdzony</div>
        </div>
        {versions.map(item => (
          <RowEditable
            key={item.id}
            itemId={item.id}
            isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
            className={version.id === item.id ? 'selectedCalculation' : ''}
            isRemoveHidden={versions.length === 1}
            onClick={handleSelectVersion(item)}
            onRemove={handleRemoveVersion(item)}
          >
            <div className="flex-10 min-w-130 p-2 br">{item.version ?? '-'}</div>
            <div className="flex-20 min-w-160 p-2 br">{item.created_date ?? '-'}</div>
            <div className="flex-40 min-w-150 p-2 br">{proposal?.user_creator?.full_name ?? '-'}</div>
            <div className="flex-20 min-w-150 p-2 br"><Tooltip label={proposal?.identifier} /></div>
            <div className="flex-20 min-w-150 p-2 br">{parseMoney(item.max_exposure_f)} {proposal?.currency?.name}</div>
            <div className="flex-40 min-w-150 p-2 br">{item.user_approving?.full_name ?? '-'}</div>
            <div className="flex-20 min-w-160 p-2 br">{item.approval_date ?? '-'}</div>
            <div className="flex-10 min-w-120  p-2"><BooleanCheck field={item.status >= STATUS_PROPOSAL.ZATWIERDZONY} /></div>
          </RowEditable>
        ))}
      </div>
    </Card>
  );
};
