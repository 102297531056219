import {
  cloneDeep, isObject, omit
} from 'lodash';

import HttpClient from '../utilities/http';

export default {
  generate: (charge_ids, category, issue_date) => HttpClient.post('/invoices/creator/', { charge_ids, category, issue_date }),
  fetch: (params) => HttpClient.get('/claims/charges/', { params }),
  create: (data) => HttpClient.post('/payments/creator/', data),
  saveForm: (form, data) => {
    const forcePut = false;
    data = cloneDeep(data);
    let u = '/payments/';

    const mutate = (x, excludedKeys = []) => {
      x = { ...x };
      Object.keys(x).forEach((key) => {
        if (excludedKeys.indexOf(key) === -1 && (isObject(x[key]) || x[key] === null)) {
          delete x[key];
        }
      });

      return x;
    };

    switch (form) {
      case 'TransfersSelectForExportForm':
        u += 'export/';
        break;
      case 'CreateTransferForm':
        data = mutate({
          ...data,
          sender_bank_account_id: data.sender_bank_account?.id,
          recipient_bank_account_id: data.recipient_bank_account?.id
        });
        break;
      case 'CancelAuthorizationForm':
        u += 'cancel_authorization/';
        break;
      default:
        break;
    }

    Object.keys(data).forEach((key) => {
      if (data[key] === null) {
        delete data[key];
      }
    });

    u = data.id ? `${u + data.id}/` : u;

    return data.id || forcePut ? HttpClient.put(u, omit(data, 'id')) : HttpClient.post(u, data);
  },
  fetchDetails: (id) => HttpClient.get(`/payments/${id}/`),
  remove: (agreementId, uri, objectId, version) => HttpClient.delete(`/agreements/${agreementId}/${version ? `agreement_versions/${version}/` : ''}${uri}/${objectId}/`)
};
