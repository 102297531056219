import Card from 'components/Layout/Card';
import React from 'react';
import { formatString, formatStrings } from 'utilities/helpers';


export const Cooperation = ({ company }) => {
  return (
    <Card header='Współpraca'>
      <div>
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Kategoria faktoranta</div>
              <div className="col-sm-9 p-2 ">{formatString(company.client_category)}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Kategoria dłużnika</div>
              <div className="col-sm-9 p-2 ">{formatString(company.debtor_category)}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Rozpoczęcie współpracy</div>
              <div className="col-sm-9 p-2">{formatString(company.beginning_date_of_cooperation)}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Aktywne umowy</div>
              <div className="col-sm-9">
                <div className="row">
                  <div className="col-sm-3 p-2 br grey">Ilość</div>
                  <div className="col-sm-3 p-2 br grey">Wartość</div>
                  <div className="col-sm-3 p-2 br grey">Deklarowane wykorzystanie</div>
                  <div className="col-sm-3 p-2 grey">Bieżące wykorzystanie</div>
                </div>
                <div className="row bt">
                  <div className="col-sm-3 p-2 br ">{formatString(company.active_agreements_count?.number)}</div>
                  <div className="col-sm-3 p-2 br ">{formatString(company.active_agreements_count?.amount)}</div>
                  <div className="col-sm-3 p-2 br ">{formatString(company.active_agreements_count?.declared_usage)}</div>
                  <div className="col-sm-3 p-2 ">{formatString(company.active_agreements_count?.current_usage)}</div>
                </div>
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Ilość zagrożonych umów</div>
              <div className="col-sm-9 p-2 ">{formatString(company.agreements_at_risk_count)}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Ilość umów w windykacji</div>
              <div className="col-sm-9 p-2 ">{formatString(company.agreements_terminated_count)}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Wierzytelności niespłacone</div>
              <div className="col-sm-9 p-2">
                {formatStrings([company.total_amount_of_unsettled_claims?.number, ' / ', company.total_amount_of_unsettled_claims?.total_amount, ' ', 'PLN'])}
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Wierzytelności spłacone</div>
              <div className="col-sm-9">
                <div className="row">
                  <div className="col-sm-6 p-2 br grey">{formatStrings([company.total_amount_of_settled_claims?.number, ' ', 'szt.'])}</div>
                  <div className="col-sm-6 p-2 grey">{formatStrings([company.total_amount_of_settled_claims?.amount, ' ', 'PLN'])}</div>
                </div>
                <div className="row bt">
                  <div className="col-sm-6 p-2 br ">
                    Terminowo
                    {' '}
                    <span className="float-right">{formatString(company.total_amount_of_settled_claims?.in_time_no)}</span>
                  </div>
                  <div className="col-sm-6 p-2">
                    Terminowo
                    {' '}
                    <span className="float-right">{formatString(company.total_amount_of_settled_claims?.in_time_amount)}</span>
                  </div>
                </div>
                <div className="row bt">
                  <div className="col-sm-6 p-2 br ">
                    Przeterminowane pow. 30 dni
                    <span className="float-right">{formatString(company.total_amount_of_settled_claims?.overtime_0_30_days_amount)}</span>
                  </div>
                  <div className="col-sm-6 p-2 ">
                    Przeterminowane pow. 30 dni
                    <span className="float-right">{formatString(company.total_amount_of_settled_claims?.overtime_0_30_days_no)}</span>
                  </div>
                </div>
                <div className="row bt">
                  <div className="col-sm-6 p-2 br ">
                    Przeterminowane pow. 60 dni
                    <span className="float-right">{formatString(company.total_amount_of_settled_claims?.overtime_30_60_days_amount)}</span>
                  </div>
                  <div className="col-sm-6 p-2 ">
                    Przeterminowane pow. 60 dni
                    <span className="float-right">{formatString(company.total_amount_of_settled_claims?.overtime_30_60_days_count)}</span>
                  </div>
                </div>
                <div className="row bt">
                  <div className="col-sm-6 p-2 br ">
                    Przeterminowane pow. 90 dni
                    <span className="float-right">{formatString(company.total_amount_of_settled_claims?.overtime_60_90_days_amount)}</span>
                  </div>
                  <div className="col-sm-6 p-2 ">
                    Przeterminowane pow. 90 dni
                    <span className="float-right">{formatString(company.total_amount_of_settled_claims?.overtime_60_90_days_count)}</span>
                  </div>
                </div>
                <div className="row bt">
                  <div className="col-sm-6 p-2 br ">
                    Przeterminowane pow. 120 dni
                    <span className="float-right">{formatString(company.total_amount_of_settled_claims?.overtime_90_120_days_amount)}</span>
                  </div>
                  <div className="col-sm-6 p-2 ">
                    Przeterminowane pow. 120 dni
                    <span className="float-right">{formatString(company.total_amount_of_settled_claims?.overtime_90_120_days_count)}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Ilość dłużników zgłoszonych</div>
              <div className="col-sm-9 p-2 ">{formatString(company.reported_debtors_count)}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Ilość dłużników obsługiwanych</div>
              <div className="col-sm-9 p-2 ">{formatString(company.served_debtors_count)}</div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
