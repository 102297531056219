import React from 'react';

import { Datepicker, Form, Input, Select } from '../../../components/Forms';

const InsuranceClaimsForm = props => {
  const f = props.forms?.[props.formName] ?? {};
  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row mt-3">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
                  Ubezpieczony
                </label>
                <div className="col-sm-6">
                  {f.insured?.name ?? 'brak'}

                  <button
                    type="button"
                    className="ml-4 mr-auto"
                    onClick={() => {
                      props.toggleForm('insured');
                      props.fetchIssuers();
                    }}>
                    Zmień
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row mt-3">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
                  Termin płatności
                </label>
                <div className="col-sm-6">
                  <Select
                    formName={props.formName}
                    options={Array(180)
                      .fill(null)
                      .map((_, i) => ({ value: i + 1, name: i + 1 }))}
                    name="date_of_payment"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
                  Przyznany limit
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="granted_limit" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
                  Od dnia
                </label>
                <div className="col-sm-6">
                  <Datepicker formName={props.formName} name="from_date" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
                  Wygasa
                </label>
                <div className="col-sm-6">
                  <Datepicker formName={props.formName} name="to_date" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
                  Udział własny
                </label>
                <div className="col-sm-6">
                  <Select
                    formName={props.formName}
                    name="own_contribution"
                    options={Array(100)
                      .fill(null)
                      .map((_, i) => ({ value: i + 1, name: `${i + 1}%` }))}
                  />
                </div>
              </div>
            </div>
          </div>

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancelButtonClick}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}>
                  Anuluj
                </button>
                <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};

export default InsuranceClaimsForm;
