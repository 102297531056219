import { ColNameBig } from 'components/Forms/Col';
import React from 'react';

import {
  Checkbox,   Form, Input, Select,
  SelectButton } from '../../../components/Forms';

export const ContactsForm = (props) => (
  <Form initialForm={{ is_active: 1 }} visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
    {props.visible ? (
      <>
        <div className="row mt-3 pr-5">
          <div className="col-sm-5">
            <div className="form-group row  align-items-center">
              <ColNameBig>Typ kontaktu</ColNameBig>
              <div className={`${props.typeSelected ? 'col-sm-6 mt-05' : 'col-sm-6 mt-05'}`}>
                <SelectButton
                  buttonOnClick={() => props.clear(props.formName, 'type')}
                  buttonDisabled={props.selectButtonDisabled}
                  formName={props.formName}
                  options={props.contactTypes}
                  name="type"
                  value={props.selectButtonValue}
                />
              </div>
            </div>
          </div>
          {props.typeSelected && (
            <div className="col-sm-7">
              <div className="form-group row  align-items-center">
                {props.typeSelected === 'phone' ? (
                  <>
                    <div className="col-sm-3">
                      <Select
                        number
                        isClearable={false}
                        formName={props.formName}
                        options={props.countryCallingCodes}
                        name="country_calling_code_id"
                      />
                    </div>
                    <div className="col-sm-4">
                      <Input formName={props.formName} type="text" name="value"/>
                    </div>
                  </>
                ) : (
                  <div className="col-sm-7">
                    <Input formName={props.formName} name="value"/>
                  </div>
                )}
                {props.canBeActive ? (
                  <div className="col-sm-5">
                    <Checkbox label="Czy aktywny" formName={props.formName} name="is_active" />
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
        {props.withButtons ? (
          <div className="row">
            <div className="mt-4 col-sm-12 text-center">
              <button
                type="button"
                onClick={props.onCancelButtonClick}
                className="btn btn-primary-outline mr-2 font-weight-light"
                style={{ color: '#4B4E55' }}
              >
                Anuluj
              </button>
              <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                Zapisz
              </button>
            </div>
          </div>
        ) : null}
      </>
    ) : null}
  </Form>
);
