import Card from 'components/Layout/Card';
import { STATUS_AGREEMENT_VERSION } from 'containers/Agreements/Details/Index';
import React, { useEffect } from 'react';

import { ClaimBidsForm } from '../../Forms/Bids';

const getBidId = (claim, debtor) =>
  debtor?.basicbids_with_intrestmargins
    ?.map(i => ({ id: i.id, days: i.max_payment_date }))
    .sort((a, b) => a.days - b.days)
    .find(i => i.days >= claim?.expected_funding_period)?.id;

export default props => {
  const debtor = props.claim?.agreement?.agreement_versions
    ?.find(item => item.status === STATUS_AGREEMENT_VERSION.ZATWIERDZONY)
    ?.agreement_debtors?.find(x => x.id === props.claim?.debtor?.id) ?? {};

  useEffect(() => {
    if (props.populateForm) {
      props.populateForm(props.formName, {
        content_type: props.claim?.content_type ?? props.claimData?.content_types?.basic_bid_id,
        object_id: props.claim?.object_id ?? getBidId(props.claim, debtor)
      });
    }
    // eslint-disable-next-line
  }, [props.claim]);

  return (
    <Card mode='creator' header='+ Wybierz stawkę' onSaveClick={() => props.saveForm(props.formName, false)}>
      <div>
        <ClaimBidsForm
          debtor={debtor}
          claimData={props.claimData}
          form={props.forms?.[props.formName]}
          storeInput={props.storeInput}
          formName={props.formName}
          claim={props.claim}
        />
      </div>
    </Card>
  );
};
