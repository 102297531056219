import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import FormActions from '../../actions/forms';

class Form extends React.Component {
  constructor(props) {
    super(props);

    const form = this.props.state.Forms.forms[this.props.formName];
    const initialForm = { ...this.props.initialForm } || {};

    if (!form) {
      const rules = this.props.rules || {};
      this.props.dispatch(FormActions.initialize(this.props.formName, initialForm, rules));
    }

    if ((this.props.visibleOnMount || props.history?.location?.hash) && this.props.state.Forms.options.visible.indexOf(this.props.formName) === -1) {
      this.props.dispatch(FormActions.toggleForm(this.props.formName));
    }
  }

  componentWillUnmount() {
    // this.props.dispatch(FormActions.deleteForm(this.props.formName));
  }

  render() {
    const errors = this.props.state.Forms.errors[this.props.formName] || {};
    const classNames = this.props.className || '';

    const ErrorsComponent = () => ((errors?.non_field_errors?.length > 0 || (this.props.forceErrors && Object.keys(errors).length > 0)) ? (
      <div className="row alert-danger p-3 m-3">
        <div className="col-12 d-flex align-items-center">
          <span>{errors.non_field_errors?.[0]}</span>
          {this.props.forceErrors ? <span>{Object.values(errors)?.[0]?.[0]}</span> : null}
        </div>
      </div>
    ) : null);

    return this.props.asDiv ? (
      <div className={this.props.divClasses || ''}>
        {this.props.noErrors ? null : <ErrorsComponent />}
        {this.props.children}
      </div>
    ) : (
      <form id={this.props.id} className={`form-container ${classNames}`} style={this.props.style} onSubmit={(e) => e.preventDefault()}>
        {this.props.noErrors ? null : <ErrorsComponent />}
        {this.props.children}
      </form>
    );
  }
}

const mapStateToProps = (state) => ({ state });

export default withRouter(connect(
  mapStateToProps,
  null
)(Form));
