import DecisionString from 'components/Format/DecisionString';
import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import Tooltip from 'components/Layout/Tooltip';
import { STATUS_PROPOSAL } from 'containers/Proposals/Details/Index';
import { t } from 'lang/pl';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import FactorCreditDecision from '../../Forms/FactorCreditDecision';

export default (props) => {
  const [stage, setStage] = useState('I');
  const proposal = props.proposal || {};
  const version = props.version || {};
  const formName = 'FactorCreditDecision';

  const ratingDecisions = props.common && props.common.proposalData ? props.common.proposalData.rating_decisions : {};

  const edit = (s) => {
    if(props.proposal?.status === STATUS_PROPOSAL.OCZEKUJE_NA_DECYZJE) {
      setStage(s);
      const editable = s === 'I' ? {
        credit_decision_1: version.credit_decision_1,
        credit_decision_comment_1: version.credit_decision_comment_1,
        credit_decision_date_1: version.credit_decision_date_1 ?? moment().format('DD.MM.YYYY'),
        credit_decision_evaluative_1: props.user?.id,
        credit_decision_role_1: version.credit_decision_role_1?.id
      } : {
        credit_decision_2: version.credit_decision_2,
        credit_decision_comment_2: version.credit_decision_comment_2,
        credit_decision_date_2: version.credit_decision_date_2 ?? moment().format('DD.MM.YYYY'),
        credit_decision_evaluative_2: props.user?.id,
        credit_decision_role_2: version.credit_decision_role_2?.id
      };
      props.edit(editable, formName);
    } else {
      toast.error(t.error.toast.statusWarning);
    }
  };

  return (
    <Card
      header='Faktorant decyzja kredytowa osobowa'
      isBlocked={proposal?.status >= STATUS_PROPOSAL.OCZEKUJE_NA_DECYZJE}
      onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
    >
      <div className="collapse show" id="metryka">
        <div className="row bb">
          <div className="col-sm-12 p-2" style={{ color: '#1DB393' }}>
            Etap I
            <span className="float-right" style={{ cursor: 'pointer' }}>
              <i className="fas fa-pencil-alt ml-2" onClick={() => edit('I')} />
            </span>
          </div>
        </div>

        <div className="row bb">
          <div className="flex-10 max-w-180 p-2 br grey">Data oceny</div>
          <div className="flex-15 p-2 br grey">Oceniający</div>
          <div className="flex-10 max-w-150 p-2 br grey">Etap I</div>
          <div className="flex-15 max-w-180 p-2 br grey">Decyzja kredytowa</div>
          <div className="flex-10 max-w-150 p-2 br grey">Odstępstwa</div>
          <div className="flex-15 max-w-180 br p-2 grey">Nr wniosku</div>
          <div className="flex-25 p-2 grey">Klient</div>
        </div>
        <div className="row bb">
          <div className="flex-10 max-w-180 p-2 br ">{version.credit_decision_date_1 || '-'}</div>
          <div className="flex-15 p-2 br ">{version.credit_decision_evaluative_1 ? version.credit_decision_evaluative_1.full_name : '-'}</div>
          <div className="flex-10 max-w-150 p-2 br ">{version.credit_decision_role_1 ? version.credit_decision_role_1.name : '-'}</div>
          <div className="flex-15 max-w-180 p-2 br "><DecisionString value={ratingDecisions[version.credit_decision_1]}/></div>
          <div className="flex-10 max-w-150 p-2 br "><Tooltip label='-' tip='Brak implementacji' /></div>
          <div className="flex-15 max-w-180 br p-2"><Tooltip label={version.identifier} /></div>
          <div className="flex-25 p-2">{proposal.client?.name ?? '-'}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-12 p-2 grey">Odstępstwa / komentarz</div>
        </div>
        <div className="row bb">
          <div className="col-sm-12 p-2">{version.credit_decision_comment_1 || '-'}</div>
        </div>

        <div className="row bb">
          <div className="col-sm-12 p-2" style={{ color: '#1DB393' }}>
            Etap II
            <span className="float-right" style={{ cursor: 'pointer' }}>
              <i className="fas fa-pencil-alt ml-2" onClick={() => edit('II')} />
            </span>
          </div>
        </div>
        <div className="row bb">
          <div className="flex-10 max-w-180 p-2 br grey">Data oceny</div>
          <div className="flex-15 p-2 br grey">Oceniający</div>
          <div className="flex-10 max-w-150 p-2 br grey">Etap II</div>
          <div className="flex-15 max-w-180 p-2 br grey">Decyzja kredytowa</div>
          <div className="flex-10 max-w-150 p-2 br grey">Odstępstwa</div>
          <div className="flex-15 max-w-180 br p-2 grey">Nr wniosku</div>
          <div className="flex-25 p-2 grey">Klient</div>
        </div>
        <div className="row bb">
          <div className="flex-10 max-w-180 p-2 br ">{version.credit_decision_date_2 || '-'}</div>
          <div className="flex-15 p-2 br ">{version.credit_decision_evaluative_2 ? version.credit_decision_evaluative_2.full_name : '-'}</div>
          <div className="flex-10 max-w-150 p-2 br ">{version.credit_decision_role_2 ? version.credit_decision_role_2.name : '-'}</div>
          <div className="flex-15 max-w-180 p-2 br "><DecisionString value={ratingDecisions[version.credit_decision_2]}/></div>
          <div className="flex-10 max-w-150 p-2 br "><Tooltip label='-' tip='Brak implementacji' /></div>
          <div className="flex-15 max-w-180 br p-2"><Tooltip label={version.identifier} /></div>
          <div className="flex-25 p-2">{proposal.client?.name ?? '-'}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-12 p-2 grey">Odstępstwa / komentarz</div>
        </div>
        <div className="row bb">
          <div className="col-sm-12 p-2">{version.credit_decision_comment_2 || '-'}</div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>
              Faktorant decyzja kredytowa osobowa Etap
              {' '}
              {stage}
            </span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <FactorCreditDecision
          user={props.user}
          stage={stage}
          forms={props.forms}
          proposalData={props.common ? props.common.proposalData : {}}
          offerData={props.common ? props.common.offerData : {}}
          roles={props.common ? props.common.systemRoles : []}
          proposal={props.proposal}
          version={props.version}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(formName)}
          formVisible={props.formVisible}
          onCancelButtonClick={() => props.toggleForm(formName)}
          toggleForm={props.toggleForm}
          formName={formName}
          withButtons
          submitForm={() => props.saveForm(formName)}
        />
      </BaseModal>
    </Card>
  );
};
