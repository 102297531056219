import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useState } from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { ContactsForm } from '../../Forms/Contacts';
import RemovalModal from '../../Forms/RemovalModal';

export const SocialMedia = (props) => {
  const { person } = props;
  const [objectToRemove, setObjectToRemove] = useState({});

  const formName = 'SocialMediaForm';
  const editFormName = 'SocialMediaEditForm';

  const contacts = person && person.contacts ? person.contacts : [];
  const modalSelectedType = props.forms && props.forms[formName] ? props.forms[formName].type : null;
  const editModalSelectedType = props.forms && props.forms[editFormName] ? props.forms[editFormName].type : null;

  const allowedContactTypes = ['facebook', 'twitter', 'linkedin', 'www'];
  const contactTypes = props.contactTypes ? props.contactTypes.filter((i) => allowedContactTypes.indexOf(i.value) !== -1) : [];

  const facebook = contacts
    .filter((i) => i.type === 'facebook')
    .map((i) => (
      <a role="button" style={{ marginRight: '0.5rem' }} href={`https://facebook.com/${i.value}`}>
        {i.value}
      </a>
    ))[0] || null;
  const www = contacts
    .filter((i) => i.type === 'www')
    .map((i) => (
      <a role="button" style={{ marginRight: '0.5rem' }} href={i.value}>
        {i.value}
      </a>
    ))[0] || null;
  const linkedin = contacts
    .filter((i) => i.type === 'linkedin')
    .map((i) => (
      <a role="button" style={{ marginRight: '0.5rem' }} href={`https://linkedin.com/${i.value}`}>
        {i.value}
      </a>
    ))[0] || null;
  const twitter = contacts
    .filter((i) => i.type === 'twitter')
    .map((i) => (
      <a role="button" style={{ marginRight: '0.5rem' }} href={`https://twitter.com/${i.value}`}>
        {i.value}
      </a>
    ))[0] || null;

  let selectOptions = allowedContactTypes.filter((type) => contacts.filter((c) => c.type === type).length === 0);
  selectOptions = props.contactTypes ? props.contactTypes.filter((i) => selectOptions.indexOf(i.value) !== -1) : [];

  return (
    <Card header='Social media' onAddClick={selectOptions.length > 0 ? () => props.create(formName) : null}>
      <div>
        <RowEditable
          isObjectToRemove
          onEdit={
            selectOptions.map((i) => i.value).indexOf('facebook') === -1
              ? () => props.edit(editFormName, contacts.find((i) => i.type === 'facebook'))
              : null
          }
          onRemove={
            selectOptions.map((i) => i.value).indexOf('facebook') === -1
              ? () => setObjectToRemove(contacts.find((i) => i.type === 'facebook'))
              : null
          }
        >
          <div className="col-sm-3 p-2 br grey">
            <i className="fab fa-facebook-square mr-3" />
            Facebook
          </div>
          <div className="col-sm-9 p-2">{facebook}</div>
        </RowEditable>
        <RowEditable
          isObjectToRemove
          onEdit={
            selectOptions.map((i) => i.value).indexOf('linkedin') === -1
              ? () => props.edit(editFormName, contacts.find((i) => i.type === 'linkedin'))
              : null
          }
          onRemove={
            selectOptions.map((i) => i.value).indexOf('linkedin') === -1
              ? () => setObjectToRemove(contacts.find((i) => i.type === 'linkedin'))
              : null
          }
        >
          <div className="col-sm-3 p-2 br grey">
            <i className="fab fa-linkedin mr-3" />
            LinkedIn
          </div>
          <div className="col-sm-9 p-2">{linkedin}</div>
        </RowEditable>
        <RowEditable
          isObjectToRemove
          onEdit={
            selectOptions.map((i) => i.value).indexOf('twitter') === -1
              ? () => props.edit(editFormName, contacts.find((i) => i.type === 'twitter'))
              : null
          }
          onRemove={
            selectOptions.map((i) => i.value).indexOf('twitter') === -1
              ? () => setObjectToRemove(contacts.find((i) => i.type === 'twitter'))
              : null
          }
        >
          <div className="col-sm-3 p-2 br grey">
            <i className="fab fa-twitter-square mr-3" />
            Twitter
          </div>
          <div className="col-sm-9 p-2">{twitter}</div>
        </RowEditable>
        <RowEditable
          isObjectToRemove
          onEdit={
            selectOptions.map((i) => i.value).indexOf('www') === -1
              ? () => props.edit(editFormName, contacts.find((i) => i.type === 'www'))
              : null
          }
          onRemove={
            selectOptions.map((i) => i.value).indexOf('www') === -1 ? () => setObjectToRemove(contacts.find((i) => i.type === 'www')) : null
          }
        >
          <div className="col-sm-3 p-2 br grey">
            <i className="fas fa-link mr-3" />
            www
          </div>
          <div className="col-sm-9 p-2">{www}</div>
        </RowEditable>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Kontakty</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <ContactsForm
          clear={props.clear}
          visibleOnMount={false}
          visible={props.formVisible(formName)}
          formName={formName}
          contactTypes={selectOptions}
          selectButtonDisabled
          selectButtonValue={modalSelectedType}
          typeSelected={modalSelectedType}
          withButtons
          submitForm={() => props.saveForm(formName)}
          onCancelButtonClick={() => props.toggleForm(formName)}
        />
      </BaseModal>

      <BaseModal
        visible={props.formVisible(editFormName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Kontakty</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(editFormName)}
      >
        <ContactsForm
          clear={props.clear}
          visibleOnMount={false}
          visible={props.formVisible(editFormName)}
          formName={editFormName}
          contactTypes={contactTypes}
          selectButtonDisabled
          selectButtonValue={editModalSelectedType}
          typeSelected={editModalSelectedType}
          withButtons
          submitForm={() => props.saveForm(editFormName)}
          onCancelButtonClick={() => props.toggleForm(editFormName)}
        />
      </BaseModal>

      <RemovalModal
        visible={Object.keys(objectToRemove).length > 0}
        toggle={() => setObjectToRemove({})}
        remove={() => {
          props.remove(objectToRemove.id);
          setObjectToRemove({});
        }}
      >
        {objectToRemove && (
          <div className="container-fluid">
            <div className="row bb bt grey">
              <div className="col-sm-2 p-2 br">
                <i className="fa fa-envelope mr-2" />
                Kanał komunikacji
              </div>
              <div className="col-sm-2 p-2 br">
                <i className="fa fa-envelope mr-2" />
                Wartość
              </div>
              <div className="col-sm-2 p-2 br">Wartość</div>
              <div className="col-sm-2 p-2 br">Deaktywował</div>
              <div className="col-sm-2 p-2 br">Ważny do</div>
              <div className="col-sm-2 p-2">Aktywny</div>
            </div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br">{objectToRemove.type}</div>
              <div className="col-sm-2 p-2 br">{objectToRemove.value}</div>
              <div className="col-sm-2 p-2 br">{objectToRemove.user_creator ? objectToRemove.user_creator.full_name : 'brak'}</div>
              <div className="col-sm-2 p-2 br">{objectToRemove.user_deactivator ? objectToRemove.user_deactivator.full_name : '-'}</div>
              <div className="col-sm-2 p-2 br">{objectToRemove.valid_until_date || '-'}</div>
              <div className="col-sm-2 p-2">
                <BooleanCheck field={objectToRemove.is_active} />

              </div>
            </div>
          </div>
        )}
      </RemovalModal>
    </Card>
  );
};
