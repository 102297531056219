import FormActions from 'actions/forms';
import api from 'api';
import { Container, MainContainer, SidebarContainer } from 'components/Layout';
import { Modal } from 'components/Layout/Modal';
import { Pagination } from 'components/Layout/Pagination';
import OptionsPanel from 'components/OptionsPanel';
import SortableTable from 'components/SortableTable';
import { CreatePersonForm } from 'containers/Persons/List/Create';
import { SearchBar } from 'containers/SearchBar';
import Tab from 'containers/Tab';
import useContainers from 'containers/useContainers';
import { setContextMenu } from 'ducks/layout';
import useData from 'hooks/useData';
import useModals from 'hooks/useModals';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Constants from 'utilities/constants';

import PersonSidebarView from './Sidebar';

export const CREATE_PERSON_FORM = 'CreatePersonForm';
export const DELETE_PERSON_FORM = 'DeletePersonForm';

export default function PersonList() {
  const { limit } = useSelector(state => state.Options);
  const dispatch = useDispatch();

  const { sendRequest, sendSilentRequest, loading, data } = useData({ loadingOnRequest: false });
  const { currentObject, orderedColumn, orderByAsc, handleShowPreview, handleSortTable } = useContainers(data);
  const { instance, handleOpenModal, handleCloseModal } = useModals();

  useEffect(() => {
    dispatch(setContextMenu());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line
  }, []);

  const handleFetchData = () => {
    sendRequest(api.persons.getList({ limit }));
  };

  const handleSearchInput = value => {
    sendRequest(api.persons.getList({ term: value }));
  };

  const handleChangePage = (page, limit) => {
    sendRequest(api.persons.getList({ page, limit }));
  };

  const handleChangeFilters = filter => {
    sendRequest(api.persons.getList({ modifiedDate: filter }));
  };

  const handleSortingTable = (key, columnNumber) => {
    sendRequest(api.persons.getList({ direction: orderByAsc ? 'ascending' : 'descending', orderBy: key }));
    handleSortTable(columnNumber);
  };

  const removePerson = () => dispatch(FormActions.toggleForm(DELETE_PERSON_FORM));

  const remove = id => {
    sendSilentRequest(api.persons.removePerson({ id }), () => {
      toast.success('Usunięto obiekt.');
      sendRequest(api.persons.getList());
    });
  };

  return (
    <Container>
      <MainContainer>
        <SearchBar title="Lista osób" records={data?.count} onSubmit={handleSearchInput} />

        <Tab name={Constants.Tabs.Person.name}>
          <OptionsPanel onClickAddButton={handleOpenModal(CreatePersonForm.name)} onChangeFilters={handleChangeFilters} />

          <SortableTable
            loading={loading}
            results={data?.results}
            orderedColumn={orderedColumn}
            onItemClick={handleShowPreview}
            onSortingTable={handleSortingTable}
          />
        </Tab>

        <Modal
          isOpen={instance === CreatePersonForm.name}
          header='+ Kreator dodawania osoby krok 1 z 2'
          footer="W celu dodania nowej osoby, kliknij przycisk Zapisz."
          onClose={handleCloseModal}
        >
          <CreatePersonForm onSubmit={handleFetchData} onClose={handleCloseModal} />
        </Modal>

        {data && <Pagination withSelect currentPage={data.current_page} count={data.count} onChangePage={handleChangePage}  />}
      </MainContainer>
      <SidebarContainer>
        <PersonSidebarView loading={loading} person={currentObject} removePerson={removePerson} remove={remove} />
      </SidebarContainer>
    </Container>
  );
}
