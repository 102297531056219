import 'twin.macro';

import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import Tooltip from 'components/Layout/Tooltip';
import React from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { formatNip } from '../../../../utilities/helpers';
import { CompanyForm } from '../../Forms/Main';

export const Metrics = (props) => {
  const { company } = props;

  const formName = 'CompanyForm';

  return (
    <Card header='Dane podstawowe' onEditClick={() => props.edit(formName)}>
      <div className="collapse show" id="metryka">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Identyfikator firmy</div>
              <div className="col-sm-6 p-2 br"><Tooltip label={company.identifier} /></div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">ID firmy</div>
              <div className="col-sm-6 p-2 br">{company.id}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Forma prawna</div>
              <div className="col-sm-6 p-2 br">{company.legal_form ? company.legal_form.name : ''}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">NIP</div>
              <div className="col-sm-6 p-2 br">{formatNip(company.nip)}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">REGON</div>
              <div className="col-sm-6 p-2 br">{company.regon}</div>
            </div>
            <div className="row">
              <div className="col-sm-6 p-2 br grey">KRS/CEIDG(ID)</div>
              <div className="col-sm-6 p-2 br">{company.krs || company.ceidg}</div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row bb">
              <div className="col-sm-4 p-2 br grey">Branża</div>
              <div className="col-sm-8 p-2">{company.industry ? company.industry.name : ''}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-4 p-2 br grey">Sposób reprezentacji</div>
              <div className="col-sm-8 p-2">{company.representation ? company.representation.name : ''}</div>
            </div>

            <div className="row bb">
              <div className="col-sm-4 p-2 br grey">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="d-block">Podatnik VAT</div>
                  </div>
                </div>
              </div>
              <div className="col-sm-8 pl-0 pr-0">
                <div className="col-sm-12">
                  <div className="d-block">
                    <div className="row">
                      <div className="col-sm-12 p-2">
                        <BooleanCheck field={company.is_vat_payer} />
                      </div>
                    </div>
                  </div>
                </div>
                {company.is_vat_payer ? (
                  <>
                    <div className="col-sm-12">
                      <div className="d-block">
                        <div className="row bb bt">
                          <div className="col-sm-5 p-2 br grey">Data sprawdzenia</div>
                          <div className="col-sm-7 p-2">{company.vat_date_checked || '-'}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="d-block">
                        <div className="row">
                          <div className="col-sm-5 p-2 br grey">Sprawdził</div>
                          <div className="col-sm-7 p-2">{company.vat_user_checked ? company.vat_user_checked.full_name : 'brak'}</div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Dane podstawowe firmy</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <CompanyForm
          companyData={props.companyData}
          companyId={props.company ? props.company.id : 'brak'}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          withButtons
          onSubmit={() => props.saveForm(formName)}
          onCancel={() => props.toggleForm(formName)}
          visible={props.formVisible(formName)}
          formName={formName}
          storeInput={props.storeInput}
        />
      </BaseModal>
    </Card>
  );
};
