import { ColNameBig } from 'components/Forms/Col';
import React from 'react';

import { Checkbox, Datepicker, Form, Input, Select, Textarea } from '../../../components/Forms';
import Secures from './Debtors/Secures';

const mapper = i => (i ? i.map(x => ({ value: x.id, name: x.name })) : []);

export default props => {
  const d = props.proposalData || {};
  const form = props.forms[props.formName] || {};
  const p = props.proposal || { debtors: [] };

  const ratingTypes = mapper(d.rating_types);
  const ratingClasses =
    form.rating_type_id && d.rating_types.find(i => i.id === form.rating_type_id)
      ? mapper(d.rating_types.find(i => i.id === form.rating_type_id).rating_classes)
      : [];

  const informationTypes = mapper(d.information_agencies_data);
  const informationClasses =
    form.information_agency_id && d.information_agencies_data.find(i => i.id === form.information_agency_id)
      ? mapper(d.information_agencies_data.find(i => i.id === form.information_agency_id).information_agency_classes)
      : [];

  const ratingAgencies = mapper(d.rating_agencies_data);
  const ratingAgencyScores =
    form.rating_agency_id && d.rating_agencies_data.find(i => i.id === form.rating_agency_id)
      ? mapper(d.rating_agencies_data.find(i => i.id === form.rating_agency_id).rating_agency_scores)
      : [];

  const mspCriteria = mapper(d.msp_criteria_data);

  const employees = d.sf_employees.map(i => ({ value: i.id, name: i.full_name }));
  const debtor = form.debtor && form.debtor.id ? p.debtors.find(i => i.id === form.debtor.id) : null;
  const addresses =
    debtor && debtor.debtor && debtor.debtor.addresses
      ? debtor.debtor.addresses.map(i => ({
        value: i.id,
        name: `${i.street} ${i.building_no}/${i.local_no || '-'}, ${i.zipcode} ${i.city}`
      }))
      : [];

  const pmis = Array.from({ length: 100 }, (v, o) => o + 1).map(i => ({ value: i, name: i }));

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row mt-3 align-items-center">
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <div className="mb-10 col-sm-6 col-form-label label-right">
                  Przychody za poprzedni rok
                </div>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="last_revenues" number />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <div className="col-sm-6">
                  <Select
                    formName={props.formName}
                    options={ratingTypes}
                    name="rating_type_id"
                    number
                    onChange={() => props.clear(props.formName, 'rating_class_id')}
                  />
                </div>
                <div className="col-sm-6">
                  <Select formName={props.formName} options={ratingClasses} name="rating_class_id" number />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <div className="mb-10 col-sm-6 col-form-label label-right">
                  Nazwa agencji ratingowej
                </div>
                <div className="col-sm-6">
                  <Select formName={props.formName} options={ratingAgencies} name="rating_agency_id" number />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-10 col-form-label label-right">
                  Jednostka samorządu terytorialnego
                </label>
                <div className="col-sm-2">
                  <Checkbox label=" " formName={props.formName} name="is_jst_or_corporate_treasury" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <div className="mb-10 col-sm-6 col-form-label label-right">
                  Ocena agencji ratingowej
                </div>
                <div className="col-sm-6">
                  <Select formName={props.formName} options={ratingAgencyScores} name="rating_agency_score_id" number />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-10 col-form-label label-right">
                  Podmiot w upadłości lub likwidacji
                </label>
                <div className="col-sm-2">
                  <Checkbox label=" " formName={props.formName} name="is_bankruptcy_or_liquidation" />
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center mt-15">
                <div className="mb-10 col-sm-3 label-right">
                  Wywiadownia, Rating, PMI, MŚP
                </div>
                <div className="col-sm-3">
                  <Select formName={props.formName} options={informationTypes} name="information_agency_id" number />
                </div>
                <div className="col-sm-2">
                  <Select formName={props.formName} options={informationClasses} name="information_agency_class_id" number />
                </div>
                <div className="col-sm-2">
                  <Select formName={props.formName} options={pmis} name="information_agency_pmi" number />
                </div>
                <div className="col-sm-2">
                  <Select formName={props.formName} options={mspCriteria} name="msp_criteria_id" number />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <div className="mb-10 col-sm-3 col-form-label label-right">
                  Limit wywiadowni gospodarczej
                </div>
                <div className="col-sm-3">
                  <Input formName={props.formName} type="text" name="information_agency_limit" number />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <ColNameBig className='col-sm-3 text-right'>
                  Data raportu wywiadowni
                </ColNameBig>
                <div className="col-sm-3">
                  <Datepicker formName={props.formName} name="information_agency_report_date" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <ColNameBig className='col-sm-3 text-right'>
                  Wizja lokalna
                </ColNameBig>
                <div className="col-sm-2">
                  <Checkbox label=" " formName={props.formName} name="wizja_lokalna" />
                </div>
              </div>
            </div>
          </div>

          {form.wizja_lokalna ? (
            <>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group row  align-items-center">
                    <ColNameBig className='col-sm-3 text-right'>
                      Data wizji
                    </ColNameBig>
                    <div className="col-sm-3">
                      <Datepicker formName={props.formName} name="data_vision" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group row  align-items-center">
                    <ColNameBig className='col-sm-3 text-right'>
                      Pracownik
                    </ColNameBig>
                    <div className="col-sm-3">
                      <Select formName={props.formName} options={employees} name="employee_vision_id" number />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group row  align-items-center">
                    <ColNameBig className='col-sm-3 text-right'>
                      Adres wizji
                    </ColNameBig>
                    <div className="col-sm-3">
                      <Select formName={props.formName} options={addresses} name="address_vision_id" number />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group row  align-items-center">
                    <ColNameBig className='col-sm-3 text-right'>
                      Ilość punktów
                    </ColNameBig>
                    <div className="col-sm-2">
                      <Input formName={props.formName} type="number" name="score_vision" />
                    </div>
                    <div className="col-sm-1 grey">/ 100</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group row  align-items-center">
                    <ColNameBig className='col-sm-3 text-right'>
                      Rekomendacja
                    </ColNameBig>
                    <div className="col-sm-2">
                      <Checkbox label=" " formName={props.formName} name="is_recommanded_vision" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group row  align-items-center">
                    <ColNameBig className='col-sm-3 text-right'>
                      Opis wizji
                    </ColNameBig>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <Textarea formName={props.formName} name="comment_vision" />
                </div>
              </div>
            </>
          ) : null}

          <hr />

          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
                  Limit wywiadowni gospodarczej
                </label>
                <div className="col-sm-1">
                  <label className="custom-control custom-radio d-flex align-items-center">
                    <input
                      id="radio1"
                      name="radio"
                      type="radio"
                      className="custom-control-input mr-3"
                      onChange={() => {
                        props.storeInput(props.formName, 'has_business_intelligence_limit', 1);
                        props.storeInput(props.formName, 'has_msp_credit_limit', 0);
                      }}
                      checked={form.has_business_intelligence_limit === 1}
                    />
                    <span className="custom-control-label " />
                  </label>
                </div>
                <div className="col-sm-4 grey">{d.proposal_setup ? `${d.proposal_setup.business_intelligence_max_limit} PLN` : ''}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
                  Limit kredytowy MŚP
                </label>
                <div className="col-sm-1">
                  <label className="custom-control custom-radio d-flex align-items-center">
                    <input
                      id="radio1"
                      name="radio"
                      type="radio"
                      className="custom-control-input mr-3"
                      onChange={() => {
                        props.storeInput(props.formName, 'has_business_intelligence_limit', 0);
                        props.storeInput(props.formName, 'has_msp_credit_limit', 1);
                      }}
                      checked={form.has_msp_credit_limit === 1}
                    />
                    <span className="custom-control-label " />
                  </label>
                </div>
                <div className="col-sm-4 grey">{d.proposal_setup ? `${d.proposal_setup.msp_credit_max_limit} PLN` : ''}</div>
              </div>
            </div>
          </div>

          <hr />

          {props.noSecures ? (
            ''
          ) : (
            <>
              <div className="row mt-10">
                <div className="col-sm-12">
                  <div className="form-group row  align-items-center">
                    <div className="col-sm-3 col-form-label label-right pb-15">
                      Dodaj zabezpieczenia dłużnika
                    </div>
                    <div className="col-sm-2">
                      <Checkbox label=" " formName={props.formName} name="zabezpieczenia" />
                    </div>
                  </div>
                </div>
              </div>

              {form.zabezpieczenia ? (
                <div className="row">
                  <div className="col">
                    <Secures
                      isRiskComponent
                      issuers={props.issuers}
                      fetchIssuers={props.fetchIssuers}
                      form={form}
                      formName={props.formName}
                      addField={props.addField}
                      removeField={props.removeField}
                      common={props.common}
                      proposalData={props.proposalData}
                      offerData={props.offerData}
                      formVisible={props.formVisible}
                      toggleForm={props.toggleForm}
                    />
                  </div>
                </div>
              ) : null}
            </>
          )}

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancelButtonClick}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}>
                  Anuluj
                </button>
                <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
