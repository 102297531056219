import Loader from 'components/Loader';
import DebtorsAgreement from 'containers/Agreements/Creator/Components/DebtorsAgreement';
import DebtorsProposal from 'containers/Agreements/Creator/Components/DebtorsProposal';
import Buttons from 'containers/Agreements/Forms/Buttons';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import AgreementActions from '../../../actions/agreement';
import CommonActions from '../../../actions/common';
import FormActions from '../../../actions/forms';
import { Container, MainContainer } from '../../../components/Layout';
import Constants from '../../../utilities/constants';
import Tabs from '../Tabs';
import ClientAccounts from './Components/ClientAccounts';
import DebtorsAccounts from './Components/DebtorsAccounts';
import Emails from './Components/Emails';
import FactorAccounts from './Components/FactorAccounts';
import People from './Components/People';

export const getCurrentAgreementVersion = (agreement, version) => agreement?.agreement_versions?.find(item => item.id === version.id);

export const getAgreementDebtor = (debtorName, agreement, version) => {
  const currentAgreementVersion = getCurrentAgreementVersion(agreement, version);
  return currentAgreementVersion?.agreement_debtors?.find(item => item.name === debtorName);
};

class Index extends React.Component {
  isSendingRequest = false;
  steps = [{ id: 1, name: 'Parametry umowy' }, { id: 2, name: 'Wybór dłużników' }];

  constructor(props) {
    super(props);
    const { slug } = this.props.match.params;
    const id = slug ? slug.split('-').slice(-1)[0] : null;

    if (!this.props.agreement || Object.keys(this.props.agreement).length === 0) {
      this.props.dispatch(AgreementActions.fetchDetails(id));
    }

    this.props.dispatch(CommonActions.changeView(Constants.Views.Creator));
  }

  componentWillUnmount() {
    this.props.dispatch(FormActions.clearAllForms());
  }

  updateView() {
    const { slug } = this.props.match.params;
    const id = slug ? slug.split('-').slice(-1)[0] : null;
    this.props.dispatch(AgreementActions.fetchDetails(id));
    toast.success('Zaktualizowano dane.');
  }

  getMainComponent() {
    const defaultProps = {
      updateView: () => this.updateView(),
      remove: (uri, id) => this.remove(uri, id),
      removeAttachment: (uri, id) => this.remove(uri, id),
      saveForm: formName => this.save(formName, true),
      agreement: this.props.agreement,
      version: this.props.version,
      formVisible: f => this.props.forms.options.visible.indexOf(f) !== -1,
      toggleForm: f => this.toggleForm(f),
      clearForm: f => this.props.dispatch(FormActions.clearForm(f)),
      forms: this.props.forms,
      populateForm: (f, d) => this.props.dispatch(FormActions.initialize(f, { ...d }, {})),
      edit: (n, o, p) => this.edit(o, n, p),
      common: this.props.common,
      storeInput: (f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))
    };

    switch (this.props.tab) {
      case 1:
        return (
          <>
            <People {...defaultProps} />
            <Emails {...defaultProps} />
            <ClientAccounts {...defaultProps} />
            <FactorAccounts {...defaultProps} />
          </>
        );
      case 2:
        return (
          <>
            <DebtorsProposal {...defaultProps} saveDebtors={(f, h) => this.saveDebtors(f, h)} />
            <DebtorsAgreement {...defaultProps} />
            <DebtorsAccounts {...defaultProps} />
          </>
        );
      // case 3:
        // let additionalProps = {
        //   reset:() => this.props.dispatch(AgreementActions.resetDocuments()),
        //   generate:(formName, obj, attachmentId, action, deleting, update) => {
        //     return this.props.dispatch(AgreementActions.saveForm(formName, this.props.agreement.id, false, null, true)).then(res => {
        //       return this.props.dispatch(AgreementActions.handleFile(formName, obj, attachmentId, action, deleting, update, res?.data?.id));
        //     });
        //   },
        //   forms:this.props.forms.forms,
        //   fetchIssuers:filtersForm => this.props.dispatch(CompanyActions.fetch(this.props.forms?.forms?.[filtersForm], true)),
        //   issuers:this.props.companies,
        //   guarantors:this.props.guarantors,
        //   fetchGuarantors:filtersFormName => this.props.dispatch(AgreementActions.fetchGuarantors(filtersFormName)),
        //   debtorsDict:this.props.debtorsDict,
        //   fetchDebtorsDict:filtersFormName => this.props.dispatch(AgreementActions.fetchDebtorsDict(filtersFormName)),
        //   archive: (type, id) => this.props.dispatch(AgreementActions.archive(this.props.agreement.id, this.props.version.id, type, id))
        // };
        // return (
        //   <>
        //     <Secures
        //       {...defaultProps}
        //       {...additionalProps}
        //     />
        //     <Documents
        //       {...defaultProps}
        //       {...additionalProps}
        //     />
        //   </>
        // );
      default:
        return null;
    }
  }

  create(name) {
    this.props.dispatch(FormActions.initialize(name, {}, {}));
  }

  edit(object, name, background) {
    this.props.dispatch(FormActions.initialize(name, object, {}));
    if (background) return;
    this.props.dispatch(FormActions.toggleForm(name));
  }

  toggleForm(formName) {
    this.props.dispatch(FormActions.toggleForm(formName));
  }

  remove(uri, id) {
    this.props.dispatch(AgreementActions.remove(this.props.agreement.id, uri, id));
  }

  changeTab(tab) {
    this.props.dispatch(AgreementActions.changeTab(tab));
  }

  save(formName, hide = true) {
    this.props.dispatch(AgreementActions.saveForm(formName, this.props.agreement.id, hide));
  }

  saveDebtors(formName, hide = true) {
    if(!this.isSendingRequest) {
      this.props.dispatch(AgreementActions.saveDebtors(formName, this.props.agreement.id, hide));
      this.isSendingRequest = true;

      setTimeout(() => {
        this.isSendingRequest = false;
      }, 1000);
    }
  }

  hasDebtorsValidate() {
    const agreement = this.props.agreement;
    const version = this.props.version;

    const agreementDebtors = version.agreement_debtors.filter(item => {
      return item?.debtor?.name === getAgreementDebtor(item?.debtor?.name, agreement, version)?.name;
    });

    if(!agreementDebtors?.length) {
      toast.error('Wymagany jest przynajmniej jeden dłużnik');
    } else {
      let isAssignmentBillError = false;

      for (const item of agreementDebtors) {
        if(!item.bank_accounts?.length) {
          toast.error(`Wymagany jest przynajmniej jeden rachunek cesyjny lub walutowy dla dłużnika ${item?.name}`);
          isAssignmentBillError = true;
        }
      }

      if(!isAssignmentBillError) {
        return true;
      }
    }
    return false;
  }

  handleChangeStep(index) {
    this.changeTab(index);
  }

  handleNextButton() {
    const currentTab = this.props.tab < 2 ? this.props.tab + 1 : this.props.tab - 1;
    this.changeTab(currentTab);
  }

  handleSubmit() {
    if(this.hasDebtorsValidate()) {
      window.location.href = `/umowy/${this.props.agreement.slug}`;
    }
  }

  render() {
    if (
      (!this.props.version || !this.props.version.id) &&
      this.props.agreement.agreement_versions &&
      this.props.agreement.agreement_versions.length > 0
    ) {
      const version = this.props.agreement.agreement_versions.slice(-1)[0];
      this.props.dispatch(AgreementActions.selectVersion(version));
    }

    const isLoadingState = !this.props.agreement?.id || !this.props.version?.id;

    if(isLoadingState) {
      return <Loader />;
    }

    return (
      <Container className="mt-3">
        <MainContainer className='w-full max-w-1400 mx-auto'>
          <Tabs creator tab={this.props.tab} changeStep={tab => this.handleChangeStep(tab)} steps={this.steps} />

          {this.getMainComponent()}

          <Buttons
            className="mb-3"
            onCancel={() => this.handleNextButton()}
            cancelButton={this.props.tab < 2 ? 'Dalej' : 'Wstecz'}
            isSubmitVisible={this.props.tab === 2}
            onSubmit={() => this.handleSubmit()}
            submitButton="Szczegóły"
          />
        </MainContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  forms: state.Forms,
  factors: state.Agreement.factors,
  formData: state.Agreement.formData,
  agreement: state.Agreement.agreement,
  version: state.Agreement.version,
  common: state.Common,
  tab: state.Agreement.currentTab,
  companies: state.Company,
  guarantors: state.Agreement.guarantors,
  debtorsDict: state.Agreement.debtorsDict
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Index)
);
