import { CONSENTS_CREATOR_FORM } from 'containers/Persons/Creator/Components/Consents';
import { toast } from 'react-toastify';

import CompanyService from '../services/company';
import PersonService from '../services/person';
import CommonActions from './common';
import FormActions from './forms';
import { OptionsActions } from 'ducks/options';

const preview = person => dispatch => {
  dispatch({ type: 'PERSONS_SELECTED_FOR_PREVIEW', payload: person });
  dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
};

const fetch = (filters, shallowFetch = false) => (dispatch) => {
  dispatch(OptionsActions.toggleLoader(true));

  // TODO: filters Options
  // const globalFilters = getState().Options.limit;
  const f = filters || CommonActions.defaultFilters;

  PersonService.fetch(f).then(response => {
    dispatch({ type: 'PERSONS_FETCHED', payload: response.data });
    if (response.data.results.length > 0 && !shallowFetch) {
      dispatch(preview(response.data.results[0]));
    }
    dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
    dispatch(OptionsActions.toggleLoader(false));
  });
};

const fetchPersonDetails = (personId, loader = true) => dispatch => {
  if (loader) {
    dispatch({ type: 'CONTENT_LOADING' });
    dispatch({ type: 'CONTENT_PREVIEW_LOADING' });
    dispatch(OptionsActions.toggleLoader(loader));
  }

  PersonService.fetchPersonDetails(personId).then(response => {
    dispatch({ type: loader ? 'PERSONS_DETAILS_FETCHED' : 'PERSONS_DETAILS_UPDATED', payload: response.data });
    dispatch({ type: 'CONTENT_LOADED' });

    if (loader) {
      dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
      dispatch(OptionsActions.toggleLoader(false));
    } else {
      toast.success('Zaktualizowano dane.');
    }
  });
};

const createPerson = (form, stayOnList = false, push) => (dispatch, getState) => {
  const data = getState().Forms.forms[form];
  dispatch(FormActions.clearErrors(form));

  return PersonService.create(data)
    .then(response => {
      dispatch(FormActions.clearForm(form));
      dispatch(FormActions.toggleForm(form));

      if (stayOnList) {
        toast.success('Obiekt został utworzony.');
      } else {
        dispatch({ type: 'PERSONS_DETAILS_FETCHED', payload: response.data });
        push(`/osoby/${response.data.slug}/kreator`);
      }
    }).catch(errors => dispatch(FormActions.handleErrors(form, errors?.response?.data)));
};

const saveForm = (form, personId = null, hide = false) => (dispatch, getState) => {
  const data = getState().Forms.forms[form];

  dispatch(FormActions.clearErrors(form));

  PersonService.saveForm(form, data, personId)
    .then(() => {
      dispatch(CommonActions.toggleModal());
      dispatch(fetchPersonDetails(personId || data.id, false));

      document.getElementsByTagName('html')[0].style = '';

      if (form !== 'MainCreatorForm' && form !== CONSENTS_CREATOR_FORM) {
        dispatch(FormActions.clearForm(form));
      }

      if (hide) {
        dispatch(FormActions.toggleForm(form));
      }
    }).catch(errors => dispatch(FormActions.handleErrors(form, errors?.response?.data)));
};

const removePerson = id => () => PersonService.removePerson(id).then(() => toast.success('Usunięto obiekt.'));

const removeObject = (objectId, personId, object) => dispatch =>
  PersonService.removeObject(objectId, personId, object).then(() => {
    dispatch(CommonActions.toggleModal());
    dispatch(fetchPersonDetails(personId, false));

    document.getElementsByTagName('html')[0].style = '';
  });

const removeSystemRole = roleId => (dispatch, getState) => {
  const { person } = getState().Person;

  PersonService.removeSystemRole(person, roleId).then(() => {
    dispatch(CommonActions.toggleModal());
    dispatch(fetchPersonDetails(person.id, false));

    document.getElementsByTagName('html')[0].style = '';
  });
};

const toggleStatus = (personId, objectId, objectType) => dispatch =>
  PersonService.toggleStatus(personId, objectId, objectType).then(() => dispatch(fetchPersonDetails(personId, false)));

const changeConsentStatus = consent => (dispatch, getState) => {
  const personId = getState().Person.person.id;

  PersonService.changeConsentStatus(consent, personId).then(() => {
    dispatch(fetchPersonDetails(personId, false));
  });
};

const addBusinessRole = (formName, hide) => (dispatch, getState) => {
  const { person } = getState().Person;
  const form = getState().Forms.forms[formName];

  const handleResponse = () => {
    dispatch(fetchPersonDetails(person?.id, false));

    dispatch(FormActions.clearForm(formName));

    if (hide) {
      dispatch(FormActions.toggleForm(formName));
    }
  };

  CompanyService.saveForm(
    'PersonForm',
    {
      person_id: person?.id,
      roles_ids: [Number(form?.role)]
    },
    form?.company?.id
  )
    .then(handleResponse)
    .catch(errors => dispatch(FormActions.handleErrors('RolesForm', errors?.response?.data)));
};

const removeBusinessRole = (role, company) => (dispatch, getState) => {
  const { person } = getState().Person;
  const newRoles = company.roles.filter(r => r.id !== role.id).map(r => r.id);

  const handleResponse = () => {
    dispatch(fetchPersonDetails(person.id, false));
  };

  CompanyService.removeObject(company.person_in_company_id, company.company.id, 'person').then(() => {
    if (newRoles.length > 0) {
      CompanyService.saveForm('PersonForm', { person_id: person.id, roles_ids: newRoles }, company.company.id)
        .then(handleResponse).catch(errors => dispatch(FormActions.handleErrors('PersonForm', errors?.response?.data)));
    } else {
      handleResponse();
    }
  });
};

const addAttachment = files => (dispatch, getState) => {
  const data = new FormData();
  data.append('file', files[0]);

  const personId = getState().Person.person.id;
  PersonService.addAttachment(personId, data).then(response => response);
};

const saveCommission = (formName, hide = true) => (dispatch, getState) => {
  const form = getState().Forms.forms[formName];
  const { person } = getState().Person;
  const parent = person.commission_parents?.find(p => p.category.id === Number(form?.category)) ?? null;

  const handleResponse = () => {
    dispatch(fetchPersonDetails(person.id, false));
    dispatch(FormActions.clearForm(formName));

    if (hide) {
      dispatch(FormActions.toggleForm(formName));
    }
  };

  if (parent || form.id) {
    if (parent.id) {
      form.commission_parent_id = parent.id;
    }
    PersonService.saveCommission(person.id, form).then(handleResponse)
      .catch(errors => dispatch(FormActions.handleErrors(formName, errors?.response?.data)));
  } else {
    PersonService.saveParentCommission(person.id, form).then(r => {
      form.commission_parent_id = r.data.id;
      return PersonService.saveCommission(person.id, form).then(handleResponse)
        .catch(errors => dispatch(FormActions.handleErrors(formName, errors?.response?.data)));

    }).catch(errors => dispatch(FormActions.handleErrors(formName, errors?.response?.data)));
  }
};

export default {
  fetch,
  preview,
  fetchPersonDetails,
  saveForm,
  removePerson,
  removeObject,
  removeBusinessRole,
  removeSystemRole,
  toggleStatus,
  createPerson,
  changeConsentStatus,
  addBusinessRole,
  addAttachment,
  saveCommission
};
