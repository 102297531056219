import Options from 'ducks/options';
import { combineReducers } from 'redux';

import Agreement from './agreement';
import Authorization from './authorization';
import Claim from './claim';
import Common from './common';
import Company from './company';
import Due from './due';
import Forms from './forms';
import Invoice from './invoice';
import Offer from './offer';
import Other from './other';
import Person from './person';
import Proposal from './proposal';
import Transfer from './transfer';

export default combineReducers({
  Authorization,
  Common,
  Forms,
  Person,
  Company,
  Offer,
  Proposal,
  Agreement,
  Claim,
  Transfer,
  Invoice,
  Due,
  Other,
  Options
});
