import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { ScoringForm } from '../../Forms/Scoring';

export const Scoring = (props) => {
  const company = props.company || {};
  const formName = 'ScoringForm';

  const companyEditable = {
    ...company,
    legal_form_id: company.legal_form ? company.legal_form.id : null,
    industry_id: company.industry ? company.industry.id : null,
    representation_id: company.representation ? company.representation.id : null,
    main_pkd_id: company.main_pkd ? company.main_pkd.id : null,
    activity_range_id: company.activity_range ? company.activity_range.id : null,
    accounting_support_id: company.accounting_support ? company.accounting_support.id : null,
    accounting_type_id: company.accounting_type ? company.accounting_type.id : null,
    office_ownership_id: company.office_ownership ? company.office_ownership.id : null,
    insurance_claims: company.insurance_claims
  };
  return (
    <Card header='Scoring' onEditClick={() => props.edit(formName, companyEditable)}>
      <div>
        <div className="row">
          <div className="col-sm-5">
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Liczba zatrudnionych</div>
              <div className="col-sm-6 p-2 br">{company.no_employees}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Data rozpoczęcia działalności</div>
              <div className="col-sm-6 p-2 br">{company.company_started_date}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Rodzaj księgowości</div>
              <div className="col-sm-6 p-2 br">{company.accounting_type ? company.accounting_type.name : ''}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Ilość dostawców</div>
              <div className="col-sm-6 p-2 br">
                {company.suppliers_number}
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Zasięg działania</div>
              <div className="col-sm-6 p-2 br">{company.activity_range ? company.activity_range.name : 'brak'}</div>
            </div>
            <div className="row">
              <div className="col-sm-6 p-2 br grey">Własność siedziby</div>
              <div className="col-sm-6 p-2 br">{company.office_ownership ? company.office_ownership.name : 'brak'}</div>
            </div>
          </div>
          <div className="col-sm-7">
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Nr głównego PKD</div>
              <div className="col-sm-6 p-2 ">{company.main_pkd ? company.main_pkd.name : 'brak'}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Data pierwszej faktury</div>
              <div className="col-sm-6 p-2 ">{company.first_invoice_date}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Biuro rachunkowe</div>
              <div className="col-sm-6 p-2 ">{company.accounting_support ? company.accounting_support.name : 'brak'}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Ilość odbiorców</div>
              <div className="col-sm-6 p-2 ">
                {company.recipients_number}
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Ubezpieczenie należności</div>
              <div className="col-sm-6 p-2 ">
                <BooleanCheck field={company.insurance_claims} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Dane scoringowe</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <ScoringForm
          companyData={props.companyData}
          companyId={props.company ? props.company.id : 'brak'}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          withButtons
          onSubmit={() => props.saveForm(formName)}
          onCancel={() => props.toggleForm(formName)}
          visible={props.formVisible(formName)}
          formName={formName}
        />
      </BaseModal>
    </Card>
  );
};
