import Card from 'components/Layout/Card';
import React, { useEffect } from 'react';

import { AdministratorForm } from '../../Forms/Administrator';

export const Administrator = props => {
  const formName = 'AdministratorCreatorForm';

  useEffect(() => {
    if (props.populateForm) {
      props.populateForm(formName);
    }
    // eslint-disable-next-line
  }, props);

  const calculation = props.calculation || {};

  return (
    <Card mode='creator' header='+ Administrator' onSaveClick={() => props.saveForm(formName)}>
      <div/>
      <div className="pr-5 pt-3">
        <AdministratorForm
          freqs={props.freqs}
          currencies={props.currencies}
          calculation={calculation}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(formName)}
          formName={formName}
          submitForm={() => props.saveForm(formName)}
          onCancelButtonClick={() => props.toggleForm(formName)}
          populate={() => props.populateForm(formName)}
        />
      </div>
    </Card>
  );
};
