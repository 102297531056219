import clsx from 'clsx';
import React from 'react';

const getStyles = mode => {
  switch(mode) {
    case 'plus': return 'fa fa-plus';
    case 'edit': return 'fas fa-pencil-alt';
    case 'trash': return 'fas fa-trash text-danger';
    case 'refresh': return 'fas fa-sync';
    case 'plus-creator': return 'fa fa-plus-circle';
    case 'save-creator': return 'fas fa-save';
    case 'loading': return 'fas fa-cog fa-spin';
    default: return '';
  }
};

export default function Icon({ isCreator, mode, className, ...props }) {

  return (
    <div className={clsx(
      'pointer py-15 pl-1 pr-1 ml-1 mr-1',
      isCreator ? 'creator__btn-icon' : 'details__btn-icon',
      getStyles(mode),
      className
    )}
    {...props}
    />
  );
}

Icon.defaultProps = {
  onClick: () => {}
};
