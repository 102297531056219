import React from 'react';

import { Form, Input, Select } from '../../../components/Forms';

export const TransferForm = (props) => {
  // const edit = props.forms?.[props.formName];
  const edit = false;
  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
              Kwota brutto
            </label>
            <div className="col-sm-6">
              <Input type="text" formName={props.formName} name="net_transfer_value" />
            </div>
          </div>
        </div>
      </div>
      {edit && (
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                W tym VAT
              </label>
              <div className="col-sm-6">
                <Input type="text" formName={props.formName} name="vat_transfer_value" />
              </div>
            </div>
          </div>
        </div>
      )}
      {props.noCurrency ? null : (
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                Waluta
              </label>
              <div className="col-sm-6">
                <Select
                  options={props.common?.currencies?.map((x) => ({ ...x, value: x.id }))}
                  formName={props.formName}
                  name="transfer_value_currency_id"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {props.withButtons ? (
        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button
              type="button"
              onClick={props.onCancelButtonClick}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}
            >
              Anuluj
            </button>
            <button
              type="button"
              onClick={() => props.submitForm()}
              className="btn btn-primary ml-2  font-weight-light"
            >
              {props.saveText || 'Dodaj'}
            </button>
          </div>
        </div>
      ) : null}
    </Form>
  );
};
