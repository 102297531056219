import React from 'react';
import { Modal as ModalCore } from 'react-overlays';
import { useHistory } from 'react-router-dom';

const getMaxWidth = size => {
  switch (size) {
    case 'small':
      return '300px';
    case 'medium':
      return '650px';
    case 'large':
      return '900px';
    case 'extralarge':
      return '1500px';
    default:
      return '1200px';
  }
};

const modalStyle = size => ({
  maxWidth: getMaxWidth(size),
  position: 'fixed',
  width: '100%',
  zIndex: 1040,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '1px solid #7E6F41',
  backgroundColor: 'white'
});

const renderBackdrop = props => (
  <div
    {...props}
    style={{
      position: 'fixed',
      zIndex: 1040,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: '#000',
      opacity: 0.5
    }}
  />
);

export const getUrlHash = element => {
  if(!element) return false;
  let string = element;

  if(typeof element !== 'string') {
    const count = element?.props?.children?.length;
    string = element?.props?.children[count - 1]?.props?.children;
  }
  if(typeof element !== 'string') return false;

  const stringArray = string.replace(/([-+.,/';\s_{}:!@?"#$%^&*()])/g, ' ').split(' ');
  const currentHash = stringArray.filter(string => string).join('-')
    .replace(/([ą])/g, 'a').replace(/([ć])/g, 'c').replace(/([ę])/g, 'e').replace(/([ł])/g, 'l')
    .replace(/([ń])/g, 'n').replace(/([ś])/g, 's').replace(/([ź])/g, 'z').replace(/([ż])/g, 'z');

  return `#${currentHash.toLowerCase()}`;
};

export const Modal = ({ isOpen, header, children, footer, size, onClose, ...props }) => {
  const history = useHistory();

  const handleQuestionClick = () => {
  };

  const handleModalClose = () => {
    history?.location?.hash && history.replace(history.location.pathname);
    onClose();
  };

  return (
    <ModalCore
      show={isOpen || history?.location?.hash?.toLowerCase() === getUrlHash(header).toLowerCase()}
      style={modalStyle(size)}
      onHide={handleModalClose}
      aria-labelledby="modal-label"
      renderBackdrop={renderBackdrop}
      {...props}
    >
      <div>
        <div className="modal__header d-flex justify-content-between align-items-center">
          <span className="modal__header_span float-left text-left">
            <div className="d-flex align-items-center">
              <i className="fas fa-pencil-alt mr-2" />
              <span>{header}</span>
            </div>
          </span>
          <i className="fa fa-question-circle" style={{ fontSize: '28px' }} onClick={handleQuestionClick} />
        </div>
        <div className="modal__content">
          {children}
        </div>
        <div className="modal__footer">
          {footer}
        </div>
      </div>
    </ModalCore>
  );
};
