import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import { isEditableProposal, STATUS_PROPOSAL } from 'containers/Proposals/Details/Index';
import React from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import ScoringForm from '../../Forms/Scoring';
import { toast } from 'react-toastify';
import { t } from 'lang/pl';

export default (props) => {
  const formName = 'ScoringForm';
  const proposal = props.version && props.version ? props.version : {};

  const editableProposal = {
    ...proposal,
    legal_form_id: proposal.legal_form ? proposal.legal_form.id : null,
    industry_id: proposal.industry ? proposal.industry.id : null,
    representation_id: proposal.representation ? proposal.representation.id : null,
    main_pkd_id: proposal.main_pkd ? proposal.main_pkd.id : null,
    activity_range_id: proposal.activity_range ? proposal.activity_range.id : null,
    accounting_support_id: proposal.accounting_support ? proposal.accounting_support.id : null,
    accounting_type_id: proposal.accounting_type ? proposal.accounting_type.id : null,
    office_ownership_id: proposal.office_ownership ? proposal.office_ownership.id : null,
    insurance_claims: proposal.insurance_claims
  };

  return (
    <Card
      header='Faktorant dane scoringowe'
      isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
      onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
      onEditClick={() => props.edit(editableProposal, formName)}
    >
      <div>
        <div className="row">
          <div className="col-sm-5">
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Liczba zatrudnionych</div>
              <div className="col-sm-6 p-2 br">{proposal.no_employees}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Data rozpoczęcia działalności</div>
              <div className="col-sm-6 p-2 br">{proposal.company_started_date}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Rodzaj księgowości</div>
              <div className="col-sm-6 p-2 br">{proposal.accounting_type ? proposal.accounting_type.name : '-'}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Ilość dostawców</div>
              <div className="col-sm-6 p-2 br">
                {proposal.suppliers_number ?? '-'}
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Zasięg działania</div>
              <div className="col-sm-6 p-2 br">{proposal.activity_range ? proposal.activity_range.name : 'brak'}</div>
            </div>
            <div className="row">
              <div className="col-sm-6 p-2 br grey">Własność siedziby</div>
              <div className="col-sm-6 p-2 br">{proposal.office_ownership ? proposal.office_ownership.name : 'brak'}</div>
            </div>
          </div>
          <div className="col-sm-7">
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Nr głównego PKD</div>
              <div className="col-sm-6 p-2 ">{proposal.main_pkd ? proposal.main_pkd.name : 'brak'}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Data pierwszej faktury</div>
              <div className="col-sm-6 p-2 ">{proposal.first_invoice_date}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Biuro rachunkowe</div>
              <div className="col-sm-6 p-2 ">{proposal.accounting_support ? proposal.accounting_support.name : 'brak'}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Ilość odbiorców</div>
              <div className="col-sm-6 p-2 ">
                {proposal.recipients_number ?? '-'}
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Ubezpieczenie należności</div>
              <div className="col-sm-6 p-2 ">
                <BooleanCheck field={proposal.insurance_claims} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Dane scoringowe</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <ScoringForm
          onCancel={() => props.toggleForm(formName)}
          companyData={props.companyData}
          visibleOnMount
          visible={props.formVisible(formName)}
          formName={formName}
          onSubmit={() => props.saveForm(formName)}
          withButtons
        />
      </BaseModal>
    </Card>
  );
};
