import React from 'react';
import tw, { styled } from 'twin.macro';

const StyledColName = styled.div`
  ${tw`font-bold text-grey-8 text-center pt-05 mb-15`};
`;

const StyledCol = styled.div`
  ${tw`pt-05`};
`;

export function ColNameBig({ children, ...props }) {
  return (
    <StyledColName {...props}>
      <span className='mx-20'>{children}</span>
    </StyledColName>
  );
}

export function ColName({ children, ...props }) {
  return (
    <StyledColName {...props}>
      <span className='mx-05'>{children}</span>
    </StyledColName>
  );
}

export function ColBig({ children, ...props }) {
  return (
    <StyledCol {...props}>
      <span className='mx-20'>{children}</span>
    </StyledCol>
  );
}

export default function Col({ children, ...props }) {
  return (
    <StyledCol {...props}>
      <span>{children}</span>
    </StyledCol>
  );
}
