import React from 'react';

import Documents from '../Components/Documents';
import Secures from '../Components/Secures';

export default props => (
  <>
    <Documents
      refresh={props.refresh}
      reset={props.reset}
      archive={props.archive}
      generate={props.generate}
      forms={props.forms}
      remove={props.remove}
      saveForm={props.saveForm}
      version={props.version}
      agreement={props.agreement}
      edit={props.edit}
      common={props.common}
      formVisible={props.formVisible}
      toggleForm={props.toggleForm}
      removeAttachment={props.remove}
      storeInput={props.storeInput}
    />
    <Secures
      archive={props.archive}
      generate={props.generate}
      guarantors={props.guarantors}
      fetchGuarantors={props.fetchGuarantors}
      fetchDebtorsDict={props.fetchDebtorsDict}
      debtorsDict={props.debtorsDict}
      issuers={props.issuers}
      fetchIssuers={props.fetchIssuers}
      forms={props.forms}
      remove={props.remove}
      saveForm={props.saveForm}
      version={props.version}
      agreement={props.agreement}
      formVisible={props.formVisible}
      toggleForm={props.toggleForm}
      clearForm={props.clearForm}
      edit={props.edit}
      common={props.common}
      removeAttachment={props.remove}
      storeInput={props.storeInput}
    />
  </>
);
