import React from 'react';

import { OldCard } from '../../../../components/Layout';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parseMoney } from '../../../../utilities/helpers';

export default props => {
  const header = (
    <a
      role="button"
      style={{ fontSize: '24px' }}
      className="collapsed "
      data-toggle="collapse"
      href="#warunkiDodatkowe"
      aria-expanded="false"
      aria-controls="collapseExample">
      Ewidencja sald należności
    </a>
  );

  const customCreateSection = (
    <>
      <i className="fas fa-question-circle ml-2 mr-2 " onClick={() => {}} />
    </>
  );

  const getIncomesForCharge = chargeId => props.claim?.subpayments.map(s => s.incomes.map(x => ({ ...x, identifier: s.identifier, payment_category: s.payment_category }))).flat().filter(i => i.charge?.id === chargeId);
  const charges = props.claim.charges?.map(c => ({ ...c, payments: getIncomesForCharge(c.id) })) ?? [];

  const finalPayment =
    props.claim?.commitments
      ?.filter(x => x.context === 5 || x.context === 6) // płatność końcowa lub płatność końcowa vat
      ?.map(x => ({
        title: x.title,
        created_date: x.commitment_date,
        gross_value: x.gross_value,
        currency: x.currency,
        paid_value: x.paid_value,
        to_pay_value: x.to_pay_value,
        status: x.status,
        payments: x.payments
      })) ?? [];

  return (
    <OldCard header={header} id="financialData-card" showIcons customCreateSection={customCreateSection}>
      <div className="collapse show" id="financialData">
        <div className="row bb">
          <div className="col-sm-2 p-2 grey br">Tytuł</div>
          <div className="col-sm-2 p-2 grey br">Tytuł rozliczenia</div>
          <div className="col-sm-1 p-2 grey br">Data rozliczenia</div>
          <div className="col-sm-1 p-2 grey br">Kwota wydatku</div>
          <div className="col-sm-1 p-2 grey br">Waluta rozliczenia</div>
          <div className="col-sm-1 p-2 grey br">Kwota w walucie</div>
          <div className="col-sm-1 p-2 grey br">Kurs</div>
          <div className="col-sm-1 p-2 grey br">Rozliczono</div>
          <div className="col-sm-1 p-2 grey br">Do rozliczenia</div>
          <div className="col-sm-1 p-2 grey">Status</div>
        </div>
        {[...finalPayment, ...charges].map(charge => (
          <>
            <RowEditable>
              <div className="col-sm-2 p-2 br">{charge.title}</div>
              <div className="col-sm-2 p-2 br"> </div>
              <div className="col-sm-1 p-2 br">{charge.created_date}</div>
              <div className="col-sm-1 p-2 br">{parseMoney(charge.gross_value)}</div>
              <div className="col-sm-1 p-2  br">{charge.currency?.name}</div>
              <div className="col-sm-1 p-2  br">-</div>
              <div className="col-sm-1 p-2  br">-</div>
              <div className="col-sm-1 p-2  br">{parseMoney(charge.paid_value)}</div>
              <div className="col-sm-1 p-2 br">{parseMoney(charge.to_pay_value)}</div>
              <div className="col-sm-1 p-2 " style={{ color: charge.status ? '#1DB393' : 'inherit', fontSize: '90%' }}>
                {Number(charge.to_pay_value) === 0 ? 'Rozliczony' : 'Nierozliczony'}
              </div>
            </RowEditable>
            {charge.payments.map(income => (
              <RowEditable>
                <div className="col-sm-2 p-2 br text-themecolor"> </div>
                <div className="col-sm-2 p-2 br text-themecolor">{income.identifier || income.id}</div>
                <div className="col-sm-1 p-2 br text-themecolor">{income.created_date}</div>
                <div className="col-sm-1 p-2 br text-themecolor">{parseMoney(income.income_value || income.gross_transfer_value)}</div>
                <div className="col-sm-1 p-2  br text-themecolor">
                  {income.income_value_currency?.name ?? income.gross_transfer_value_currency?.name ?? income.vat_transfer_value_currency?.name}
                </div>
                <div className="col-sm-1 p-2  br text-themecolor">-</div>
                <div className="col-sm-1 p-2  br text-themecolor">-</div>
                <div className="col-sm-1 p-2  br text-themecolor">{income.category_name || income.payment_category || 'Przelew'}</div>
                <div className="col-sm-1 p-2 br text-themecolor" />
                <div className="col-sm-1 p-2  text-themecolor">{income.is_settled ? 'Rozliczony' : 'Nierozliczony'} </div>
              </RowEditable>
            ))}
          </>
        ))}
      </div>
    </OldCard>
  );
};
