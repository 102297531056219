import IconPlus from 'components/Forms/IconPlus';
import IconSync from 'components/Forms/IconSync';
import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import { t } from 'lang/pl';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { formatString, formatValueToEurope, parseMoney, formatNip } from '../../../../utilities/helpers';
import { AdditionalRatesForm } from '../../../Offers/Forms/AdditionalRates';
import { MultilevelRatesForm } from '../../../Offers/Forms/MultilevelRates';
import { RatesForm } from '../../../Offers/Forms/Rates';
import DebtorsForm from '../../Forms/Debtors';
import DocumentsForm from '../../Forms/Debtors/Documents';
import SecuresForm from '../../Forms/Secures';

const NoData = () => (
  <div className="row bb">
    <div className="col-12 p-2">Brak danych.</div>
  </div>
);

export default ({ isEditable, ...props }) => {
  const [fromOffer, setFromOffer] = useState({});

  const maxPaymentDates = [];
  const bidTypes =
    props.offerData && props.offerData.additional_bid_types ? props.offerData.additional_bid_types.map(v => ({ ...v, value: v.id })) : [];
  const dtbs = props.offerData && props.offerData.dtb_types ? props.offerData.dtb_types : [];

  const p = props.proposal || {};

  const getBidType = id => {
    const bid = bidTypes.find(x => x.id === id);
    return bid ? bid.name : '-';
  };

  const handleEditClick = debtor => {
    props.edit(
      {
        debtor: debtor.debtor,
        id: debtor.id,
        requested_sublimit: debtor.requested_sublimit,
        mpp: debtor.mpp,
        deducted_rates: debtor.deducted_rates,
        category_id: debtor.category ? debtor.category.id : null,
        assignment_consent: debtor.assignment_consent
      },
      'DebtorsEditForm'
    );
  };

  const debtors = props.proposal && props.proposal.debtors ? props.proposal.debtors : [];
  const offerData = props.offerData || {};

  return (
    <div className='w-full flex-col flex items-center justify-center'>
      {debtors.map(debtor => (
        <Card
          key={debtor.id}
          header={`Warunki handlowe: ${debtor.debtor?.name ?? debtor.name} ${formatValueToEurope(debtor.requested_sublimit, '', { withoutCents: true })}`}
          isBlocked={!isEditable}
          onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
          onEditClick={() => handleEditClick(debtor)}
          onRemoveClick={() => props.remove('debtor', debtor.id)}
          options={{ isCollapsed: false }}
        >
          <div>
            <div className="row bb">
              <div className="flex-30 min-w-200 p-2 br grey">Nazwa firmy</div>
              <div className="flex-20 max-w-200 p-2 br grey">NIP</div>
              <div className="flex-10 min-w-100 p-2 br grey">Wnioskowany sublimit</div>
              <div className="flex-10 min-w-100 p-2 br grey">Dostępny sublimit</div>
              <div className="flex-10 min-w-100 p-2 br grey">Wykorzystany sublimit</div>
              <div className="flex-10 min-w-100 p-2 br grey">Kategoria</div>
              <div className="flex-10 min-w-100 max-w-160 p-2 br grey">MPP</div>
              <div className="flex-10 min-w-100 max-w-160 p-2 br grey">Zgoda na cesję</div>
              <div className="flex-10 min-w-100 max-w-160 p-2 grey">Potrącane z zaliczki</div>
            </div>
            <div className="row bb">
              <div className="flex-30 min-w-200 p-2 br">{debtor.debtor?.name ?? debtor.name ?? '-'}</div>
              <div className="flex-20 max-w-200 p-2 br">{formatNip(debtor.debtor?.nip ?? debtor.nip)}</div>
              <div className="flex-10 min-w-100 p-2 br">
                {formatValueToEurope(props.version?.limit_amount ?? debtor.requested_sublimit, props.currency?.name)}
              </div>
              <div className="flex-10 min-w-100 p-2 br">
                {formatValueToEurope(debtor.available_sublimit, props.currency?.name)}
              </div>
              <div className="flex-10 min-w-100 p-2 br">
                {formatValueToEurope(debtor.used_sublimit, props.currency?.name)}
              </div>
              <div className="flex-10 min-w-100 p-2 br">{debtor.category ? debtor.category.name : '-'}</div>
              <div className="flex-10 min-w-100 max-w-160 p-2 br">
                <BooleanCheck field={debtor.mpp} />
              </div>
              <div className="flex-10 min-w-100 max-w-160 p-2 br">
                <BooleanCheck field={debtor.assignment_consent} />
              </div>
              <div className="flex-10 min-w-100 max-w-160 p-2">
                <BooleanCheck field={debtor.deducted_rates} />
              </div>
            </div>

            <div className="row bb">
              <div className="col-12 px-2 pt-24 pb-10 text-themecolor font-weight-bold">
                Stawki podstawowe
                <span className="float-right" style={{ cursor: 'pointer' }}>
                  {p.offer_has_basicbids_with_intrestmargins && (
                    <IconSync isBlocked={!isEditable} onClick={() => setFromOffer({ debtor: debtor, obj: 'basicbids_with_intrestmargins_mode' })} />
                  )}
                  <IconPlus isBlocked={!isEditable} onClick={() => props.edit({ debtor_id: debtor.id, debtor: debtor }, 'Proposal_BasicBids')} />
                </span>
              </div>
            </div>
            <div className="row bb">
              <div className="flex-15 min-w-150 max-w-250 p-2 br grey">Nazwa stawki</div>
              <div className="flex-10 min-w-150 max-w-250 p-2 br grey">Max wysokość zaliczki</div>
              <div className="flex-10 min-w-150 max-w-250 p-2 br grey">Max termin płatności</div>
              <div className="flex-10 min-w-100 max-w-150 p-2 br grey">Stawka flat</div>
              <div className="flex-10 max-w-180 p-2 br grey">Data stopy</div>
              <div className="flex-10 max-w-150 p-2 br grey">Stopa</div>
              <div className="flex-10 max-w-150 p-2 br grey">Zmienna?</div>
              <div className="flex-10 max-w-180 p-2 br grey">Marża odsetkowa?</div>
              <div className="flex-10 p-2 grey">Marża odsetkowa</div>
            </div>
            {debtor.basicbids_with_intrestmargins && debtor.basicbids_with_intrestmargins.length ? (
              debtor.basicbids_with_intrestmargins.map(i => (
                <RowEditable
                  key={i.identifier}
                  isBlocked={!isEditable}
                  onEdit={() => {
                    props.edit(
                      {
                        ...i,
                        debtor_id: debtor.id,
                        intrest_margin: i.is_intrest_margin
                          ? {
                            date_of_rate: i.date_of_rate,
                            intrest_rate_id: i.intrest_rate.id,
                            value: i.value,
                            is_variable: i.is_variable
                          }
                          : {}
                      },
                      'Proposal_BasicBids'
                    );
                  }}
                  onRemove={() => {
                    props.remove(`debtor/${debtor.id}/basic_bid`, i.id);
                  }}>
                  <div className="flex-15 min-w-150 max-w-250 p-2 br ">{formatString(i.name)}</div>
                  <div className="flex-10 min-w-150 max-w-250 p-2 br ">{formatString(i.max_advance_payment)}</div>
                  <div className="flex-10 min-w-150 max-w-250 p-2 br ">{formatString(i.max_payment_date)}</div>
                  <div className="flex-10 min-w-100 max-w-150 p-2 br ">{formatString(i.flat_rate)}</div>
                  <div className="flex-10 max-w-180 p-2 br ">{formatString(i.date_of_rate)}</div>
                  <div className="flex-10 max-w-150 p-2 br ">{i.intrest_rate ? i.intrest_rate.name : '-'}</div>
                  <div className="flex-10 max-w-150 p-2 br ">{i.is_variable ? 'tak' : 'nie'}</div>
                  <div className="flex-10 max-w-180 p-2 br ">{i.is_intrest_margin ? 'tak' : 'nie'}</div>
                  <div className="flex-10 p-2 ">{formatString((i.value))}</div>
                </RowEditable>
              ))
            ) : (
              <NoData />
            )}

            <div className="row bb">
              <div className="col-12 px-2 pt-24 pb-10 text-themecolor font-weight-bold">
                Stawki złożone
                <span className="float-right" style={{ cursor: 'pointer' }}>
                  {p.offer_has_multilevelbids && (
                    <IconSync isBlocked={!isEditable} onClick={() => setFromOffer({ debtor: debtor, obj: 'multilevelbids_mode' })} />
                  )}
                  <IconPlus isBlocked={!isEditable} onClick={() => props.edit({ debtor_id: debtor.id }, 'Proposal_MultilevelBids')} />
                </span>
              </div>
            </div>
            <div className="row bb">
              <div className="flex-15 min-w-150 max-w-250 p-2 br grey">Nazwa stawki</div>
              <div className="flex-10 min-w-150 max-w-250 p-2 br grey">Max wysokość zaliczki</div>
              <div className="flex-10 min-w-150 max-w-250 p-2 br grey">Max termin płatności</div>
              <div className="flex-10 min-w-100 max-w-150 p-2 br grey">Stawka flat</div>
              <div className="flex-10 max-w-100 p-2 br grey">Poziom</div>
              <div className="flex-40 p-2 grey">Nazwa dokumentu</div>
            </div>

            {debtor.multilevelbids && debtor.multilevelbids.length ? (
              debtor.multilevelbids.map(i => (
                <RowEditable
                  key={i.identifier}
                  isBlocked={!isEditable}
                  onRemove={() => {
                    props.remove(`debtor/${debtor.id}/multilevel_bid`, i.id);
                  }}>
                  {i.levels.map((level, key) => {
                    return (
                      <div key={level.id} className="col-sm-12">
                        <div className="row">
                          <div className="flex-15 min-w-150 max-w-250 p-2 br">{key === 0 ? i.name : ''}</div>
                          <div className="flex-10 min-w-150 max-w-250 p-2 br">{level.max_advance_payment}</div>
                          <div className="flex-10 min-w-150 max-w-250 p-2 br">{i.max_payment_date}</div>
                          <div className="flex-10 min-w-100 max-w-150 p-2 br">{i.multilevel_flat_rate}</div>
                          <div className="flex-10 max-w-100 p-2 br">{level.level_no}</div>
                          <div className="flex-40 p-2">{level.document_to_buy_types ? level.document_to_buy_types.map(x => x.name).join(', ') : '-'}</div>
                        </div>
                      </div>
                    )
                  })}
                </RowEditable>
              ))
            ) : (
              <NoData />
            )}

            <div className="row bb">
              <div className="col-12 px-2 pt-24 pb-10 text-themecolor font-weight-bold">
                Stawki dodatkowe
                <span className="float-right" style={{ cursor: 'pointer' }}>
                  {p.offer_has_additionalbids && (
                    <IconSync isBlocked={!isEditable} onClick={() => setFromOffer({ debtor: debtor, obj: 'additionalbids_mode' })} />
                  )}
                  <IconPlus isBlocked={!isEditable} onClick={() => props.edit({ debtor_id: debtor.id }, 'Proposal_AdditionalBids')} />
                </span>
              </div>
            </div>
            <div className="row bb">
              <div className="flex-35 min-w-150 p-2 br grey">Nazwa stawki</div>
              <div className="flex-20 min-w-150 p-2 br grey">Typ stawki</div>
              <div className="flex-20 min-w-150 p-2 br grey">Przeterminowanie ponad (dni)</div>
              <div className="flex-10 p-2 grey">Stawka</div>
            </div>
            {debtor.additionalbids && debtor.additionalbids.length ? (
              debtor.additionalbids.map(i => (
                <RowEditable
                  key={i.identifier}
                  isBlocked={!isEditable}
                  onEdit={() => {
                    props.edit({ ...i, debtor_id: debtor.id }, 'Proposal_AdditionalBids');
                  }}
                  onRemove={() => {
                    props.remove(`debtor/${debtor.id}/additional_bid`, i.id);
                  }}>
                  <div className="flex-35 min-w-150 p-2 br ">{i.name}</div>
                  <div className="flex-20 min-w-150 p-2 br ">{getBidType(i.bid_type)}</div>
                  <div className="flex-20 min-w-150 p-2 br ">{i.days_after_payment_date}</div>
                  <div className="flex-10 p-2 ">{i.rate_amount}</div>
                </RowEditable>
              ))
            ) : (
              <NoData />
            )}

            <div className="row bb">
              <div className="col-12 px-2 pt-24 pb-10 text-themecolor font-weight-bold">
                Dokumenty do wykupu
                <span className="float-right" style={{ cursor: 'pointer' }}>
                  {p.offer_has_documents_to_buy && (
                    <IconSync isBlocked={!isEditable} onClick={() => setFromOffer({ debtor: debtor, obj: 'documentstobuy_mode' })} />
                  )}
                  <IconPlus isBlocked={!isEditable} onClick={() => props.edit({ debtor_id: debtor.id, documentstobuy: [{}] }, 'Proposal_Documents')} />
                </span>
              </div>
            </div>
            <div className="row bb">
              <div className="flex-34 p-2 br grey">Nazwa dokumentu</div>
              <div className="flex-20 p-2 br grey">Oczekiwana forma dokumentu</div>
              <div className="flex-30 p-2 grey">Wymagania formalne</div>
            </div>
            {debtor.documentstobuy && debtor.documentstobuy.length ? (
              debtor.documentstobuy.map(i => (
                <RowEditable
                  key={i.identifier}
                  isBlocked={!isEditable}
                  onEdit={() => {
                    props.edit(
                      {
                        debtor_id: debtor.id,
                        documentstobuy: [
                          {
                            ...i,
                            dtb_type_id: i.dtb_type.id,
                            dtb_form_id: i.dtb_form.id,
                            formal_expectation_id: i.formal_expectation ? i.formal_expectation.id : null
                          }
                        ]
                      },
                      'Proposal_Documents'
                    );
                  }}
                  onRemove={() => {
                    props.remove(`debtor/${debtor.id}/document_to_buy`, i.id);
                  }}>
                  <div className="flex-34 p-2 br ">{i.dtb_type ? i.dtb_type.name : '-'}</div>
                  <div className="flex-20 p-2 br ">{i.dtb_form ? i.dtb_form.name : '-'}</div>
                  <div className="flex-30 p-2 ">{i.formal_expectation ? i.formal_expectation.name : '-'}</div>
                </RowEditable>
              ))
            ) : (
              <NoData />
            )}

            {props.inAgreement ? null : (
              <>
                <div className="row bb">
                  <div className="col-12 px-2 pt-24 pb-10 text-themecolor font-weight-bold">
                    Zabezpieczenia dłużnika
                    <span className="float-right" style={{ cursor: 'pointer' }}>
                      {p.offer_has_secure_documents && (
                        <IconSync isBlocked={!isEditable} onClick={() => setFromOffer({ debtor: debtor, obj: 'securedocuments_mode' })} />
                      )}
                      <IconPlus isBlocked={!isEditable} onClick={() => props.edit({ issuer: debtor.debtor }, 'Proposal_Secures')} />
                    </span>
                  </div>
                </div>
                <div className="row bb">
                  <div className="col-sm-2 p-2 br grey">Rodzaj zabezpieczenia</div>
                  <div className="col-sm-2 p-2 br grey">Kwota zabezpieczenia</div>
                  <div className="col-sm-2 p-2 br grey">Wartość zabezpieczenia</div>
                  <div className="col-sm-2 p-2 br grey">Forma dokumentu</div>
                  {/*<div className="col-sm-1 p-2 br grey">Wystawiający</div>*/}
                  <div className="col-sm-1 p-2 br grey">Klauzula</div>
                  <div className="col-sm-1 p-2 br grey">Wzór faktora</div>
                  <div className="col-sm-2 p-2 grey">Moje punkty / max</div>
                </div>
                {debtor.securedocuments && debtor.securedocuments.length ? (
                  debtor.securedocuments.map(i => (
                    <RowEditable
                      key={i.identifier}
                      isBlocked={!isEditable}
                      onEdit={() => {
                        props.edit(
                          {
                            debtor_id: debtor.debtor?.id,
                            ...i,
                            dts_type_id: i.dts_type.id,
                            dts_form_id: i.dts_form.id
                          },
                          'Proposal_Secures'
                        );
                      }}
                      onRemove={() => {
                        props.remove(props.inAgreement ? 'documents_to_secure' : 'secure_documents', i.id);
                      }}>
                      <div className="col-sm-2 p-2 br ">{i.dts_type ? i.dts_type.name : '-'}</div>
                      <div className="col-sm-2 p-2 br ">{parseMoney(i.current_debt)}</div>
                      <div className="col-sm-2 p-2 br ">{parseMoney(i.collateral_value)}</div>
                      <div className="col-sm-2 p-2 br ">{i.dts_form ? i.dts_form.name : '-'}</div>
                      <div className="col-sm-1 p-2 br ">{i.clauses ? i.clauses.map(c => c.name).join(', ') : '-'}</div>
                      <div className="col-sm-1 p-2 br ">{i.is_factor_pattern ? 'tak' : 'nie'}</div>
                      <div className="col-sm-2 p-2 ">
                        {i.my_score}/{i.dts_type ? i.dts_type.score : '0'}
                      </div>
                    </RowEditable>
                  ))
                ) : (
                  <NoData />
                )}
              </>
            )}
          </div>

          <BaseModal
            visible={props.formVisible('DebtorsEditForm')}
            header={
              <div className="d-flex align-items-center">
                <i className="fas fa-pencil-alt mr-2" />
                <span>Dłużnik</span>
              </div>
            }
            footer="Aby zakończyć edycję wciśnij Zapisz."
            onClickAway={() => props.toggleForm('DebtorsEditForm')}>
            <DebtorsForm
              details
              categoryDisabled={props.categoryDisabled}
              forms={props.forms}
              preloadedForm
              visibleOnMount
              clear={props.clear}
              visible={props.formVisible('DebtorsEditForm')}
              formVisible={props.formVisible}
              toggleForm={props.toggleForm}
              fetch={props.fetch}
              proposal={props.proposal}
              version={props.version}
              formName="DebtorsEditForm"
              submitForm={() => props.saveForm('DebtorsEditForm')}
              onCancelButtonClick={() => props.toggleForm('DebtorsEditForm')}
              storeInput={props.storeInput}
              withButtons
              formData={props.formData}
              offerData={props.offerData}
              proposalData={props.proposalData}
              addField={props.addField}
              removeField={props.removeField}
              getFlatRate={props.getFlatRate}
              getIntrestRates={props.getIntrestRates}
              getMultilevelFlatRate={props.getMultilevelFlatRate}
              intrestRates={props.intrestRates}
              hasRiskCategory={props.proposal.transaction_risk_category}
              setLastAvailableDateOfRate={props.setLastAvailableDateOfRate}
            />
          </BaseModal>

          <BaseModal
            visible={props.formVisible('Proposal_BasicBids')}
            header={
              <div className="d-flex align-items-center">
                <i className="fas fa-pencil-alt mr-2" /> <span>Stawki podstawowe</span>
              </div>
            }
            footer="Aby zakończyć edycję wciśnij Zapisz."
            onClickAway={() => props.toggleForm('Proposal_BasicBids')}>
            <RatesForm
              margin_based_on_tran_risk={offerData.offer_setup ? offerData.offer_setup.global_min_margin_control_basicbid : false}
              setLastAvailableDateOfRate={() => props.setLastAvailableDateOfRate('Proposal_BasicBids')}
              getFlatRate={props.getFlatRate}
              getIntrestRates={props.getIntrestRates}
              intrestRates={props.intrestRates}
              maxPaymentDates={maxPaymentDates}
              formName="Proposal_BasicBids"
              offer={props.offer}
              preloadedForm
              visibleOnMount={false}
              clear={props.clear}
              visible={props.formVisible('Proposal_BasicBids')}
              formVisible={props.formVisible}
              saveForm={() => props.saveForm('Proposal_BasicBids')}
              onCancel={() => props.toggleForm('Proposal_BasicBids')}
              withButtons
              errors={props.errors ? props.errors.Proposal_BasicBids : {}}
              forms={props.forms}
              toggleForm={props.toggleForm}
              hasRiskCategory={props.proposal.transaction_risk_category}
            />
          </BaseModal>

          <BaseModal
            visible={props.formVisible('Proposal_MultilevelBids')}
            header={
              <div className="d-flex align-items-center">
                <i className="fas fa-pencil-alt mr-2" /> <span>Stawki wielopoziomowe</span>
              </div>
            }
            footer="Aby zakończyć edycję wciśnij Zapisz."
            onClickAway={() => props.toggleForm('Proposal_MultilevelBids')}>
            <MultilevelRatesForm
              margin_based_on_tran_risk={offerData.offer_setup ? offerData.offer_setup.global_min_margin_control_multilevelbid : false}
              getMultilevelFlatRate={props.getMultilevelFlatRate}
              dtbs={dtbs}
              formName="Proposal_MultilevelBids"
              offer={props.offer}
              preloadedForm
              visibleOnMount={false}
              clear={props.clear}
              visible={props.formVisible('Proposal_MultilevelBids')}
              formVisible={props.formVisible}
              saveForm={() => props.saveForm('Proposal_MultilevelBids')}
              onCancel={() => props.toggleForm('Proposal_MultilevelBids')}
              withButtons
              errors={props.errors ? props.errors.Proposal_MultilevelBids : {}}
              forms={props.forms}
              toggleForm={props.toggleForm}
            />
          </BaseModal>

          <BaseModal
            visible={props.formVisible('Proposal_AdditionalBids')}
            header={
              <div className="d-flex align-items-center">
                <i className="fas fa-pencil-alt mr-2" /> <span>Stawki dodatkowe</span>
              </div>
            }
            footer="Aby zakończyć edycję wciśnij Zapisz."
            onClickAway={() => props.toggleForm('Proposal_AdditionalBids')}>
            <AdditionalRatesForm
              bidTypes={bidTypes}
              formName="Proposal_AdditionalBids"
              offer={props.offer}
              preloadedForm
              visibleOnMount={false}
              clear={props.clear}
              visible={props.formVisible('Proposal_AdditionalBids')}
              formVisible={props.formVisible}
              saveForm={() => props.saveForm('Proposal_AdditionalBids')}
              onCancel={() => props.toggleForm('Proposal_AdditionalBids')}
              withButtons
              errors={props.errors ? props.errors.Proposal_AdditionalBids : {}}
              forms={props.forms}
              toggleForm={props.toggleForm}
            />
          </BaseModal>

          <BaseModal
            visible={props.formVisible('Proposal_Documents')}
            header={
              <div className="d-flex align-items-center">
                <i className="fas fa-pencil-alt mr-2" /> <span>Dokumenty do wykupu</span>
              </div>
            }
            footer="Aby zakończyć edycję wciśnij Zapisz."
            onClickAway={() => props.toggleForm('Proposal_Documents')}>
            <DocumentsForm
              details
              forms={props.forms}
              proposalData={props.proposalData}
              offerData={props.offerData}
              proposal={props.proposal}
              version={props.version}
              preloadedForm
              visibleOnMount
              clear={props.clear}
              visible={props.formVisible('Proposal_Documents')}
              formVisible={props.formVisible}
              onCancelButtonClick={() => props.toggleForm('Proposal_Documents')}
              toggleForm={props.toggleForm}
              formName="Proposal_Documents"
              withButtons
              submitForm={() => props.saveForm('Proposal_Documents')}
            />
          </BaseModal>

          <BaseModal
            visible={props.formVisible('Proposal_Secures')}
            header={
              <div className="d-flex align-items-center">
                <i className="fas fa-pencil-alt mr-2" /> <span>Zabezpieczenia dłużnika</span>
              </div>
            }
            footer="Aby zakończyć edycję wciśnij Zapisz."
            onClickAway={() => props.toggleForm('Proposal_Secures')}>
            <SecuresForm
              issuers={props.issuers}
              fetchIssuers={props.fetchIssuers}
              forms={props.forms}
              common={props.common}
              proposal={props.proposal}
              version={props.version}
              preloadedForm
              visibleOnMount
              clear={props.clear}
              visible={props.formVisible('Proposal_Secures')}
              formVisible={props.formVisible}
              onCancelButtonClick={() => props.toggleForm('Proposal_Secures')}
              toggleForm={props.toggleForm}
              formName="Proposal_Secures"
              withButtons
              submitForm={() => props.saveForm('Proposal_Secures')}
            />
          </BaseModal>
          <BaseModal
            visible={Object.keys(fromOffer).length > 0}
            header={
              <div className="d-flex align-items-center">
                <span>Przywracanie danych z oferty</span>
              </div>
            }
            footer="Aby zakończyć edycję wciśnij Zapisz."
            onClickAway={() => setFromOffer({})}>
            <div className="row">
              <div className="mt-1 col-sm-12">Czy chcesz przywrócić dane z oferty?</div>
            </div>

            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={() => setFromOffer({})}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}>
                  Anuluj
                </button>
                <button
                  type="button"
                  onClick={() => {
                    props.fetchFromOffer(fromOffer.debtor, fromOffer.obj);
                    setFromOffer({});
                  }}
                  className="btn btn-primary ml-2  font-weight-light">
                  Przywróć
                </button>
              </div>
            </div>
          </BaseModal>
        </Card>
      ))}
    </div>
  );
};
