import clsx from 'clsx';
import React from 'react';

const primaryOutline = 'btn btn-primary-outline mr-2 font-weight-light';
const primary = 'btn btn-primary ml-2 font-weight-light';
const secondary = 'btn btn-secondary ml-2 font-weight-light';
const select = 'font-weight-light mb-15';
const danger = 'btn btn-danger ml-2 pl-5 pr-5 font-weight-light';

const getStyles = mode => {
  switch(mode) {
    case 'primary': return primary;
    case 'secondary': return secondary;
    case 'select': return select;
    case 'danger': return danger;
    default: return primaryOutline;
  }
};

export default function Button({ className, mode, type, children, ...props }) {
  return (
    <button className={clsx(getStyles(mode), className)} type={type} {...props}>
      {children}
    </button>
  );
}

Button.defaultProps = {
  type: 'button',
  onClick: () => {}
};
