// Założenia:
// TABELA_OPLAT_I_PROWIZJI_Z2 i SZCZEGOLOWE_WARUNKI_UMOWY_Z4:
// okres obowiązywania powinien byc z umowy - data zawarcia tak samo i tylko read only
export const AGREEMENT_TYPES = {
  AML_OSOBA_FIZYCZNA: 'aml-osoba-fizyczna',
  AML_OSOBA_PRAWNA: 'aml-osoba-prawna',
  ANEKS_DO_UMOWY_LIMITU: 'aneks-do-umowy-limitu',
  KLAUZULA_INFORMACYJNA_RODO: 'klauzula-informacyjna-rodo',
  MONIT_O_ZAPLATE: 'monit-o-zaplate',
  OSWIADCZENIE_O_NIEZALEGANIU_ZUS_US: 'oswiadczenie-o-niezaleganiu-zus_us',
  SZCZEGOLOWE_WARUNKI_UMOWY_Z4: 'szczegolowe-warunki-umowy-z4',
  TABELA_OPLAT_I_PROWIZJI_Z2: 'tabela-oplat-i-prowizji-z2',
  UMOWA_LIMITU_Z_REGRESEM_Z1_Z3: 'umowa-limitu-z-regresem-z1-z3',
  WEZWANIE_DO_ZAPLATY_Z_KRD: 'wezwanie-do-zaplaty-z-krd'
};
