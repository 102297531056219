import React from 'react';

import ExternalSettlements from '../Components/ExternalSettlements';
import InternalSettlements from '../Components/InternalSettlements';
import Records from '../Components/Records';

export default props => (
  <>
    <ExternalSettlements
      common={props.common}
      claim={props.claim}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      create={props.edit}
      saveForm={props.saveForm}
      edit={props.edit}
      onCancel={f => props.toggleForm(f)}
      remove={(t, id) => props.remove(t, id)}
      removeTransfer={props.removeTransfer}
      storeInput={props.storeInput}
      clear={props.clear}
      docs={props.docs}
      docsFilters={props.docsFilters}
      fetchDocs={props.fetchDocs}
      clients={props.clients}
      clientFilters={props.clientFilters}
      fetchClients={props.fetchClients}
      sendCompensation={props.sendCompensation}
    />
    <InternalSettlements
      common={props.common}
      claim={props.claim}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      create={props.edit}
      saveForm={props.saveForm}
      edit={props.edit}
      onCancel={f => props.toggleForm(f)}
      storeInput={props.storeInput}
      fetchBuckets={props.fetchBuckets}
      buckets={props.buckets}
      removeLink={props.removeLink}
      linkChargeWithPayment={props.linkChargeWithPayment}
      removeChargeFromPayment={props.removeChargeFromPayment}
      compensate={props.compensate}
      docs={props.docs}
      docsFilters={props.docsFilters}
      fetchDocs={props.fetchDocs}
      documentsFilters={props.documentsFilters}
      fetchDocuments={props.fetchDocuments}
      documents={props.documents}
      clients={props.clients}
      clientFilters={props.clientFilters}
      fetchClients={props.fetchClients}
      sendCompensation={props.sendCompensation}
      removeCompensation={props.removeCompensation}
    />
    <Records
      common={props.common}
      claim={props.claim}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      create={props.edit}
      saveForm={props.saveForm}
      edit={props.edit}
      onCancel={f => props.toggleForm(f)}
      remove={n => props.remove('complaints', n.id)}
      removeNote={(c, n) => props.remove(`complaints/${c.id}/notes`, n.id)}
      storeInput={props.storeInput}
    />
  </>
);
