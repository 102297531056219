import 'twin.macro';

import FormActions from 'actions/forms';
import Col, { ColName } from 'components/Forms/Col';
import React from 'react';
import { useDispatch } from 'react-redux';

import { Checkbox, Form, Input, Required, Select, Textarea } from '../../../components/Forms';

export const BasicDataForm = (props) => {
  const dispatch = useDispatch();

  const durations = props.durations || [
    { value: 1, name: '1 miesiąc' },
    { value: 3, name: '3 miesiące' },
    { value: 6, name: '6 miesięcy' },
    { value: 12, name: '12 miesięcy' }
  ];
  const transactionRiskCategories = props.offerData && props.offerData.transaction_risk_categories
    ? props.offerData.transaction_risk_categories.map((i) => ({ ...i, value: i.id }))
    : [];
  const currencies = props.currencies ? props.currencies.map((i) => ({ ...i, value: i.id })) : [];

  const commissionTypes = props.offerData && props.offerData.preparation_commission_type
    ? props.offerData.preparation_commission_type.map((i) => ({ ...i, value: i.id }))
    : [];

  const f = props.forms && props.formName ? props.forms[props.formName] : {};

  const handleForm = () => {
    props.saveForm();
    clearErrors();
  };

  const clearErrors = () => {
    dispatch(FormActions.clearErrors('BasicRatesCreatorForm'));
    dispatch(FormActions.clearErrors('MultilevelRatesCreatorForm'));
  };

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="t-row">
            <ColName className='text-right flex-1'>Wersja kalkulacji</ColName>
            <Col className='ml-10 flex-4'>
              {f.id && f.version ? f.version : (props.calculationsCount || 0) + 1}
              <div className='min-h-15' />
            </Col>
          </div>

          <div className="t-row">
            <ColName className='text-right flex-1'>Status kalkulacji</ColName>
            <Col className='ml-10 flex-4'>
              <Checkbox className="custom-control custom-checkbox d-flex" label="" formName={props.formName} name="status" />
            </Col>
          </div>

          <div className="t-row">
            <ColName className='text-right flex-1'>Planowany okres umowy<Required /></ColName>
            <Col className='ml-10 flex-4'>
              <Select formName={props.formName} options={durations} name="duration_of_the_contract" number />
            </Col>
          </div>

          <div className="t-row">
            <ColName className='text-right flex-1'>Kwota</ColName>
            <div className='t-row flex-4'>
              <div className='ml-10 flex flex-1'>
                <Input formName={props.formName} type="text" name="limit_amount" />

                <div className='ml-10'>
                  {props.formName === 'CalculationsEditForm' ? <div className='pt-07'>{f.currencyName}</div> : (
                    <Select
                      tw='w-80'
                      isClearable={false}
                      formName={props.formName}
                      options={currencies}
                      name="currency"
                      errorName="currency_id"
                      placeholder='waluta'
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="t-row">
            <ColName className='text-right flex-1'>Regres</ColName>
            <Col className='ml-10 flex-4'>
              <Checkbox className="custom-control custom-checkbox d-flex" label="" formName={props.formName} name="recourse" />
            </Col>
          </div>

          <div className="t-row">
            <ColName className='text-right flex-1'>Wielu dłużników</ColName>
            <Col className='ml-10 flex-4'>
              <Checkbox
                className="custom-control custom-checkbox d-flex"
                label=""
                formName={props.formName}
                name="many_debtors"
              />
            </Col>
          </div>

          <div className="t-row">
            <ColName className='text-right flex-1'>Kategoria ryzyka transakcji<Required /></ColName>
            <Col className='ml-10 flex-4'>
              <Select
                formName={props.formName}
                options={transactionRiskCategories}
                name="transaction_risk_category_id"
                onChange={clearErrors}
                number
              />
            </Col>
          </div>

          <div className="t-row">
            <ColName className='text-right flex-1'>Prowizja faktora<Required /></ColName>

            <div className='t-row flex-4'>
              <Col className='ml-10 flex-15'>
                <Input formName={props.formName} type="text" name="preparation_commission" />
              </Col>

              <ColName className='text-right flex-10'>Liczona<Required /></ColName>
              <Col className='ml-10 flex-20'>
                <Select formName={props.formName} options={commissionTypes} name="preparation_commission_type" number />
              </Col>

              <ColName className='text-right flex-20'>Potrącana z zaliczki<Required /></ColName>
              <Col className='ml-10 flex-5'>
                <Checkbox
                  label=""
                  formName={props.formName}
                  name="deducted_preparation_commission"
                />
              </Col>
            </div>
          </div>

          <div className="t-row">
            <ColName className='text-right flex-1'>Komentarz drukowany</ColName>
            <Col className='ml-10 flex-4'>
              <Textarea formName={props.formName} name="comment" />
            </Col>
          </div>

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancel}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}
                >
                  Anuluj
                </button>
                <button type="button" onClick={handleForm} className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
