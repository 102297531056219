import Input from 'components/Forms/OldInput';
import React from 'react';

import { Form, Selectable } from '../../../components/Forms';
import { RowEditable } from '../../../components/Layout/RowEditable';
import { parseMoney } from '../../../utilities/helpers';

export const ConnectForm = props => {
  const [invoiceSelectableVisible, setInvoiceSelectableVisible] = React.useState(false);
  const [selectableVisible, setSelectableVisible] = React.useState(false);
  const [selectableVatVisible, setSelectableVatVisible] = React.useState(false);

  const f = props.forms?.[props.formName] ?? {};
  const currency = f.transfer?.gross_transfer_value_currency?.name ?? '-';
  const vatCurrency = f.transfer?.vat_transfer_value_currency?.name ?? '-';

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {/* <div className="row d-flex align-items-center"> */}
      {/*  <div className="col-sm-9"> */}
      {/*    <div className="form-group row mb-0 align-items-center"> */}
      {/*      <label htmlFor="example-text-input " className="col-sm-2 col-form-label label-right"> */}
      {/*        Klient */}
      {/*        <Required /> */}
      {/*      </label> */}
      {/*      <div className="col-sm-10"> */}
      {/*        <button type="button" onClick={() => {}}>zmień</button> */}
      {/*      </div> */}
      {/*    </div> */}
      {/*  </div> */}
      {/*  <div className="col-sm-3"> */}
      {/*    <span className="mr-3"> */}
      {/*      Tylko nierozliczone */}
      {/*    </span> */}
      {/*    <BooleanCheck field={false} /> */}
      {/*  </div> */}
      {/* </div> */}
      <div className="row mt-3 bt bb">
        <div className="col-sm-2 grey p-2 br">Uznanie netto</div>
        <div className="col-sm-4 grey p-2 br">Tytuł przelewu</div>
        <div className="col-sm-4 grey p-2 br">Zleceniodawca</div>
        <div className="col-sm-2 grey p-2">Data księgowania</div>
      </div>
      <div className="row bb">
        <div className="col-sm-2  p-2 br" style={{ color: '#1DB393' }}>
          {parseMoney(f.transfer?.net_transfer_value)} {currency}
        </div>
        <div className="col-sm-4  p-2 br">{f.transfer?.payment_title}</div>
        <div className="col-sm-4  p-2 br">{f.transfer?.sender_details}</div>
        <div className="col-sm-2  p-2">{f.transfer?.posting_date}</div>
      </div>
      <div className="row mt-2 align-items-center">
        <div className="col-sm-1 grey font-weight-bold p-2">ID</div>
        <div className="col-sm-2 grey font-weight-bold p-2">Kwota {currency}</div>
        <div className="col-sm-3 grey font-weight-bold p-2">Wierzytelność</div>
        <div className="col-sm-3 grey font-weight-bold p-2">Dokument</div>
        <div className="col-sm-2 grey font-weight-bold p-2">Rozliczono</div>
      </div>
      {f?.claims?.map((k, j) => (
        <RowEditable
          className="mb-0 align-items-center"
          onRemove={() => props.storeInput(props.formName, 'claims', f?.claims?.filter((x, y) => y !== j))}>
          <div className="col-sm-1 p-2">{j + 1}</div>
          <div className="col-sm-2 p-2">
            <Input formName={props.formName} type="text" name={`claims.${j}.amount`} />
          </div>
          <div className="col-sm-3 p-2">
            {k.claim?.identifier}
            <button
              type="button"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                props.fetch();
                setSelectableVisible(j);
              }}>
              Wybierz
            </button>
          </div>
          <div className="col-sm-3 p-2">
            {k.document?.identifier}
            <button
              type="button"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                props.fetchInvoices();
                setInvoiceSelectableVisible(j);
              }}>
              Wybierz
            </button>
          </div>
          <div className="col-sm-2 p-2">-</div>

          <Selectable
            visible={invoiceSelectableVisible === j}
            formName={props.formName}
            field={`claims.${j}.document`}
            columns={props.invoiceColumns}
            options={props.invoices}
            fetch={props.fetchInvoices}
            filtersForm={props.invoicesFilters}
            onClickAway={() => setInvoiceSelectableVisible(false)}
            header="Wybierz dokument do powiązania"
            footer="Aby zapisać wciśnij ZATWIERDŹ"
          />
          <Selectable
            visible={selectableVisible === j}
            formName={props.formName}
            field={`claims.${j}.claim`}
            columns={props.columns}
            options={props.options}
            fetch={props.fetch}
            filtersForm={props.claimsFilters}
            onClickAway={() => setSelectableVisible(false)}
            header="Wybierz wierzytelność do powiązania"
            footer="Aby zapisać wciśnij ZATWIERDŹ"
          />
        </RowEditable>
      ))}
      <div className="row mt-1 align-items-center">
        <div className="col-sm-12 grey font-weight-bold p-2">
          <button type="button" onClick={() => props.storeInput(props.formName, 'claims', [...(f?.claims ?? []), {}])}>
            Dodaj
          </button>
        </div>
      </div>

      {f.transfer?.vat_transfer_value !== '0.00' ? (
        <>
          <div className="row mt-3 bt bb">
            <div className="col-sm-2 grey p-2 br">Uznanie VAT</div>
            <div className="col-sm-4 grey p-2 br">Tytuł przelewu</div>
            <div className="col-sm-4 grey p-2 br">Zleceniodawca</div>
            <div className="col-sm-2 grey p-2">Data księgowania</div>
          </div>
          <div className="row bb">
            <div className="col-sm-2  p-2 br" style={{ color: '#1DB393' }}>
              {parseMoney(f.transfer?.vat_transfer_value)} {vatCurrency}
            </div>
            <div className="col-sm-4  p-2 br">{f.transfer?.payment_title}</div>
            <div className="col-sm-4  p-2 br">{f.transfer?.sender_details}</div>
            <div className="col-sm-2  p-2">{f.transfer?.posting_date}</div>
          </div>

          <div className="row mt-2 align-items-center">
            <div className="col-sm-1 grey font-weight-bold p-2">ID</div>
            <div className="col-sm-2 grey font-weight-bold p-2">Kwota {vatCurrency}</div>
            <div className="col-sm-3 grey font-weight-bold p-2">Wierzytelność</div>
            <div className="col-sm-3 grey font-weight-bold p-2">Poza faktoringiem</div>
          </div>
          <RowEditable className="mb-0 align-items-center" onRemove={() => props.storeInput(props.formName, 'claim_vat', null)}>
            <div className="col-sm-1 p-2">1</div>
            <div className="col-sm-2 p-2">
              <Input formName={props.formName} type="text" name={'claim_vat.amount'} />
            </div>
            <div className="col-sm-3 p-2">
              {f.claim_vat?.claim?.identifier}
              <button
                type="button"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  props.fetch();
                  setSelectableVatVisible(true);
                }}>
                Wybierz
              </button>
            </div>
            <div className="col-sm-3 p-2">
              <button type="button" onClick={() => {}} disabled>
                Klient
              </button>
            </div>
            <Selectable
              visible={selectableVatVisible}
              formName={props.formName}
              field={'claim_vat.claim'}
              columns={props.columns}
              options={props.options}
              fetch={props.fetch}
              onClickAway={() => setSelectableVatVisible(false)}
              header="Wybierz wierzytelność do powiązania"
              footer="Aby zapisać wciśnij ZATWIERDŹ"
            />
          </RowEditable>
        </>
      ) : null}

      {props.withButtons ? (
        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button
              type="button"
              onClick={() => props.toggleForm(props.formName)}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}>
              Anuluj
            </button>
            <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
              Zatwierdź
            </button>
          </div>
        </div>
      ) : null}
    </Form>
  );
};
