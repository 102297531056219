import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'providers/ThemeProvider';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import store from './utilities/store';

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

const renderApp = () =>
  render(
    <Provider store={store}>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </Provider>,
    document.getElementById('root')
  );

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept(App, renderApp);
}

renderApp();
