import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CommonActions from '../../../actions/common';
import { Form, Input } from '../../../components/Forms';
import {
  OldCard, Container, MainContainer
} from '../../../components/Layout';

class Raports extends React.Component {
  componentDidMount() {
    this.props.dispatch(CommonActions.setContextMenu());
  }

  render() {
    const header = (
      <>
        <span className="mb-0 font-weight-light" style={{ color: '#006BFF', fontSize: '24px' }}>
          Moduł raportowania
        </span>
      </>
    );

    return (
      <Container>
        <MainContainer className="col-sm-12">
          <OldCard className="mt-5" header={header}>
            <Form asDiv>

              <div className="row bb ">
                <div className="col-sm-1 pl-2 pr-2 pt-3 pb-3 br text-center">
                  1
                </div>
                <div className="col-sm-11 pl-3 pr-2 pt-3 pb-3 br d-flex align-items-center">
                  Klient
                  <button type="button" className="ml-3">Wybierz</button>
                </div>
              </div>
              <div className="row bb ">
                <div className="col-sm-1 pl-2 pr-2 pt-3 pb-3 br text-center">
                  2
                </div>
                <div className="col-sm-11 pl-3 pr-2 pt-3 pb-3 br d-flex align-items-center">
                  Dłużnik
                  <button type="button" className="ml-3">Wybierz</button>
                </div>
              </div>
              <div className="row bb ">
                <div className="col-sm-1 pl-2 pr-2 pt-3 pb-3 br text-center">
                  3
                </div>
                <div className="col-sm-11 pl-3 pr-2 pt-3 pb-3 br d-inline-flex">
                  <div className="mr-4">
                    <label className="custom-control custom-radio d-inline-flex ">
                      <input
                        id="test1"
                        name="test1"
                        type="radio"
                        className="custom-control-input mr-3"

                      />
                      <span className="custom-control-label radio-label">Wierzytelności</span>
                    </label>
                  </div>
                  <div>
                    <label className="custom-control custom-radio d-inline-flex ">
                      <input
                        id="test1}"
                        name="test1"
                        type="radio"
                        className="custom-control-input mr-3"
                      />
                      <span className="custom-control-label radio-label">Faktury</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row bb ">
                <div className="col-sm-1 pl-2 pr-2 pt-3 pb-3 br text-center">
                  4
                </div>
                <div className="col-sm-11 pl-3 pr-2 pt-3 pb-3 br">
                  <div className="row bb align-items-center align-self-center">
                    <div className="col-sm-12 p-2 ">
                      <label className="custom-control custom-radio d-inline-flex ">
                        <input
                          id="test1}"
                          name="test1"
                          type="radio"
                          className="custom-control-input mr-3"
                        />
                        <span className="custom-control-label radio-label">Wiekowanie na dziś</span>
                      </label>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-sm-2 p-2">
                      <label className="custom-control custom-radio d-inline-flex ">
                        <input
                          id="test1}"
                          name="test1"
                          type="radio"
                          className="custom-control-input mr-3"
                        />
                        <span className="custom-control-label radio-label">Skumulowane wg daty</span>
                      </label>
                    </div>
                    <div className="col-sm-6 p-2">
                      <div className="row ">
                        <label htmlFor="example-text-input " className="col-sm-1 col-form-label label-right">
                          Od
                        </label>
                        <div className="col-sm-2">
                          <Input formName="test" name="creditor" />
                        </div>
                        <label htmlFor="example-text-input " className="col-sm-1 col-form-label label-right">
                          Do
                        </label>
                        <div className="col-sm-2">
                          <Input formName="test" name="creditor" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-sm-12 pl-2 pr-2 pt-3 pb-3 br text-center">
                  <button type="button" className="btn btn-primary btn-primary-blue">
                    Generuj raport
                  </button>
                </div>
              </div>
            </Form>
          </OldCard>
        </MainContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.Common
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Raports)
);
