import React from 'react';
import { withRouter } from 'react-router-dom';

import BooleanCheck from '../../../components/BooleanCheck';
import { OldCard } from '../../../components/Layout';
import { parseMoney } from '../../../utilities/helpers';

class CompanySidebarView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { objectToRemove: {} };
  }

  setObjectToRemove = object => {
    if (this.state.objectToRemove.id) {
      this.setState({ objectToRemove: {} });
    } else {
      this.setState({ objectToRemove: object });
    }
  };

  render() {
    const transfer = this.props.transfer ?? {};
    return (
      <OldCard className="mt-3 pt-3 pb-3">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="font-weight-bold text-themecolor">
              {parseMoney(transfer.gross_transfer_value)} {transfer.gross_transfer_value_currency?.name || transfer.vat_transfer_value_currency?.name}
            </h3>
          </div>
        </div>
        <div className="row bb">
          <div className="col-sm-12 p-2 grey">Dane zleceniodawcy</div>
        </div>
        <div className="row bb">
          <div className="col-sm-12 p-2">{transfer.sender?.name ?? 'Brak'}</div>
        </div>
        <div className="row">
          <div className="col-sm-12 p-2 grey">Tytuł przelewu</div>
        </div>
        <div className="row bb">
          <div className="col-sm-12 pl-2 pr-2">{transfer.payment_title || 'Brak'}</div>
        </div>
        {transfer.transaction_details && (
          <>
            <div className="row">
              <div className="col-sm-12 p-2 grey">Szczegóły transakcji</div>
            </div>
            <div className="row bb">
              <div className="col-sm-12 pl-2 pr-2">{transfer.transaction_details}</div>
            </div>
          </>
        )}
        <div className="row">
          <div className="col-sm-12 p-2 grey">Przeksięgowanie na rach. VAT</div>
        </div>
        <div className="row bb">
          <div className="col-sm-12 pl-2 pr-2">Test</div>
        </div>
        <div className="row">
          <div className="col-sm-12 p-2 grey">Referencje z banku</div>
        </div>
        <div className="row bb">
          <div className="col-sm-12 pl-2 pr-2">Test</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">Kategoria przelewu</div>
          <div className="col-sm-6 p-2 text-right">{this.props.common?.paymentData?.categories?.[transfer.category]}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">System rozliczeniowy</div>
          <div className="col-sm-6 p-2 text-right">{this.props.common?.paymentData?.billing_systems?.[transfer.system_billing]}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">Typ zlecenia</div>
          <div className="col-sm-6 p-2 text-right">{this.props.common?.paymentData?.order_types?.[transfer.order_type]}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">Status w banku</div>
          <div className="col-sm-6 p-2 text-right">Wartość</div>
        </div>
        <div className="row bb bb-bold">
          <div className="col-sm-6 p-2 grey">Data księgowania</div>
          <div className="col-sm-6 p-2 text-right">Wartość</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">Status w naszym systemie</div>
          <div className="col-sm-6 p-2 text-right">{this.props.common?.paymentData?.statuses?.[transfer.status]}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">Data importu / eksportu</div>
          <div className="col-sm-6 p-2 text-right">{this.props.common?.paymentData?.statuses?.[transfer.status]}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">Zaimportował / eksportował</div>
          <div className="col-sm-6 p-2 text-right">{this.props.common?.paymentData?.statuses?.[transfer.status]}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 text-themecolor font-weight-bolder">MPP</div>
          <div className="col-sm-6 p-2 text-right">
            <BooleanCheck field={transfer.mpp} />
          </div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">Waluta / VAT</div>
          <div className="col-sm-6 p-2 text-right">
            {transfer.gross_transfer_value_currency?.name || '-'}
            {' / '}
            {transfer.vat_transfer_value_currency?.name || '-'}
          </div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">Kwota brutto</div>
          <div className="col-sm-6 p-2 text-right">{parseMoney(transfer.gross_transfer_value)}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">Kwota VAT</div>
          <div className="col-sm-6 p-2 text-right">{parseMoney(transfer.vat_transfer_value)}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">Nr faktury</div>
          <div className="col-sm-6 p-2 text-right">{transfer.invoice_number}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">Tytuł przelewu</div>
          <div className="col-sm-6 p-2 text-right">{transfer.payment_title || 'Brak'}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-12 p-2 text-themecolor font-weight-bolder">Rachunek bankowy beneficjenta (Wierzyciela)</div>
        </div>
        <div className="row bb">
          <div className="col-sm-4 p-2 grey">Nr rachunku</div>
          <div className="col-sm-8 p-2 text-right">{transfer.recipient_bank_account?.number}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">Waluta</div>
          <div className="col-sm-6 p-2 text-right">{transfer.recipient_bank_account?.currency?.name}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">Typ rachunku</div>
          <div className="col-sm-6 p-2 text-right">{transfer.recipient_bank_account?.bank_account_type?.name}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">Nazwa banku</div>
          <div className="col-sm-6 p-2 text-right">{transfer.recipient_bank_account?.bank_name}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-12 p-2 text-themecolor font-weight-bolder">Rachunek bankowy obciążany (Dłużnika)</div>
        </div>
        <div className="row bb">
          <div className="col-sm-4 p-2 grey">Nr rachunku</div>
          <div className="col-sm-8 p-2 text-right">{transfer.sender_bank_account?.number}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">Waluta</div>
          <div className="col-sm-6 p-2 text-right">{transfer.sender_bank_account?.currency?.name}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">Typ rachunku</div>
          <div className="col-sm-6 p-2 text-right">{transfer.sender_bank_account?.bank_account_type?.name}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 grey">Nazwa banku</div>
          <div className="col-sm-6 p-2 text-right">{transfer.sender_bank_account?.bank_name}</div>
        </div>
      </OldCard>
    );
  }
}

export default withRouter(CompanySidebarView);
