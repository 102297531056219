import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useState } from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { CommissionForm } from '../../Forms/Commission';
import RemovalModal from '../../Forms/RemovalModal';

export const Commissions = (props) => {
  const [objectToRemove, setObjectToRemove] = useState({});

  const formName = 'CommissionForm';

  const editCommission = (commission, parent) => props.edit(formName, {
    id: commission.id,
    category: parent.category.id,
    commission_type: commission.commission_type,
    unit: commission.unit,
    max_value: commission.max_value,
    currency_id: commission.currency.id,
    is_default_in_offer: commission.is_default_in_offer
  });

  const commissionTypeSelected = props.forms && props.forms[formName] ? Number(props.forms[formName].commission_type) : null;
  const parents = props.person.commission_parents || [];
  const ct = props.commission_types || [];

  const getCommissionType = (c) => {
    const type = ct.find((i) => i.id === c.commission_type);
    return type || {};
  };

  const getCommissionUnit = (c) => {
    const type = getCommissionType(c);

    if (type) {
      const units = type.units || [];
      return units.find((u) => u.id === c.unit) || {};
    }

    return {};
  };

  const categories = props.person && props.person.companies
    ? props.person.companies
      .map((c) => c.roles)
      .flat()
      .concat(props.person.system_roles)
      .filter((r) => r.is_used_for_commissions)
      .map((r) => ({ ...r, value: r.id }))
    : [];

  return (
    <Card header='Ustawienia prowizji' onAddClick={() => props.create(formName)}>
      {parents.length > 0 ? (
        parents.map((parent) => (
          <>
            <div className="row bb">
              <div className="col-sm-12 p-2 text-themecolor">
                <span>
                  Rola:
                  {parent.category.name}
                </span>
              </div>
            </div>

            <div className="row bb grey">
              <div className="col-sm-2 p-2 br">Typ</div>
              <div className="col-sm-2 p-2 br">Kod</div>
              <div className="col-sm-2 p-2 br">Jednostka</div>
              <div className="col-sm-2 p-2 br">Wartość max.</div>
              <div className="col-sm-2 p-2 br">Waluta</div>
              <div className="col-sm-2 p-2">Domyślna w ofercie</div>
            </div>

            {parent.commissions.length > 0 ? (
              parent.commissions.map((c) => (
                <RowEditable isObjectToRemove key={c.id} itemId={c.id} onEdit={() => editCommission(c, parent)} onRemove={() => setObjectToRemove(c)}>
                  <div className="col-sm-2 p-2 br">{getCommissionType(c).name}</div>
                  <div className="col-sm-2 p-2 br">{c.code || '-'}</div>
                  <div className="col-sm-2 p-2 br">{getCommissionUnit(c).name}</div>
                  <div className="col-sm-2 p-2 br">{c.max_value || '-'}</div>
                  <div className="col-sm-2 p-2 br">{c.currency ? c.currency.name : '-'}</div>
                  <div className="col-sm-2 p-2">
                    <BooleanCheck field={c.is_default_in_offer} />

                  </div>
                </RowEditable>
              ))
            ) : (
              <div className="row">
                <div className="col">Brak rekordów.</div>
              </div>
            )}
          </>
        ))
      ) : (
        <div className="row">
          <div className="col mt-3 mb-3">Brak rekordów.</div>
        </div>
      )}

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>
              Ustawienia prowizji
            </span>
          </div>
        )}
        footer="Aby zakończyć wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <CommissionForm
          selectButtonDisabled
          categories={categories}
          currencies={props.currencies}
          commission_types={props.commission_types}
          commissionTypeSelected={commissionTypeSelected}
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(formName)}
          formName={formName}
          setField={props.setField}
          withButtons
          submitForm={() => props.saveForm(formName)}
          onCancelButtonClick={() => props.toggleForm(formName)}
          categorySelected={props.forms[formName] && props.forms[formName].category ? props.forms[formName].category : null}
        />
      </BaseModal>

      <RemovalModal
        visible={Object.keys(objectToRemove).length > 0}
        toggle={() => setObjectToRemove({})}
        remove={() => {
          props.remove(objectToRemove.id);
          setObjectToRemove({});
        }}
      >
        {objectToRemove && (
          <div className="container-fluid">
            <div className="row bb bt grey">
              <div className="col-sm-2 p-2 br">Typ</div>
              <div className="col-sm-1 p-2 br">Kod</div>
              <div className="col-sm-2 p-2 br">Jednostka</div>
              <div className="col-sm-2 p-2 br">Wartość max.</div>
              <div className="col-sm-1 p-2 br">Waluta</div>
              <div className="col-sm-2 p-2 br">Rekordów max.</div>
              <div className="col-sm-2 p-2">Domyślna w ofercie</div>
            </div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br">
                {props.commission_types
                  ? props.commission_types.find((i) => i.id === objectToRemove.commission_type)
                    ? props.commission_types.find((i) => i.id === objectToRemove.commission_type).name
                    : '-'
                  : '-'}
              </div>
              <div className="col-sm-1 p-2 br">{objectToRemove.code || '-'}</div>
              <div className="col-sm-2 p-2 br">
                {props.commission_types
                  ? props.commission_types.find((i) => i.id === objectToRemove.commission_type)
                    ? props.commission_types
                      .find((i) => i.id === objectToRemove.commission_type)
                      .units.find((u) => u.id === objectToRemove.unit).name
                    : '-'
                  : '-'}
              </div>
              <div className="col-sm-2 p-2 br">{objectToRemove.max_value || '-'}</div>
              <div className="col-sm-1 p-2 br">{objectToRemove.currency ? objectToRemove.currency.name : '-'}</div>
              <div className="col-sm-2 p-2 br">{objectToRemove.max_records || '-'}</div>
              <div className="col-sm-2 p-2">
                <BooleanCheck field={objectToRemove.is_default_in_offer} />

              </div>
            </div>
          </div>
        )}
      </RemovalModal>
    </Card>
  );
};
