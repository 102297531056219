import api from 'api';
import Card from 'components/Layout/Card';
import { RowEditable } from 'components/Layout/RowEditable';
import { getAgreementDebtor } from 'containers/Agreements/Creator/Index';
import useData from 'hooks/useData';
import React from 'react';
import { BANK_ACCOUNTS } from 'utilities/enums';

import { Selectable } from '../../../../components/Forms';
import { parseBan } from '../../../../utilities/helpers';

export default props => {
  const agreementVersion = props.version || {};
  const agreement = props.agreement || {};

  const { sendRequest } = useData();

  const columns = [
    { name: 'Numer rachunku', getter: 'number', sortable: 1 },
    { name: 'Waluta', getter: 'currency.name', sortable: 1 },
    { name: 'Alias rachunku', getter: 'bank_alias.name', sortable: 1 },
    { name: 'Typ rachunku', getter: 'bank_account_type.name', sortable: 1 },
    { name: 'Nazwa banku', getter: 'bank_name', sortable: 1 },
    { name: 'Data dodania', getter: 'created_date', sortable: 1 },
    { name: 'Dodał', getter: 'user_creator.full_name', sortable: 1 }
  ];

  const title = d => (
    <div className="row mt-2 align-items-center mb-2">
      <div className="col-sm-1 p-2 text-right grey" style={{ fontWeight: '600' }}>
        Dłużnik
      </div>
      <div className="col-sm-5 p-2 " style={{ fontWeight: '600' }}>
        {d.name}
      </div>
      <div className="col-sm-3 p-2 text-right grey" />
    </div>
  );

  const handleAddDebtorAccounts = (item, banks) => {
    sendRequest(api.agreements.addDebtorBankAccounts({
      id_agreement: agreement?.id,
      id_version: agreementVersion?.id,
      id_debtor: item?.id,
      bank_account_ids: banks.map(bank => bank.id)
    }), () => {
      props.updateView();
    });
  };

  // Only cession and credit bank accounts
  const factorBankAccounts = agreement?.factor?.bank_accounts?.map(i => ({ ...i, number: parseBan(i) }))
    .filter(account => account?.bank_alias?.identifier === BANK_ACCOUNTS.TYPE.UZNANIOWY || account?.bank_alias?.identifier === BANK_ACCOUNTS.TYPE.WALUTOWY);

  return (
    <Card mode='creator' header='+ Rachunki uznaniowe faktora (techniczne dla dłużników)' options={{ isPosted: true }}>
      <div>
        {agreementVersion?.proposal_debtors?.length > 0 ? (
          agreementVersion?.proposal_debtors?.map(item => {
            const agreementDebtor = getAgreementDebtor(item?.debtor?.name, agreement, agreementVersion);

            return agreementDebtor ? (
              <>
                <div className="row mt-2 align-items-center mb-2" style={{ fontWeight: '600', color: '#878F9A' }}>
                  <div className="col-sm-1 p-2 text-right">Dłużnik</div>
                  <div className="col-sm-10">
                    <span className="mr-3" style={{ fontWeight: '300', color: '#4B4E55' }}>
                      {item?.debtor?.name}
                    </span>
                    <button type="button" className="ml-4" onClick={() => props.toggleForm(`debtor_accounts.${item?.debtor?.id}`)}>
                      Zarządzaj
                    </button>
                  </div>
                </div>
                <div className="row bt bb">
                  <div className="flex-30 p-2 grey br">Numer rachunku</div>
                  <div className="flex-12 p-2 grey br">Waluta rachunku</div>
                  <div className="flex-20 p-2 grey br">Alias rachunku</div>
                  <div className="flex-30 p-2 grey br">Bank</div>
                  <div className="flex-15 p-2 grey br">Data</div>
                  <div className="flex-15 p-2 grey">Dodający</div>
                </div>
                {agreementDebtor?.bank_accounts?.map(i => {
                  return (
                    <RowEditable key={i.id} onRemove={() => props.remove(`debtor/${agreementDebtor?.id}/techdebtor_bank_accounts`, i.id)}>
                      <div className="flex-30 p-2 br">{parseBan(i)}</div>
                      <div className="flex-12 p-2 br">{i.currency?.name}</div>
                      <div className="flex-20 p-2 br">{i.bank_alias?.name}</div>
                      <div className="flex-30 p-2 br">{i.bank_name}</div>
                      <div className="flex-15 p-2 br">{i.created_date}</div>
                      <div className="flex-15 p-2">{i.user_creator?.full_name}</div>
                    </RowEditable>
                  );
                })}

                <Selectable
                  multiple
                  fetch={() => {}}
                  visible={props.formVisible(`debtor_accounts.${item?.debtor?.id}`)}
                  formName={`debtor_accounts.${item?.debtor?.id}`}
                  field="debtor_accounts"
                  columns={columns}
                  title={title(item?.debtor)}
                  options={factorBankAccounts}
                  onClickAway={() => props.toggleForm(`debtor_accounts.${item?.debtor?.id}`)}
                  header="Wybierz rachunki faktora"
                  footer="Aby zapisać wciśnij ZATWIERDŹ"
                  onSelected={banks => handleAddDebtorAccounts(agreementDebtor, banks)}
                />
              </>
            ) : null;
          })
        ) : (
          <div className="row">
            <div className="col-12 p-2">Nie dodano dłużników.</div>
          </div>
        )}
      </div>
    </Card>
  );
};
