import { toast } from 'react-toastify';

import OtherService from '../services/other';
import FormActions from './forms';

const fetchIndicators = (showMessage = false) => (dispatch) => {
  OtherService.fetchIndicators()
    .then(
      (response) => {
        if (showMessage) {
          toast.success('Wskaźniki zostały odświeżone.');
        }
        dispatch({ type: 'INDICATORS_FETCHED', payload: response.data });
      }
    )
    .catch(
      () => toast.error('Pobranie wskaźników nie powiodło się.')
    );
};

export default {
  sendNotification: (formName) => (dispatch, getState) => {
    const data = getState().Forms?.forms?.[formName];
    Object.keys(data).forEach((k) => {
      if (data[k] === '') {
        delete data[k];
      }
    });
    if (data.recipient) {
      data.recipient_id = data.recipient.id;
      delete data.recipient;
    }
    OtherService.sendNotification(data).then(() => {
      toast.success('Powiadomienie zostało wysłane.');
    }).catch((err) => {
      dispatch(FormActions.handleErrors(formName, err.response.data));
      toast.error('Wysyłanie powiadomienia nie powiodło się.');
    });
  },
  fetchIndicators,
  updateIndicators: () => (dispatch) => {
    OtherService.updateIndicators()
      .then(() => dispatch(fetchIndicators(true)))
      .catch(() => toast.error('Odświeżenie wskaźników nie powiodło się.'));
  },
  fetchSettlements: (filters) => (dispatch) => {
    dispatch({ type: 'CONTENT_LOADING' });

    const params = {
      client_id: filters?.client?.id,
      agreement_id: filters?.agreement?.id,
      claim_id: filters?.claim?.id,
      limit: filters.limit,
      page: filters.page,
      settled: filters.settled
    };

    Object.keys(params).forEach((k) => {
      if (!params[k]) {
        delete params[k];
      }
    });

    OtherService.fetchSettlements(params).then((res) => {
      dispatch({ type: 'SETTLEMENTS_FETCHED', payload: res.data });
    }).catch().finally(() => {
      dispatch({ type: 'CONTENT_LOADED' });
    });
  }
};
