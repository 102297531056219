import { uniq } from 'lodash';
import { toast } from 'react-toastify';

import InvoiceService from '../services/invoice';
import { parseBan } from '../utilities/helpers';
import ClaimActions from './claim';
import CommonActions from './common';
import FormActions from './forms';
import { OptionsActions } from 'ducks/options';

const fetch = filters => dispatch => {
  dispatch(OptionsActions.toggleLoader(true));
  dispatch({ type: 'CONTENT_LOADING' });

  const f = filters ?? { ...CommonActions.defaultFilters };

  Object.keys(f).forEach(k => {
    if (f[k] === '' || f[k] === null) {
      delete f[k];
    }
  });

  InvoiceService.fetch(f).then(response => {
    dispatch({ type: 'INVOICES_FETCHED', payload: response ? response.data : [] });
    dispatch({ type: 'CONTENT_LOADED' });
    dispatch(OptionsActions.toggleLoader(false));
  });
};

const fetchDetails = (id, loader = true) => dispatch => {
  if (loader) {
    dispatch({ type: 'CONTENT_LOADING' });
    dispatch({ type: 'CONTENT_PREVIEW_LOADING' });
    dispatch(OptionsActions.toggleLoader(loader));
  }

  InvoiceService.fetchDetails(id).then(async response => {
    dispatch({ type: loader ? 'TRANSFER_DETAILS_FETCHED' : 'TRANSFER_DETAILS_UPDATED', payload: response.data });
    dispatch({ type: 'CONTENT_LOADED' });

    if (loader) {
      dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
      dispatch(OptionsActions.toggleLoader(false));
    }
  });
};

const showDetails = (id, form, mode = 'details', toggle = true) => dispatch => {
  InvoiceService.fetchDetails(id).then(async response => {
    const invoice = {
      ...response.data,
      mode,
      previous_items: response.data.previous_items.length ? response.data.previous_items : response.data.items,
      items: response.data.items?.map(i => ({ ...i, gtu: i.gtu?.id })) ?? [],
      tax_procedure_code: response.data.tax_procedure_code?.id
    };

    try {
      const linked = await InvoiceService.fetchLinked(id);
      invoice.linked = linked.data ?? { error: true };

      const settlements = await InvoiceService.fetchSettlements(id);
      invoice.settlements = settlements.data ?? { error: true };
    }
    catch (e) {
      // ignore for now
    }
    finally {
      dispatch(FormActions.initialize(form, invoice, {}));
      if (toggle) {
        dispatch(FormActions.toggleForm(form));
      }
    }

  });
};

const saveForm = (form, id, hide = false) => (dispatch, getState) => {
  dispatch(FormActions.clearErrors(form));
  const f = { ...getState().Forms.forms[form] };

  InvoiceService.saveForm(form, f, id)
    .then(() => {
      dispatch(CommonActions.toggleModal());

      if (form === 'AddItemToInvoice') {
        dispatch(showDetails(f.invoiceId, 'InvoiceForm', 'edit', false));
        toast.success('Pozycja na fakturze została dodana.');
      } else {
        dispatch(fetch({ page: 1, limit: 10, sales: 1 }));
      }

      document.getElementsByTagName('html')[0].style = '';

      const clearForm = true;

      if (clearForm) {
        dispatch(FormActions.clearForm(form));
      }

      if (hide) {
        dispatch(FormActions.toggleForm(form));
      }

      toast.success('Zaktualizowano dane.');
    }).catch((errors) => dispatch(FormActions.handleErrors(form, errors?.response?.data)));
};

export default {
  removeCompensation: (subpaymentId) => (dispatch, getState) => {
    const { id } = getState().Claim.claim ?? 0;
    InvoiceService.removeCompensation(subpaymentId).then(() => {
      toast.success('Usunięto kompensatę.');
      dispatch(ClaimActions.fetchDetails(id, false));
    }).catch(errors => {
      const e = errors && errors.response ? errors?.response?.data : null;
      if (e) {
        const toasts = uniq(Object.keys(e).map(key => (typeof e[key] === 'object' ? e[key][0] : e[key])));
        toasts.forEach(t => toast.error(t));
      } else {
        toast.error('Wystąpił błąd.');
      }
    });
  },
  removeIncome: (invoiceId, income) => dispatch => {
    const { subpayment_id, income_id } = income;

    InvoiceService.removeIncome(subpayment_id, income_id).then(() => {
      toast.success('Usunięto subprzelew.');
      dispatch(showDetails(invoiceId, 'InvoiceForm', 'details', false));
    }).catch((e) => {
      toast.error(e?.response?.data?.income ?? 'Operacja nie powiodła się.');
    });
  },
  sendCompensation: (form, cForm, what, id) => (dispatch, getState) => {
    const { Forms, Claim } = getState();
    const docForm = Forms.forms?.[form];
    const commitmentForm = Forms.forms?.[cForm];
    let data, u = '/payments/compensation/';

    const isClaim = docForm.docs.identifier[0] === 'W';

    if (cForm === 'SettleIncomeTransferForm') {
      data = isClaim ? {
        'identifier': docForm.docs.identifier,
        'subpayment_id': commitmentForm.id,
        'transfer_value': commitmentForm.to_pay_value,
        'transfer_value_currency_id': commitmentForm.amount_currency.id
      } : { invoice_id: docForm.docs.id };
      u = isClaim ? u + 'subpayment/' :  `payments/subpayment/${commitmentForm.id}/create_link/`;
    } else {
      const { to_pay_value } = Claim.claim?.commitments?.find(x => x.id === commitmentForm.commitment_id) ?? 0;
      data = {
        'identifier': docForm.docs.identifier,
        'commitment_id': commitmentForm.commitment_id,
        'transfer_value': to_pay_value,
        'transfer_value_currency_id': commitmentForm.commitment.currency.id
      };
    }

    InvoiceService.sendCompensation(u, data).then(() => {
      toast.success('Powiązano dokument.');

      if (what === 'claim') {
        dispatch(ClaimActions.fetchDetails(id, false));
      }

    }).catch((errors) => dispatch(FormActions.handleErrors(form, errors?.response?.data)));
  },
  remove: invoiceId => dispatch => {
    InvoiceService.remove(invoiceId)
      .then(() => dispatch(fetch({ ...CommonActions.defaultFilters, sales: 1 })))
      .catch(e => e);
  },
  removeItem: (invoiceId, itemId) => dispatch => {
    InvoiceService.removeItem(invoiceId, itemId)
      .then(() => {
        dispatch(showDetails(invoiceId, 'InvoiceForm', 'edit', false));
        toast.success('Pozycja na fakturze została usunięta.');
      })
      .catch(() => {
        toast.error('Nie udało się usunąć pozycji na fakturze.');
      });
  },
  print: (id, form) => dispatch => {
    InvoiceService.print(id)
      .then(r => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(r.data);
        // link.href = window.URL.createObjectURL(new Blob([r.data]));
        link.download = `Smart_Faktor_Faktura_${id}.pdf`;
        link.click();

        if (form) {
          dispatch(FormActions.toggleForm(form));
        }
      })
      .catch(e => {
        const msg = e?.response?.data?.invoice;
        toast.error(msg ?? 'Drukowanie nie powiodło się.');
      });
  },
  acceptInvoices: (ids, filters, form, status, callbackOnSuccess) => dispatch => {
    InvoiceService.changeStatus(ids, status)
      .then(() => {
        dispatch(fetch(filters));
        dispatch(FormActions.toggleForm(form));
        callbackOnSuccess();
        toast.success('Status został zmieniony.');
      })
      .catch(e => {
        let msg = 'Status nie został zmieniony.';

        if (e?.response?.data?.invoice_ids) {
          if (Array.isArray(e?.response?.data?.invoice_ids)) {
            msg = e?.response?.data?.invoice_ids?.[0];
          } else {
            msg = e?.response?.data?.invoice_ids;
          }
        }

        if (e?.response?.data?.status) {
          if (Array.isArray(e?.response?.data?.status)) {
            msg = e?.response?.data?.status?.[0];
          } else {
            msg = e?.response?.data?.status;
          }
        }

        toast.error(msg);
        dispatch(FormActions.toggleForm(form));
      });
  },
  showDetails,
  fetch,
  fetchDetails,
  saveForm,
  fetchAgreements: f => (dispatch, getState) => {
    const filters = getState().Forms?.forms?.[f] ?? {};
    InvoiceService.fetchAgreements(filters)
      .then(r => {
        dispatch({ type: 'INVOICE_AGREEMENTS_FETCHED', payload: r.data });
      })
      .catch(() => {});
  },
  fetchDocs: y => (dispatch, getState) => {
    const f = { ...getState().Forms?.forms?.[y] } ?? {};
    f.client_id = f.client_id?.id ?? null;

    Object.keys(f).forEach(k => {
      if (f[k] === '' || f[k] === null) {
        delete f[k];
      }
    });

    InvoiceService.fetchDocs(f)
      .then(r => {
        dispatch({ type: 'INVOICE_DOCS_FETCHED', payload: r.data });
      })
      .catch(() => {});
  },
  fetchFactors: f => (dispatch, getState) => {
    const filters = getState().Forms?.forms?.[f] ?? {};
    InvoiceService.fetchFactors(filters)
      .then(r => {
        dispatch({ type: 'INVOICE_FACTORS_FETCHED', payload: r.data });
      })
      .catch(() => {});
  },
  fetchClients: f => (dispatch, getState) => {
    const filters = getState().Forms?.forms?.[f] ?? {};
    InvoiceService.fetchClients(filters)
      .then(r => {
        dispatch({ type: 'INVOICE_CLIENTS_FETCHED', payload: r.data });
      })
      .catch(() => {});
  },
  fetchAccounts: (formName) => (dispatch, getState) => {
    const f = getState().Forms.forms[formName] ?? {};
    const u = f.agreement?.id ? `/agreements/${f.agreement?.id}` : '/companies/faktor_bank_accounts/';

    InvoiceService.fetchAccounts(u)
      .then(r => {
        let payload = [];

        if (f.agreement?.id) {
          const version = r.data.agreement_versions.find(x => x.status === 1); // status wersji umowy = 1 = zatwierdzony
          payload = version ? [
            ...version.factor_bank_accounts[0],
            ...version.factor_bank_accounts[1]
          ] : payload;
        } else {
          payload = r.data.map(a => ({ ...a, number: parseBan(a) }));
        }

        dispatch({ type: 'INVOICE_ACCOUNTS_FETCHED', payload });
      })
      .catch(() => {});
  }
};
