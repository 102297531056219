import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useState } from 'react';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import { PeopleForm } from '../../Forms/People';
import RemovalModal from '../../Forms/RemovalModal';

export const PEOPLE_FORM = 'PeopleForm';

export const People = (props) => {
  const people = props.company && props.company.persons ? props.company.persons : [];

  const formName = PEOPLE_FORM;

  const [objectToRemove, setObjectToRemove] = useState({});

  return (
    <Card header='Osoby powiązane' onAddClick={() => props.create(formName)}>
      <div>
        <div className="row bb">
          <div className="col-sm-3 grey p-2 br">Imię i nazwisko</div>
          <div className="col-sm-3 grey p-2 br">Rola biznesowa</div>
          <div className="col-sm-3 grey p-2 br">Powiązane od</div>
          <div className="col-sm-3 grey p-2">Powiązał</div>
        </div>
        {people.length === 0 && (
          <div className="row">
            <div className="col-sm-12 p-2">Brak danych.</div>
          </div>
        )}
        {people.map((p) => (
          <RowEditable
            isObjectToRemove
            itemId={p.id}
            onRemove={() => {
              setObjectToRemove(p);
            }}
          >
            <div className="col-sm-3 p-2 br">{p.person ? <a role="button" href={`/osoby/${p.person.id}/`}>{p.person.full_name}</a> : 'brak'}</div>
            <div className="col-sm-3 p-2 br">{p.roles.map((r) => r.name).join(', ')}</div>
            <div className="col-sm-3 p-2 br">{p.created_date}</div>
            <div className="col-sm-3 p-2">{p.user_creator ? p.user_creator.full_name : 'brak'}</div>
          </RowEditable>
        ))}
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Osoby powiązane: dodawanie</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <PeopleForm
          visibleOnMount={false}
          formVisible={(f) => props.formVisible(f)}
          visible={props.formVisible(formName)}
          formName={formName}
          withButtons
          submitForm={() => props.addPerson(formName)}
          onCancelButtonClick={() => props.toggleForm(formName)}
          voivodeships={props.voivodeships}
          people={props.people}
          fetchPeople={props.fetchPeople}
          filtersForm={props.filtersForm}
          company={props.company}
          selectPerson={props.selectPerson}
          roles={props.roles}
          toggleForm={props.toggleForm}
          selectedPerson={props.forms[formName] ? props.forms[formName].person : null}
        />
      </BaseModal>

      <RemovalModal
        visible={Object.keys(objectToRemove).length > 0}
        toggle={() => setObjectToRemove({})}
        remove={() => {
          props.detachPerson(objectToRemove.id);
          setObjectToRemove({});
        }}
      >
        {objectToRemove && (
          <div className="container-fluid">
            <div className="row mt-1 bt bb">
              <div className="col-sm-6 grey p-2 br">Imię i nazwisko</div>
              <div className="col-sm-6 grey p-2 br">Rola biznesowa</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br">{objectToRemove.person ? objectToRemove.person.full_name : 'brak'}</div>
              <div className="col-sm-6 p-2 br">
                {objectToRemove && objectToRemove.roles ? objectToRemove.roles.map((i) => i.name).join(', ') : '-'}
              </div>
            </div>
          </div>
        )}
      </RemovalModal>
    </Card>
  );
};
