import 'twin.macro';

import AgreementActions from 'actions/agreement';
import Tooltip from 'components/Layout/Tooltip';
import { AGREEMENT_TYPES } from 'containers/Agreements/enumsAgreement';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import BooleanCheck from '../../../components/BooleanCheck';
import { Datepicker, File, Form, Select, Selectable, Textarea } from '../../../components/Forms';
import { parseMoney, formatNip } from '../../../utilities/helpers';

const columns = {
  guarantor: [
    { name: 'Imię i nazwisko', getter: 'name', sortable: 1 },
    {
      name: 'Rola biznesowa',
      getter: 'nip',
      sortable: 1,
      mutator: formatNip
    },
    { name: 'E-mail', getter: 'active_email', sortable: 1 }
  ],
  clauses: [{ name: 'Nazwa klauzuli', getter: 'name', sortable: 1 }, { name: 'Opis', getter: 'description', sortable: 1 }]
};

const getActorAddress = actor => actor?.addresses?.find(x => x.is_active) ?? {};

const getActorInfo = (actor, representation) => {
  return (
    <div className="col-sm-4">
      <div className="row">
        <div className="col-sm-6"><Tooltip label={actor?.identifier} tip={actor?.name} /></div>
        <div className="col-sm-6">{formatNip(actor?.nip)} (N)</div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          {getActorAddress(actor).zipcode} {getActorAddress(actor).city}
        </div>
        <div className="col-sm-6">{actor.regon || '-'} (R)</div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          {getActorAddress(actor).street} {getActorAddress(actor).building_no}
          {getActorAddress(actor).local_no ? `/${getActorAddress(actor).local_no}` : ''}
        </div>
        <div className="col-sm-6">{actor?.ceidg || actor?.krs || '-'} (K/C)</div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-12">
          <span
            style={{
              padding: '0.5rem',
              borderRadius: '8px',
              width: '150px',
              height: '28px',
              background: `${representation.representation_type.background_color} 0% 0% no-repeat padding-box`,
              opacity: 1
            }}>
            {representation.representation_type.name}
          </span>
        </div>
      </div>
      {representation.people.map((x, y) => (
        <div className={`row mt-${y === 0 ? '3' : '1'}`}>
          <div className="col-sm-6 ">{x.person_in_company.person.full_name}</div>
          <div className="col-sm-6">{x.person_in_company.roles.map(r => r.name).join(', ')}</div>
        </div>
      ))}
    </div>
  );
};

export default props => {
  const dispatch = useDispatch();

  const f = props.forms?.[props.formName] ?? {};
  const clauses = props.common.proposalData.factor_secure_clauses_data ?? [];

  const isAgreementRegress = f?.document_name?.identifier === AGREEMENT_TYPES.UMOWA_LIMITU_Z_REGRESEM_Z1_Z3;
  const isAgreementDetails = f?.document_name?.identifier === AGREEMENT_TYPES.SZCZEGOLOWE_WARUNKI_UMOWY_Z4 || f?.document_name?.identifier === AGREEMENT_TYPES.TABELA_OPLAT_I_PROWIZJI_Z2;

  const handleSave = () => {
    dispatch(AgreementActions.saveDocuments({ formName: props.formName, id: props.agreement.id }));
  };

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      <div className="row mt-1 align-items-center">
        <div className="col-sm-2 grey text-right" style={{ fontWeight: 600 }}>
          Nazwa dokumentu
        </div>
        <div className="col-sm-4" style={{ fontWeight: 600 }}>
          {f.document_name?.name}
        </div>
        <div className="col-sm-2 grey text-right" style={{ fontWeight: 600 }}>
          Nr dokumentu / Status
        </div>
        <div className="col-sm-4">{`${f.identifier} / ${props.common.agreementData.document_agreement_statuses[f.status]}`}</div>
      </div>
      <div className="row align-items-center">
        <div className="col-sm-2 grey  text-right" style={{ fontWeight: 600, marginTop: '-10px' }}>
          Produkt / Procedura
        </div>
        <div className="col-sm-3 grey" style={{ marginTop: '-10px' }}>{`${f.product?.name} / ${f.procedure?.name}`}</div>

        <div className="col-sm-3 grey  text-right" style={{ fontWeight: 600 }}>
          Okres obowiązywania
        </div>
        <div className="col-sm-4 d-flex align-items-center">
          {(!isAgreementRegress && !isAgreementDetails) && (
            <BooleanCheck asRadioButton field={!!f.indefinite_period_of_validity} onClick={() => {
              props.storeInput(props.formName, 'indefinite_period_of_validity', true);
              // props.storeInput(props.formName, 'period_of_validity_from', null);
              // props.storeInput(props.formName, 'period_of_validity_to', null);
            }} />
          )}
          {(!isAgreementRegress && !isAgreementDetails) && <span className="ml-1 mr-1">bezterm.</span>}
          {(!isAgreementRegress && !isAgreementDetails) && <BooleanCheck asRadioButton field={!f.indefinite_period_of_validity} onClick={() => props.storeInput(props.formName, 'indefinite_period_of_validity', false)} />}
          <span className="ml-1 mr-1">od</span>
          <Datepicker style={{ paddingTop: '7px' }} disabled={f.indefinite_period_of_validity} formName={props.formName} name="period_of_validity_from" />
          <span className="ml-1 mr-1">do</span>
          <Datepicker style={{ paddingTop: '7px' }} disabled={f.indefinite_period_of_validity} formName={props.formName} name="period_of_validity_to" />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-sm-2 grey text-right" style={{ fontWeight: 600, marginTop: '-50px' }}>
          Kwota limitu
        </div>
        <div className="col-sm-3 grey" style={{ marginTop: '-50px' }}>{`${parseMoney(props.agreement?.limit_amount)} ${props.agreement?.currency?.name}`}</div>
        <div className="col-sm-3 grey mb-07 text-right" style={{ fontWeight: 600 }}>
          Forma dokumentu
          {/*{f.expected_dtb_form ? '/ oczekiwana' : ''}*/}
        </div>
        <div className="col-sm-4 grey d-flex align-items-center">
          <Select
            number
            disabled={props.selectDisabled || false}
            formName={props.formName}
            options={props.common?.offerData?.dtb_forms?.map(i => ({ ...i, value: i.id })) ?? []}
            name="dts_form_id"
          />
        </div>
        <div className="col-sm-2 grey d-flex align-items-center">
          {f.expected_dtb_form ? <>/ {f.expected_dtb_form?.name || '-'}</> : ''}
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-sm-2 grey text-right" style={{ fontWeight: 600, marginTop: '-80px' }}>
          Nr umowy
        </div>
        <div className="col-sm-3 grey" style={{ marginTop: '-80px' }}><Tooltip label={props.agreement?.identifier} /></div>
        <div className="col-sm-3 grey mb-07 text-right" style={{ fontWeight: 600 }}>
          Wzór faktora
        </div>
        <div className="col-sm-2 grey">
          <Select
            tw='w-60'
            placeholder=''
            disabled={props.selectDisabled || false}
            formName={props.formName}
            options={[{ value: 1, name: 'tak' }, { value: 0, name: 'nie' }]}
            name="is_factor_pattern"
            number
          />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-sm-2 grey  text-right" style={{ fontWeight: 600, marginTop: '-60px' }}>
          Nr szablonu
        </div>
        <div className="col-sm-3 grey" style={{ marginTop: '-60px' }}><Tooltip label='1' tip='Brak implementacji.' /></div>
      </div>

      <div className="row mb-10">
        <div className="col-sm-12">
          <span className="grey">Szczególne parametry dotyczące dokumentu</span>
          <hr />
        </div>
      </div>

      <div className="row align-items-center justify-center mb-2">
        <div className="col-sm-4 grey">Klient</div>
        <div className="col-sm-4 grey">Faktor</div>
        {props.agreement?.operational_administrator ? <div className="col-sm-4 grey">Administrator operacyjny</div> : null}
      </div>
      <div className="row mb-3 justify-center">
        {getActorInfo(props.agreement?.client, props.version?.client_representation)}
        {getActorInfo(props.agreement?.factor, props.version?.factor_representation)}
        {props.agreement?.operational_administrator
          ? getActorInfo(props.agreement?.operational_administrator, props.version?.oa_representation)
          : null}
      </div>

      <hr />

      <div className="row mt-3">
        <div className="col-sm-5">
          <Textarea formName={props.formName} name="comment" placeholder="Komentarz..." />
        </div>
        <div className="col-sm-7">
          <div className="row align-items-center">
            <div className="col-sm-2 grey text-right pb-10" style={{ fontWeight: 600 }}>
              Data zawarcia
            </div>
            <div className="col-sm-2">
              <Datepicker disabled={isAgreementDetails} formName={props.formName} name="conclused_date" />
            </div>

            <div className="col-sm-1 grey text-right pb-15" style={{ fontWeight: 600 }}>
              Klauzule
            </div>
            <div className="col-sm-7 d-flex align-items-center pb-15">
              {f.clauses?.map(c => c.name).join(', ')}

              <button type="button" className="ml-4 mr-auto" onClick={() => props.toggleForm('clauses')}>
                ...
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row bb bt">
        <div className="flex-15 grey p-2 br ">Dokument</div>
        <div className="flex-4 grey p-2 br">Wygenerowano</div>
        <div className="flex-4 grey p-2 br">Zarejestrowano</div>
        <div className="flex-7 grey p-2 br">Nr szablonu / dokumentu</div>
        <div className="flex-8 grey p-2 br">Zarejestrował</div>
        <div className="flex-4 grey p-2">Data rejestracji</div>
      </div>
      {f.attachments?.map(a => (
        <div className="row bb">
          <div className="flex-15 p-2 br ">{a.name === '' ? f.document_name?.name : a.name}</div>
          <div className="flex-4 p-2 br">
            {a.generated_file ? (
              <>
                <button
                  type="button"
                  className="mr-2"
                  onClick={() => {
                    window.open(process.env.REACT_APP_BACKEND_LINK + a.generated_file, '_blank');
                  }}>
                  Pobierz
                </button>
                <button type="button" onClick={() => props.generate(props.formName, 'generated_documents', a.id, 'generate', true)}>
                  Usuń
                </button>
              </>
            ) : a.template && f.is_factor_pattern ? (
              <button type="button" onClick={() => {
                props.generate(props.formName, 'generated_documents', a.id, 'generate');
              }}>
                Generuj
              </button>
            ) : null}
          </div>
          <div className="flex-4 p-2 br">
            {a.registered_file ? (
              <>
                <button
                  type="button"
                  className="mr-2"
                  onClick={() => {
                    window.open(process.env.REACT_APP_BACKEND_LINK + a.registered_file, '_blank');
                  }}>
                  Pobierz
                </button>{' '}
                <button
                  type="button"
                  onClick={() =>
                    a.template
                      ? props.generate(props.formName, 'generated_documents', a.id, 'register', true)
                      : props.removeAttachment(`generated_documents/${f.id}/attachments`, a.id)
                  }>
                  Usuń
                </button>
              </>
            ) : a.generated_file || !f.is_factor_pattern ? (
              <File
                text="Rejestruj"
                url={`agreements/${props.agreement?.id}/agreement_versions/${props.version?.id}/generated_documents/${f.id}/attachments/${
                  a.id
                }/register/`}
                objectId={a.id}
                field="attachments"
                formName={props.formName}
                noToast
                onSuccess={() => {
                  toast.success('Dokument zarejestrowany.');
                }}
                method="PATCH"
              />
            ) : null}
          </div>
          <div className="flex-7 p-2 br">{a.identifier}</div>
          <div className="flex-8 p-2 br">{a.author?.full_name}</div>
          <div className="flex-4 p-2">{a.registered_date}</div>
        </div>
      ))}
      <div className='mt-05'>
        <File
          url={`agreements/${props.agreement?.id}/agreement_versions/${props.version?.id}/generated_documents/${f.id}/attachments/`}
          field="attachments"
          fileName="registered_file"
          formName={props.formName}
        />
      </div>

      {props.withButtons ? (
        <div className="row">
          <div className="col-sm-12 text-center">
            <button type="button" onClick={handleSave} className="btn btn-primary font-weight-light mr-4">
              Zapisz
            </button>
            <button
              type="button"
              onClick={props.onClose}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}>
              Zamknij
            </button>
          </div>
        </div>
      ) : null}

      <Selectable
        multiple
        fetch={() => {}}
        // fetch={props.fetch}
        visible={props.formVisible('clauses')}
        formName={props.formName}
        field="clauses"
        columns={columns.clauses}
        options={clauses}
        onClickAway={() => props.toggleForm('clauses')}
        header="Wybierz klauzule"
        footer="Aby zapisać wciśnij ZATWIERDŹ"
      />
    </Form>
  );
};
