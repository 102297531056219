import Card from 'components/Layout/Card';
import React, { useEffect } from 'react';

import CreateClaimForm from '../../Forms/Create';

export default props => {
  const c = props.claim || {};
  const f = props.forms?.forms?.[props.formName] ?? {};

  useEffect(() => {
    if (props.populateForm) {
      props.populateForm(props.formName, {
        ...c,
        gross_value_currency_id: props.claim?.gross_value_currency?.id,
        vat_value_currency_id: props.claim?.vat_value_currency?.id,
        net_value_currency_id: props.claim?.net_value_currency?.id,
        purchase_currency_id: props.claim?.purchase_currency?.id,
        purchase_custom_value_currency_id: props.claim?.purchase_custom_value_currency?.id,
        purchase_custom_vat_value_currency_id: props.claim?.purchase_custom_vat_value_currency?.id
      });
    }
    // eslint-disable-next-line
  }, [props.claim]);

  return (
    <Card mode='creator' header='+ Parametry podstawowe' onSaveClick={() => props.saveForm(props.formName)} options={{ isPosted: true }}>
      <div>
        <div className="row">
          <div className="col-sm-12">
            <CreateClaimForm
              isCreator
              errors={props.forms?.errors?.[props.formName]}
              form={f}
              forms={props.forms}
              clear={props.clear}
              visibleOnMount={false}
              visible
              toggleForm={props.toggleForm}
              isVisible={() => false}
              formName={props.formName}
              submitForm={props.saveForm}
              onCancel={props.onCancel}
              currencies={props.common?.currencies}
              fetch={props.props}
              formData={props.claim?.formData}
              storeInput={props.storeInput}
              representations={props.common?.companyData?.representations}
              onSubmitAndLeave={() => {}}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};
