import CompanyActions from 'actions/companies';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import { Attachments } from "./Components/Attachments";
// import { SectionNavigation } from "./Components/SectionNavigation";
import CommonActions from '../../../actions/common';
import FormActions from '../../../actions/forms';
import PersonActions from '../../../actions/person';
// import _ from "lodash";
import { Buttons } from '../../../components/Forms';
import { Container, MainContainer } from '../../../components/Layout';
import Loader from '../../../components/Loader';
import { getBankAccountData } from '../../../services/company';
import Constants from '../../../utilities/constants';
import { Accounts } from './Components/Accounts';
import { Addresses } from './Components/Addresses';
import { Commissions } from './Components/Commissions';
import { Contacts } from './Components/Contacts';
import { Header } from './Components/Header';
import { Metrics } from './Components/Metrics';
// import { Consents } from "./Components/Consents";
import { Notes } from './Components/Notes';
import { People } from './Components/People';
import { Roles } from './Components/Roles';
import { Scoring } from './Components/Scoring';
import { SocialMedia } from './Components/SocialMedia';
import { optionsCountries } from 'utilities/helpers';

class Index extends React.Component {
  constructor(props) {
    super(props);

    const { slug } = this.props.match.params;
    const id = slug ? slug.split('-').slice(-1)[0] : null;

    this.props.dispatch(CompanyActions.fetchCompanyDetails(id));
    this.props.dispatch(CommonActions.changeView(Constants.Views.Creator));
    this.peopleFiltersForm = 'PeopleFilters';
  }

  componentWillUnmount() {
    this.props.dispatch(FormActions.clearAllForms());
  }

    save = (formName) => this.props.dispatch(CompanyActions.saveForm(formName, this.props.company.id, true));

    create = (name, object = {}) => {
      this.props.dispatch(FormActions.initialize(name, object, {}));
    };

    edit = (object, name) => {
      this.props.dispatch(FormActions.initialize(name, object, {}));
      this.props.dispatch(FormActions.toggleForm(name));
    };

    remove = (modal) => this.props.dispatch(CommonActions.toggleModal(modal));

    populateForm = (f) => {
      const object = { ...this.props.company };

      this.props.dispatch(FormActions.initialize(f, object, {}));
    };

    getPeopleFilters = () => {
      const currentFilters = this.props.forms.forms[this.peopleFiltersForm] || {};

      if (!currentFilters || Object.keys(currentFilters).length <= Object.keys(CommonActions.defaultFilters).length) {
        this.props.dispatch(FormActions.initialize(this.peopleFiltersForm, CommonActions.defaultFilters, {}));
      }

      return this.props.forms.forms[this.peopleFiltersForm];
    };

    fetchPeople = () => {
      this.props.dispatch(PersonActions.fetch(this.getPeopleFilters(), true));
    };

  getBankAccountInfo = (val, form) => {
    const ban = val.replaceAll(' ', '');
    if (ban.length === 26 || ban.length === 28) {
      getBankAccountData(ban).then((r) => {
        this.props.dispatch(FormActions.storeInput(form, 'bank_name', r.data.short_name));
      }).catch((e) => e);
    } else {
      this.props.dispatch(FormActions.clearField(form, 'bank_name'));
    }
  };

  render() {
    const isLoadingState = !this.props.company?.id;

    if(isLoadingState) {
      return <Loader />;
    }

    const { common } = this.props;

    const currencies = common.currencies ? common.currencies.map((i) => ({ value: i.id, name: i.name })) : [];
    const bankAccountTypes = common.bankAccounts && common.bankAccounts.bank_account_types
      ? common.bankAccounts.bank_account_types.map((i) => ({ value: i.id, name: i.name }))
      : [];
    const bankAccountAliases = common.bankAccounts && common.bankAccounts.bank_aliases ? common.bankAccounts.bank_aliases.map((i) => ({ value: i.id, name: i.name })) : [];

    const addressTypes = common?.commonData?.company_address_types ?
      Object.entries(common?.commonData?.company_address_types)?.map(c => ({ value: String(c[0]), name: c[1] })) : [];
    const voivodeships = common?.commonData?.voivodeships ?
      Object.entries(common?.commonData?.voivodeships)?.map(c => ({ value: String(c[0]), name: c[1] })) : [];
    const contactTypes = this.props.common.contactTypes
      ? Object.entries(this.props.common.contactTypes).map((c) => ({ value: String(c[0]), name: c[1] })) : [];

    const companyRoles = common.companyData && common.companyData.company_roles ? common.companyData.company_roles.map((i) => ({ ...i, value: i.id })) : [];

    const companyForm = {
      ...this.props.company,
      legal_form_id: this.props.company.legal_form ? this.props.company.legal_form.id : null,
      industry_id: this.props.company.industry ? this.props.company.industry.id : null,
      representation_id: this.props.company.representation ? this.props.company.representation.id : null
      // main_pkd_id: this.props.companies.main_pkd ? this.props.companies.main_pkd.id : null
    };

    if (this.props.company && this.props.company.main_pkd && this.props.company.main_pkd.id) {
      companyForm.main_pkd_id = this.props.company.main_pkd.id;
    }

    const commission_types = common.commissions ? common.commissions.map((i) => ({ ...i, value: i.id })) : [];
    const commissionCategories = this.props.company.business_company
      ? this.props.company.business_company.filter((i) => i.is_used_for_commissions).map((i) => ({ value: i.id, name: i.name }))
      : [];

    const roles = common.businessRoles ? common.businessRoles.map((i) => ({ ...i, value: i.id })) : [];
    const countryCallingCodes = optionsCountries(this.props.common.countryCallingCodes);

    return (
      <Container className="mt-3">
        <MainContainer className='w-full max-w-1400 mx-auto'>
          <Header company={this.props.company} />

          <Metrics
            companyData={this.props.common.companyData}
            populateForm={(f) => this.props.dispatch(FormActions.initialize(f, companyForm, {}))}
            // populateForm={f => this.populateForm(f)}
            company={this.props.company}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            submitForm={(formName) => {
              this.props.dispatch(CompanyActions.saveForm(formName, this.props.company.id, false));
            }}
            storeInput={(n, f, v) => this.props.dispatch(FormActions.storeInput(n, f, v))}
          />

          <Roles
            company={this.props.company}
            companyRoles={companyRoles}
            forms={this.props.forms.forms}
            toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            create={(form) => this.create(form)}
            submitForm={(f, h) => this.props.dispatch(CompanyActions.updateBusinessRoles(f, h))}
            onCancel={(f) => this.props.dispatch(FormActions.toggleForm(f))}
            remove={(roleId) => this.props.dispatch(CompanyActions.removeBusinessRole(roleId, this.props.company))}
          />

          <People
            roles={roles}
            forms={this.props.forms.forms}
            toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            create={(f) => this.edit(f, {})}
            company={this.props.company}
            people={this.props.people}
            addPerson={(formName) => this.props.dispatch(CompanyActions.addPerson(formName))}
            detachPerson={(personId) => this.props.dispatch(CompanyActions.detachPerson(personId))}
            selectPerson={(formName, person) => this.props.dispatch(FormActions.storeInput(formName, 'person', person))}
            fetchPeople={this.fetchPeople}
            filtersForm={this.peopleFiltersForm}
          />

          <Addresses
            forms={this.props.forms.forms}
            company={this.props.company}
            create={(form) => this.create(form, { is_active: true })}
            edit={(obj, modal) => this.edit({ ...obj, type: obj.address_type }, modal)}
            remove={(objectId) => this.props.dispatch(CompanyActions.removeObject(objectId, this.props.company.id, 'addresses'))}
            toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
            addressTypes={addressTypes}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            submitForm={(formName, hide) => {
              this.props.dispatch(CompanyActions.saveForm(formName, this.props.company.id, hide));
            }}
            voivodeships={voivodeships}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            populate={(form, address) => {
              const original = this.props.forms.forms[form] || {};
              this.props.dispatch(FormActions.initialize(form, Object.assign(original, address), {}));
            }}
          />

          <Contacts
            countryCallingCodes={countryCallingCodes}
            contactTypes={contactTypes}
            forms={this.props.forms.forms}
            submitForm={(formName, hide) => {
              this.props.dispatch(CompanyActions.saveForm(formName, this.props.company.id, hide));
            }}
            edit={(contact, modal) => this.edit(contact, modal)}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            company={this.props.company}
            remove={(objectId) => this.props.dispatch(CompanyActions.removeObject(objectId, this.props.company.id, 'contacts'))}
          />

          <SocialMedia
            contactTypes={contactTypes}
            forms={this.props.forms.forms}
            submitForm={(formName, hide) => {
              this.props.dispatch(CompanyActions.saveForm(formName, this.props.company.id, hide));
            }}
            edit={(contact, modal) => this.edit(contact, modal)}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            company={this.props.company}
            remove={(objectId) => this.props.dispatch(CompanyActions.removeObject(objectId, this.props.company.id, 'contacts'))}
          />

          <Accounts
            create={(form) => this.create(form)}
            edit={(obj, modal) => this.edit({ ...obj, type: obj.address_type }, modal)}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            company={this.props.company}
            forms={this.props.forms.forms}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            saveForm={(f) => this.save(f)}
            submitForm={(formName, hide) => {
              this.props.dispatch(CompanyActions.saveForm(formName, this.props.company.id, hide));
            }}
            onCancel={(f) => this.toggleForm(f)}
            remove={(objectId) => this.props.dispatch(CompanyActions.removeObject(objectId, this.props.company.id, 'bank_accounts'))}
            currencies={currencies}
            toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
            bankAccountTypes={bankAccountTypes}
            bankAccountAliases={bankAccountAliases}
            banks={this.props.common.banks?.map((b) => ({ ...b, value: b.name })) ?? []}
            checkBankAccount={this.getBankAccountInfo}
          />

          <Scoring
            forms={this.props.forms.forms}
            submitForm={(formName, hide) => {
              this.props.dispatch(CompanyActions.saveForm(formName, this.props.company.id, hide));
            }}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            company={companyForm}
            companyData={this.props.common.companyData}
          />

          <Notes
            notes={this.props.company?.notes ?? []}
            forms={this.props.forms.forms}
            submitForm={(formName, hide) => {
              this.props.dispatch(CompanyActions.saveForm(formName, this.props.company.id, hide));
            }}
            edit={(contact, modal) => this.edit(contact, modal)}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            remove={(objectId) => this.props.dispatch(CompanyActions.removeObject(objectId, this.props.company.id, 'notes'))}
          />

          <Commissions
            company={this.props.company}
            forms={this.props.forms.forms}
            toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            remove={(objectId) => {
              this.props.dispatch(CompanyActions.removeObject(objectId, this.props.company.id, 'commissions'));
            }}
            create={(f) => this.showForm(f, {})}
            submitForm={(formName, hide) => {
              this.props.dispatch(CompanyActions.saveCommission(formName, this.props.company.id, hide));
            }}
            onCancel={(f) => this.props.dispatch(FormActions.toggleForm(f))}
            edit={(f, o) => this.edit(o, f)}
            currencies={currencies}
            commission_types={commission_types}
            categories={commissionCategories}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
          />

          <Buttons
            className="mb-3"
            onCancel={() => { (window.location.href = '/firmy/'); }}
            submitButton="Dalej"
            cancelButton="Lista firm"
            onSubmit={() => { (window.location.href = `/firmy/${this.props.company.slug}`); }}
          />
        </MainContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  forms: state.Forms,
  company: state.Company.company,
  common: state.Common,
  companies: state.Company,
  people: state.Person
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Index)
);
