import React from 'react';

import DebtorsRisk from '../Components/DebtorsRisk';

export default props => (
  <>
    <DebtorsRisk
      populateForm={props.populateForm}
      clear={props.clear}
      proposal={props.proposal}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
      storeInput={props.storeInput}
      fetch={props.fetch}
      formData={props.formData}
      addField={props.addField}
      removeField={props.removeField}
      offerData={props.offerData}
      proposalData={props.proposalData}
      common={props.common}
      getFlatRate={props.getFlatRate}
      getMultilevelFlatRate={props.getMultilevelFlatRate}
      getIntrestRates={props.getIntrestRates}
      intrestRates={props.intrestRates}
      setLastAvailableDateOfRate={props.setLastAvailableDateOfRate}
    />
  </>
);
