import React from 'react';

import Annexes from '../Components/Annexes';
import Complaints from '../Components/Complaints';

export default props => (<>
  <Complaints
    common={props.common}
    agreement={props.agreement}
    version={props.version}
    forms={props.forms.forms}
    errors={props.forms.errors}
    toggleForm={props.toggleForm}
    formVisible={props.formVisible}
    create={props.edit}
    saveForm={props.saveForm}
    edit={props.edit}
    onCancel={f => props.toggleForm(f)}
    remove={n => props.remove('complaints', n.id)}
    removeNote={(c, n) => props.remove(`complaints/${c.id}/notes`, n.id)}
    storeInput={props.storeInput}
    fetchClaims={props.fetchClaims}
    claims={props.claims}
  />

  <Annexes
    user={props.user}
    common={props.common}
    agreement={props.agreement}
    version={props.version}
    forms={props.forms.forms}
    errors={props.forms.errors}
    toggleForm={props.toggleForm}
    formVisible={props.formVisible}
    create={props.edit}
    saveForm={props.saveForm}
    edit={props.edit}
    onCancel={f => props.toggleForm(f)}
    remove={n => props.remove('annexes', n.id)}
    removeNote={(c, n) => props.remove(`annexes/${c.id}/notes`, n.id)}
    storeInput={props.storeInput}
    fetchClaims={props.fetchClaims}
    claims={props.claims}
    launch={props.launch}
  />
</>
);
