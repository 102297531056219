import 'twin.macro';

import FormActions from 'actions/forms';
import { ColName } from 'components/Forms/Col';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import {
  Checkbox, Datepicker,
  Form, Input, Select, Textarea } from '../../../components/Forms';

export default ({ qualitiesErrors, ...props }) => {
  const form = props.forms[props.formName];
  const [isStupidProject, setStupidProject] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setStupidProject(true);
    dispatch(FormActions.storeInput(props.formName, 'only_required', true));
  }, [qualitiesErrors]);

  const allCategories = props.categories ? props.categories
    .map((c) => ({ ...c, definitions: c.definitions.filter((d) => d.is_aml === (props.aml || false)).map((d) => ({ ...d, category_id: c.id, category_name: c.name })) }))
    .map((c) => c.definitions)
    .filter((i) => i.length)
    .flat() : [];

  const handleQualities = () => {
    const [criteriaId, definitionId] = form?.criteria_and_definition_id.split('_');
    const category = { ...props.categories.find((y) => y.id === Number(criteriaId)) };

    category.definitions = category.definitions.filter((z) => z.id === Number(definitionId)).map((d) => ({ ...d, category_id: category.id }));
    props.storeInput(props.formName, 'qualities', [category]);
  };

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          {props.creator ? null : (
            form.id ? null : (
              <div className="pb-3">
                <div className="row align-items-center">
                  <label htmlFor="example-text-input " className="col-sm-2 col-form-label label-right">
                    Tylko wymagane
                  </label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <Checkbox label=" " formName={props.formName} name="only_required" />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="example-text-input " className="col-sm-2 col-form-label label-right">
                    Kryterium
                  </label>
                  <div className="col-sm-6">
                    <Select
                      tw='max-w-1000'
                      formName={props.formName}
                      options={
                        allCategories
                          .filter((y) => (form.only_required ? y.is_required : true))
                          .map((y) => ({ value: `${y.category_id}_${y.id}`, name: `${y.category_name} / ${y.name}` }))
                      }
                      name="criteria_and_definition_id"
                      onChange={handleQualities}
                    />
                  </div>
                </div>
              </div>
            )
          )}

          {form?.qualities?.map((quality, k) => (
            <>
              {props.creator ? (
                <div className={`row bb pb-3 ${k > 0 ? 'mt-3' : ''} mb-3  mt-3`}>
                  <div className="col-sm-12">
                    <span className="col-sm-6 col-form-label label-right">
                      {quality.name}
                    </span>
                  </div>
                </div>
              ) : null}
              {quality.definitions ? quality.definitions.map((item, j) => {
                const errorField = qualitiesErrors?.find(quality => quality?.idError === item?.id);
                const selectError = errorField?.data?.field_option_value;
                const dateError = errorField?.data?.field_date_value;

                return (
                  <>
                    <div className="row mt-2 align-items-center mb-2">
                      <div className="flex-7">
                        <span className="grey">
                        Kryterium
                        </span>
                        <span className="mx-05 grey">
                          {item.version_number}
                        </span>
                        <span className="ml-3">
                          {item.name}
                          <ReactTooltip place="right" key={item.name} />
                          <i className="fa fa-eye ml-4" data-tip={item.instruction} />
                        </span>
                      </div>
                      <div className="flex-5 grey d-flex align-items-center align-content-end">
                        <ColName className='flex-6 text-right'>{item.field_name}</ColName>
                        <div className="flex-5 mr-15 ml-05">
                          {item.field_type === 0 && <Checkbox label=" " formName={props.formName} name={`qualities.${k}.definitions.${j}.field_boolean_value`} />}
                          {item.field_type === 1 && <Select error={isStupidProject ? selectError : null} onChange={() => setStupidProject(false)} formName={props.formName} options={item.select_options ? item.select_options.map((o) => ({ ...o, value: o.id })) : []} name={`qualities.${k}.definitions.${j}.field_option_value`} number />}
                          {item.field_type === 2 && <Input formName={props.formName} type="text" name={`qualities.${k}.definitions.${j}.field_numeric_value`} number />}
                          {item.field_type === 3 && <Datepicker error={isStupidProject ? dateError : null} onChange={() => setStupidProject(false)} formName={props.formName} name={`qualities.${k}.definitions.${j}.field_date_value`} />}
                          {item.field_type === 4 && (
                            <span className="d-flex align-items-center justify-content-between">
                              <Select
                                formName={props.formName}
                                options={Array
                                  .from({ length: item.field_score_max_value }, (v, o) => o + 1)
                                  .map((i) => ({ value: i, name: i }))}
                                name={`qualities.${k}.definitions.${j}.field_score_value`}
                                number
                              />
                              {item.field_type === 4 ? `/ ${item.field_score_max_value}` : ''}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <Textarea formName={props.formName} name={`qualities.${k}.definitions.${j}.comment`} placeholder="Komentarz.." />
                      </div>
                    </div>
                  </>
                );
              }) : null}
            </>
          )) ?? null}

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancelButtonClick}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}
                >
                  Anuluj
                </button>
                <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
