import React from 'react';

import {
  Form, Input, Select
} from '../../../components/Forms';
import { ColNameBig } from 'components/Forms/Col';

const years = [
  { value: '2019', name: '2019' },
  { value: '2020', name: '2020' },
  { value: '2021', name: '2021' },
  { value: '2022', name: '2022' }
];

const months = [
  { value: '1', name: '1' },
  { value: '2', name: '2' },
  { value: '3', name: '3' },
  { value: '4', name: '4' },
  { value: '5', name: '5' },
  { value: '6', name: '6' },
  { value: '7', name: '7' },
  { value: '8', name: '8' },
  { value: '9', name: '9' },
  { value: '10', name: '10' },
  { value: '11', name: '11' },
  { value: '12', name: '12' }

];

export default (props) => (
  <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
    {props.visible ? (
      <>
        <div className="row mt-3">
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <ColNameBig className="col-sm-4 text-right">Rok</ColNameBig>
              <div className="col-sm-6">
                <Select formName={props.formName} options={years} name="year" />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <ColNameBig className="col-sm-4 text-right">Ilość miesięcy</ColNameBig>
              <div className="col-sm-6">
                <Select formName={props.formName} options={months} name="months_no" />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <ColNameBig className="col-sm-4 text-right">Przychód</ColNameBig>
              <div className="col-sm-6">
                <Input formName={props.formName} name="income" />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group row  align-items-center">
              <ColNameBig className="col-sm-4 text-right">Dochód</ColNameBig>
              <div className="col-sm-6">
                <Input formName={props.formName} name="revenue" />
              </div>
            </div>
          </div>
        </div>

        {props.withButtons ? (
          <div className="row">
            <div className="mt-4 col-sm-12 text-center">
              <button
                type="button"
                onClick={props.onCancelButtonClick}
                className="btn btn-primary-outline mr-2 font-weight-light"
                style={{ color: '#4B4E55' }}
              >
                Anuluj
              </button>
              <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                Zapisz
              </button>
            </div>
          </div>
        ) : null}
      </>
    ) : null}
  </Form>
);
