import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { formatValueToEurope, formatNip } from '../../../../utilities/helpers';
import DebtorsForm from '../../Forms/Debtors';

const NoData = () => (
  <div className="row bb">
    <div className="col-12 p-2">Brak danych.</div>
  </div>
);

export const DebtorsFormName = 'DebtorsForm';

export default props => {
  const debtors = props.proposal && props.proposal.debtors ? props.proposal.debtors : [];

  return (
    <>
      <div className="row text-center mb-3 mt-3">
        <div className="col-12">
          <button
            type="button"
            className="btn btn-primary w-25 btn-lg"
            onClick={() => props.toggleForm(DebtorsFormName)}>
            Dodaj dłużnika
          </button>
        </div>
      </div>
      {debtors.map(d => (
        <Card key={d?.id} mode='creator' header={`Warunki handlowe: ${d?.debtor?.name} ${formatValueToEurope(d?.requested_sublimit, '', { withoutCents: true })}`} onRemoveClick={() => props.remove('debtor', d?.id)}>
          <div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br grey">Nazwa firmy</div>
              <div className="col-sm-1 p-2 br grey">NIP</div>
              <div className="col-sm-2 p-2 br grey">Wnioskowany sublimit</div>
              <div className="col-sm-2 p-2 br grey">Dostępny sublimit</div>
              <div className="col-sm-1 p-2 br grey">Wykorzystany sublimit</div>
              <div className="col-sm-1 p-2 br grey">Kategoria</div>
              <div className="col-sm-1 p-2 br grey">MPP</div>
              <div className="col-sm-1 p-2 br grey">Zgoda na cesję</div>
              <div className="col-sm-1 p-2 grey">Potrącane z zaliczki</div>
            </div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br">{d.debtor ? d.debtor.name : '-'}</div>
              <div className="col-sm-1 p-2 br">{formatNip(d.debtor?.nip)}</div>
              <div className="col-sm-2 p-2 br">{formatValueToEurope(d.requested_sublimit)}</div>
              <div className="col-sm-2 p-2 br">{formatValueToEurope(d.available_sublimit)}</div>
              <div className="col-sm-1 p-2 br">{formatValueToEurope(d.used_sublimit)}</div>
              <div className="col-sm-1 p-2 br">{d.category?.name}</div>
              <div className="col-sm-1 p-2 br">
                <BooleanCheck field={d.mpp} />
              </div>
              <div className="col-sm-1 p-2 br">
                <BooleanCheck field={d.assignment_consent} />
              </div>
              <div className="col-sm-1 p-2">
                <BooleanCheck field={d.deducted_rates} />
              </div>
            </div>

            <div className="row bb">
              <div className="col-12 p-2 text-themecolor">Stawki podstawowe</div>
            </div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br grey">Nazwa stawki</div>
              <div className="col-sm-1 p-2 br grey">Max wysokość zaliczki</div>
              <div className="col-sm-2 p-2 br grey">Max termin płatności</div>
              <div className="col-sm-2 p-2 br grey">Stawka flat</div>
              <div className="col-sm-1 p-2 br grey">Czy marża odsetkowa</div>
              <div className="col-sm-1 p-2 br grey">Data stopy</div>
              <div className="col-sm-1 p-2 br grey">Stopa</div>
              <div className="col-sm-1 p-2 br grey">Czy zmienna</div>
              <div className="col-sm-1 p-2 grey">Marża odsetkowa</div>
            </div>
            {d.basicbids_with_intrestmargins.length ? (
              d.basicbids_with_intrestmargins.map(i => (
                <div className="row bb">
                  <div className="col-sm-2 p-2 br ">{i.name}</div>
                  <div className="col-sm-1 p-2 br ">{i.max_advance_payment}</div>
                  <div className="col-sm-2 p-2 br ">{i.max_payment_date}</div>
                  <div className="col-sm-2 p-2 br ">{i.flat_rate}</div>
                  <div className="col-sm-1 p-2 br ">{i.is_intrest_margin ? 'tak' : 'nie'}</div>
                  <div className="col-sm-1 p-2 br ">{i.date_of_rate}</div>
                  <div className="col-sm-1 p-2 br ">{i.intrest_rate ? i.intrest_rate.name : '-'}</div>
                  <div className="col-sm-1 p-2 br ">{i.is_variable ? 'tak' : 'nie'}</div>
                  <div className="col-sm-1 p-2 ">{i.value}</div>
                </div>
              ))
            ) : (
              <NoData />
            )}

            <div className="row bb">
              <div className="col-12 p-2 text-themecolor">Stawki złożone</div>
            </div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br grey">Nazwa stawki</div>
              <div className="col-sm-1 p-2 br grey">Poziom</div>
              <div className="col-sm-2 p-2 br grey">Max wysokość zaliczki</div>
              <div className="col-sm-2 p-2 br grey">Max termin płatności</div>
              <div className="col-sm-1 p-2 br grey">Stawka flat</div>
              <div className="col-sm-4 p-2 grey">Nazwa dokumentu</div>
            </div>

            {d.multilevelbids.length ? (
              d.multilevelbids.map(i => (
                <div className="row bb">
                  {i.levels.map((level, key) => (
                    <div className="col-sm-12">
                      <div className="row bt">
                        <div className="col-sm-2 p-2 br">{key === 0 ? i.name : ''}</div>
                        <div className="col-sm-1 p-2 br">{level.level_no}</div>
                        <div className="col-sm-2 p-2 br">{level.max_advance_payment}</div>
                        <div className="col-sm-2 p-2 br">{i.max_payment_date}</div>
                        <div className="col-sm-1 p-2 br">{i.multilevel_flat_rate}</div>
                        <div className="col-sm-4 p-2 br">
                          {level.document_to_buy_types ? level.document_to_buy_types.map(x => x.name).join(', ') : '-'}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <NoData />
            )}

            <div className="row bb">
              <div className="col-12 p-2 text-themecolor">Stawki dodatkowe</div>
            </div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br grey">Nazwa stawki</div>
              <div className="col-sm-2 p-2 br grey">Typ stawki</div>
              <div className="col-sm-2 p-2 br grey">Przeterminowanie ponad (dni)</div>
              <div className="col-sm-2 p-2 grey">Stawka</div>
            </div>
            {d.additionalbids.length ? (
              d.additionalbids.map(i => (
                <div className="row bb">
                  <div className="col-sm-2 p-2 br ">{i.name}</div>
                  <div className="col-sm-2 p-2 br ">{i.bid_type}</div>
                  <div className="col-sm-2 p-2 br ">{i.days_after_payment_date}</div>
                  <div className="col-sm-2 p-2 ">{i.rate_amount}</div>
                </div>
              ))
            ) : (
              <NoData />
            )}

            <div className="row bb">
              <div className="col-12 p-2 text-themecolor">Dokumenty do wykupu</div>
            </div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br grey">Nazwa dokumentu</div>
              <div className="col-sm-3 p-2 br grey">Oczekiwana forma dokumentu</div>
              <div className="col-sm-2 p-2 grey">Wymagania formalne</div>
            </div>
            {d.documentstobuy.length ? (
              d.documentstobuy.map(i => (
                <div className="row bb">
                  <div className="col-sm-2 p-2 br ">{i.dtb_type ? i.dtb_type.name : '-'}</div>
                  <div className="col-sm-3 p-2 br ">{i.dtb_form ? i.dtb_form.name : '-'}</div>
                  <div className="col-sm-2 p-2 ">{i.formal_expectation ? i.formal_expectation.name : '-'}</div>
                </div>
              ))
            ) : (
              <NoData />
            )}
          </div>
        </Card>
      ))}
      <BaseModal
        visible={props.formVisible(DebtorsFormName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Dłużnik</span>
          </div>
        }
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(DebtorsFormName)}>
        <DebtorsForm
          noSecures
          forms={props.forms}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(DebtorsFormName)}
          formVisible={props.formVisible}
          toggleForm={props.toggleForm}
          proposal={props.proposal}
          version={props.version}
          fetch={props.fetch}
          formData={props.formData}
          formName={DebtorsFormName}
          submitForm={() => props.saveForm(DebtorsFormName)}
          onCancelButtonClick={() => props.toggleForm(DebtorsFormName)}
          storeInput={props.storeInput}
          withButtons
          common={props.common}
          offerData={props.offerData}
          proposalData={props.proposalData}
          addField={props.addField}
          removeField={props.removeField}
          getFlatRate={props.getFlatRate}
          getMultilevelFlatRate={props.getMultilevelFlatRate}
          getIntrestRates={props.getIntrestRates}
          intrestRates={props.intrestRates}
          hasRiskCategory={props?.proposal?.transaction_risk_category}
          setLastAvailableDateOfRate={props.setLastAvailableDateOfRate}
        />
      </BaseModal>
    </>
  );
};
