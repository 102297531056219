import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import FormActions from '../../actions/forms';

// Dealing with Textarea Height
function calcHeight(value) {
  let numberOfLineBreaks = (value.match(/\n/g) || []).length;
  return 20 + numberOfLineBreaks * 20 + 12 + 2;
}

export default connect(
  (state) => ({ forms: state.Forms.forms, errors: state.Forms.errors }),
  (dispatch) => ({
    storeInput: (f, n, v) => dispatch(FormActions.storeInput(f, n, v))
  })
)((props) => {
  let error = null;
  const errors = props.errors[props.formName];
  const key = props.formName + props.name;
  const value = get(props.forms[props.formName], props.name, null);

  const [isMounted, setMounted] = useState(true);

  useEffect(() => {
    if(!value) {
      setMounted(false);
      setTimeout(() => setMounted(true));
    }

    let textarea = document.getElementById(`textarea-${props.name}`);

    function handleCalcHeight() {
      textarea.style.height = calcHeight(textarea.value) + 'px';
    }

    if(textarea) {
      handleCalcHeight();
      textarea.addEventListener('keyup', handleCalcHeight);
      return () => textarea.removeEventListener('keyup', handleCalcHeight);
    }
  }, [value]);

  if (errors && errors[props.name]) {
    error = errors[props.name];
  }

  return isMounted ? (
    <div className="form-group mb-0">
      <textarea
        key={key}
        placeholder={props.placeholder || null}
        defaultValue={value}
        rows={props.rows || 1}
        id={`textarea-${props.name}`}
        disabled={props.disabled || false}
        className={`${props.className || ''} form-control ${error ? 'form-control-danger' : ''}`}
        onChange={(e) => props.storeInput(props.formName, props.name, e.target.value)}
      />
      <div className="form-control-feedback small min-h-15" style={{ color: 'red' }}>
        {error}
      </div>
    </div>
  ) : (
    <div className="form-group mb-0">
      <textarea
        id={`textarea-${props.name}`}
        rows={props.rows || 1}
        className={`${props.className || ''} form-control ${error ? 'form-control-danger' : ''}`}
      />
      <div className="min-h-15" />
    </div>
  );
});
