import { BaseModal } from 'components/Layout/OldModal';
import React, { useState } from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { OldCard } from '../../../../components/Layout';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parsePhone } from '../../../../utilities/helpers';
import ComplaintsForm from '../../Forms/Complaints';
import { TextLink } from 'containers/Companies/Details/Components/Notes';
import { NoteForm } from 'containers/Companies/Forms/Note';

export default props => {
  const [noteId, setNoteId] = useState('');
  const formName = 'ComplaintsForm';
  const notesFormName = 'ComplaintsNotesForm';
  const claimsSelectorFilters = 'ClaimsSelectorFiltersForm';
  const header = c => (
    <a
      role="button"
      style={{ fontSize: '24px' }}
      className="collapsed "
      data-toggle="collapse"
      href="#warunkiDodatkowe"
      aria-expanded="false"
      aria-controls="collapseExample">
      Reklamacja: {c.identifier}
      <span className="ml-5" style={c.status ? { color: '#1DB393' } : {}}>
        {props.common.agreementData?.complaints_statuses?.[c.status]}
      </span>
    </a>
  );

  const customCreateSection = c => (
    <>
      {c.status === 0 ? (
        <i
          className="fas fa-plus ml-2 mr-2 "
          onClick={() => {
            setNoteId(`${c.identifier}/${props.common.agreementData?.complaints_statuses?.[c.status]}`);
            props.edit(notesFormName, {
              complaintId: c.id
            });
          }}
        />
      ) : null}
      <i className="fas fa-pencil-alt ml-2 mr-2 " onClick={() => props.edit(formName, c)} />
      <i className="fas fa-question-circle ml-2 mr-2 " onClick={() => {}} />
    </>
  );

  return (
    <>
      {props.version?.complaints?.map(c => {
        const isClosed = c?.status === 1;
        return (
          <OldCard header={header(c)} id="financialData-card" showIcons customCreateSection={customCreateSection(c)} hideCustomCreateSection={isClosed}>
            <div className="collapse show" id="financialData">
              <div className="row bb">
                <div className="col-sm-2 p-2 grey br">Numer reklamacji</div>
                <div className="col-sm-2 p-2 grey br">Data otwarcia</div>
                <div className="col-sm-2 p-2 grey br">Wierzytelność</div>
                <div className="col-sm-2 p-2 grey br">Zgłaszający</div>
                <div className="col-sm-1 p-2 grey br">Nr telefonu</div>
                <div className="col-sm-2 p-2 grey br">Adres e-mail</div>
                <div className="col-sm-1 p-2 grey">Zakończona</div>
              </div>
              <div className="row bb">
                <div className="col-sm-2 p-2  br">{c.identifier}</div>
                <div className="col-sm-2 p-2  br">{c.created_date}</div>
                <div className="col-sm-2 p-2  br">{c.claim?.identifier ?? '-'}</div>
                <div className="col-sm-2 p-2  br">{c.person_in_company?.person?.full_name}</div>
                <div className="col-sm-1 p-2  br">{parsePhone(c.phone)}</div>
                <div className="col-sm-2 p-2  br">{c.email}</div>
                <div className="col-sm-1 p-2 ">
                  <BooleanCheck field={c.closed_date} />
                </div>
              </div>
              <div className="row bb">
                <div className="col-sm-4 p-2 br" style={{ color: '#006BFF' }}>
                  Opis zgłoszenia reklamacyjnego
                </div>
                <div className="col-sm-8 p-2 ">
                  <TextLink>{c.comment}</TextLink>
                </div>
              </div>
            </div>
            {c.notes.length > 0 ? (
              c.notes.map(note => (
                <RowEditable
                  key={note.id}
                  className="row data-row mt-2 mb-1"
                  itemId={note.id}
                  onEdit={() => {
                    setNoteId(`${c.identifier}/${props.common.agreementData?.complaints_statuses?.[c.status]}`);
                    props.edit(notesFormName, { ...note, complaintId: c.id });
                  }}
                  onRemove={() => props.removeNote(c, note)}>
                  <div className="col-sm-2 text-center align-self-center">
                    <img src={note.avatar} className="img image rounded-circle" style={{ width: '80%' }} alt="" />
                    <div className="grey" style={{ fontSize: '80%' }}>
                      <span className="d-block mt-2">Notatka</span>
                      <span>{note.created_date}</span>
                    </div>
                  </div>
                  <div className="col-sm-10">
                    <a
                      role="button"
                      style={{ color: 'black' }}
                      className="collapsed"
                      data-toggle="collapse"
                      href={`#notatka${note.id}`}
                      aria-expanded="false"
                      aria-controls="collapseExample">
                      <i className="fa fa-sort-down mr-2" />
                      <span className="text-themecolor">{note.user_creator?.full_name}</span> dodał notatkę dla reklamacji{' '}
                      <span className="text-themecolor">{c.identifier}</span>
                    </a>

                    <div className="collapse show" id={`notatka${note.id}`} style={{ color: 'grey' }}>
                      <p className="small mb-1">
                        {note.created_date} |<span style={{ color: 'orange' }}>Temat:</span> {note.name}
                      </p>
                      <TextLink>{note.comment}</TextLink>

                      <div style={{ border: '1px solid lightgrey' }} className="p-2 small mt-2 mb-2">
                        <span className="d-block">
                        Dodał: <span className="text-themecolor">{note.user_creator?.full_name}</span>
                        </span>
                        <span className="d-block">
                        Data ostatniej modyfikacji:
                          {note.modified_date}
                        </span>
                        <span className="d-block">
                        Data utworzenia:
                          {note.created_date}
                        </span>
                      </div>
                    </div>
                  </div>
                </RowEditable>
              ))
            ) : (
              <div className="row">
                <div className="col p-2">
                  <span>Brak notatek.</span>
                </div>
              </div>
            )}
            <div className="row bt bb">
              <div className="col-sm-3 p-2" style={{ color: '#006BFF' }}>
                Operatorzy zgłoszenia
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br grey">Otworzył</div>
              <div className="col-sm-2 p-2 br grey">Data otwarcia</div>
              <div className="col-sm-2 p-2 br grey">Zamknął</div>
              <div className="col-sm-2 p-2 grey">Data zamknięcia</div>
            </div>
            <div className="row bb">
              <div className="col-sm-2 p-2 br">{c.user_opened?.full_name || '-'}</div>
              <div className="col-sm-2 p-2 br">{c.created_date || '-'}</div>
              <div className="col-sm-2 p-2 br">{c.user_closed?.full_name || '-'}</div>
              <div className="col-sm-2 p-2">{c.closed_date || '-'}</div>
            </div>
          </OldCard>
        );
      })}

      <BaseModal
        visible={props.formVisible(formName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Dodawanie reklamacji</span>
          </div>
        }
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}>
        <ComplaintsForm
          forms={props.forms}
          errors={props.errors}
          version={props.version}
          agreement={props.agreement}
          agreementData={props.common.agreementData}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(formName)}
          formVisible={props.formVisible}
          onCancelButtonClick={() => props.toggleForm(formName)}
          toggleForm={props.toggleForm}
          formName={formName}
          withButtons
          storeInput={props.storeInput}
          submitForm={() => props.saveForm(formName)}
          fetchClaims={() => props.fetchClaims(claimsSelectorFilters)}
          claimsFilters={claimsSelectorFilters}
          claims={props.claims}
        />
      </BaseModal>
      <BaseModal
        visible={props.formVisible(notesFormName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Notatka do reklamacji</span>
          </div>
        }
        footer="Aby zapisać notatkę wciśnij ZATWIERDŹ."
        onClickAway={() => props.toggleForm(notesFormName)}>
        <div className="pr-5">
          <NoteForm
            visibleOnMount
            clear={props.clear}
            visible={props.formVisible(notesFormName)}
            formName={notesFormName}
            withButtons
            submitForm={() => props.saveForm(notesFormName)}
            onCancelButtonClick={() => props.toggleForm(notesFormName)}
            identifier={noteId}
          />
        </div>
      </BaseModal>
    </>
  );
};
