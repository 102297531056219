import React from 'react';

function Loader() {
  return (
    <div className="text-center pt-5">
      <div className="lds-dual-ring" />
    </div>
  );
}

export default Loader;
