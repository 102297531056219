import { OptionsActions } from 'ducks/options';
import { get } from 'lodash';
import { toast } from 'react-toastify';

import OfferService from '../services/offer';
import CommonActions from './common';
import FormActions from './forms';

const selectCalculation = (calculation) => (dispatch) => {
  dispatch({ type: 'OFFER_CALCULATION_SELECTED', payload: calculation });
};

const fetch = (filters) => (dispatch) => {
  dispatch(OptionsActions.toggleLoader(true));
  const f = filters || CommonActions.defaultFilters;
  OfferService.fetch(f).then((response) => {
    dispatch({ type: 'OFFERS_FETCHED', payload: response.data });
    dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
    dispatch(OptionsActions.toggleLoader(false));
  });
};

const createOffer = (form, stayOnList, push) => (dispatch, getState) => {
  const data = getState().Forms.forms[form];
  dispatch(FormActions.clearErrors(form));

  OfferService.create(data)
    .then((response) => {
      dispatch(FormActions.clearForm(form));
      dispatch(FormActions.toggleForm(form));

      if (stayOnList) {
        toast.success('Obiekt został utworzony.');
        dispatch(fetch(null));
      } else {
        dispatch({ type: 'OFFER_DETAILS_FETCHED', payload: response.data });
        dispatch(selectCalculation(response.data.calculations[0]));
        push(`/oferty/${response.data.slug}/kreator`);
      }
    })
    .catch((errors) => dispatch(FormActions.handleErrors(form, errors?.response?.data)));
};

const fetchOfferDetails = (offerId, loader = true) => (dispatch, getState) => {
  const selectedCalculation = getState().Offer?.calculation;

  if (loader) {
    dispatch({ type: 'CONTENT_LOADING' });
    dispatch({ type: 'CONTENT_PREVIEW_LOADING' });
    dispatch(OptionsActions.toggleLoader(loader));
  }

  OfferService.fetchOfferDetails(offerId).then((response) => {
    dispatch({ type: loader ? 'OFFER_DETAILS_FETCHED' : 'OFFER_DETAILS_UPDATED', payload: response.data });
    dispatch({ type: 'CONTENT_LOADED' });

    let updatedCalculation = response.data.calculations[0];

    if(selectedCalculation?.id) {
      updatedCalculation = response.data.calculations.find(calculation => calculation.id === selectedCalculation.id);
    }

    dispatch(selectCalculation(updatedCalculation));

    if (loader) {
      dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
      dispatch(OptionsActions.toggleLoader(false));
    } else {
      // toast.success('Zaktualizowano dane.');
    }
  });
};

const selectForPreview = (offer) => (dispatch) => {
  dispatch({ type: 'OFFER_SELECTED_FOR_PREVIEW', payload: offer });
};

const saveForm = (form, offerId = null, hide = false) => (dispatch, getState) => {
  const data = getState().Forms.forms[form];
  const { calculation } = getState().Offer;

  if (calculation && (!data.calculationId || !data.currency_id)) {
    data.calculationId = calculation.id;
    data.currency_id = calculation.currency ? calculation.currency.id : null;
  }

  dispatch(FormActions.clearErrors(form));

  return OfferService.saveForm(form, data, offerId, calculation)
    .then(response => {
      dispatch(CommonActions.toggleModal());
      dispatch(fetchOfferDetails(offerId || data.id, false));

      document.getElementsByTagName('html')[0].style = '';

      const formsToBeLeftFilled = [
        'CalculationsCreatorForm',
        'AdministratorCreatorForm',
        'ReadinessCreatorForm',
        'ConditionsCreatorForm',
        'BasicRatesCreatorForm'
      ];

      if (formsToBeLeftFilled.indexOf(form) === -1) {
        dispatch(FormActions.clearForm(form));
      }

      if (hide) {
        dispatch(FormActions.toggleForm(form));
      }

      toast.success('Zaktualizowano dane.');
      return response;
    }).catch((errors) => dispatch(FormActions.handleErrors(form, errors?.response?.data)));
};

const removeObject = (objectId, offerId, object, extraData = '') => (dispatch) => OfferService
  .removeObject(objectId, offerId, object, extraData).then(() => {
    // dispatch(selectCalculation({}));

    dispatch(CommonActions.toggleModal());
    dispatch(fetchOfferDetails(offerId, false));

    document.getElementsByTagName('html')[0].style = '';
    toast.success('Usunięto dane.');
  });

const removeOffer = (id) => (dispatch) => OfferService.removeOffer(id)
  .then(() => {
    dispatch(fetch(CommonActions.defaultFilters));
    toast.success('Usunięto obiekt.');
  })
  .catch((e) => e);

const getFlatRate = (formName) => (dispatch, getState) => {
  dispatch(FormActions.clearErrors(formName));
  const data = get(getState().Forms.forms, formName);
  const { calculation } = getState().Offer;

  const trcId = calculation.transaction_risk_category?.id
    || data.transaction_risk_category_id
    || null;

  if (data && data.margin_based_on_tran_risk && !trcId) {
    dispatch(
      FormActions.handleErrors(formName, {
        non_field_errors: [
          'Stawka nie zostanie wyliczona automatycznie, ponieważ nie wybrano kategorii ryzyka transakcji lub nie zapisano danych podstawowych kalkulacji.'
        ]
      })
    );
    return;
  }

  data.transaction_risk_category_id = trcId;

  if (data && data.max_advance_payment && data.max_payment_date && data.margin_based_on_tran_risk) {
    OfferService.getFlatRate(data).then((r) => {
      const flatRate = r.data.flat_rate || 0;
      dispatch(FormActions.storeInput(formName, 'flat_rate', flatRate.toString()));
    });
  }
};

const getMultilevelFlatRate = (formName) => (dispatch, getState) => {
  dispatch(FormActions.clearErrors(formName));
  const data = get(getState().Forms.forms, formName);
  const { calculation } = getState().Offer;
  const trcId = calculation.transaction_risk_category?.id
    || data.transaction_risk_category_id
    || null;

  if (data && data.margin_based_on_tran_risk && !trcId) {
    dispatch(
      FormActions.handleErrors(formName, {
        non_field_errors: [
          'Stawka nie zostanie wyliczona automatycznie, ponieważ nie wybrano kategorii ryzyka transakcji lub nie zapisano danych podstawowych kalkulacji.'
        ]
      })
    );
    return;
  }

  data.transaction_risk_category_id = trcId;

  if (
    data?.margin_based_on_tran_risk
    && data?.max_payment_date
    && data?.levels?.length > 0
    && data?.levels.filter((item) => item?.max_advance_payment).length === data?.levels?.length
  ) {
    OfferService.getMultilevelFlatRate(data).then((r) => {
      const flatRate = r.data.flat_rate || 0;
      dispatch(FormActions.storeInput(formName, 'multilevel_flat_rate', flatRate.toString()));
    });
  }
};

const getIntrestRates = (formName) => (dispatch, getState) => {
  const data = get(getState().Forms.forms, formName);
  if (data && data.is_intrest_margin && data.intrest_margin && data.intrest_margin.date_of_rate) {
    OfferService.getIntrestRates(data).then((r) => {
      const payload = r.data.result ? r.data.result.map((i) => ({ value: i.id, name: `${i.name} / ${i.credit_cost[0].value}`, date: i.credit_cost[0].date })) : [];
      dispatch({ type: 'OFFER_INTREST_RATES_FETCHED', payload });

      if (payload.length === 0) {
        dispatch({ type: 'OFFER_INTREST_RATES_DATE_AVAILABLE', payload: r.data.date_of_rate_before });
      }
    });
  }
};

const fetchBeneficiaries = (formName) => (dispatch, getState) => {
  const data = getState().Forms.forms[formName];
  const role_name = getState().Common.systemRoles.find(
    (r) => r.id === Number(data.type)
  ).name || null;

  OfferService.fetchBeneficiaries({ role_name, search_mode: 'company' }).then((r) => {
    const results = r.data && r.data.results ? r.data.results : [];
    const payload = results
      .filter((i) => i.commission_parents)
      .filter((i) => i.commission_parents.length > 0);

    dispatch({ type: 'OFFER_BENEFICIARIES_FETCHED', payload });
  });
};

const acceptCalculation = (offerId, calculationId) => (dispatch) => {
  OfferService.acceptCalculation(offerId, calculationId)
    .then(() => {
      dispatch(fetchOfferDetails(offerId, false));
      document.getElementsByTagName('html')[0].style = '';
      dispatch(FormActions.toggleForm('acceptance'));
    })
    .catch((e) => e);
};

const generatePdf = (offerId, form) => (dispatch) => {
  OfferService.generatePdf(offerId)
    .then((r) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(r.data);
      link.download = `Smart_Faktor_oferta_${offerId}.pdf`;
      link.click();
      dispatch(FormActions.toggleForm(form));
      toast.success('Pobieranie rozpoczęte.');
    })
    .catch((e) => e);
};

const setLastAvailableDateOfRate = (formName, field = 'intrest_margin.date_of_rate') => (dispatch, getState) => {
  dispatch(FormActions.storeInput(formName, field, getState().Offer.intrestRatesDate));
  if (field && field !== 'intrest_margin.date_of_rate') {
    let f = `${formName}.${field}`;
    f = f.split('.');
    f.length -= 2;
    f = f.join('.');
    dispatch(getIntrestRates(f));
  } else {
    dispatch(getIntrestRates(formName));
  }
};

const changeStatus = (offerId, filters) => (dispatch) => {
  OfferService.changeStatus(offerId).then(() => {
    dispatch(FormActions.toggleForm('status'));
    toast.success('Status obiektu został zmieniony.');
    dispatch(fetch(filters));
  }).catch(() => {
    toast.error('Nie udało się zmienić statusu oferty.');
  });
};

const toggleOffer = (offerId) => (dispatch) => OfferService.changeStatus(offerId).then(() => {
  dispatch(fetchOfferDetails(offerId));
  toast.success('Status został zmieniony.');
}).catch(() => toast.error('Brak zatwierdzonej kalkulacji.'));

export default {
  fetch,
  selectForPreview,
  createOffer,
  fetchOfferDetails,
  selectCalculation,
  saveForm,
  removeObject,
  removeOffer,
  getFlatRate,
  getMultilevelFlatRate,
  getIntrestRates,
  fetchBeneficiaries,
  acceptCalculation,
  generatePdf,
  setLastAvailableDateOfRate,
  changeStatus,
  toggleOffer
};
