import React from 'react';

import {
  Checkbox, Datepicker,
  Form, Input, Select } from '../../../../components/Forms';
import { RowEditable } from '../../../../components/Layout/RowEditable';

const RatesForm = (props) => {
  const f = props.form || { basicbids_with_intrestmargins: [] };

  return (
    <Form
      noErrors
      asDiv
      initialForm={{
        calculationId: props.calculationId,
        margin_based_on_tran_risk:
                    f && typeof f.margin_based_on_tran_risk !== 'undefined' ? f.margin_based_on_tran_risk : props.margin_based_on_tran_risk
      }}
      visibleOnMount={1}
      className="form-horizontal"
      formName={props.formName}
    >

      <div className="row  ">
        <div className="col-sm-12">
          <div className="form-group row  align-items-center">
            <div className="col-sm-3 col-form-label label-right font-weight-normal">Marża wg ryzyka transakcji</div>
            <div className="col-sm-6">
              <Checkbox
                className="custom-control custom-checkbox d-flex"
                label=""
                formName={props.formName}
                name="margin_based_on_tran_risk"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row  ">
        <div className="col-sm-12">
          <div className="form-group row  align-items-center">
            <div className="col-sm-3 col-form-label label-right font-weight-normal">Kontrola marży minimalnej</div>
            <div className="col-sm-6">
              <Checkbox
                className="custom-control custom-checkbox d-flex"
                label=""
                formName={props.formName}
                name="min_margin_control"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-sm-2 grey">Max wysokość zaliczki</div>
        <div className="col-sm-2 grey">Max termin płatności</div>
        <div className="col-sm-1 grey">Stawka</div>
        <div className="col-sm-1 grey">Marża odsetkowa?</div>

        <div className="col-sm-2 grey">Data stopy</div>
        <div className="col-sm-2 grey">Stopa</div>
        <div className="col-sm-1 grey">Czy zmienna</div>
        <div className="col-sm-1 grey">Marża odsetkowa</div>
      </div>
      {f.basicbids_with_intrestmargins.map((bid, k) => (
        <>
          <RowEditable
            className="row mb-1"
            key={bid.id}
            onRemove={() => props.removeField(`basicbids_with_intrestmargins.${k}`, props.formName)}
          >
            <div className="col-sm-2 grey">
              <Input
                formName={props.formName}
                type="text"
                name={`basicbids_with_intrestmargins.${k}.max_advance_payment`}
                number
                onChange={() => props.getFlatRate(props.formName, k)}
              />
            </div>
            <div className="col-sm-2 grey">
              <Input
                formName={props.formName}
                type="text"
                name={`basicbids_with_intrestmargins.${k}.max_payment_date`}
                number
                onChange={() => props.getFlatRate(props.formName, k)}
              />
            </div>
            <div className="col-sm-1 grey">
              <Input
                formName={props.formName}
                type="text"
                name={`basicbids_with_intrestmargins.${k}.flat_rate`}
                disabled={bid.margin_based_on_tran_risk && props.hasRiskCategory}
              />
            </div>
            <div className="col-sm-1 grey align-items-center">
              <Checkbox label=" " formName={props.formName} name={`basicbids_with_intrestmargins.${k}.is_intrest_margin`} />
            </div>
            {bid.is_intrest_margin ? (
              <>
                <div className="col-sm-2 grey">
                  <Datepicker
                    onChange={() => {
                      props.clear(props.formName, `basicbids_with_intrestmargins.${k}.intrest_margin.intrest_rate_id`);
                      props.getIntrestRates(`${props.formName}.basicbids_with_intrestmargins.${k}`);
                    }}
                    formName={props.formName}
                    name={`basicbids_with_intrestmargins.${k}.intrest_margin.date_of_rate`}
                  />
                </div>
                <div className="col-sm-2 grey">
                  <Select
                    disabled={(bid.intrest_margin && !bid.intrest_margin.date_of_rate)}
                    formName={props.formName}
                    options={props.intrestRates.filter((i) => bid.intrest_margin && bid.intrest_margin.date_of_rate && i.date === bid.intrest_margin.date_of_rate)}
                    number
                    name={`basicbids_with_intrestmargins.${k}.intrest_margin.intrest_rate_id`}
                  />
                </div>
                <div className="col-sm-1 grey">
                  <Checkbox label=" " formName={props.formName} name={`basicbids_with_intrestmargins.${k}.intrest_margin.is_variable`} />
                </div>
                <div className="col-sm-1 grey">
                  <Input formName={props.formName} type="text" name={`basicbids_with_intrestmargins.${k}.intrest_margin.value`} />
                </div>
              </>
            ) : (
              <>
                <div className="col-2">-</div>
                <div className="col-2">-</div>
                <div className="col-1">-</div>
                <div className="col-1">-</div>
              </>
            )}
          </RowEditable>
          {bid.is_intrest_margin
          && bid.intrest_margin
          && bid.intrest_margin.date_of_rate
          && props.intrestRates.filter((i) => i.date === bid.intrest_margin.date_of_rate).length === 0 ? (
              <div className="row p-2">
                <div className="text-center text-danger">
                  <p>
                  Nie określono stopy referencyjej dla wybranej daty.
                    {' '}
                    {' '}
                    <u style={{ cursor: 'pointer' }} onClick={() => props.setLastAvailableDateOfRate(props.formName, `basicbids_with_intrestmargins.${k}.intrest_margin.date_of_rate`)}>
                    Czy wstawić ostatnią, która jest dostępna?
                    </u>
                  </p>
                </div>
              </div>
            ) : null}
        </>
      ))}

      <button type="button" onClick={() => props.addField('basicbids_with_intrestmargins', props.formName)} className="btn btn-sm">+ Dodaj</button>
    </Form>
  );
};

export default RatesForm;
