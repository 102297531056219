import BooleanCheck from 'components/BooleanCheck';
import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import { RowEditable } from 'components/Layout/RowEditable';
import { isEditableProposal } from 'containers/Proposals/Details/Index';
import QualityForm from 'containers/Proposals/Forms/Quality';
import { t } from 'lang/pl';
import moment from 'moment';
import React, { } from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { formatString } from 'utilities/helpers';
import DecisionString from 'components/Format/DecisionString';
import { TextLink } from 'containers/Companies/Details/Components/Notes';

export default (props) => {
  const formName = 'FraudForm';
  const amlForm = 'AMLDecisionForm';

  const create = () => props.edit({}, formName);
  const edit = (criteria, definition) => {
    definition = { ...definition };
    if (definition.criteria_select_options && definition.criteria_select_options.length) {
      definition.select_options = definition.criteria_select_options.map((o) => ({ ...o, value: o.id }));
      delete definition.criteria_select_options;
      definition.field_option_value = definition?.field_option_value?.id;
    }
    props.edit({
      id: `${criteria.id}_${definition.id}`,
      qualities: [
        {
          id: criteria.id,
          definitions: [
            { ...definition, category_id: criteria.id }
          ]
        }
      ]
    }, formName);
  };

  const rows = props?.version?.criteria_categories_aml ?? [];
  const amlDecisions = props?.version?.aml_decisions ?? [];
  const client = props?.proposal?.client ?? {};

  return (
    <Card
      header='Faktorant ocena ryzyka fraudowego i AML'
      isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
      onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
      onAddClick={create}
    >
      <div>
        <ReactTooltip />
        {rows.length ? rows.map((row) => (
          <div key={row.id} className="row">
            <div className="col-sm-12">
              <div className="row bb ">
                <div className="col-sm-12 p-2 text-themecolor">
                  {row.name}
                </div>
              </div>
              {row?.proposal_criteria?.map(item => (
                <>
                  <div className="row bb">
                    <div className="flex-10 max-w-60 p-2 br grey">ID</div>
                    <div className="flex-25 p-2 br grey">Kryterium</div>
                    <div className="flex-20 max-w-250 p-2 br grey">{item.field_name}</div>
                    <div className="flex-10 max-w-100 p-2 br grey">Wymagany</div>
                    <div className="flex-10 max-w-120 p-2 br grey">Oceniający</div>
                    <div className="flex-10 max-w-60 p-2 grey">&nbsp;</div>
                  </div>
                  <RowEditable
                    key={row.identifier}
                    onEdit={() => edit(row, item)}
                    onRemove={item.is_required ? false : () => props.remove('criteria', item.id)}
                    isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
                  >
                    <div className="flex-10 max-w-60 p-2 br ">{item.version_number}</div>
                    <div className="flex-25 p-2 br ">{item.name}</div>
                    <div className="flex-20 max-w-250 p-2 br ">
                      {item.field_type === 0 ? item.field_boolean_value : null}
                      {item.field_type === 1 ? <DecisionString value={item.field_option_value?.name} /> : null}
                      {item.field_type === 2 ? item.field_numeric_value : null}
                      {item.field_type === 3 ? item.field_date_value : null}
                      {item.field_type === 4 ? item.field_score_value : null}
                    </div>
                    <div className="flex-10 max-w-100 p-2 br "><BooleanCheck field={item.is_required} /></div>
                    <div className="flex-10 max-w-120 p-2 br ">{item.evaluative ? item.evaluative.full_name : '-'}</div>
                    <div className="flex-10 max-w-60 p-2 ">
                      <i className="fa fa-eye" data-tip={item.instruction} />
                    </div>
                  </RowEditable>
                  {item.comment ? (
                    <div className="row bb">
                      <div className="col p-2">
                        <TextLink>{item.comment}</TextLink>
                      </div>
                    </div>
                  ) : ''}
                </>
              )) ?? <div className="row"><div className="col p-2"><span>Brak danych.</span></div></div>}
            </div>
          </div>
        )) : <div className="row"><div className="col p-2">Brak danych.</div></div>}

        <div className="row">
          <div className="col-sm-12 p-2 text-themecolor">
            Anti-money laundering (AML)
            <span className="float-right" style={{ cursor: 'pointer' }}>
              <i className="fa fa-pencil-alt ml-2" onClick={() => props.toggleForm(amlForm)} />
            </span>
          </div>
        </div>
        <div className="row bb bt">
          <div className="col-sm-2 p-2 br grey">Data oceny AML</div>
          <div className="col-sm-2 p-2 br grey">Zatwierdził decyzję</div>
          <div className="col-sm-2 p-2 br grey">Ocena</div>
          <div className="col-sm-4 p-2 grey">Uwagi</div>
        </div>
        {amlDecisions.map(amlDecision => (
          <div key={amlDecision.id} className="row">
            <div className="col-sm-2 p-2 br ">{amlDecision?.decision_date ?? '-'}</div>
            <div className="col-sm-2 p-2 br ">{amlDecision?.decision_maker?.full_name ?? '-'}</div>
            <div className="col-sm-2 p-2 br "><DecisionString value={amlDecision?.decision}/></div>
            <div className="col-sm-4 p-2 ">{formatString(amlDecision?.comments, 'Brak')}</div>
          </div>
        ))}

        <BaseModal
          visible={props.formVisible(formName)}
          header={(
            <div className="d-flex align-items-center">
              <i className="fas fa-pencil-alt mr-2" />
              <span>Ocena ryzyka fraudowego</span>
            </div>
          )}
          footer="Aby zakończyć edycję wciśnij Zapisz."
          onClickAway={() => props.toggleForm(formName)}
        >
          <QualityForm
            aml
            populate={() => props.populateForm(formName)}
            categories={props.categories}
            fieldTypes={props.fieldTypes}
            forms={props.forms}
            preloadedForm
            visibleOnMount
            clear={props.clear}
            visible={props.formVisible(formName)}
            formName={formName}
            submitForm={() => props.saveForm(formName)}
            onCancelButtonClick={() => props.toggleForm(formName)}
            storeInput={props.storeInput}
            withButtons
          />
        </BaseModal>

        <BaseModal
          visible={props.formVisible(amlForm)}
          header={(
            <div className="d-flex align-items-center">
              <i className="fas fa-pencil-alt mr-2" />
              <span>Ocena AML</span>
            </div>
          )}
          footer="Aby dodać wciśnij zatwierdź."
          onClickAway={() => props.toggleForm(amlForm)}
        >
          <div className="container">
            <div className="row bb bt">
              <div className="col-sm-2 p-2 br bl grey">Data oceny AML</div>
              <div className="col-sm-2 p-2 br grey">Klient</div>
              <div className="col-sm-2 p-2 br grey">Zatwierdził decyzję</div>
              <div className="col-sm-2 p-2 br grey">Ocena</div>
              <div className="col-sm-4 p-2 br grey">Uwagi</div>
            </div>
            {amlDecisions?.length && (
              <div className="row bb">
                <div className="col-sm-2 p-2 br bl">{amlDecisions[0].decision_date ?? moment().format('DD.MM.YYYY')}</div>
                <div className="col-sm-2 p-2 br">{client.name ?? '-'}</div>
                <div className="col-sm-2 p-2 br">{amlDecisions[0].decision_maker?.full_name ?? '-'}</div>
                <div className="col-sm-2 p-2 br"><DecisionString value={amlDecisions[0].decision}/></div>
                <div className="col-sm-4 p-2 br">{formatString(amlDecisions[0].comments, 'Brak')}</div>
              </div>
            )}
            <div className="row mt-3">
              <div className="col-sm-1 p-2 grey text-right">
                Uwagi:
              </div>
              <div className="col-sm-11 p-2">
                Zamierzasz dokonać oceny AML zgodnie z obowiązującą polityką w zakresie przeciwdziałania prania brudnych pieniędzy i finansowania terroryzmu. Twoja ocena zostanie zapisana w logu transakcyjnym.
              </div>
            </div>
            <div className="row mt-3">
              <div className="mt-4 col-sm-12 text-center">
                <button type="button" onClick={() => props.toggleForm(amlForm)} className="btn btn-primary-outline mr-2 font-weight-light" style={{ color: '#4B4E55' }}>
                  Anuluj
                </button>
                <button type="button" onClick={() => props.updateAmlDecision(amlForm)} className="btn btn-primary ml-2 font-weight-light">
                  Zatwierdź
                </button>
              </div>
            </div>
          </div>
        </BaseModal>
      </div>
    </Card>
  );
};
