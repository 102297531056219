import FormActions from 'actions/forms';
import Card from 'components/Layout/Card';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BasicDataForm } from '../../Forms/BasicData';

export const BasicData = props => {
  const formName = 'CalculationsCreatorForm';
  const { calculation } = useSelector(state => state.Offer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FormActions.initialize(formName, {
      ...calculation,
      currency: calculation?.currency?.id,
      transaction_risk_category_id: calculation?.transaction_risk_category?.id
    }, {}));
  }, []);

  const handleSaveForm = () => {
    dispatch(FormActions.clearErrors('BasicRatesCreatorForm'));
    dispatch(FormActions.clearErrors('MultilevelRatesCreatorForm'));
    props.saveForm(formName);
  };

  return (
    <Card mode='creator' header='+ Dane podstawowe kalkulacji' onSaveClick={handleSaveForm}>
      <div/>

      <div className="pt-3">
        <BasicDataForm
          forms={props.forms}
          currencies={props.currencies}
          offerData={props.offerData}
          calculationId={calculation?.id}
          calculation={calculation}
          offer={props.offer}
          populate={() => props.populateForm(formName)}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(formName)}
          formName={formName}
          saveForm={handleSaveForm}
          onCancel={() => props.toggleForm(formName)}
        />
      </div>
    </Card>
  );
};
