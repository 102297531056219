import Card from 'components/Layout/Card';
import React, { useEffect } from 'react';

import { AdministratorForm } from '../../Forms/Administrator';

export default props => {
  const formName = 'AdministratorForm';

  const f = props.version && props.version ? props.version : {};

  useEffect(() => {
    if (props.populateForm) {
      props.populateForm(formName, {
        id: f.id,
        company_started_date: f.company_started_date,
        first_invoice_date: f.first_invoice_date,
        no_employees: f.no_employees,
        main_pkd_id: f.main_pkd ? f.main_pkd.id : null,
        suppliers_number: f.suppliers_number,
        recipients_number: f.recipients_number,
        accounting_support_id: f.accounting_support ? f.accounting_support.id : null,
        accounting_type_id: f.accounting_type ? f.accounting_type.id : null,
        activity_range_id: f.activity_range ? f.activity_range.id : null,
        office_ownership_id: f.office_ownership ? f.office_ownership.id : null,
        insurance_claims: f.insurance_claims
      });
    }
    // eslint-disable-next-line
  }, props);

  return (
    <Card mode='creator' header='+ Administrator' onSaveClick={() => props.saveForm(formName)}>
      <div />
      <AdministratorForm
        freqs={props.freqs}
        visible
        visibleOnMount
        formName={formName}
        onSubmit={() => props.saveForm(formName)}
      />
    </Card>
  );
};
