import React from 'react';

import ExternalRegister from '../Components/ExternalRegister';
import InternalRegister from '../Components/InternalRegister';

export default (props) => (
  <>
    <ExternalRegister
      claim={props.claim}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      create={props.edit}
      saveForm={props.saveForm}
      edit={props.edit}
      onCancel={(f) => props.toggleForm(f)}
      storeInput={props.storeInput}
      removeTransfer={props.removeTransfer}
      common={props.common}
    />
    <InternalRegister
      claim={props.claim}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      create={props.edit}
      saveForm={props.saveForm}
      edit={props.edit}
      onCancel={(f) => props.toggleForm(f)}
      storeInput={props.storeInput}
      removeTransfer={props.removeTransfer}
      common={props.common}
    />
  </>
);
