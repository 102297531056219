import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AuthorizationActions from '../actions/authorization';
import { Buttons, Form, Input } from './Forms';

class LoginForm extends React.Component {
  formName = 'LoginForm';

  submit() {
    this.props.dispatch(AuthorizationActions.login(this.formName, this.props.history.push));
  }

  render() {
    const error = this.props.forms?.errors?.[this.formName]?.error;

    return (
      <section id="wrapper">
        <div className="login-register" style={{ background: 'lightblue' }}>
          <div className="login-box card">
            <div className="card-body p-5">
              <Form className="form-horizontal form-material" formName={this.formName}>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Input onSubmit={() => this.submit()} formName={this.formName} type="text" placeholder="Podaj login" name="username" />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Input onSubmit={() => this.submit()} formName={this.formName} type="password" placeholder="Podaj hasło" name="password" />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-sm-12">
                    <Buttons submitButton="Zaloguj" onSubmit={() => this.submit()} />
                  </div>
                </div>
                {error && (
                  <div className="row" style={{ background: 'red', color: 'white', borderRadius: '5px' }}>
                    <div className="col-sm-12 text-center p-1">Wprowadzono błędne dane logowania.</div>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  forms: state.Forms,
  authorization: state.Authorization
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(LoginForm)
);
