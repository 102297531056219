import React from 'react';

import { OldCard } from '../../../../components/Layout';
import { BaseModal } from 'components/Layout/OldModal';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parseMoney } from '../../../../utilities/helpers';
import TransferForm from '../../../Transfers/Forms/Transfer';

export default props => {
  const formName = 'ExternalRegisterForm';

  const header = (
    <a
      role="button"
      style={{ fontSize: '24px' }}
      className="collapsed "
      data-toggle="collapse"
      href="#warunkiDodatkowe"
      aria-expanded="false"
      aria-controls="collapseExample">
      Rejestr rozliczenia wydatków
    </a>
  );

  const customCreateSection = (
    <>
      <i className="fas fa-question-circle ml-2 mr-2 " onClick={() => {}} />
    </>
  );

  const edit = (e, p) => {
    props.edit(formName, { commitment: e, ...p, claim: props.claim });
  };

  return (
    <OldCard header={header} id="financialData-card" showIcons customCreateSection={customCreateSection}>
      <div className="collapse show" id="financialData">
        <div className="row bb">
          <div className="col-sm-3 p-2 grey br">Tytuł</div>
          <div className="col-sm-2 p-2 grey br">Podstawa wydatku</div>
          <div className="col-sm-1 p-2 grey br">Data wydatku</div>
          <div className="col-sm-1 p-2 grey br">Kwota wydatku</div>
          <div className="col-sm-1 p-2 grey br">Waluta rozliczenia</div>
          <div className="col-sm-1 p-2 grey br">Rozliczono</div>
          <div className="col-sm-1 p-2 grey br">Do rozliczenia</div>
          <div className="col-sm-2 p-2 grey">Status</div>
        </div>

        {props.claim?.commitments?.map((e, k) => (
          <>
            <RowEditable itemId={e.id}>
              <div className="col-sm-3 p-2 br">
                <a
                  role="button"
                  style={{ color: 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#wydatek${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample">
                  <i className="fa fa-sort-down mr-2" />
                  {e.title}
                </a>
              </div>
              <div className="col-sm-2 p-2 br">
                <a
                  role="button"
                  style={{ color: 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#wydatek${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample">
                  {e.document_no}
                </a>
              </div>
              <div className="col-sm-1 p-2 br">
                <a
                  role="button"
                  style={{ color: 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#wydatek${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample">
                  {e.commitment_date}
                </a>
              </div>
              <div className="col-sm-1 p-2 br">
                <a
                  role="button"
                  style={{ color: 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#wydatek${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample">
                  {parseMoney(e.gross_value)}
                </a>
              </div>
              <div className="col-sm-1 p-2 br">
                <a
                  role="button"
                  style={{ color: 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#wydatek${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample">
                  {e.currency?.name}
                </a>
              </div>
              <div className="col-sm-1 p-2 br">
                <a
                  role="button"
                  style={{ color: 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#wydatek${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample">
                  {parseMoney(e.paid_value)}
                </a>
              </div>
              <div className="col-sm-1 p-2 br">
                <a
                  role="button"
                  style={{ color: 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#wydatek${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample">
                  {parseMoney(e.to_pay_value)}
                </a>
              </div>
              <div className="col-sm-2 d-flex p-2">
                <a
                  role="button"
                  style={{ color: e.status ? '#1DB393' : 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#wydatek${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample">
                  {e.is_settled ? 'Rozliczony' : 'Nierozliczony'}
                </a>
              </div>
            </RowEditable>

            <div className="collapse" id={`wydatek${k}`}>
              <div className="row bb grey">
                <div className="col-sm-3 p-2 br"> </div>
                <div className="col-sm-2 p-2 br">Postawa rozliczenia</div>
                <div className="col-sm-1 p-2 grey br">Data rozliczenia</div>
                <div className="col-sm-1 p-2 grey br">Kwota rozliczenia</div>
                <div className="col-sm-1 p-2 grey br">Waluta</div>
                <div className="col-sm-1 p-2 grey br">Rozliczono</div>
                <div className="col-sm-1 p-2 grey br">Sposób rozliczenia</div>
                <div className="col-sm-2 p-2 grey">Status</div>
              </div>
              {e.payments.map(p => (
                <RowEditable>
                  <div className="col-sm-3 grey p-2 br"> </div>
                  <div className="col-sm-2 grey p-2 br">{p.identifier}</div>
                  <div className="col-sm-1 grey p-2 br">{p.created_date}</div>
                  <div className="col-sm-1 grey p-2 br">{parseMoney(p.gross_transfer_value)}</div>
                  <div className="col-sm-1 grey p-2 br">{p.gross_transfer_value_currency?.name ?? p.vat_transfer_value_currency?.name}</div>
                  <div className="col-sm-1 grey p-2 br">{parseMoney(p.to_pay_value)}</div>
                  <div className="col-sm-1 grey p-2 br">Przelew</div>
                  <div className="col-sm-2 grey d-flex p-2">
                    {props.common?.paymentData?.payment_statuses?.[p.status]}
                    {p.status === 0 ? (
                      <button className="ml-3" type="button" onClick={() => edit(e, p)}>
                        Autoryzuj
                      </button>
                    ) : null}
                    {/* {e.status === 0 ? <button className="ml-1" onClick={() => props.edit(formName, { commitment_id: e.id })}>Rozlicz</button> : null} */}
                  </div>
                </RowEditable>
              ))}
            </div>
          </>
        ))}
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" /> <span>Podgląd przelewu</span>
          </div>
        }
        footer="Aby zapisać dokument wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}>
        <TransferForm
          storeInput={props.storeInput}
          formVisible={f => props.formVisible(f)}
          fetchForForm={() => {}}
          dataForForm={{}}
          removeTransfer={props.removeTransfer}
          authorizeTransfer={() => props.saveForm(formName)}
          common={props.common}
          toggleForm={props.toggleForm}
          formName={formName}
          claim={props.claim}
          forms={props.forms}
          withButtons
          details
          register
        />
      </BaseModal>
    </OldCard>
  );
};
