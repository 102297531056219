import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useEffect } from 'react';

import { RowEditable } from '../../../../../components/Layout/RowEditable';
import { getGroupOfErrors, parseMoney } from '../../../../../utilities/helpers';
import LiabilitiesForm from '../../../Forms/Liabilities/Leasing';
import FormActions from 'actions/forms';
import { useDispatch } from 'react-redux';

export default props => {
  const dispatch = useDispatch();
  const formName = 'LeasingLiabilitiesCreatorForm';
  const modalFormName = 'LeasingLiabilitiesCreatorEditForm';

  useEffect(() => {
    if (props.populateForm) {
      props.populateForm(formName);
    }
    // eslint-disable-next-line
  }, props);

  const canBeSubmitted =
    props.forms[formName] &&
    props.forms[formName].lessor_id &&
    props.forms[formName].leasing_subject_id &&
    props.forms[formName].current_debt &&
    props.forms[formName].last_installment_date;

  const rows = props.version && props.version.leasing_liabilities ? props.version.leasing_liabilities : [];

  const handleBackLightFields = () => {
    dispatch(FormActions.handleValidErrors(formName, ['lessor_id', 'leasing_subject_id', 'current_debt', 'last_installment_date']));
  };

  return (
    <Card mode='creator' header='+ Zobowiązania leasingowe' onSaveClick={() => props.saveForm(formName, true)} isBlocked={!canBeSubmitted} onBlockedMessage={handleBackLightFields}>
      <LiabilitiesForm
        populate={() => props.populateForm(formName)}
        forms={props.forms}
        proposalData={props.proposalData}
        preloadedForm
        visible
        visibleOnMount
        clear={props.clear}
        formName={formName}
        submitForm={() => props.saveForm(formName)}
        onCancelButtonClick={() => props.toggleForm(formName)}
      />

      {rows.length ? (
        <div className="row bt">
          <div className="col-sm-12">
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Leasingodawca</div>
              <div className="col-sm-3 p-2 br grey">Przedmiot leasingu</div>
              <div className="col-sm-2 p-2 br grey">Aktualne zadłużenie</div>
              <div className="col-sm-2 p-2 br grey">Data ostatniej raty</div>
              <div className="col-sm-2 p-2 grey">Kwota wykupu</div>
            </div>
            {rows.map(row => (
              <RowEditable
                key={row.identifier}
                onEdit={() => {
                  const editableRow = {
                    lessor_id: row.lessor ? row.lessor.id : null,
                    id: row.id,
                    leasing_subject_id: row.leasing_subject ? row.leasing_subject.id : null,
                    current_debt: row.current_debt,
                    last_installment_date: row.last_installment_date,
                    redemption_amount: row.redemption_amount
                  };
                  delete editableRow.lessor;
                  delete editableRow.leasing_subject;
                  props.edit(editableRow, modalFormName);
                }}
                onRemove={() => {
                  props.remove('leasing_liabilities', row.id);
                }}>
                <div className="col-sm-3 p-2 br ">{row.lessor ? row.lessor.name : '-'}</div>
                <div className="col-sm-3 p-2 br ">{row.leasing_subject ? row.leasing_subject.name : '-'}</div>
                <div className="col-sm-2 p-2 br ">{parseMoney(row.current_debt)}</div>
                <div className="col-sm-2 p-2 br ">{row.last_installment_date}</div>
                <div className="col-sm-2 p-2 ">{parseMoney(row.redemption_amount)}</div>
              </RowEditable>
            ))}
          </div>
        </div>
      ) : null}

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Zobowiązania leasingowe</span>
          </div>
        }
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}>
        <LiabilitiesForm
          withButtons
          populate={() => props.populateForm(modalFormName)}
          forms={props.forms}
          proposalData={props.proposalData}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(modalFormName)}
          formName={modalFormName}
          submitForm={() => props.saveForm(modalFormName)}
          onCancelButtonClick={() => props.toggleForm(modalFormName)}
        />
      </BaseModal>
    </Card>
  );
};
