import React from 'react';

const BooleanCheck = ({ asRadioButton, onClick, className, field, disabled, style }) => {
  if(typeof field !== 'boolean') return '-';

  return asRadioButton ? (
    <i
      onClick={disabled ? undefined : onClick}
      style={{
        ...style,
        width: '16px',
        height: '16px',
        cursor: !disabled && typeof onClick !== 'undefined' ? 'pointer' : 'initial',
        color: (field && !disabled) ? 'rgb(29, 179, 147)' : 'rgb(153,161,153)'
      }}
      className={`${className} ${field ? 'fas fa-circle' : 'far fa-circle'}`}
    />
  ) : (
    <img
      alt="Checkbox"
      onClick={disabled ? undefined : onClick}
      className={className || ''}
      src={field ? (disabled ? '/checkbox-checked-disabled.svg' : '/checkbox-checked.svg') : '/checkbox-unchecked.svg'}
      style={{ ...style, width: '16px', height: '16px', cursor: (onClick && !disabled) ? 'pointer' : 'initial', fill: '#f00' }}
    />
  );
};

export default BooleanCheck;
