import { updateObject } from 'utilities/helpers';

const RESET = 'options/RESET';
const UPDATE = 'options/UPDATE';
const UPDATE_LIMIT = 'options/UPDATE_LIMIT';
const TOGGLE_LOADER = 'options/TOGGLE_LOADER';
const SET_CURRENT_ACTION = 'options/SET_CURRENT_ACTION';

const initialState = {
  limit: 20,
  isLoader: false,
  actionType: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET:
      return updateObject(state, initialState);
    case UPDATE_LIMIT:
      return updateObject(state, { limit: payload });
    case TOGGLE_LOADER:
      return updateObject(state, { isLoader: payload });
    case SET_CURRENT_ACTION:
      return updateObject(state, { actionType: payload });
    default:
      return state;
  }
};

const resetOptions = () => {
  return { type: RESET };
};

const updateOptions = data => {
  return { type: UPDATE, payload: data };
};

const updateLimit = value => {
  return { type: UPDATE_LIMIT, payload: value };
};

const toggleLoader = isVisible => {
  return { type: TOGGLE_LOADER, payload: isVisible };
};

const setCurrentAction = actionType => {
  return { type: SET_CURRENT_ACTION, payload: actionType };
};

export const OptionsActions = {
  resetOptions,
  updateOptions,
  updateLimit,
  toggleLoader,
  setCurrentAction
};
