import Tooltip from 'components/Layout/Tooltip';
import { OFFER_APPROVED_CALCULATION, OFFER_CLOSED, OFFER_NOT_APPROVED_CALCULATION, OFFER_OPEN } from 'containers/Offers/Details/Index';
import { t } from 'lang/pl';
import React from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import { OldCard } from '../../../../components/Layout';
import { isEnabledOnStatuses, parseMoney } from '../../../../utilities/helpers';

export const Header = props => {
  const offer = props.offer || {};
  const calculation = props.calculation || {};

  const header = (
    <span className="mb-0 font-weight-light" style={{ color: '#006BFF', fontSize: '32px' }}>
      Oferta {offer.identifier}
    </span>
  );

  const Participants = () =>
    Object.keys(offer).map(k => {
      let tip;

      if (!offer[k]) {
        return null;
      }

      switch (k) {
        case 'trade_supervisor':
          tip = `Opiekun handlowy: ${offer[k].full_name}`;
          break;
        case 'operational_administrator':
          tip = `Administrator operacyjny: ${offer[k].name}`;
          break;
        case 'offer_keeper':
          tip = `Opiekun oferty: ${offer[k].full_name}`;
          break;
        case 'client':
          tip = `Klient: ${offer[k].name}`;
          break;
        case 'billing_manager':
          tip = `Opiekun ds. rozliczeń: ${offer[k].full_name}`;
          break;
        case 'factor':
          tip = `Faktor: ${offer[k].name}`;
          break;
        default:
          return null;
      }

      return (
        <React.Fragment key={k}>
          <ReactTooltip id={k} />
          <img alt=""  src="/user.png" width="40" height="40" className="img-circle mr-2" data-tip={tip} data-for={k} />
        </React.Fragment>
      );
    });

  return (
    <OldCard header={header} contextMenu={() => toast.error(t.error.toast.notImplemented)}>
      <div className="row mb-1 mt-3">
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 col-md-4 text-right grey">Status oferty</div>
            <div className="col-sm-6 col-md-8">
              <span className="badge badge-warning mr-3 pt-1 pb-1 pl-2 pr-2">{offer.status ? 'zamknięta' : 'otwarta'}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-1 align-items-center">
        <div className="col-sm-6">
          <div className="row align-items-center">
            <div className="col-sm-6 col-md-4 text-right grey">Numer oferty</div>
            <div className="col-sm-6 col-md-8"><Tooltip label={offer.identifier} /></div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row align-items-center">
            <div className="col-sm-6 col-md-4 text-right grey">Uczestnicy</div>
            <div className="col-sm-6 col-md-8 d-flex align-items-center">
              <Participants />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 col-md-4 text-right grey">Utworzył</div>
            <div className="col-sm-6 col-md-8">{offer.user_creator ? offer.user_creator.full_name : 'brak'}</div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 col-md-4 text-right grey">Identyfikator oferty</div>
            <div className="col-sm-6 col-md-8"><Tooltip label={offer.identifier} /></div>
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 col-md-4 text-right grey">Kwota limitu</div>
            {/* <div className="col-sm-6 col-md-8">{offer.active_limit_amount || 'brak'}</div> */}
            <div className="col-sm-6 col-md-8">
              {`${parseMoney(calculation.limit_amount)} ${calculation.currency ? calculation.currency.name : '-'}`}
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 col-md-4 text-right grey">Utworzony dnia</div>
            <div className="col-sm-6 col-md-8">{offer.created_date}</div>
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 col-md-4 text-right grey">Klient</div>
            <div className="col-sm-6 col-md-8">
              <Tooltip label={offer?.client?.identifier} tip={offer?.client?.name} />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 col-md-4 text-right grey">Ostatnia aktualizacja</div>
            <div className="col-sm-6 col-md-8">{offer.modified_date}</div>
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 col-md-4 text-right grey">Faktor</div>
            <div className="col-sm-6 col-md-8">
              <Tooltip label={offer?.factor?.identifier} tip={offer?.factor?.name} />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 col-md-4 text-right grey">Numer wniosku</div>
            {/* TODO: check */}
            <div className="col-sm-6 col-md-8"><Tooltip label={offer.proposal_id} /></div>
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 col-md-4 text-right grey">Produkt</div>
            <div className="col-sm-6 col-md-8">{offer.product ? offer.product.name : 'brak'}</div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 col-md-4 text-right grey">Nr umowy</div>
            {/* TODO: check */}
            <div className="col-sm-6 col-md-8"><Tooltip label={offer.agreement_id} /></div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 col-md-4 text-right grey">Procedura</div>
            <div className="col-sm-6 col-md-8">{offer.procedure ? offer.procedure.name : 'brak'}</div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-12">
          <button id="btn-close-offer" type="button" className="mr-3 btn-green" onClick={() => props.close()}>
            {offer.status ? 'Otwórz ofertę' : 'Zamknij ofertę'}
          </button>
          <button
            disabled={isEnabledOnStatuses([OFFER_CLOSED], offer.status)}
            id="btn-create-proposal"
            type="button"
            className="mr-3 btn-green"
            onClick={props.createProposal}
          >
            Generuj wniosek
          </button>
          <button
            disabled={isEnabledOnStatuses([OFFER_OPEN], offer.status)}
            type="button"
            className="mr-3 btn-green"
            onClick={() => props.toggleForm('CalculationsForm')}
          >
            Nowa kalkulacja
          </button>
          <button
            disabled={calculation.status === OFFER_APPROVED_CALCULATION || isEnabledOnStatuses([OFFER_OPEN], offer.status)}
            id="btn-confirm-calculation"
            type="button"
            className="mr-3 btn-green"
            onClick={() => props.toggleForm('acceptance')}
          >
            Zatwierdź kalkulację
          </button>
          <button
            disabled={calculation.status === OFFER_NOT_APPROVED_CALCULATION}
            type="button"
            className="mr-3 btn-green"
            onClick={() => props.toggleForm('generatePdf')}
          >
            Generuj PDF
          </button>
        </div>
      </div>
    </OldCard>
  );
};
