import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { ConsentForm } from '../../Forms/Consent';

export const CONSENTS_CREATOR_FORM = 'ConsentsCreatorForm';

export const Consents = (props) => {
  const formName = CONSENTS_CREATOR_FORM;
  const modalFormName = 'ConsentsCreatorEditForm';

  const consents = props.person && props.person.consents ? props.person.consents.map((c) => ({ ...c, is_active: c.consent_logs[0].is_accepted })) : [];

  return (
    <Card mode='creator' header='+ Ochrona danych' onSaveClick={() => props.submitForm(formName)}>
      <ConsentForm
        sources={props.sources}
        templates={props.templates}
        administrators={props.administrators}
        representators={props.representators}
        inspectors={props.inspectors}
        form={props.forms[formName]}
        visibleOnMount
        clear={props.clear}
        visible={props.formVisible(formName)}
        formName={formName}
        person={props.person}
        toggleModal={props.toggleForm}
        isVisible={props.formVisible}
        buttonOnClick={() => props.clear(formName, 'date')}
      />

      {consents && consents.length ? (
        <>
          <div className="collapse show" id="zgody">
            <div className="row mt-3">
              <div className="col-sm-12">
                <div className="row bt grey">
                  <div className="col-sm-6 p-2 br">Rodzaj zgody</div>
                  <div className="col-sm-3 p-2 br">Źródło</div>
                  <div className="col-sm-3 p-2">Aktywna</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12  mb-4">
                {consents.map((c, i) => (
                  <RowEditable
                    index={i}
                    key={`${c.type}-${c.id}`}
                    itemId={c.id}
                    onCancel={c.is_active ? () => props.onConsentCancel(c) : null}
                  >
                    <div className="col-sm-6 p-2 br">{c.text}</div>
                    <div className="col-sm-3 p-2 br">
                      <span className="mr-2" style={{ color: '#878F9A' }}>
                        ID
                      </span>
                      <span>{c.id}</span>
                    </div>
                    <div className="col-sm-3 p-2">
                      <BooleanCheck field={c.is_active} />

                    </div>
                  </RowEditable>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : null}

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Ochrona danych</span>
          </div>
        )}
        footer="Aby zapisać zgodę wciśnij ZATWIERDŹ."
        onClickAway={() => props.toggleForm(modalFormName)}
      >
        <ConsentForm
          sources={props.sources}
          templates={props.templates}
          administrators={props.administrators}
          representators={props.representators}
          inspectors={props.inspectors}
          form={props.forms[modalFormName]}
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(modalFormName)}
          formName={modalFormName}
          person={props.person}
          withButtons
          submitForm={() => props.submitForm(modalFormName, true)}
          onCancelButtonClick={() => props.toggleForm(modalFormName)}
          toggleModal={props.toggleForm}
          isVisible={props.formVisible}
          buttonOnClick={() => props.clear(modalFormName, 'date')}
        />
      </BaseModal>
    </Card>
  );
};
