import React from 'react';

import { Form } from '../../../components/Forms';

export const AcceptInvoice = props => (
  <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
    <div className="row">
      {props.settling ? (
        <div className={'col-sm-12 p-2 '}>Czy chcesz rozliczyć zaznaczoną fakturę?</div>
      ) : (
        <div className="col-sm-12 p-2">
          Czy chcesz zmienić status {props.ids.length > 1 ? 'zaznaczonych faktur' : 'zaznaczonej faktury'} na {props.status}?
        </div>
      )}
    </div>
    {props.withButtons ? (
      <div className="row">
        <div className="mt-4 col-sm-12 text-center">
          <button
            type="button"
            onClick={() => props.toggleForm(props.formName)}
            className="btn btn-primary-outline mr-2 font-weight-light"
            style={{ color: '#4B4E55' }}>
            Anuluj
          </button>
          <button type="button" onClick={() => props.submitForm()} className="btn btn-primary btn-brown ml-2  font-weight-light">
            Zatwierdź
          </button>
        </div>
      </div>
    ) : null}
  </Form>
);
