export const BANK_ACCOUNTS = {
  TYPE: {
    ZALICZKOWY: 'zaliczkowy',
    OBCIAZENIOWY: 'obciazeniowy',
    PODSTAWOWY: 'podstawowy',
    UZNANIOWY: 'uznaniowy',
    WALUTOWY: 'walutowy'
  }
};

export const MODALS = {
  PERSON: {
    LIST_ADD_PERSON: { title: '+ Kreator dodawania osoby krok 1 z 2', hash: '#Kreator-dodawania-osoby-krok-1-z-2' }
  }
};
