import { OptionsActions } from 'ducks/options';
import { toast } from 'react-toastify';
import HttpClient from 'utilities/http';

import AgreementService from '../services/agreement';
import ProposalService from '../services/proposal';
import CommonActions from './common';
import FormActions from './forms';
import { AGREEMENT_UPDATE_ATTACHMENTS } from 'reducers/agreement';
import { FORM_SECURES_MANAGE } from 'containers/Agreements/Details/Components/Secures';

const fetchDetails = (id, loader = true, elementToFetch) => (dispatch, getState) => {
  dispatch(OptionsActions.toggleLoader(loader));

  if (loader) {
    dispatch({ type: 'CONTENT_LOADING' });
    dispatch({ type: 'CONTENT_PREVIEW_LOADING' });
  }

  AgreementService.fetchDetails(id).then(async response => {
    const { version } = getState().Agreement;
    const agreementVersions = response?.data?.agreement_versions;
    const proposal = response?.data?.proposal;

    const agreementVersion = elementToFetch ? agreementVersions[elementToFetch-1] : (agreementVersions.find(i => i.id === version?.id) || agreementVersions[0]) || {};

    const payload = response.data;

    Promise.all([
      AgreementService.fetchClient(payload.client?.id),
      AgreementService.fetchClientEmails(payload.client?.id),
      AgreementService.fetchFactor(payload.factor?.id)
    ]).then((values) => {
      payload.client = values[0];
      payload.client_emails = values[1];
      payload.factor = values[2];
    });

    if (payload.operational_administrator && payload.operational_administrator.id) {
      payload.operational_administrator = await AgreementService.fetchFactor(payload.operational_administrator.id);
    }

    const proposal_debtors = await AgreementService.fetchProposalDebtors(payload.proposal?.id, proposal?.approved_proposal_version);

    dispatch({ type: loader ? 'AGREEMENT_DETAILS_FETCHED' : 'AGREEMENT_DETAILS_UPDATED', payload });
    dispatch({ type: 'AGREEMENT_VERSION_FETCHED', payload: { ...agreementVersion, proposal_debtors } });
    dispatch({ type: 'CONTENT_LOADED' });

    if (loader) {
      dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
      dispatch(OptionsActions.toggleLoader(false));
    }
  });
};

const saveForm = (form, id, hide = false, elementToFetch, forceStopDataFetch) => (dispatch, getState) => {
  const agreement = { ...getState().Agreement?.agreement };
  const version = { ...getState().Agreement?.version };
  const data = {
    ...getState().Forms.forms[form],
    client: agreement?.client,
    factor: agreement?.factor,
    operational_administrator: agreement?.operational_administrator
  };

  dispatch(FormActions.clearErrors(form));

  return AgreementService.saveForm(form, data, id, version?.id)
    .then(response => {
      dispatch(CommonActions.toggleModal());

      if(!forceStopDataFetch) {
        dispatch(fetchDetails(id, true, elementToFetch));

        document.getElementsByTagName('html')[0].style = '';

        const formsToBeLeftFilled = ['DebtorsCreatorForm'];
        let clearForm = true;

        formsToBeLeftFilled.forEach(e => {
          if (form.indexOf(e) !== -1) {
            clearForm = false;
          }
        });

        if (clearForm) {
          dispatch(FormActions.clearForm(form));
        }

        if (hide) {
          dispatch(FormActions.toggleForm(form));
        }

        if (form === 'InsuranceClaimForm') {
          let claims = getState().Forms.forms?.SecuresManageForm?.insurance_limits ?? [];

          if (data.id) {
            claims = claims.filter(x => x.id !== data.id);
          }
          claims = claims.concat([response.data]);
          dispatch(FormActions.storeInput('SecuresManageForm', 'insurance_limits', claims));
        }

        if (form === 'GuarantorForm') {
          const guarantors = getState().Forms.forms?.SecuresManageForm?.guarantors ?? [];
          dispatch(FormActions.storeInput('SecuresManageForm', 'guarantors', guarantors.concat([response.data])));
        }
        toast.success('Zaktualizowano dane.');
      }
      return response;
    }).catch((errors) => dispatch(FormActions.handleErrors(form, errors?.response?.data)));
};

const fetch = filters => dispatch => {
  const f = filters || CommonActions.defaultFilters;
  AgreementService.fetch(f).then(response => {
    dispatch({ type: 'AGREEMENTS_FETCHED', payload: response ? response.data : [] });
    if (response?.data?.results?.length) {
      dispatch({ type: 'AGREEMENT_FOR_PREVIEW_SELECTED', payload: response.data?.results?.[0] });
    }
    dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
  });
};

export default {
  fetchClaimsForAgreement: filtersForm => (dispatch, getState) => {
    const { Agreement, Forms } = getState();
    const filters = Forms.forms[filtersForm] ?? {};

    AgreementService.fetchClaimsForAgreement(Agreement.agreement.id, filters)
      .then(r => {
        dispatch({ type: 'AGREEMENT_CLAIMS_FETCHED', payload: r.data });
      })
      .catch(() => {});
  },
  resetDocuments: () => () => {
    // const { Agreement, } = getState();
    // AgreementService.resetDocuments()
    //   .then(() => {
    //     toast.success('Dokumenty zostały zresetowane.');
    //     dispatch(fetchDetails(Agreement.Id));
    //   })
    //   .catch(e => {
    //     const msg = e?.response?.data;
    //     toast.error(msg.status);
    //   });
  },
  archive: (agreementId, versionId, type, secureId) => dispatch => {
    AgreementService.archive(agreementId, versionId, type, secureId)
      .then(() => {
        toast.success('Zarchiwizowano.');
        dispatch(fetchDetails(agreementId));
      })
      .catch(e => {
        const msg = e?.response?.data;
        toast.error(msg.status);
      });
  },
  generateHtml: id => () => {
    AgreementService.generateHtml(id).then(() => {});
  },
  handleFile: (formName, type, attachmentId, action, del = false, update = false, idGeneratedDocuments) => (dispatch, getState) => {
    const { Agreement, Forms } = getState();
    const agreementId = Agreement.agreement.id;
    const versionId = Agreement.version.id;
    const obj = Forms.forms?.[formName];

    AgreementService.handleFile(agreementId, versionId, type, idGeneratedDocuments ?? obj.id, attachmentId, action, del)
      .then(r => {
        const newAttachments = obj.attachments.map(a => {
          if (a.id === attachmentId) {
            return r.data;
          } else {
            return a;
          }
        });

        dispatch(FormActions.storeInput(formName, 'attachments', newAttachments));
        toast.success(`Dokument został ${del ? 'usunięty' : action === 'generate' ? 'wygenerowany' : 'zarejestrowany'}.`);

        if (update) {
          dispatch(fetchDetails(agreementId));
        }
      }).catch((errors) => dispatch(FormActions.handleErrors(formName, errors?.response?.data)));
  },
  removeAgreement: () => () => {
    toast.error('Nie zaimplementowano.');
    // AgreementService.removeAgreement(id).then(() => {
    //   dispatch(fetch());
    //   toast.success('Usunięto dane.');
    // });
  },
  preview: agreement => dispatch => dispatch({ type: 'AGREEMENT_FOR_PREVIEW_SELECTED', payload: agreement }),
  fetch,
  fetchForForm: (resource, filtersFormName) => (dispatch, getState) => {
    const filters = getState().Forms.forms[filtersFormName];
    AgreementService.fetchForForm(resource, filters).then(response => {
      dispatch({ type: 'AGREEMENT_FORMDATA_FETCHED', payload: { data: response.data, resource } });
    });
  },
  fetchDetails,
  changeTab: tab => dispatch =>
    dispatch({
      type: 'AGREEMENT_TAB_CHANGED',
      payload: tab
    }),
  create: (formName, push) => (dispatch, getState) => {
    const formData = getState().Forms.forms[formName];

    const data = {
      proposal_id: formData.proposal?.id,
      limit_amount: formData.limit_amount,
      currency_id: formData.currency_id,
      client_id: formData.client?.id,
      client_representation_id: formData.client_representation_id,
      client_people_ids: formData.client_people ? formData.client_people.map(i => i.id) : [],
      factor_id: formData.factor?.id,
      factor_representation_id: formData.factor_representation_id,
      factor_people_ids: formData.factor_people ? formData.factor_people.map(i => i.id) : [],
      operational_administrator_id: formData.operational_administrator?.id,
      oa_representation_id: formData.oa_representation_id,
      oa_people_ids: formData.oa_people ? formData.oa_people.map(i => i.id) : [],
      trade_supervisor_id: formData.trade_supervisor?.id,
      billing_manager_id: formData.billing_manager?.id,
      vindicator_id: formData.vindicator?.id
    };

    AgreementService.create(data)
      .then(async response => {
        dispatch(FormActions.clearForm(formName));
        dispatch(FormActions.toggleForm(formName));

        dispatch(fetchDetails(response.data.id));
        dispatch({ type: 'AGREEMENT_TAB_CHANGED', payload: 1 });

        push(`/umowy/${response.data.slug}/kreator`);
      }).catch((errors) => dispatch(FormActions.handleErrors(formName, errors?.response?.data)));
  },
  saveForm,
  saveDocuments: ({ formName, id }) => async (dispatch, getState) => {
    const agreement = { ...getState().Agreement?.agreement };
    const version = { ...getState().Agreement?.version };
    const formData = {
      ...getState().Forms.forms[formName],
      client: agreement?.client,
      factor: agreement?.factor,
      operational_administrator: agreement?.operational_administrator
    };

    dispatch(FormActions.clearErrors(formName));

    AgreementService.saveForm(formName, formData, id, version?.id)
      .then(response => {
        const newAttachments = response?.data?.attachments;
        // dispatch({ type: AGREEMENT_UPDATE_ATTACHMENTS, payload: newAttachments });
        dispatch(FormActions.storeInput(FORM_SECURES_MANAGE, 'attachments', newAttachments));
        toast.success('Dokumenty zostały zaktualizowane.');
      })
      .catch((errors) => dispatch(FormActions.handleErrors(formName, errors?.response?.data)));
  },
  saveDebtors: (form, id, hide) => async (dispatch, getState) => {
    const debtorsInAgreement = getState().Agreement.version?.agreement_debtors ?? [];
    const newDebtors = getState().Forms.forms?.[form]?.debtors ?? [];

    if (debtorsInAgreement.length > 0) {
      Promise.all(
        debtorsInAgreement.map(d => {
          if (newDebtors.find(x => x.proposal_debtor_id === d.id)) {
            return null;
          }
          return AgreementService.remove(id, 'debtor', d.id, getState().Agreement?.version?.id);
        })
      ).then(() => dispatch(saveForm(form, id, hide)));
    } else {
      dispatch(saveForm(form, id, hide));
    }
  },
  fetchFactors: () => dispatch => {
    AgreementService.fetchForForm('factors')
      .then(res => {
        dispatch({
          type: 'AGREEMENT_FACTORS_FETCHED',
          payload: res.data.results
        });
      });
  },
  remove: (id, uri, objectId) => (dispatch, getState) => {
    const { Agreement, Forms } = getState();

    AgreementService.remove(id, uri, objectId, Agreement?.version?.id).then(() => {
      dispatch(CommonActions.toggleModal());
      dispatch(fetchDetails(id));

      if (uri.indexOf('attachments') !== -1) {
        const formname = uri.indexOf('secure') !== -1 ? 'SecuresManageForm' : 'DocumentsManageForm';
        const newAttachments = Forms.forms[formname]?.attachments?.filter(a => a.id !== objectId);
        dispatch(FormActions.storeInput(formname, 'attachments', newAttachments));
      }

      document.getElementsByTagName('html')[0].style = '';
      toast.success('Usunięto dane.');
    }).catch(e => {
      const msg = Object.values(e?.response?.data)?.[0] ?? 'Generowanie nie powiodło się.';
      toast.error(msg);
    });
  },
  removeNote: (agreementId, noteId) => dispatch => HttpClient.delete(`/agreements/${agreementId}/notes/${noteId}/`).then(() => dispatch(fetchDetails(agreementId))),
  removeVersion: (id, uri, objectId) => dispatch =>
    AgreementService.remove(id, uri, objectId, null).then(() => {
      dispatch(CommonActions.toggleModal());
      dispatch(fetchDetails(id));

      document.getElementsByTagName('html')[0].style = '';
      toast.success('Usunięto dane.');
    }),
  selectVersion: payload => dispatch => dispatch({ type: 'AGREEMENT_VERSION_FETCHED', payload }),
  fetchGuarantors: () => (dispatch, getState) => {
    ProposalService.fetchForForm('guarantors', getState().Forms?.forms?.guarantor_filters)
      .then(res => {
        dispatch({
          type: 'AGREEMENT_GUARANTORS_FETCHED',
          payload: res.data
        });
      }).catch((errors) => dispatch(FormActions.handleErrors('guarantor_filters', errors?.response?.data)));
  },
  fetchDebtorsDict: () => (dispatch, getState) => {
    ProposalService.fetchForForm('debtors', getState().Forms?.forms?.guarantor_filters)
      .then(res => {
        dispatch({
          type: 'AGREEMENT_DEBTORS_DICT_FETCHED',
          payload: res.data
        });
      }).catch((errors) => dispatch(FormActions.handleErrors('guarantor_filters', errors?.response?.data)));
  },
  generateRaport: id => () => {
    AgreementService.generateRaport(id)
      .then(r => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(r.data);
        // link.href = window.URL.createObjectURL(new Blob([r.data]));
        link.download = `Raport_do_umowy_${id}.pdf`;
        link.click();
      })
      .catch(e => {
        const msg = e?.response?.data?.agreement;
        toast.error(msg ?? 'Generowanie raportu nie powiodło się.');
      });
  },
  accept: (agreementId, versionId) => dispatch =>
    AgreementService.accept(agreementId, versionId)
      .then(() => {
        toast.success('Wersja umowy została zatwierdzona.');
        dispatch(fetchDetails(agreementId));
      })
      .catch(() => toast.error('Zatwierdzanie wersji umowy nie powiodło się.')),
  approve: id => dispatch =>
    AgreementService.approve(id)
      .then(() => {
        toast.success('Status został zmieniony.');
        dispatch(fetchDetails(id));
      })
      .catch(() => toast.error('Zmiana statusu nie powiodła się.')),
  active: id => dispatch =>
    AgreementService.active(id)
      .then(() => {
        toast.success('Status został zmieniony.');
        dispatch(fetchDetails(id));
      })
      .catch(() => toast.error('Zmiana statusu nie powiodła się.')),
  annex: id => dispatch =>
    AgreementService.annex(id)
      .then(() => {
        toast.success('Status został zmieniony.');
        dispatch(fetchDetails(id));
      })
      .catch(() => toast.error('Zmiana statusu nie powiodła się.')),
  cancel: id => dispatch =>
    AgreementService.cancel(id)
      .then(() => {
        toast.success('Status został zmieniony.');
        dispatch(fetchDetails(id));
      })
      .catch(() => toast.error('Zmiana statusu nie powiodła się.')),
  recover: id => dispatch =>
    AgreementService.recover(id)
      .then(() => {
        toast.success('Status został zmieniony.');
        dispatch(fetchDetails(id));
      })
      .catch(() => toast.error('Zmiana statusu nie powiodła się.')),
  restructure: id => dispatch =>
    AgreementService.restructure(id)
      .then(() => {
        toast.success('Status został zmieniony.');
        dispatch(fetchDetails(id));
      })
      .catch(() => toast.error('Zmiana statusu nie powiodła się.')),

  //TODO: cleanup with endpoints
  updateStatusRestructure: id => dispatch => HttpClient.patch(`/agreements/${id}/restructure/`).then(() => {
    toast.success('Status został zmieniony.');
    dispatch(fetchDetails(id));
  }),
  updateStatusConclude: id => dispatch => HttpClient.patch(`/agreements/${id}/conclude/`).then(() => {
    toast.success('Status został zmieniony.');
    dispatch(fetchDetails(id));
  }),
  updateStatusToEdit: id => dispatch => HttpClient.patch(`/agreements/${id}/to_edit/`).then(() => {
    toast.success('Status został zmieniony.');
    dispatch(fetchDetails(id));
  }),
  addAgreementDebtors: (idAgreement, idVersion, debtors) => dispatch => {
    HttpClient.post(`/agreements/${idAgreement}/agreement_versions/${idVersion}/debtor/`, { debtors }).then(() => {
      dispatch(fetchDetails(idAgreement));
      toast.success('Zaktualizowano dłużników.');
    });
  },
  removeAgreementDebtor: (idAgreement, idVersion, idDebtor) => dispatch => {
    AgreementService.remove(idAgreement, 'debtor', idDebtor, idVersion).then(() => {
      dispatch(fetchDetails(idAgreement));
      toast.success('Usunięto dłużnika z umowy.');
    });
  },
  removeAgreementDebtors: (idAgreement, version, debtors) => dispatch => {
    Promise.all(
      version?.agreement_debtors?.map(debtor => {
        if (debtors?.find(newDebtor => newDebtor.proposal_debtor_id === debtor.id)) {
          return null;
        }
        return AgreementService.remove(idAgreement, 'debtor', debtor?.id, version?.id);
      })
    ).then(() => {
      dispatch(fetchDetails(idAgreement));
      toast.success('Usunięto dane.');
    });
  },
  addAnnex: (form, id) => (dispatch) => {
    dispatch(saveForm(form, id, true, 1));
  }
};
