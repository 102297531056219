import axios from 'axios';
import * as R from 'ramda';
import { toast } from 'react-toastify';

export const SF_ACCESS_TOKEN = 'sf_access_token';
export const SF_REFRESH_TOKEN = 'sf_refresh_token';

const HttpClient = axios.create();

HttpClient.defaults.baseURL = process.env.REACT_APP_BACKEND_URI;

HttpClient.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${localStorage.getItem(SF_ACCESS_TOKEN)}`
  }
}));

const refreshToken = () => {
  const rt = localStorage.getItem(SF_REFRESH_TOKEN);

  if (rt) {
    const params = {
      refresh_token: rt,
      grant_type: 'refresh_token',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET
    };

    const request = new URLSearchParams();

    Object.keys(params).forEach(
      (key) => request.set(key, params[key])
    );

    return HttpClient.post('/o/token/', request);
  }

  return Promise.reject();
};

HttpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          handleError400(error);
          return Promise.reject(error);
        case 401:
          return refreshToken()
            .then(() => {
              const method = error.config.method ? error.config.method.toLowerCase() : 'get';
              return HttpClient[method](error.config.url, error.config.data);
            })
            .catch(() => {
              localStorage.removeItem(SF_ACCESS_TOKEN);
              localStorage.removeItem('state');
              window.location.href = '/';
              return Promise.reject(error);
            });
        case 404:
          // throw new Error('404 Not Found');
          // window.location.href = '/';
          toast.error('Nie znaleziono strony.');
          return Promise.reject(error);
        case 500:
          toast.error('Błąd serwera.');
          return Promise.reject(error);
        case 502:
          toast.error('Błąd sieci.');
          return Promise.reject(error);
        default:
          return Promise.reject(error);
      }
    }

    throw error;
  }
);

const handleError400 = error => {
  const statusErrors = error.response?.data?.status_errors;
  const statusNonFieldErrors = error.response?.data?.non_field_errors;
  toast.dismiss();

  if(statusErrors) {
    toast.info('Wyczyść wszystko.', {
      position: 'bottom-right',
      autoClose: false,
      hideProgressBar: true,
      onClick: () => toast.dismiss(),
      draggable: true
    });
    Object.values(statusErrors).map(errors => errors.map(error => toast.error(error, {
      position: 'bottom-right',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true
    })));
  }
  statusNonFieldErrors && statusNonFieldErrors.map(error => toast.error(error));
};

export const getParsedCompanyContacts = data => {
  const companyContacts = [];

  if(data.email) {
    companyContacts.push({
      type: 'email',
      is_active: true,
      value: data.email
    });
  }
  if(data.phone) {
    companyContacts.push({
      type: 'phone',
      is_active: true,
      value: data.phone,
      country_calling_code_id: data.country_calling_code_id
    });
  }

  return companyContacts;
};

export const getParsedObject = data => {
  const parsedObject = {};

  if(data.business_company_ids) {
    const filteredData = R.filter(x => x, data.business_company_ids);
    Object.assign(parsedObject, {
      business_company_ids: filteredData
    });
  }
  if(data.roles_ids) {
    const filteredData = R.filter(x => x, data.roles_ids);
    Object.assign(parsedObject, {
      roles_ids: filteredData
    });
  }
  if(data.country_calling_code_id) {
    Object.assign(parsedObject, {
      country_calling_code_id: data.country_calling_code_id
    });
  }
  if(data.person_id) {
    Object.assign(parsedObject, {
      person_id: data.person_id
    });
  }
  return parsedObject;
};

export default HttpClient;
