import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React from 'react';
import { useSelector } from 'react-redux';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import { ProtectionsForm } from '../../Forms/Protections';

export const Protections = (props) => {
  const { ProtectionsCreatorForm: form } = useSelector(state => state.Forms.forms);

  const formName = 'ProtectionsCreatorForm';
  const modalFormName = 'ProtectionsCreatorEditForm';
  const calculationId = props.calculation ? props.calculation.id : null;

  const docs = props.calculation && props.calculation.documents_to_secure ? props.calculation.documents_to_secure : [];

  return (
    <Card mode='creator' header='+ Zabezpieczenia' onSaveClick={() => props.saveForm(formName)} isBlocked={!form?.dts_type}>
      <div className="col align-items-center pr-5 pt-3">
        <ProtectionsForm
          calculationId={calculationId}
          // companySelected={companySelected}
          filtersForm={props.filtersForm}
          offer={props.offer}
          protectionSelected={props.forms[formName] ? props.forms[formName].dts_type : null}
          documents={props.documents}
          expectedForms={props.expectedForms}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(formName)}
          formVisible={props.formVisible}
          formName={formName}
          submitForm={() => props.saveForm(formName)}
          onCancel={() => props.onCancel(formName)}
          errors={props.errors[formName]}
          forms={props.forms}
          toggleForm={props.toggleForm}
          fetch={() => {}}
        />
      </div>
      <div className="collapse show" key="role" id="role">
        <div className="row">
          <div className="col-sm-12 mt-2">
            <div className="row bb bt grey">
              <div className="col-sm-3 p-2 br">Rodzaj zabezpieczenia</div>
              <div className="col-sm-3 p-2">Oczekiwana forma dokumentu</div>
            </div>
          </div>
          <div className="col-sm-12">
            {docs.map((doc) => (
              <RowEditable
                itemId={doc.id}
                onRemove={() => {
                  props.remove(doc);
                }}
              >
                <div className="col-sm-3 p-2 br">{doc.dts_type ? doc.dts_type.name : '-'}</div>
                <div className="col-sm-3 p-2">{doc.dts_form ? doc.dts_form.name : '-'}</div>
              </RowEditable>
            ))}
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Zabezpieczenia</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}
      >
        <ProtectionsForm
          // companySelected={companySelected}
          filtersForm={props.filtersForm}
          offer={props.offer}
          protectionSelected={props.forms[formName] ? props.forms[formName].dts_type : null}
          documents={props.documents}
          expectedForms={props.expectedForms}
          preloadedForm
          visibleOnMount={false}
          clear={props.clear}
          visible={props.formVisible(formName)}
          formVisible={props.formVisible}
          formName={formName}
          submitForm={() => props.saveForm(formName)}
          onCancel={() => props.onCancel(formName)}
          withButtons
          errors={props.errors[formName]}
          forms={props.forms}
          toggleForm={props.toggleForm}
          fetch={() => {}}
        />
      </BaseModal>
    </Card>
  );
};
