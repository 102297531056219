import React from 'react';

const style = {
  cursor: 'pointer',
  fontSize: '90%'
};

export default function OrderBy({ className, isOrdering, column, onClick }) {
  return (
    <div className={className ?? 'col-sm-2 p-2 br'} onClick={onClick}>
      <span style={style} className={isOrdering ? 'label-right ml-0' : 'grey font-weight-light ml-0'}>
        <i className="fas fa-sort mr-1" />
        {column}
      </span>
    </div>
  );
}
