import React from 'react';

import ClientAccounts from '../Components/ClientAccounts';
import DebtorsAccounts from '../Components/DebtorsAccounts';
import FactorAccounts from '../Components/FactorAccounts';

export default (props) => (
  <>
    <ClientAccounts
      remove={props.remove}
      saveForm={props.saveForm}
      version={props.version}
      agreement={props.agreement}
      formVisible={props.formVisible}
      toggleForm={props.toggleForm}
    />
    <FactorAccounts
      remove={props.remove}
      saveForm={props.saveForm}
      version={props.version}
      agreement={props.agreement}
      formVisible={props.formVisible}
      toggleForm={props.toggleForm}
    />
    <DebtorsAccounts
      remove={props.remove}
      saveForm={props.saveForm}
      version={props.version}
      agreement={props.agreement}
      formVisible={props.formVisible}
      toggleForm={props.toggleForm}
    />
  </>
);
