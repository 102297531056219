import { BaseModal } from 'components/Layout/OldModal';
import React from 'react';

import { OldCard } from '../../../../components/Layout';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { PeopleForm } from '../../Forms/People';
import Card from 'components/Layout/Card';

export const People = (props) => {
  const people = props.company && props.company.persons ? props.company.persons : [];

  const formName = 'PeopleCreatorForm';

  return (
    <Card mode='creator' header='+ Osoby powiązane' onSaveClick={() => props.addPerson(formName)}>
      <PeopleForm
        visible
        visibleOnMount
        formName={formName}
        formVisible={(f) => props.formVisible(f)}
        submitForm={() => props.addPerson(formName)}
        onCancelButtonClick={() => props.toggleForm(formName)}
        voivodeships={props.voivodeships}
        people={props.people}
        fetchPeople={props.fetchPeople}
        filtersForm={props.filtersForm}
        company={props.company}
        selectPerson={props.selectPerson}
        roles={props.roles}
        toggleForm={props.toggleForm}
        forms={props.forms}
        selectedPerson={props.forms[formName] ? props.forms[formName].person : null}
      />

      <div className="collapse show" id="osoby-div">
        {people.length > 0 ? (
          <div className="row bb">
            <div className="col-sm-3 grey p-2 br">Imię i nazwisko</div>
            <div className="col-sm-3 grey p-2 br">Rola biznesowa</div>
            <div className="col-sm-3 grey p-2 br">Powiązane od</div>
            <div className="col-sm-3 grey p-2">Powiązał</div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 mt-3 mb-15">
              <p>Brak danych.</p>
            </div>
          </div>
        )}

        {people.map((p) => (
          <RowEditable itemId={p.id} onRemove={() => props.detachPerson(p.id)}>
            <div className="col-sm-3 p-2 br">
              {p.person ? p.person.full_name : 'brak'}
            </div>
            <div className="col-sm-3 p-2 br">{p.roles.map((r) => r.name).join(', ')}</div>
            <div className="col-sm-3 p-2 br">{p.created_date}</div>
            <div className="col-sm-3 p-2">{p.user_creator ? p.user_creator.full_name : 'brak'}</div>
          </RowEditable>
        ))}
      </div>

      {/*<BaseModal*/}
      {/*  visible={props.formVisible(formName)}*/}
      {/*  header={(*/}
      {/*    <div className="d-flex align-items-center">*/}
      {/*      <i className="fas fa-pencil-alt mr-2" />*/}
      {/*      {' '}*/}
      {/*      <span>Osoby powiązane</span>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*  footer="Aby zakończyć edycję wciśnij Zapisz."*/}
      {/*  onClickAway={() => props.toggleForm(formName)}*/}
      {/*>*/}

      {/*</BaseModal>*/}
    </Card>
  );
};
