import 'twin.macro';

import ClaimActions from 'actions/claim';
import BooleanCheck from 'components/BooleanCheck';
import { Buttons, Datepicker, Form, Textarea } from 'components/Forms';
import Required from 'components/Forms/Required';
import { OldCard } from 'components/Layout';
import { BaseModal } from 'components/Layout/OldModal';
import Tooltip from 'components/Layout/Tooltip';
import { getForm } from 'containers/Claims/Creator/Components/Advance';
import { STATUS_CLAIMS, STATUS_DUE_CLAIMS } from 'containers/Claims/Details/Index';
import DueStatus from 'containers/Claims/Details/Modals/DueStatus';
import GenerateDue from 'containers/Claims/Details/Modals/GenerateDue';
import GenerateExpense from 'containers/Claims/Details/Modals/GenerateExpense';
import { AdvanceForm } from 'containers/Claims/Forms/Advance';
import { OtherExpenseForm } from 'containers/Claims/Forms/OtherExpense';
import RemovalModal from 'containers/Offers/Forms/RemovalModal';
import useModals from 'hooks/useModals';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { parseMoney } from 'utilities/helpers';

const Header = props => {
  const { instance, handleOpenModal, handleCloseModal } = useModals();

  const formName = 'ClaimChangeStatus';
  const checkFormName = 'ClaimValidateForm';
  const generateAdvanceForm = 'GenerateAdvanceForm';
  const otherExpenseForm = 'OtherExpenseForm';
  const finalPaymentForm = 'FinalPaymentForm';

  const { claim } = props;
  const [type, setType] = useState(0);
  const [removing, setRemoving] = useState(false);
  const dispatch = useDispatch();

  const populateTasks = () =>
    props.populateForm(checkFormName, {
      corr_proc_comment: claim?.corr_proc?.corr_proc_comment,
      tasks: claim?.corr_proc?.tasks
    });

  const isTaskChecked = taskId => props.forms?.[checkFormName]?.tasks?.find(x => x.id === taskId)?.status ?? false;

  const checkTask = (task, key, overrideAsTrue = false) => {
    props.storeInput(checkFormName, `tasks.${key}.status`, overrideAsTrue || !isTaskChecked(task.id));
  };

  const changeStatus = t => {
    setType(t);
    props.toggleForm(formName);
  };

  const handleChangeStatus = status => () => {
    dispatch(ClaimActions.changeStatus({ id: claim.id, status }));
  };

  const header = (
    <span className="mb-0 font-weight-light" style={{ color: '#006BFF', fontSize: '32px' }}>
      Wierzytelność {claim.identifier}
    </span>
  );

  const Participants = () =>
    Object.keys(claim).map(k => {
      let tip;

      if (!claim[k]) {
        return null;
      }

      switch (k) {
        case 'trade_supervisor':
          tip = `Opiekun handlowy: ${claim[k].full_name}`;
          break;
        case 'operational_administrator':
          tip = `Administrator operacyjny: ${claim[k].name}`;
          break;
        case 'claim_keeper':
          tip = `Opiekun oferty: ${claim[k].full_name}`;
          break;
        case 'client':
          tip = `Klient: ${claim[k].name}`;
          break;
        case 'billing_manager':
          tip = `Opiekun ds. rozliczeń: ${claim[k].full_name}`;
          break;
        case 'factor':
          tip = `Faktor: ${claim[k].name}`;
          break;
        default:
          return null;
      }

      return (
        <div style={{ display: 'inline' }} key={k}>
          <ReactTooltip id={k} />
          <img alt="" src="/user.png" width="25" height="25" className="img-circle mr-2" data-tip={tip} data-for={k} />
        </div>
      );
    });

  return (
    <OldCard
      header={header}
      contextMenu={() => {
        props.changeTab(2);
        setTimeout(() => {
          // eslint-disable-next-line no-unused-expressions
          document?.getElementById('claim-edit')?.click();
        }, 500);
        return 1;
      }}>
      <div className="row mb-1 mt-3">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Status wierzytelności</div>
            <div className="col-sm-6">
              <span className="badge badge-warning pt-1 pb-1 pl-1 pr-1">{props.common?.claimData?.claim_statuses?.[claim?.status]}</span>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-4  text-right grey">Nr dokumentu</div>
            <div className="col-sm-8">{claim?.document_no || '-'}</div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6  text-right grey">Uczestnicy</div>
            <div className="col-sm-6  d-flex align-items-center">
              <Participants />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6  text-right grey">Data wykupu</div>
            <div className="col-sm-6 ">{claim?.purchase_date}</div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-4  text-right grey">Nr umowy</div>
            <div className="col-sm-8"><Tooltip label={claim?.agreement?.identifier} /></div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6  text-right grey">Utworzył</div>
            <div className="col-sm-6 ">{claim.user_creator?.full_name || '-'}</div>
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6  text-right grey">Wartość dokumentu</div>
            <div className="col-sm-6 ">
              {parseMoney(claim.gross_value)} {claim.gross_value_currency?.name}
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row mt-1">
            <div className="col-sm-4 text-right grey">Klient</div>
            <div className="col-sm-4">
              <Tooltip id='claim-client-identifier' tip={claim.client?.name} label={claim.client?.identifier} />
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6  text-right grey">Utworzony dnia</div>
            <div className="col-sm-6 ">{claim.created_date || '-'}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6  text-right grey">Kwota wykupu</div>
            <div className="col-sm-6 ">
              {parseMoney(claim.purchase_gross_value ?? claim.purchase_custom_value)}{' '}
              {claim.purchase_custom_value_currency?.name || claim.purchase_currency?.name}
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row mb-1">
            <div className="col-sm-4 text-right grey">Dłużnik</div>
            <div className="col-sm-4">
              <Tooltip id='claim-debtor-identifier' tip={claim.debtor?.name} label={claim.debtor?.identifier} />
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6  text-right grey">Ostatnia aktualizacja</div>
            <div className="col-sm-6 ">{claim.modified_date || '-'}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Status wymagalności</div>
            <div className="col-sm-6">
              <span className="badge badge-warning pt-1 pb-1 pl-1 pr-1">{props.common?.claimData?.due_claim_statuses?.[claim?.due_status]}</span>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-4 text-right grey f90 justify-content-end">Produkt/Procedura</div>
            <div className="col-sm-7">
              {claim.agreement?.product?.name || '-'} / {claim.agreement?.procedure?.name || '-'}
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="row">
            <div className="col-sm-6 text-right grey">Odstępstwo / Regres</div>
            <div className="col-sm-6 ">{claim?.isDeviated ? 'Tak' : 'Nie'} / {claim?.recourse ? 'Tak' : 'Nie'}</div>
          </div>
        </div>
      </div>
      <div className="row mt-4 mb-3">
        <div className="col-md-6 col-sm-12 d-flex justify-content-start">
          <div className='flex flex-col'>
            <div>
              <button disabled={claim.status > STATUS_CLAIMS.UTWORZONA} type="button" className="h-24 mr-1 btn-green" onClick={handleChangeStatus(STATUS_CLAIMS.DO_ZATWIERDZENIA)}>
                Do zatwierdzenia
              </button>
              <button disabled={claim.status > STATUS_CLAIMS.DO_ZATWIERDZENIA} type="button" className="h-24 mr-1 btn-green" onClick={() => changeStatus(1)}>
                Zatwierdź
              </button>
              <button disabled={claim.status !== STATUS_CLAIMS.DO_ZATWIERDZENIA && claim.status !== STATUS_CLAIMS.ZATWIERDZONA && claim.status !== STATUS_CLAIMS.ODRZUCONA && claim.status !== STATUS_CLAIMS.AKCEPT} type="button" className="h-24 mr-1 btn-green" onClick={() => changeStatus(3)}>
                Do edycji
              </button>
              <button disabled={claim.status !== STATUS_CLAIMS.ZATWIERDZONA_ODSTEPSTWO} type="button" className="h-24 mr-1 btn-green" onClick={handleChangeStatus(STATUS_CLAIMS.AKCEPT)}>
                Akceptuj
              </button>
              <button disabled={claim.status !== STATUS_CLAIMS.ZATWIERDZONA_ODSTEPSTWO} type="button" className="h-24 mr-1 btn-green" onClick={handleChangeStatus(STATUS_CLAIMS.ODRZUCONA)}>
                Odrzuć
              </button>
              <button disabled={claim.status !== STATUS_CLAIMS.ROZLICZONA} type="button" className="h-24 mr-1 btn-green" onClick={handleChangeStatus(STATUS_CLAIMS.ZAKSIEGOWANA)}>
                Księguj
              </button>
              <button disabled={claim.status !== STATUS_CLAIMS.UTWORZONA && claim.status !== STATUS_CLAIMS.ZATWIERDZONA && claim.status !== STATUS_CLAIMS.AKCEPT} type="button" className="h-24 mr-1 btn-green" onClick={handleChangeStatus(STATUS_CLAIMS.ANULOWANA)}>
                Anuluj
              </button>
              <button disabled={claim.due_status < STATUS_DUE_CLAIMS.WEZWANIE} type="button" className="h-24 mr-1 ml-3 btn-green" onClick={handleOpenModal(DueStatus.name)}>
                SW
              </button>
            </div>
            <div className='my-10'>
              <button disabled={claim.status >= 7} type="button" className="h-24 btn-green mr-1" onClick={() => setRemoving(claim.id)}>
                Usuń<Required/>
              </button>
              <button type="button" className="btn-green h-24 mr-1" onClick={() => changeStatus(2)}>
                Akceptuj odstępstwa<Required/>
              </button>
              <button
                type="button"
                disabled={claim.status >= 2}
                className="h-24 btn-green mr-1"
                onClick={() => {
                  populateTasks();
                  props.toggleForm(checkFormName);
                }}>
                Sprawdzenie procedury<Required/>
              </button>
              <button type="button" className="btn-green h-24 mr-1" disabled onClick={() => {}}>
                Odstępstwo<Required/>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 d-flex justify-content-end">
          <button
            disabled={!claim.can_execute_advance_payment}
            type="button"
            className="h-40 mr-1 btn-green"
            onClick={() => props.toggleForm(generateAdvanceForm)}>
            Generuj zaliczkę
          </button>
          <button type="button" className="h-40 btn-green mr-1" disabled={!claim.can_execute_additional_salary} onClick={() => {}}>
            Generuj wyn. dodatkowe
          </button>
          <button
            type="button"
            className="h-40 mr-1 btn-green"
            disabled={!claim.can_execute_final_payment}
            onClick={() => props.toggleForm(finalPaymentForm)}>
            Generuj pł. końcową
          </button>
          <button type="button" className="h-40 btn-green mr-1" disabled onClick={() => {}}>
            Generuj prowizję
          </button>
          <button type="button" className="h-40 btn-green mr-1" onClick={handleOpenModal(GenerateDue.name)}>
            Generuj należność
          </button>
          <button type="button" className="h-40 btn-green mr-1" onClick={handleOpenModal(GenerateExpense.name)}>
            Generuj wydatek
          </button>
          <button type="button" className="h-40 btn-green mr-1" disabled onClick={() => props.toggleForm(otherExpenseForm)}>
            Generuj rozl. wydatku
          </button>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        headerText="Zmiana statusu"
        footer="Aby zapisać wciśnij Dalej."
        onClickAway={() => props.toggleForm(formName)}>
        <Form className="form-horizontal" formName={formName}>
          <div className="row mt-3">
            <div className="col-sm-3">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Wierzytelność
                </label>
                <div className="col-sm-6">Test</div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
                  Kierunek
                </label>
                <div className="col-sm-9">
                  {type === 1 && 'Utworzona -> Zatwierdzona'}
                  {type === 2 && 'Do zatwierdzenia -> Zatwierdzono odstępstwa'}
                  {type === 3 && 'Do zatwierdzenia -> Utworzona'}
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Data zawarcia
                </label>
                <div className="col-sm-6">
                  <Datepicker formName={formName} name="oa_lump_sum_amount" />
                </div>
              </div>
            </div>
          </div>
          <div className="row bt bb mt-3">
            <div className="col-sm-3 p-2 grey br">Aktor</div>
            <div className="col-sm-3 p-2 grey br">Komunikat</div>
            <div className="col-sm-3 p-2 grey br">Wartość</div>
            <div className="col-sm-3 p-2 grey">Czy odstępstwo</div>
          </div>
          <div className="row mt-4  pl-5 pr-5">
            <div className="col-sm-12">
              <Textarea formName={formName} name="corr_proc_comment" placeholder="Komentarz..." />
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
                  Zatwierdził
                </label>
                <div className="col-sm-8">-</div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Zaakceptował odstępstwa
                </label>
                <div className="col-sm-6">-</div>
              </div>
            </div>
          </div>

          <Buttons
            formName={formName}
            onCancel={() => props.toggleForm(formName)}
            onSubmit={() => {
              props.storeInput(formName, 'tasks', claim?.corr_proc?.tasks);
              // OLD: props.storeInput(formName, 'submit_type', 'approve');
              props.storeInput(formName, 'submit_type', type === 1 ? 'approve' : type === 2 ? 'approve' : 'revoke_approval');
              props.saveForm(formName);
            }}
            submitButton={type === 1 ? 'Zmień' : type === 2 ? 'Zatwierdź odstępstwo' : 'Do edycji'}
            submitButtonClassNames={`btn btn-primary wider ml-2 font-weight-light ${type === 2 ? 'btn-brown' : ''}`}
          />
        </Form>
      </BaseModal>

      <BaseModal
        visible={props.formVisible(checkFormName)}
        headerText="Sprawdzenie poprawności procedury"
        footer="Aby zapisać wciśnij Potwierdź."
        onClickAway={() => props.toggleForm(checkFormName)}>
        <Form className="form-horizontal" formName={checkFormName}>
          <div className="row mt-3">
            <div className="col-sm-3">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Wierzytelność
                </label>
                <div className="col-sm-6">{claim?.identifier}</div>
              </div>
            </div>
            <div className="col-sm-6" />
            <div className="col-sm-3 ">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
                  Utworzył
                </label>
                <div className="col-sm-8">{claim?.user_creator?.full_name}</div>
              </div>
            </div>
          </div>
          <div className="row bt bb mt-3">
            <div className="col-sm-1 p-2 grey br">
              {/* <BooleanCheck */}
              {/*  field={props.forms?.[checkFormName]?.tasks?.filter((x) => !isTaskChecked(x.id)).length === 0} */}
              {/*  onClick={() => props.forms?.[checkFormName]?.tasks?.forEach((t, k) => checkTask(t, k, true))} */}
              {/* /> */}
            </div>
            <div className="col-sm-8 p-2 grey br">Zadanie</div>
            <div className="col-sm-3 p-2 grey">Uwagi</div>
          </div>
          {props.forms?.[checkFormName]?.tasks?.map((t, k) => (
            <div className="row bb">
              <div className="col-sm-1 p-2 br">
                <BooleanCheck field={isTaskChecked(t.id)} onClick={() => checkTask(t, k)} />
              </div>
              <div className="col-sm-8 p-2 br">{t.task.name}</div>
              <div className="col-sm-3 p-2">
                <ReactTooltip />
                <i className="fa fa-eye ml-4" data-tip={t.task.desc || '-'} />
              </div>
            </div>
          ))}
          <div className="row mt-4  pl-5 pr-5">
            <div className="col-sm-12">
              <Textarea formName={checkFormName} name="corr_proc_comment" placeholder="Komentarz..." />
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
                  Potwierdził
                </label>
                <div className="col-sm-8">
                  {claim?.corr_proc?.corr_proc_user_confirmed?.full_name ?? '-'}
                  {claim?.corr_proc?.corr_proc_confirmed_date ? ` / ${claim.corr_proc?.corr_proc_confirmed_date}` : ''}
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                  Zaakceptował odstępstwa
                </label>
                <div className="col-sm-6">
                  {claim?.corr_proc?.corr_proc_user_accepted?.full_name ?? '-'}
                  {claim?.corr_proc?.corr_proc_accepted_date ? ` / ${claim.corr_proc?.corr_proc_accepted_date}` : ''}
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-12 text-center">
              <button type="button" onClick={() => props.toggleForm(checkFormName)} className="btn btn-primary-outline ml-2 font-weight-light">
                Anuluj
              </button>
              <button
                type="button"
                onClick={() => {
                  props.storeInput(checkFormName, 'submit_type', 'save');
                  props.saveForm(checkFormName);
                }}
                className="btn btn-primary ml-2 font-weight-light">
                Zapisz
              </button>
              <button
                type="button"
                onClick={() => {
                  props.storeInput(checkFormName, 'submit_type', 'approve');
                  props.saveForm(checkFormName);
                }}
                className="btn btn-primary btn-primary-blue ml-2 font-weight-light">
                Zatwierdź
              </button>
              <button
                type="button"
                onClick={() => {
                  props.storeInput(checkFormName, 'submit_type', 'approve_deviation');
                  props.saveForm(checkFormName);
                }}
                className="btn btn-primary btn-brown ml-2 wider font-weight-light">
                Zatwierdź odstępstwo
              </button>
              <button
                type="button"
                onClick={() => {
                  props.storeInput(checkFormName, 'submit_type', 'revoke_approval');
                  props.saveForm(checkFormName);
                }}
                className="btn btn-primary-outline wider ml-2 font-weight-light">
                Cofnij zatwierdzenie
              </button>
            </div>
          </div>
        </Form>
      </BaseModal>

      <BaseModal
        visible={props.formVisible(generateAdvanceForm)}
        headerText="Generowanie płatności zaliczkowej"
        onClickAway={() => props.toggleForm(generateAdvanceForm)}>
        <AdvanceForm
          details
          form={getForm(props.claim)}
          clear={props.clear}
          visibleOnMount={false}
          visible
          toggleForm={props.toggleForm}
          isVisible={() => false}
          formName={formName}
          submitForm={() => props.saveForm(generateAdvanceForm)}
          onCancel={() => props.toggleForm(generateAdvanceForm)}
          currencies={props.common?.currencies}
          formData={props.claim?.formData}
          storeInput={props.storeInput}
          claim={claim}
          withButtons
          submitButtonText="Generuj zaliczkę"
        />
        {/* <AdvanceForm details onCancelButtonClick={() => props.toggleForm(generateAdvanceForm)} submitButtonText="Generuj wydatek" formName={generateAdvanceForm} visible withButtons /> */}
      </BaseModal>
      <BaseModal
        visible={props.formVisible(otherExpenseForm)}
        headerText="Generowanie innego wydatku"
        onClickAway={() => props.toggleForm(otherExpenseForm)}>
        <OtherExpenseForm
          onCancelButtonClick={() => props.toggleForm(otherExpenseForm)}
          submitButtonText="Generuj wydatek"
          formName={otherExpenseForm}
          visible
          withButtons
        />
      </BaseModal>
      <BaseModal
        visible={props.formVisible(finalPaymentForm)}
        headerText="Generowanie płatności końcowej"
        onClickAway={() => props.toggleForm(finalPaymentForm)}>
        <Form asDiv>
          <div className="row mt-1  p-2">
            <div className="col-sm-12 d-flex align-items-center justify-content-end">
              <span className="font-weight-bold grey mr-3">MPP</span>
              <BooleanCheck field={claim.mpp} />
            </div>
          </div>
          <hr />
          <div className="row mt-1  p-2">
            <div className="col-sm-12">
              <span className="mr-5 font-weight-bold grey">Automatyczna płatność końcowa (w tym VAT)</span>
              <span className="">
                {parseMoney(claim.final_payment_gross_value)} ({parseMoney(claim.final_payment_vat_value)}) {claim.gross_value_currency?.name}
              </span>
            </div>
          </div>
          <hr />
          <div className="row mt-3">
            <div className="col-sm-12 text-center">
              <button
                type="button"
                onClick={() => props.toggleForm(finalPaymentForm)}
                className="btn btn-primary-outline ml-2 wider font-weight-light">
                Anuluj
              </button>
              <button
                type="button"
                onClick={() => props.generateFinalPayment(finalPaymentForm)}
                className="btn btn-primary wider ml-2 font-weight-light">
                Generuj wydatek
              </button>
            </div>
          </div>
        </Form>
      </BaseModal>

      <DueStatus isOpen={instance === DueStatus.name} onClose={handleCloseModal} claim={claim} />

      <GenerateDue isOpen={instance === GenerateDue.name} onClose={handleCloseModal} />

      <GenerateExpense isOpen={instance === GenerateExpense.name} onClose={handleCloseModal} />

      <RemovalModal
        visible={removing}
        toggle={() => setRemoving(false)}
        remove={() => {
          props.removeClaim(removing);
          setRemoving(false);
        }}>
        <div className="col-sm-12">Czy na pewno chcesz usunąć tę wierzytelność?</div>
      </RemovalModal>
    </OldCard>
  );
};

export default Header;
