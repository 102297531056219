import Autocomplete from '@material-ui/lab/Autocomplete';
import { get } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeInput } from 'reducers/forms';

import {
  Checkbox,   Form, Input, Selectable
} from '../../../../components/Forms';

const days_after_payment_date = [
  { value: '15', name: '15' },
  { value: '30', name: '30' },
  { value: '45', name: '45' },
  { value: '60', name: '60' },
  { value: '120', name: '120' },
  { value: '365', name: '365' }
];

const MultilevelRatesForm = (props) => {
  const { forms } = useSelector(state => state.Forms);
  const dispatch = useDispatch();

  const handleOnChange = name => (event, newValue) => {
    dispatch(storeInput(props.formName, name, newValue));
  };

  const f = props.form || {
    levels: [],
    // margin_based_on_tran_risk:
    //             props.forms[props.formName] && typeof props.forms[props.formName].margin_based_on_tran_risk !== 'undefined'
    //               ? props.forms[props.formName].margin_based_on_tran_risk
    //               : props.margin_based_on_tran_risk,
    margin_based_on_tran_risk: 0
  };

  const k = f.multilevelbids.length ? f.multilevelbids.length - 1 : 0;

  const noOfLevelsChanged = () => {
    if (!f.multilevelbids[k].no_of_levels || f.multilevelbids[k].no_of_levels === '' || f.multilevelbids[k].no_of_levels < 1) {
      return;
    }
    if (!f.multilevelbids[k].levels || f.multilevelbids[k].levels.length === 0) {
      f.multilevelbids[k].levels = [...Array(Number(f.multilevelbids[k].no_of_levels)).keys()].map((key) => ({
        level_no: key + 1,
        document_to_buy_types: [],
        max_advance_payment: null
      }));
    } else if (f.multilevelbids[k].levels.length < f.multilevelbids[k].no_of_levels) {
      f.multilevelbids[k].levels = f.multilevelbids[k].levels.concat(
        [...Array(Number(f.multilevelbids[k].no_of_levels - f.multilevelbids[k].levels.length)).keys()].map((key) => ({
          level_no: f.levels.length + key + 1,
          document_to_buy_types: [],
          max_advance_payment: null
        }))
      );
    } else if (f.multilevelbids[k].levels.length > f.multilevelbids[k].no_of_levels) {
      f.multilevelbids[k].levels = f.multilevelbids[k].levels.slice(0, f.multilevelbids[k].no_of_levels);
    }
  };

  const columns = [
    { name: 'Nazwa dokumentu', getter: 'name', sortable: 1 }
    // { name: 'ID dokumentu', getter: 'nip' },
    // { name: 'Wzór faktora', getter: 'active_email' },
  ];

  const dtbs = props.offerData && props.offerData.dtb_types ? props.offerData.dtb_types.map((x) => ({ value: x.id, name: x.name })) : [];

  return (
    <>
      <Form
        noErrors
        asDiv
        visibleOnMount={props.visibleOnMount}
        className="form-horizontal"
        formName={props.formName}
      >
        <div className="row  ">
          <div className="col-sm-12">
            <div className="form-group row  align-items-center">
              <div className="col-sm-3 col-form-label label-right font-weight-normal">Marża wg ryzyka transakcji</div>
              <div className="col-sm-2">
                <Checkbox
                  onChange={() => props.getMultilevelFlatRate(props.formName, k)}
                  className="custom-control custom-checkbox d-flex"
                  label=""
                  formName={props.formName}
                  name={`multilevelbids.${k}.margin_based_on_tran_risk`}
                />
              </div>
              <div className="col-sm-2 col-form-label label-right font-weight-normal">Ilość poziomów</div>
              <div className="col-sm-3">
                <Input formName={props.formName} type="number" name={`multilevelbids.${k}.no_of_levels`} onChange={noOfLevelsChanged} />
              </div>
            </div>
          </div>
        </div>
        <div className="row  ">
          <div className="col-sm-12">
            <div className="form-group row  align-items-center">
              <div className="col-sm-3 col-form-label label-right font-weight-normal">Kontrola marży minimalnej</div>
              <div className="col-sm-2">
                <Checkbox
                  className="custom-control custom-checkbox d-flex"
                  label=""
                  formName={props.formName}
                  name={`multilevelbids.${k}.min_margin_control`}
                />
              </div>
              <div className="col-sm-2 col-form-label label-right font-weight-normal">Termin płatności</div>
              <div className="col-sm-3">
                <Autocomplete
                  freeSolo
                  value={get(forms[props.formName], `multilevelbids.${k}.max_payment_date`, '')}
                  onInputChange={handleOnChange(`multilevelbids.${k}.max_payment_date`)}
                  options={days_after_payment_date.map(item => item.name)}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref} className='form-group mb-0'>
                      <input {...params.inputProps} type="number" className='form-control' />
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        {f.multilevelbids[k].levels && f.multilevelbids[k].levels.length > 0 ? (
          <>
            <div className="row mt-3">
              <div className="col-sm-1 grey">Poziom</div>
              <div className="col-sm-2 grey">Max wysokość zaliczki</div>
              <div className="col-sm-9 grey">Nazwa dokumentu</div>
            </div>
            {f.multilevelbids[k].levels.map((level, key) => (
              <div className="row mt-2">
                <div className="col-sm-1">{level.level_no}</div>
                <div className="col-sm-2">
                  <Input
                    onChange={() => props.getMultilevelFlatRate(props.formName, k)}
                    formName={props.formName}
                    type="number"
                    name={`multilevelbids.${k}.levels.${key}.max_advance_payment`}
                  />
                </div>
                <div className="col-sm-9">
                  {level.document_to_buy_types ? level.document_to_buy_types.map((d) => d.name).join(', ') : ''}
                  <button
                    type="button"
                    className="ml-3"
                    onClick={() => {
                      props.toggleForm(`multilevelbids.${k}.levels.${key}.document_to_buy_types`);
                    }}
                  >
                    Wybierz
                  </button>

                  <Selectable
                    multiple
                    filtersForm={props.filtersForm}
                    fetch={props.fetch}
                    visible={props.formVisible(`multilevelbids.${k}.levels.${key}.document_to_buy_types`)}
                    formName={props.formName}
                    field={`multilevelbids.${k}.levels.${key}.document_to_buy_types`}
                    columns={columns}
                    options={dtbs}
                    onClickAway={() => props.toggleForm(`multilevelbids.${k}.levels.${key}.document_to_buy_types`)}
                    header="Wybierz rodzaj dokumentu"
                    footer=""
                  />
                </div>
              </div>
            ))}
          </>
        ) : null}

        <div className="row mt-5">
          <div className="col-sm-12">
            <div className="form-group row  align-items-center">
              <div className="col-sm-3 col-form-label label-right font-weight-normal">Stawka wielopoziomowa</div>
              <div className="col-sm-3">
                <Input
                  formName={props.formName}
                  type="number"
                  name={`multilevelbids.${k}.multilevel_flat_rate`}
                  disabled={f.multilevelbids[k].margin_based_on_tran_risk}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button
              type="button"
              onClick={props.onCancel}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}
            >
              Anuluj
            </button>
            <button type="button" onClick={props.onSave} className="btn btn-primary ml-2  font-weight-light">
              Zapisz
            </button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default MultilevelRatesForm;
