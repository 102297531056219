import OrderBy from 'components/Forms/OldOrderBy';
import Select from 'components/Forms/Select';
import ListFilters from 'components/OldListFilters';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AgreementActions from '../../../actions/agreement';
import ClaimActions from '../../../actions/claim';
import CommonActions from '../../../actions/common';
import CompanyActions from 'actions/companies';
import FormActions from '../../../actions/forms';
import OtherActions from '../../../actions/other';
import { Form, Selectable } from '../../../components/Forms';
import { OldCard, Container, MainContainer } from '../../../components/Layout';
import { OldPagination } from 'components/Layout/OldPagination';
import { RowEditable } from '../../../components/Layout/RowEditable';
import Loader from '../../../components/Loader';
import Constants from '../../../utilities/constants';
import { parseMoney } from '../../../utilities/helpers';

class TransferList extends React.Component {
  constructor(props) {
    super(props);

    this.filtersForm = 'SettlementsFilters';

    this.props.dispatch(CommonActions.changeView(Constants.Views.List));

    this.state = {
      tab: 1
    };

    this.table = [
      { name: 'Nazwa tytułu', getter: 'title' },
      { name: 'Typ', getter: null },
      { name: 'Data wystawienia', getter: 'created_date' },
      { name: 'Termin zapłaty', getter: 'payment_date' },
      { name: 'Wartość brutto / waluta rozliczenia', custom: i => `${parseMoney(i.gross_value)} ${i.currency?.name}` },
      { name: 'Rozliczono w wal. rozl.', getter: null },
      { name: 'Kurs tytułu / pośredni / rozl.', getter: null },
      // {name: 'Kurs pośredni', getter: 'test'},
      // {name: 'Kurs rozl.', getter: 'test'},
      { name: 'Kwota rozl. w wal. płatności', getter: null },
      { name: 'Metoda płatności', getter: 'payment_method' },
      { name: 'Status', custom: i => (i.status ? 'Rozliczony' : 'Nierozliczony') },
      { name: 'Kwota do rozl. w wal. rozl.', getter: 'to_pay_value' }
    ];

    this.columns = {
      client: [{ name: 'Identyfikator', getter: 'identifier' }, { name: 'Nazwa', getter: 'name' }, { name: 'NIP', getter: 'nip' }],
      agreement: [
        { name: 'Identyfikator', getter: 'identifier' },
        { name: 'Klient', getter: 'client.name' },
        { name: 'Kwota limitu', getter: 'limit_amount', mutator: parseMoney }
      ],
      claim: [
        { name: 'Identyfikator', getter: 'identifier' },
        { name: 'Umowa', getter: 'agreement.identifier' },
        { name: 'Dłużnik', getter: 'debtor.name' },
        { name: 'Wartość brutto', getter: 'gross_value', mutator: parseMoney }
      ]
    };

    this.filters = {
      client: 'kf',
      agreement: 'af',
      claim: 'cf'
    };
  }

  componentDidMount() {
    this.props.dispatch(CommonActions.setContextMenu());

    const url = new URLSearchParams(this.props.location.search);
    const { tab, client } = {
      tab: url.get('tab') ?? 1,
      client: {
        id: url.get('client_id'),
        name: url.get('client_name')
      }
    };

    if (client.id) {
      this.props.dispatch(FormActions.storeInput(this.filtersForm, 'client', client));
      this.props.dispatch(FormActions.storeInput(this.filtersForm, 'client_from_url', true));
    }

    this.changeTab(Number(tab));
  }

  changeTab(tab) {
    this.setState({ tab });

    this.fetchResults();
  }

  changePage = page => {
    this.props.dispatch(FormActions.storeInput(this.filtersForm, 'page', page));
    this.fetchResults();
  };

  fetchResults() {
    this.props.dispatch(OtherActions.fetchSettlements({ ...CommonActions.defaultFilters, ...this.getForm(this.filtersForm) }));
  }

  toggleForm(f) {
    this.props.dispatch(FormActions.toggleForm(f));
  }

  formVisible(f) {
    return this.props.forms.options.visible.indexOf(f) !== -1;
  }

  getForm(f) {
    return this.props.forms.forms[f] ?? {};
  }

  render() {
    const rows = this.props.settlements.results ?? [];
    const { tab } = this.state;

    return (
      <Container>
        <MainContainer className="col-sm-12">
          <OldCard className="mt-3 pt-3 pb-3">
            <h4 className="card-title">
              <span className=" text-themecolor">Rozrachunki z kontrahentami</span>
              {/*<p className="small d-inline-flex float-right text-black">*/}
              {/*  Ilość rekordów:*/}
              {/*  {' '}*/}
              {/*  3*/}
              {/*</p>*/}
            </h4>
            <Form formName={this.filtersForm} asDiv>
              <div className="row mb-1">
                <div className="col-1 text-right grey">Kontrahent</div>
                <div className="col-sm-2">
                  {this.getForm(this.filtersForm).client?.name ?? this.getForm(this.filtersForm).client?.id ?? '-'}
                  {this.getForm(this.filtersForm).client_from_url ? null : (
                    <button
                      type="button"
                      className="float-right"
                      onClick={() => {
                        this.props.dispatch(CompanyActions.fetch(this.getForm(this.filters.client)));
                        this.toggleForm('client');
                      }}>
                      Zmień
                    </button>
                  )}
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-1 text-right grey">Umowa</div>
                <div className="col-sm-2">
                  {this.getForm(this.filtersForm).agreement?.identifier ?? '-'}

                  <button
                    type="button"
                    className="float-right"
                    onClick={() => {
                      this.props.dispatch(AgreementActions.fetch(this.getForm(this.filters.agreement)));
                      this.toggleForm('agreement');
                    }}>
                    Zmień
                  </button>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-sm-1 text-right grey">Wierzytelność</div>
                <div className="col-sm-2">
                  {this.getForm(this.filtersForm).claim?.identifier ?? '-'}

                  <button
                    type="button"
                    className="float-right"
                    onClick={() => {
                      this.props.dispatch(ClaimActions.fetch(this.getForm(this.filters.claim)));
                      this.toggleForm('claim');
                    }}>
                    Zmień
                  </button>
                </div>
              </div>
              <div className="row mb-1 align-items-center">
                <div className="col-sm-1 text-right grey">Inne dokumenty</div>
                <div className="col-sm-2">
                  <Select formName={this.filtersForm} name="test" options={[]} />
                </div>
              </div>
              <div className="row mb-1 align-items-center">
                <div className="col-sm-1 text-right grey">Waluty</div>
                <div className="col-sm-2">
                  <Select formName={this.filtersForm} name="test" options={[]} />
                </div>
              </div>
              <div className="row mb-1 align-items-center">
                <div className="col-sm-1 text-right grey">Status</div>
                <div className="col-sm-2">
                  <Select
                    formName={this.filtersForm}
                    name="settled"
                    options={[{ value: 0, name: 'Nierozliczony' }, { value: 1, name: 'Rozliczony' }]}
                  />
                </div>
              </div>
              <div className="row mt-2 align-items-center">
                <div className="col-sm-1 text-right grey" />
                <div className="col-sm-1">
                  <button
                    type="button"
                    className="w-100"
                    onClick={() => this.props.dispatch(FormActions.initialize(this.filtersForm, CommonActions.defaultFilters))}>
                    Wyczyść
                  </button>
                </div>
                <div className="col-sm-1">
                  <button type="button" className="btn-green w-100" onClick={() => this.fetchResults()}>
                    Filtruj
                  </button>
                </div>
              </div>
            </Form>
          </OldCard>

          <ul className="nav nav-tabs" role="tablist">
            <li onClick={() => this.props.history.push('/rozrachunki?tab=1')} className={`nav-item nav-link ${tab === 1 ? 'active' : ''}`}>
              Wszystkie
            </li>
            <li onClick={() => this.props.history.push('/rozrachunki?tab=2')} className={`nav-item nav-link ${tab === 2 ? 'active' : ''}`}>
              Należności
            </li>
            <li onClick={() => this.props.history.push('/rozrachunki?tab=3')} className={`nav-item nav-link ${tab === 3 ? 'active' : ''}`}>
              Zobowiązania
            </li>
            <li onClick={() => this.props.history.push('/rozrachunki?tab=4')} className={`nav-item nav-link ${tab === 4 ? 'active' : ''}`}>
              Rozliczone
            </li>
            <li onClick={() => this.props.history.push('/rozrachunki?tab=5')} className={`nav-item nav-link ${tab === 5 ? 'active' : ''}`}>
              Nierozliczone
            </li>
          </ul>

          <div className="tab-content tabcontent-border" style={{ background: 'white' }}>
            {this.props.common.loading ? (
              <Loader />
            ) : (
              <div className="tab-pane active p-20" id="#" role="tabpanel">
                <div className="row mb-4 mt-3 align-items-center">
                  <div className="col-sm-9">
                    <ListFilters formName={this.filtersForm} onSubmit={() => this.fetchResults()} />
                  </div>
                  <div className="col-sm-3 text-right">
                    <h4 className="text-themecolor" style={{ cursor: 'pointer' }}>
                      {/* <i className="fa fa-ellipsis-h mr-2" /> */}
                      {/* <i className="fa fa-plus" onClick={() => this.createTransfer()} /> */}
                    </h4>
                  </div>
                </div>
                <div className="row bt bb">
                  {this.table.map((i, j) => (
                    <div className={`col p-2 ${j !== this.table.length - 1 ? 'br' : ''}`}>
                      <OrderBy
                        field={i.field}
                        spanClassName="ml-0"
                        column={i.name}
                        formName={this.filtersForm}
                        onSubmit={() => this.fetchResults()}
                      />
                    </div>
                  ))}
                </div>
                <Form visibleOnMount style={{ padding: 0 }} formName={this.selectForExportForm}>
                  {rows.map(row => (
                    <RowEditable>
                      {this.table.map((i, j) => (
                        <div className={`col p-2 ${j !== this.table.length - 1 ? 'br' : ''}`}>{i.custom ? i.custom(row) : row[i.getter] ?? '-'}</div>
                      ))}
                    </RowEditable>
                  ))}
                </Form>
              </div>
            )}
          </div>

          <OldPagination
            onList
            currentPage={this.props.settlements?.current_page}
            changePage={this.changePage}
            links={this.props.settlements?.links}
            count={this.props.settlements?.count}
          />

          <Selectable
            filtersForm={this.filters.client}
            fetch={() => this.props.dispatch(CompanyActions.fetch(this.getForm(this.filters.client)))}
            visible={this.formVisible('client')}
            formName={this.filtersForm}
            field="client"
            columns={this.columns.client}
            options={this.props.companies}
            onClickAway={() => this.toggleForm('client')}
            header="Wybierz kontrahenta"
          />

          <Selectable
            filtersForm={this.filters.agreement}
            fetch={() => this.props.dispatch(AgreementActions.fetch(this.getForm(this.filters.agreement)))}
            visible={this.formVisible('agreement')}
            formName={this.filtersForm}
            field="agreement"
            columns={this.columns.agreement}
            options={this.props.agreements}
            onClickAway={() => this.toggleForm('agreement')}
            header="Wybierz umowę"
          />

          <Selectable
            filtersForm={this.filters.claim}
            fetch={() => this.props.dispatch(ClaimActions.fetch(this.getForm(this.filters.claim)))}
            visible={this.formVisible('claim')}
            formName={this.filtersForm}
            field="claim"
            columns={this.columns.claim}
            options={this.props.claims}
            onClickAway={() => this.toggleForm('claim')}
            header="Wybierz wierzytelność"
          />
        </MainContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  forms: state.Forms,
  common: state.Common,
  companies: state.Company,
  agreements: state.Agreement,
  claims: state.Claim,
  settlements: state.Other.settlements
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(TransferList)
);
