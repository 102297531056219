import React from 'react';
import { TextLink } from 'containers/Companies/Details/Components/Notes';

export const SidebarNotes = ({ object }) => (
  <div>
    {object?.notes?.length === 0 && <span>Brak notatek.</span>}
    {object?.notes?.map((note) => (
      <div className="row mb-3" key={note.id}>
        <div className="col-sm-2 text-center">
          <img src={note.user_creator?.avatar_url} className="img image rounded-circle" style={{ width: '40%' }} alt="" />
          <div className="grey" style={{ fontSize: '80%' }}>
            <span className="d-block mt-2">Notatka</span>
            <span>{note.created_date}</span>
          </div>
        </div>
        <div className="col-sm-10">
          <a
            role="button"
            style={{ color: 'black' }}
            className="collapsed"
            data-toggle="collapse"
            href={`#notatka${note.id}`}
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <i className="fa fa-sort-down mr-2" />
            <span className="text-themecolor">{note.user_creator?.full_name}</span>
            {' '}
            dodał notatkę dla
            {' '}
            {' '}
            <span className="text-themecolor">{object?.full_name || object.identifier || '?'}</span>
          </a>

          <div className="collapse show" id={`notatka${note.id}`} style={{ color: 'grey' }}>
            <p className="small">
              {note.created_date}
              {' '}
              | Temat:
              {' '}
              {note.name}
            </p>
            <TextLink>{note.comment}</TextLink>
          </div>
        </div>
      </div>
    ))}
  </div>
);
