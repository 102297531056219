import CompanyActions from 'actions/companies';
import LoaderBackdrop from 'components/Layout/LoaderBackdrop';
import { t } from 'lang/pl';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

// import _ from "lodash";
import CommonActions from '../../../actions/common';
import FormActions from '../../../actions/forms';
import OfferActions from '../../../actions/offer';
import { Container, MainContainer } from '../../../components/Layout';
import { SidebarDetails } from '../../../components/SidebarDetails';
import Constants from '../../../utilities/constants';
import { AdditionalRates } from './Components/AdditionalRates';
import { Administrator } from './Components/Administrator';
import { BasicData } from './Components/BasicData';
import { BuyoutDocs } from './Components/BuyoutDocs';
import { Calculations } from './Components/Calculations';
import { Commissions } from './Components/Commissions';
import { Conditions } from './Components/Conditions';
import { Header } from './Components/Header';
import { MultilevelRates } from './Components/MultilevelRates';
import { Protections } from './Components/Protections';
import { Rates } from './Components/Rates';
import { Readiness } from './Components/Readiness';
import { DetailsSidebar } from './Sidebar';
import { Notes } from 'containers/Companies/Details/Components/Notes';

export const OFFER_OPEN = 0;
export const OFFER_CLOSED = 1;

export const OFFER_NOT_APPROVED_CALCULATION = 0;
export const OFFER_APPROVED_CALCULATION = true;

const hasEditableStatus = (offerStatus, calculationStatus) => {
  if(offerStatus === OFFER_CLOSED || calculationStatus === OFFER_APPROVED_CALCULATION) {
    toast.warn(t.error.toast.statusWarning);
    return false;
  }
  return true;
};

class Index extends React.Component {
  constructor(props) {
    super(props);

    const { slug } = this.props.match.params;
    const id = slug ? slug.split('-').slice(-1)[0] : null;

    this.props.dispatch(OfferActions.fetchOfferDetails(id, true));
    this.props.dispatch(CommonActions.changeView(Constants.Views.Details));
  }

  componentDidMount() {
    const { offerData } = this.props.common;

    this.props.dispatch(
      CommonActions.setContextMenu([
        { name: 'Lista ofert', link: '/oferty' },
        {
          name: 'Oferta',
          options: [
            { name: 'Nowa kalkulacja', onClick: () => this.showForm('CalculationsForm', { is_active: true }) },
            { name: 'Zatwierdź kalkulację', onClick: () => this.toggleForm('acceptance') },
            { name: 'Usuń kalkulację', onClick: () => this.remove(this.props.calculation.id, 'calculations') },
            { name: `${this.props.offer.status ? 'Otwórz' : 'Zamknij'} ofertę`, onClick: () => this.props.dispatch(OfferActions.toggleOffer(this.props.offer.id)) }
          ]
        },
        {
          name: 'Dodaj',
          options: [
            { name: 'Dodaj dłużnika', onClick: () => this.showForm('AddressForm', { is_active: true }) },
            { name: 'Dodaj zabezpieczenia', onClick: () => this.showForm('ProtectionsForm', { calculationId: this.props.calculation.id }) },
            { name: 'Dokumenty do wykupu', onClick: () => this.showForm('BuyoutDocsForm', { calculationId: this.props.calculation.id }) }
          ]
        },
        {
          name: 'Podstawowe',
          onClick: () => this.showForm('CalculationsEditForm', {
            ...this.props.calculation,
            currencyName: this.props.calculation.currency?.name,
            currency: this.props.calculation.currency?.id,
            transaction_risk_category_id: this.props.calculation.transaction_risk_category?.id

          })
        },
        { name: 'Notatka', onClick: () => this.showForm('NotesForm', {}) },
        {
          name: 'Stawki',
          options: [
            {
              name: 'Dodaj stawkę podstawową',
              onClick: () => this.showForm('BasicRatesForm', {
                calculationId: this.props.calculation.id,
                margin_based_on_tran_risk: offerData.offer_setup
                  ? Boolean(offerData.offer_setup.global_min_margin_control_multilevelbid)
                  : false
              })
            },
            {
              name: 'Dodaj stawkę złożoną',
              onClick: () => this.showForm('MultilevelRatesForm', {
                calculationId: this.props.calculation.id,
                margin_based_on_tran_risk: offerData.offer_setup
                  ? Boolean(offerData.offer_setup.global_min_margin_control_multilevelbid)
                  : false
              })
            },
            {
              name: 'Dodaj stawkę dodatkową',
              onClick: () => this.showForm('AdditionalRatesForm', {
                calculationId: this.props.calculation.id
              })
            }
          ]
        },
        {
          name: 'Przejdź do',
          options: [
            { name: 'Wniosek', onClick: () => this.props.history.push(`/wnioski?offer_id=${this.props.offer.id}`) },
            { name: 'Umowa', onClick: () => this.props.history.push(`/umowy?offer_id=${this.props.offer.id}`) }
          ]
        },
        { name: 'Opiekunowie', onClick: () => this.showForm('CommissionForm', {}) },
        {
          name: 'Dokumenty',
          onClick: () => this.showForm('BuyoutDocsForm', {
            calculationId: this.props.calculation.id
          })
        }
      ])
    );
  }

  componentWillUnmount() {
    this.props.dispatch(FormActions.clearAllForms(['CreateProposalForm']));
  }

    create = (name) => {
      this.props.dispatch(FormActions.initialize(name, {}, {}));
    };

    edit = (object, name) => {
      if(hasEditableStatus(this.props.offer.status, this.props.calculation.status)) {
        this.props.dispatch(FormActions.initialize(name, object, {}));
        this.props.dispatch(FormActions.toggleForm(name));
      }
    };

  save = (formName) => this.props.dispatch(OfferActions.saveForm(formName, this.props.offer.id, true));

  showForm = (formName, object) => {
    if(hasEditableStatus(this.props.offer.status, this.props.calculation.status)) {
      Object.keys(object).forEach((key) => {
        if (object[key] === null) {
          delete object[key];
        }
      });

      this.props.dispatch(FormActions.initialize(formName, object, {}));
      this.props.dispatch(FormActions.toggleForm(formName));
    }
  };

  showFormFromHeader = (formName, object) => {
    Object.keys(object).forEach((key) => {
      if (object[key] === null) {
        delete object[key];
      }
    });

    this.props.dispatch(FormActions.initialize(formName, object, {}));
    this.props.dispatch(FormActions.toggleForm(formName));
  };

  showFormFromCalc = (formName, object) => {
    if(this.props.offer.status === OFFER_OPEN) {
      Object.keys(object).forEach((key) => {
        if (object[key] === null) {
          delete object[key];
        }
      });

      this.props.dispatch(FormActions.initialize(formName, object, {}));
      this.props.dispatch(FormActions.toggleForm(formName));
    } else {
      toast.warn(t.error.toast.statusWarning);
    }
  };

    remove = (oId, object, extraData = '') => {
      hasEditableStatus(this.props.offer.status, this.props.calculation.status) && this.props.dispatch(OfferActions.removeObject(oId, this.props.offer.id, object, extraData));
    };

    toggleForm = (formName) => {
      hasEditableStatus(this.props.offer.status, this.props.calculation.status) && this.props.dispatch(FormActions.toggleForm(formName));
    };

    createProposal() {
      const { offer, calculation, history, dispatch } = this.props;

      dispatch(
        FormActions.initialize('CreateProposalForm', {
          offer: { ...offer, active_calculation: calculation },
          limit_amount: offer.active_limit_amount,
          operational_administrator: offer.operational_administrator,
          trade_supervisor: offer.trade_supervisor,
          billing_manager: offer.billing_manager,
          factor: offer.factor,
          client: offer.client,
          duration_of_the_contract: calculation.duration_of_the_contract,
          recourse: calculation.recourse
        }, {})
      );

      this.props.dispatch(FormActions.toggleForm('CreateProposalForm'));
      history.push('/wnioski');
    }

    render() {
      const common = this.props.common || {};
      const offerData = common.offerData || {};

      if ((!this.props.calculation || !this.props.calculation.id) && this.props.offer.calculations && this.props.offer.calculations.length > 0) {
        const newCalc = this.props.offer.calculations.slice(-1)[0];
        this.props.dispatch(OfferActions.selectCalculation(newCalc));
      }

      // values is never used - TODO: to remove
      const objectTypes = {
        person: 19,
        company: 33
      };

      return (
        <Container className="mt-3">
          <LoaderBackdrop />

          <MainContainer>
            <Header
              toggleForm={(f) => this.showFormFromHeader(f, {})}
              offer={this.props.offer}
              calculation={this.props.calculation}
              close={() => this.props.dispatch(OfferActions.toggleOffer(this.props.offer.id))}
              createProposal={() => this.createProposal()}
            />

            <Calculations
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              currencies={this.props.common.currencies}
              offerData={this.props.common.offerData}
              offer={this.props.offer}
              forms={this.props.forms.forms}
              create={(form) => this.showFormFromCalc(form, {})}
              remove={(objectId) => this.remove(objectId, 'calculations')}
              formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
              toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
              saveForm={(formName, hide) => {
                this.props.dispatch(OfferActions.saveForm(formName, this.props.offer.id, hide));
              }}
              calculation={this.props.calculation}
              selectCalculation={(c) => this.props.dispatch(OfferActions.selectCalculation(c))}
            />

            {this.props.calculation && Object.keys(this.props.calculation).length > 0 ? (
              <>
                <BasicData
                  clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
                  currencies={this.props.common.currencies}
                  offerData={this.props.common.offerData}
                  offer={this.props.offer}
                  forms={this.props.forms.forms}
                  edit={(obj, modal) => this.edit(obj, modal)}
                  formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
                  toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
                  saveForm={(formName, hide) => {
                    this.props.dispatch(OfferActions.saveForm(formName, this.props.offer.id, hide));
                  }}
                  calculation={this.props.calculation}
                />

                <Administrator
                  freqs={offerData.lump_sum_req}
                  calculation={this.props.calculation}
                  clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
                  currencies={this.props.common.currencies}
                  offer={this.props.offer}
                  forms={this.props.forms.forms}
                  toggleForm={this.toggleForm}
                  formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
                  saveForm={(f) => this.save(f)}
                  edit={(f, o) => this.showForm(f, o)}
                />

                <Conditions
                  offerData={offerData}
                  calculation={this.props.calculation}
                  clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
                  currencies={this.props.common.currencies}
                  offer={this.props.offer}
                  forms={this.props.forms.forms}
                  toggleForm={this.toggleForm}
                  formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
                  saveForm={(f) => this.save(f)}
                  edit={(f, o) => this.showForm(f, o)}
                />

                <Readiness
                  countingMethods={offerData.counting_method ? offerData.counting_method.map((i) => ({ value: i.id, name: i.name })) : []}
                  storeInput={(a, b, c) => this.props.dispatch(FormActions.storeInput(a, b, c))}
                  calculation={this.props.calculation}
                  clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
                  currencies={this.props.common.currencies}
                  offer={this.props.offer}
                  forms={this.props.forms.forms}
                  toggleForm={this.toggleForm}
                  formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
                  saveForm={(f) => this.save(f)}
                  edit={(f, o) => this.showForm(f, o)}
                />

                <BuyoutDocs
                  calculation={this.props.calculation}
                  offer={this.props.offer}
                  forms={this.props.forms.forms}
                  errors={this.props.forms.errors}
                  toggleForm={this.toggleForm}
                  formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
                  create={(f) => this.showForm(f, { calculationId: this.props.calculation.id })}
                  saveForm={(f) => this.save(f)}
                  onCancel={(f) => this.toggleForm(f)}
                  remove={(obj) => this.remove(this.props.calculation.id, 'calculations', `documents_to_buy/${obj.id}/`)}
                  fetchCompanies={this.fetchCompanies}
                  filtersForm={this.companiesFiltersForm}
                  documents={offerData.dtb_types ? offerData.dtb_types.map((i) => ({ ...i, value: i.id })) : []}
                  expectedForms={offerData.dtb_forms ? offerData.dtb_forms.map((i) => ({ ...i, value: i.id })) : []}
                />

                <Protections
                  calculation={this.props.calculation}
                  offer={this.props.offer}
                  forms={this.props.forms.forms}
                  errors={this.props.forms.errors}
                  toggleForm={this.toggleForm}
                  formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
                  create={(f) => this.showForm(f, { calculationId: this.props.calculation.id })}
                  saveForm={(f) => this.save(f)}
                  onCancel={(f) => this.toggleForm(f)}
                  remove={(obj) => this.remove(this.props.calculation.id, 'calculations', `documents_to_secure/${obj.id}/`)}
                  fetchCompanies={this.fetchCompanies}
                  filtersForm={this.companiesFiltersForm}
                  documents={offerData.dts_types ? offerData.dts_types.map((i) => ({ ...i, value: i.id })) : []}
                  expectedForms={offerData.dts_forms ? offerData.dts_forms.map((i) => ({ ...i, value: i.id })) : []}
                />

                <Commissions
                  isEditable={true}

                  objectTypes={objectTypes}
                  clearBeneficiaries={() => this.props.dispatch({ type: 'OFFER_CLEAR_BENEFICIARIES' })}
                  fetchBeneficiaries={(f) => this.props.dispatch(OfferActions.fetchBeneficiaries(f))}
                  beneficiaries={this.props.beneficiaries}
                  types={this.props.common.commissions ? this.props.common.commissions.map((i) => ({ ...i, value: i.id })) : []}
                  roles={
                    common.systemRoles
                      ? common.systemRoles.filter((r) => r.is_used_for_commissions).map((i) => ({ ...i, value: i.id }))
                      : []
                  }
                  clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
                  calculation={this.props.calculation}
                  offer={this.props.offer}
                  forms={this.props.forms.forms}
                  errors={this.props.forms.errors}
                  toggleForm={this.toggleForm}
                  formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
                  create={(f) => this.showForm(f, { calculationId: this.props.calculation.id })}
                  edit={(obj, modal) => this.edit(obj, modal)}
                  saveForm={(f) => this.save(f)}
                  onCancel={(f) => this.toggleForm(f)}
                  remove={(obj) => this.remove(this.props.calculation.id, 'calculations', `commissions/${obj.id}/`)}
                />

                <Rates
                  margin_based_on_tran_risk={offerData.offer_setup ? offerData.offer_setup.global_min_margin_control_basicbid : false}
                  setLastAvailableDateOfRate={(formName) => this.props.dispatch(OfferActions.setLastAvailableDateOfRate(formName))}
                  intrestRates={this.props.intrestRates}
                  getFlatRate={(formName) => this.props.dispatch(OfferActions.getFlatRate(formName))}
                  getIntrestRates={(formName) => this.props.dispatch(OfferActions.getIntrestRates(formName))}
                  offerData={offerData}
                  calculation={this.props.calculation}
                  offer={this.props.offer}
                  clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
                  forms={this.props.forms.forms}
                  errors={this.props.forms.errors}
                  toggleForm={this.toggleForm}
                  formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
                  create={(f) => this.showForm(f, {
                    calculationId: this.props.calculation.id,
                    margin_based_on_tran_risk: offerData.offer_setup
                      ? Boolean(offerData.offer_setup.global_min_margin_control_basicbid)
                      : false
                  })}
                  edit={(obj, modal) => this.edit(obj, modal)}
                  saveForm={(f) => this.save(f)}
                  onCancel={(f) => this.toggleForm(f)}
                  remove={(obj) => this.remove(this.props.calculation.id, 'calculations', `basic_bid/${obj.id}/`)}
                />

                <MultilevelRates
                  margin_based_on_tran_risk={
                    offerData.offer_setup ? offerData.offer_setup.global_min_margin_control_multilevelbid : false
                  }
                  getMultilevelFlatRate={(formName) => this.props.dispatch(OfferActions.getMultilevelFlatRate(formName))}
                  clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
                  calculation={this.props.calculation}
                  offer={this.props.offer}
                  offerData={offerData}
                  forms={this.props.forms.forms}
                  errors={this.props.forms.errors}
                  toggleForm={this.toggleForm}
                  formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
                  create={(f) => this.showForm(f, {
                    calculationId: this.props.calculation.id,
                    margin_based_on_tran_risk: offerData.offer_setup
                      ? Boolean(offerData.offer_setup.global_min_margin_control_multilevelbid)
                      : false
                  })}
                  edit={(obj, modal) => this.edit(obj, modal)}
                  saveForm={(f) => this.save(f)}
                  onCancel={(f) => this.toggleForm(f)}
                  remove={(obj) => this.remove(this.props.calculation.id, 'calculations', `multilevel_bid/${obj.id}/`)}
                />

                <AdditionalRates
                  calculation={this.props.calculation}
                  offer={this.props.offer}
                  forms={this.props.forms.forms}
                  errors={this.props.forms.errors}
                  toggleForm={this.toggleForm}
                  formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
                  create={(f) => this.showForm(f, { calculationId: this.props.calculation.id })}
                  edit={(obj, modal) => this.edit(obj, modal)}
                  saveForm={(f) => this.save(f)}
                  onCancel={(f) => this.toggleForm(f)}
                  offerData={this.props.common.offerData}
                  remove={(obj) => this.remove(this.props.calculation.id, 'calculations', `additional_bid/${obj.id}/`)}
                />
              </>
            ) : null}
          </MainContainer>
          <SidebarDetails
            Notes={
              <Notes
                name={this.props.company?.name}
                notes={
                  this.props.offer?.notes?.map((n) => ({
                    created_date: n.created_date,
                    modified_date: n.modified_date,
                    avatar: n.user_creator ? n.user_creator.avatar_url : '',
                    creator: n.user_creator ? n.user_creator.full_name : 'brak',
                    comment: n.comment,
                    name: n.name,
                    id: n.id
                  })) ?? []
                }
                forms={this.props.forms.forms}
                toggleForm={this.toggleForm}
                formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
                create={f => this.showForm(f, {})}
                saveForm={f => this.save(f)}
                onCancel={f => this.toggleForm(f)}
                edit={(f, o) => this.showForm(f, o)}
                remove={objectId => this.props.dispatch(CompanyActions.removeObject(objectId, this.props.company.id, 'notes'))}
              />
            }
            section={(
              <DetailsSidebar
                offer={this.props.offer}
                calculation={this.props.calculation}
                formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
                toggleForm={(f) => this.props.dispatch(FormActions.toggleForm(f))}
                acceptCalculation={() => this.props.dispatch(OfferActions.acceptCalculation(this.props.offer.id, this.props.calculation.id))}
                generatePdf={(f) => this.props.dispatch(OfferActions.generatePdf(this.props.offer.id, f))}
              />
            )}
          />
        </Container>
      );
    }
}

const mapStateToProps = (state) => ({
  forms: state.Forms,
  offer: state.Offer.offer,
  beneficiaries: state.Offer.beneficiaries,
  intrestRates: state.Offer.intrestRates,
  calculation: state.Offer.calculation,
  common: state.Common,
  companies: state.Company.list
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Index)
);
