import Card from 'components/Layout/Card';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { populateForm } from 'reducers/forms';

import ConditionsForm from '../../Forms/Conditions';

export default props => {
  const dispatch = useDispatch();

  const formName = 'ConditionsForm';
  const version = props.version;

  useEffect(() => {
    version && dispatch(populateForm(formName, {
      id: version.id,
      max_maturity: version.max_maturity,
      max_financing_period: version.max_financing_period,
      claim_limit: version.claim_limit,
      repayment_of_capital: version.repayment_of_capital,
      split_payment: version.split_payment,
      company_started_date: version.company_started_date,
      first_invoice_date: version.first_invoice_date,
      no_employees: version.no_employees,
      main_pkd_id: version.main_pkd ? version.main_pkd.id : null,
      suppliers_number: version.suppliers_number,
      recipients_number: version.recipients_number,
      accounting_support_id: version.accounting_support ? version.accounting_support.id : null,
      accounting_type_id: version.accounting_type ? version.accounting_type.id : null,
      activity_range_id: version.activity_range ? version.activity_range.id : null,
      office_ownership_id: version.office_ownership ? version.office_ownership.id : null,
      insurance_claims: version.insurance_claims
    }));
    // eslint-disable-next-line
  }, [version]);

  const repaymentOfCapital = props.offerData && props.offerData.repayment_of_capital ? props.offerData.repayment_of_capital.map(i => ({ ...i, value: i.id })) : [];

  return (
    <Card mode='creator' header='+ Warunki dodatkowe' onSaveClick={() => props.saveForm(formName)}>
      <div/>
      <ConditionsForm
        offerData={props.offerData}
        visible
        visibleOnMount
        formName={formName}
        onSubmit={() => props.saveForm(formName)}
        repaymentOfCapital={repaymentOfCapital}
      />
    </Card>
  );
};
