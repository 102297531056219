import CompanyActions from 'actions/companies';
import OrderBy from 'components/Forms/OldOrderBy';
import { BaseModal } from 'components/Layout/OldModal';
import { Pagination } from 'components/Layout/Pagination';
import Tooltip from 'components/Layout/Tooltip';
import ListFilters from 'components/OldListFilters';
import { OldSearchBar } from 'containers/OldSearchBar';
import { t } from 'lang/pl';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import CommonActions from '../../../actions/common';
import FormActions from '../../../actions/forms';
import { Container, MainContainer, SidebarContainer } from '../../../components/Layout';
import Constants from '../../../utilities/constants';
import { formatNip, optionsCountries, parsePhone } from '../../../utilities/helpers';
import Tab from '../../Tab';
import { CreateCompanyForm } from '../Forms/Create';
import CompanySidebarView from './Sidebar';

class Companies extends React.Component {
  constructor(props) {
    super(props);

    this.filtersForm = 'CompaniesFilters';
    this.createCompanyForm = 'CompanyForm';
    this.deleteCompanyForm = 'CompanyDeleteForm';

    this.props.dispatch(CommonActions.changeView(Constants.Views.List));
    this.props.dispatch(CompanyActions.fetch({ page: this.props.companies?.currentPage ?? 1, limit: this.props.options.limit }));
  }

  componentDidMount() {
    this.props.dispatch(CommonActions.setContextMenu());
  }

  fetchResults = () => this.props.dispatch(CompanyActions.fetch(this.getFilters()));

  getFilters = () => {
    const currentFilters = this.props.forms.forms[this.filtersForm] || {};
    let params = {};
    let form = {};

    if (this.props.history.location.search) {
      const urlParams = new URLSearchParams(this.props.history.location.search.replace('?', ''));
      params = Object.fromEntries(urlParams);
    }

    if (!currentFilters || Object.keys(currentFilters).length <= Object.keys(CommonActions.defaultFilters).length) {
      form = { ...CommonActions.defaultFilters, ...params, ...currentFilters };
    } else {
      form = { ...params, ...currentFilters };
    }

    this.props.dispatch(FormActions.initialize(this.filtersForm, form, {}));
    return form;
  };

  selectCompanyForPreview = Company => {
    this.props.dispatch(CompanyActions.preview(Company));
  };

  saveForm = (stayOnList = false) => {
    this.props.dispatch(CompanyActions.createCompany(this.createCompanyForm, stayOnList, this.props.history.push));
  };

  createCompany = () => {
    this.toggleForm(this.createCompanyForm);
  };

  removeCompany = () => this.props.dispatch(FormActions.toggleForm(this.deleteCompanyForm));

  remove = id => this.props.dispatch(CompanyActions.removeCompany(id));

  toggleForm = () => this.props.dispatch(FormActions.toggleForm(this.createCompanyForm));

  formVisible = () => this.props.forms.options.visible.indexOf(this.createCompanyForm) !== -1;

  handleChangePage = (page, limit) => {
    this.props.dispatch(FormActions.storeInput(this.filtersForm, 'page', page));
    this.props.dispatch(FormActions.storeInput(this.filtersForm, 'limit', limit));
    this.fetchResults();
  };

  onNameChange = name => {
    const identifier = name
      ?.replaceAll(' ', '')
      .match(/([0-9a-zA-Z ])/g)
      ?.join('')
      ?.substring(0, 6);
    this.props.dispatch(FormActions.storeInput(this.createCompanyForm, 'identifier', identifier));
  };

  render() {
    const countryCallingCodes = optionsCountries(this.props.common.countryCallingCodes);
    const companies = this.props.companies.list || [];

    return (
      <Container>
        <MainContainer>
          <OldSearchBar title="Lista firm" formName={this.filtersForm} records={this.props.companies.count} onSubmit={this.fetchResults} />

          <Tab name={Constants.Tabs.Companies.name}>
            <div className="row mb-4 mt-3">
              <div className="col-sm-9">
                <ListFilters formName={this.filtersForm} onSubmit={this.fetchResults} />
              </div>
              <div className="col-sm-3 text-right">
                <h4 className="text-themecolor" style={{ cursor: 'pointer' }}>
                  <i className="fa fa-ellipsis-h mr-2" onClick={() => toast.error(t.error.toast.notImplemented)} />
                  <i className="fa fa-plus" onClick={this.createCompany} />
                </h4>
              </div>
            </div>
            <div className="row bt bb">
              <div className="col-sm-12">
                <div className="row grey" style={{ fontSize: '90%' }}>
                  <div className="flex-3 p-2 br">
                    <OrderBy field="identifier" spanClassName="ml-0" column="ID" formName={this.filtersForm} onSubmit={this.fetchResults} />
                  </div>
                  <div className="flex-10 p-2 br">
                    <OrderBy field="name" spanClassName="ml-0" column="Nazwa" formName={this.filtersForm} onSubmit={this.fetchResults} />
                  </div>
                  <div className="flex-4 p-2 br">
                    <OrderBy field="nip" spanClassName="ml-0" column="NIP" formName={this.filtersForm} onSubmit={this.fetchResults} />
                  </div>
                  <div className="flex-8 max-w-200 p-2 br">
                    <OrderBy field="active_email" spanClassName="ml-0" column="E-mail" formName={this.filtersForm} onSubmit={this.fetchResults} />
                  </div>
                  <div className="flex-4 p-2 br">
                    <OrderBy field="active_phone" spanClassName="ml-0" column="Telefon" formName={this.filtersForm} onSubmit={this.fetchResults} />
                  </div>
                  <div className="flex-3 p-2 f90 br">Rola</div>
                  <div className="flex-5 p-2">
                    <OrderBy
                      field="modified_date"
                      spanClassName="ml-0"
                      column="Ostatnia modyfikacja"
                      formName={this.filtersForm}
                      onSubmit={this.fetchResults}
                    />
                  </div>
                </div>
              </div>
            </div>
            {companies.length > 0 ? (
              companies.map(row => (
                <div
                  className="row"
                  onDoubleClick={() => this.props.history.push(`/firmy/${row.slug}`)}
                  onClick={() => this.selectCompanyForPreview(row)}>
                  <div className="col-sm-12">
                    <div className="row bb data-row">
                      <div className="flex-3 p-2 br">
                        <Tooltip label={row.identifier} />
                      </div>
                      <div className="flex-10 p-2 br">{row.name}</div>
                      <div className="flex-4 p-2 br">{formatNip(row.nip)}</div>
                      <div className="flex-8 p-2 br max-w-200">{row.active_email || '-'}</div>
                      <div className="flex-4 p-2 br">{parsePhone(row.active_phone)}</div>
                      <div className="flex-3 p-2 br">
                        {row.business_company
                          ? row.business_company.map(i => (
                            <span key={i.name} className="badge badge-warning pt-1 pb-1 pl-2 pr-2">
                              {i.name}
                            </span>
                          ))
                          : '-'}
                      </div>
                      <div className="flex-5 p-2">{row.modified_date}</div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="row">
                <div className="col-sm-12 mt-3">Brak wyników.</div>
              </div>
            )}
          </Tab>

          {companies && (
            <Pagination
              withSelect
              count={this.props.companies.count}
              currentPage={this.props.companies.currentPage}
              onChangePage={this.handleChangePage}
            />
          )}

          <BaseModal
            visible={this.formVisible(this.createCompanyForm)}
            header="+ Kreator dodawania firmy krok 1 z 2"
            footer="W celu dodania nowej firmy, kliknij przycisk Zapisz."
            onClickAway={() => this.toggleForm(this.createCompanyForm)}>
            <CreateCompanyForm
              countryCallingCodes={countryCallingCodes}
              form={this.props.forms.forms[this.createCompanyForm]}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              visibleOnMount={false}
              visible={this.formVisible(this.createCompanyForm)}
              formName={this.createCompanyForm}
              withButtons
              submitForm={() => this.saveForm()}
              onSubmitAndLeave={() => this.saveForm(true)}
              onCancel={() => this.toggleForm(this.createCompanyForm)}
              companyData={this.props.common.companyData}
              onNameChange={name => this.onNameChange(name)}
            />
          </BaseModal>
        </MainContainer>
        <SidebarContainer>
          <CompanySidebarView
            remove={this.remove}
            removeCompany={this.removeCompany}
            company={this.props.companies.preview}
            loading={this.props.common.loadingPreview}
          />
        </SidebarContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  forms: state.Forms,
  common: state.Common,
  options: state.Options,
  companies: state.Company
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Companies)
);
