import React from 'react';

import { OldCard } from '../../../../components/Layout';
import { RowEditable } from '../../../../components/Layout/RowEditable';

export default (props) => {
  const header = (
    <a
      role="button"
      style={{ fontSize: '24px' }}
      className="collapsed "
      data-toggle="collapse"
      href="#warunkiDodatkowe"
      aria-expanded="false"
      aria-controls="collapseExample"
    >
      Autoryzowany email klienta
    </a>
  );

  const customCreateSection = (
    <>
      {/* <i className="fas fa-save ml-2 mr-2 " onClick={() => props.saveForm(null, false)} /> */}
      <i className="fas fa-question-circle" onClick={() => { }} />
    </>
  );

  return (
    <OldCard
      header={header}
      id="financialData-card"
      showIcons
      customCreateSection={customCreateSection}
    >
      <div className="collapse show" id="financialData">
        <div className="row mt-2 align-items-center mb-2" style={{ fontWeight: '600', color: '#878F9A', fontSize: '90%' }}>
          <div className="col-sm-2 p-2 text-right">
            Klient
          </div>
          <div className="col-sm-10">
            <span className="mr-3" style={{ fontWeight: '600', color: '#4B4E55' }}>
              {props.claim?.client?.name ?? '-'}
            </span>

            {/* <button type="button" className="ml-4" onClick={() => props.toggleForm('emails')}> */}
            {/*  Zarządzaj */}
            {/* </button> */}
          </div>
        </div>
        <div className="row bb bt">
          <div className="col-sm-2 p-2 grey br">E-mail firmy</div>
          <div className="col-sm-2 p-2 grey br">Wprowadził</div>
          <div className="col-sm-2 p-2 grey">Data autoryzacji</div>
        </div>
        {props.claim?.client_authorized_emails?.map((i) => (
          <RowEditable>
            <div className="col-sm-2 p-2  br">{i.email}</div>
            <div className="col-sm-2 p-2  br">{i.user_creator?.full_name}</div>
            <div className="col-sm-2 p-2  ">{i.created_date}</div>
          </RowEditable>
        ))}
      </div>
    </OldCard>
  );
};
