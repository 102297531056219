import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useEffect } from 'react';

import { RowEditable } from '../../../../../components/Layout/RowEditable';
import { getGroupOfErrors, parseMoney } from '../../../../../utilities/helpers';
import LiabilitiesForm from '../../../Forms/Liabilities/Other';
import FormActions from 'actions/forms';
import { useDispatch } from 'react-redux';

export default props => {
  const dispatch = useDispatch();
  const formName = 'OtherLiabilitiesCreatorForm';
  const modalFormName = 'OtherLiabilitiesCreatorEditForm';

  useEffect(() => {
    if (props.populateForm) {
      props.populateForm(formName);
    }
    // eslint-disable-next-line
  }, props);

  const canBeSubmitted =
    props.forms[formName] &&
    props.forms[formName].type_of_dept_id &&
    props.forms[formName].expiration_date &&
    props.forms[formName].current_debt;

  const rows = props.version && props.version.other_obligations ? props.version.other_obligations : [];

  const handleBackLightFields = () => {
    dispatch(FormActions.handleValidErrors(formName, ['type_of_dept_id', 'expiration_date', 'current_debt']));
  };

  return (
    <Card mode='creator' header='+ Zobowiązania pozostałe' onSaveClick={() => props.saveForm(formName, true)} isBlocked={!canBeSubmitted} onBlockedMessage={handleBackLightFields}>
      <LiabilitiesForm
        proposalData={props.proposalData}
        populate={() => props.populateForm(formName)}
        forms={props.forms}
        preloadedForm
        visibleOnMount
        visible
        clear={props.clear}
        formName={formName}
        submitForm={() => props.saveForm(formName)}
        onCancelButtonClick={() => props.toggleForm(formName)}
      />

      {rows.length ? (
        <div className="row bt">
          <div className="col-sm-12">
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Rodzaj zadłużenia</div>
              <div className="col-sm-3 p-2 br grey">Wierzyciel</div>
              <div className="col-sm-2 p-2 br grey">Aktualne zadłużenie</div>
              <div className="col-sm-2 p-2 grey">Data spłaty</div>
            </div>
            {rows.map(row => (
              <RowEditable
                key={row.identifier}
                onEdit={() => {
                  const editableRow = {
                    type_of_dept_id: row.type_of_dept ? row.type_of_dept.id : null,
                    id: row.id,
                    current_debt: row.current_debt,
                    expiration_date: row.expiration_date,
                    creditor: row.creditor
                  };
                  delete editableRow.type_of_debt;
                  props.edit(editableRow, modalFormName);
                }}
                onRemove={() => {
                  props.remove('other_obligation', row.id);
                }}>
                <div className="col-sm-3 p-2 br ">{row.type_of_dept ? row.type_of_dept.name : '-'}</div>
                <div className="col-sm-3 p-2 br ">{row.creditor}</div>
                <div className="col-sm-2 p-2 br ">{parseMoney(row.current_debt)}</div>
                <div className="col-sm-2 p-2">{row.expiration_date}</div>
              </RowEditable>
            ))}
          </div>
        </div>
      ) : null}

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Zobowiązania pozostałe</span>
          </div>
        }
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}>
        <LiabilitiesForm
          withButtons
          proposalData={props.proposalData}
          populate={() => props.populateForm(modalFormName)}
          forms={props.forms}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(modalFormName)}
          formName={modalFormName}
          submitForm={() => props.saveForm(modalFormName)}
          onCancelButtonClick={() => props.toggleForm(modalFormName)}
        />
      </BaseModal>
    </Card>
  );
};
