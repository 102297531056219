import { STATUS_PROPOSAL } from 'containers/Proposals/Details/Index';
import { OptionsActions } from 'ducks/options';
import { t } from 'lang/pl';
import { toast } from 'react-toastify';
import HttpClient from 'utilities/http';

import ProposalService, { GENERATE_TYPE } from '../services/proposal';
import CommonActions from './common';
import FormActions from './forms';

const updateView = (id, getState, dispatch) => {
  dispatch(OptionsActions.toggleLoader(true));
  const { version } = getState().Proposal;

  ProposalService.fetchDetails(id).then(async (response) => {
    const payload = response.data;
    const updatedVersion = payload?.proposal_versions?.find(item => item?.id === version?.id) ?? payload.proposal_versions[0];

    payload.debtors = await ProposalService.fetchDetailsDebtors(id, updatedVersion?.id);
    payload.risks = await ProposalService.fetchDetailsDebtorsRisks(id, updatedVersion?.id);

    dispatch({ type: 'PROPOSAL_DETAILS_UPDATED', payload });
    dispatch({ type: 'PROPOSAL_VERSION_FETCHED', payload: updatedVersion });
    dispatch(OptionsActions.toggleLoader(false));
  });
};

const saveForm = (form, id, idVersion, hide = false) => (dispatch, getState) => {
  const data = getState().Forms.forms[form];
  const { proposal } = getState().Proposal;

  if (form === 'SecuresForm' || form === 'SecuresCreatorForm' || form === 'SecuresCreatorEditForm') {
    data.issuer_id = proposal.client.id;
  }

  dispatch(FormActions.clearErrors(form));

  ProposalService.saveForm(form, data, id, idVersion)
    .then(() => {
      dispatch(CommonActions.toggleModal());
      updateView(id, getState, dispatch);

      document.getElementsByTagName('html')[0].style = '';

      const formsToBeLeftFilled = ['RiskCreatorForm', 'PublicLiabilitiesCreatorForm', 'ScoringCreatorForm', 'DebtorsRiskForm', 'ReadinessForm', 'ConditionsForm', 'AdministratorForm'];
      let clearForm = true;

      formsToBeLeftFilled.forEach((e) => {
        if (form.indexOf(e) !== -1) {
          clearForm = false;
        }
      });

      if (clearForm) {
        dispatch(FormActions.clearForm(form));
      }

      if (hide) {
        dispatch(FormActions.toggleForm(form));
      }

      toast.success('Zaktualizowano dane.');
    }).catch(errors => dispatch(FormActions.handleErrors(form, errors?.response?.data)));
};

export default {
  selectForPreview: (version) => async (dispatch) => {
    dispatch({ type: 'PROPOSAL_FOR_PREVIEW_FETCHED', payload: version });
    dispatch({ type: 'PROPOSAL_VERSION_FETCHED', payload: version });
  },
  copy: (push) => (dispatch, getState) => {
    const proposalId = getState().Proposal.proposal.id;
    ProposalService.copy(proposalId, GENERATE_TYPE.CLEAN_VERSION).then((response) => {
      const proposal = {
        ...response.data
        // factor: await ProposalService.fetchFactor(response.data.factor.id),
        // client: await ProposalService.fetchClient(response.data.client.id),
      };

      dispatch({ type: 'PROPOSAL_DETAILS_FETCHED', payload: proposal });
      dispatch({ type: 'PROPOSAL_TAB_CHANGED', payload: 1 });

      push(`/wnioski/${response.data.slug}/kreator`);
    });
  },
  fetch: (filters) => (dispatch) => {
    const f = filters || CommonActions.defaultFilters;
    ProposalService.fetch(f).then((response) => {
      dispatch({ type: 'PROPOSALS_FETCHED', payload: response.data });
      dispatch({ type: 'PROPOSAL_FOR_PREVIEW_FETCHED', payload: response.data?.results?.[0] });
      dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
    });
  },
  createNewVersion: () => (dispatch, getState) => {
    const proposalId = getState().Proposal.proposal.id;
    ProposalService.copy(proposalId, GENERATE_TYPE.LAST_VERSION).then(({ data }) => {
      ProposalService.fetchDetails(proposalId).then(async (response) => {
        const payload = response.data;
        const updatedVersion = payload?.proposal_versions?.find(item => item?.id === data.id);

        payload.debtors = await ProposalService.fetchDetailsDebtors(proposalId, data.id);
        payload.risks = await ProposalService.fetchDetailsDebtorsRisks(proposalId, data.id);

        dispatch({ type: 'PROPOSAL_DETAILS_UPDATED', payload });
        dispatch({ type: 'PROPOSAL_VERSION_FETCHED', payload: updatedVersion });
      });
    });
  },
  fetchForForm: (resource, filtersFormName) => (dispatch, getState) => {
    let filters = getState().Forms.forms[filtersFormName];

    if(filtersFormName === 'OaFiltersForm') {
      filters = { ...filters, search_mode: 'company' };
    }

    ProposalService.fetchForForm(resource, filters).then((response) => {
      dispatch({ type: 'PROPOSAL_FORMDATA_FETCHED', payload: { data: response.data, resource } });
    });
  },
  fetchDetails: (id, loader = true) => (dispatch, getState) => {
    dispatch(OptionsActions.toggleLoader(loader));
    const { version } = getState().Proposal;

    if (loader) {
      dispatch({ type: 'CONTENT_LOADING' });
      dispatch({ type: 'CONTENT_PREVIEW_LOADING' });
    }

    return ProposalService.fetchDetails(id).then(async (response) => {
      const payload = response?.data;
      const updatedVersion = payload?.proposal_versions?.find(item => item?.id === version?.id) ?? payload.proposal_versions[0];

      payload.debtors = await ProposalService.fetchDetailsDebtors(id, updatedVersion.id);
      payload.risks = await ProposalService.fetchDetailsDebtorsRisks(id, updatedVersion.id);

      dispatch({ type: 'PROPOSAL_DETAILS_UPDATED', payload });
      dispatch({ type: 'PROPOSAL_VERSION_FETCHED', payload: updatedVersion });
      dispatch({ type: 'CONTENT_LOADED' });

      if (loader) {
        dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
        dispatch(OptionsActions.toggleLoader(false));
      }

      return payload;
    });
  },
  fetchProposalVersion: (idProposal, idVersion) => () => HttpClient.get(`/proposals/${idProposal}/proposal_versions/${idVersion}/`),
  changeTab: (tab) => (dispatch) => dispatch({
    type: 'PROPOSAL_TAB_CHANGED',
    payload: tab
  }),
  create: (formName, push) => (dispatch, getState) => {
    const formData = getState().Forms.forms[formName];
    const data = {
      offer_id: formData.offer ? formData.offer.id : null,
      limit_amount: formData.limit_amount,
      client_id: formData.client ? formData.client.id : null,
      duration_of_the_contract: formData.duration_of_the_contract,
      recourse: formData.recourse,
      factor_id: formData.factor ? formData.factor.id : null,
      trade_supervisor_id: formData.trade_supervisor ? formData.trade_supervisor.id : null,
      billing_manager_id: formData.billing_manager ? formData.billing_manager.id : null
    };
    if (formData.operational_administrator) {
      data.operational_administrator_id = formData.operational_administrator.id;
    }
    ProposalService.create(data).then(async (response) => {
      const updatedVersion = response.data.proposal_version;

      dispatch({ type: 'PROPOSAL_VERSION_FETCHED', payload: updatedVersion });
      dispatch({ type: 'PROPOSAL_DETAILS_FETCHED', payload: response.data });
      dispatch({ type: 'PROPOSAL_TAB_CHANGED', payload: 1 });

      dispatch(FormActions.clearForm(formName));
      dispatch(FormActions.toggleForm(formName));

      push(`/wnioski/${response.data.slug}/kreator`);
    })
      .catch((errors) => dispatch(FormActions.handleErrors(formName, errors?.response?.data)));
  },
  saveForm,
  fetchFactors: () => (dispatch) => {
    ProposalService.fetchForForm('factors')
      .then((res) => {
        dispatch({
          type: 'PROPOSAL_FACTORS_FETCHED',
          payload: res.data.results
        });
      })
      .catch(() => toast.error('Wystąpił błąd.'));
  },
  remove: (id, o, oId) => (dispatch, getState) => ProposalService.remove(id, o, oId, getState().Proposal.version.id).then(() => {
    dispatch(CommonActions.toggleModal());
    updateView(id, getState, dispatch);

    document.getElementsByTagName('html')[0].style = '';
    toast.success('Usunięto dane.');
  }),
  fetchFromOffer: (debtor, object) => (dispatch, getState) => {
    const proposalId = getState().Proposal.proposal.id;
    const versionId = getState().Proposal.version.id;

    ProposalService.fetchFromOffer(proposalId, debtor, object, versionId).then(() => {
      updateView(proposalId, getState, dispatch);
    });

    document.getElementsByTagName('html')[0].style = '';
    toast.success('Przywrócono dane z oferty.');
  },
  updateAmlDecision: (formName) => (dispatch, getState) => {
    const { proposal, version } = getState().Proposal;
    const latestDecision = version?.aml_decisions?.length ? version.aml_decisions[0] : null;

    if(latestDecision) {
      ProposalService.updateAmlDecision(proposal.id, latestDecision.id, version.id).then(() => {
        dispatch(FormActions.toggleForm(formName));
        updateView(proposal.id, getState, dispatch);

        document.getElementsByTagName('html')[0].style = '';
        toast.success('Zaktualizowano dane.');
      });
    }
  },
  approve: (id) => (dispatch, getState) => ProposalService.approve(id, getState().Proposal.version.id).then(() => {
    toast.success('Wniosek zaakceptowany.');
    updateView(id, getState, dispatch);
  }).catch(() => {
    toast.error('Wniosek nie został zaakceptowany.');
  }),
  selectVersion: version => async (dispatch, getState) => {
    const { proposal } = getState().Proposal;

    proposal.debtors = await ProposalService.fetchDetailsDebtors(proposal.id, version?.id);
    proposal.risks = await ProposalService.fetchDetailsDebtorsRisks(proposal.id, version?.id);

    dispatch({ type: 'PROPOSAL_DETAILS_UPDATED', payload: proposal });
    dispatch({ type: 'PROPOSAL_VERSION_FETCHED', payload: version });
  },
  sendForDecision: (id) => (dispatch, getState) => {
    const { proposal } = getState().Proposal;
    const idApprovedVersion = proposal?.proposal_versions?.find(item => item.status >= STATUS_PROPOSAL.ZATWIERDZONY)?.id;

    return ProposalService.sendForDecision(id, idApprovedVersion).then(() => {
      toast.success('Wniosek wysłany do decyzji.');
      updateView(id, getState, dispatch);
    });
  },

  removeNote: (proposalId, noteId) => async (dispatch, getState) => {
    await ProposalService.removeNote(proposalId, noteId);
    dispatch({ type: 'NOTE_REMOVED' });

    updateView(proposalId, getState, dispatch);
  },

  addCriteria: qualities => (dispatch, getState) => {
    const { proposal, version } = getState().Proposal;
    if(!qualities) return null;

    let delay = 0; const delayIncrement = 100;

    const promises = qualities.flatMap((item) => item.definitions).map(data => {
      delay += delayIncrement;
      return new Promise(resolve => setTimeout(resolve, delay)).then(() =>
        HttpClient.post(`/proposals/${proposal.id}/proposal_versions/${version.id}/criteria/`, data).catch(error => error?.response));
    });

    return Promise.all(promises).then(qualitiesResponse => {
      if(qualitiesResponse[0].status === 400) {
        toast.warn(t.error.toast.tryAgain);
        return null;
      } else {
        const errors = qualitiesResponse.filter(response => response?.idError);
        errors?.length ? toast.warn(t.error.toast.fieldsError) : toast.success(t.success.toast.dataUpdated);
        return errors;
      }
    });
  },

  updateCriteria: qualities => (dispatch, getState) => {
    const { proposal, version } = getState().Proposal;
    if(!qualities) return null;

    let delay = 0; const delayIncrement = 100;

    const promises = qualities.flatMap((item) => item.definitions).map(data => {
      delay += delayIncrement;
      return new Promise(resolve => setTimeout(resolve, delay)).then(() =>
        HttpClient.put(`/proposals/${proposal.id}/proposal_versions/${version.id}/criteria/${data.id}/`, data)
          .catch(error => ({ idError: data.id, ...error.response })));
    });

    return Promise.all(promises).then(qualitiesResponse => {
      if(!qualitiesResponse[0]) {
        toast.warn(t.error.toast.tryAgain);
        return null;
      } else {
        const errors = qualitiesResponse.filter(response => response?.idError);
        errors?.length ? toast.warn(t.error.toast.fieldsError) : toast.success(t.success.toast.dataUpdated);
        return errors;
      }
    });
  },

  changeProposalStatus: (idProposal, status) => (dispatch, getState) => HttpClient.patch(`/proposals/${idProposal}/status/`, { status })
    .then(() => toast.success(t.success.toast.statusChanged)).then(() => updateView(idProposal, getState, dispatch)),

  deleteProposalVersion: (idProposal, idVersion) => (dispatch, getState) => HttpClient.delete(`/proposals/${idProposal}/proposal_versions/${idVersion}/`)
    .then(() => toast.success('Wersja wniosku została usunięta.')).then(() => updateView(idProposal, getState, dispatch))
};
