import { ColNameBig } from 'components/Forms/Col';
import SelectableSmall from 'components/Forms/SelectableSmall';
import React from 'react';

import { Form, Select } from '../../../components/Forms';

export default (props) => {
  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row mt-3">
            <div className="col-sm-8">
              <div className="form-group row  align-items-center">
                <ColNameBig>Sposób reprezentacji</ColNameBig>
                <div className="col-sm-6">
                  <Select formName={props.formName} options={props.representations.map((r) => ({ ...r, value: r.id }))} name="representation_id" />
                </div>
              </div>
            </div>
            <div className="mt-07">
              <SelectableSmall
                title='Wybierz reprezentantów'
                name='people_in_company_ids'
                formName={props.formName}
                options={props.representants}
                columns={[{ name: 'Imię i nazwisko', property: 'name' }, { name: 'Rola biznesowa', property: 'roles' }, { name: 'Data', property: 'date' }]}
              />
            </div>
          </div>

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 flex align-items-center justify-content-center">
                <button
                  type="button"
                  onClick={props.onCancelButtonClick}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}
                >
                  Anuluj
                </button>
                <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
