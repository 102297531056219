import React, { useState } from 'react';

import { OldCard } from '../../../../components/Layout';
import { BaseModal } from 'components/Layout/OldModal';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parseMoney } from '../../../../utilities/helpers';
import RecalculateForm from '../../Forms/Recalculate';
import { SettleIncomeForm } from '../../Forms/SettleIncome';

export default props => {
  const [incomeToSettle, setIncomeToSettle] = useState('');
  const formName = 'SettleIncomeTransferForm';
  const recalculateFormName = 'RecalculateForm';

  const header = (
    <a
      role="button"
      style={{ fontSize: '24px' }}
      className="collapsed "
      data-toggle="collapse"
      href="#warunkiDodatkowe"
      aria-expanded="false"
      aria-controls="collapseExample">
      Wpływy do rozliczenia
    </a>
  );

  const customCreateSection = (
    <>
      <i className="fas fa-question-circle ml-2 mr-2 " onClick={() => {}} />
    </>
  );

  const rows = props.claim?.subpayments ?? [];

  const edit = e => {
    setIncomeToSettle(e);
    props.edit(formName, {
      ...e
    });
  };

  const recalculate = e => {
    props.edit(recalculateFormName, {
      ...e
    });
  };

  const shouldDisplayRecalculateButton = payment => {
    const fieldToCompare = props.claim?.purchase_currency || props.claim?.purchase_custom_value_currency || {};
    return fieldToCompare.name === 'PLN' && payment.amount_currency?.name !== 'PLN';
  };

  const isCompensate = payment => payment?.identifier?.[0] === "K";

  return (
    <OldCard header={header} id="financialData-card" showIcons customCreateSection={customCreateSection}>
      <div className="collapse show" id="financialData">
        <div className="row bb">
          <div className="col-sm-2 p-2 grey br">Podstawa wpływu</div>
          <div className="col-sm-1 p-2 grey br">Data wpływu</div>
          <div className="col-sm-1 p-2 grey br">Kwota wpływu</div>
          <div className="col-sm-1 p-2 grey br">Waluta</div>
          <div className="col-sm-1 p-2 grey br">Rozliczono</div>
          <div className="col-sm-1 p-2 grey br">Do rozliczenia</div>
          <div className="col-sm-1 p-2 grey br">Kwota w walucie</div>
          <div className="col-sm-1 p-2 grey br">Kurs</div>
          <div className="col-sm-1 p-2 grey br">Sposób rozliczenia</div>
          <div className="col-sm-2 p-2 grey">Status</div>
        </div>

        {rows
          .filter(payment => Number(payment.to_pay_value) > 0)
          .map(payment => (
            <RowEditable onRemove={payment?.paid_value > 0 ? undefined : () => props.removeLink(payment.id, payment.payment_id)}>
              <div className="col-sm-2 p-2  br">{payment.identifier}</div>
              <div className="col-sm-1 p-2  br">{payment.posting_date}</div>
              <div className="col-sm-1 p-2  br">{parseMoney(payment.amount)}</div>
              <div className="col-sm-1 p-2  br">{payment.amount_currency?.name ?? '-'}</div>
              <div className="col-sm-1 p-2  br"> {parseMoney(payment.paid_value)}</div>
              <div className="col-sm-1 p-2  br"> {parseMoney(payment.to_pay_value)}</div>
              <div className="col-sm-1 p-2  br">-</div>
              <div className="col-sm-1 p-2  br">-</div>
              <div className="col-sm-1 p-2  br">{isCompensate(payment) ? "Kompensata" : "Przelew"}</div>
              <div className="col-sm-2 p-2 ">
                {payment.is_settled ? 'Rozliczony' : 'Nierozliczony'}
                {shouldDisplayRecalculateButton(payment) ? (
                  <button type="button" className="ml-2" onClick={() => recalculate(payment)}>
                    Przelicz
                  </button>
                ) : null}
                <button type="button" className="ml-2" onClick={() => edit(payment)}>
                  Rozlicz
                </button>
              </div>
            </RowEditable>
          ))}
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Rozliczenie wpływów {incomeToSettle?.outgoing_payment_title || incomeToSettle?.identifier || ''}</span>
          </div>
        }
        footer="Aby zapisać dokument wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}>
        <SettleIncomeForm
          docs={props.docs}
          docsFilters={props.docsFilters}
          fetchDocs={props.fetchDocs}
          fetchDocuments={props.fetchDocuments}
          documents={props.documents}
          documentsFilters={props.documentsFilters}
          clients={props.clients}
          clientFilters={props.clientFilters}
          fetchClients={props.fetchClients}
          forms={props.forms}
          edit={props.edit}
          submitForm={(f, h = false) => props.saveForm(f, h)}
          withButtons
          formVisible={props.formVisible}
          toggleForm={props.toggleForm}
          formName={formName}
          onCancelButtonClick={() => props.toggleForm(formName)}
          claim={props.claim}
          storeInput={props.storeInput}
          common={props.common}
          clear={props.clear}
          fetchBuckets={props.fetchBuckets}
          buckets={props.buckets}
          generateTransfer={id => {
            props.saveForm(`GenerateTransfer.${id}`, false);
          }}
          removeTransfer={props.removeTransfer}
          linkChargeWithPayment={props.linkChargeWithPayment}
          removeChargeFromPayment={props.removeChargeFromPayment}
          compensate={props.compensate}
          sendCompensation={f => props.sendCompensation(f, formName)}
          removeCompensation={props.removeCompensation}
        />
      </BaseModal>

      <BaseModal
        size="medium"
        visible={props.formVisible(recalculateFormName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Przelicz</span>
          </div>
        }
        onClickAway={() => props.toggleForm(recalculateFormName)}>
        <RecalculateForm
          forms={props.forms}
          recalculate={() =>
            props.saveForm(recalculateFormName, false, r => {
              props.storeInput(recalculateFormName, 'average_rate', r.data.average_rate);
              props.storeInput(recalculateFormName, 'calc_value', r.data.calc_value);
            })
          }
          accept={() => {
            props.storeInput(recalculateFormName, 'shouldUpdate', true);
            props.saveForm(recalculateFormName, true);
          }}
          withButtons
          formVisible={props.formVisible}
          toggleForm={props.toggleForm}
          formName={recalculateFormName}
          onCancelButtonClick={() => props.toggleForm(recalculateFormName)}
          claim={props.claim}
          common={props.common}
          storeInput={props.storeInput}
        />
      </BaseModal>
    </OldCard>
  );
};
