import Card from 'components/Layout/Card';
import React, { useState } from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { Selectable } from '../../../../components/Forms';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parsePhone } from '../../../../utilities/helpers';

export default props => {
  const [active, setActive] = useState(false);
  const [personInCompany, setPersonInCompany] = useState(false);

  const v = props.version || {};

  const columns = [
    { name: 'E-mail', getter: 'email', sortable: 1 },
    { name: 'Imię i nazwisko', getter: 'full_name', sortable: 1 },
    {
      name: 'Rola biznesowa',
      getter: 'roles',
      sortable: 1,
      mutator: g => g?.join(', ')
    },
    {
      name: 'Telefon',
      getter: 'active_phone',
      sortable: 1,
      mutator: parsePhone
    },
    { name: 'Powiązane od', getter: 'created_date', sortable: 1 }
  ];

  const title = (
    <>
      <div className="row align-items-center">
        <div className="col-sm-2 p-2 text-right grey" style={{ fontWeight: '600' }}>
          Klient
        </div>
        <div className="col-sm-5 p-2 " style={{ fontWeight: '600' }}>
          {props.agreement?.client?.name ?? '-'}
        </div>
        <div className="col-sm-2 p-2 text-right d-flex align-items-center">
          Tylko aktywne
          <BooleanCheck className="ml-3" field={active} onClick={() => setActive(!active)} />
        </div>
        <div className="col-sm-3 p-2 text-right d-flex align-items-center">
          Tylko osoby powiązane
          <BooleanCheck className="ml-3" field={personInCompany} onClick={() => setPersonInCompany(!personInCompany)} />
        </div>
      </div>
    </>
  );

  const emails = props.agreement?.client_emails
    ?.filter(x => !props.version?.client_authorized_emails?.find(y => y.email === x.email))
    ?.filter(x => (active ? x.is_active : true))
    ?.filter(x => (personInCompany ? x.is_person_in_company : true));

  return (
    <Card mode='creator' header='+ Autoryzowany email klienta' options={{ isPosted: true }}>
      <div>
        <div className="row mt-2 align-items-center mb-2" style={{ fontWeight: '600', color: '#878F9A' }}>
          <div className="col-sm-2 p-2 text-right">Klient</div>
          <div className="col-sm-10">
            <span className="mr-3" style={{ fontWeight: '600', color: '#4B4E55' }}>
              {props.agreement?.client?.name ?? '-'}
            </span>

            <button type="button" className="ml-4" onClick={() => props.toggleForm('emails')}>
              Zarządzaj
            </button>
          </div>
        </div>
        <div className="row bb bt">
          <div className="col-sm-2 p-2 grey br">E-mail firmy</div>
          <div className="col-sm-2 p-2 grey br">Wprowadził</div>
          <div className="col-sm-2 p-2 grey">Data autoryzacji</div>
        </div>
        {v?.client_authorized_emails?.map(i => (
          <RowEditable onRemove={() => props.remove('client_authorized_emails', i.id)}>
            <div className="col-sm-2 p-2  br">{i.email}</div>
            <div className="col-sm-2 p-2  br">{i.user_creator?.full_name}</div>
            <div className="col-sm-2 p-2  ">{i.created_date}</div>
          </RowEditable>
        ))}
      </div>

      <Selectable
        multiple
        fetch={() => {}}
        // fetch={props.fetch}
        visible={props.formVisible('emails')}
        formName="client_emails"
        field="client_emails"
        columns={columns}
        title={title}
        options={emails}
        onClickAway={() => props.toggleForm('emails')}
        header="Wybierz email"
        footer="Aby zapisać wciśnij ZATWIERDŹ"
        onSelected={() => {
          props.saveForm('client_emails', false);
          props.toggleForm('client_emails');
          props.clearForm('client_emails');
        }}
      />
    </Card>
  );
};
