import React from 'react';

import {
  Buttons,   Form, Select
} from '../../../components/Forms';
import { ColName, ColNameBig } from 'components/Forms/Col';
import { storeInput } from 'reducers/forms';
import api from 'api';
import { useDispatch } from 'react-redux';
import Selectable from 'components/Forms/Selectable';

export const PeopleForm = (props) => {
  const dispatch = useDispatch();

  return (
    <>
      <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
        {props.visible ? (
          <>
            {props.errors && props.errors.length > 0 ? (
              <div className="row mt-3 mb-3">
                <div className="col-sm-12">{props.errors[0]}</div>
              </div>
            ) : null}
            <div className="row mt-3">
              <div className="col-sm-6">
                <div className="form-group row  align-items-center">
                  <ColName className='col-sm-6 text-right'>Rola biznesowa</ColName>
                  <div className="col-sm-6">
                    <Select formName={props.formName} options={props.roles} name="role" errorName='roles_ids' />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className='t-row'>
                  <ColNameBig>Osoba</ColNameBig>
                  <Selectable
                    title="Czy chcesz powiązać z osobą"
                    columns={['Imię i nazwisko', 'Adres e-mail']}
                    names={['full_name', 'active_email']}
                    apiFunction={api.persons.getList}
                    formName={props.formName}
                    errorName='person_id'
                    name='person'
                  />
                </div>
              </div>
            </div>
            {props.withButtons ? (
              <Buttons
                formName={props.formName}
                onCancel={props.onCancelButtonClick}
                onSubmit={props.submitForm}
                onSubmitAndLeave={props.onSubmitAndLeave}
              />
            ) : null}
          </>
        ) : null}
      </Form>
    </>
  );
};
