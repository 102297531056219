import AgreementActions from 'actions/agreement';
import FormActions from 'actions/forms';
import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import Tooltip from 'components/Layout/Tooltip';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { File, Form, Select, Selectable } from '../../../../components/Forms';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import Documents from '../../Forms/Documents';

export default props => {
  const dispatch = useDispatch();
  const { agreement } = useSelector(state => state.Agreement);

  const formName = 'DocumentsForm';
  const manageFormName = 'DocumentsManageForm';

  const [documentType, setDocumentType] = useState(0);

  const d = props.version?.generated_documents_agreement ?? { 0: [], 1: [], 2: [] };

  useEffect(() => {
    dispatch(FormActions.storeInput(formName, 'product', agreement?.product?.id));
    dispatch(FormActions.storeInput(formName, 'procedure', agreement?.procedure?.id));
  }, []);

  const edit = i => {
    props.edit(manageFormName, {
      ...i,
      dts_form_id: i.dts_form?.id,
      dts_type_id: i.dts_type?.id,
      is_factor_pattern: Number(i.is_factor_pattern),
      indefinite_period_of_validity: Number(i.indefinite_period_of_validity)
    });
  };

  const generate = i => {
    const firstAttachment = i.attachments?.[0] ?? {};

    if (firstAttachment.id && !firstAttachment.generated_file) {
      props.edit(manageFormName, i, true);
      props.generate(manageFormName, 'generated_documents', firstAttachment.id, 'generate', false, true);
    }
  };

  const columns = [
    { name: 'Nazwa', getter: 'document_name.name', sortable: 1 },
    { name: 'Wersja', getter: 'ap_version', sortable: 1 },
    { name: 'Autor', getter: 'author.full_name', sortable: 1 },
    { name: 'Produkt', getter: 'product.name', sortable: 1 },
    { name: 'Procedura', getter: 'procedure.name', sortable: 1 }
  ];

  const title = (
    <Form formName={props.formName} asDiv divClasses="row align-items-center">
      <div className="col-sm-2 col-form-label label-right pb-15">
        Wybierz produkt
      </div>
      <div className="col-sm-3 align-items-center">
        <Select number formName={formName} name="product" options={props.common.offerData?.products?.map(x => ({ value: x.id, name: x.name }))} />
      </div>
      <div className="col-sm-2 col-form-label label-right pb-15">
        Wybierz procedurę
      </div>
      <div className="col-sm-3 align-items-center">
        <Select number formName={formName} name="procedure" options={props.common.offerData?.procedures?.map(x => ({ value: x.id, name: x.name }))} />
      </div>
    </Form>
  );

  const f = props.forms?.[formName] || {};
  let options = [];

  if (documentType !== null) {
    options = props.common.agreementData?.templates?.[documentType];
  } else {
    options = [
      ...(props.common.agreementData?.templates?.[0] ?? []),
      ...(props.common.agreementData?.templates?.[1] ?? []),
      ...(props.common.agreementData?.templates?.[2] ?? [])
    ];
  }

  if (f.product && f.procedure) {
    options = options.filter(o => o.product.id === f.product && o.procedure.id === f.procedure);
  } else if (f.product) {
    options = options.filter(o => o.product.id === f.product);
  } else if (f.procedure) {
    options = options.filter(o => o.procedure.id === f.procedure);
  }

  const handleCloseModal = () => {
    dispatch(AgreementActions.fetchDetails(agreement.id));
    dispatch(FormActions.toggleForm(manageFormName));
  };

  return (
    <Card header='Dokumenty umowy'>
      <div>
        <div className="row bb">
          <div className="col-12 p-2" style={{ color: '#006BFF' }}>
            Dokumenty umowy
            <span className="float-right" style={{ cursor: 'pointer' }}>
              <i
                className="fa fa-plus ml-2"
                onClick={() => {
                  setDocumentType(0);
                  props.toggleForm(formName);
                }}
              />
            </span>
          </div>
        </div>
        <div className="row bb">
          <div className="col-4 p-2 br grey">Nazwa dokumentu</div>
          <div className="col-1 p-2 br grey">Wersja PA</div>
          <div className="col-1 p-2 br grey">Produkt</div>
          <div className="col-1 p-2 br grey">Procedura</div>
          <div className="col-2 p-2 br grey">Nr dokumentu</div>
          <div className="col-1 p-2 br grey">Status</div>
          <div className="col-2 p-2">
            {/* <button type="button" onClick={() => {}} className="mr-2"> */}
            {/*  ... */}
            {/* </button> */}
            {/* <button type="button" onClick={() => props.toggleForm(formName)} className="mr-2"> */}
            {/*  Generuj */}
            {/* </button> */}
          </div>
        </div>
        {d[0].map(i => (
          <RowEditable onRemove={i.is_required ? undefined : () => props.remove('generated_documents', i.id)}>
            <div className="col-4 p-2 br">{i.document_name?.name}</div>
            <div className="col-1 p-2 br">{i.ap_version}</div>
            <div className="col-1 p-2 br">{i.product?.name}</div>
            <div className="col-1 p-2 br">{i.procedure?.name}</div>
            <div className="col-2 p-2 br"><Tooltip label={i.identifier} /></div>
            <div className="col-1 p-2 br">{props.common.agreementData?.document_agreement_statuses?.[i.status]}</div>
            <div className="col-2 p-2">
              <button type="button" onClick={() => edit(i)} className="mr-2">
                ...
              </button>
              {i.attachments?.[0].generated_file ? (
                <button
                  type="button"
                  className="mr-2"
                  onClick={() => {
                    window.open(process.env.REACT_APP_BACKEND_LINK + i.attachments?.[0].generated_file, '_blank');
                  }}>
                  Podgląd
                </button>
              ) : i.is_factor_pattern ? (
                <button type="button" onClick={() => generate(i)} className="mr-2">
                  Generuj
                </button>
              ) : null}
              {i.status === 1 ? (
                <button className="ml-1" type="button" onClick={() => props.archive('generated_documents', i.id)}>
                  Archiwizuj
                </button>
              ) : null}
            </div>
          </RowEditable>
        ))}
        <div className="row bb">
          <div className="col-12 p-2" style={{ color: '#006BFF' }}>
            Dokumenty AML Faktoranta
            <span className="float-right" style={{ cursor: 'pointer' }}>
              <i
                className="fa fa-plus ml-2"
                onClick={() => {
                  setDocumentType(1);
                  props.toggleForm(formName);
                }}
              />
            </span>
          </div>
        </div>
        <div className="row bb">
          <div className="col-4 p-2 br grey">Nazwa dokumentu</div>
          <div className="col-1 p-2 br grey">Wersja PA</div>
          <div className="col-1 p-2 br grey">Produkt</div>
          <div className="col-1 p-2 br grey">Procedura</div>
          <div className="col-2 p-2 br grey">Nr dokumentu</div>
          <div className="col-1 p-2 br grey">Status</div>
          <div className="col-2 p-2">
            {/* <button type="button" onClick={() => {}}> */}
            {/*  Zarządzaj */}
            {/* </button> */}
          </div>
        </div>
        {d[1].map(i => (
          <RowEditable onRemove={i.is_required ? undefined : () => props.remove('generated_documents', i.id)}>
            <div className="col-4 p-2 br">{i.document_name?.name}</div>
            <div className="col-1 p-2 br">{i.ap_version}</div>
            <div className="col-1 p-2 br">{i.product?.name}</div>
            <div className="col-1 p-2 br">{i.procedure?.name}</div>
            <div className="col-2 p-2 br"><Tooltip label={i.identifier} /></div>
            <div className="col-1 p-2 br">{props.common.agreementData?.document_agreement_statuses?.[i.status]}</div>
            <div className="col-2 p-2">
              <button type="button" onClick={() => edit(i)} className="mr-2">
                ...
              </button>
              {i.attachments?.[0].generated_file ? (
                <button
                  type="button"
                  className="mr-2"
                  onClick={() => {
                    window.open(process.env.REACT_APP_BACKEND_LINK + i.attachments?.[0].generated_file, '_blank');
                  }}>
                  Podgląd
                </button>
              ) : i.is_factor_pattern ? (
                <button type="button" onClick={() => generate(i)} className="mr-2">
                  Generuj
                </button>
              ) : null}
              {i.status === 1 ? (
                <button className="ml-1" type="button" onClick={() => props.archive(i.id)}>
                  Archiwizuj
                </button>
              ) : null}
            </div>
          </RowEditable>
        ))}
        <div className="row bb">
          <div className="col-12 p-2" style={{ color: '#006BFF' }}>
            Dokumenty pozostałe
            <span className="float-right" style={{ cursor: 'pointer' }}>
              <i
                className="fa fa-plus ml-2"
                onClick={() => {
                  setDocumentType(2);
                  props.toggleForm(formName);
                }}
              />
            </span>
          </div>
        </div>
        <div className="row bb">
          <div className="col-4 p-2 br grey">Nazwa dokumentu</div>
          <div className="col-1 p-2 br grey">Wersja PA</div>
          <div className="col-1 p-2 br grey">Produkt</div>
          <div className="col-1 p-2 br grey">Procedura</div>
          <div className="col-2 p-2 br grey">Nr dokumentu</div>
          <div className="col-1 p-2 br grey">Status</div>
          <div className="col-2 p-2">
            {/* <button type="button" onClick={() => {}}> */}
            {/*  Zarządzaj */}
            {/* </button> */}
          </div>
        </div>
        {d[2]?.docs?.map(i => (
          <RowEditable onRemove={i.is_required ? undefined : () => props.remove('generated_documents', i.id)}>
            <div className="col-4 p-2 br">{i.document_name?.name || i.name || '-'}</div>
            <div className="col-1 p-2 br">{i.ap_version}</div>
            <div className="col-1 p-2 br">{i.product?.name ?? '-'}</div>
            <div className="col-1 p-2 br">{i.procedure?.name ?? '-'}</div>
            <div className="col-2 p-2 br"><Tooltip label={i.identifier} /></div>
            <div className="col-1 p-2 br">{props.common.agreementData?.document_agreement_statuses?.[i.status]}</div>
            <div className="col-2 p-2">
              <button type="button" onClick={() => edit(i)} className="mr-2">
                ...
              </button>
              {i.attachments?.[0].generated_file ? (
                <button
                  type="button"
                  className="mr-2"
                  onClick={() => {
                    window.open(process.env.REACT_APP_BACKEND_LINK + i.attachments?.[0].generated_file, '_blank');
                  }}>
                  Podgląd
                </button>
              ) : i.is_factor_pattern ? (
                <button type="button" onClick={() => generate(i)} className="mr-2">
                  Generuj
                </button>
              ) : null}
              {i.status === 1 ? (
                <button className="ml-1" type="button" onClick={() => props.archive('documents_to_secure', i.id)}>
                  Archiwizuj
                </button>
              ) : null}
            </div>
          </RowEditable>
        ))}
        {d[2]?.attachments?.map(i => (
          <RowEditable onRemove={() => props.remove('attachments', i.id)}>
            <div className="col-4 p-2 br">{i.document_name?.name || i.name || '-'}</div>
            <div className="col-1 p-2 br">{i.ap_version || '-'}</div>
            <div className="col-1 p-2 br">{i.product?.name ?? '-'}</div>
            <div className="col-1 p-2 br">{i.procedure?.name ?? '-'}</div>
            <div className="col-2 p-2 br">{i.identifier ?? '-'}</div>
            <div className="col-1 p-2 br">{props.common.agreementData?.document_agreement_statuses?.[i.status]}</div>
            <div className="col-2 p-2">
              <button
                type="button"
                className="mr-2"
                onClick={() => {
                  window.open(process.env.REACT_APP_BACKEND_LINK + i.file, '_blank');
                }}>
                Pobierz
              </button>
            </div>
          </RowEditable>
        ))}

        <div className="row mt-1 mb-2">
          <div className="col-sm-12 p-2">
            <File
              url={`agreements/${props.agreement?.id}/agreement_versions/${props.version?.id}/attachments/`}
              data={{ category: 1 }}
              field="attachments"
              formName={props.formName}
              onSuccess={props.refresh}
            />

            <button
              type="button"
              onClick={() => {
                setDocumentType(null);
                props.toggleForm(formName);
              }}
              className="ml-2 mr-2">
              Dodaj z szablonu
            </button>
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(manageFormName)}
        size='extralarge'
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" /> <span>Generowanie dokumentu</span>
          </div>
        }
        onClickAway={handleCloseModal}>
        <Documents
          generate={props.generate}
          register={props.register}
          forms={props.forms}
          onClose={handleCloseModal}
          withButtons
          formVisible={props.formVisible}
          toggleForm={props.toggleForm}
          formName={manageFormName}
          onCancelButtonClick={() => props.toggleForm(manageFormName)}
          common={props.common}
          agreement={props.agreement}
          version={props.version}
          removeAttachment={props.removeAttachment}
          storeInput={props.storeInput}
        />
      </BaseModal>

      <Selectable
        fetch={() => {}}
        visible={props.formVisible(formName)}
        formName={formName}
        field="document"
        columns={columns}
        options={options}
        onClickAway={() => props.toggleForm(formName)}
        header="Dodawanie dokumentu"
        title={title}
        onSelected={() => {
          props.saveForm(formName, false);
          props.toggleForm(formName);
        }}
      />
    </Card>
  );
};
