import React from 'react';

import {
  Buttons,   Form, Select, Selectable
} from '../../../components/Forms';

export const ProtectionsForm = (props) => {
  const columns = [
    { name: 'Nazwa zabezpieczenia', getter: 'name', sortable: 1 }
  ];
  return (
    <>
      <Form
        initialForm={{ calculationId: props.calculationId }}
        visibleOnMount={props.visibleOnMount}
        className="form-horizontal"
        formName={props.formName}
      >
        {props.visible ? (
          <>
            {props.errors && props.errors.length > 0 ? (
              <div className="row mt-3 mb-3">
                <div className="col-sm-12">{props.errors[0]}</div>
              </div>
            ) : null}
            <div className="row mt-3">
              <div className="col-sm-6">
                <div className="form-group row align-items-center">
                  <span className="col-sm-8 col-form-label label-right mb-15">
                    Oczekiwana forma dokumentu
                  </span>
                  <div className="col-sm-4">
                    <Select formName={props.formName} options={props.expectedForms} name="dts_form_id" />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 pr-5">
                <div className="form-group row align-items-center">
                  <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                    Rodzaj zabezpieczenia
                  </label>
                  <div className="col-sm-6">
                    {props.protectionSelected ? (
                      <span className="mr-3">
                        {' '}
                        {props.protectionSelected.name}
                      </span>
                    ) : null}
                    <button
                      type="button"
                      id={'btn-dts_type'}
                      onClick={() => {
                        props.fetch();
                        props.toggleForm('dts_type');
                      }}
                    >
                      Wybierz
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {props.withButtons ? (
              <Buttons
                formName={props.formName}
                onCancel={props.onCancel}
                onSubmit={props.submitForm}
                onSubmitAndLeave={props.onSubmitAndLeave}
              />
            ) : null}
          </>
        ) : null}
      </Form>

      <Selectable
        filtersForm={props.filtersForm}
        fetch={props.fetch}
        visible={props.formVisible('dts_type')}
        formName={props.formName}
        field="dts_type"
        columns={columns}
        options={props.documents}
        onClickAway={() => props.toggleForm('dts_type')}
        header="Wybierz rodzaj zabezpieczenia"
        footer=""
      />
    </>
  );
};
