import React from 'react';
import { useSelector } from 'react-redux';

import BooleanCheck from '../../../components/BooleanCheck';
import { Form, Input, Required, Select } from '../../../components/Forms';
import { isAnyEmpty, parseMoney } from '../../../utilities/helpers';

const getNotEmptyField = value => {
  if(!value || value === '0.00') return false;
  return value;
};

export const AdvanceForm = props => {
  const f = props.form ?? {};

  const { forms } = useSelector(state => state.Forms);
  const { claim } = useSelector(state => state.Claim);

  const netRedemption = getNotEmptyField(forms?.ClaimParamsForm?.purchase_custom_value)
    || getNotEmptyField(forms?.ClaimParamsForm?.net_value)
    || getNotEmptyField(claim?.purchase_custom_value)
    || getNotEmptyField(claim?.net_value);

  const vatRedemption = getNotEmptyField(forms?.ClaimParamsForm?.purchase_custom_vat_value)
    || getNotEmptyField(forms?.ClaimParamsForm?.vat_value)
    || getNotEmptyField(claim?.purchase_custom_vat_value)
    || getNotEmptyField(claim?.vat_value);

  return (
    <Form forceErrors={!props.details} visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row d-flex align-items-center my-10">
            <div className="label-right col-form-label ml-40">
              Wysokość zaliczki
              <Required />
            </div>

            <div className="col-sm-2 d-flex align-items-center">
              <BooleanCheck
                disabled={props.details}
                className="mr-3"
                asRadioButton
                field={!!f.percent_advance_payment}
                onClick={() => {
                  props.storeInput(props.formName, 'percent_advance_payment', f.max_advance_payment);
                  props.clear(props.formName, 'advance_payment_value_custom');
                  props.clear(props.formName, 'advance_payment_vat_custom');
                }}
              />
              {props.details ? (
                <span>{f.percent_advance_payment ? `${f.percent_advance_payment}%` : '-'}</span>
              ) : (
                <Select
                  errorName="none"
                  formName={props.formName}
                  options={Array.from({ length: 20 }, (v, i) => ({ value: i * 5, name: `${i * 5}%` })).filter(x =>
                    f.max_advance_payment ? x.value <= Number(f.max_advance_payment) : x
                  )}
                  name="percent_advance_payment"
                  disabled={!f.percent_advance_payment}
                  number
                />
              )}
            </div>
            <div className=" d-flex align-items-center pr-3 ml-30">
              <BooleanCheck
                disabled={props.details}
                className="mr-2"
                asRadioButton
                field={!f.percent_advance_payment}
                onClick={() => {
                  props.clear(props.formName, 'percent_advance_payment');
                }}
              />
              <span> własna</span>
            </div>
            <div className="pl-2 pr-2">
              {props.details ? (
                <span style={{ fontWeight: '600' }}>{f.advance_payment_value_custom}</span>
              ) : (
                <Input
                  style={{ width: '8rem' }}
                  errorName="none"
                  formName={props.formName}
                  name="advance_payment_value_custom"
                  disabled={f.percent_advance_payment}
                />
              )}
            </div>
            <div>
              <span className='mr-30'>{f.currency ?? f.gross_value_currency?.name ?? f.vat_value_currency?.name}</span>VAT
            </div>
            <div className="pl-2 pr-2">
              {props.details ? (
                <span style={{ fontWeight: '600' }}>{f.advance_payment_vat_custom}</span>
              ) : (
                <Input
                  style={{ width: '8rem' }}
                  errorName="none"
                  defaultValue={f.advance_payment_vat_custom}
                  formName={props.formName}
                  name="advance_payment_vat_custom"
                  disabled={f.percent_advance_payment || props.details}
                />
              )}
            </div>
            <div>{f.currency ?? f.gross_value_currency?.name ?? f.vat_value_currency?.name}</div>
          </div>
          <hr />
          <div className="row my-07">
            <div className="p-2 d-flex align-items-center mx-40">
              MPP
              <BooleanCheck field={f.mpp} className="ml-3" />
            </div>
            <div className="p-2 d-flex align-items-center">
              Stawki potrącane z zaliczki
              <BooleanCheck field={f.deducted_rates} className="ml-3" />
            </div>
          </div>
          <hr />
          <div className="row mt-2 mb-3 align-items-center">
            <label htmlFor="example-text-input" className="col-sm-4 label-right col-form-label">
              Planowana wysokość zaliczki (netto / VAT)
            </label>
            <div className="col-sm-8">
              {parseMoney(isAnyEmpty(f.percent_advance_payment) ? f.advance_payment_value_custom : (netRedemption * f.percent_advance_payment / 100))} {f.currency ?? f.gross_value_currency?.name ?? f.vat_value_currency?.name}
              {' '}/ {parseMoney(isAnyEmpty(f.percent_advance_payment) ? f.advance_payment_vat_custom : (vatRedemption * f.percent_advance_payment / 100))}{' '}{f.vat_currency ?? f.gross_value_currency?.name ?? f.vat_value_currency?.name}
            </div>
          </div>

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancel}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}>
                  Anuluj
                </button>
                <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2 wider font-weight-light">
                  {props.submitButtonText || 'Zapisz'}
                </button>
                {props.recalculateAdvance && f.can_edit_advance_payment ? (
                  <button type="button" onClick={props.recalculateAdvance} className="btn btn-primary ml-2 wider font-weight-light">
                    Przekalkuluj
                  </button>
                ) : null}
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
