import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import Icon from 'components/Forms/Icon';
import ModalOnRemove from 'components/Layout/ModalOnRemove';
import { t } from 'lang/pl';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

const defaultOptions = {
  isCollapsed: true,
  isPosted: false
};

const Card = ({
  className, loading, mode, header, isBlocked, messageRemove,
  onBlockedMessage, onAddClick, onEditClick, onRemoveClick,
  onRefreshClick, onCreateClick, onSaveClick, onQuestionClick,
  isOpen, canEdit, canSave,
  children, options, ...props
}) => {
  const opt = { ...defaultOptions, ...options };
  const [isCollapsed, setCollapsed] = useState(opt.isCollapsed);
  const [isPosted, setPosted] = useState(opt.isPosted);
  const isCreator = mode === 'creator';

  const handleOnToggleClick = () => {
    setCollapsed(prev => !prev || isOpen);
  };

  // creator
  const handleOnCreateClick = event => {
    isBlocked ? handleBlockedMessage() : setTimeout(() => onCreateClick(event), 200);
    setCollapsed(true);
  };

  // creator
  const handleOnSaveClick = event => {
    isBlocked ? handleBlockedMessage() : setTimeout(() => onSaveClick(event), 200);
    setCollapsed(true);
    setPosted(true);
  };

  // details
  const handleOnAddClick = event => {
    isBlocked ? handleBlockedMessage() : onAddClick(event);
  };

  // details
  const handleOnEditClick = event => {
    isBlocked ? handleBlockedMessage() : onEditClick(event);
  };

  const handleOnTrashClick = event => {
    isBlocked ? handleBlockedMessage() : onRemoveClick(event);
  };

  // details
  const handleQuestionClick = () => {
    toast.error(t.error.toast.notImplemented);
  };

  const handleCardHeaderClick = () => {
    handleOnToggleClick();
  };

  const handleBlockedMessage = () => {
    onBlockedMessage();
  };

  return (
    <div className={clsx('w-full', header !== 'Notatki' && 'card', className)} style={{ border: isCreator && (isPosted ? '1px solid #1DB393' : '1px solid #B3931D') }} {...props}>
      <div className={clsx('border-b border-solid border-grey-D px-12')}>
        <div className="card-head flex items-center justify-between mb-0 h-full">
          <a
            onClick={handleCardHeaderClick}
            className={clsx('max-w-4/5 text-15 py-12 pointer', isCreator ? 'creator__btn-icon' : 'details__btn-icon')}
          >
            <span>{header}</span>
          </a>
          <div className={clsx('float-right text-15', isCreator ? 'card-link' : '')}>
            {onAddClick && <Icon isCreator={isCreator} mode='plus' onClick={handleOnAddClick} />}
            {canEdit && onEditClick && <Icon isCreator={isCreator} mode='edit' onClick={handleOnEditClick} />}
            {onRemoveClick && <ModalOnRemove isBlocked={isBlocked} message={messageRemove} onRemove={handleOnTrashClick} onBlocked={handleBlockedMessage} />}

            {onRefreshClick && <Icon isCreator={isCreator} mode='refresh' onClick={onRefreshClick} />}
            {onCreateClick && <Icon isCreator={isCreator} mode='plus-creator' onClick={handleOnCreateClick} />}
            {canSave && onSaveClick && (<div className='inline-block w-40'>{loading ? <Icon mode='loading' /> : <Icon isCreator={isCreator} mode='save-creator' onClick={handleOnSaveClick} />}</div>)}
            {onQuestionClick && <Icon isCreator={isCreator} mode='question-creator' onClick={handleQuestionClick} />}
          </div>
        </div>
      </div>
      <div>
        <Collapse in={isCollapsed || isOpen}>
          <div className="px-15">{children?.length ? children[0] : children}</div>
        </Collapse>
        <div className="px-15">{children?.length && children?.map((child, key) => key !== 0 && child)}</div>
      </div>
    </div>
  );
};

Card.propTypes = {
  header: PropTypes.string.isRequired
};

Card.defaultProps = {
  onBlockedMessage: () => toast.warn(t.error.toast.fieldWarning),
  options: defaultOptions,
  canEdit: true,
  canSave: true
};

export default Card;
