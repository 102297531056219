import React from 'react';

import { Form, Select } from '../../../components/Forms';
import { OldCard } from '../../../components/Layout';

export const Searchbar = props => (
  <OldCard className="mt-3 pt-3 pb-3">
    <h4 className="card-title">
      <span className=" text-themecolor">{props.title}</span>
      <p className="small d-inline-flex float-right text-black">Ilość rekordów: {props.records}</p>
    </h4>
    {props.subtitle && <h6 className="card-subtitle">{props.subtitle}</h6>}
    <Form className="form-horizontal" formName={props.formName}>
      <Select onChange={() => props.onSubmit()} formName={props.formName} options={props.options || []} name="is_settled" placeholder="Wszystkie" />
    </Form>
  </OldCard>
);
