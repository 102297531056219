import { cloneDeep, isObject, omit } from 'lodash';

import HttpClient from '../utilities/http';

export default {
  removeCompensation: id => HttpClient.delete(`payments/compensation/subpayment/${id}/`),
  removeIncome: (subpayment_id, income_id) => HttpClient.delete(`/payments/subpayment/${subpayment_id}/income/${income_id}/`),
  sendCompensation: (u, data) => HttpClient.post(u, data),
  removeItem: (invoiceId, itemId) => HttpClient.delete(`/invoices/${invoiceId}/item/${itemId}/`),
  remove: id => HttpClient.delete(`/invoices/${id}/`),
  print: id =>
    HttpClient.post(
      `/invoices/${id}/pdf/`,
      {},
      {
        responseType: 'blob'
      }
    ),
  changeStatus: (invoice_ids, status) =>
    HttpClient.patch('/invoices/status/', {
      status,
      invoice_ids
    }),
  fetch: params => HttpClient.get('/invoices/', { params }),
  create: data => HttpClient.post('/payments/creator/', data),
  saveForm: (form, d) => {
    const forcePut = false;
    let data = cloneDeep(d);
    let u = '/payments/';

    const mutate = (x, excludedKeys = []) => {
      x = { ...x };
      Object.keys(x).forEach(key => {
        if (excludedKeys.indexOf(key) === -1 && (isObject(x[key]) || x[key] === null)) {
          delete x[key];
        }
      });

      return x;
    };

    switch (form) {
      case 'TransfersSelectForExportForm':
        u += 'export/';
        break;
      case 'CreateTransferForm':
        data = mutate({
          ...data,
          sender_bank_account_id: data.sender_bank_account?.id,
          recipient_bank_account_id: data.recipient_bank_account?.id
        });
        break;
      case 'CancelAuthorizationForm':
        u += 'cancel_authorization/';
        break;
      case 'InvoiceForm':
        u = data.id ? '/invoices/' : '/invoices/create/';

        if (data.mode === 'correction') {
          u = `/invoices/${data.id}/correction/`;
          delete data.id;
        }

        data = {
          agreement: data.agreement?.id,
          buyer: data.buyer?.id,
          seller: data.seller?.id,
          manual_bank_account: data.manual_bank_account?.id,
          buyer_bank_account: data.buyer_bank_account?.id,
          seller_bank_account: data.seller_bank_account?.id,
          issue_date: data.issue_date,
          sale_date: data.sale_date,
          payment_date: data.payment_date,
          payment_method: data.payment_method,
          items: data.items,
          id: data.id,
          category: data.category,
          tax_procedure_code: data.tax_procedure_code,
          correction_reason: data.correction_reason,
          comment: data.comment
        };

        break;
      case 'AddItemToInvoice':
        u = `/invoices/${data.invoiceId}/item/`;
        break;
      default:
        break;
    }

    Object.keys(data).forEach(key => {
      if (data[key] === null) {
        delete data[key];
      }
    });

    u = data.id ? `${u + data.id}/` : u;

    return data.id || forcePut ? HttpClient.put(u, omit(data, 'id')) : HttpClient.post(u, data);
  },
  fetchDetails: id => HttpClient.get(`/invoices/${id}/`),
  fetchSettlements: id => HttpClient.get(`/invoices/${id}/settlements/`),
  fetchLinked: id => HttpClient.get(`/invoices/${id}/linked/`),
  fetchAgreements: filters =>
    HttpClient.get('/agreements/', {
      params: { ...filters, limit: 10 }
    }),
  fetchFactors: filters =>
    HttpClient.get('/offers/search_people_and_companies/', {
      params: { ...filters, role_name: 'faktor', limit: 10 }
    }),
  fetchClients: filters =>
    HttpClient.get('/offers/search_people_and_companies/', {
      params: { ...filters, role_name: 'client', limit: 10 }
    }),
  fetchAccounts: (u) => HttpClient.get(u),
  fetchDocs: (params) => HttpClient.get('/companies/compensation/', { params })
};
