import clsx from 'clsx';
import { get } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectCore, { createFilter } from 'react-select';
import tw from 'twin.macro';
import { isObject } from 'utilities/helpers';

import FormActions from '../../actions/forms';

export const optionsBoolean = [{ value: false, name: 'nie' }, { value: true, name: 'tak' }];

export const optionsGrossNet = [{ value: 0, name: 'brutto' }, { value: 1, name: 'netto' }];

const DropdownIndicator = props => (
  <div {...props}>
    <i className="fas fa-sort-down mr-1 ml-1 mb-10 text-grey-A"/>
  </div>
);

const customStyles = {
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  control: base => ({ ...base, minHeight: 35 }),
  valueContainer: base => ({ ...base, padding: '0px 8px' }),
  clearIndicator: base => ({ ...base, padding: '0px' })
};

const filterConfig = {
  stringify: option => `${option?.data?.name} ${option?.data?.short_name}`,
  ignoreCase: true,
  ignoreAccents: true,
  trim: true
};

const Select = props => {
  const dispatch = useDispatch();
  const Forms = useSelector(state => state.Forms);

  const options = props.options?.map(option => ({ ...option, label: option.name })) ?? [];

  const errorName = props.errorName ?? props.name;
  const error = get(Forms.errors[props.formName], errorName, props.error, '');
  let value = get(Forms.forms[props.formName], props.name, null);
  value = isObject(value) ? value?.id : value;

  const valueObject = options.find(option => String(option.value) === String(value)) ?? null;

  return (
    <div css={[ tw`mb-0 min-w-120 max-w-250` ]} {...props}>
      <SelectCore
        styles={customStyles}
        filterOption={createFilter(filterConfig)}
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        id={`select-${props.name}`}
        key={props.formName + props.field}
        className={clsx('min-h-35', error && 'border-error')}
        menuPortalTarget={document.body}
        isClearable={props.isClearable ?? true}
        multiple={props.multiple || false}
        isDisabled={props.isDisabled || false}
        noOptionsMessage={() => props.noOptionsMessage ?? 'Brak'}
        value={valueObject}
        onChange={e => {
          let v = e ? e.value : e;
          v = (props.number || props.type === 'number') && v ? Number(v) : v;

          if (v === null) {
            dispatch(FormActions.clearField(props.formName, props.name));
          } else {
            const vObject = options.find(option => option.value === v);
            dispatch(FormActions.storeInput(props.formName, props.name, v));
            dispatch(FormActions.storeInput(props.formName, `${props.name}_select`, vObject));
          }

          if (props.onChange) {
            props.onChange(v);
          }
        }}
        placeholder={props.placeholder}
        options={props.options?.map(o => ({ ...o, label: o.name }))}
      />
      <div className="form-control-feedback small min-h-15" style={{ color: 'red' }}>
        {error}
      </div>
    </div>
  );
};

Select.defaultProps = {
  placeholder: 'wybierz'
};

export default Select;
