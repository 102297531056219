import FormActions from 'actions/forms';
import { Datepicker, Form, Input, Select } from 'components/Forms';
import Button from 'components/Forms/Button';
import CheckboxLabel from 'components/Forms/CheckboxLabel';
import { BaseModal } from 'components/Layout/OldModal';
import TextareaLab from 'components/TextareaLab';
import { t } from 'lang/pl';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { optionsCommon } from 'utilities/helpers';

export default function GenerateDue({ isOpen, onClose }) {
  const { vat, currencies } = useSelector(state => state.Common);
  const { forms } = useSelector(state => state.Forms);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    console.log('send to api', forms[GenerateDue.name]);
    toast.error(t.error.toast.notImplemented);
    handleClose();
  };

  const handleRemove = () => {
    toast.error(t.error.toast.notImplemented);
    handleClose();
  };

  const handleClose = () => {
    dispatch(FormActions.clearForm(GenerateDue.name));
    onClose();
  };

  return (
    <BaseModal
      visible={isOpen}
      headerText="Generowanie innej należności"
      onClickAway={handleClose}>
      <Form formName={GenerateDue.name} visibleOnMount>
        <div className='grid grid-cols-32-auto gap-x-20 gap-y-10'>
          <p className='col-span-7 font-bold text-grey-8 text-right pt-07'>Tytuł należności</p>
          <div className='col-span-10'>
            <Input fullWidth formName={GenerateDue.name} type="text" name="tytul_naleznosci" />
          </div>
          <p className='col-span-4 font-bold text-grey-8 text-right pt-07'>Stawka VAT</p>
          <div>
            <Select formName={GenerateDue.name} options={optionsCommon(vat)} name="legal_form_id" />
          </div>
          <div className='col-span-10 flex justify-end'>
            <CheckboxLabel
              formName={GenerateDue.name}
              name='multilevelbids'
              label="Czy nota"
            />
            <CheckboxLabel
              formName={GenerateDue.name}
              name='isMpp'
              label="MPP"
            />
          </div>

          <p className='col-span-7 font-bold text-grey-8 text-right pt-07'>Kwota brutto</p>
          <div className='col-span-10 flex w-full'>
            <Input formName={GenerateDue.name} type="number" name="kwota_brutto" />
            <div className='ml-05'>
              <Select  isClearable={false} formName={GenerateDue.name} name="waluta" options={optionsCommon(currencies)} />
            </div>
          </div>

          <p className='col-span-4 font-bold text-grey-8 text-right pt-07'>Termin płatności</p>
          <div>
            <Datepicker formName={GenerateDue.name} name="date" />
          </div>

          <div className='col-span-full'>
            <TextareaLab
              formName={GenerateDue.name}
              name="comment"
              rows={4}
            />
          </div>
        </div>

        <div className="flex justify-center items-center">
          <Button onClick={handleRemove}>Usuń</Button>
          <Button onClick={handleClose}>Anuluj</Button>
          <Button onClick={handleSubmit} mode='primary' type="submit">Generuj</Button>
          <Button onClick={handleSubmit} mode='primary' type="submit">Przekalkuluj</Button>
        </div>
      </Form>
    </BaseModal>
  );
}

GenerateDue.propTypes = {
  onClose: PropTypes.func.isRequired
};

GenerateDue.defaultProps = {
  isOpen: false
};
