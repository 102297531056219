import React from 'react';

import { Buttons } from '../../../components/Forms';
import { BaseModal } from 'components/Layout/OldModal';

const RemovalModal = (props) => (
  <BaseModal
    visible={props.visible}
    header={(
      <div className="d-flex align-items-center">
        <i className="fas fa-pencil-alt mr-2" />
        {' '}
        <span>{props.header || 'Usuwanie obiektu'}</span>
      </div>
    )}
    // footer={props.footer || 'Usuń obiekt.'}
    onClickAway={() => props.toggle()}
  >
    {props.children}
    <div className="col-sm-12">
      <Buttons onCancel={() => props.toggle()} onRemove={props.remove} />

    </div>
  </BaseModal>
);

export default RemovalModal;
