import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import { genderOptions } from 'containers/Persons/List/Create';
import React from 'react';

import { formatString, formatNip } from '../../../../utilities/helpers';
import { PersonForm } from '../../Forms/Main';

export const Metrics = (props) => {
  const { person } = props;

  const formName = 'PersonForm';
  const gender = genderOptions.find(option => option.value === person.sex)?.name;

  return (
    <Card header='Metryka osoby' onEditClick={() => props.edit(formName)}>
      <div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">ID Osoby</div>
              <div className="col-sm-6 p-2 br">{person.identifier}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Imię</div>
              <div className="col-sm-6 p-2 br">{person.first_name || '-'}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Drugie imię</div>
              <div className="col-sm-6 p-2 br">{person.second_name || '-'}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Nazwisko</div>
              <div className="col-sm-6 p-2 br">{person.last_name || '-'}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Płeć</div>
              <div className="col-sm-6 p-2 br">{formatString(gender)}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Data urodzenia</div>
              <div className="col-sm-6 p-2 br">{person.date_of_birth || '-'}</div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">PESEL</div>
              <div className="col-sm-6 p-2">{person.pesel || '-'}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">NIP</div>
              <div className="col-sm-6 p-2">{formatNip(person.nip) || '-'}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Dowód osobisty</div>
              <div className="col-sm-6 p-2">{person.id_card || '-'}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Paszport</div>
              <div className="col-sm-6 p-2">{person.passport || '-'}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-6 p-2 br grey">Obywatelstwo</div>
              <div className="col-sm-6 p-2 br">{person.citizenship || '-'}</div>
            </div>
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Metryka osoby</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <PersonForm
          personId={props.person ? props.person.id : 'brak'}
          preloadedForm
          visibleOnMount={false}
          clear={props.clear}
          visible={props.formVisible(formName)}
          formName={formName}
          submitForm={() => props.saveForm(formName)}
          onCancel={() => props.onCancel(formName)}
          withButtons
        />
      </BaseModal>
    </Card>
  );
};
