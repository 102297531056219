import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import RemovalModal from '../../Forms/RemovalModal';
import { RolesForm } from '../../Forms/Roles';
import Tooltip from 'components/Layout/Tooltip';

export const FORM_ROLES = 'RolesForm';

export const Roles = (props) => {
  const [objectToRemove, setObjectToRemove] = useState({});
  const [companyToRemove, setCompanyToRemove] = useState({});

  const companies = props.person && props.person.companies ? props.person.companies : [];
  const system_roles = props.person.system_roles || [];

  const formName = FORM_ROLES;

  const companySelected = props.forms[formName] ? props.forms[formName].company : null;

  return (
    <Card header='Role' onAddClick={() => props.create(formName)}>
      <div className="collapse show" key="role" id="role">
        <div className="row">
          <div className="col-sm-12">
            <div className="row bb grey">
              <div className="col-sm-3 p-2 br">Rola biznesowa</div>
              <div className="col-sm-2 p-2 br">Firma</div>
              <div className="col-sm-3 p-2 br">Rola firmy</div>
              <div className="col-sm-2 p-2 br">Powiązane od</div>
              <div className="col-sm-2 p-2">Powiązał</div>
            </div>
          </div>
        </div>

        {companies.map((c) => c.roles.map((r) => (
          <div className="row" key={c.name}>
            <div className="col-sm-12">
              <RowEditable
                isObjectToRemove
                itemId={c.id}
                onRemove={() => {
                  setObjectToRemove(r);
                  setCompanyToRemove(c);
                }}
              >
                <div className="col-sm-3 p-2 br">{r.name}</div>
                <div className="col-sm-2 p-2 br">
                  <Tooltip label={c.company.identifier} tip={c.company.name} path={`/firmy/${c.company.slug}`} />
                </div>
                <div className="col-sm-3 p-2 br">{c.business_company_roles.map((i) => i.name).join(', ')}</div>
                <div className="col-sm-2 p-2 br">{c.created_date}</div>
                <div className="col-sm-2 p-2">{c?.user_creator?.full_name}</div>
              </RowEditable>
            </div>
          </div>
        )))}

        <div className="mt-4 mb-3">
          <div style={{ fontSize: '18px' }} className="grey mb-2">
            Role systemowe
            <span className="float-right" style={{ cursor: 'pointer' }}>
              {/* <i className="fa fa-plus ml-2" onClick={() => props.createSystemRoles(props.person)} /> */}
            </span>
          </div>
          {system_roles.map((r) => (
            <span key={r.name} className="badge badge-warning mr-3 p-2" onDoubleClick={() => {}}>
              {r.name}
            </span>
          ))}
        </div>
      </div>
      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Role</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <RolesForm
          companySelected={companySelected}
          fetchCompanies={props.fetchCompanies}
          filtersForm={props.filtersForm}
          person={props.person}
          companies={props.companies}
          preloadedForm
          visibleOnMount={false}
          clear={props.clear}
          visible={props.formVisible(formName)}
          formVisible={props.formVisible}
          formName={formName}
          submitForm={() => props.saveForm(formName)}
          onCancel={() => props.onCancel(formName)}
          withButtons
          roles={props.roles}
          errors={props.errors[formName]}
          forms={props.forms}
          toggleForm={props.toggleForm}
          selectCompany={props.selectCompany}
        />
      </BaseModal>

      <RemovalModal
        visible={Object.keys(objectToRemove).length > 0}
        toggle={() => setObjectToRemove({})}
        remove={() => {
          props.remove(objectToRemove, companyToRemove);
          setObjectToRemove({});
        }}
      >
        {objectToRemove && companyToRemove && (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="row bt bb grey">
                  <div className="col-sm-3 p-2 br ">Rola biznesowa</div>
                  <div className="col-sm-2 p-2 br">Nazwa firmy</div>
                  <div className="col-sm-3 p-2 br">Rola firmy</div>
                  <div className="col-sm-2 p-2 br">Powiązane od</div>
                  <div className="col-sm-2 p-2">Powiązał</div>
                </div>
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br">{objectToRemove ? objectToRemove.name : ''}</div>
              <div className="col-sm-2 p-2 br">{companyToRemove && companyToRemove.company ? companyToRemove.company.name : ''}</div>
              <div className="col-sm-3 p-2 br">
                {companyToRemove && companyToRemove.business_company_roles
                  ? companyToRemove.business_company_roles.map((i) => i.name).join(', ')
                  : ''}
              </div>
              <div className="col-sm-2 p-2 br">{companyToRemove ? companyToRemove.created_date : ''}</div>
              <div className="col-sm-2 p-2">
                {companyToRemove && companyToRemove.user_creator ? companyToRemove.user_creator.full_name : ''}
              </div>
            </div>
          </div>
        )}
      </RemovalModal>
    </Card>
  );
};
