import React from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { OldCard } from '../../../../components/Layout';
import { BaseModal } from 'components/Layout/OldModal';
import { formatString, parseMoney } from '../../../../utilities/helpers';
import CreateClaimForm from '../../Forms/Create';

export default (props) => {
  const formName = 'ClaimParamsForm';

  const header = (
    <a
      role="button"
      style={{ fontSize: '24px' }}
      className="collapsed "
      data-toggle="collapse"
      href="#warunkiDodatkowe"
      aria-expanded="false"
      aria-controls="collapseExample"
    >
      Parametry podstawowe
    </a>
  );

  const customCreateSection = (
    <>
      <i
        className="fas fa-pencil-alt ml-2 mr-2 "
        id="claim-edit"
        onClick={() => props.edit(formName, {
          ...props.claim,
          gross_value_currency_id: props.claim?.gross_value_currency?.id,
          net_value_currency_id: props.claim?.net_value_currency?.id,
          vat_value_currency_id: props.claim?.vat_value_currency?.id,
          purchase_currency_id: props.claim?.purchase_currency?.id,
          purchase_custom_value_currency_id: props.claim?.purchase_custom_value_currency?.id,
          purchase_custom_vat_value_currency_id: props.claim?.purchase_custom_vat_value_currency?.id
        })}
      />
      <i className="fas fa-question-circle" onClick={() => { }} />
    </>
  );

  return (
    <OldCard
      header={header}
      id="financialData-card"
      showIcons
      customCreateSection={customCreateSection}
    >
      <div className="collapse show" id="financialData">
        <div className="row bb">
          <div className="col-1 p-2 grey br">Numer dokumentu</div>
          <div className="col-1 p-2 grey br">Data dokumentu</div>
          <div className="col-2 p-2 grey br">Termin wymagalności</div>
          <div className="col-2 p-2 grey br">
            Wartość brutto{' '}
            {props.claim?.gross_value_currency?.name}
          </div>
          <div className="col-1 p-2 grey br">
            Kwota VAT{' '}
            {props.claim?.vat_value_currency?.name}
          </div>
          <div className="col-2 p-2 grey br">
            Kwota wykupu {' '}
            {props.claim?.purchase_currency?.name}
          </div>
          <div className="col-1 p-2 grey br">MPP</div>
          <div className="col-1 p-2 grey br">Potrącane z zaliczki</div>
          <div className="col-1 p-2 grey br">Data spłaty</div>
        </div>
        <div className="row bb">
          <div className="col-1 p-2  br">{props.claim?.document_no}</div>
          <div className="col-1 p-2  br">{props.claim?.created_date}</div>
          <div className="col-2 p-2  br">{props.claim?.due_date}</div>
          <div className="col-2 p-2  br">
            {parseMoney(props.claim?.gross_value)}
          </div>
          <div className="col-1 p-2  br">
            {parseMoney(props.claim?.vat_value)}
          </div>
          <div className="col-2 p-2  br">
            {parseMoney(props.claim?.purchase_gross_value)}
          </div>
          <div className="col-1 p-2 br"><BooleanCheck field={props.claim?.mpp} /></div>
          <div className="col-1 p-2 br"><BooleanCheck field={props.claim?.deducted_rates} /></div>
          <div className="col-1 p-2 br">{formatString(props.claim?.settled_date)}</div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        headerText="Edycja parametrów podstawowych"
        footer=""
        onClickAway={() => props.toggleForm(formName)}
      >
        <CreateClaimForm
          editMode
          withButtons
          errors={props?.errors?.[formName]}
          form={props.forms?.[formName]}
          forms={props.forms}
          clear={props.clear}
          visibleOnMount={false}
          visible
          toggleForm={props.toggleForm}
          isVisible={() => false}
          formName={formName}
          submitForm={() => props.saveForm(formName)}
          onCancel={() => props.toggleForm(formName)}
          currencies={props.common?.currencies}
          formData={props.claim?.formData}
          storeInput={props.storeInput}
          representations={props.common?.companyData?.representations}
          recalculate={(v, c, n) => props.recalculate(formName, v, c, n)}
        />
      </BaseModal>
    </OldCard>
  );
};
