import 'twin.macro';

import Tooltip from 'components/Layout/Tooltip';
import moment from 'moment';
import React from 'react';

import BooleanCheck from '../../../components/BooleanCheck';
import {
  Form, Select, Textarea
} from '../../../components/Forms';
import Decision from 'components/Format/Decision';

export default (props) => {
  const d = props.debtor || {};
  const proposal = props.version || {};

  const decisions = props.proposalData && props.proposalData.rating_decisions ? Object.keys(props.proposalData.rating_decisions).map((k) => ({ value: k, name: props.proposalData.rating_decisions[k] })) : [];

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row bb bt">
            <div className="col-sm-3 p-2 br grey">Oceniający</div>
            <div className="col-sm-2 p-2 br grey">Data oceny</div>
            <div className="col-sm-2 p-2 br grey">Kwalifikacja auto</div>
            <div className="col-sm-2 p-2 br grey">Nr wniosku</div>
            <div className="col-sm-3 p-2 grey">Decyzja kredytowa</div>
          </div>

          <div className="row bb">
            <div className="col-sm-3 p-2 br ">{d.credit_score_user?.full_name ?? '-'}</div>
            <div className="col-sm-2 p-2 br ">{d.credit_score_date ?? moment().format('DD.MM.YYYY')}</div>
            <div className="col-sm-2 p-2 br ">
              <Decision isPositive={d.credit_score_qualification} />
            </div>
            <div className="col-sm-2 p-2 br "><Tooltip label={proposal.identifier} /></div>
            <div className="col-sm-3 p-2 ">
              <Select tw='max-w-300' formName={props.formName} options={decisions} name="credit_score_decition" number />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 p-2 ">
              <Textarea formName={props.formName} name="credit_score_comment" placeholder="Komentarz.." />
            </div>
          </div>

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancelButtonClick}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}
                >
                  Anuluj
                </button>
                <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
