import React from 'react';

import { BaseModal } from 'components/Layout/OldModal';
import { parseMoney, formatNip, parsePhone } from '../../../utilities/helpers';

export const DetailsSidebar = (props) => {
  const offer = props.offer || {};
  const calculation = props.calculation || {};
  const client = offer.client || {};

  const getAddress = (a) => `${a.street} ${a.building_no}${a.local_no ? `/${a.local_no}` : ''}, ${a.zipcode} ${a.city}`;

  return (
    <div className='p-20'>
      <div className="row">
        <div className="col-sm-12">
          <h2 className="font-weight-light text-themecolor mb-0">
            Kwota limitu:
            {' '}
            {parseMoney(calculation.limit_amount)}
            {' '}
            {calculation.currency ? calculation.currency.name : ''}
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 text-left grey">
          Data utworzenia:
          {calculation.created_date}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 mt-3 mb-3">
          <button type="button" className="btn btn-sm btn-success mr-3 pl-3 pr-3 pt-2 pb-2">
            <i className="fa fa-envelope mr-3" />
            Wiadomość
          </button>
          <button
            type="button"
            disabled={calculation.status}
            style={{
              backgroundColor: calculation.status ? 'grey' : '#26dad2',
              border: calculation.status ? '1px solid grey' : '1px solid #26dad2'
            }}
            className="btn btn-sm btn-success mr-3 pl-3 pr-3 pt-2 pb-2"
            onClick={() => props.toggleForm('acceptance')}
          >
            <i className="fa fa-envelope mr-3" />
            Zatwierdź
          </button>
          <button
            disabled={!calculation.status}
            type="button"
            className="btn btn-sm btn-success pl-3 pr-3 pt-2 pb-2"
            style={{
              backgroundColor: !calculation.status ? 'grey' : '#26dad2',
              border: !calculation.status ? '1px solid grey' : '1px solid #26dad2'
            }}
            onClick={() => props.toggleForm('generatePdf')}
          >
            {/* <i className="fa fa-envelope mr-3" /> */}
            Generuj PDF
          </button>
        </div>
      </div>
      <div className="row bb">
        <div className="col-12">
          <h4 className="text-themecolor mt-2 mb-2">Opiekunowie</h4>
        </div>
      </div>
      <div className="row p-1 mt-1">
        <div className="col-sm-6 text-left grey">Administrator operacyjny</div>
        <div className="col-sm-6 text-right">{offer.operational_administrator ? offer.operational_administrator.name : '-'}</div>
      </div>
      <div className="row p-1">
        <div className="col-sm-6 text-left grey">Opiekun oferty</div>
        <div className="col-sm-6 text-right">brak</div>
      </div>
      <div className="row p-1">
        <div className="col-sm-6 text-left grey">Opiekun ds. rozliczeń</div>
        <div className="col-sm-6 text-right">{offer.trade_supervisor ? offer.trade_supervisor.full_name : 'brak'}</div>
      </div>
      <div className="row p-1">
        <div className="col-sm-6 text-left grey">Pośrednik</div>
        <div className="col-sm-6 text-right">brak</div>
      </div>
      <div className="row p-1">
        <div className="col-sm-6 text-left grey">Broker</div>
        <div className="col-sm-6 text-right">brak</div>
      </div>

      <div className="rows-bordered">
        <div className="row p-1">
          <div className="col-6">
            <h4 className="text-themecolor mt-2 mb-2">Status oferty</h4>
          </div>
          <div className="col-sm-6 text-right">
            <span className="badge badge-warning mt-2 mb-2 pt-1 pb-1 pl-2 pr-2">
              {calculation.status ? 'zatwierdzona' : 'niezatwierdzona'}
            </span>
          </div>
        </div>
        <div className="row p-1 mt-1">
          <div className="col-sm-6 text-left grey">Faktor</div>
          <div className="col-sm-6 text-right">{offer.factor ? offer.factor.name : 'brak'}</div>
        </div>
        <div className="row p-1">
          <div className="col-sm-6 text-left grey">Produkt</div>
          <div className="col-sm-6 text-right">{offer.product ? offer.product.name : 'brak'}</div>
        </div>
        <div className="row p-1">
          <div className="col-sm-6 text-left grey">Procedura</div>
          <div className="col-sm-6 text-right">{offer.procedure ? offer.procedure.name : 'brak'}</div>
        </div>
      </div>
      <div className="rows-bordered">
        <div className="row p-1">
          <div className="col-12">
            <h4 className="text-themecolor mt-2 mb-2">Klient</h4>
          </div>
        </div>
        <div className="row p-1">
          <div className="col-sm-6 text-left grey">Nazwa firmy</div>
          <div className="col-sm-6 text-right">{client.name || 'brak'}</div>
        </div>
        <div className="row p-1">
          <div className="col-sm-6 text-left grey">NIP</div>
          <div className="col-sm-6 text-right">{formatNip(client.nip)}</div>
        </div>
        <div className="row p-1">
          <div className="col-sm-6 text-left grey">Forma prawna</div>
          <div className="col-sm-6 text-right">{client.legal_form ? client.legal_form.name : 'brak'}</div>
        </div>
        <div className="row p-1">
          <div className="col-sm-6 text-left grey">Telefon</div>
          <div className="col-sm-6 text-right">{parsePhone(client.active_phone)}</div>
        </div>
        <div className="row p-1">
          <div className="col-sm-6 text-left grey">Email</div>
          <div className="col-sm-6 text-right">{client.active_email || 'brak'}</div>
        </div>
        <div className="row p-1">
          <div className="col-sm-6 text-left grey">Adres korespondencji</div>
          <div className="col-sm-6 text-right">{client.active_address ? getAddress(client.active_address) : 'brak'}</div>
        </div>
      </div>

      <div className="rows-bordered">
        <div className="row p-1">
          <div className="col-12">
            <h4 className="text-themecolor mt-2 mb-2">Wniosek</h4>
          </div>
        </div>
        <div className="row p-1">
          <div className="col-sm-6 text-left grey">Numer wniosku</div>
          <div className="col-sm-6 text-right">brak</div>
        </div>
        <div className="row p-1">
          <div className="col-sm-6 text-left grey">Data utworzenia wniosku</div>
          <div className="col-sm-6 text-right">brak</div>
        </div>
        <div className="row p-1">
          <div className="col-sm-6 text-left grey">Status wniosku</div>
          <div className="col-sm-6 text-right">brak</div>
        </div>
      </div>

      <div className="rows-bordered">
        <div className="row p-1">
          <div className="col-12">
            <h4 className="text-themecolor mt-2 mb-2">Umowa</h4>
          </div>
        </div>
        <div className="row p-1">
          <div className="col-sm-6 text-left grey">Numer umowy</div>
          <div className="col-sm-6 text-right">brak</div>
        </div>
        <div className="row p-1">
          <div className="col-sm-6 text-left grey">Data umowy</div>
          <div className="col-sm-6 text-right">brak</div>
        </div>
        <div className="row p-1">
          <div className="col-sm-6 text-left grey">Status umowy</div>
          <div className="col-sm-6 text-right">brak</div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible('acceptance')}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Zatwierdzanie kalkulacji</span>
          </div>
        )}
        onClickAway={() => props.toggleForm('acceptance')}
      >
        <div className="row">
          <div className="col">Czy na pewno chcesz zatwierdzić wybraną kalkulację?</div>
        </div>
        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button
              type="button"
              onClick={() => props.toggleForm('acceptance')}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}
            >
              Anuluj
            </button>
            <button id="btn-confirm-calculation-true" type="button" onClick={props.acceptCalculation} className="btn btn-primary ml-2  font-weight-light">
              Zatwierdź
            </button>
          </div>
        </div>
      </BaseModal>

      <BaseModal
        visible={props.formVisible('generatePdf')}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Generowanie PDF</span>
          </div>
        )}
        onClickAway={() => props.toggleForm('generatePdf')}
      >
        <div className="row">
          <div className="col">Czy na pewno chcesz wygenerować PDF z wybranej oferty?</div>
        </div>
        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button
              type="button"
              onClick={() => props.toggleForm('generatePdf')}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}
            >
              Anuluj
            </button>
            <button type="button" onClick={() => props.generatePdf('generatePdf')} className="btn btn-primary ml-2  font-weight-light">
              Generuj
            </button>
          </div>
        </div>
      </BaseModal>
    </div>
  );
};
