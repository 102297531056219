import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import { isEditableProposal } from 'containers/Proposals/Details/Index';
import { t } from 'lang/pl';
import React, { } from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import QualityForm from '../../Forms/Quality';
import { TextLink } from 'containers/Companies/Details/Components/Notes';

export default (props) => {
  const formName = 'QualityForm';

  const create = () => props.edit({}, formName);

  const edit = (criteria, definition) => {
    definition = { ...definition };
    if (definition.criteria_select_options && definition.criteria_select_options.length) {
      definition.select_options = definition.criteria_select_options.map((o) => ({ ...o, value: o.id }));
      delete definition.criteria_select_options;
      definition.field_option_value = definition.field_option_value?.id;
    }
    props.edit({
      id: `${criteria.id}_${definition.id}`,
      qualities: [
        {
          id: criteria.id,
          definitions: [
            { ...definition, category_id: criteria.id }
          ]
        }
      ]
    }, formName);
  };

  const rows = props.version && props.version.criteria_categories ? props.version.criteria_categories : [];

  return (
    <Card
      header='Faktorant ocena jakościowa'
      isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
      onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
      onAddClick={create}
    >
      <div>
        <ReactTooltip />
        {rows.length ? rows.map((row) => (
          <div className="row">
            <div className="col-sm-12">
              <div className="row bb">
                <div className="col-sm-12 p-2 text-themecolor">
                  {row.name}
                </div>
              </div>
              {row.proposal_criteria && row.proposal_criteria.length ? row.proposal_criteria.map((c) => (
                <>
                  <div className="row bb">
                    <div className="flex-10 max-w-60 p-2 br grey">ID</div>
                    <div className="flex-25 p-2 br grey">Kryterium</div>
                    <div className="flex-20 p-2 br grey">
                      {c.field_name}
                    </div>
                    <div className="flex-10 max-w-100 p-2 br grey">Wymagany</div>
                    <div className="flex-10 max-w-60 p-2 grey">&nbsp;</div>
                  </div>
                  <RowEditable
                    key={row.identifier}
                    onEdit={() => edit(row, c)}
                    onRemove={c.is_required ? false : () => props.remove('criteria', c.id)}
                  >
                    <div className="flex-10 max-w-60 p-2 br ">{c.version_number}</div>
                    <div className="flex-25 p-2 br ">{c.name}</div>
                    <div className="flex-20 p-2 br ">
                      {c.field_type === 0 ? (c.field_boolean_value ? 'tak' : 'nie') : null}
                      {c.field_type === 1 && c.field_option_value ? c.field_option_value.name : null}
                      {c.field_type === 2 ? c.field_numeric_value : null}
                      {c.field_type === 3 ? c.field_date_value : null}
                      {c.field_type === 4 ? c.field_score_value : null}
                    </div>
                    <div className="flex-10 max-w-100 p-2 br ">{c.is_required ? 'tak' : 'nie'}</div>
                    <div className="flex-10 max-w-60 p-2 ">
                      <i className="fa fa-eye" data-tip={c.instruction} />
                    </div>
                  </RowEditable>
                  {c.comment ? (
                    <div className="row bb">
                      <div className="col p-2">
                        <TextLink>{c.comment}</TextLink>
                      </div>
                    </div>
                  ) : '' }
                </>
              )) : <div className="row"><div className="col p-2"><span>Brak danych.</span></div></div>}
            </div>
          </div>
        )) : <div className="row"><div className="col p-2">Brak danych.</div></div>}

        <BaseModal
          visible={props.formVisible(formName)}
          header={(
            <div className="d-flex align-items-center">
              <i className="fas fa-pencil-alt mr-2" />
              <span>Ocena jakościowa</span>
            </div>
          )}
          footer="Aby zakończyć edycję wciśnij Zapisz."
          onClickAway={() => props.toggleForm(formName)}
        >
          <QualityForm
            populate={() => props.populateForm(formName)}
            categories={props.categories}
            fieldTypes={props.fieldTypes}
            forms={props.forms}
            preloadedForm
            visibleOnMount
            clear={props.clear}
            visible={props.formVisible(formName)}
            formName={formName}
            submitForm={() => props.saveForm(formName)}
            onCancelButtonClick={() => props.toggleForm(formName)}
            storeInput={props.storeInput}
            withButtons
          />
        </BaseModal>
      </div>
    </Card>
  );
};
