import { cloneDeep, get, set } from 'lodash';
import { updateObject } from 'utilities/helpers';

const RESET = 'forms/RESET';
const FORM_STORE_INPUT = 'FORM_STORE_INPUT';
const FORM_INITIALIZED = 'FORM_INITIALIZED';

export const initialState = {
  forms: {},
  rules: {},
  errors: {},
  options: {
    visible: []
  }
};

export default function f(state = initialState, { type, payload }) {
  const forms = { ...state.forms };
  const errors = { ...state.errors };
  const rules = { ...state.rules };
  const options = { ...state.options };

  switch (type) {
    case FORM_INITIALIZED:
      forms[payload.name] = payload.preloadedForm;
      rules[payload.name] = payload.rules;
      errors[payload.name] = {};

      return {
        ...state, forms, errors, rules
      };
    case FORM_STORE_INPUT:
      set(get(forms, payload.form), payload.field, payload.value);
      return { ...state, forms };
    case 'FORM_ERRORS_RAISED':
      errors[payload.form] = payload.errors;
      return { ...state, errors };
    case 'FORM_ERRORS_CLEARED':
      errors[payload] = {};
      return { ...state, errors };
    case 'FORM_CLEARED':
      forms[payload] = {};
      return { ...state, forms };
    case 'FORM_FIELD_CLEARED':
      set(forms[payload.form], payload.field, '');
      // forms[payload.form][payload.field] = "";
      return { ...state, forms };
    case 'FORM_FIELD_DELETED':
      delete forms[payload.form][payload.field];
      return { ...state, forms };
    case 'FORM_TOGGLED':
      if (options.visible.indexOf(payload) === -1) {
        options.visible.push(payload);
      } else {
        options.visible = options.visible.filter((i) => i !== payload);
      }
      return { ...state, options };
    case 'FORM_HIDDEN':
      return {
        ...state,
        options: {
          visible: []
        }
      };
    case 'FORM_DELETED':
      return {
        ...state,
        forms: {
          ...forms,
          [payload]: {}
        },
        options: {
          ...options, visible: options.visible.filter((i) => i !== payload)
        }
      };
    case RESET:
      return updateObject(state, initialState);
    default:
      return state;
  }
}

export const storeInput = (form, field, value) => {
  return { type: FORM_STORE_INPUT, payload: { form, field, value } };
};

export const populateForm = (form, preloadedForm, rules) => {
  return { type: FORM_INITIALIZED, payload: { name: form, preloadedForm: cloneDeep(preloadedForm), rules } };
};
