import ListFilters from 'components/ListFilters';
import { t } from 'lang/pl';
import React from 'react';
import { toast } from 'react-toastify';

export default function OptionsPanel({ additionalOptions, onClickAddButton, onChangeFilters }) {
  return (
    <div className="flex items-center justify-between my-10">
      <div>
        {additionalOptions && (
          <select className="form-control w-initial">
            {additionalOptions?.map(({ id, value, name }) => (
              <option key={id} value={value}>
                {name}
              </option>
            ))}
          </select>
        )}
      </div>

      <ListFilters onChange={onChangeFilters} />

      <div className="text-right">
        <h4 className="text-themecolor" style={{ cursor: 'pointer' }}>
          <i className="fa fa-ellipsis-h mr-2" onClick={() => toast.error(t.error.toast.notImplemented)} />
          <i className="fa fa-plus" onClick={onClickAddButton} />
        </h4>
      </div>
    </div>
  );
}
