import FormActions from 'actions/forms';
import React from 'react';
import { connect } from 'react-redux';

class OldOrderBy extends React.PureComponent {
  orderBy() {
    const form = this.props.forms.forms[this.props.formName] || {};

    let direction = 'descending';

    if (this.areResultsAlreadySortedByField()) {
      const currentDirection = form.direction || 'ascending';
      direction = currentDirection === 'ascending' ? 'descending' : 'ascending';
    }

    this.props.dispatch(FormActions.storeInput(this.props.formName, 'direction', direction));
    this.props.dispatch(FormActions.storeInput(this.props.formName, 'order_by', this.props.field));

    this.props.onSubmit();
  }

  areResultsAlreadySortedByField() {
    const form = this.props.forms.forms[this.props.formName] || {};
    const currentOrderBy = form.order_by || null;

    return currentOrderBy === this.props.field;
  }

  render() {
    const style = {
      cursor: 'pointer',
      fontSize: '90%'
    };

    let spanClassName = this.props.spanClassName || '';
    spanClassName = this.areResultsAlreadySortedByField() ? `${spanClassName} label-right` : `${spanClassName} grey font-weight-light`;

    return (
      <span style={style} className={spanClassName} onClick={this.orderBy.bind(this)}>
        <i className="fas fa-sort mr-1" />
        {this.props.column}
      </span>
    );
  }
}

const mapStateToProps = state => ({
  forms: state.Forms
});

export default connect(
  mapStateToProps,
  null
)(OldOrderBy);
