import SelectCore from 'components/Forms/SelectCore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const inputStyle = {
  width: '40px',
  height: '35px',
  margin: '0 5px',
  textAlign: 'center',
  border: '0.5px solid #878F9A',
  borderRadius: '8px'
};

const options = [
  { id: 1, label: '20', value: 20 },
  { id: 2, label: '50', value: 50 },
  { id: 3, label: '100', value: 100 }
];

export const Pagination = ({ withSelect, currentPage, count, onChangePage }) => {
  const { limit } = useSelector(state => state.Options);
  const [inputPage, setInputPage] = useState(currentPage);
  const [currentLimit, setCurrentLimit] = useState(limit);

  const lastPage = Math.ceil(count / currentLimit) || 1;

  const inputOnKeyUp = e => {
    if (e.key === 'Enter' && inputPage >= 1 && inputPage <= lastPage) {
      onChangePage(inputPage, limit);
    }
  };

  const handleFirstPage = () => {
    if(inputPage !== 1) {
      setInputPage(1);
      onChangePage(1, limit);
    }
  };

  const handlePreviousPage = () => {
    if(inputPage > 1) {
      setInputPage(prev => prev - 1);
      onChangePage(inputPage - 1, limit);
    }
  };

  const handleNextPage = () => {
    if(inputPage < lastPage) {
      setInputPage(prev => prev + 1);
      onChangePage(inputPage + 1, limit);
    }
  };

  const handleLastPage = () => {
    if(inputPage !== lastPage) {
      setInputPage(lastPage);
      onChangePage(lastPage, limit);
    }
  };

  const handleInputChange = ({ target: { value } }) => {
    setInputPage(value);
  };

  const handleSelectChange = ({ value }) => {
    onChangePage(1, value);
    setCurrentLimit(value);
    setInputPage(1);
  };

  return (
    <div className='row items-center justify-between px-15 py-10 mt-05 bg-light'>
      <div className='w-200 text-left'>
        Ilość rekordów: {count}
      </div>

      <div className='row items-center justify-center'>
        <i className="fas page-item fa-fast-backward mr-3" onClick={handleFirstPage} />

        <i className="fas page-item fa-backward mr-3" onClick={handlePreviousPage} />

        <span className="mr-3">
          Strona {<input type="number" value={inputPage} onChange={handleInputChange} onKeyUp={inputOnKeyUp} style={inputStyle} />}{' '}
            z {lastPage}
        </span>

        <i className="fas page-item fa-forward mr-3" onClick={handleNextPage} />
        <i className="fas page-item fa-fast-forward mr-3" onClick={handleLastPage} />
      </div>

      <div className='w-200 mt-10'>
        {withSelect && (
          <SelectCore
            className='w-90 ml-auto'
            onChange={handleSelectChange}
            defaultValue={options.find(item => item.value === limit)}
            options={options}
          />
        )}
      </div>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired
};
