// import { cloneDeep } from 'lodash';

const initialState = {
  list: [],
  links: {},
  count: 0,
  currentPage: 1
};

export default function Proposals(state = initialState, action) {
  switch (action.type) {
    case 'DUES_FETCHED':
      return {
        ...state,
        list: action.payload.results,
        links: action.payload.links,
        currentPage: action.payload.current_page,
        count: action.payload.count
      };
    default:
      return state;
  }
}
