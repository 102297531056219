import FormActions from 'actions/forms';
import ProposalActions from 'actions/proposal';
import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Form, Select } from '../../../../components/Forms';
import QualityForm from '../../Forms/Quality';

export default props => {
  const dispatch = useDispatch();

  const [qualitiesErrors, setQualitiesErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const [newCriteria, setNewCriteria] = useState(false);
  const formName = 'FraudCreatorForm';

  const qualities = props.forms && props.forms[formName] ? props.forms[formName].qualities : [];

  const x =
    props.categories && qualities
      ? props.categories
        .map(category => ({
          ...category,
          definitions: category.definitions
            .filter(definition => {
              const inForm = qualities.find(i => i.id === category.id);
              if (inForm) {
                return !inForm.definitions.map(d => d.id).includes(definition.id);
              }
              return true;
            })
            .filter(definition => definition.is_aml)
            .map(definition => ({
              ...definition,
              category_id: category.id,
              category_name: category.name
            }))
        }))
        .map(category => category.definitions)
        .filter(definitions => definitions.length)
        .flat()
      : [];

  const addNewCriteria = id => {
    const [categoryId, definitionId] = id.split('_');
    const currentCriteria = qualities.find(i => i.id === Number(categoryId));
    let object;

    if (currentCriteria) {
      object = props.categories
        .find(category => category.id === Number(categoryId))
        .definitions.find(definition => definition.id === Number(definitionId));
      object.category_id = categoryId;
      currentCriteria.definitions.push(object);
    } else {
      object = props.categories.find(category => category.id === Number(categoryId));
      object.definitions = object.definitions
        .filter(definition => definition.id === Number(definitionId))
        .map(definition => ({ ...definition, category_id: categoryId }));
      qualities.push(object);
    }

    props.storeInput(formName, 'qualities', qualities);
    setNewCriteria(false);
  };

  useEffect(() => {
    if (typeof props.populateForm !== 'undefined') {
      props.populateForm(formName);
    }
    // eslint-disable-next-line
  }, [props.version]);

  const handleQualities = errors => {
    setQualitiesErrors(errors);

    dispatch(ProposalActions.fetchProposalVersion(props.proposal.id, props.version.id)).then(({ data }) => {
      const currentCriteria = data?.criteria_categories_aml;

      if(currentCriteria.length) {
        const proposalCriteria = currentCriteria.map(item => {
          item.definitions = item.proposal_criteria?.map(proposalCriteria => ({
            ...proposalCriteria,
            field_option_value: proposalCriteria?.field_option_value?.id,
            select_options: proposalCriteria.criteria_select_options,
            category_id: item.id
          }));
          delete item.proposal_criteria;
          return item;
        });
        dispatch(FormActions.storeInput(formName, 'qualities', proposalCriteria));
        setLoading(false);
      }
    });
  };

  const handleCreateList = () => {
    dispatch(ProposalActions.addCriteria(qualities)).then(errors => handleQualities(errors));
  };

  const handleUpdateList = () => {
    dispatch(ProposalActions.updateCriteria(qualities)).then(errors => handleQualities(errors));
  };

  const handleSubmit = () => {
    setLoading(true);

    dispatch(ProposalActions.fetchProposalVersion(props.proposal.id, props.version.id)).then(({ data }) => {
      if(data?.criteria_categories_aml?.length) {
        handleUpdateList();
      } else {
        handleCreateList();
      }
    });
  };

  return (
    <Card loading={loading} mode='creator' header='+ Faktorant ocena Ryzyka fraudowego i AML' onSaveClick={handleSubmit} isBlocked={false}>
      <QualityForm
        aml
        creator
        qualitiesErrors={qualitiesErrors}
        populate={() => props.populateForm(formName)}
        categories={props.categories}
        fieldTypes={props.fieldTypes}
        forms={props.forms}
        preloadedForm
        visibleOnMount
        visible
        clear={props.clear}
        formName={formName}
        submitForm={() => props.saveForm(formName)}
        onCancelButtonClick={() => props.toggleForm(formName)}
      />

      <BaseModal
        visible={newCriteria}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" /> <span>Dodaj nowe kryterium</span>
          </div>
        }
        onClickAway={() => setNewCriteria(false)}>
        <Form className="form-horizontal" formName="NewCriteriaForm">
          <div className="row mb-3">
            <label htmlFor="example-text-input " className="col-sm-2 col-form-label label-right">
              Kryterium
            </label>
            <div className="col-sm-8">
              <Select
                formName="NewCriteriaForm"
                options={x.map(y => ({ value: `${y.category_id}_${y.id}`, name: `${y.category_name} / ${y.name}` }))}
                name="i"
                onChange={() => addNewCriteria(props.forms.NewCriteriaForm.i)}
              />
            </div>
          </div>
        </Form>
      </BaseModal>
    </Card>
  );
};
