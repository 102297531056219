import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import { getCommissionsNameByType, getIdentifierByType, getKeyByType } from 'containers/Offers/actions';
import { t } from 'lang/pl';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import { CommissionForm } from '../../Forms/Commissions';
import RemovalModal from '../../Forms/RemovalModal';

export const Commissions = (props) => {
  const [isEditState, setEditState] = useState(false);
  const { CommmissionsForm: form } = useSelector(state => state.Forms.forms);
  const [objectToRemove, setObjectToRemove] = useState({});

  const formName = 'CommmissionsForm';

  const calculation = props.calculation || {};
  const commissions = calculation.commissions || {};

  const getTypeByParentName = (object, type) => {
    const i = object?.business_company?.find((y) => y.name === getKeyByType(type));
    return i ? i.id : 0;
  };

  const getCommissionType = (type) => {
    const foundType = props.types.find((t) => t.id === type);
    return foundType ? foundType.name : 'brak';
  };
  const getCommissionUnit = (type, unit) => {
    const foundType = props.types.find((t) => t.id === type);
    if (foundType) {
      const foundUnit = foundType.units.find((u) => u.id === unit);
      return foundUnit ? foundUnit.name : 'brak';
    }
    return '-';
  };

  const edit = (commission, f, type) => {
    setEditState(true);

    const commissionToEdit = {
      type: commission.role ? commission.role.id : getTypeByParentName(commission.object, type),
      role: getKeyByType(type),
      role_identifier: getIdentifierByType(type),
      beneficiary: commission.object,
      commission_type: commission.commission_type,
      unit: commission.unit,
      id: commission.id,
      max_value: commission.max_value
    };

    props.edit(commissionToEdit, f);
  };

  const hasCommissions = Object.keys(commissions).map((k) => commissions[k]).flat().length;

  const handleSaveForm = () => {
    if(!form?.role_identifier || !form?.beneficiary || !form?.max_value) {
      toast.warn(t.error.toast.fieldWarning);
    } else {
      props.saveForm(formName);
    }
  };

  return (
    <Card
      header='Prowizje'
      onAddClick={() => props.create(formName)}
      isBlocked={!props.isEditable}
      onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
    >
      <div>
        <div className="row">
          <div className="col-sm-12">
            {hasCommissions ? Object.keys(commissions).map((type) => {
              if (commissions[type].length > 0) {
                return (
                  <React.Fragment key={type}>
                    <div className="row bb">
                      <div className="col-sm-12 p-2" style={{ color: '#006BFF' }}>
                        Rola:
                        {' '}
                        {getCommissionsNameByType(type)}
                      </div>
                    </div>
                    <div className="row bb">
                      <div className="col-sm-4 p-2 br grey">Beneficjent</div>
                      <div className="col-sm-3 p-2 br grey">Typ</div>
                      <div className="col-sm-3 p-2 br grey">Sposób liczenia</div>
                      <div className="col-sm-2 p-2 grey">Wysokość</div>
                      {/* <div className="col-sm-1 p-2 grey">Waluta</div> */}
                    </div>
                    {commissions[type].map((commission) => (
                      <RowEditable
                        isObjectToRemove
                        key={commission.id}
                        onEdit={() => edit(commission, formName, type)}
                        onRemove={() => {
                          setObjectToRemove(commission);
                        }}
                      >
                        <div className="col-sm-4 p-2 br">
                          {commission && commission.object ? commission.object.name || commission.object.full_name : 'brak'}
                        </div>
                        <div className="col-sm-3 p-2 br">{getCommissionType(commission.commission_type)}</div>
                        <div className="col-sm-3 p-2 br">
                          {getCommissionUnit(commission.commission_type, commission.unit)}
                        </div>
                        <div className="col-sm-2 p-2">{commission.max_value}</div>
                      </RowEditable>
                    ))}
                  </React.Fragment>
                );
              }
              return null;
            }) : (
              <div className="row">
                <div className="col p-2">
                  <span>Brak danych.</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Ustawienia prowizji</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => {
          props.toggleForm(formName);
          setEditState(false);
        }}
      >
        <CommissionForm
          isEditState={isEditState}
          systemRoles={props.systemRoles}
          objectTypes={props.objectTypes}
          types={props.types}
          fetch={() => props.fetchBeneficiaries(formName)}
          clearBeneficiaries={props.clearBeneficiaries}
          beneficiaries={props.beneficiaries}
          formName={formName}
          offer={props.offer}
          preloadedForm
          visibleOnMount={false}
          clear={props.clear}
          visible={props.formVisible(formName)}
          formVisible={props.formVisible}
          onCancel={() => {
            props.onCancel(formName);
            setEditState(false);
          }}
          saveForm={handleSaveForm}
          withButtons
          errors={props.errors[formName]}
          form={props.forms[formName]}
          toggleForm={props.toggleForm}
        />
      </BaseModal>

      <RemovalModal
        visible={Object.keys(objectToRemove).length > 0}
        toggle={() => setObjectToRemove({})}
        remove={() => {
          props.remove(objectToRemove);
          setObjectToRemove({});
        }}
      >
        {objectToRemove && (
          <div className="container-fluid">
            <div className="row bb bt">
              <div className="col-sm-4 p-2 br grey">Beneficjent</div>
              <div className="col-sm-3 p-2 br grey">Typ</div>
              <div className="col-sm-3 p-2 br grey">Sposób liczenia</div>
              <div className="col-sm-2 p-2 grey">Wysokość</div>
            </div>
            <div className="row bb">
              <div className="col-sm-4 p-2 br">
                {objectToRemove && objectToRemove.object ? objectToRemove.object.name || objectToRemove.object.full_name : 'brak'}
              </div>
              <div className="col-sm-3 p-2 br">{getCommissionType(objectToRemove.commission_type)}</div>
              <div className="col-sm-3 p-2 br">{getCommissionUnit(objectToRemove.commission_type, objectToRemove.unit)}</div>
              <div className="col-sm-2 p-2">{objectToRemove.max_value}</div>
            </div>
          </div>
        )}
      </RemovalModal>
    </Card>
  );
};
