import HttpClient, { SF_ACCESS_TOKEN, SF_REFRESH_TOKEN } from '../utilities/http';

const login = (data) => {
  const request = new URLSearchParams();
  const requestData = {
    ...data,
    grant_type: 'password',
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET
  };

  Object.keys(requestData).forEach(
    (key) => request.set(key, requestData[key])
  );

  return HttpClient.post('/o/token/', request);
};

const getUserInfo = () => HttpClient.get('/user/profile/');

const logout = () => new Promise((resolve) => {
  localStorage.removeItem('state');
  localStorage.removeItem(SF_ACCESS_TOKEN);
  localStorage.removeItem(SF_REFRESH_TOKEN);
  resolve();
});

export default {
  login,
  logout,
  getUserInfo
};
