import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import FormActions from '../../actions/forms';
import CommonService from '../../services/common';

export default connect(
  state => ({ forms: state.Forms }),
  dispatch => ({
    storeInput: (f, n, v) => dispatch(FormActions.storeInput(f, n, v))
  })
)(props => (
  <>
    {props.customElement || (
      <button
        onClick={() => {
          document.getElementById(`f1l31nput-${props.formName}-${props.url}`).click();
        }}
        type="button">
        {props.text || 'Dodaj załącznik'}
      </button>
    )}
    <input
      style={{ visibility: 'hidden' }}
      id={`f1l31nput-${props.formName}-${props.url}`}
      key={`f1l31nput-${props.formName}-${props.url}`}
      name={`attachment-${props.formName}-${props.url}`}
      type="file"
      onChange={e => {
        if (props.storeIn) {
          props.storeInput(props.formName, props.storeIn, e.target.files[0]);
        }
        if (props.url) {
          CommonService.uploadAttachment(e.target.files[0], props.url, props.data || {}, props.method, props.fileName)
            .then(r => r.json())
            .then(r => {
              if (r.status && Array.isArray(r.status)) {
                toast.error(r.status?.[0]);
                return;
              }
              if (props.field) {
                let data = get(props.forms?.forms?.[props.formName], props.field, props.single ? {} : []);

                if (Array.isArray(data)) {
                  data = props.objectId
                    ? data.map(element => {
                      if (element.id === props.objectId) {
                        return r;
                      } else {
                        return element;
                      }
                    })
                    : [...data, r];
                }
                props.storeInput(props.formName, props.field, props.single ? { ...data, ...r } : data);
              }
              if (props.onSuccess) {
                props.onSuccess(r);
              }
              if (!props.noToast) {
                toast.success('Załącznik dodany.');
              }
            });
        }
      }}
    />
  </>
));
