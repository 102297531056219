import FormActions from 'actions/forms';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { to, toAll } from 'utilities/helpers';

const defaultOptions = {
  loading: true,
  loadingOnRequest: true,
  msDelayLoading: 0,
  data: null,
  errorText: ''
};

export default function useData(options = {}) {
  const opt = { ...defaultOptions, ...options };

  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();

  const [data, setData] = useState(opt.data);
  const [loading, setLoading] = useState(opt.loading);
  const [errorText, setErrorText] = useState(opt.errorText);

  const sendSilentRequest = async (request, onSuccess = () => {}, onError = () => {}) => {
    opt.loadingOnRequest && setLoading(true);

    const [error, response] = await to(request);

    if (isMountedRef.current) {
      if (error) {
        onError(error);
      } else {
        setErrorText(opt.errorText);
        onSuccess(response?.data);
      }
      opt.msDelayLoading ? setTimeout(() => setLoading(false), opt.msDelayLoading) : setLoading(false);
    }
  };

  const sendRequest = async (request, onSuccess = () => {}, onError = () => {}) => {
    opt.loadingOnRequest && setLoading(true);

    const [error, response] = await to(request);

    if (isMountedRef.current) {
      if (error) {
        onError(error);
      } else {
        setErrorText(opt.errorText);
        setData(response?.data);
        onSuccess(response?.data);
      }
      opt.msDelayLoading ? setTimeout(() => setLoading(false), opt.msDelayLoading) : setLoading(false);
    }
  };

  const sendDispatchRequest = useCallback(async (request, params = {}, onSuccess = () => {}, onError = () => {}) => {
    opt.loadingOnRequest && setLoading(true);

    const [error, response] = await to(dispatch(request(params)));

    if (isMountedRef.current) {
      if (error) {
        dispatch(FormActions.handleErrors(opt.formName, error?.response?.data));
        onError(error);
      } else {
        setErrorText(opt.errorText);
        setData(response);
        onSuccess(response);
      }
      opt.msDelayLoading ? setTimeout(() => setLoading(false), opt.msDelayLoading) : setLoading(false);
    }
  }, [opt, isMountedRef, loading, data]);

  const sendRequests = async (...args) => {
    opt.loadingOnRequest && setLoading(true);

    const [error, response] = await toAll(...args);

    if (isMountedRef.current) {
      opt.msDelayLoading ? setTimeout(() => setLoading(false), opt.msDelayLoading) : setLoading(false);

      if (error) {
        return error;
      }
      return response;
    }
    return null;
  };

  return {
    data,
    setData,
    loading,
    setLoading,
    errorText,
    setErrorText,
    sendSilentRequest,
    sendRequest,
    sendDispatchRequest,
    sendRequests
  };
}
