import { cloneDeep, isObject, omit } from 'lodash';

import HttpClient from '../utilities/http';

export default {
  authorizeMany: ids => Promise.all(ids.map(id => HttpClient.patch(`/payments/${id}/authorize/`))),
  connectWithClaim: (payment, claims) => HttpClient.post(`/payments/${payment.id}/create_link/`, claims),
  fetchFactorsForImport: params =>
    HttpClient.get('/offers/search_people_and_companies/', {
      params: {
        ...params,
        role_name: 'faktor'
      }
    }),
  fetchInvoicesForConnect: params => HttpClient.get('/invoices/?status=2&context=1', { params }),
  fetchClaimsForConnect: params => HttpClient.get('/claims/?exclude_settled=1', { params }),
  fetchClientsForConnect: () => HttpClient.get('/claims/clients/', {}),
  getAgreement: id => HttpClient.get(`/agreements/${id}/`),
  getClaim: id => HttpClient.get(`/claims/${id}/`),
  removeTransfer: id => HttpClient.delete(`/payments/${id}/`),
  fetchForForm: (what, data) => {
    switch (what) {
      case 'agreements':
        return HttpClient.get('/agreements/?has_claims=1');
      case 'claims':
        return HttpClient.get(`/claims/?agreement_id=${data.agreement?.id}`);
      case 'agreement':
        return HttpClient.get(`/agreements/${data.agreement?.id}`);
      default:
        return Promise.resolve([]);
    }
  },
  handleExport: (id, shouldExport) => HttpClient[shouldExport ? 'put' : 'delete'](`/payments/export/${id}/`),
  fetch: params => HttpClient.get('/payments/', { params }),
  create: data => HttpClient.post('/payments/creator/', data),
  saveForm: (form, data) => {
    const forcePut = false;
    data = cloneDeep(data);
    let u = '/payments/';

    const mutate = (x, excludedKeys = []) => {
      x = { ...x };
      Object.keys(x).forEach(key => {
        if (excludedKeys.indexOf(key) === -1 && (isObject(x[key]) || x[key] === null)) {
          delete x[key];
        }
      });

      return x;
    };

    switch (form) {
      case 'TransfersSelectForExportForm':
        u += 'export/';
        break;
      case 'CreateTransferForm':
        data = mutate({
          ...data,
          sender_bank_account_id: data.sender_bank_account?.id,
          recipient_bank_account_id: data.recipient_bank_account?.id
        });
        break;
      case 'CancelAuthorizationForm':
        u += 'cancel_authorization/';
        break;
      default:
        break;
    }

    Object.keys(data).forEach(key => {
      if (data[key] === null) {
        delete data[key];
      }
    });

    u = data.id ? `${u + data.id}/` : u;

    return data.id || forcePut ? HttpClient.put(u, omit(data, 'id')) : HttpClient.post(u, data);
  },
  fetchDetails: id => HttpClient.get(`/payments/${id}/`),
  remove: (agreementId, uri, objectId, version) =>
    HttpClient.delete(`/agreements/${agreementId}/${version ? `agreement_versions/${version}/` : ''}${uri}/${objectId}/`)
};
