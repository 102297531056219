import { cloneDeep, isObject, pick } from 'lodash';

import HttpClient from '../utilities/http';

const fetch = (filters) => HttpClient.get('/offers/', { params: filters });
const fetchOfferDetails = (id) => HttpClient.get(`/offers/${id}/`);
const create = (data) => {
  data = { ...data };
  data.client_id = data.client ? data.client.id : null;
  data.factor_id = data.factor ? data.factor.id : null;
  data.offer_keeper_id = data.offer_keeper ? data.offer_keeper.id : null;
  data.trade_supervisor_id = data.trade_supervisor ? data.trade_supervisor.id : null;
  data.operational_administrator_id = data.operational_administrator?.id ?? null;
  data.billing_manager_id = data.billing_manager ? data.billing_manager.id : null;

  Object.keys(data).forEach((key) => {
    if (!data[key] || isObject(data[key])) {
      delete data[key];
    }
  });

  return HttpClient.post('/offers/creator/', data);
};

const saveForm = async (form, data, offerId, calculation) => {
  data = cloneDeep(data);
  let u = '/offers/';

  const mutate = (x) => {
    x = { ...calculation, ...x };
    Object.keys(x).forEach((key) => {
      if (isObject(x[key])) {
        delete x[key];
      }
    });

    return x;
  };

  switch (form) {
    case 'NotesForm':
    case 'NotesCreatorForm':
    case 'NotesCreatorEditForm':
      u = `${u + offerId}/notes/`;
      break;
    case 'ConditionsForm':
    case 'ConditionsCreatorForm':
      u = `${u + offerId}/calculations/`;
      data = pick(data, ['max_maturity', 'max_financing_period', 'claim_limit', 'repayment_of_capital', 'split_payment']);
      data = mutate(data);
      break;
    case 'AdministratorForm':
    case 'AdministratorCreatorForm':
      u = `${u + offerId}/calculations/`;
      data = pick(data, ['oa_lump_sum_amount', 'oa_lump_sum_freq']);
      data = mutate(data);
      break;
    case 'ReadinessForm':
    case 'ReadinessCreatorForm':
      u = `${u + offerId}/calculations/`;
      data = pick(data, [
        'declared_use',
        'preparation_commission_type',
        'unused_amount_commission',
        'percentage_unused_amount_commission',
        'limit_commission',
        'start_up_contract',
        'billing_period',
        'counting_method'
      ]);
      data = mutate(data);
      break;
    case 'CalculationsForm':
      u = `${u + offerId}/calculations/`;
      data.currency_id = data.currency;
      data = pick(data, [
        'currency_id',
        'duration_of_the_contract',
        'limit_amount',
        'recourse',
        'many_debtors',
        'transaction_risk_category_id',
        'preparation_commission',
        'preparation_commission_type',
        'deducted_preparation_commission',
        'comment'
      ]);

      data = mutate(data);
      delete data.id;

      break;
    case 'CalculationsCreatorForm':
    case 'CalculationsEditForm':
      u = `${u + offerId}/calculations/`;
      data.id = data.id || data.calculationId;
      data.currency_id = data.currency;
      data = pick(data, [
        'currency_id',
        'duration_of_the_contract',
        'limit_amount',
        'recourse',
        'many_debtors',
        'transaction_risk_category_id',
        'preparation_commission',
        'preparation_commission_type',
        'deducted_preparation_commission',
        'comment'
      ]);
      data = mutate(data);
      break;
    case 'BuyoutDocsForm':
    case 'BuyoutDocsCreatorForm':
      u = `${u + offerId}/calculations/${data.calculationId}/documents_to_buy/`;
      data.dtb_type_id = data.dtb_type.id;
      delete data.dtb_type;
      break;
    case 'ProtectionsForm':
    case 'ProtectionsCreatorForm':
      u = `${u + offerId}/calculations/${data.calculationId}/documents_to_secure/`;
      data.dts_type_id = data.dts_type.id;
      delete data.dts_type;
      break;
    case 'BasicRatesForm':
    case 'BasicRatesCreatorForm':
    case 'BasicRatesEditForm':
      if (!data.is_intrest_margin) {
        delete data.intrest_margin;
      }
      u = `${u + offerId}/calculations/${data.calculationId}/basic_bid/`;
      break;
    case 'AdditionalRatesForm':
    case 'AdditionalRatesCreatorForm':
    case 'AdditionalRatesEditForm':
      u = `${u + offerId}/calculations/${data.calculationId}/additional_bid/`;
      break;
    case 'MultilevelRatesForm':
    case 'MultilevelRatesCreatorForm':
    case 'MultilevelRatesEditForm':
      u = `${u + offerId}/calculations/${data.calculationId}/multilevel_bid/`;
      if (data.levels && data.levels.length > 0) {
        data.levels.map((level) => {
          level.document_to_buy_types_ids = level.document_to_buy_types.map((d) => d.id);
          delete level.document_to_buy_types;
          return level;
        });
      }
      break;
    case 'CommmissionsForm':
    case 'CommmissionsCreatorForm':
    case 'CommmissionsEditForm':
      u = `${u + offerId}/calculations/${data.calculationId}/commissions/`;

      data = {
        ...data,
        content_type_id: data.beneficiary.content_type_id,
        object_id: data.beneficiary.id,
        commission_type: data.commission_type,
        unit: data.unit,
        max_value: data.max_value,
        currency_id: data.currency_id,
        id: data.id
      };
      break;
    default:
      break;
  }

  Object.keys(data).forEach((key) => {
    if (data[key] === null) {
      delete data[key];
    }
  });

  u = data.id ? `${u + data.id}/` : u;

  return data.id ? HttpClient.put(u, data) : HttpClient.post(u, data);
};

const removeObject = (objectId, offerId, type, extraData = '') => HttpClient.delete(`/offers/${offerId}/${type}/${objectId}/${extraData}`);

const removeOffer = (id) => HttpClient.delete(`/offers/${id}`);

const getFlatRate = (data) => HttpClient.post('/offers/basic_bid/calc_flat_rate/', {
  transaction_risk_category_id: Number(data.transaction_risk_category_id),
  max_advance_payment: Number(data.max_advance_payment),
  max_payment_date: Number(data.max_payment_date)
});

const getMultilevelFlatRate = (data) => HttpClient.post('/offers/multilevel_bid/calc_flat_rate/', {
  transaction_risk_category_id: Number(data.transaction_risk_category_id),
  max_payment_date: Number(data.max_payment_date),
  max_advance_payments: data.levels.map((l) => Number(l.max_advance_payment))
});

const getIntrestRates = (data) => HttpClient.post('/offers/intrest_rate_per_day/', {
  date: data.intrest_margin.date_of_rate
});

const fetchBeneficiaries = (data) => HttpClient.get('/offers/search_people_and_companies/', { params: data });

const acceptCalculation = (offerId, calculationId) => HttpClient.patch(`/offers/${offerId}/calculations/${calculationId}/accept/`);

const generatePdf = (offerId) => HttpClient.get(`/offers/${offerId}/pdf/`, { responseType: 'blob' });

const changeStatus = (offerId) => HttpClient.put(`/offers/${offerId}/change_offer_status/`);

export default {
  fetch,
  fetchOfferDetails,
  create,
  saveForm,
  removeObject,
  removeOffer,
  getFlatRate,
  getMultilevelFlatRate,
  getIntrestRates,
  fetchBeneficiaries,
  acceptCalculation,
  generatePdf,
  changeStatus
};
