import LoaderBackdrop from 'components/Layout/LoaderBackdrop';
import Header from 'containers/Claims/Details/Components/Header';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ClaimActions from '../../../actions/claim';
import CommonActions from '../../../actions/common';
import FormActions from '../../../actions/forms';
import InvoiceActions from '../../../actions/invoice';
import { Container, MainContainer } from '../../../components/Layout';
import { SidebarDetails } from '../../../components/SidebarDetails';
import Constants from '../../../utilities/constants';
import Tabs from '../Tabs';
import Conditions from './Tabs/Conditions';
import Documents from './Tabs/Documents';
import Params from './Tabs/Params';
import Settlements from './Tabs/Settlements';
import Transactions from './Tabs/Transactions';
import { Notes } from 'containers/Companies/Details/Components/Notes';

export const STATUS_CLAIMS = {
  WPROWADZONA: 0,
  DO_ZATWIERDZENIA: 1,
  ZATWIERDZONA: 2,
  ZATWIERDZONA_ODSTEPSTWO: 3,
  ODRZUCONA: 4,
  AKCEPT: 5,
  ANULOWANA: 6,
  WYGENEROWANO_ZALICZKE_CZESCIOWO: 7,
  WYGENEROWANO_ZALICZKE: 8,
  WYPLACONO_ZALICZKE_CZESCIOWO: 9,
  WYPLACONO_ZALICZKE: 10,
  POWIAZANA: 11,
  W_ROZLICZENIU: 12,
  ROZLICZONY_KAPITAL: 13,
  ROZLICZONE_WYN_PODSTAWOWE: 14,
  ROZLICZONE_WYN_DODATKOWE: 15,
  ROZLICZONA: 16,
  ZAKSIEGOWANA: 17,
  ARCHIWUM: 18
};

export const STATUS_DUE_CLAIMS = {
  TERMINOWA: 0,
  PREWENCJA: 1,
  PRZTERMINOWANA: 2,
  MONIT: 3,
  WEZWANIE: 4,
  POZEW: 5,
  NAKAZ_ZAPLATY: 6,
  KLAUZULA: 7,
  EGZEKUCJA_KOMORNICZA: 8,
  UMORZENIE_EGZEKUCJI: 9,
  UTRACONA: 10
};

class Index extends React.Component {
  steps = [
    { id: 1, name: 'Komunikacja' },
    { id: 2, name: 'Warunki handlowe' },
    { id: 3, name: 'Dokumenty wykupu' },
    { id: 4, name: 'Rozliczenia i ewidencja sald' },
    { id: 5, name: 'Transakcje' }
  ];

  constructor(props) {
    super(props);
    const { slug } = this.props.match.params;
    const id = slug ? slug.split('-').slice(-1)[0] : null;
    this.props.dispatch(ClaimActions.fetchDetails(id));

    this.props.dispatch(CommonActions.changeView(Constants.Views.Details));
  }

  componentDidMount() {
    this.props.dispatch(
      CommonActions.setContextMenu([
        {
          name: 'Lista wierzytelności',
          link: '/wierzytelnosci'
        },
        {
          name: 'Wierzytelność',
          link: '#',
          options: [
            { name: 'Zatwierdź', onClick: () => this.toggleForm('ClaimChangeStatus') },
            { name: 'Do edycji', onClick: () => this.toggleForm('ClaimChangeStatus') },
            { name: 'Usuń', onClick: () => this.toggleForm('ClaimChangeStatus') },
            { name: 'Akceptuj odstępstwa', onClick: () => this.toggleForm('ClaimChangeStatus') },
            { name: 'Anuluj', onClick: () => this.toggleForm('ClaimChangeStatus') }
          ]
        },
        {
          name: 'Kwota wykupu',
          onClick: () => {}
        },
        {
          name: 'Edytuj stawkę',
          onClick: () => {
            this.changeTab(2);
            this.toggleForm('ClaimBidsForm');
          }
        },
        {
          name: 'Notatka',
          onClick: () => {
            this.changeTab(1);
            this.toggleForm('NotesForm');
          }
        },
        {
          name: 'Wydatki',
          link: '#',
          options: [
            { name: 'Generuj zaliczkę', onClick: () => this.toggleForm('GenerateAdvanceForm') },
            { name: 'Generuj płatność końcową', onClick: () => this.toggleForm('FinalPaymentForm') },
            { name: 'Generuj prowizję', onClick: () => {} },
            { name: 'Generuj rozliczenie wydatku', onClick: () => this.toggleForm('OtherExpenseForm') }
          ]
        },
        {
          name: 'Przejdź do',
          link: '#',
          options: [
            { name: 'Klient', link: `/firmy/${this.props.claim?.client?.id}` },
            { name: 'Dłużnik', link: `/firmy/${this.props.claim?.debtor?.id}` },
            { name: 'Wniosek', link: `/wnioski/${this.props.claim?.agreement?.proposal?.id}` },
            { name: 'Umowa', link: `/umowy/${this.props.claim?.agreement?.id}` },
            { name: 'Przelewy przychodzące', link: '/przelewy?mode=przychodzace' },
            { name: 'Przelewy wychodzące', link: '/przelewy?mode=wychodzace' }
          ]
        },
        {
          name: 'Sprawdzenie procedury',
          onClick: () =>
            this.edit(
              {
                corr_proc_comment: this.props.claim?.corr_proc?.corr_proc_comment,
                tasks: this.props.claim?.corr_proc?.tasks
              },
              'ClaimValidateForm'
            )
        },
        {
          name: 'Opiekunowie',
          onClick: () => {}
        },
        {
          name: 'Dokumenty wierzytelności',
          link: '#',
          options: [
            {
              name: 'Dodaj załącznik',
              onClick: () => {
                this.changeTab(3);
              }
            },
            {
              name: 'Dodaj z szablonu',
              onClick: () => {
                this.changeTab(3);
                this.toggleForm('DocumentsManageForm');
              }
            }
          ]
        },
        {
          name: 'Rozliczenia',
          onClick: () => this.changeTab(4)
        },
        {
          name: 'Transakcje',
          onClick: () => this.changeTab(5)
        }
      ])
    );
  }

  componentWillUnmount() {
    this.props.dispatch(FormActions.clearAllForms());
  }

  getMainComponent() {
    switch (this.props.tab) {
      case 1:
        return (
          <Params
            edit={(n, o) => this.edit(o, n)}
            version={this.props.version}
            saveForm={formName => this.save(formName, true)}
            remove={(uri, id) => this.remove(uri, id)}
            claim={this.props.claim}
            toggleForm={f => this.toggleForm(f)}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
          />
        );
      case 2:
        return (
          <Conditions
            recalculateAdvance={() => this.save('RecalculateAdvance')}
            forms={this.props.forms}
            errors={this.props.forms.errors}
            toggleForm={f => this.toggleForm(f)}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            edit={(modal, obj) => this.edit(obj, modal)}
            saveForm={(f, h) => this.save(f, h)}
            onCancel={f => this.toggleForm(f)}
            common={this.props.common}
            remove={(object, id) => this.remove(object, id)}
            claim={this.props.claim}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            recalculate={(f, v, c, n) => this.recalculate(f, v, c, n)}
          />
        );
      case 3:
        return (
          <Documents
            forms={this.props.forms}
            version={this.props.version}
            saveForm={formName => this.save(formName, true)}
            remove={(uri, id) => this.remove(uri, id)}
            claim={this.props.claim}
            toggleForm={f => this.toggleForm(f)}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            edit={(n, o, p) => this.edit(o, n, p)}
            common={this.props.common}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            refresh={() => this.props.dispatch(ClaimActions.fetchDetails(this.props.claim.id))}
            resetDocs={() => this.props.dispatch(ClaimActions.resetDocumentsToBuy(this.props.claim.id))}
            generate={(formName, obj, attachmentId, action, deleting, update) =>
              this.props.dispatch(ClaimActions.handleFile(formName, obj, attachmentId, action, deleting, update))
            }
            //       // this.props.dispatch(ClaimActions.handleFile(formName, obj, attachmentId, action, deleting, update))
            //       // TODO: check ClaimActions::idGeneratedDocuments
            //       return this.props.dispatch(ClaimActions.saveForm(formName, this.props.claim.id, false)).then(res => {
            //   return this.props.dispatch(ClaimActions.handleFile(formName, obj, attachmentId, action, deleting, update, res?.data?.id));
            // });
          />
        );
      case 4:
        return (
          <Settlements
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            version={this.props.version}
            claim={this.props.claim}
            saveForm={(formName, hide = true, callback = null, compensation=false) => this.save(formName, hide, callback, compensation)}
            remove={(uri, id) => this.remove(uri, id)}
            removeTransfer={(id, compensate) => this.removeTransfer(id, compensate)}
            toggleForm={f => this.toggleForm(f)}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            edit={(n, o) => this.edit(o, n)}
            forms={this.props.forms.forms}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            common={this.props.common}
            fetchBuckets={params => this.props.dispatch(ClaimActions.fetchBuckets(params))}
            buckets={this.props.buckets}
            removeLink={(subpaymentId, paymentId) => this.props.dispatch(ClaimActions.removeLink(subpaymentId, paymentId, this.props.claim?.id))}
            linkChargeWithPayment={(paymentId, chargeId, isDocument = false) =>
              this.props.dispatch(ClaimActions.linkChargeWithPayment(this.props.claim.id, paymentId, chargeId, isDocument))
            }
            removeChargeFromPayment={(paymentId, chargeId) =>
              this.props.dispatch(ClaimActions.removeChargeFromPayment(this.props.claim.id, paymentId, chargeId))
            }
            compensate={(paymentId, chargeId, c) => this.props.dispatch(ClaimActions.compensate(this.props.claim.id, paymentId, chargeId, c))}
            docs={this.props.invoice.docs}
            docsFilters={'df'}
            fetchDocs={() => this.props.dispatch(InvoiceActions.fetchDocs('df'))}
            fetchDocuments={params => this.props.dispatch(ClaimActions.fetchDocuments(params))}
            documents={this.props.documents}
            documentsFilters={'ddf'}
            clients={this.props.invoice.clients}
            clientFilters={'cf'}
            fetchClients={() => this.props.dispatch(InvoiceActions.fetchClients('cf'))}
            sendCompensation={(f, e) => this.props.dispatch(InvoiceActions.sendCompensation(f, e, 'claim', this.props.claim.id))}
            removeCompensation={id => this.props.dispatch(InvoiceActions.removeCompensation(id))}
          />
        );
      case 5:
        return (
          <Transactions
            common={this.props.common}
            agreementData={this.props.common.claimData}
            version={this.props.version}
            claim={this.props.claim}
            saveForm={formName => this.save(formName, true)}
            remove={(uri, id) => this.remove(uri, id)}
            toggleForm={f => this.toggleForm(f)}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            edit={(n, o) => this.edit(o, n)}
            forms={this.props.forms.forms}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            removeTransfer={id => this.removeTransfer(id)}
            removeLink={(subpaymentId, paymentId) => this.props.dispatch(ClaimActions.removeLink(subpaymentId, paymentId, this.props.claim?.id))}
          />
        );
      default:
        return null;
    }
  }

  recalculate = (f, amount, currency_id, name) => {
    this.props.dispatch(
      FormActions.initialize(
        'RecalculateForm',
        {
          amount,
          currency_id,
          is_nbp: true
        },
        {}
      )
    );
    this.props.dispatch(
      ClaimActions.saveForm('RecalculateForm', false, false, r => {
        this.props.dispatch(FormActions.storeInput(f, name, r.data.calc_value));
        // if (!name.includes('Vat')) {
        //   this.props.dispatch(FormActions.storeInput(f, 'exchange_rate', r.data.average_rate));
        // }
      })
    );
  };

  create(name) {
    this.props.dispatch(FormActions.initialize(name, {}, {}));
  }

  edit(object, name, background) {
    this.props.dispatch(FormActions.initialize(name, object, {}));
    if (background) return;
    this.props.dispatch(FormActions.toggleForm(name));
  }

  toggleForm(formName) {
    this.props.dispatch(FormActions.toggleForm(formName));
  }

  remove(uri, id) {
    this.props.dispatch(ClaimActions.remove(this.props.claim.id, uri, id));
  }

  removeTransfer(id, compensate) {
    this.props.dispatch(ClaimActions.removeTransfer(id, this.props.claim.id, compensate));
  }

  changeTab(tab) {
    this.props.dispatch(ClaimActions.changeTab(tab));
  }

  save(formName, hide = true, callback = null, compensation) {
    this.props.dispatch(ClaimActions.saveForm(formName, this.props.claim.id, hide, callback, compensation));
  }

  render() {
    return (
      <Container className="mt-3">
        <LoaderBackdrop />

        <MainContainer>
          <Header
            changeTab={t => this.changeTab(t)}
            claim={this.props.claim}
            saveForm={formName => this.save(formName, true)}
            remove={(uri, id) => this.remove(uri, id)}
            removeClaim={id => this.props.dispatch(ClaimActions.removeClaim(id))}
            toggleForm={f => this.toggleForm(f)}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            edit={(n, o) => this.edit(o, n)}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            common={this.props.common}
            forms={this.props.forms?.forms}
            populateForm={(name, form) => this.props.dispatch(FormActions.initialize(name, form, {}))}
            generateFinalPayment={formName => {
              this.props.dispatch(ClaimActions.generateFinalPayment(this.props.claim.id, formName));
            }}
          />

          <Tabs tab={this.props.tab} changeStep={tab => this.changeTab(tab)} steps={this.steps} />

          {this.getMainComponent()}
        </MainContainer>
        <SidebarDetails
          Notes={
            <Notes
              name={this.props.claim?.identifier}
              notes={this.props.claim?.notes?.map((n) => ({
                created_date: n.created_date,
                modified_date: n.modified_date,
                avatar: n.user_creator ? n.user_creator.avatar_url : '',
                creator: n.user_creator ? n.user_creator.full_name : 'brak',
                comment: n.comment,
                name: n.name,
                id: n.id
              })) ?? []}
              forms={this.props.forms}
              toggleForm={f => this.toggleForm(f)}
              formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
              create={(n, o) => this.edit(o, n)}
              edit={(n, o) => this.edit(o, n)}
              saveForm={formName => this.save(formName, true)}
              onCancel={(f) => this.toggleForm(f)}
              remove={(uri, id) => this.remove('notes', id)}
            />
          }
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  forms: state.Forms,
  factors: state.Agreement.factors,
  formData: state.Agreement.formData,
  claim: state.Claim.claim,
  common: state.Common,
  tab: state.Claim.currentTab,
  intrestRates: state.Offer.intrestRates,
  proposalFormData: state.Proposal.formData,
  buckets: state.Claim.buckets,
  documents: state.Claim.documents,
  invoice: state.Invoice
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Index)
);
