import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { defaultEmptyValue, isUndefined } from 'utilities/helpers';

export const getStringStyles = (string) => {
  if(!string) return null;

  switch (string.toLowerCase()) {
    case 'nie': return 'text-red-0';
    case 'ryzyko wysokie': return 'text-red-0';
    case 'wysokie ryzyko': return 'text-red-0';
    case 'bardzo wysokie': return 'text-red-0';
    case 'brak rekomendacji': return 'text-red-0';
    case 'negatywna': return 'text-red-0';
    case 'tak': return 'text-green-0';
    case 'ryzyko niskie': return 'text-green-0';
    case 'bardzo niskie': return 'text-green-0';
    case 'niskie ryzyko': return 'text-green-0';
    case 'rekomendacja': return 'text-green-0';
    case 'pozytywna warunkowa': return 'text-green-0';
    case 'pozytywna': return 'text-green-0';
    case 'brak decyzji': return 'text-accent-0';
    case 'wymaga decyzji': return 'text-accent-0';
    case 'warunkowa': return 'text-accent-0';
    case 'brak': return 'text-accent-0';
    default: return null;
  }
};

export default function DecisionString({ value }) {
  return (
    <span className={clsx(getStringStyles(value))}>{isUndefined(value) ? defaultEmptyValue : value}</span>
  );
}

DecisionString.propTypes = {
  value: PropTypes.string.isRequired
};
