import { OldCard } from 'components/Layout';
import React from 'react';
import { isAnyEmpty } from 'utilities/helpers';

export const SearchBar = ({ onSubmit, ...props }) => {
  const onKeyUp = ({ key, target: { value } }) => {
    if (key === 'Enter') {
      onSubmit && onSubmit(isAnyEmpty(value) ? null : value);
    }
  };

  return (
    <OldCard className="mt-3 pt-3 pb-3">
      <h4 className="card-title text-themecolor">
        {props.title}
        <p className="small d-inline-flex float-right text-black">Elementów: {props.records}</p>
      </h4>
      <div className="form-group mb-0">
        <input className="form-control" placeholder="Wpisz szukaną frazę" onKeyUp={onKeyUp} type="text" />
        <div className="h-15" />
      </div>
    </OldCard>
  );
};
