import FormActions from 'actions/forms';
import { ColNameBig } from 'components/Forms/Col';
import { BaseModal } from 'components/Layout/OldModal';
import Tooltip from 'components/Layout/Tooltip';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Buttons, Checkbox, Form, Input, Select, Selectable } from '../../../components/Forms';
import { RowEditable } from '../../../components/Layout/RowEditable';
import { formatNip } from '../../../utilities/helpers';
import AdditionalBids from './Debtors/AdditionalBids';
import BasicBids from './Debtors/BasicBids';
import DocumentsToBuy from './Debtors/Documents';
import MultilevelBids from './Debtors/MultilevelBids';
import Secures from './Debtors/Secures';

export default props => {
  const dispatch = useDispatch();
  const { forms, errors } = useSelector(state => state.Forms);
  const { calculation } = useSelector(state => state.Offer);

  const [multilevelBidsModal, setMultilevelBidsModal] = useState(false);

  const debtors = props.formData && props.formData.debtors ? props.formData.debtors.results : [];
  const proposal = props.proposal || {};

  useEffect(() => {
    dispatch(FormActions.initialize('DebtorsForm', {
      assignment_consent: true,
      additionalbids: calculation?.additional_bids ?? [],
      additionalbids_mode: proposal?.offer_has_additionalbids ? 1 : 0,
      basicbids_with_intrestmargins: calculation?.basic_bids ?? [],
      basicbids_with_intrestmargins_mode: proposal?.offer_has_basicbids_with_intrestmargins ? 1 : 0,
      multilevelbids: calculation?.multilevel_bids ?? [],
      multilevelbids_mode: proposal?.offer_has_multilevelbids ? 1 : 0,
      documentstobuy: calculation?.documents_to_buy ?? [],
      documentstobuy_mode: proposal?.offer_has_documents_to_buy ? 1 : 0,
      securedocuments: calculation?.documents_to_secure ?? [],
      securedocuments_mode: proposal?.offer_has_secure_documents ? 1 : 0
    }));
  }, []);

  const handleSelectDebtorCategory = item => {
    dispatch(FormActions.storeInput(props.formName, 'category_id', item.debtor_category));
  };

  const form = forms[props.formName];
  const debtorFiltersForm = 'DebtorsFiltersForm';

  const columns = [
    { name: 'Nazwa', getter: 'name', sortable: 1 },
    {
      name: 'NIP',
      getter: 'nip',
      sortable: 1,
      mutator: formatNip
    },
    { name: 'E-mail', getter: 'active_email', sortable: 1 }
  ];

  const selectOptions = offerHasData => [
    { value: 0, name: 'Brak', disabled: offerHasData },
    { value: 1, name: 'Przenieś automatycznie', disabled: !offerHasData },
    { value: 2, name: 'Zarządzaj' }
  ];

  const categories =
    props.proposalData && props.proposalData.debtor_categories ? props.proposalData.debtor_categories.map(v => ({ ...v, value: v.id })) : [];

  const bidTypes =
    props.offerData && props.offerData.additional_bid_types ? props.offerData.additional_bid_types.map(v => ({ ...v, value: v.id })) : [];

  const debtorError = get(errors[props.formName], 'debtor_id', '');

  const handleSubmit = () => {
    if(form?.additionalbids_mode !== 2) {
      dispatch(FormActions.storeInput('DebtorsForm', 'additionalbids', []));
    }
    if(form?.basicbids_with_intrestmargins_mode !== 2) {
      dispatch(FormActions.storeInput('DebtorsForm', 'basicbids_with_intrestmargins', []));
    }
    if(form?.multilevelbids_mode !== 2) {
      dispatch(FormActions.storeInput('DebtorsForm', 'multilevelbids', []));
    }
    if(form?.documentstobuy_mode !== 2) {
      dispatch(FormActions.storeInput('DebtorsForm', 'documentstobuy', []));
    }
    if(form?.securedocuments_mode !== 2) {
      dispatch(FormActions.storeInput('DebtorsForm', 'securedocuments', []));
    }
    props.submitForm();
  };

  return (
    <Form initialForm={form} visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row ">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label" style={{ color: debtorError ? 'red' : 'black' }}>
                  {form?.debtor ? <Tooltip label={form?.debtor?.identifier} tip={form?.debtor?.name} /> : 'nie wybrano'}
                </label>
                <div className="col-sm-6  d-flex align-items-center">
                  {props.details ? (
                    ''
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={() => {
                          if (props.details) {
                            return;
                          }

                          props.toggleForm('debtors');
                          props.fetch('debtors', debtorFiltersForm);
                        }}>
                        Zmień
                      </button>
                      <div className="form-control-feedback small min-h-15 ml-05" style={{ color: 'red' }}>
                        {debtorError}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Wnioskowany sublimit
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="text" name="requested_sublimit" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <ColNameBig className='text-right col-sm-6'>
                  Kategoria dłużnika
                </ColNameBig>
                <div className="col-sm-6">
                  <Select disabled={props.categoryDisabled} formName={props.formName} options={categories} name="category_id" number />
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Stawki potrącane z zaliczki
                </label>
                <div className="col-sm-6  d-flex align-items-center">
                  <Checkbox label=" " formName={props.formName} name="deducted_rates" />
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  MPP
                </label>
                <div className="col-sm-6  d-flex align-items-center">
                  <Checkbox label=" " formName={props.formName} name="mpp" />
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Zgoda na cesję
                </label>
                <div className="col-sm-6  d-flex align-items-center">
                  <Checkbox label=" " formName={props.formName} name="assignment_consent" />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row mt-2">
            <div className="col grey">
              <span>Konfiguracja oferty OFFERID, nr kalkulacji CALCULATIONID</span>
            </div>
          </div> */}
          {props.details ? null : (
            <>
              <hr />
              <div className="row mt-3">
                <div className="col-sm-6">
                  <div className="form-group row  align-items-center">
                    <ColNameBig className='text-right col-sm-6'>
                      Stawki podstawowe
                    </ColNameBig>
                    <div className="col-sm-6">
                      <Select
                        formName={props.formName}
                        options={selectOptions(proposal.offer_has_basicbids_with_intrestmargins)}
                        name="basicbids_with_intrestmargins_mode"
                        number
                      />
                    </div>
                  </div>
                </div>
              </div>

              {form?.basicbids_with_intrestmargins_mode && form?.basicbids_with_intrestmargins_mode === 2 ? (
                <div className="row">
                  <div className="col">
                    <BasicBids
                      setLastAvailableDateOfRate={props.setLastAvailableDateOfRate}
                      clear={props.clear}
                      intrestRates={props.intrestRates}
                      getIntrestRates={props.getIntrestRates}
                      form={form}
                      formName={props.formName}
                      addField={props.addField}
                      removeField={props.removeField}
                      hasRiskCategory={props.hasRiskCategory}
                      getFlatRate={props.getFlatRate}
                    />
                  </div>
                </div>
              ) : null}

              <div className="row mt-3">
                <div className="col-sm-6">
                  <div className="form-group row  align-items-center">
                    <ColNameBig className='text-right col-sm-6'>
                      Stawki złożone
                    </ColNameBig>
                    <div className="col-sm-6">
                      <Select formName={props.formName} options={selectOptions(proposal.offer_has_multilevelbids)} name="multilevelbids_mode" number />
                    </div>
                  </div>
                </div>
              </div>

              {form?.multilevelbids_mode && form?.multilevelbids_mode === 2 ? (
                <>
                  <div className="row bb bt">
                    <div className="col-sm-2 p-2 br grey">Numer stawki</div>
                    <div className="col-sm-1 p-2 br grey">Poziom</div>
                    <div className="col-sm-2 p-2 br grey">Max wysokość zaliczki</div>
                    <div className="col-sm-2
Wybierz dłużnika
p-2 br grey">Max termin płatności</div>
                    <div className="col-sm-1 p-2 br grey">Stawka flat</div>
                    <div className="col-sm-4 p-2 grey">Nazwa dokumentu</div>
                  </div>

                  {form?.multilevelbids && form?.multilevelbids.length > 0
                    ? form?.multilevelbids.map((i, k) => (
                      <RowEditable
                        className="row mb-1"
                        key={i.identifier}
                        onRemove={() => props.removeField(`multilevelbids.${k}`, props.formName)}>
                        {i.levels
                          ? i.levels.map((level, key) => (
                            <div className="col-sm-12">
                              <div className="row bt">
                                <div className="col-sm-2 p-2 br">{key === 0 ? k + 1 : ''}</div>
                                <div className="col-sm-1 p-2 br">{level.level_no}</div>
                                <div className="col-sm-2 p-2 br">{level.max_advance_payment}</div>
                                <div className="col-sm-2 p-2 br">{i.max_payment_date}</div>
                                <div className="col-sm-1 p-2 br">{i.multilevel_flat_rate}</div>
                                <div className="col-sm-4 p-2">
                                  {level.document_to_buy_types ? level.document_to_buy_types.map(x => x.name).join(', ') : '-'}
                                </div>
                              </div>
                            </div>
                          ))
                          : ''}
                      </RowEditable>
                    ))
                    : ''}
                  <div className="row">
                    <div className="col">
                      <button
                        type="button"
                        onClick={() => {
                          props.addField('multilevelbids', props.formName);
                          setMultilevelBidsModal(true);
                        }}
                        className="btn btn-sm">
                        + Dodaj
                      </button>
                    </div>
                  </div>
                </>
              ) : null}

              <div className="row mt-3">
                <div className="col-sm-6">
                  <div className="form-group row  align-items-center">
                    <ColNameBig className='text-right col-sm-6'>
                      Stawki dodatkowe
                    </ColNameBig>
                    <div className="col-sm-6">
                      <Select formName={props.formName} name="additionalbids_mode" options={selectOptions(proposal.offer_has_additionalbids)} number />
                    </div>
                  </div>
                </div>
              </div>

              {form?.additionalbids_mode && form?.additionalbids_mode === 2 ? (
                <div className="row">
                  <div className="col">
                    <AdditionalBids
                      form={form}
                      formName={props.formName}
                      addField={props.addField}
                      removeField={props.removeField}
                      bidTypes={bidTypes}
                    />
                  </div>
                </div>
              ) : null}

              <div className="row mt-3">
                <div className="col-sm-6">
                  <div className="form-group row  align-items-center">
                    <ColNameBig className='text-right col-sm-6'>
                      Dokumenty do wykupu
                    </ColNameBig>
                    <div className="col-sm-6">
                      <Select formName={props.formName} options={selectOptions(proposal.offer_has_documents_to_buy)} name="documentstobuy_mode" number />
                    </div>
                  </div>
                </div>
              </div>

              {form?.documentstobuy_mode && form?.documentstobuy_mode === 2 ? (
                <div className="row">
                  <div className="col">
                    <DocumentsToBuy
                      forms={props.forms}
                      formName={props.formName}
                      addField={props.addField}
                      removeField={props.removeField}
                      offerData={props.offerData}
                      proposalData={props.proposalData}
                    />
                  </div>
                </div>
              ) : null}

              {props.noSecures ? null : (
                <>
                  <div className="row mt-3">
                    <div className="col-sm-6">
                      <div className="form-group row  align-items-center">
                        <ColNameBig className='text-right col-sm-6'>
                          Zabezpieczenia dłużnika
                        </ColNameBig>
                        <div className="col-sm-6">
                          <Select
                            formName={props.formName}
                            options={selectOptions(proposal.offer_has_secure_documents)}
                            name="securedocuments_mode"
                            number
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {form?.securedocuments_mode && form?.securedocuments_mode === 2 ? (
                    <div className="row">
                      <div className="col">
                        <Secures
                          issuers={props.issuers}
                          fetchIssuers={props.fetchIssuers}
                          form={form}
                          formName={props.formName}
                          addField={props.addField}
                          removeField={props.removeField}
                          proposalData={props.proposalData}
                          offerData={props.offerData}
                          formVisible={props.formVisible}
                          toggleForm={props.toggleForm}
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              )}
            </>
          )}

          {props.withButtons ? (
            <Buttons
              formName={props.formName}
              onCancel={() => props.toggleForm(props.formName)}
              onSubmit={handleSubmit}
              submitButton={props.details ? 'Zapisz' : 'Dodaj dłużnika'}
              submitButtonClassNames="btn btn-primary btn-primary-blue ml-2 font-weight-light"
            />
          ) : null}

          <Selectable
            filtersForm={debtorFiltersForm}
            fetch={() => props.fetch('debtors', debtorFiltersForm)}
            visible={props.formVisible('debtors')}
            formName={props.formName}
            field="debtor"
            columns={columns}
            options={debtors}
            onSelected={handleSelectDebtorCategory}
            onClickAway={() => props.toggleForm('debtors')}
            header="Wybierz dłużnika"
          />
        </>
      ) : null}

      <BaseModal
        visible={multilevelBidsModal}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Stawki złożone: dodawanie</span>
          </div>
        }
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => setMultilevelBidsModal(false)}>
        <MultilevelBids
          form={form}
          formName={props.formName}
          addField={props.addField}
          removeField={props.removeField}
          proposalData={props.proposalData}
          offerData={props.offerData}
          formVisible={props.formVisible}
          toggleForm={props.toggleForm}
          getMultilevelFlatRate={props.getMultilevelFlatRate}
          onCancel={() => {
            const k = form?.multilevelbids.length ? form?.multilevelbids.length - 1 : 0;
            props.removeField(`multilevelbids.${k}`, props.formName);
            setMultilevelBidsModal(false);
          }}
          onSave={() => setMultilevelBidsModal(false)}
        />
      </BaseModal>
    </Form>
  );
};
