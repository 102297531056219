import CompanyActions from 'actions/companies';
import LoaderBackdrop from 'components/Layout/LoaderBackdrop';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { optionsCountries } from 'utilities/helpers';

import CommonActions from '../../../actions/common';
import FormActions from '../../../actions/forms';
import PersonActions from '../../../actions/person';
import { Container, MainContainer } from '../../../components/Layout';
import Loader from '../../../components/Loader';
import { SidebarDetails } from '../../../components/SidebarDetails';
import { getBankAccountData } from '../../../services/company';
import Constants from '../../../utilities/constants';
// import { Attachments } from "./Components/Attachments";
import { Accounts } from './Components/Accounts';
import { Addresses } from './Components/Addresses';
import { Commissions } from './Components/Commissions';
import { Contacts } from './Components/Contacts';
import { Cooperation } from './Components/Cooperation';
import { Engagement } from './Components/Engagement';
import { Header } from './Components/Header';
import { Maps } from './Components/Maps';
import { Metrics } from './Components/Metrics';
import { People } from './Components/People';
import { Roles } from './Components/Roles';
import { Scoring } from './Components/Scoring';
import { SocialMedia } from './Components/SocialMedia';
import { Notes } from 'containers/Companies/Details/Components/Notes';

class Index extends React.Component {
  constructor(props) {
    super(props);
    const { slug } = this.props.match.params;
    const id = slug ? slug.split('-').slice(-1)[0] : null;
    this.props.dispatch(CompanyActions.fetchCompanyDetails(id));

    this.props.dispatch(CommonActions.changeView(Constants.Views.Details));

    this.peopleFiltersForm = 'PeopleFilters';
  }

  componentDidMount() {
    this.props.dispatch(
      CommonActions.setContextMenu([
        { name: 'Lista firm', link: '/firmy' },
        {
          name: 'Dodaj',
          options: [
            { name: 'Dodaj adres firmy', onClick: () => this.showForm('AddressForm', { is_active: true }) },
            { name: 'Telefon firmy', onClick: () => this.showForm('ContactForm', { is_active: true }) },
            { name: 'E-mail firmy', onClick: () => this.showForm('ContactForm', { is_active: true }) },
            { name: 'Dodaj rolę biznesową', onClick: () => this.showForm('RoleForm', {}) }
          ]
        },
        { name: 'Podstawowe', onClick: () => this.editCompany() },
        { name: 'Notatka', onClick: () => this.showForm('NotesForm', {}) },
        { name: 'Powiąż osobę', onClick: () => this.showForm('PeopleForm', {}) },
        {
          name: 'Przejdź do',
          options: [
            { name: 'Oferty', link: `/oferty?client_id=${this.props.company?.id}` },
            { name: 'Wnioski', link: `/wnioski?client_id=${this.props.company?.id}` },
            { name: 'Umowy', link: `/umowy?client_id=${this.props.company?.id}` },
            { name: 'Wierzytelności', link: `/wierzytelnosci?client_id=${this.props.company?.id}` },
            { name: 'Przelewy wychodzące', link: `/przelewy?mode=wychodzace&client_id=${this.props.company?.id}` }
          ]
        },
        {
          name: 'Bankowe',
          options: [{ name: 'Dodaj rachunek bankowy', onClick: () => this.showForm('BankAccountsForm', { is_active: true }) }]
        },
        {
          name: 'Scoring',
          onClick: () =>
            this.showForm('ScoringForm', {
              ...this.props.company,
              legal_form_id: this.props.company.legal_form?.id,
              industry_id: this.props.company.industry?.id,
              representation_id: this.props.company.representation?.id,
              main_pkd_id: this.props.company.main_pkd?.id,
              activity_range_id: this.props.company.activity_range?.id,
              accounting_support_id: this.props.company.accounting_support?.id,
              accounting_type_id: this.props.company.accounting_type?.id,
              office_ownership_id: this.props.company.office_ownership?.id,
              insurance_claims: this.props.company.insurance_claims
            })
        },
        { name: 'Prowizje', onClick: () => this.showForm('CommissionForm', {}) },
        { name: 'Sprzedaż', link: '/faktury?client=' + this.props.company.name },
        { name: 'Rozrachunki', link: `/rozrachunki?client_id=${this.props.company.id}&client_name=${this.props.company.name}` }
      ])
    );
  }

  componentWillUnmount() {
    this.props.dispatch(FormActions.clearAllForms());
  }

  save = formName => this.props.dispatch(CompanyActions.saveForm(formName, this.props.company.id, true));

  showForm = (formName, object) => {
    this.props.dispatch(FormActions.initialize(formName, object, {}));
    this.props.dispatch(FormActions.toggleForm(formName));
  };

  remove = (o, object) => this.props.dispatch(CompanyActions.removeObject(o.id, this.props.company.id, object));

  toggleForm = formName => this.props.dispatch(FormActions.toggleForm(formName));

  getPeopleFilters = () => {
    const currentFilters = this.props.forms.forms[this.peopleFiltersForm] || {};

    if (!currentFilters || Object.keys(currentFilters).length <= Object.keys(CommonActions.defaultFilters).length) {
      this.props.dispatch(FormActions.initialize(this.peopleFiltersForm, CommonActions.defaultFilters, {}));
    }

    return this.props.forms.forms[this.peopleFiltersForm];
  };

  fetchPeople = () => {
    this.props.dispatch(PersonActions.fetch(this.getPeopleFilters(), true));
  };

  getBankAccountInfo = (val, form) => {
    const ban = val.replaceAll(' ', '');
    if (ban.length === 26 || ban.length === 28) {
      getBankAccountData(ban)
        .then(r => {
          this.props.dispatch(FormActions.storeInput(form, 'bank_name', r.data.short_name));
        })
        .catch(e => e);
    } else {
      this.props.dispatch(FormActions.clearField(form, 'bank_name'));
    }
  };

  editCompany() {
    this.showForm('CompanyForm', {
      ...this.props.company,
      accounting_support_id: this.props.company.accounting_support ? this.props.company.accounting_support.id : null,
      accounting_type_id: this.props.company.accounting_type ? this.props.company.accounting_type.id : null,
      activity_range_id: this.props.company.activity_range ? this.props.company.activity_range.id : null,
      industry_id: this.props.company.industry ? this.props.company.industry.id : null,
      legal_form_id: this.props.company.legal_form ? this.props.company.legal_form.id : null,
      main_pkd_id: this.props.company.main_pkd ? this.props.company.main_pkd.id : null,
      office_ownership_id: this.props.company.office_ownership ? this.props.company.office_ownership.id : null,
      representation_id: this.props.company.representation ? this.props.company.representation.id : null
    });
  }

  render() {
    if (this.props.common.loading) {
      return <Loader />;
    }

    const { common } = this.props;

    const currencies = common.currencies ? common.currencies.map(i => ({ value: i.id, name: i.name })) : [];
    const bankAccountTypes =
      common.bankAccounts && common.bankAccounts.bank_account_types
        ? common.bankAccounts.bank_account_types.map(i => ({ value: i.id, name: i.name }))
        : [];
    const bankAccountAliases =
      common.bankAccounts && common.bankAccounts.bank_aliases ? common.bankAccounts.bank_aliases.map(i => ({ value: i.id, name: i.name })) : [];

    const companyRoles =
      common.companyData && common.companyData.company_roles ? common.companyData.company_roles.map(i => ({ ...i, value: i.id })) : [];

    const addressTypes = common?.commonData?.company_address_types ?
      Object.entries(common?.commonData?.company_address_types)?.map(c => ({ value: String(c[0]), name: c[1] })) : [];
    const voivodeships = common?.commonData?.voivodeships ?
      Object.entries(common?.commonData?.voivodeships)?.map(c => ({ value: String(c[0]), name: c[1] })) : [];
    const contactTypes = this.props.common.contactTypes
      ? Object.entries(this.props.common.contactTypes).map(c => ({ value: String(c[0]), name: c[1] })) : [];


    const roles = common.businessRoles ? common.businessRoles.map(i => ({ ...i, value: i.id })) : [];

    const commission_types = common.commissions ? common.commissions.map(i => ({ ...i, value: i.id })) : [];
    const commissionCategories = this.props.company.business_company
      ? this.props.company.business_company.filter(i => i.is_used_for_commissions).map(i => ({ value: i.id, name: i.name }))
      : [];
    const countryCallingCodes = optionsCountries(this.props.common.countryCallingCodes);

    return (
      <Container className="mt-3">
        <LoaderBackdrop />

        <MainContainer>
          <Header company={this.props.company} />
          {/* <SectionNavigation /> */}
          <Metrics
            companyData={this.props.common.companyData}
            company={this.props.company}
            forms={this.props.forms.forms}
            toggleForm={this.toggleForm}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            storeInput={(n, f, v) => this.props.dispatch(FormActions.storeInput(n, f, v))}
            edit={() => this.editCompany()}
            saveForm={f => this.save(f)}
          />
          <Roles
            company={this.props.company}
            companyRoles={companyRoles}
            forms={this.props.forms.forms}
            toggleForm={this.toggleForm}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            create={f => this.showForm(f, {})}
            saveForm={f => this.props.dispatch(CompanyActions.updateBusinessRoles(f, 1))}
            onCancel={f => this.toggleForm(f)}
            edit={(f, o) => this.showForm(f, o)}
            remove={roleId => this.props.dispatch(CompanyActions.removeBusinessRole(roleId, this.props.company))}
          />
          <People
            roles={roles}
            forms={this.props.forms.forms}
            toggleForm={this.toggleForm}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            create={f => this.showForm(f, {})}
            company={this.props.company}
            people={this.props.people}
            addPerson={formName => this.props.dispatch(CompanyActions.addPerson(formName))}
            detachPerson={personId => this.props.dispatch(CompanyActions.detachPerson(personId))}
            fetchPeople={this.fetchPeople}
            filtersForm={this.peopleFiltersForm}
          />
          <Addresses
            company={this.props.company}
            forms={this.props.forms.forms}
            toggleForm={this.toggleForm}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            create={f => this.showForm(f, { is_active: true })}
            saveForm={f => this.save(f)}
            onCancel={f => this.toggleForm(f)}
            edit={(f, o) => this.showForm(f, o)}
            remove={objectId => this.props.dispatch(CompanyActions.removeObject(objectId, this.props.company.id, 'addresses'))}
            addressTypes={addressTypes}
            voivodeships={voivodeships}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            populate={(form, address) => {
              const original = this.props.forms.forms[form] || {};
              this.props.dispatch(FormActions.initialize(form, Object.assign(original, address), {}));
            }}
          />
          <Contacts
            countryCallingCodes={countryCallingCodes}
            contactTypes={contactTypes}
            company={this.props.company}
            forms={this.props.forms.forms}
            toggleForm={this.toggleForm}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            create={f => this.showForm(f, { is_active: 1 })}
            saveForm={f => this.save(f)}
            onCancel={f => this.toggleForm(f)}
            edit={(f, o) => this.showForm(f, o)}
            remove={objectId => this.props.dispatch(CompanyActions.removeObject(objectId, this.props.company.id, 'contacts'))}
          />
          <SocialMedia
            contactTypes={contactTypes}
            company={this.props.company}
            forms={this.props.forms.forms}
            toggleForm={this.toggleForm}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            create={f => this.showForm(f, {})}
            saveForm={f => this.save(f)}
            onCancel={f => this.toggleForm(f)}
            edit={(f, o) => this.showForm(f, o)}
            remove={objectId => this.props.dispatch(CompanyActions.removeObject(objectId, this.props.company.id, 'contacts'))}
          />

          <Accounts
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            company={this.props.company}
            forms={this.props.forms.forms}
            toggleForm={this.toggleForm}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            create={f => this.showForm(f, { is_active: 1 })}
            saveForm={f => this.save(f)}
            onCancel={f => this.toggleForm(f)}
            edit={(f, o) => this.showForm(f, o)}
            remove={objectId => this.props.dispatch(CompanyActions.removeObject(objectId, this.props.company.id, 'bank_accounts'))}
            currencies={currencies}
            bankAccountTypes={bankAccountTypes}
            bankAccountAliases={bankAccountAliases}
            banks={this.props.common.banks?.map(b => ({ ...b, value: b.name })) ?? []}
            checkBankAccount={this.getBankAccountInfo}
          />
          <Cooperation company={this.props.company} />
          <Engagement company={this.props.company} />
          <Scoring
            companyData={this.props.common.companyData}
            forms={this.props.forms.forms}
            toggleForm={this.toggleForm}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            company={this.props.company}
            edit={(f, o) => this.showForm(f, o)}
            saveForm={f => this.save(f)}
          />
          <Commissions
            company={this.props.company}
            forms={this.props.forms.forms}
            toggleForm={this.toggleForm}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            remove={objectId => this.props.dispatch(CompanyActions.removeObject(objectId, this.props.company.id, 'commissions'))}
            create={f => this.showForm(f, {})}
            saveForm={f => this.props.dispatch(CompanyActions.saveCommission(f))}
            onCancel={f => this.toggleForm(f)}
            edit={(f, o) => this.showForm(f, o)}
            currencies={currencies}
            commission_types={commission_types}
            categories={commissionCategories}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
          />
          <Maps addressesBlue={this.props.company?.addresses ?? []} />
        </MainContainer>
        <SidebarDetails
          Notes={
            <Notes
              name={this.props.company?.name}
              notes={
                this.props.company?.notes?.map((n) => ({
                  created_date: n.created_date,
                  modified_date: n.modified_date,
                  avatar: n.user_creator ? n.user_creator.avatar_url : '',
                  creator: n.user_creator ? n.user_creator.full_name : 'brak',
                  comment: n.comment,
                  name: n.name,
                  id: n.id
                })) ?? []
              }
              forms={this.props.forms.forms}
              toggleForm={this.toggleForm}
              formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
              create={f => this.showForm(f, {})}
              saveForm={f => this.save(f)}
              onCancel={f => this.toggleForm(f)}
              edit={(f, o) => this.showForm(f, o)}
              remove={objectId => this.props.dispatch(CompanyActions.removeObject(objectId, this.props.company.id, 'notes'))}
            />
          }
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  forms: state.Forms,
  companies: state.Company,
  company: state.Company.company,
  people: state.Person,
  common: state.Common
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Index)
);
