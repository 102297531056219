import { uniq } from 'lodash';
import { toast } from 'react-toastify';

import CommonService from '../services/common';
import TransferService from '../services/transfer';
import CommonActions from './common';
import FormActions from './forms';
import { OptionsActions } from 'ducks/options';

const fetch = filters => dispatch => {
  dispatch(OptionsActions.toggleLoader(true));
  dispatch({ type: 'CONTENT_LOADING' });

  TransferService.fetch(filters).then(response => {
    dispatch({ type: 'TRANSFERS_FETCHED', payload: response ? response.data : [] });
    dispatch({ type: 'TRANSFER_FOR_PREVIEW_SELECTED', payload: response ? response.data?.results?.[0] : {} });
    dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
    dispatch({ type: 'CONTENT_LOADED' });
    dispatch(OptionsActions.toggleLoader(false));
  });
};

const fetchDetails = (id, loader = true) => dispatch => {
  dispatch(OptionsActions.toggleLoader(loader));

  if (loader) {
    dispatch({ type: 'CONTENT_LOADING' });
    dispatch({ type: 'CONTENT_PREVIEW_LOADING' });
    dispatch(OptionsActions.toggleLoader(loader));
  }

  TransferService.fetchDetails(id).then(async response => {
    dispatch({ type: loader ? 'TRANSFER_DETAILS_FETCHED' : 'TRANSFER_DETAILS_UPDATED', payload: response.data });
    dispatch({ type: 'CONTENT_LOADED' });
    dispatch(OptionsActions.toggleLoader(false));

    if (loader) {
      dispatch({ type: 'CONTENT_PREVIEW_LOADED' });
      dispatch(OptionsActions.toggleLoader(false));
    }
  });
};

const saveForm = (form, id, hide = false) => (dispatch, getState) => {
  dispatch(FormActions.clearErrors(form));

  TransferService.saveForm(form, { ...getState().Forms.forms[form] }, id)
    .then(() => {
      dispatch(CommonActions.toggleModal());

      if (id) {
        dispatch(fetchDetails(id));
      } else {
        dispatch(fetch({ page: 1, limit: 10, status: 0 }));
      }

      document.getElementsByTagName('html')[0].style = '';

      let clearForm = true;

      if (clearForm) {
        dispatch(FormActions.clearForm(form));
      }

      if (hide) {
        dispatch(FormActions.toggleForm(form));
      }

      toast.success('Zaktualizowano dane.');
    })
    .catch(errors => {
      const e = errors && errors.response ? errors?.response?.data : null;
      if (e) {
        dispatch(FormActions.handleErrors(form, e));

        const toasts = uniq(Object.keys(e).map(key => (typeof e[key] === 'object' ? e[key][0] : e[key])));

        toasts.forEach(t => toast.error(t));
      } else {
        toast.error('Wystąpił błąd.');
      }
    });
};

export default {
  authorizeMany: ids => dispatch => {
    TransferService.authorizeMany(ids)
      .then(() => {
        dispatch(fetch({ status: 0, category: 0, limit: 10, page: 1 }));
        toast.success('Grupowa autoryzacja zakończona powodzeniem.');
      })
      .catch(() => {
        toast.error('Grupowa autoryzacja nie powiodła się.');
      });
  },
  import: formName => (dispatch, getState) => {
    const { file } = getState().Forms?.forms?.[formName];
    CommonService.uploadAttachment(file, 'payments/import/', {})
      .then(r => r.json())
      .then((r) => {
        dispatch(FormActions.toggleForm(formName));
        dispatch(fetch({ status: 7, category: 1, limit: 10, page: 1 }));
        toast.success(`Zaimportowanych przelewów: ${r.payments_imported}`);
      });
  },
  connectWithClaim: (payment, claims, form, callback) => dispatch => {
    TransferService.connectWithClaim(payment, claims)
      .then(() => {
        toast.success('Pomyślnie powiązano.');
        if (callback && typeof callback === 'function') {
          callback();
        }
        dispatch(fetch({ status: 7, category: 1, limit: 10, page: 1 }));
      })
      .catch(errors => {
        const e = errors && errors.response ? errors?.response?.data : null;

        if (e) {
          const msgAmount = e?.[0]?.amount?.[0] ?? e?.[0]?.non_field_errors?.[0] ?? 'Wystąpił błąd';

          toast.error(msgAmount);
        } else {
          toast.error('Wystąpił błąd.');
        }
      });
  },
  prepareForEdit: (object, formName) => async dispatch => {
    const agreement = object.agreement ? await TransferService.getAgreement(object.agreement.id) : null;
    const claim = object.claim ? await TransferService.getClaim(object.claim) : null;
    const transfer = { ...object, claim: claim?.data, agreement: agreement?.data };

    dispatch(FormActions.initialize(formName,          transfer, {}));
    dispatch(FormActions.toggleForm(formName));
  },
  selectForPreview: transfer => dispatch => {
    dispatch({ type: 'TRANSFER_FOR_PREVIEW_SELECTED', payload: transfer });
  },
  fetchForForm: (what, form) => (dispatch, getState) => {
    const f = getState().Forms?.forms[form] ?? {};
    TransferService.fetchForForm(what, f).then(res => {
      dispatch({ type: 'DATA_FOR_FORM_FETCHED', payload: { what, data: res.data } });
    });
  },
  fetchInvoicesForConnect: filtersForm => (dispatch, getState) => {
    const filters = getState().Forms?.forms?.[filtersForm];
    TransferService.fetchInvoicesForConnect(filters).then(r => {
      dispatch({ type: 'INVOICES_FOR_CONNECT_FETCHED', payload: r.data });
    });
  },
  fetchClaimsForConnect: filtersForm => (dispatch, getState) => {
    const filters = getState().Forms?.forms?.[filtersForm];
    TransferService.fetchClaimsForConnect(filters).then(r => {
      dispatch({ type: 'CLAIMS_FOR_CONNECT_FETCHED', payload: r.data });
    });
  },
  fetchClientsForConnect: () => dispatch => {
    TransferService.fetchClientsForConnect().then(r => {
      dispatch({ type: 'CLIENTS_FOR_CONNECT_FETCHED', payload: r.data });
    });
  },
  fetchFactorsForImport: filtersForm => (dispatch, getState) => {
    const filters = getState().Forms?.forms?.[filtersForm];
    TransferService.fetchFactorsForImport(filters).then(r => {
      dispatch({ type: 'FACTORS_FOR_IMPORT_FETCHED', payload: r.data });
    });
  },
  handleExport: (formName, shouldExport) => (dispatch, getState) => {
    const { id, export_file_url } = getState().Forms?.forms?.[formName];

    TransferService.handleExport(id, shouldExport).then(() => {
      if (shouldExport) {
        window.open(export_file_url, 'Download');
      }
      toast.success(shouldExport ? 'Wyeksportowano.' : 'Eksport anulowany.');
      dispatch(fetch({ page: 1, limit: 10, status: 1 }));
      dispatch(FormActions.toggleForm(formName));
    });
  },
  sendToBank: (payments_ids, exportForm, callback) => (dispatch, getState) => {
    const bank_id = getState().Common?.banks?.find(x => x.name === 'mBank Spółka Akcyjna')?.id;
    const factor_id = getState().Transfer.list?.find(x => x.id === payments_ids[0])?.sender?.id;
    const data = { payments_ids, bank_id, factor_id };

    TransferService.saveForm('TransfersSelectForExportForm', data, null)
      .then(response => {
        dispatch(
          FormActions.initialize(
            exportForm,
            {
              ...response.data,
              ignored_transactions: 0,
              file: { name: response.data?.export_file_url?.split('/')?.slice(-1)?.[0] }
            },
            {}
          )
        );
        callback();
      })
      .catch((e) => {
        toast.error(e.response?.data?.payments_ids ?? 'Wysyłka do banku nie powiodła się.');
      });
  },
  cancelAuthorization: payments_ids => dispatch => {
    TransferService.saveForm('CancelAuthorizationForm', { payments_ids }, null)
      .then(() => {
        toast.success('Autoryzacja została cofnięta.');
        dispatch(fetch({ page: 1, limit: 10, status: 1 }));
      })
      .catch(() => {});
  },
  fetch,
  fetchDetails,
  changeTab: tab => dispatch =>
    dispatch({
      type: 'TRANSFER_TAB_CHANGED',
      payload: tab
    }),
  create: formName => (dispatch, getState) => {
    const formData = getState().Forms.forms[formName];

    const data = {
      agreement_id: formData.agreement?.id,
      claim_id: formData?.claim?.id,
      commitment_id: formData?.commitment?.id,
      recipient_id: 2,
      mpp: formData.mpp,
      transfer_value: formData.payment?.gross_transfer_value,
      transfer_value_currency_id: 1,
      invoice_date: formData.invoice_date,
      invoice_desc: formData.invoice_desc,
      comments: formData.comments,
      references: formData.references,
      invoice_number: formData.invoice_number,
      order_type: formData.order_type,
      billing_system: formData.billing_system,
      recipient_bank_account_id: formData.payment?.recipient_bank_account?.id
    };

    TransferService.create(data)
      .then(async () => {
        dispatch(FormActions.clearForm(formName));
        dispatch(FormActions.toggleForm(formName));

        dispatch(fetch());
      })
      .catch(errors => dispatch(FormActions.handleErrors(formName, errors?.response?.data)));
  },
  saveForm,
  removeTransfer: id => dispatch => {
    TransferService.removeTransfer(id).then(() => {
      dispatch(fetch({ page: 1, limit: 10, status: 0 }));
      document.getElementsByTagName('html')[0].style = '';
      toast.success('Usunięto dane.');
    });
  },
  remove: (id, uri, objectId) => (dispatch, getState) =>
    TransferService.remove(id, uri, objectId, getState().Transfer?.version?.id).then(() => {
      dispatch(CommonActions.toggleModal());
      dispatch(fetchDetails(id));

      document.getElementsByTagName('html')[0].style = '';
      toast.success('Usunięto dane.');
    })
};
