import { isEditableProposal, STATUS_PROPOSAL } from 'containers/Proposals/Details/Index';
import React from 'react';

import { Commissions } from '../../../Offers/Details/Components/Commissions';
import { Administrator } from '../Components/Administrator';
import { Conditions } from '../Components/Conditions';
import { Readiness } from '../Components/Readiness';
import Secures from '../Components/Secures';

export default props => (
  <>
    <Conditions
      isEditable={isEditableProposal(props.proposal.status, props.version.status)}
      clear={props.clear}
      currencies={props.common.currencies}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={(f, h) => props.saveForm(f, h)}
      edit={props.edit}
      offerData={props.offerData}
    />

    <Readiness
      isEditable={isEditableProposal(props.proposal.status, props.version.status)}
      countingMethods={props.offerData.counting_method ? props.offerData.counting_method.map(i => ({ value: i.id, name: i.name })) : []}
      clear={props.clear}
      currencies={props.common.currencies}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={(f, h) => props.saveForm(f, h)}
      edit={props.edit}
      storeInput={props.storeInput}
      removeField={props.removeField}
    />

    <Administrator
      freqs={props.offerData.lump_sum_req}
      clear={props.clear}
      currencies={props.common.currencies}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
    />

    <Commissions
      isEditable={props.proposal?.status < STATUS_PROPOSAL.OCZEKUJE_NA_DECYZJE}

      objectTypes={[]}
      clearBeneficiaries={props.clearBeneficiaries}
      fetchBeneficiaries={props.fetchBeneficiaries}
      beneficiaries={props.beneficiaries}
      proposal={props.proposal}
      version={props.version}
      types={props.types}
      roles={props.roles}
      clear={props.clear}
      calculation={props.version}
      offer={props.offer}
      forms={props.forms}
      errors={props.errors}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      create={f => props.edit(f, { calculationId: 1 })}
      edit={(o, f) => props.edit(f, o)}
      saveForm={props.saveForm}
      onCancel={f => props.toggleForm(f)}
      remove={obj => props.remove('commissions', obj.id)}
    />

    <Secures
      issuers={props.issuers}
      fetchIssuers={props.fetchIssuers}
      transactions
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={(object, formName) => props.edit(formName, object)}
      common={props.common}
      storeInput={props.storeInput}
      remove={props.remove}
    />
  </>
);
