import React from 'react';

import { OldCard } from '../../../../components/Layout';

export const Header = (props) => {
  const { person } = props;

  const header = (
    <span style={{ fontSize: '32px', color: '#007bff' }} className="font-weight-light mb-0">
      {`${person.first_name} ${person.second_name} ${person.last_name}`}
    </span>
  );

  return (
    <OldCard header={header}>
      <div className="row mt-3">
        <div className="col-sm-6 col-md-3 text-right">Utworzony dnia</div>
        <div className="col-sm-6 col-md-8 ml-md-3">{person.created_date}</div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-6 col-md-3 text-right">Ostatnia aktualizacja</div>
        <div className="col-sm-6 col-md-8 ml-md-3">{person.modified_date}</div>
      </div>
    </OldCard>
  );
};
