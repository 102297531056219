import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CommonActions from '../../../actions/common';
import OtherActions from '../../../actions/other';
import {
  OldCard, Container, MainContainer
} from '../../../components/Layout';

class Controlling extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(CommonActions.setContextMenu());
    dispatch(OtherActions.fetchIndicators());
  }

  render() {
    const { indicators } = this.props;
    const fTypes = ['szt.', 'dni'];

    const header = (
      <>
        <span className="mb-0 font-weight-light" style={{ color: '#006BFF', fontSize: '24px' }}>
          Wskaźniki controllingowe
        </span>
        <span style={{ float: 'right', display: 'flex' }}>
          Wygenerowano:
          {' '}
          {indicators?.[0]?.created_date ?? '-'}
          {' '}
          |
          {' '}
          Smart Faktor
        </span>

      </>
    );

    return (
      <Container>
        <MainContainer className="col-sm-12">
          <OldCard className="mt-5" header={header}>
            <div className="row">
              <div className="col-sm-12 text-right mt-2 mb-2 align-items-center">
                <button
                  type="button"
                  className="ml-3"
                  onClick={
                    () => this.props.dispatch(OtherActions.updateIndicators())
                  }
                >
                  Generuj nowe dane
                </button>
              </div>
            </div>
            <div className="row bb bt">
              <div className="col-sm-4 grey p-2 br">
                Nazwa wskaźnika
              </div>
              <div className="col-sm-2 grey p-2 br">
                Wartość wskaźnika
              </div>
              <div className="col-sm-2 grey p-2 br">
                Jednostka miary
              </div>
              <div className="col-sm-4 grey p-2">
                Uwagi
              </div>
            </div>
            {indicators.map((indicator) => (
              <div className="row bb">
                <div className="col-sm-4 p-2 br">
                  {indicator.indicator_type?.name}
                </div>
                <div className="col-sm-2 p-2 br">
                  {fTypes.includes(indicator.indicator_type?.unit) ? Number(indicator.score).toFixed(0) : indicator.score}
                </div>
                <div className="col-sm-2 p-2 br">
                  {indicator.indicator_type?.unit}
                </div>
                <div className="col-sm-4 p-2">
                  {indicator.indicator_type?.comments}
                </div>
              </div>
            ))}
          </OldCard>
        </MainContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.Common,
  indicators: state.Other.indicators
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Controlling)
);
