import { BaseModal } from 'components/Layout/OldModal';
import React from 'react';

import DebtorsForm from '../../Forms/Debtors';
import Debtors from '../Components/Debtors';
import { isEditableProposal } from 'containers/Proposals/Details/Index';
import { DebtorsFormName } from 'containers/Proposals/Creator/Components/Debtors';

const formName = 'DebtorsForm';

export default props => (
  <div className='flex flex-col items-center'>
    <button
      type="button"
      className="btn btn-primary w-25 btn-lg my-20"
      onClick={() => props.toggleForm(DebtorsFormName)}
    >
      Dodaj dłużnika
    </button>

    <Debtors
      isEditable={isEditableProposal(props?.proposal?.status, props?.version?.status)}
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      errors={props.errors}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={(obj, form) => props.edit(form, obj)}
      storeInput={props.storeInput}
      fetch={props.fetch}
      formData={props.formData}
      addField={props.addField}
      removeField={props.removeField}
      offerData={props.offerData}
      proposalData={props.proposalData}
      common={props.common}
      getFlatRate={props.getFlatRate}
      getMultilevelFlatRate={props.getMultilevelFlatRate}
      getIntrestRates={props.getIntrestRates}
      setLastAvailableDateOfRate={props.setLastAvailableDateOfRate}
      remove={props.remove}
      fetchFromOffer={props.fetchFromOffer}
      intrestRates={props.intrestRates}
      fetchIssuers={props.fetchIssuers}
      issuers={props.issuers}
    />
    <BaseModal
      visible={props.formVisible(formName)}
      header={
        <div className="d-flex align-items-center">
          <i className="fas fa-pencil-alt mr-2" />
          <span>Dodawanie dłużnika</span>
        </div>
      }
      footer="Aby zakończyć edycję wciśnij Zapisz."
      onClickAway={() => props.toggleForm(formName)}>
      <DebtorsForm
        noSecures
        proposal={props.proposal}
        version={props.version}
        forms={props.forms}
        preloadedForm
        visibleOnMount
        clear={props.clear}
        visible={props.formVisible(formName)}
        formVisible={props.formVisible}
        toggleForm={props.toggleForm}
        fetch={props.fetch}
        formName={formName}
        submitForm={() => props.saveForm(formName)}
        onCancelButtonClick={() => props.toggleForm(formName)}
        storeInput={props.storeInput}
        withButtons
        formData={props.formData}
        offerData={props.offerData}
        proposalData={props.proposalData}
        addField={props.addField}
        removeField={props.removeField}
        getFlatRate={props.getFlatRate}
        getIntrestRates={props.getIntrestRates}
        getMultilevelFlatRate={props.getMultilevelFlatRate}
        intrestRates={props.intrestRates}
        hasRiskCategory={props.proposal.transaction_risk_category}
        setLastAvailableDateOfRate={props.setLastAvailableDateOfRate}
        fetchIssuers={props.fetchIssuers}
        issuers={props.issuers}
      />
    </BaseModal>
  </div>
);
