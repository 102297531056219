import Tooltip from 'components/Layout/Tooltip';
import React from 'react';

import { Buttons, Checkbox, Form, Input, Required, Selectable, SelectButton } from '../../../components/Forms';
import { parseMoney, formatNip, parsePhone } from '../../../utilities/helpers';

export default props => {
  const e = props.errors || {};
  const filters = {
    offers: 'ProposalOffersFilters',
    clients: 'ProposalClientsFilters',
    factors: 'ProposalFactorsFilters',
    operational_administrators: 'ProposalOAFilters',
    billing_managers: 'ProposalBMFilters',
    trade_supervisors: 'ProposalTSFilters'
  };

  const columns = {
    offers: [
      { name: 'Nr oferty', getter: 'identifier', tooltip: true, sortable: 1 },
      { name: 'Klient', getter: 'client.name', sortable: 1 },
      { name: 'Procedura', getter: 'procedure.name', sortable: 1 },
      {
        name: 'Kwota',
        getter: 'active_limit_amount',
        sortable: 1,
        mutator: parseMoney
      },
      {
        name: 'Waluta',
        getter: 'active_currency',
        sortable: 1,
        mutator: g => {
          const currency = props?.currencies.find(c => c?.id === g?.id);
          return currency?.name;
        }
      },
      { name: 'Opiekun handlowy', getter: 'trade_supervisor.full_name', sortable: 1 },
      { name: 'Data utworzenia', getter: 'created_date', sortable: 1 }
    ],
    clients: [
      { name: 'Klient', getter: 'identifier', tooltip: true, sortable: 1 },
      { name: 'Nazwa Firmy', getter: 'name', sortable: 1 },
      {
        name: 'NIP',
        getter: 'nip',
        sortable: 1,
        mutator: formatNip
      },
      { name: 'E-mail', getter: 'active_email', sortable: 1 },
      {
        name: 'Telefon',
        getter: 'active_phone',
        sortable: 1,
        mutator: parsePhone
      },
      { name: 'Rola biznesowa', getter: 'roles', sortable: 1 },
      { name: 'Ostatnia modyfikacja', getter: 'modified_date', sortable: 1 }
    ],
    default: [{ name: 'Nazwa', getter: 'name' }]
  };

  const formData = props.formData || {};

  const durations = [
    { value: 1, name: '1 miesiąc' },
    { value: 3, name: '3 miesiące' },
    { value: 6, name: '6 miesięcy' },
    { value: 12, name: '12 miesięcy' }
  ];

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row ">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Oferta
                  <Required />
                </label>
                <div className="col-sm-6  d-flex align-items-center">
                  <span className="mr-3" style={{ fontWeight: '600', color: e.offer_id ? 'red' : '#4B4E55' }}>
                    <Tooltip label={props.form?.offer?.identifier || props.form?.offer_identifier} />
                  </span>
                  {props.details ? null : (
                    <button
                      className="ml-auto"
                      type="button"
                      onClick={() => {
                        props.toggleForm('offer');
                        props.fetch('offers', filters.offers);
                      }}>
                      Wybierz
                    </button>
                  )}
                  {e.offer ? <div>{e.offer_id?.[0]}</div> : null}
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-5 label-right col-form-label">
                  Kalkulacja
                </label>
                <div className="col-sm-7  d-flex align-items-center">
                  <span className="mr-3">
                    <Tooltip label={props.form?.offer?.active_calculation?.identifier || props.form?.calculation_id} />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="row mt-1">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Wnioskowany limit
                  <Required />
                </label>
                <div className="col-sm-6">
                  <Input formName={props.formName} type="number" name="limit_amount" />
                </div>
              </div>
            </div>
            <div className="col-sm-6 ">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-5 label-right col-form-label">
                  Klient<Required />
                </label>
                <div className="col-sm-7 d-flex align-items-center">
                  <span className="mr-3" style={{ fontWeight: '600', color: e.client_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                    {props.form.client ? <Tooltip label={props?.form?.client?.identifier} id='client' tip={props?.form?.client?.name} /> : 'nie wybrano'}
                  </span>
                  {props.details ? null : (
                    <button
                      className="float-right"
                      type="button"
                      onClick={() => {
                        props.toggleForm('client');
                        props.fetch('clients', filters.client);
                      }}>
                      Wybierz
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="row ">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Produkt
                </label>
                <div className="col-sm-6  d-flex align-items-center">{props.form.offer?.product?.name || props.form?.product?.name || '-'}</div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-5 label-right col-form-label">
                  Czas trwania umowy
                </label>
                <div className="col-sm-7 d-flex align-items-center">
                  <SelectButton
                    buttonOnClick={() => props.clear(props.formName, 'duration_of_the_contract')}
                    formName={props.formName}
                    options={durations}
                    name="duration_of_the_contract"
                    value={props.form.duration_of_the_contract}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Procedura
                </label>
                <div className="col-sm-6 d-flex align-items-center">{props.form.offer?.procedure?.name || props.form?.procedure?.name || '-'}</div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-5 label-right col-form-label">
                  Regres
                </label>
                <div className="col-sm-7 d-flex align-items-center">
                  <Checkbox label=" " formName={props.formName} name="recourse" />
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="row ">
            <div className="col-sm-12">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-3 label-right col-form-label">
                  Faktor
                  <Required />
                </label>
                <div className="col-sm-4  d-flex align-items-center">
                  <span className="mr-3" style={{ fontWeight: '600', color: e.factor_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                    {props.form.factor ? props.form.factor.name : 'nie wybrano'}
                  </span>
                  <button
                    className="ml-auto mr-5"
                    type="button"
                    onClick={() => {
                      props.toggleForm('factor');
                      props.fetch('factors', filters.factors);
                    }}>
                    Wybierz
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-12">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-3 label-right col-form-label">
                  Administrator operacyjny
                </label>
                <div className="col-sm-4  d-flex align-items-center">
                  <span className="mr-3" style={{ fontWeight: '600', color: e.operational_administrator_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                    {props.form.operational_administrator ? props.form.operational_administrator.name : 'nie wybrano'}
                  </span>
                  <button
                    className="ml-auto mr-5"
                    type="button"
                    onClick={() => {
                      props.toggleForm('operational_administrator');
                      props.fetch('operational_administrators', filters.operational_administrators);
                    }}>
                    Wybierz
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-12">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-3 label-right col-form-label">
                  Opiekun handlowy
                  <Required />
                </label>
                <div className="col-sm-4  d-flex align-items-center">
                  <span className="mr-3" style={{ fontWeight: '600', color: e.trade_supervisor_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                    {props.form.trade_supervisor ? props.form.trade_supervisor.full_name || props.form.trade_supervisor.name : 'nie wybrano'}
                  </span>
                  <button
                    type="button"
                    className="ml-auto mr-5"
                    onClick={() => {
                      props.toggleForm('trade_supervisor');
                      props.fetch('trade_supervisors', filters.trade_supervisors);
                    }}>
                    Wybierz
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-12">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-3 label-right col-form-label">
                  Opiekun ds. rozliczeń
                  <Required />
                </label>
                <div className="col-sm-4  d-flex align-items-center">
                  <span className="mr-3" style={{ fontWeight: '600', color: e.billing_manager_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                    {props.form.billing_manager ? props.form.billing_manager.name ?? props.form.billing_manager.full_name : 'nie wybrano'}
                  </span>
                  <button
                    className="ml-auto mr-5"
                    type="button"
                    onClick={() => {
                      props.toggleForm('billing_manager');
                      props.fetch('billing_managers', filters.billing_managers);
                    }}>
                    Wybierz
                  </button>
                </div>
              </div>
            </div>
          </div>

          {props.withButtons ? (
            <Buttons
              formName={props.formName}
              onCancel={props.onCancel}
              onSubmit={props.submitForm}
              submitButton={props.details ? 'Zapisz' : 'Utwórz wniosek'}
              submitButtonClassNames="btn btn-primary btn-primary-blue ml-2 font-weight-light"
            />
          ) : null}

          {formData.offers && (
            <Selectable
              filtersForm={filters.offers}
              fetch={() => props.fetch('offers', filters.offers)}
              visible={props.isVisible('offer')}
              formName={props.formName}
              field="offer"
              columns={columns.offers}
              options={formData.offers}
              onClickAway={() => props.toggleForm('offer')}
              header="Wybierz ofertę"
              onSelected={() => {
                props.storeInput(props.formName, 'limit_amount', props.form.offer.active_limit_amount);
                props.storeInput(props.formName, 'operational_administrator', props.form.offer.operational_administrator);
                props.storeInput(props.formName, 'trade_supervisor', props.form.offer.trade_supervisor);
                props.storeInput(props.formName, 'billing_manager', props.form.offer.billing_manager);
                props.storeInput(props.formName, 'factor', props.form.offer.factor);
                props.storeInput(props.formName, 'client', props.form.offer.client);
                props.storeInput(props.formName, 'duration_of_the_contract', props.form.offer.active_calculation?.duration_of_the_contract);
                props.storeInput(props.formName, 'recourse', props.form.offer.active_calculation?.recourse);
              }}
            />
          )}
          <Selectable
            filtersForm={filters.factors}
            fetch={() => props.fetch('factors', filters.factors)}
            visible={props.isVisible('factor')}
            formName={props.formName}
            field="factor"
            columns={columns.default}
            options={formData.factors}
            onClickAway={() => props.toggleForm('factor')}
            header="Wybierz faktora"
          />
          <Selectable
            optionMapper={i => (i.is_company ? i : null)}
            filtersForm={filters.operational_administrators}
            fetch={() => props.fetch('operational_administrators', filters.operational_administrators)}
            visible={props.isVisible('operational_administrator')}
            formName={props.formName}
            field="operational_administrator"
            columns={columns.default}
            options={formData.operational_administrators}
            onClickAway={() => props.toggleForm('operational_administrator')}
            header="Wybierz administratora operacyjnego"
          />
          <Selectable
            filtersForm={filters.clients}
            fetch={() => props.fetch('clients', filters.clients)}
            visible={props.isVisible('client')}
            formName={props.formName}
            field="client"
            columns={columns.clients}
            options={formData.clients}
            onClickAway={() => props.toggleForm('client')}
            header="Wybierz klienta"
          />
          <Selectable
            filtersForm={filters.trade_supervisors}
            fetch={() => props.fetch('trade_supervisors', filters.trade_supervisors)}
            visible={props.isVisible('trade_supervisor')}
            formName={props.formName}
            field="trade_supervisor"
            columns={columns.default}
            options={formData.trade_supervisors}
            onClickAway={() => props.toggleForm('trade_supervisor')}
            header="Wybierz opiekuna handlowego"
          />
          <Selectable
            filtersForm={filters.billing_managers}
            fetch={() => props.fetch('billing_managers', filters.billing_managers)}
            visible={props.isVisible('billing_manager')}
            formName={props.formName}
            field="billing_manager"
            columns={columns.default}
            options={formData.billing_managers}
            onClickAway={() => props.toggleForm('billing_manager')}
            header="Wybierz opiekuna ds. rozliczeń"
          />
        </>
      ) : null}
    </Form>
  );
};
