import 'twin.macro';

import clsx from 'clsx';
import ErrorMessageTemp from 'components/ErrorMessageTemp';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import SelectCore from 'react-select';

const DropdownIndicator = props => (
  <div {...props}>
    <i className="fas fa-sort-down mr-1 ml-1 mb-10 text-grey-A"/>
  </div>
);

const joinOptionsProps = (array, name = 'name', valueName = 'id') => {
  return array?.map(option => ({ ...option, label: option[name], value: option[valueName] }));
};

export default function SelectHook({ name, control, error, options, rules, optionName, optionValue, defaultValue, ...props }) {
  return (
    <div>
      <div>
        <Controller
          className={clsx(error && 'border-error')}
          components={{ DropdownIndicator, IndicatorSeparator: () => null }}
          noOptionsMessage={() => 'Brak'}
          as={SelectCore}
          control={control}
          rules={rules}
          name={name}
          options={joinOptionsProps(options, optionName, optionValue)}
          defaultValue={defaultValue}
          {...props}
        />
      </div>
      <ErrorMessageTemp error={error} />
    </div>
  );
}

SelectHook.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
};

SelectHook.defaultProps = {
  defaultValue: null,
  placeholder: 'Wybierz'
};
