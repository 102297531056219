import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import { isEditableProposal } from 'containers/Proposals/Details/Index';
import { t } from 'lang/pl';
import React from 'react';
import { toast } from 'react-toastify';

import BooleanCheck from '../../../../components/BooleanCheck';
import ReadinessForm from '../../Forms/Readiness';

export const Readiness = (props) => {
  const formName = 'ReadinessForm';

  const proposal = props.version || {};
  const proposalToEdit = {
    ...proposal,
    currency_id: proposal.currency ? proposal.currency.id : null,
    oa_lump_sum_currency: proposal.oa_lump_sum_currency || '',
    claim_limit_currency: proposal.claim_limit_currency || ''
  };
  delete proposalToEdit.currency;

  const getCountingMethod = (c) => {
    const method = props.countingMethods ? props.countingMethods.find((m) => m.value === c.counting_method) : null;
    return method ? method.name : 'brak';
  };

  return (
    <Card
      header='Gotowość'
      isBlocked={!props.isEditable}
      onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
      onEditClick={() => props.edit(formName, proposalToEdit)}
    >
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Deklarowane wykorzystanie</div>
              <div className="col-sm-9 p-2">{proposal.declared_use ? `${proposal.declared_use}%` : 'brak'}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Prowizja za gotowość</div>
              <div className="col-sm-9">
                <div className="row">
                  <div className="col-sm-6 br p-2">
                    Od kwoty limitu
                    <span className="float-right">
                      {proposal.unused_amount_commission}
                    </span>
                  </div>
                  <div className="col-sm-6 p-2">
                    <BooleanCheck field={proposal.unused_amount_commission} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 br p-2">
                    Od niewykorzystanej kwoty limitu
                    <span className="float-right">
                      {proposal.percentage_unused_amount_commission}
                    </span>
                  </div>
                  <div className="col-sm-6 p-2">
                    <BooleanCheck field={proposal.percentage_unused_amount_commission} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 br p-2">
                    Ryczałt
                    <span className="float-right">
                      {proposal.limit_commission ?? '-'}
                    </span>
                  </div>
                  <div className="col-sm-6 p-2">
                    <BooleanCheck field={proposal.limit_commission} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Deklarowany czas rozruchu</div>
              <div className="col-sm-9 p-2">
                {proposal.start_up_contract
                  ? `${proposal.start_up_contract} ${proposal.start_up_contract === 1 ? 'dzień' : 'dni'}`
                  : 'brak'}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3 p-2 br grey">Okres rozliczeniowy</div>
              <div className="col-sm-3 p-2 br">
                {proposal.billing_period
                  ? `${proposal.billing_period} ${proposal.billing_period === 1 ? 'dzień' : 'dni'}`
                  : 'brak'}
              </div>
              <div className="col-sm-3 p-2 br grey">Metoda liczenia</div>
              <div className="col-sm-3 p-2">{getCountingMethod(proposal)}</div>
            </div>
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Gotowość</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <ReadinessForm
          countingMethods={props.countingMethods}
          offer={props.offer}
          forms={props.forms}
          populate={() => {}}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(formName)}
          storeInput={props.storeInput}
          formName={formName}
          saveForm={() => props.saveForm(formName, true)}
          onCancel={() => props.toggleForm(formName)}
          withButtons
          removeField={props.removeField}
        />
      </BaseModal>
    </Card>
  );
};
