import React from 'react';
import { connect } from 'react-redux';

const WithRole = (allowedRoles, emptyContent = false) => (WrappedComponent) => connect(
  (state) => ({
    authorization: state.Authorization
  }),
  null
)((props) => {
  if (allowedRoles.includes(props.authorization.user.role)) {
    return <WrappedComponent {...props} />;
  }

  if (emptyContent) {
    return <span />;
  }

  return <h1>Error 403.</h1>;
});

export default WithRole;
