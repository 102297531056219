const offers = service => ({
  getCompaniesOrPeopleList: async ({ role_name, page = 1, limit = 20 } = {}) =>
    service.get('offers/search_people_and_companies/', {
      params: {
        page,
        limit,
        role_name
      }
    })
});

export default offers;
