import React from 'react';

import { DatepickerButton, File, Form, Required, Selectable } from '../../../components/Forms';

const factorForm = 'ImportFactorForm';

const columns = [{ name: 'Imię i nazwisko', getter: 'name' }];

export const ImportExportForm = props => {
  const i = props.type && props.type === 'import';
  const f = props.forms?.[props.formName] ?? {};

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Plik {i ? 'importu' : 'eksportu'}
              <Required />
            </label>
            <div className="col-sm-8">
              <span className="font-weight-bold mr-3">{f.file?.name || '-'}</span>
              {i && (
                <File
                  noToast
                  method="PUT"
                  url="payments/import/"
                  storeIn="file"
                  field="tmp"
                  formName={props.formName}
                  onSuccess={r => {
                    props.storeInput(props.formName, 'transaction_count', r.transactions);
                    props.storeInput(props.formName, 'credit_transactions', r.credit_transactions);
                    props.storeInput(props.formName, 'debit_transactions', r.debit_transactions);
                    props.storeInput(props.formName, 'ignored_transactions', r.ignored_transactions);
                    props.storeInput(props.formName, 'bank_account_count', r.accounts);
                  }}
                  text="Wybierz"
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Ilość wszystkich transakcji w pliku
            </label>
            <div className="col-sm-8" style={{ fontSize: '90%' }}>
              {f.transaction_count ? (
                <>
                  <div className='font-bold'>Transakcje Debetowe: {f.debit_transactions}</div>
                  <div className='font-bold'>Transakcje Kredytowe: {f.credit_transactions}</div>
                  <div className='font-bold'>Kredytowe które zostaną pominięte: {f.ignored_transactions}</div>
                </>
              ) : '-' }
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Bank
              <Required />
            </label>
            <div className="col-sm-8">
              <span className="font-weight-bold">{f.bank?.name ?? 'mBank SA'}</span>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Ilość rachunków
            </label>
            <div className="col-sm-6">{f.bank_account_count || '-'}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Faktor
              <Required />
            </label>
            <div className="col-sm-8">
              <span className="font-weight-bold">{f.factor?.name ?? 'Smart Faktor'}</span>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Data
            </label>
            <div className="col-sm-6">{i ? <DatepickerButton formName={props.formName} name="date" buttonDisabled /> : f.created_date}</div>
          </div>
        </div>
      </div>

      {props.withButtons ? (
        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button
              type="button"
              onClick={() => (i ? props.toggleForm(props.formName) : props.handle(false))}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}>
              Anuluj
            </button>
            <button
              disabled={i && !f.file}
              type="button"
              onClick={() => props.handle(true)}
              className={`btn btn-primary ml-2  font-weight-light ${i ? 'btn-primary-blue' : 'btn-brown'}`}>
              {i ? 'Importuj do bufora' : 'Eksport do pliku'}
            </button>
          </div>
        </div>
      ) : null}

      <Selectable
        fetch={() => props.fetchFactors()}
        // fetch={props.fetch}
        visible={props.formVisible(factorForm)}
        formName={props.formName}
        field="factor"
        columns={columns}
        options={props.factors || []}
        onClickAway={() => props.toggleForm(factorForm)}
        header="Wybierz faktora"
        footer="Aby zapisać wciśnij ZATWIERDŹ"
      />
    </Form>
  );
};
