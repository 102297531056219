import React, { useState } from 'react';

export const OldPagination = ({ loading, changePage, count, currentPage, links, onList }) => {
  const [page, setPage] = useState(1);
  const [error, setError] = useState(false);

  const link = links?.next || links?.previous;
  const url = link ? new URL(link) : null;

  const limit = url?.searchParams.get('limit') ?? 10;
  const lastPage = Math.ceil(count / limit) || 1;

  const style = onList
    ? {
      padding: '20px 15px',
      backgroundColor: 'white'
    }
    : {};

  const inputOnChange = e => {
    setPage(e.target.value);
    setError(false);
  };

  const inputOnKeyDown = e => {
    if (e.key === 'Enter') {
      if (Number(page) >= 1 && Number(page) <= lastPage) {
        changePage(page);
      } else {
        setError(true);
      }
    }
  };

  const inputStyle = {
    width: '40px',
    height: '35px',
    margin: '0 5px',
    textAlign: 'center',
    border: `0.5px solid ${error ? 'red' : '#878F9A'}`,
    borderRadius: '8px'
  };

  return (
    !loading && (
      <div className={'d-flex mt-3 align-items-center justify-content-center'} style={style}>
        <i
          className="fas page-item fa-fast-backward mr-3"
          onClick={() => {
            setPage(1);
            changePage(1);
          }}
        />
        <i
          className="fas page-item fa-backward mr-3"
          onClick={
            currentPage > 1
              ? () => {
                setPage(currentPage - 1);
                changePage(currentPage - 1);
              }
              : undefined
          }
        />
        <span className="mr-3">
          Strona {onList ? <input type="number" value={page} onChange={inputOnChange} onKeyDown={inputOnKeyDown} style={inputStyle} /> : currentPage}{' '}
          z {lastPage}
        </span>
        <i
          className="fas page-item fa-forward mr-3"
          onClick={
            currentPage < lastPage
              ? () => {
                setPage(currentPage + 1);
                changePage(currentPage + 1);
              }
              : undefined
          }
        />
        <i
          className="fas page-item fa-fast-forward mr-3"
          onClick={() => {
            setPage(lastPage);
            changePage(lastPage);
          }}
        />

        {onList ? (
          <span style={{ position: 'absolute', right: 0, padding: '35px' }}>
            Widok {Number((currentPage - 1) * limit) + 1} - {currentPage * limit > count ? count : currentPage * limit} z {count}
          </span>
        ) : null}
      </div>
    )
  );
};
