import Card from 'components/Layout/Card';
import React, { useEffect } from 'react';

import { ConditionsForm } from '../../Forms/Conditions';

export const Conditions = props => {
  const formName = 'ConditionsCreatorForm';

  useEffect(() => {
    if (props.populateForm) {
      props.populateForm(formName);
    }
    // eslint-disable-next-line
  }, props);

  const calculation = props.calculation || {};
  const calculationToEdit = {
    ...calculation,
    currency_id: calculation.currency ? calculation.currency.id : null,
    oa_lump_sum_currency: calculation.oa_lump_sum_currency || '',
    claim_limit_currency: calculation.claim_limit_currency || ''
  };
  delete calculationToEdit.currency;

  const repaymentOfCapital = props.offerData && props.offerData.repayment_of_capital ? props.offerData.repayment_of_capital.map(i => ({ ...i, value: i.id })) : [];

  return (
    <Card mode='creator' header='+ Warunki dodatkowe' onSaveClick={() => props.saveForm(formName)}>
      <div/>
      <div className='pr-5 pt-3'>
        <ConditionsForm
          repaymentOfCapital={repaymentOfCapital}
          populate={() => props.populateForm(formName)}
          forms={props.forms}
          currencies={props.currencies}
          calculationId={calculation.id}
          calculation={calculation}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(formName)}
          formName={formName}
          submitForm={() => props.saveForm(formName)}
          onCancelButtonClick={() => props.toggleForm(formName)}
        />
      </div>
    </Card>
  );
};
