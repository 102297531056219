import React from 'react';

import { OldCard } from '../../../../components/Layout';
import { BaseModal } from 'components/Layout/OldModal';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { ContactsForm } from '../../Forms/Contacts';
import Card from 'components/Layout/Card';

export const SocialMedia = (props) => {
  const formName = 'SocialMediaCreatorForm';
  const modalFormName = 'SocialMediaCreatorEditForm';

  const selectedType = props.forms[formName] ? props.forms[formName].type : null;
  const modalSelectedType = props.forms[modalFormName] ? props.forms[modalFormName].type : null;

  const allowedContactTypes = ['facebook', 'twitter', 'linkedin', 'www'];

  const contacts = props.person && props.person.contacts ? props.person.contacts.filter((i) => allowedContactTypes.indexOf(i.type) !== -1) : [];

  let selectOptions = allowedContactTypes.filter((type) => contacts.filter((c) => c.type === type).length === 0);
  selectOptions = props.contactTypes ? props.contactTypes.filter((i) => selectOptions.indexOf(i.value) !== -1) : [];

  const getLink = (c) => {
    let link;

    switch (c.type) {
      case 'facebook':
        link = `https://facebook.com/${c.value}`;
        break;
      case 'twitter':
        link = `https://twitter.com/${c.value}`;
        break;
      case 'linkedin':
        link = `https://linkedin.com/in/${c.value}`;
        break;
      default:
        break;
    }

    return (
      <a role="button" href={link} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
        {c.value}
      </a>
    );
  };

  return (
    <Card mode='creator' header='+ Social media' onSaveClick={() => props.submitForm(formName)}>
      <ContactsForm
        visibleOnMount
        clear={props.clear}
        visible={props.formVisible(formName)}
        formName={formName}
        contactTypes={selectOptions}
        selectButtonDisabled={false}
        selectButtonValue={selectedType}
        typeSelected={selectedType}
      />
      <div className="collapse show " id="kontakty">
        {contacts && contacts.length ? (
          <>
            <div className="collapse show" id="kontakty">
              <div className="row mt-3">
                <div className="col-sm-12  mb-4">
                  {contacts.map((c, i) => (
                    <RowEditable
                      index={i}
                      key={`${c.type}-${c.id}`}
                      itemId={c.id}
                      onEdit={() => props.edit(c, modalFormName)}
                      onRemove={() => {
                        props.remove(c.id);
                      }}
                    >
                      <div className="col-sm-4 p-2 br text-left">
                        <span className="ml-5 grey mr-3 font-weight-light">Kanał komunikacji:</span>
                        <span className="grey font-weight-bold" style={{ color: '#4B4E55', fontSize: '14px' }}>
                          {props.contactTypes && props.contactTypes.find((a) => a.value === c.type)
                            ? props.contactTypes.find((a) => a.value === c.type).name
                            : '-'}
                        </span>
                      </div>
                      <div className="col-sm-4 p-2 grey">
                        {c.type === 'www' ? <a className='text-primary' href={c?.value}>{c?.value}</a> : getLink(c)}
                      </div>
                      {/* <div className="col-sm-4 p-2">
                                                <i
                                                    className={`far ${c.is_active ? "fa-check-square" : "fa-square"}`}
                                                    style={{ color: c.is_active ? "green" : "black" }}
                                                />
                                                <span className="ml-3 grey font-weight-light">Czy aktywny</span>
                                            </div> */}
                    </RowEditable>
                  ))}
                </div>
              </div>
              {/* <div className="row">
                            <div className="col-sm-12">
                                <div className="row bb grey">
                                    <div className="col-sm-4 p-2 br">
                                        <i className="fa fa-users mr-2" />
                                        Inne
                                    </div>
                                </div>

                                <div className="row bb data-row">
                                    <div className="col-sm-4 p-2 br">Skype</div>
                                    <div className="col-sm-8 p-2">{props.person.skype_id || "-"}</div>
                                </div>
                                <div className="row data-row">
                                    <div className="col-sm-4 p-2 br">WeChat</div>
                                    <div className="col-sm-8 p-2">{props.person.wechat_id || "-"}</div>
                                </div>
                            </div>
                        </div> */}
            </div>
          </>
        ) : null}
      </div>

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Kontakty</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}
      >
        <ContactsForm
          clear={props.clear}
          visible={props.formVisible(modalFormName)}
          formName={modalFormName}
          contactTypes={props.contactTypes}
          selectButtonDisabled
          selectButtonValue={modalSelectedType}
          typeSelected={modalSelectedType}
          showIsActiveField={modalSelectedType === 'email' || modalSelectedType === 'phone'}
          withButtons
          submitForm={() => props.submitForm(modalFormName, true)}
          onCancelButtonClick={() => props.toggleForm(modalFormName)}
        />
      </BaseModal>
    </Card>
  );
};
