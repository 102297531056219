import React from 'react';

import { BaseModal } from 'components/Layout/OldModal';
import { RowEditable } from '../../../../../components/Layout/RowEditable';
import { parseMoney } from '../../../../../utilities/helpers';
import LiabilitiesForm from '../../../Forms/Liabilities/Leasing';

export default (props) => {
  const modalFormName = 'LeasingLiabilitiesForm';

  const rows = props.version && props.version.leasing_liabilities ? props.version.leasing_liabilities : [];

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12 p-2 text-themecolor">
              Zobowiązania leasingowe
              <span className="float-right" style={{ cursor: 'pointer' }}>
                <i className="fa fa-plus ml-2" onClick={() => props.toggleForm(modalFormName)} />
              </span>
            </div>
          </div>
          <div className="row bt bb">
            <div className="col-sm-3 p-2 br grey">Leasingodawca</div>
            <div className="col-sm-3 p-2 br grey">Przedmiot leasingu</div>
            <div className="col-sm-2 p-2 br grey">Aktualne zadłużenie</div>
            <div className="col-sm-2 p-2 br grey">Data ostatniej raty</div>
            <div className="col-sm-2 p-2 grey">Kwota wykupu</div>
          </div>
          {rows.length ? rows.map((row) => (
            <RowEditable
              key={row.identifier}
              onEdit={() => {
                const editableRow = {
                  lessor_id: row.lessor ? row.lessor.id : null,
                  id: row.id,
                  leasing_subject_id: row.leasing_subject ? row.leasing_subject.id : null,
                  current_debt: row.current_debt,
                  last_installment_date: row.last_installment_date,
                  redemption_amount: row.redemption_amount
                };
                delete editableRow.lessor;
                delete editableRow.leasing_subject;
                props.edit(editableRow, modalFormName);
              }}
              onRemove={() => {
                props.remove('leasing_liabilities', row.id);
              }}
            >
              <div className="col-sm-3 p-2 br ">{row.lessor ? row.lessor.name : '-'}</div>
              <div className="col-sm-3 p-2 br ">{row.leasing_subject ? row.leasing_subject.name : '-'}</div>
              <div className="col-sm-2 p-2 br ">{parseMoney(row.current_debt)}</div>
              <div className="col-sm-2 p-2 br ">{row.last_installment_date}</div>
              <div className="col-sm-2 p-2 ">{parseMoney(row.redemption_amount)}</div>
            </RowEditable>
          )) : <div className="row bb"><div className="col p-2"><span>Brak danych.</span></div></div>}
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Zobowiązania leasingowe</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}
      >
        <LiabilitiesForm
          withButtons
          populate={() => props.populateForm(modalFormName)}
          forms={props.forms}
          proposalData={props.proposalData}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(modalFormName)}
          formName={modalFormName}
          submitForm={() => props.saveForm(modalFormName)}
          onCancelButtonClick={() => props.toggleForm(modalFormName)}
        />
      </BaseModal>
    </>
  );
};
