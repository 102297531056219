import React from 'react';
import { useSelector } from 'react-redux';
import 'twin.macro';

import { Buttons, Form, Input, Required, Select, Selectable, SelectButton } from '../../../components/Forms';
import { parsePhone } from '../../../utilities/helpers';

export default props => {
  const { CreateAgreementForm: form } = useSelector(state => state.Forms?.forms);
  const e = props.errors || {};
  const filters = {
    proposals: 'AgreementProposalsFilters',
    clients: 'AgreementClientsFilters',
    factors: 'AgreementFactorsFilters',
    operational_administrators: 'AgreementOAFilters',
    billing_managers: 'AgreementBMFilters',
    trade_supervisors: 'AgreementTSFilters'
  };

  const columns = {
    proposals: [
      { name: 'Klient', getter: 'client.name', sortable: 1 },
      { name: 'Nr wniosku', getter: 'identifier', sortable: 1 },
      { name: 'Procedura', getter: 'procedure.name', sortable: 1 },
      { name: 'Data utworzenia', getter: 'created_date', sortable: 1 }
    ],
    representation: [
      { name: 'Imię i nazwisko', getter: 'person.full_name', sortable: 1 },
      { name: 'E-mail', getter: 'person.active_email', sortable: 1 },
      {
        name: 'Telefon',
        getter: 'person.active_phone',
        sortable: 1,
        mutator: parsePhone
      },
      {
        name: 'Rola biznesowa',
        getter: 'roles',
        sortable: 1,
        mutator: g => g.map(i => i.name).join()
      },
      { name: 'Ostatnia modyfikacja', getter: 'modified_date', sortable: 1 }
    ],
    default: [{ name: 'Nazwa', getter: 'name' }]
  };

  const formData = props.formData || {};

  const representationFormFor = field => (
    <Form formName={props.formName} asDiv>
      <div className="row align-items-center">

        <label htmlFor="example-text-input " className="col-sm-2 col-form-label label-right">
              Sposób reprezentacji
        </label>
        <div className="col-sm-3">
          <Select formName={props.formName} options={props.representations?.map(r => ({ ...r, value: r.id })) ?? []} name={field} number />
        </div>
      </div>

    </Form>
  );

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row ">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Wniosek
                  <Required />
                </label>
                <div className="col-sm-6  d-flex align-items-center">
                  <span className="mr-3" style={{ fontWeight: '600', color: e.proposal_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                    {form.proposal?.identifier ?? 'nie wybrano'}
                  </span>
                  <button
                    className="ml-auto"
                    type="button"
                    onClick={() => {
                      props.toggleForm('proposals');
                      props.fetch('proposals', filters.proposals);
                    }}>
                    Wybierz
                  </button>
                </div>
                {e.factor_id ? (
                  <div className="col-sm-6 offset-sm-6">
                    <div className="form-control-feedback small mt-1" style={{ color: 'red' }}>
                      {e.proposal_id[0]}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Kalkulacja
                </label>
                <div className="col-sm-6  d-flex align-items-center">
                  <span className="mr-3">{form.proposal?.calculation_id}</span>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="row mt-2 mb-2">
            <div className="col-sm-6">
              <div className="form-group row align-items-center">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Kwota limitu
                  <Required />
                </label>
                <div className="col-sm-4">
                  {/* 200 000 PLN */}
                  <Input formName={props.formName} type="text" name="limit_amount" />
                </div>
                <div className="col-sm-2">
                  <Select
                    tw='w-80'
                    isClearable={false}
                    formName={props.formName}
                    options={props.currencies.map(x => ({ ...x, value: x.id }))}
                    placeholder='waluta'
                    name="currency_id"
                    isDisabled
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6 ">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Produkt / procedura
                  <Required />
                </label>
                <div className="col-sm-6  d-flex align-items-center">
                  {form.product ? form.product.name : '-'}
                  {' / '}
                  {form.procedure ? form.procedure.name : '-'}
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="row mt-3 mb-3">
            <div className="col-sm-12 grey">
              <span>Wybierz reprezentację do umowy</span>
            </div>
          </div>

          <hr />

          <div className="row ">
            <div className="col-sm-12">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-3 label-right col-form-label">
                  Klient
                </label>
                <div className="col-sm-9  d-flex align-items-center">
                  <span className="mr-3" style={{ fontWeight: '600', color: e.client_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                    {form.client ? form.client.name : 'nie wybrano'}
                  </span>
                  <span className="mr-3">({props.representations?.find(x => x.id === form?.client_representation_id)?.name ?? '-'})</span>
                  <span className="mr-3">{form.client_people ? form.client_people.map(c => c.person?.full_name).join(', ') : ''}</span>
                  <button
                    className="ml-3"
                    type="button"
                    onClick={() => {
                      props.toggleForm('clientPeople');
                    }}>
                    Wybierz
                  </button>
                </div>
                {e.client_id ? (
                  <div className="col-sm-6 offset-sm-3 d-block">
                    {' '}
                    <div className="form-control-feedback small mt-1" style={{ color: 'red' }}>
                      {e.client_id[0]}
                    </div>
                  </div>
                ) : null}
                {e.client_representation_id ? (
                  <div className="col-sm-6 offset-sm-3 d-block">
                    {' '}
                    <div className="form-control-feedback small mt-1" style={{ color: 'red' }}>
                      Reprezentacja klienta jest polem wymaganym.
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-12">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-3 label-right col-form-label">
                  Faktor
                </label>
                <div className="col-sm-9  d-flex align-items-center">
                  <span className="mr-3" style={{ fontWeight: '600', color: e.factor_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                    {form.factor?.name ?? 'nie wybrano'}
                  </span>
                  <span className="mr-3">({props.representations?.find(x => x.id === form?.factor_representation_id)?.name ?? '-'})</span>
                  <span className="mr-3">{form.factor_people ? form.factor_people.map(c => c.person?.full_name).join(', ') : ''}</span>
                  <button
                    className="ml-3"
                    type="button"
                    onClick={() => {
                      props.toggleForm('factor');
                    }}>
                    Wybierz
                  </button>
                </div>
                {e.factor_id ? (
                  <div className="col-sm-6 offset-sm-3 d-block">
                    {' '}
                    <div className="form-control-feedback small mt-1" style={{ color: 'red' }}>
                      {e.factor_id[0]}
                    </div>
                  </div>
                ) : null}
                {e.factor_representation_id ? (
                  <div className="col-sm-6 offset-sm-3 d-block">
                    {' '}
                    <div className="form-control-feedback small mt-1" style={{ color: 'red' }}>
                      Reprezentacja faktora jest polem wymaganym.
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-12">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-3 label-right col-form-label">
                  Administrator operacyjny
                </label>
                <div className="col-sm-9 d-flex align-items-center">
                  <span className="mr-3" style={{ fontWeight: '600', color: e.operational_administrator_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                    {form.operational_administrator?.name ?? 'nie wybrano'}
                  </span>
                  <span className="mr-3">({props.representations?.find(x => x.id === form?.oa_representation_id)?.name ?? '-'})</span>
                  <span className="mr-3">{form.oa_people ? form.oa_people.map(c => c.person?.full_name).join(', ') : ''}</span>
                  <button
                    className="ml-3"
                    type="button"
                    onClick={() => {
                      props.toggleForm('operational_administrator');
                    }}>
                    Wybierz
                  </button>
                </div>
                {e.operational_administrator_id ? (
                  <div className="col-sm-6 offset-sm-3 d-block">
                    {' '}
                    <div className="form-control-feedback small mt-1" style={{ color: 'red' }}>
                      {e.operational_administrator_id[0]}
                    </div>
                  </div>
                ) : null}
                {e.oa_representation_id ? (
                  <div className="col-sm-6 offset-sm-3 d-block">
                    {' '}
                    <div className="form-control-feedback small mt-1" style={{ color: 'red' }}>
                      Reprezentacja operatora administracyjnego jest polem wymaganym.
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <hr />

          <div className="row ">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Opiekun handlowy
                  <Required />
                </label>
                <div className="col-sm-6  d-flex align-items-center">
                  <span className="mr-3" style={{ fontWeight: '600', color: e.trade_supervisor_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                    {form.trade_supervisor ? form.trade_supervisor.full_name || form.trade_supervisor.name : 'nie wybrano'}
                  </span>
                  <button
                    type="button"
                    className="ml-auto"
                    onClick={() => {
                      props.toggleForm('trade_supervisor');
                      props.fetch('trade_supervisors', filters.trade_supervisors);
                    }}>
                    Wybierz
                  </button>
                </div>
                {e.trade_supervisor_id ? (
                  <div className="col-sm-6 offset-sm-6 d-block">
                    <div className="form-control-feedback small mt-1" style={{ color: 'red' }}>
                      {e.trade_supervisor_id[0]}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Opiekun ds. rozliczeń
                  <Required />
                </label>
                <div className="col-sm-6  d-flex align-items-center">
                  <span className="mr-3" style={{ fontWeight: '600', color: e.billing_manager_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                    {form.billing_manager ? form.billing_manager.full_name || form.billing_manager.name : 'nie wybrano'}
                  </span>
                  <button
                    className="ml-auto"
                    type="button"
                    onClick={() => {
                      props.toggleForm('billing_manager');
                      props.fetch('billing_managers', filters.billing_managers);
                    }}>
                    Wybierz
                  </button>
                </div>
                {e.billing_manager_id ? (
                  <div className="col-sm-6 offset-sm-6 d-block">
                    <div className="form-control-feedback small mt-1" style={{ color: 'red' }}>
                      {e.billing_manager_id[0]}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-6">
              <div className="form-group row">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                  Opiekun ds. Windykacji
                </label>
                <div className="col-sm-6  d-flex align-items-center">
                  <span className="mr-3" style={{ fontWeight: '600', color: e.vindicator_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                    {form.vindicator ? form.vindicator.name : 'nie wybrano'}
                  </span>
                  <button
                    className="ml-auto"
                    type="button"
                    onClick={() => {
                      props.toggleForm('vindicators');
                      props.fetch('vindicators', filters.vindicators);
                    }}>
                    Wybierz
                  </button>
                  {e.vindicator_id ? (
                    <div className="col-sm-6 offset-sm-6">
                      {' '}
                      <div className="form-control-feedback small mt-1" style={{ color: 'red' }}>
                        {e.vindicator_id[0]}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {props.withButtons ? (
            <Buttons
              formName={props.formName}
              onCancel={props.onCancel}
              onSubmit={props.submitForm}
              submitButton="Zapisz"
              submitButtonClassNames="btn btn-primary btn-primary-blue ml-2 font-weight-light"
            />
          ) : null}

          <Selectable
            filtersForm={filters.proposals}
            fetch={() => props.fetch('proposals', filters.proposals)}
            visible={props.isVisible('proposals')}
            formName={props.formName}
            field="proposal"
            columns={columns.proposals}
            options={formData.proposals}
            onClickAway={() => props.toggleForm('proposals')}
            header="Wybierz wniosek"
            onSelected={() => {
              props.storeInput(props.formName, 'limit_amount', form.proposal.limit_amount);
              props.storeInput(props.formName, 'currency_id', form.proposal.currency?.id);
              props.storeInput(props.formName, 'operational_administrator', form.proposal.operational_administrator);
              props.storeInput(props.formName, 'trade_supervisor', form.proposal.trade_supervisor);
              props.storeInput(props.formName, 'billing_manager', form.proposal.billing_manager);
              props.storeInput(props.formName, 'factor', form.proposal.factor);
              props.storeInput(props.formName, 'client', form.proposal.client);
              props.storeInput(props.formName, 'client_representation_id', form.proposal.client.representation.id);
              props.storeInput(props.formName, 'client_people', form.proposal.client.people);
              props.storeInput(props.formName, 'factor_representation_id', form.proposal.factor.representation.id);
              props.storeInput(props.formName, 'factor_people', form.proposal.factor.people);
              props.storeInput(props.formName, 'procedure', form.proposal.procedure);
              props.storeInput(props.formName, 'product', form.proposal.product);
            }}
          />
          <Selectable
            fetch={() => {}}
            visible={props.isVisible('factor')}
            formName={props.formName}
            field="factor_people"
            columns={columns.representation}
            options={form.factor?.people}
            onClickAway={() => props.toggleForm('factor')}
            header="Wybierz reprezentantów faktora"
            multiple
            title={representationFormFor('factor_representation_id')}
          />
          <Selectable
            fetch={() => {}}
            visible={props.isVisible('operational_administrator')}
            formName={props.formName}
            field="oa_people"
            columns={columns.representation}
            options={form.operational_administrator?.people}
            onClickAway={() => props.toggleForm('operational_administrator')}
            header="Wybierz reprezentantów administratora operacyjnego"
            multiple
            title={representationFormFor('oa_representation_id')}
          />
          <Selectable
            fetch={() => {}}
            visible={props.isVisible('clientPeople')}
            formName={props.formName}
            field="client_people"
            columns={columns.representation}
            options={form.client?.people}
            onClickAway={() => props.toggleForm('clientPeople')}
            header="Wybierz reprezentantów klienta"
            multiple
            title={representationFormFor('client_representation_id')}
          />
          <Selectable
            filtersForm={filters.trade_supervisors}
            fetch={() => props.fetch('trade_supervisors', filters.trade_supervisors)}
            visible={props.isVisible('trade_supervisor')}
            formName={props.formName}
            field="trade_supervisor"
            columns={columns.default}
            options={formData.trade_supervisors}
            onClickAway={() => props.toggleForm('trade_supervisor')}
            header="Wybierz opiekuna handlowego"
          />
          <Selectable
            filtersForm={filters.billing_managers}
            fetch={() => props.fetch('billing_managers', filters.billing_managers)}
            visible={props.isVisible('billing_manager')}
            formName={props.formName}
            field="billing_manager"
            columns={columns.default}
            options={formData.billing_managers}
            onClickAway={() => props.toggleForm('billing_manager')}
            header="Wybierz opiekuna ds. rozliczeń"
          />
          <Selectable
            filtersForm={filters.vindicators}
            fetch={() => props.fetch('vindicators', filters.vindicators)}
            visible={props.isVisible('vindicators')}
            formName={props.formName}
            field="vindicator"
            columns={columns.default}
            options={formData.vindicators}
            onClickAway={() => props.toggleForm('vindicators')}
            header="Wybierz windykatora"
          />
        </>
      ) : null}
    </Form>
  );
};
