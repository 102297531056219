import ErrorMessageTemp from 'components/ErrorMessageTemp';
import pl from 'date-fns/locale/pl';
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import tw, { styled } from 'twin.macro';

const StyledDatePicker = styled(DatePicker)`
  ${tw`w-full h-38`};
`;

export default function Datepicker({ name, register, setValue, clearErrors, error, ...props }) {
  const [date, setDate] = useState(null);

  const handleOnChange = name => newDate => {
    setValue(name, moment(newDate).format('DD.MM.YYYY'));
    setDate(newDate);
    clearErrors(name);
  };

  return (
    <div className="form-group mb-0">
      <StyledDatePicker
        locale={pl}
        showMonthDropdown
        showYearDropdown
        selected={date}
        dateFormat="dd.MM.yyyy"
        className={`form-control rounded ${error ? 'form-control-danger' : ''}`}
        onChange={handleOnChange(name)}
        style={{ zIndex: '99999' }}
        popperModifiers={{
          offset: {
            enabled: true,
            offset: '-30px, 5px'
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: true,
            boundariesElement: 'viewport'
          }
        }}
        {...props}
      />
      <input className='hidden' name={name} ref={register} value={date} />
      <ErrorMessageTemp error={error} />
    </div>
  );
}
