import React from 'react';
import SelectCore from 'react-select';

const DropdownIndicator = props => (
  <div {...props}>
    <i className="fas fa-sort-down mr-1 ml-1 mb-10 text-grey-A"/>
  </div>
);

export default function Select({ options, withReset, ...props }) {
  return (
    <SelectCore
      options={options}
      components={{ DropdownIndicator, IndicatorSeparator: () => null }}
      {...props}
    />
  );
}

Select.defaultProps = {
  placeholder: 'Wybierz',
  noOptionsMessage: () => 'Brak'
};
