import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parseBan } from '../../../../utilities/helpers';
import { BankAccountsForm } from '../../Forms/BankAccounts';
import { TextLink } from 'containers/Companies/Details/Components/Notes';

export const Accounts = (props) => {
  const accounts = props.company && props.company.bank_accounts ? props.company.bank_accounts : [];

  const formName = 'BankAccountsCreatorForm';
  const modalFormName = 'BankAccountsCreatorEditForm';

  return (
    <Card mode='creator' header='+ Rachunki bankowe' onSaveClick={() => props.submitForm(formName)}>
      <div className="row mb-4">
        <div className="col-sm-12">
          <BankAccountsForm
            clear={props.clear}
            visibleOnMount
            visible={props.formVisible(formName)}
            formName={formName}
            types={props.bankAccountTypes}
            currencies={props.currencies}
            f={props.forms[formName]}
            submitForm={() => props.saveForm(formName)}
            onCancelButtonClick={() => props.toggleForm(formName)}
            selectButtonOnClick={props.clear}
            bankAccountAliases={props.bankAccountAliases}
            banks={props.banks}
            checkBankAccount={(v) => props.checkBankAccount(v, formName)}
          />
        </div>
      </div>
      {accounts.map((account, k) => (
        <div key={account.id} className={`row ${k === 0 ? 'bt' : ''}`}>
          <div className="col-sm-12">
            <RowEditable
              itemId={account.id}
              onEdit={() => {
                const a = {
                  ...account,
                  currency_id: account.currency.id,
                  bank_account_type_id: account.bank_account_type.id,
                  bank_alias_id: account.bank_alias.id
                };
                delete a.user_creator;
                delete a.user_deactivator;

                props.edit(a, modalFormName);
              }}
              onRemove={() => props.remove(account.id)}
            >
              <div className="col-sm-3 p-2 br">
                <a
                  role="button"
                  style={{ color: '#878F9A' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#rachunek${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <i className="fa fa-sort-down mr-2" />
                  {account?.bank_alias?.name}
                </a>
              </div>
              <div className="col-sm-3 p-2 br">
                <a
                  role="button"
                  style={{ color: 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#rachunek${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  {parseBan(account)}
                </a>
              </div>
              <div className="col-sm-1 br p-2">
                <a
                  role="button"
                  style={{ color: '#878F9A', marginLeft: '10px' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#rachunek${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  {account.currency?.name}
                </a>
              </div>
              <div className="col-sm-1 br p-2">
                <a
                  role="button"
                  style={{ color: '#878F9A', marginLeft: '10px' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#rachunek${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  {account.bank_account_type?.name}
                </a>
              </div>
              <div className="col-sm-2 p-2">
                <BooleanCheck className="ml-3" field={account.is_active} />
              </div>
            </RowEditable>
          </div>

          <div className="col-sm-12">
            <div className="collapse" id={`rachunek${k}`}>
              <div className="row bb">
                <div className="col-sm-2 grey p-2 br">Nazwa banku</div>
                <div className="col-sm-10 p-2">{account.bank_name}</div>
              </div>
              <div className="row bb">
                <div className="col-sm-2 grey p-2 br">Komentarz</div>
                <div className="col-sm-10 p-2">
                  <TextLink>{account.comment}</TextLink>
                </div>
              </div>
              <div className="row bb">
                <div className="col-sm-2 p-2 grey br">Data dodania</div>
                <div className="col-sm-3 p-2 grey br">Dodał</div>
                <div className="col-sm-3 p-2 grey br">Deaktywował</div>
                <div className="col-sm-2 p-2 grey br">Ważny do</div>
                <div className="col-sm-2 p-2 grey">SWIFT/BIC</div>
              </div>
              <div className="row bb">
                <div className="col-sm-2 p-2 br">{account.user_creator ? account.user_creator.full_name : '-'}</div>
                <div className="col-sm-3 p-2 br">{account.user_creator ? account.user_creator.full_name : '-'}</div>
                <div className="col-sm-3 p-2 br">{account.user_deactivator ? account.user_deactivator.full_name : '-'}</div>
                <div className="col-sm-2 p-2 br">{account.valid_until_date || '-'}</div>
                <div className="col-sm-2 p-2 ">{account.swift_or_bic || '-'}</div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <BaseModal
        size="large"
        visible={props.formVisible(modalFormName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Rachunki bankowe</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}
      >
        <BankAccountsForm
          clear={props.clear}
          visibleOnMount={false}
          visible={props.formVisible(modalFormName)}
          formName={modalFormName}
          types={props.bankAccountTypes}
          currencies={props.currencies}
          withButtons
          f={props.forms[modalFormName]}
          submitForm={() => props.saveForm(modalFormName)}
          onCancelButtonClick={() => props.toggleForm(modalFormName)}
          selectButtonOnClick={props.clear}
          bankAccountAliases={props.bankAccountAliases}
          banks={props.banks}
          checkBankAccount={(v) => props.checkBankAccount(v, modalFormName)}
        />
      </BaseModal>
    </Card>
  );
};
