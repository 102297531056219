import Card from 'components/Layout/Card';
import React, { useEffect } from 'react';
import { formatValueToEurope } from 'utilities/helpers';

import DebtorsRiskForm from '../../Forms/DebtorsRisk';

export default props => {
  const formName = 'DebtorsRiskForm';

  const risks = props.proposal && props.proposal.risks ? props.proposal.risks : [];

  useEffect(() => {
    if (props.populateForm) {
      risks.forEach(risk =>
        props.populateForm(`${formName}.${risk.debtor.id}`, {
          ...risk,
          debtor_id: risk.debtor?.company_id,
          debtor: risk.debtor,
          securedocuments: risk.securedocuments.map(securedocument => ({
            ...securedocument,
            dts_type_id: securedocument.dts_type?.id,
            dts_form_id: securedocument.dts_form?.id,
            issuer_id: securedocument.issuer?.id ?? 1
          }))
        })
      );
    }
    // eslint-disable-next-line
  }, props);

  return (
    <>
      {risks.map(d => (
        <div key={d.id}>
          <Card mode='creator' header={`Ryzyko dłużnika: ${d.debtor?.name} ${formatValueToEurope(d.debtor?.requested_sublimit, '', { withoutCents: true })}`} onSaveClick={() => props.saveForm(`${formName}.${d.debtor.id}`)}>
            <DebtorsRiskForm
              proposal={props.proposal}
              forms={props.forms}
              preloadedForm
              visibleOnMount
              clear={props.clear}
              visible
              formVisible={props.formVisible}
              toggleForm={props.toggleForm}
              fetch={props.fetch}
              formName={`${formName}.${d.debtor.id}`}
              onCancelButtonClick={() => props.toggleForm(formName)}
              storeInput={props.storeInput}
              formData={props.formData}
              common={props.common}
              offerData={props.offerData}
              proposalData={props.proposalData}
              addField={props.addField}
              removeField={props.removeField}
            />
          </Card>
        </div>
      ))}
    </>
  );
};
