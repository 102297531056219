const initialState = {
  list: [],
  links: {},
  count: 0,
  currentPage: 1,
  transfer: {},
  currentTab: 1,
  dataForForm: {},
  invoices: {},
  claims: {},
  factors: [],
  clients: []
};

export default function Transfers(state = initialState, action) {
  switch (action.type) {
    case 'TRANSFERS_FETCHED':
      return {
        ...state,
        list: action.payload.results,
        links: action.payload.links,
        currentPage: action.payload.current_page,
        count: action.payload.count
      };
    case 'TRANSFER_DETAILS_FETCHED':
    case 'TRANSFER_DETAILS_UPDATED':
    case 'TRANSFER_FOR_PREVIEW_SELECTED':
      return { ...state, transfer: action.payload };
    case 'TRANSFER_TAB_CHANGED':
      return { ...state, currentTab: action.payload };
    case 'DATA_FOR_FORM_FETCHED':
      return {
        ...state,
        dataForForm: { ...state.dataForForm, [action.payload.what]: action.payload.data }
      };
    case 'INVOICES_FOR_CONNECT_FETCHED':
      return { ...state, invoices: action.payload };
    case 'CLAIMS_FOR_CONNECT_FETCHED':
      return { ...state, claims: action.payload };
    case 'FACTORS_FOR_IMPORT_FETCHED':
      return { ...state, factors: action.payload };
    case 'CLIENTS_FOR_CONNECT_FETCHED':
      return { ...state, clients: action.payload };
    default:
      return state;
  }
}
