import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React from 'react';

import { parseMoney } from '../../../../utilities/helpers';
import { AdministratorForm } from '../../Forms/Administrator';

export const Administrator = (props) => {
  const formName = 'AdministratorForm';

  const calculation = props.calculation || {};
  const calculationToEdit = {
    ...calculation,
    currency_id: calculation.currency ? calculation.currency.id : null,
    oa_lump_sum_currency: calculation.oa_lump_sum_currency || '',
    claim_limit_currency: calculation.claim_limit_currency || ''
  };
  delete calculationToEdit.currency;

  const freq = props.freqs && props.freqs.find((f) => f.id === calculation.oa_lump_sum_freq)
    ? props.freqs.find((f) => f.id === calculation.oa_lump_sum_freq).name
    : 'brak';

  return (
    <Card header='Administrator' onEditClick={() => props.edit(formName, calculationToEdit)}>
      <div className="collapse show" id="administrator">
        <div className="row">
          <div className="col-sm-12">
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Kwota ryczałtu netto</div>
              <div className="col-sm-9 p-2">
                {parseMoney(calculation.oa_lump_sum_amount)}
                {' '}
                {calculation.oa_lump_sum_amount && calculation.currency ? calculation.currency.name : ''}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3 p-2 br grey">Okres rozliczeniowy ryczałtu</div>
              <div className="col-sm-9 p-2">
                {freq}
                {' '}
              </div>
            </div>
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Administrator</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <AdministratorForm
          freqs={props.freqs}
          currencies={props.currencies}
          calculationId={calculation.id}
          calculation={calculation}
          preloadedForm
          visibleOnMount={false}
          clear={props.clear}
          visible={props.formVisible(formName)}
          formName={formName}
          submitForm={() => props.saveForm(formName)}
          onCancelButtonClick={() => props.toggleForm(formName)}
          withButtons
        />
      </BaseModal>
    </Card>
  );
};
