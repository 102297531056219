import AgreementActions from 'actions/agreement';
import FormActions from 'actions/forms';
import { Modal } from 'components/Layout/Modal';
import { getAgreementDebtor } from 'containers/Agreements/Creator/Index';
import DebtorForm from 'containers/Agreements/Details/Tabs/DebtorForm';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Debtors from '../../../Proposals/Details/Components/Debtors';

export default (props) => {
  const { agreement, version } = useSelector(state => state.Agreement);
  const dispatch = useDispatch();

  const debtors = version?.proposal_debtors?.filter(item => {
    const agreementDebtor = getAgreementDebtor(item?.debtor?.name, agreement, version);
    return !agreementDebtor;
  });

  const handleSubmit = checkedDebtors => {
    dispatch(AgreementActions.addAgreementDebtors(agreement?.id, version?.id, checkedDebtors));
    dispatch(FormActions.clearForm(DebtorForm.name));
    dispatch(FormActions.toggleForm(DebtorForm.name));
  };

  return (
    <div className='flex flex-col items-center'>
      {!!debtors?.length && (
        <>
          <button
            type="button"
            className="btn btn-primary w-25 btn-lg my-20"
            onClick={() => props.toggleForm(DebtorForm.name)}
          >
            Dodaj dłużnika
          </button>
          <Modal
            size='extralarge'
            header='Dodawanie dłużnika'
            isOpen={props.formVisible(DebtorForm.name)}
            onClose={() => props.toggleForm(DebtorForm.name)}>
            <DebtorForm
              debtors={debtors}
              onSubmit={handleSubmit}
            />
          </Modal>
        </>
      )}

      <Debtors
        isEditable
        inAgreement
        categoryDisabled
        currency={props.proposal?.currency}
        doNotDelete
        clear={props.clear}
        proposal={{ debtors: props.debtors }}
        forms={props.forms}
        errors={props.errors}
        version={props.version}
        toggleForm={props.toggleForm}
        formVisible={props.formVisible}
        saveForm={props.saveForm}
        edit={(obj, form) => props.edit(form, obj)}
        storeInput={props.storeInput}
        fetch={props.fetch}
        formData={props.formData}
        addField={props.addField}
        removeField={props.removeField}
        offerData={props.offerData}
        proposalData={props.proposalData}
        getFlatRate={props.getFlatRate}
        getMultilevelFlatRate={props.getMultilevelFlatRate}
        getIntrestRates={props.getIntrestRates}
        setLastAvailableDateOfRate={props.setLastAvailableDateOfRate}
        remove={props.remove}
        fetchFromOffer={props.fetchFromOffer}
        intrestRates={props.intrestRates}
      />
    </div>
  );
};
