import React from 'react';

import { OldCard } from '../../../../components/Layout';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parseMoney } from '../../../../utilities/helpers';

export default props => {
  const formName = 'InternalRegisterForm';

  const header = (
    <a
      role="button"
      style={{ fontSize: '24px' }}
      className="collapsed "
      data-toggle="collapse"
      href="#warunkiDodatkowe"
      aria-expanded="false"
      aria-controls="collapseExample">
      Rejestr rozliczenia wpływów
    </a>
  );

  const customCreateSection = (
    <>
      <i className="fas fa-question-circle ml-2 mr-2 " onClick={() => {}} />
    </>
  );

  const edit = (e, p) => {
    props.edit(formName, { commitment: e, ...p, claim: props.claim });
  };

  return (
    <OldCard header={header} id="financialData-card" showIcons customCreateSection={customCreateSection}>
      <div className="collapse show" id="financialData">
        <div className="row bb">
          <div className="col-sm-3 p-2 grey br">Tytuł rozliczenia</div>
          <div className="col-sm-2 p-2 grey br">Podstawa wpływu</div>
          <div className="col-sm-1 p-2 grey br">Data wpływu</div>
          <div className="col-sm-1 p-2 grey br">Kwota wpływu</div>
          <div className="col-sm-1 p-2 grey br">Waluta rozliczenia</div>
          <div className="col-sm-1 p-2 grey br">Rozliczono</div>
          <div className="col-sm-1 p-2 grey br">Do rozliczenia</div>
          <div className="col-sm-2 p-2 grey">Status</div>
        </div>

        {props.claim?.subpayments?.map((e, k) => (
          <>
            <RowEditable itemId={e.id}>
              <div className="col-sm-3 p-2 br">
                <a
                  role="button"
                  style={{ color: 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#wplyw${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample">
                  <i className="fa fa-sort-down mr-2" />
                  {e.title || e.identifier}
                </a>
              </div>
              <div className="col-sm-2 p-2 br">
                <a
                  role="button"
                  style={{ color: 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#wplyw${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample">
                  Przelew
                </a>
              </div>
              <div className="col-sm-1 p-2 br">
                <a
                  role="button"
                  style={{ color: 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#wplyw${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample">
                  {e.created_date}
                </a>
              </div>
              <div className="col-sm-1 p-2 br">
                <a
                  role="button"
                  style={{ color: 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#wplyw${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample">
                  {parseMoney(e.amount)}
                </a>
              </div>
              <div className="col-sm-1 p-2 br">
                <a
                  role="button"
                  unzip
                  style={{ color: 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#wplyw${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample">
                  {e.amount_currency?.name}
                </a>
              </div>
              <div className="col-sm-1 p-2 br">
                <a
                  role="button"
                  style={{ color: 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#wplyw${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample">
                  {parseMoney(e.paid_value)}
                </a>
              </div>
              <div className="col-sm-1 p-2 br">
                <a
                  role="button"
                  style={{ color: 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#wplyw${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample">
                  {parseMoney(e.to_pay_value)}
                </a>
              </div>
              <div className="col-sm-2 d-flex p-2">
                <a
                  role="button"
                  style={{ color: e.status ? '#1DB393' : 'black' }}
                  className="collapsed"
                  data-toggle="collapse"
                  href={`#wplyw${k}`}
                  aria-expanded="false"
                  aria-controls="collapseExample">
                  {e.status ? 'Rozliczony' : 'Nierozliczony'}
                </a>
              </div>
            </RowEditable>

            <div className="collapse" id={`wplyw${k}`}>
              <div className="row bb grey">
                <div className="col-sm-3 p-2 br"> </div>
                <div className="col-sm-2 p-2 br">Postawa rozliczenia</div>
                <div className="col-sm-1 p-2 grey br">Data rozliczenia</div>
                <div className="col-sm-1 p-2 grey br">Kwota rozliczenia</div>
                <div className="col-sm-1 p-2 grey br">Waluta</div>
                <div className="col-sm-1 p-2 grey br">Sposób rozliczenia</div>
                <div className="col-sm-1 p-2 grey br">Nr dokumentu</div>
                <div className="col-sm-2 p-2 grey">Status</div>
              </div>
              {e.incomes.map(p => (
                <RowEditable>
                  <div className="col-sm-3 grey p-2 br"> </div>
                  <div className="col-sm-2 grey p-2 br">{p.charge?.title}</div>
                  <div className="col-sm-1 grey p-2 br">{p.created_date}</div>
                  <div className="col-sm-1 grey p-2 br">{parseMoney(p.income_value)}</div>
                  <div className="col-sm-1 grey p-2 br">{p.income_value_currency?.name}</div>
                  {/*TODO: Kompensata i id*/}
                  <div className="col-sm-1 grey p-2 br">Kompensata</div>
                  <div className="col-sm-1 grey p-2 br">-</div>
                  <div className="col-sm-2 grey d-flex p-2">
                    {props.common?.paymentData?.statuses?.[p.status]}
                    {p.status === 0 ? (
                      <button className="ml-3" type="button" onClick={() => edit(e, p)}>
                        Autoryzuj
                      </button>
                    ) : null}
                    {/* {e.status === 0 ? <button className="ml-1" onClick={() => props.edit(formName, { commitment_id: e.id })}>Rozlicz</button> : null} */}
                  </div>
                </RowEditable>
              ))}
            </div>
          </>
        ))}
      </div>
    </OldCard>
  );
};
