import Card from 'components/Layout/Card';
import GoogleMapReact from 'google-map-react';
import * as R from 'ramda';
import React from 'react';
import ReactTooltip from 'react-tooltip';

const key = 'AIzaSyCeK_G_tk8mz9OauGxYcIeE-6iD9GI4HYg';

const size = { height: '500px', width: '100%' };

const MarkerComponent = ({ size = 30, isRed, isGreen, isBlue, isGrey, lat, lng, tip, ...props }) => {
  if(typeof lat === 'undefined' || typeof lng === 'undefined') {
    return null;
  }

  return (
    <div>
      {tip && <ReactTooltip id='tip-marker' />}
      <img
        data-for='tip-marker'
        data-tip={tip}
        style={{ width: size, height: size, marginTop: -size, marginLeft: -size/2 }}
        src={isRed ? '/placeholder-minired.svg' : isGreen ? '/placeholder-minigreen.svg' : isBlue ? '/placeholder-miniblue.svg' : isGrey ? '/placeholder-minigrey.svg' : ''}
        alt="Marker"
        {...props}
      />
    </div>
  );
};

const getPoints = arrayAddresses => {
  const lngLeft = R.reduce(R.min, Infinity, [...arrayAddresses.map(item => item?.point?.longitude)]);
  const lngRight = R.reduce(R.max, -Infinity, [...arrayAddresses.map(item => item?.point?.longitude)]);
  const latTop = R.reduce(R.max, -Infinity, [...arrayAddresses.map(item => item?.point?.latitude)]);
  const latBottom = R.reduce(R.min, Infinity, [...arrayAddresses.map(item => item?.point?.latitude)]);

  return { lngLeft, lngRight, latBottom, latTop };
};

const getZoom = arrayAddresses => {
  const { lngLeft, lngRight, latBottom, latTop } = getPoints(arrayAddresses);

  const xLength = Math.abs(lngLeft - lngRight);
  const yLength = Math.abs(latTop - latBottom) * 1.4;
  const maxLength = Math.max(xLength, yLength);
  let zoom;

  if(maxLength <= 1) {
    zoom = -3 * Math.pow(maxLength, 2) + 10;
  } else {
    zoom = -0.02 * Math.pow(maxLength, 1.42) + 7;
  }
  return zoom;
};

const getCenter = arrayAddresses => {
  const { lngLeft, lngRight, latBottom, latTop } = getPoints(arrayAddresses);

  const lng = (lngLeft + lngRight) / 2;
  const lat = (latBottom + latTop) / 2;

  return { lat, lng };
};

export const Maps = ({ addressesRed = [], addressesGreen = [], addressesBlue = [], addressesGrey = [], showLegend, ...props }) => {
  const allAddresses = [...addressesRed, ...addressesGreen, ...addressesBlue, ...addressesGrey];
  const hasPoint = !!allAddresses?.find(item => item?.point);
  // const hasAddressOutsidePoland = allAddresses?.find(address => address.country !== 'Poland' && address.country !== 'Polska')

  const handleMapLoad = (map, maps) => {};

  return (
    <Card header='Mapy' {...props}>
      <div>
        {showLegend && (
          <div className="row mb-1 pl-3">
            <div className="col-sm-3 p-2 br d-flex align-items-center">
              <img alt="marker" src="/placeholder-minired.svg" style={{ height: '80%' }} />
              <span className="ml-2">Adresy Faktoranta</span>
            </div>
            <div className="col-sm-3 p-2 br d-flex align-items-center">
              <img alt="marker" src="/placeholder-minigreen.svg" style={{ height: '80%' }} />
              <span className="ml-2">Adresy dłużników</span>
            </div>
            <div className="col-sm-3 p-2 br d-flex align-items-center">
              <img alt="marker" src="/placeholder-miniblue.svg" style={{ height: '80%' }} />
              <span className="ml-2">Adresy osób powiązanych</span>
            </div>
            <div className="col-sm-3 p-2 d-flex align-items-center">
              <img alt="marker" src="/placeholder-minigrey.svg" style={{ height: '80%' }} />
              <span className="ml-2">Wszystkie adresy nieaktywne</span>
            </div>
          </div>
        )}

        {hasPoint ? (
          <div style={size}>
            <GoogleMapReact
              bootstrapURLKeys={{ key }}
              defaultCenter={getCenter(allAddresses)}
              defaultZoom={getZoom(allAddresses)}
              onGoogleApiLoaded={({ map, maps }) => handleMapLoad(map, maps)}
            >
              {addressesGrey?.map(address => (
                <MarkerComponent
                  size={24}
                  key={address?.id}
                  lat={address?.point?.latitude}
                  lng={address?.point?.longitude}
                  tip={address?.name}
                  isGrey
                />
              ))}
              {addressesRed?.map(address => (
                <MarkerComponent
                  key={address?.id}
                  lat={address?.point?.latitude}
                  lng={address?.point?.longitude}
                  tip={address?.name}
                  isRed
                />
              ))}
              {addressesGreen?.map(address => (
                <MarkerComponent
                  key={address?.id}
                  lat={address?.point?.latitude}
                  lng={address?.point?.longitude}
                  tip={address?.name}
                  isGreen
                />
              ))}
              {addressesBlue?.map(address => (
                <MarkerComponent
                  size={24}
                  key={address?.id}
                  lat={address?.point?.latitude}
                  lng={address?.point?.longitude}
                  tip={address?.name}
                  isBlue
                />
              ))}
            </GoogleMapReact>
          </div>
        ) : <p className='my-10 mx-05'>Brak adresów.</p>}
      </div>
    </Card>
  );
};
