export const AUTHORIZATION_USER_DATA_FETCHED = 'AUTHORIZATION_USER_DATA_FETCHED';

const initialState = {
  user: null
};

export default function authorization(state = initialState, action) {
  switch (action.type) {
    case AUTHORIZATION_USER_DATA_FETCHED:
      return { ...state, user: action.payload };
    case 'LOGOUT':
      return { ...state, user: null };
    default:
      return state;
  }
}
