import React from 'react';

import { Form, Input } from '../components/Forms';
import { OldCard } from '../components/Layout';

export const OldSearchBar = props => (
  <OldCard className="mt-3 pt-3 pb-3">
    <h4 className="card-title text-themecolor">
      {props.title}
      <p className="small d-inline-flex float-right text-black">
        Elementów:
        {props.records}
      </p>
    </h4>
    {props.subtitle && <h6 className="card-subtitle">{props.subtitle}</h6>}
    <Form className="form-horizontal p-0" formName={props.formName}>
      <Input style={{ maxWidth: '100%' }} onSubmit={props.onSubmit} formName={props.formName} type="text" name="term" placeholder="Wpisz szukaną frazę" />
    </Form>
  </OldCard>
);
