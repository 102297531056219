import * as R from 'ramda';

export const defaultEmptyValue = '-';

const getFormattedValue = (value, ratio, options) => {
  if(value < 0) {
    value = R.negate(value);
  }
  value = (value / ratio);
  return value.toLocaleString('de-DE', options);
};

/**
 * Returns the formated "Europe" currency.
 * @param {float|number|string|null} value
 * @param {string|null} currency - e.q. zł € $
 * @param {object} options - format options
 * {string} plus - e.q. '\u00a0\u00a0'
 * {string} minus - e.q. '\u00a0-'
 * @return {string} Formated currency.
 */
export function formatValueToEurope(value, currency = '', options ) {
  const currentOptions = { plus: '', minus: '-', withoutCents: false, emptyValue: defaultEmptyValue, ratio: 1, ...options };

  if (value === null || value === undefined || value === '') return currentOptions.emptyValue;

  const sign = value < 0 ? currentOptions.minus : currentOptions.plus;

  const formattedOptions = {
    minimumFractionDigits: currentOptions.withoutCents ? 0 : 2, maximumFractionDigits: currentOptions.withoutCents ? 0 : 2
  };

  const formattedValue = getFormattedValue(value, currentOptions.ratio, formattedOptions).replace(/([.])/g, ' ');

  const substrWithoutPennies = formattedValue.substr(0, formattedValue.length - 3);
  const substr = formattedValue.substr(formattedValue.length - 3, 3);
  const currencyString = currency ? ` ${currency}` : '';

  return substr === ',00' ? `${sign}${substrWithoutPennies}${currencyString}` : `${sign}${formattedValue}${currencyString}`;
}

// Parsowanie kwot zgodnie z wytycznymi, w formacie np. "10 150,00", gdzie backend zwraca dane w postaci "10150.00".
export function parseMoney(field, emptyValue = '0,00') {
  if (field === null || field === undefined || field === '') return '-';
  if (!field || field === '-') return emptyValue;

  const f = field?.toString().replaceAll(' ', '');
  const splitted = Number(f)
    .toLocaleString('en-US')
    .split('.');
  splitted[0] = splitted[0].replaceAll(',', ' ');
  splitted[1] = splitted[1] ? [...splitted[1], '0', '0'] : ['0', '0'];

  return (splitted[1][0] === '0' && splitted[1][1] === '0') ? splitted[0] : `${splitted[0]},${splitted[1][0] + splitted[1][1]}`;
}

// Ręczne formatowanie nr telefonu - co trzy cyfry.
export function parsePhone(field) {
  if (!field) return '';

  const result = [];
  let f = field.toString().replaceAll(' ', '');
  while (f !== '') {
    result.push(f.substring(0, 3));
    f = f.substring(3);
  }
  return result.join(' ');
}

export function formatNip(value, emptyValue = 'Brak') {
  if (value === null || value === undefined || value === '') return emptyValue;
  if(value?.length === 10) {
    return `${value[0]}${value[1]}${value[2]} ${value[3]}${value[4]}${value[5]} ${value[6]}${value[7]} ${value[8]}${value[9]}`;
  }
  return value;
}

// Ręczne parsowanie numeru rachunku bankowego w oparciu o typ (switch())
export function parseBan(account) {
  if (!account) return '-';

  const { number, bank_account_type } = account;
  let f = number?.toString().replaceAll(' ', '');

  // Podmieniłem klejenie stringów, bo nie działało w przypadku, gdy podano błędny format rachunku bankowego
  // (był za krótki, dlatego np. odwołanie f[23] + f[24] zwracało NaN).
  switch (bank_account_type?.name) {
    case 'IBAN':
      return `${f.slice(0, 4)} ${f.slice(4, 8)} ${f.slice(8, 12)} ${f.slice(12, 16)} ${f.slice(16, 20)} ${f.slice(20, 24)} ${f.slice(24, 28)} `;
    case 'NRB':
      return `${f.slice(0, 2)} ${f.slice(2, 6)} ${f.slice(6, 10)} ${f.slice(10, 14)} ${f.slice(14, 18)} ${f.slice(18, 22)} ${f.slice(22, 26)} `;
    default:
      return number;
  }
}

function contextEmpty() {
  let isEmpty = false;

  for (let i = 0; i < arguments.length; i++) {
    if (typeof arguments[i] === 'string' && !arguments[i].toLocaleString().length) {
      isEmpty = true;
    } else if (typeof arguments[i] === 'object' && arguments[i] === null) {
      isEmpty = true;
    } else if (isArray(arguments[i]) && arguments[i].length === 0) {
      isEmpty = true;
    } else if (isObject(arguments[i]) && Object.entries(arguments[i]).length === 0) {
      isEmpty = true;
    }
  }
  return isEmpty;
}

// there is at least one empty argument (string or null)
export function isAnyEmpty() {
  return contextEmpty.apply(this, arguments);
}

export function formatString(value, emptyValue = defaultEmptyValue) {
  if (value === null || value === undefined || value === '') return emptyValue;
  return value;
}

export function formatStrings(stringsArray, emptyValue = defaultEmptyValue) {
  const hasEmptyValue = R.includes(null, stringsArray) || R.includes(undefined, stringsArray) || R.includes('', stringsArray) || R.includes('-', stringsArray);
  return hasEmptyValue ? emptyValue : stringsArray.join('');
}

const isArray = item => Array.isArray(item);

export const isObject = item => item === Object(item) && !isArray(item) && typeof item !== 'function';

const toCamel = string =>
  string.replace(/([-_][a-z])/gi, $1 =>
    $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );

export const toSnake = string => string.replace(/([A-Z])/g, '_$1').toLowerCase();

export function keysTo(data, func = toCamel) {
  if (isObject(data)) {
    const object = {};

    Object.keys(data).forEach(item => {
      object[func(item)] = keysTo(data[item], func);
    });

    return object;
  }
  if (isArray(data)) {
    return data.map(item => keysTo(item, func));
  }

  return data;
}

export function to(promise) {
  return promise.then(response => [null, response]).catch(error => [error]);
}

export function toAll(...args) {
  return Promise.all(args)
    .then(response => [null, keysTo(response)])

    .catch(error => [error]);
}

export const cutEmptySpaces = text => text?.replace(/\s/g, '');

export const updateObject = (oldObject, properties) => ({ ...oldObject, ...properties });

export const isEnabledOnStatuses = (statuses, currentStatus) => !R.includes(currentStatus, statuses);

export const setProperty = (name, value) => {
  if(typeof window !== 'undefined') {
    return document.documentElement.style.setProperty(name, value);
  }
};

export const addByString = name => (result, value) => {
  if(!value[name]) return '';
  return result[name] + `${value[name]} `;
};

export const isUndefined = value => typeof value === 'undefined';

export const isBoolean = value => typeof value === 'boolean';

export const getDesc = array => array.map((val, index, array) => array[array.length - 1 - index]);

// TODO: remove
export const withSelectOptions = array => array.map(item => ({ ...item, name: item.name, value: item.id }));
// TODO: use this
export const optionsCommon = (array, idValue = 'id') => array?.map(item => ({ ...item, value: item[idValue] }));

export const optionsCountries = (array, idValue = 'id', idName = 'code') => {
  const uniqueObjectsArray = [];

  for(let object of array) {
    const hasThisProperty = uniqueObjectsArray.find(item => item[idName] === object[idName]);
    if(!hasThisProperty) {
      uniqueObjectsArray.push(object);
    }
  }
  return uniqueObjectsArray?.map(item => ({ ...item, value: item[idValue], name: item[idName] }));
};

export const NumberInput = string => typeof string === 'string' ? Number(string?.replace(',', '.')) : Number(string);

export function getGroupOfErrors(namesArray, formObject, message = 'Pole wymagane.') {
  const errors = {};

  for(let i=0; i < namesArray.length; i++) {
    const foundKey = formObject && Object.keys(formObject).find(key => {
      return key === namesArray[i];
    });

    if(!foundKey) {
      errors[namesArray[i]] = message;
    }
  }
  return errors;
}
