import React from 'react';
import { Redirect } from 'react-router-dom';

import AgreementCreatorView from '../containers/Agreements/Creator/Index';
import AgreementDetailsView from '../containers/Agreements/Details/Index';
import AgreementsListView from '../containers/Agreements/List/Index';
import LiabilitiesCreatorView from '../containers/Claims/Creator/Index';
import LiabilitiesDetailsView from '../containers/Claims/Details/Index';
import LiabilitiesListView from '../containers/Claims/List/Index';
import CompanyCreatorView from '../containers/Companies/Creator/Index';
import CompanyDetailsView from '../containers/Companies/Details/Index';
import CompaniesListView from '../containers/Companies/List/Index';
import DuesList from '../containers/Invoices/List/Dues';
import InvoicesList from '../containers/Invoices/List/Index';
import OfferCreatorView from '../containers/Offers/Creator/Index';
import OfferDetailsView from '../containers/Offers/Details/Index';
import OffersListView from '../containers/Offers/List/Index';
import ControllingList from '../containers/Other/List/Controlling';
import NotificationsList from '../containers/Other/List/Notifications';
import RaportsList from '../containers/Other/List/Raports';
import Settlements from '../containers/Other/List/Settlements';
import PersonCreatorView from '../containers/Persons/Creator/Index';
import PersonDetailsView from '../containers/Persons/Details/Index';
import PersonsListView from '../containers/Persons/List/Index';
import ProposalCreatorView from '../containers/Proposals/Creator/Index';
import ProposalDetailsView from '../containers/Proposals/Details/Index';
import ProposalsListView from '../containers/Proposals/List/Index';
import TransfersList from '../containers/Transfers/List/Index';

export default [
  {
    name: 'Homepage',
    path: '/',
    guests: true,
    roles: [],
    component: () => <Redirect to="/osoby" />
  },
  {
    name: 'Widok listy osób',
    path: '/osoby',
    guests: false,
    roles: [],
    component: PersonsListView
  },
  {
    name: 'Widok szczegółow osoby',
    path: '/osoby/:slug',
    guests: false,
    roles: [],
    component: PersonDetailsView
  },
  {
    name: 'Widok kreatora osoby',
    path: '/osoby/:slug/kreator',
    guests: false,
    roles: [],
    component: PersonCreatorView
  },
  {
    name: 'Widok listy firm',
    path: '/firmy',
    guests: false,
    roles: [],
    component: CompaniesListView
  },
  {
    name: 'Widok szczegółow firmy',
    path: '/firmy/:slug',
    guests: false,
    roles: [],
    component: CompanyDetailsView
  },
  {
    name: 'Widok kreatora firmy',
    path: '/firmy/:slug/kreator',
    guests: false,
    roles: [],
    component: CompanyCreatorView
  },
  {
    name: 'Widok listy ofert',
    path: '/oferty',
    guests: false,
    roles: [],
    component: OffersListView
  },
  {
    name: 'Widok szczegółow oferty',
    path: '/oferty/:slug',
    guests: false,
    roles: [],
    component: OfferDetailsView
  },
  {
    name: 'Widok kreatora oferty',
    path: '/oferty/:slug/kreator',
    guests: false,
    roles: [],
    component: OfferCreatorView
  },
  {
    name: 'Widok listy wniosków',
    path: '/wnioski',
    guests: false,
    roles: [],
    component: ProposalsListView
  },
  {
    name: 'Widok szczegółow wniosku',
    path: '/wnioski/:slug',
    guests: false,
    roles: [],
    component: ProposalDetailsView
  },
  {
    name: 'Widok kreatora wniosku',
    path: '/wnioski/:slug/kreator',
    guests: false,
    roles: [],
    component: ProposalCreatorView
  },
  {
    name: 'Widok listy umów',
    path: '/umowy',
    guests: false,
    roles: [],
    component: AgreementsListView
  },
  {
    name: 'Widok szczegółow umowy',
    path: '/umowy/:slug',
    guests: false,
    roles: [],
    component: AgreementDetailsView
  },
  {
    name: 'Widok kreatora umowy',
    path: '/umowy/:slug/kreator',
    guests: false,
    roles: [],
    component: AgreementCreatorView
  },
  {
    name: 'Widok listy wierzytelności',
    path: '/wierzytelnosci',
    guests: false,
    roles: [],
    component: LiabilitiesListView
  },
  {
    name: 'Widok szczegółow wierzytelności',
    path: '/wierzytelnosci/:slug',
    guests: false,
    roles: [],
    component: LiabilitiesDetailsView
  },
  {
    name: 'Widok kreatora wierzytelności',
    path: '/wierzytelnosci/:slug/kreator',
    guests: false,
    roles: [],
    component: LiabilitiesCreatorView
  },
  {
    name: 'Połączona lista przelewów',
    path: '/przelewy',
    guests: false,
    roles: [],
    component: TransfersList
  },
  // {
  //   name: 'Przekierowanie na listę przelewów',
  //   path: '/przelewy',
  //   guests: false,
  //   roles: [],
  //   component: () => <Redirect to="/przelewy?mode=przychodzace" />,
  // },
  {
    name: 'Lista faktur',
    path: '/faktury',
    guests: false,
    roles: [],
    component: InvoicesList
  },
  {
    name: 'Lista należności',
    path: '/naleznosci',
    guests: false,
    roles: [],
    component: DuesList
  },
  {
    name: 'Lista wskaźników controllingowych',
    path: '/wskazniki',
    guests: false,
    roles: [],
    component: ControllingList
  },
  {
    name: 'Lista powiadomień',
    path: '/powiadomienia',
    guests: false,
    roles: [],
    component: NotificationsList
  },
  {
    name: 'Lista raportów',
    path: '/raporty',
    guests: false,
    roles: [],
    component: RaportsList
  },
  {
    name: 'Lista rozrachunków',
    path: '/rozrachunki',
    guests: false,
    roles: [],
    component: Settlements
  }
];
