import moment from 'moment';
import React from 'react';

import BooleanCheck from '../../../components/BooleanCheck';
import { DatepickerButton, Form, Select, Selectable } from '../../../components/Forms';
import { BaseModal } from 'components/Layout/OldModal';
import { RowEditable } from '../../../components/Layout/RowEditable';
import { parseBan, parseMoney } from '../../../utilities/helpers';
import { TransferForm } from './Transfer';

export const SettleForm = props => {
  const [removing, setRemoving] = React.useState(null);

  const f = props.forms[props.formName] ?? {};
  const bankAccounts = props.claim?.agreement?.agreement_versions?.find(x => x.status === 1)?.client_bank_accounts ?? [];
  const exp = props.claim?.commitments ?? [];
  const e = exp.find(y => y.id === f.commitment_id) ?? {};
  const columns = [
    { name: 'Numer rachunku', getter: 'number', sortable: 1 },
    { name: 'Waluta', getter: 'currency.name', sortable: 1 },
    { name: 'Typ rachunku', getter: 'bank_account_type.name', sortable: 1 },
    { name: 'Nazwa banku', getter: 'bank_name', sortable: 1 },
    { name: 'Data dodania', getter: 'created_date', sortable: 1 },
    { name: 'Dodał', getter: 'user_creator.full_name', sortable: 1 }
  ];

  const docsForm = 'CommitmentDocsForm';
  const transferForm = 'CommitmentTransferForm';
  const editTransferForm = 'CommitmentEditTransferForm';

  const isCompensate = id => e.payments?.find(z => z.id === id)?.identifier[0] === 'K';

  const remindSettle = [e].reduce((acc, v) => acc + Number(v?.to_pay_value), 0);

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
              Kwota wydatku
            </label>
            <div className="col-sm-6">
              {parseMoney(e.gross_value)} {e.currency?.name}
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
              MPP
            </label>
            <div className="col-sm-6">
              <BooleanCheck field={props.claim?.mpp} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
              Kwota wykupu (VAT)
            </label>
            <div className="col-sm-6">
              {parseMoney(props.claim?.gross_value)} ({parseMoney(props.claim?.vat_value)})
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
              Waluta wykupu / VAT
            </label>
            <div className="col-sm-5">
              {props.claim?.purchase_custom_value_currency?.name || props.claim?.purchase_currency?.name}
              {' / '}
              {props.claim?.purchase_custom_vat_value_currency?.name || props.claim?.vat_value_currency?.name}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
              Tytuł wydatku
            </label>
            <div className="col-sm-9">{e.title}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
              Nr wierzytelności / status
            </label>
            <div className="col-sm-9">
              {props.claim?.identifier} / {props.common?.claimData?.claim_statuses?.[e.status]}
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="row d-flex align-items-center ">
        <div className="col-sm-6">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
              Planowana data przelewu
            </label>
            <div className="col-sm-6">
              <DatepickerButton formName={props.formName} name="invoice_date" buttonOnClick={() => props.clear(props.formName, 'invoice_date')} />
            </div>
          </div>
        </div>
        <div className="col-sm-6 text-right">
          <button type="button">Zakończ generowanie zaliczki</button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
              Metoda kalkulacji wydatku
            </label>
            <div className="col-sm-6  d-flex align-items-center">
              <label className="custom-control custom-radio d-inline-flex align-items-center">
                <input
                  id="radio-0-1"
                  name="radio-0-1"
                  type="radio"
                  className="custom-control-input mr-3"
                  onChange={() => {}}
                  disabled
                  checked={false}
                />
                <span className="custom-control-label radio-label"> auto</span>
              </label>
              <label className="custom-control custom-radio d-inline-flex align-items-center ml-4">
                <input
                  id="radio-1-2"
                  name="radio-1-2"
                  type="radio"
                  className="custom-control-input mr-3"
                  onChange={() => {}}
                  disabled
                  checked={false}
                />
                <span className="custom-control-label  radio-label"> auto bez potrąceń</span>
              </label>
              <label className="custom-control custom-radio d-inline-flex align-items-center ml-4">
                <input id="radio-1-2" name="radio-1-2" type="radio" className="custom-control-input mr-3" onChange={() => {}} disabled checked />
                <span className="custom-control-label  radio-label"> własna</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
              Metoda kalkulacji VAT
            </label>

            <div className="col-sm-6  d-flex align-items-center">
              <label className="custom-control custom-radio d-inline-flex align-items-center">
                <input id="radio-0-1" name="radio-0-1" type="radio" className="custom-control-input mr-3" onChange={() => {}} disabled checked />
                <span className="custom-control-label radio-label"> proporcjonalnie</span>
              </label>
              <label className="custom-control custom-radio d-inline-flex align-items-center ml-4">
                <input
                  id="radio-1-2"
                  name="radio-1-2"
                  type="radio"
                  className="custom-control-input mr-3"
                  onChange={() => {}}
                  disabled
                  checked={false}
                />
                <span className="custom-control-label  radio-label"> Tylko VAT</span>
              </label>
              <label className="custom-control custom-radio d-inline-flex align-items-center ml-4">
                <input
                  id="radio-1-2"
                  name="radio-1-2"
                  type="radio"
                  className="custom-control-input mr-3"
                  onChange={() => {}}
                  disabled
                  checked={false}
                />
                <span className="custom-control-label  radio-label"> bez VAT</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row bt bb mt-3" style={{ fontSize: '90%' }}>
        <div className="col-2 p-2 br grey">Podstawa wydatku</div>
        <div className="col-1 p-2 br grey">Termin płatności</div>
        <div className="col-1 p-2 br grey">Do rozliczenia</div>
        <div className="col-1 p-2 br grey">Rozliczono</div>
        <div className="col-1 p-2 br grey">Saldo/kwota</div>
        <div className="col-1 p-2 br grey">Waluta dokumentu</div>
        <div className="col-1 p-2 br grey">Kurs</div>
        <div className="col-1 p-2 br grey">Data rozliczenia</div>
        <div className="col-1 p-2 br grey">Metoda płatności</div>
        <div className="col-2 p-2 grey">Status</div>
      </div>
      {[e]?.map(j => (
        <>
          <div className="row bb" style={{ fontSize: '90%' }}>
            <div className="col-2 p-2 br text-themecolor">{j.title}</div>
            <div className="col-1 p-2 br text-themecolor">
              {moment()
                .add(2, 'days')
                .format('DD.MM.YYYY')}
            </div>
            <div className="col-1 p-2 br text-themecolor">{parseMoney(j.to_pay_value)}</div>
            <div className="col-1 p-2 br text-themecolor">{parseMoney(j.paid_value)}</div>
            <div className="col-1 p-2 br text-themecolor">-</div>
            <div className="col-1 p-2 br text-themecolor">{j.currency?.name}</div>
            <div className="col-1 p-2 br text-themecolor">{props.claim?.exchange_rate}</div>
            <div className="col-1 p-2 br text-themecolor">-</div>
            <div className="col-1 p-2 br text-themecolor"> </div>
            <div className="col-2 p-2 text-themecolor">{j.is_settled ? 'Rozliczony' : 'Nierozliczony'}</div>
          </div>
          {j.payments?.map(p => (
            <RowEditable key={p.id} onRemove={p.paid_value !== '0.00' ? null : () => setRemoving(p)} style={{ fontSize: '90%' }}>
              <div className="col-2 p-2 br ">{p.identifier}</div>
              <div className="col-1 p-2 br ">
                {moment()
                  .add(2, 'days')
                  .format('DD.MM.YYYY')}
              </div>
              <div className="col-1 p-2 br ">-</div>
              <div className="col-1 p-2 br ">-</div>
              <div className="col-1 p-2 br ">
                {parseMoney(j.is_vat ? p.vat_transfer_value : p.net_transfer_value)}
                {p.status === -1 && (
                  <button
                    type="button"
                    className="ml-1"
                    onClick={() => {
                      props.edit(editTransferForm, {
                        ...p,
                        ...f,
                        net_transfer_value: j.is_vat ? p.vat_transfer_value : p.net_transfer_value,
                        transfer_value_currency_id: j.is_vat ? p.vat_transfer_value_currency?.id : p.gross_transfer_value_currency?.id
                      });
                    }}>
                    ...
                  </button>
                )}
              </div>
              <div className="col-1 p-2 br">{j.is_vat ? p.vat_transfer_value_currency?.name : p.gross_transfer_value_currency?.name}</div>
              <div className="col-1 p-2 br ">{props.claim?.exchange_rate}</div>
              <div className="col-1 p-2 br ">{p.created_date}</div>
              <div className="col-1 p-2 br ">
                {p.status === -1 ? (
                  <button type="button" style={{ maxHeight: '50px' }} onClick={() => props.generateTransfer(p.id, isCompensate(p.id))}>
                    {isCompensate(p.id) ? 'Skompensuj' : 'Generuj przelew'}
                  </button>
                ) : (
                  '-'
                )}
              </div>
              <div className="col-2 p-2"> {props.common?.paymentData?.statuses?.[p.status]}</div>
            </RowEditable>
          ))}
        </>
      ))}

      <div className="row mt-2 mb-2">
        <div className="col-sm-3">
          <button
            type="button"
            onClick={() => {
              props.storeInput(props.formName, 'net_transfer_value', e.to_pay_value);
              props.storeInput(props.formName, 'transfer_value_currency_id', e.currency?.id);
              props.toggleForm(transferForm);
            }}>
            Dodaj przelew
          </button>
          <button
            type="button"
            className="ml-2"
            onClick={() => {
              // props.storeInput(props.docsFilters, 'context', f.context === 2 ? 1 : 2);
              props.storeInput(props.docsFilters, 'client_id', props.claim.client);
              props.storeInput(props.docsFilters, 'is_settled', 0);

              props.fetchDocs();
              props.edit(docsForm, {

              });
            }}>
            Dokumenty do rozliczenia
          </button>
        </div>
        <div className="col-sm-6">
          <label htmlFor="example-text-input " className=" label-right">
            Prognozowane saldo do rozliczenia:{' '}
            <span style={{ color: 'black' }}>
              {parseMoney(remindSettle)} {e.commitment_value_currency?.name}
            </span>
          </label>
        </div>
      </div>

      <hr />

      <div className="row mt-3">
        <div className="col-sm-4">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-2 col-form-label label-right">
              Klient
            </label>
            <div className="col-sm-10">{props.claim?.client?.name}</div>
          </div>
        </div>
        <div className="col-sm-8">
          <div className="form-group row mb-0 align-items-center">
            <label htmlFor="example-text-input " className="col-sm-5 col-form-label label-right">
              Rachunek do przelewu
            </label>
            <div className="col-sm-7 d-flex align-items-center">
              {parseBan(f.bank_account)}
              <button type="button" onClick={() => props.toggleForm('bank_accounts')} className="ml-3">
                Zarządzaj
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row bt bb mt-3">
        <div className="col-sm-3 p-2 br grey">Numer rachunku</div>
        <div className="col-sm-1 p-2 br grey">Waluta</div>
        <div className="col-sm-2 p-2 br grey">Typ rachunku</div>
        <div className="col-sm-2 p-2 br grey">Nazwa banku</div>
        <div className="col-sm-2 p-2 br grey">Data dodania</div>
        <div className="col-sm-2 p-2 grey">Dodał do umowy</div>
      </div>
      {f.bank_account ? (
        <div className="row bb">
          <div className="col-sm-3 p-2 br">{parseBan(f.bank_account)}</div>
          <div className="col-sm-1 p-2 br">{f.bank_account?.currency?.name}</div>
          <div className="col-sm-2 p-2 br">{f.bank_account?.bank_account_type?.name}</div>
          <div className="col-sm-2 p-2 br">{f.bank_account?.bank_name}</div>
          <div className="col-sm-2 p-2 br">{f.bank_account?.created_date}</div>
          <div className="col-sm-2 p-2">{f.bank_account?.user_creator?.full_name}</div>
        </div>
      ) : (
        <div className="row bb">
          <div className="col-sm-12 p-2">Brak wybranego rachunku.</div>
        </div>
      )}

      {props.withButtons ? (
        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button
              type="button"
              onClick={props.onCancelButtonClick}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}>
              Anuluj
            </button>
            <button type="button" onClick={() => props.onCancelButtonClick()} className="btn btn-primary ml-2  font-weight-light">
              Zatwierdź
            </button>
          </div>
        </div>
      ) : null}

      <Selectable
        fetch={() => {}}
        // fetch={props.fetch}
        visible={props.formVisible('bank_accounts')}
        formName={props.formName}
        field="bank_account"
        columns={columns}
        options={bankAccounts}
        onClickAway={() => props.toggleForm('bank_accounts')}
        header="Wybierz rachunek klienta"
        footer="Aby zapisać wciśnij ZATWIERDŹ"
        onSelected={() => {
          props.toggleForm('client_accounts');
        }}
      />

      <BaseModal
        visible={props.formVisible(transferForm)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" /> <span>Dodawanie przelewu</span>
          </div>
        }
        footer="Aby zapisać dokument wciśnij Zapisz."
        onClickAway={() => props.toggleForm(transferForm)}>
        <TransferForm
          submitForm={() => props.submitForm(props.formName)}
          withButtons
          formVisible={props.formVisible}
          toggleForm={props.toggleForm}
          formName={props.formName}
          forms={props.forms}
          onCancelButtonClick={() => props.toggleForm(transferForm)}
          common={props.common}
          clear={props.clear}
        />
      </BaseModal>
      <BaseModal
        visible={props.formVisible(editTransferForm)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" /> <span>Edycja przelewu</span>
          </div>
        }
        footer="Aby zapisać dokument wciśnij Zapisz."
        onClickAway={() => props.toggleForm(editTransferForm)}>
        <TransferForm
          submitForm={() => props.submitForm(editTransferForm, true)}
          withButtons
          formVisible={props.formVisible}
          toggleForm={props.toggleForm}
          formName={editTransferForm}
          forms={props.forms}
          onCancelButtonClick={() => props.toggleForm(editTransferForm)}
          common={props.common}
          clear={props.clear}
          saveText="Zapisz"
        />
      </BaseModal>
      <BaseModal
        size="medium"
        visible={removing}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" /> <span>Usuwanie {isCompensate(removing?.id) ? 'kompensaty' : 'przelewu'}</span>
          </div>
        }
        onClickAway={() => setRemoving(null)}>
        <div className="row p-1">
          <div className="mt-1 col-sm-12">
            <span>Czy na pewno chcesz usunąć {isCompensate(removing?.id) ? 'kompensatę' : 'przelew'} {removing?.identifier}?</span>
          </div>
        </div>
        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button
              type="button"
              onClick={() => setRemoving(null)}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}>
              Anuluj
            </button>
            <button
              type="button"
              onClick={() => {
                props.removeTransfer(removing.id, isCompensate(removing?.id));
                setRemoving(null);
              }}
              className="btn btn-primary ml-2  font-weight-light">
              Usuń
            </button>
          </div>
        </div>
      </BaseModal>

      <Selectable
        noTermSearching
        filtersForm={props.docsFilters}
        fetch={props.fetchDocs}
        visible={props.formVisible(docsForm)}
        formName={docsForm}
        field="docs"
        title={
          (
            <div className="row mt-1 align-items-center mb-1">
              <div className="col-sm-1 p-2 text-right grey">Klient</div>
              <div className="col-sm-9 p-2 d-flex align-items-center">
                {props.forms?.[props.docsFilters]?.client_id?.name ?? '-'}
                <button type="button" className="ml-2" onClick={() => {
                  props.fetchClients();
                  props.toggleForm('settlements_client');
                }}>Wybierz</button>
              </div>
              <div className="col-sm-2 p-2 text-right grey d-flex align-items-center">
                <Select className="w-100" formName={props.docsFilters} options={[
                  { value: null, name: 'Wszystkie' },
                  { value: 1, name: 'Tylko rozliczone' },
                  { value: 0, name: 'Tylko nierozliczone' }
                ]} name="is_settled"
                onChange={() => {props.fetchDocs();}}
                />
                {/*Tylko nierozliczone*/}
                {/*<BooleanCheck className="ml-2" field={!props.forms?.[props.docsFilters]?.is_settled} onClick={() => {*/}
                {/*  props.storeInput(props.docsFilters, 'is_settled', !props.forms?.[props.docsFilters]?.is_settled);*/}
                {/*  props.fetchDocs();*/}
                {/*}}/>*/}
              </div>
            </div>
          )
        }
        columns={[
          { name: 'Identyfikator', getter: 'identifier', sortable: 1 },
          // { name: 'Kategoria', getter: 'number', sortable: 1 },
          {
            name: 'Termin płatności',
            getter: 'payment_date',
            sortable: 1
          },
          // { name: 'Rozliczono', getter: 'number', sortable: 1 },
          { name: 'Kategoria', getter: 'context', sortable: 1 },
          {
            name: 'Wartość brutto',
            getter: 'gross_value',
            mutator: parseMoney,
            sortable: 1
          }, {
            name: 'Do rozliczenia',
            getter: 'to_pay_value',
            mutator: parseMoney,
            sortable: 1
          }, {
            name: 'Status',
            getter: 'status',
            sortable: 1
          }
        ]}
        options={props.docs}
        onClickAway={() => props.toggleForm(docsForm)}
        header="Wybierz dokument do rozliczenia"
        onSelected={() => props.sendCompensation(docsForm)}
      />

      <Selectable
        filtersForm={props.clientFilters}
        fetch={props.fetchClients}
        visible={props.formVisible('settlements_client')}
        formName={props.docsFilters}
        field="client_id"
        columns={[{ name: 'Nazwa', getter: 'name', sortable: 1 }]}
        options={props.clients}
        onClickAway={() => props.toggleForm('settlements_client')}
        header="Wybierz klienta"
        onSelected={() => {
          props.fetchDocs();
        }}
      />
    </Form>
  );
};
