import api from 'api';
import Button from 'components/Forms/Button';
import Col, { ColName, ColNameBig } from 'components/Forms/Col';
import Datepicker from 'containers/Persons/List/Datepicker';
import Form from 'components/Forms/Form';
import Required from 'components/Forms/Required';
import SelectHook from 'components/Forms/SelectHook';
import SelectModal from 'components/Forms/SelectModal';
import TextField, { TextFieldNumber } from 'components/Forms/TextField';
import useData from 'hooks/useData';
import { t } from 'lang/pl';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { optionsCountries } from 'utilities/helpers';

const femaleExceptions = ['berenike', 'inez', 'ingrid', 'margaret', 'nel', 'rene', 'ruth', 'salome', 'solagne' ];
const maleExceptions = ['barnaba', 'bonawentura', 'dyzma', 'jarema', 'juda', 'kosma', 'kuba', 'sasza', 'seba', 'zawisza' ];

const GENDER = { MAN: 1, WOMAN: 2 };

export const genderOptions = [
  { id: 1, label: 'Mężczyzna', name: 'Mężczyzna', value: GENDER.MAN },
  { id: 2, label: 'Kobieta', name: 'Kobieta', value: GENDER.WOMAN }
];

export const CreatePersonForm = ({ onClose, onSubmit }) => {
  const { register, control, setValue, clearErrors, handleSubmit, errors } = useForm();
  const state = { button: 1 };

  const history = useHistory();
  const { sendRequest } = useData();
  const { countryCallingCodes, systemRoles, consentData } = useSelector(state => state.Common);

  const [isInitial, setInitial] = useState(true);

  const handleFillGender = ({ target: { value } }) => {
    if(isInitial && value.length >= 3) {
      const lowercaseValue = value.toLowerCase();
      setInitial(false);

      const lastChar = lowercaseValue.charAt(lowercaseValue.length - 1);
      const isFemale = femaleExceptions.includes(lowercaseValue);
      const isMale = maleExceptions.includes(lowercaseValue);
      let genderValue;

      if(isFemale) {
        genderValue = GENDER.WOMAN;
      } else if(isMale) {
        genderValue = GENDER.MAN;
      } else if(lastChar === 'a') {
        genderValue = GENDER.WOMAN;
      } else {
        genderValue = GENDER.MAN;
      }
      const gender = genderOptions.find(item => item.value === genderValue);
      setValue('gender', gender);
      clearErrors('gender');
    }
  };

  const handleOnSubmit = data => {
    sendRequest(api.persons.addPerson(data), ({ slug }) => {
      if (state.button === 1) {
        history.push(`/osoby/${slug}/kreator`);
      }
      if (state.button === 2) {
        onSubmit();
        onClose();
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="t-row">
        <ColNameBig className='text-right flex-22'>Imię<Required/></ColNameBig>
        <Col className='flex-22'>
          <TextField
            name='first_name'
            register={register({ required: t.error.field.required, minLength: 3, maxLength: 20 })}
            onBlur={handleFillGender}
            error={errors.first_name}
          />
        </Col>
        <ColNameBig className='text-right flex-15'>Drugie imię </ColNameBig>
        <Col className='flex-22'>
          <TextField
            name='second_name'
            register={register({ minLength: 3, maxLength: 20 })}
            error={errors.second_name}
          />
        </Col>
        <Col className='flex-19' />
      </div>

      <div className="t-row">
        <ColNameBig className='text-right flex-22'>Nazwisko<Required/></ColNameBig>
        <Col className='flex-22'>
          <TextField
            name='last_name'
            register={register({ required: t.error.field.required, minLength: 3, maxLength: 60 })}
            error={errors.last_name}
          />
        </Col>
        <ColNameBig className='text-right flex-15'>Płeć<Required/></ColNameBig>
        <Col className='flex-22'>
          <SelectHook
            name="gender"
            control={control}
            error={errors.gender}
            options={genderOptions}
            rules={{ required: t.error.field.required }}
          />
        </Col>
        <Col className='flex-19' />
      </div>

      <div className="t-row pb-05">
        <ColNameBig className='text-right flex-22'>Telefon<Required/></ColNameBig>
        <Col className='flex-6'>
          <SelectHook
            isClearable={false}
            name="country_calling_code_id"
            control={control}
            error={errors.country_calling_code_id}
            options={optionsCountries(countryCallingCodes)}
            rules={{ required: true }}
            optionName='code'
            optionValue='code'
            placeholder=''
          />
        </Col>
        <Col className='flex-1' />
        <Col className='flex-15'>
          <TextFieldNumber
            name='phone'
            control={control}
            error={errors.phone}
            rules={{ required: t.error.field.required, minLength: 5, maxLength: 20 }}
            type='tel'
          />
        </Col>
        <ColNameBig className='text-right flex-15'>Rola systemowa</ColNameBig>
        <Col className='flex-22'>
          <SelectHook
            name="system_roles_id"
            control={control}
            error={errors.system_roles_id}
            options={systemRoles}
          />
        </Col>
        <Col className='flex-19' />
      </div>

      <hr />
      <div className='m-07'>Ochrona danych osobowych</div>

      <div className="t-row">
        <Col className='flex-1' />
        <ColNameBig className='text-right flex-1'>Data zgody<Required/></ColNameBig>
        <Col className='flex-1'>
          <Datepicker
            name="accepted_date"
            register={register({ required: t.error.field.required })}
            setValue={setValue}
            clearErrors={clearErrors}
            error={errors.accepted_date}
          />
        </Col>
        <Col className='flex-2' />
      </div>

      <div className="t-row">
        <Col className='flex-1' />
        <ColNameBig className='text-right flex-1'>Rodzaj zgody<Required/></ColNameBig>
        <Col className='flex-2'>
          <SelectHook
            name="consent_template"
            control={control}
            rules={{ required: t.error.field.required }}
            error={errors.consent_template}
            options={consentData?.consent_templates}
            optionName='text'
          />
        </Col>
        <Col className='flex-1' />
      </div>

      <div className="t-row">
        <Col className='flex-1' />
        <ColNameBig className='text-right flex-1'>Źródło zgody<Required/></ColNameBig>
        <Col className='flex-1'>
          <SelectHook
            name="consent_source"
            control={control}
            rules={{ required: t.error.field.required }}
            error={errors.consent_source}
            options={consentData?.consent_sources}
          />
        </Col>
        <Col className='flex-2' />
      </div>

      <div className="t-row">
        <Col className='flex-1' />
        <ColNameBig className='text-right flex-1'>Administrator danych<Required/></ColNameBig>
        <Col className='flex-3'>
          <SelectModal
            name="administrator"
            register={register}
            setValue={setValue}
            title='Wybierz administratora danych'
            options={consentData?.consent_administrators}
          />
        </Col>
      </div>

      <div className="t-row">
        <Col className='flex-5' />
        <ColNameBig className='text-right flex-15'>Przedstawiciel administratora<Required/></ColNameBig>
        <Col className='flex-30'>
          <SelectModal
            name="representator"
            register={register}
            setValue={setValue}
            title='Wybierz przedstawiciela administratora'
            options={consentData?.consent_representators}
          />
        </Col>
      </div>

      <div className="t-row">
        <Col className='flex-1' />
        <ColNameBig className='text-right flex-1'>Inspektor danych<Required/></ColNameBig>
        <Col className='flex-3'>
          <SelectModal
            name="data_inspector"
            register={register}
            setValue={setValue}
            title='Wybierz inspektora danych'
            options={consentData?.consent_data_inspectors}
          />
        </Col>
      </div>

      <div className='flex justify-center mt-20'>
        <Button onClick={onClose}>Anuluj</Button>
        <Button mode='primary' onClick={() => (state.button = 1)} type='submit'>Zapisz</Button>
        <Button mode='primary' onClick={() => (state.button = 2)} type='submit'>Zapisz i zamknij</Button>
      </div>
    </form>
  );
};
