import React from 'react';

import Fraud from '../Components/Fraud';
import Quality from '../Components/Quality';
import Quantative from '../Components/Quantative';
import Representation from '../Components/Representation';
import Risk from '../Components/Risk';
import Scoring from '../Components/Scoring';
import Secures from '../Components/Secures';

export default props => (
  <>
    {/* <Header proposal={props.proposal} calculation={props.calculation} /> */}
    <Representation
      populateForm={props.populateForm}
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
      representations={props.common.companyData.representations || []}
      representants={props.proposal && props.proposal.client ? props.proposal.client.people : []}
    />
    <Risk
      edit={props.edit}
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      proposalData={props.common.proposalData}
    />
    <Quantative
      factors={props.factors}
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
      remove={props.remove}
      common={props.common}
    />
    <Scoring
      populateForm={props.populateForm}
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
      companyData={props.common.companyData}
    />
    <Quality
      categories={props.common.proposalData ? props.common.proposalData.criteria_categories_data : []}
      fieldTypes={props.common.proposalData ? props.common.proposalData.criteria_field_types : []}
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
      remove={props.remove}
      storeInput={props.storeInput}
    />
    <Fraud
      categories={props.common.proposalData ? props.common.proposalData.criteria_categories_data : []}
      fieldTypes={props.common.proposalData ? props.common.proposalData.criteria_field_types : []}
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
      remove={props.remove}
      storeInput={props.storeInput}
      updateAmlDecision={props.updateAmlDecision}
    />
    <Secures
      // issuers={props.issuers}
      // fetchIssuers={props.fetchIssuers}
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
      common={props.common}
      storeInput={props.storeInput}
      remove={props.remove}
    />
  </>
);
