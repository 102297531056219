import React from 'react';

import { Form, Input, Select } from '../../../components/Forms';

export const ReadinessForm = (props) => {
  const f = props.forms && props.forms[props.formName] ? props.forms[props.formName] : {};

  const declaredUsages = [
    { value: '10.00', name: '10%' },
    { value: '20.00', name: '20%' },
    { value: '30.00', name: '30%' },
    { value: '40.00', name: '40%' },
    { value: '50.00', name: '50%' },
    { value: '60.00', name: '60%' },
    { value: '70.00', name: '70%' },
    { value: '80.00', name: '80%' },
    { value: '90.00', name: '90%' },
    { value: '100.00', name: '100%' }
  ];

  const billingPeriods = [
    { value: 1, name: '1 dni' },
    { value: 7, name: '7 dni' },
    { value: 14, name: '14 dni' },
    { value: 21, name: '21 dni' },
    { value: 30, name: '30 dni' },
    { value: 45, name: '45 dni' },
    { value: 60, name: '60 dni' },
    { value: 75, name: '75 dni' },
    { value: 90, name: '90 dni' },
    { value: 120, name: '120 dni' },
    { value: 180, name: '180 dni' },
    { value: 365, name: '365 dni' }
  ];

  const getNameForField = () => {
    switch (f.preparation_commission_type) {
      case 0:
        return 'limit_commission';
      case 1:
        return 'percentage_unused_amount_commission';
      case 2:
        return 'unused_amount_commission';
      default:
        return 'none';
    }
  };

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <span className="col-sm-3 col-form-label label-right mb-15">
                  Deklarowane wykorzystanie
                </span>
                <div className="col-sm-3">
                  <Select formName={props.formName} options={declaredUsages} name="declared_use" />
                </div>
              </div>
            </div>
          </div>
          <div className="row bb mt-3">
            <div className="col-sm-3 mb-1 label-right font-weight-light grey">
              <span>Prowizja za gotowość</span>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <div className="col-sm-3 col-form-label label-right">Od kwoty limitu</div>
                <div className="col-sm-1">
                  <label className="custom-control custom-radio d-flex align-items-center">
                    <input
                      id="radio1"
                      name="radio"
                      type="radio"
                      className="custom-control-input mr-3"
                      onChange={() => {
                        props.storeInput(props.formName, 'preparation_commission_type', 0);
                      }}
                      checked={f.preparation_commission_type === 0}
                    />
                    <span className="custom-control-label " />
                  </label>
                </div>
                <div className="col-sm-3 col-form-label label-right">Od niewykorzystanej kwoty limitu</div>
                <div className="col-sm-1">
                  <label className="custom-control custom-radio d-flex align-items-center">
                    <input
                      id="radio1"
                      name="radio"
                      type="radio"
                      className="custom-control-input mr-3"
                      onChange={() => {
                        props.storeInput(props.formName, 'preparation_commission_type', 1);
                      }}
                      checked={f.preparation_commission_type === 1}
                    />
                    <span className="custom-control-label " />
                  </label>
                </div>
                <div className="col-sm-2 col-form-label label-right">Ryczałt</div>
                <div className="col-sm-1">
                  <label className="custom-control custom-radio d-flex align-items-center">
                    <input
                      id="radio1"
                      name="radio"
                      type="radio"
                      className="custom-control-input mr-3"
                      onChange={() => {
                        props.storeInput(props.formName, 'preparation_commission_type', 2);
                      }}
                      checked={f.preparation_commission_type === 2}
                    />
                    <span className="custom-control-label " />
                  </label>
                </div>
              </div>
            </div>
          </div>
          {typeof f.preparation_commission_type === 'undefined' ? null : (
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group row  align-items-center">
                  <span className="col-sm-3 col-form-label label-right mb-15">
                    Wysokość prowizji
                  </span>
                  <div className="col-sm-3">
                    <Input formName={props.formName} type="text" name={getNameForField()} />
                  </div>
                </div>
              </div>
            </div>
          )}
          <hr />
          <div className="row mt-20">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <span className="col-sm-3 col-form-label label-right mb-15">
                  Deklarowany czas rozruchu
                </span>
                <div className="col-sm-3">
                  <Input formName={props.formName} type="text" name="start_up_contract" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <span className="col-sm-3 col-form-label label-right mb-15">
                  Okres rozliczeniowy
                </span>
                <div className="col-sm-6">
                  <Select formName={props.formName} number options={billingPeriods} name="billing_period" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row  align-items-center">
                <span className="col-sm-3 col-form-label label-right mb-15">
                  Metoda liczenia
                </span>
                <div className="col-sm-6">
                  <Select formName={props.formName} options={props.countingMethods} name="counting_method" number />
                </div>
              </div>
            </div>
          </div>

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancel}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}
                >
                  Anuluj
                </button>
                <button type="button" onClick={() => props.saveForm()} className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
