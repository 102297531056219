import api from 'api';
import useData from 'hooks/useData';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { storeInput } from 'reducers/forms';
import tw from 'twin.macro';

import BooleanCheck from '../../../components/BooleanCheck';
import { Buttons, Datepicker, Form, Input, Required, Selectable, SelectButton } from '../../../components/Forms';
import { NumberInput, parseMoney, parsePhone } from '../../../utilities/helpers';
import moment from 'moment';

export const PURCHASE_TYPE = { BRUTTO: 0, NETTO: 1, CUSTOM: 2 };

export default props => {
  const { sendDispatchRequest } = useData({ formName: props.formName });
  const dispatch = useDispatch();

  const e = props.errors || {};
  const filters = {
    agreements: 'AgreementProposalsFilters',
    clients: 'AgreementClientsFilters',
    factors: 'AgreementFactorsFilters',
    operational_administrators: 'AgreementOAFilters',
    billing_managers: 'AgreementBMFilters',
    trade_supervisors: 'AgreementTSFilters',
    clients_for_agreements: 'ClientsWithAgreements'
  };

  const columns = {
    agreements: [
      { name: 'Nr umowy', getter: 'agreement.identifier', sortable: 1 },
      {
        name: 'Status umowy',
        getter: 'agreement.status',
        sortable: 1,
        mutator: () => 'Aktywna'
      },
      { name: 'Nr wersji', getter: 'version', sortable: 1 },
      {
        name: 'Status wersji',
        getter: 'status',
        sortable: 1,
        mutator: x => props.agreementData?.agreement_statuses?.[x]
      },
      { name: 'Procedura', getter: 'agreement.procedure.name', sortable: 1 },
      {
        name: 'Kwota limitu',
        getter: 'agreement.limit_amount',
        sortable: 1,
        mutator: parseMoney
      }
    ],
    representation: [
      { name: 'Imię i nazwisko', getter: 'person.full_name', sortable: 1 },
      { name: 'E-mail', getter: 'person.active_email', sortable: 1 },
      {
        name: 'Telefon',
        getter: 'person.active_phone',
        sortable: 1,
        mutator: parsePhone
      },
      {
        name: 'Rola biznesowa',
        getter: 'roles',
        sortable: 1,
        mutator: g => g.map(i => i.name).join()
      },
      { name: 'Ostatnia modyfikacja', getter: 'modified_date', sortable: 1 }
    ],
    default: [{ name: 'Nazwa', getter: 'name' }]
  };
  const formData = props.formData || {};
  const f = props.form || {};

  const currencyField = f?.purchase_type === PURCHASE_TYPE.CUSTOM ? f?.purchase_custom_value_currency_id : f?.purchase_currency_id;
  const currencyVatField = f?.purchase_type === PURCHASE_TYPE.CUSTOM ? f?.purchase_custom_vat_value_currency_id : f?.purchase_currency_id;
  const selectedCurrency = props.currencies?.find(x => x.id === currencyField)?.name || f.purchase_type === PURCHASE_TYPE.CUSTOM ? 'PLN' : '-';
  const selectedVatCurrency = props.currencies?.find(x => x.id === currencyVatField)?.name || '-';
  const netCurrency = props.currencies?.find(x => x.id === f.gross_value_currency_id)?.name || '-';

  const grossValue = () => {
    if(f.purchase_type === PURCHASE_TYPE.NETTO) {
      return f.net_value;
    }
    else if (f.purchase_type === PURCHASE_TYPE.BRUTTO) {
      return f.gross_value;
    }
    else if (f.purchase_custom_value) {
      return Number(f.purchase_custom_value) + Number(f.purchase_custom_vat_value);
    }
    return 0;
  };

  const netValue = () => {
    if (f.purchase_type === PURCHASE_TYPE.BRUTTO || f.purchase_type === PURCHASE_TYPE.NETTO) {
      return f.net_value;
    }
    else if (f.purchase_custom_value) {
      return f.purchase_custom_value;
    }
    return 0;
  };

  const vatValue = () => {
    if(f?.purchase_type === PURCHASE_TYPE.NETTO) {
      return 0;
    }
    else if (f.purchase_type === PURCHASE_TYPE.BRUTTO) {
      return f.vat_value;
    }
    else if (f.purchase_custom_vat_value) {
      return f.purchase_custom_vat_value;
    }
    return 0;
  };

  const filteredAgreements = formData.clients_with_agreements?.filter(a => {
    const { term } = props.forms?.[filters.agreements];
    if (!term || term.length === 0) {
      return a;
    }
    return a.agreement?.identifier?.toLowerCase().includes(term.toLowerCase()) || a.agreement?.limit_amount.includes(term);
  });

  useEffect(() => {
    props.storeInput(props.formName, 'purchase_type', PURCHASE_TYPE.BRUTTO);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(f?.debtors?.length === 1) {
      props.storeInput(props.formName, 'debtor', f.debtors[0]);
    }
  }, []);

  useEffect(() => {
    if(f.client?.id) {
      sendDispatchRequest(api.claims.getClaimsWithActiveAgreements, { client_id: f.client.id }, ({ data }) => {
        if(data?.length === 1) {
          dispatch(storeInput(props.formName, 'agreement', data[0].agreement));
          dispatch(storeInput(props.formName, 'trade_supervisor', data[0].agreement?.trade_supervisor));
          dispatch(storeInput(props.formName, 'billing_manager', data[0].agreement?.billing_manager));

          const debtors = data[0].debtors;
          dispatch(storeInput(props.formName, 'debtors', debtors));

          if(debtors.length === 1) {
            dispatch(storeInput(props.formName, 'debtor', debtors[0]));
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [f.client]);

  useEffect(() => {
    props.clear(props.formName, 'calculatedPurchase');
    props.clear(props.formName, 'calculatedPurchaseVat');
    props.storeInput(props.formName, 'exchange_rate', '1.000');

    // TODO: remove unused code?
    if(grossValue() && selectedCurrency === 'PLN' && f.gross_value_currency_id && f.gross_value_currency_id !== currencyField) {
      sendDispatchRequest(api.claims.exchangeRate, { value: grossValue(), currency_id: f.gross_value_currency_id, is_nbp: true }, ({ data }) => {
        props.storeInput(props.formName, 'calculatedPurchase', data.calc_value);
        props.storeInput(props.formName, 'exchange_rate', data.average_rate);
      });
    }
    if(vatValue() && selectedCurrency === 'PLN' && f.vat_value_currency_id && f.vat_value_currency_id !== currencyField) {
      sendDispatchRequest(api.claims.exchangeRate, { value: vatValue(), currency_id: f.vat_value_currency_id, is_nbp: true }, ({ data }) => {
        props.storeInput(props.formName, 'calculatedPurchaseVat', data.calc_value);
      });
    }
    // eslint-disable-next-line
  }, [f.purchase_type, f.gross_value, f.purchase_custom_value, f.purchase_currency_id, f.gross_value_currency_id, f.vat_value_currency_id]);

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          {!props.isCreator ? (
            <>
              <div className="row mt-10">
                <div className="col-sm-6">
                  <div className="form-group row">
                    <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                      Data wykupu
                      <Required />
                    </label>
                    <div className="col-sm-6  d-flex align-items-center">
                      <Datepicker formName={props.formName} name="purchase_date" defaultValue={new Date()} />
                    </div>
                    {e.factor_id ? (
                      <div className="col-sm-6 offset-sm-6">
                        <div className="form-control-feedback small mt-1" style={{ color: 'red' }}>
                          {e.agreement_id[0]}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group row">
                    <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                      Produkt / Procedura
                    </label>
                    <div className="col-sm-6  d-flex align-items-center">
                      <span className="mr-3">
                        {f.agreement?.product?.name} / {f.agreement?.procedure?.name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-sm-6">
                  <div className="form-group row">
                    <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                      Klient
                      <Required />
                    </label>
                    <div className="col-sm-6  d-flex align-items-center">
                      <span className="mr-3" style={{ fontWeight: '600', color: e.agreement_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                        {f.client?.name ?? 'nie wybrano'}
                      </span>
                      <button
                        className="ml-auto"
                        type="button"
                        onClick={() => {
                          props.toggleForm('clients_for_agreements');
                          props.fetch('clients_for_agreements', {});
                        }}>
                        Wybierz
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group row">
                    <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                      Waluta
                    </label>
                    <div className="col-sm-6  d-flex align-items-center">
                      <span className="mr-3">{f.agreement?.currency?.name}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-sm-6">
                  <div className="form-group row">
                    <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                      Umowa
                      <Required />
                    </label>
                    <div className="col-sm-6  d-flex align-items-center">
                      <span className="mr-3" style={{ fontWeight: '600', color: e.agreement_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                        {f.agreement?.identifier ?? 'nie wybrano'}
                      </span>
                      <button
                        className="ml-auto"
                        disabled={!f?.client?.name}
                        type="button"
                        onClick={() => {
                          props.toggleForm('agreements');
                          props.fetch('clients_with_agreements', { client_id: f.client?.id ?? 0 });
                        }}>
                        Wybierz
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group row">
                    <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                      Limit / dostępny
                    </label>
                    <div className="col-sm-6  d-flex align-items-center">
                      <span className="mr-3">{parseMoney(f.agreement?.limit_amount)} / -</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <div className="form-group row">
                    <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                      Dłużnik
                      <Required />
                    </label>
                    <div className="col-sm-6  d-flex align-items-center">
                      <span className="mr-3" style={{ fontWeight: '600', color: e.debtor_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                        {f.debtor?.name ?? 'nie wybrano'}
                      </span>
                      <button
                        className="ml-auto"
                        type="button"
                        disabled={!f?.agreement}
                        onClick={() => {
                          props.toggleForm('debtors');
                        }}>
                        Wybierz
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group row">
                    <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                      Sublimit / dostępny
                    </label>
                    <div className="col-sm-6  d-flex align-items-center">
                      <span className="mr-3">
                        {parseMoney(f?.debtor?.available_sublimit)}
                        {' / '}
                        {parseMoney(f?.debtor?.used_sublimit)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-sm-6">
                  <div className="form-group row">
                    <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                      Administrator operacyjny
                    </label>
                    <div className="col-sm-6  d-flex align-items-center">
                      <span className="mr-3" style={{ fontWeight: '600', color: e.agreement_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                        {f.agreement?.operational_administrator?.name ?? 'nie wybrano'}
                      </span>
                    </div>
                    {/*{e.operational_administrator_id ? (*/}
                    {/*  <div className="col-sm-6 offset-sm-6">*/}
                    {/*    <div className="form-control-feedback small mt-1" style={{ color: 'red' }}>*/}
                    {/*      {e.operational_administrator_id[0]}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*) : null}*/}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group row">
                    <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                      Status umowy
                    </label>
                    <div className="col-sm-6  d-flex align-items-center">
                      <span className="mr-3">{props?.agreementData?.agreement_statuses?.[f.agreement?.status]}</span>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
            </>
          ) : null}

          <div css={[!props.isCreator && tw`bg-grey-F`]} className='py-05'>
            <div className="row align-items-center">
              <div className="col-sm-4">
                <div className="form-group row">
                  <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                    Numer dokumentu
                    <Required />
                  </label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <Input name="document_no" formName={props.formName} />
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group row">
                  <label htmlFor="example-text-input" className="col-sm-7 label-right col-form-label">
                    Data dokumentu
                    <Required />
                  </label>
                  <div className="col-sm-5 d-flex align-items-center">
                    <Datepicker formName={props.formName} name="document_date" />
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group row">
                  <label htmlFor="example-text-input" className="col-sm-7 label-right col-form-label">
                    Termin wymagalności
                    <Required />
                  </label>
                  <div className="col-sm-5 d-flex align-items-center">
                    <Datepicker formName={props.formName} name="due_date" offset="-80px, 5px" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-6">
                <div className="form-group row">
                  <label htmlFor="example-text-input" className="col-sm-4 label-right col-form-label">
                    Kwota netto<Required />
                  </label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <Input name="net_value" formName={props.formName} divClassName="pr-2" type='number' />
                    <SelectButton
                      buttonOnClick={() => props.clear(props.formName, 'net_value_currency_id')}
                      formName={props.formName}
                      options={props.currencies?.map(r => ({ ...r, value: r.id })) ?? []}
                      name="net_value_currency_id"
                      number
                      // selectOnChange={(v) => v !== '' && v !== 1 ? props.recalculate(value(), f.gross_value_currency_id) : undefined}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group row d-flex align-items-center">
                  <label htmlFor="example-text-input" className="col-sm-3 label-right col-form-label grey">
                    Kwota VAT<Required />
                  </label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <Input name="vat_value" formName={props.formName} divClassName="pr-2" type='number' />
                    <SelectButton
                      buttonOnClick={() => props.clear(props.formName, 'vat_value_currency_id')}
                      formName={props.formName}
                      options={props.currencies?.map(r => ({ ...r, value: r.id })) ?? []}
                      name="vat_value_currency_id"
                      number
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p className='grey mt-05'>Parametry wykupu</p>

          <div css={[!props.isCreator && tw`bg-grey-F`]}>
            <div className="row border-b justify-around">
              <div className="form-group row col-sm-4 mb-0 items-center">
                <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                    Waluta wykupu<Required />
                </label>
                <div className="col-sm-6 d-flex align-items-center">
                  <SelectButton
                    buttonOnClick={() => props.clear(props.formName, 'purchase_currency_id')}
                    formName={props.formName}
                    options={props.currencies?.map(r => ({ ...r, value: r.id })) ?? []}
                    name="purchase_currency_id"
                    number
                    // selectOnChange={(v) => v === 1 ?s props.recalculate(value(), f.gross_value_currency_id) : undefined}
                  />
                </div>
              </div>
              <div className="form-group row col-sm-6 items-center">
                <label htmlFor="example-text-input" className="col-sm-4 label-right col-form-label">
                  Kurs {netCurrency}/{selectedCurrency}
                  <Required />
                </label>
                <div className="col-sm-4 d-flex align-items-center">
                  <Input defaultValue="1.0000" name="exchange_rate" formName={props.formName} type='number' />
                </div>
                {e.factor_id ? (
                  <div className="col-sm-6 offset-sm-6">
                    <div className="form-control-feedback small mt-1" style={{ color: 'red' }}>
                      {e.agreement_id[0]}
                    </div>
                  </div>
                ) : null}
                <div className="col-sm-4 mt-2 mb-1 d-flex align-items-center">
                  <div className="form-group row d-flex align-items-center">
                    <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                      MPP
                    </label>
                    <div className="col-sm-6">
                      <BooleanCheck field={f.mpp} onClick={() => props.storeInput(props.formName, 'mpp', !f.mpp)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row col-sm-5">
              <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                Kwota wykupu
                <Required />
              </label>
              <div className="col-sm-6 d-flex align-items-center">
                <BooleanCheck
                  className="ml-3 mr-2"
                  field={f?.purchase_type === PURCHASE_TYPE.NETTO}
                  asRadioButton
                  onClick={() => {
                    props.storeInput(props.formName, 'purchase_type', PURCHASE_TYPE.NETTO);
                    props.clear(props.formName, 'purchase_custom_value');
                    props.clear(props.formName, 'purchase_custom_vat_value');
                  }}
                />
                <span> netto</span>
                <BooleanCheck
                  className="ml-5 mr-2"
                  field={f?.purchase_type === PURCHASE_TYPE.BRUTTO}
                  asRadioButton
                  onClick={() => {
                    props.storeInput(props.formName, 'purchase_type', PURCHASE_TYPE.BRUTTO);
                    props.clear(props.formName, 'purchase_custom_value');
                    props.clear(props.formName, 'purchase_custom_vat_value');
                  }}
                />
                <span> brutto</span>
                <BooleanCheck
                  className="ml-5 mr-2"
                  field={f?.purchase_type === PURCHASE_TYPE.CUSTOM}
                  asRadioButton
                  onClick={() => {
                    props.storeInput(props.formName, 'purchase_type', PURCHASE_TYPE.CUSTOM);
                    props.clear(props.formName, 'purchase_custom_vat_value');
                    props.clear(props.formName, 'purchase_custom_vat_value_currency_id');
                  }}
                />
                <span className="">własna</span>
              </div>
              {e.factor_id ? (
                <div className="col-sm-6 offset-sm-6">
                  <div className="form-control-feedback small mt-1" style={{ color: 'red' }}>
                    {e.agreement_id[0]}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="row justify-end">
              <div className="col-sm-8 flex items-center">
                <div className="form-group row d-flex align-items-center justify-end">
                  <span className="grey mr-2 mb-10">NETTO</span>
                  <div className="col-sm-3 d-flex align-items-center">
                    <Input
                      divClassName="pr-2"
                      name="purchase_custom_value"
                      formName={props.formName}
                      disabled={f?.purchase_type === PURCHASE_TYPE.BRUTTO || f?.purchase_type === PURCHASE_TYPE.NETTO}
                      type='number'
                    />
                    <span className="ml-3 mb-10">PLN</span>
                  </div>

                  <span className="col-sm-1 grey d-flex justify-content-end mb-10">
                    VAT
                  </span>
                  <div className="col-sm-4 d-flex align-items-center">
                    <Input
                      divClassName="pr-2"
                      name="purchase_custom_vat_value"
                      formName={props.formName}
                      disabled={f?.purchase_type === PURCHASE_TYPE.BRUTTO || f?.purchase_type === PURCHASE_TYPE.NETTO}
                      type='number'
                    />
                    {f?.purchase_type !== PURCHASE_TYPE.BRUTTO && f?.purchase_type !== PURCHASE_TYPE.NETTO ? (
                      <SelectButton
                        selectDisabled={f?.purchase_type === PURCHASE_TYPE.BRUTTO || f?.purchase_type === PURCHASE_TYPE.NETTO || f?.purchase_type === PURCHASE_TYPE.CUSTOM}
                        buttonOnClick={() => props.clear(props.formName, 'purchase_custom_vat_value_currency_id')}
                        formName={props.formName}
                        options={props.currencies?.map(r => ({ ...r, value: r.id })) ?? []}
                        name="purchase_custom_vat_value_currency_id"
                        number
                      />
                    ) : <div className="d-flex align-items-center min-w-70"/>}
                  </div>
                </div>
              </div>
              <div className="col-sm-4 items-center">
                <div className="row align-items-center">
                  <div className="form-group row">
                    <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                      Wykup netto <br />Wykup VAT
                      <br />
                      Wykup brutto
                    </label>
                    <div className="col-sm-6 d-flex align-items-center text-right pl-0">
                      {parseMoney(f.calculatedPurchase || NumberInput(netValue()))} {selectedCurrency}
                      <br />
                      {parseMoney(f.calculatedPurchaseVat || NumberInput(vatValue()))} {selectedVatCurrency}
                      <br />
                      {parseMoney(f.calculatedPurchase || (NumberInput(netValue()) + NumberInput(vatValue())))} {f?.purchase_currency?.name ?? f?.purchase_currency_id_select?.name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <>
            <hr />
            <div className="row ">
              <div className="col-sm-6">
                <div className="form-group row">
                  <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                    Opiekun ds. Handlowych
                    <Required />
                  </label>
                  <div className="col-sm-6  d-flex align-items-center">
                    <span className="mr-3" style={{ fontWeight: '600', color: e.trade_supervisor_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                      {f.trade_supervisor ? f.trade_supervisor.full_name || f.trade_supervisor.name : 'brak'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-sm-6">
                <div className="form-group row">
                  <label htmlFor="example-text-input" className="col-sm-6 label-right col-form-label">
                    Opiekun ds. rozliczeń
                    <Required />
                  </label>
                  <div className="col-sm-6  d-flex align-items-center">
                    <span className="mr-3" style={{ fontWeight: '600', color: e.billing_manager_id ? 'red' : '#4B4E55', fontSize: '14px' }}>
                      {f.billing_manager ? f.billing_manager.full_name || f.billing_manager.name : 'brak'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>

          {props.withButtons ? (
            <Buttons
              className='mb-10'
              formName={props.formName}
              onCancel={props.onCancel}
              onSubmit={props.submitForm}
              onSubmitAndLeave={props.onSubmitAndLeave}
              submitButton={props.editMode ? 'Zapisz' : 'Utwórz wierzytelność'}
              submitButtonClassNames="btn btn-primary btn-primary-blue ml-2 font-weight-light"
            />
          ) : null}

          <Selectable
            filtersForm={filters.agreements}
            fetch={() => props.fetch('clients_with_agreements', { ...props.forms[filters.agreements], client_id: f.client?.id ?? 0 })}
            visible={props.isVisible('agreements')}
            formName={props.formName}
            field="agreement"
            columns={columns.agreements}
            options={filteredAgreements}
            onClickAway={() => props.toggleForm('agreements')}
            header="Wybierz umowę"
            onSelected={() => {
              props.storeInput(props.formName, 'debtors', f.agreement.debtors);
              props.storeInput(props.formName, 'agreement', f.agreement.agreement);
              props.storeInput(props.formName, 'trade_supervisor', f.agreement.trade_supervisor);
              props.storeInput(props.formName, 'billing_manager', f.agreement.billing_manager);
              props.storeInput(props.formName, 'purchase_currency_id', f.agreement.currency?.id);
            }}
          />
          <Selectable
            fetch={() => {}}
            visible={props.isVisible('debtors')}
            formName={props.formName}
            field="debtor"
            columns={columns.default}
            options={f.debtors}
            onClickAway={() => props.toggleForm('debtors')}
            header="Wybierz dłużnika"
          />
          <Selectable
            filtersForm={filters.clients_for_agreements}
            fetch={() => props.fetch('clients_for_agreements', {})}
            visible={props.isVisible('clients_for_agreements')}
            formName={props.formName}
            field="client"
            columns={columns.default}
            options={formData.clients_for_agreements}
            onClickAway={() => props.toggleForm('clients_for_agreements')}
            onSelected={() => {
              dispatch(storeInput(props.formName, 'debtors', null));
              dispatch(storeInput(props.formName, 'debtor', null));
              dispatch(storeInput(props.formName, 'agreement', null));
              dispatch(storeInput(props.formName, 'trade_supervisor', null));
              dispatch(storeInput(props.formName, 'billing_manager', null));
            }}
            header="Wybierz klienta"
          />
          <Selectable
            filtersForm={filters.trade_supervisors}
            fetch={() => props.fetch('trade_supervisors', {})}
            visible={props.isVisible('trade_supervisor')}
            formName={props.formName}
            field="trade_supervisor"
            columns={columns.default}
            options={formData.trade_supervisors}
            onClickAway={() => props.toggleForm('trade_supervisor')}
            header="Wybierz opiekuna handlowego"
          />
          <Selectable
            filtersForm={filters.billing_managers}
            fetch={() => props.fetch('billing_managers', {})}
            visible={props.isVisible('billing_manager')}
            formName={props.formName}
            field="billing_manager"
            columns={columns.default}
            options={formData.billing_managers}
            onClickAway={() => props.toggleForm('billing_manager')}
            header="Wybierz opiekuna ds. rozliczeń"
          />
        </>
      ) : null}
    </Form>
  );
};
