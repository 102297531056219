import moment from 'moment';
import React from 'react';

import {
  Form, Select, Textarea
} from '../../../components/Forms';
import Tooltip from 'components/Layout/Tooltip';
import { useSelector } from 'react-redux';

export default (props) => {
  const { user } = useSelector(state => state.Authorization);
  const proposal = props.proposal || {};

  const decisions = props.proposalData && props.proposalData.rating_decisions ? Object.keys(props.proposalData.rating_decisions).map((k) => ({ value: Number(k), name: props.proposalData.rating_decisions[k] })) : [];
  const userRoles = props.roles ? props.roles.map(role => ({ value: role.id, name: role.name })) : [];

  const stageId = props.stage === 'I' ? 1 : 2;

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row bb bt">
            <div className="flex-20 p-2 br grey">Oceniający</div>
            <div className="flex-20 p-2 br grey">
              Etap
              {' '}
              {props.stage}
            </div>
            <div className="flex-15 p-2 br grey">Data</div>
            <div className="flex-15 p-2 br grey">Odstępstwa</div>
            <div className="flex-20 p-2 br grey">Nr wniosku</div>
            <div className="flex-30 p-2 br grey">Klient</div>
            <div className="flex-20 p-2 grey">Decyzja kredytowa</div>
          </div>
          <div className="row bb">
            <div className="flex-20 p-2 br ">{user?.full_name ?? 'brak'}</div>
            <div className="flex-20 p-2 br "><Select formName={props.formName} options={userRoles} name={`credit_decision_role_${stageId}`} number /></div>
            <div className="flex-15 p-2 br ">{moment().format('DD.MM.YYYY')}</div>
            <div className="flex-15 p-2 br "><Tooltip label='x' tip='Brak implementacji' /></div>
            <div className="flex-20 p-2 br "><Tooltip label={proposal.identifier} /></div>
            <div className="flex-30 p-2 br ">{proposal.client?.name ?? '-'}</div>
            <div className="flex-20 p-2 ">
              <Select formName={props.formName} options={decisions} name={`credit_decision_${stageId}`} number />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 p-2 ">
              <Textarea formName={props.formName} name={`credit_decision_comment_${stageId}`} placeholder="Komentarz.." />

            </div>
          </div>

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancelButtonClick}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}
                >
                  Anuluj
                </button>
                <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
