import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useEffect } from 'react';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import { formatValueToEurope, getGroupOfErrors } from '../../../../utilities/helpers';
import FinancialDataForm from '../../Forms/FinancialData';
import FormActions from 'actions/forms';
import { useDispatch } from 'react-redux';

export default props => {
  const dispatch = useDispatch();
  const formName = 'FinancialDataCreatorForm';
  const modalFormName = 'FinancialDataCreatorEditForm';

  useEffect(() => {
    if (props.populateForm) {
      props.populateForm(formName);
    }
  // eslint-disable-next-linecontainers/Proposals/Forms/Representation.jsx:37
  }, props);

  const canBeSubmitted =
    props.forms[formName] &&
    props.forms[formName].income &&
    props.forms[formName].year &&
    props.forms[formName].months_no &&
    props.forms[formName].revenue;

  const rows = props.version && props.version.financial_data ? props.version.financial_data : [];

  const handleBackLightFields = () => {
    dispatch(FormActions.handleValidErrors(formName, ['income', 'year', 'months_no', 'revenue']));
  };

  return (
    <Card mode='creator' header='+ Dane finansowe' onSaveClick={() => props.saveForm(formName, true)} isBlocked={!canBeSubmitted} onBlockedMessage={handleBackLightFields}>
      <FinancialDataForm
        populate={() => props.populateForm(formName)}
        forms={props.forms}
        currencies={props.currencies}
        preloadedForm
        visible
        visibleOnMount
        clear={props.clear}
        formName={formName}
        submitForm={() => props.saveForm(formName)}
        onCancelButtonClick={() => props.toggleForm(formName)}
      />

      {rows.length ? (
        <div className="row bt">
          <div className="col-sm-12">
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Rok</div>
              <div className="col-sm-3 p-2 br grey">Ilość miesięcy</div>
              <div className="col-sm-3 p-2 br grey">Przychod</div>
              <div className="col-sm-3 p-2 grey">Dochód (-strata)</div>
            </div>
            {rows.map(row => (
              <RowEditable
                key={row.identifier}
                onEdit={() => {
                  props.edit(row, modalFormName);
                }}
                onRemove={() => {
                  props.remove('financial_data', row.id);
                }}
              >
                <div className="col-sm-3 p-2 br ">{row.year}</div>
                <div className="col-sm-3 p-2 br ">{row.months_no}</div>
                <div className="col-sm-3 p-2 br ">{formatValueToEurope(row.income)}</div>
                <div className="col-sm-3 p-2 ">{formatValueToEurope(row.revenue)}</div>
              </RowEditable>
            ))}
          </div>
        </div>
      ) : null}

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Dane finansowe</span>
          </div>
        }
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}>
        <FinancialDataForm
          withButtons
          populate={() => props.populateForm(modalFormName)}
          forms={props.forms}
          currencies={props.currencies}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(modalFormName)}
          formName={modalFormName}
          submitForm={() => props.saveForm(modalFormName)}
          onCancelButtonClick={() => props.toggleForm(modalFormName)}
        />
      </BaseModal>
    </Card>
  );
};
