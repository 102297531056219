import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useState } from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { parsePhone } from '../../../../utilities/helpers';
import { ContactsForm } from '../../Forms/Contacts';
import RemovalModal from '../../Forms/RemovalModal';

export const Contacts = (props) => {
  const [objectToRemove, setObjectToRemove] = useState({});

  const formName = 'ContactForm';
  const modalSelectedType = props.forms[formName] ? props.forms[formName].type : null;

  const contacts = props.company && props.company.contacts ? props.company.contacts : [];

  const phones = contacts.filter((i) => i.type === 'phone');
  const emails = contacts.filter((i) => i.type === 'email');

  const skype = contacts.find((i) => i.type === 'skype');
  const wechat = contacts.find((i) => i.type === 'weechat');

  const allowedContactTypes = ['email', 'phone'];

  if (!skype) allowedContactTypes.push('skype');
  if (!wechat) allowedContactTypes.push('weechat');

  const contactTypes = props.contactTypes ? props.contactTypes.filter((i) => allowedContactTypes.indexOf(i.value) !== -1) : [];

  const std = (t) => ['phone', 'email'].includes(t);

  return (
    <Card header='Kontakty' onAddClick={() => props.create(formName)}>
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row bb grey">
              <div className="col-sm-4 p-2 br">
                <i className="fa fa-phone mr-2" />
                  Telefon
              </div>
              <div className="col-sm-2 p-2 br">Wprowadził</div>
              <div className="col-sm-2 p-2 br">Deaktywował</div>
              <div className="col-sm-2 p-2 br">Ważny do</div>
              <div className="col-sm-2 p-2">Aktywny</div>
            </div>

            {phones.map((p) => (
              <RowEditable isObjectToRemove key={p.id} itemId={p.id} onEdit={() => props.edit(formName, p)} onRemove={() => setObjectToRemove(p)}>
                <div className="col-sm-4 p-2 br">{parsePhone(p.value)}</div>
                <div className="col-sm-2 p-2 br">{p.user_creator ? p.user_creator.full_name : '-'}</div>
                <div className="col-sm-2 p-2 br">{p.user_deactivator ? p.user_deactivator.full_name : '-'}</div>
                <div className="col-sm-2 p-2 br">{p.valid_until_date || '-'}</div>
                <div className="col-sm-2 p-2">
                  <BooleanCheck field={p.is_active} />

                </div>
              </RowEditable>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row bb grey">
              <div className="col-sm-4 p-2 br">
                <i className="fa fa-envelope mr-2" />
                  Email
              </div>
              <div className="col-sm-2 p-2 br">Wprowadził</div>
              <div className="col-sm-2 p-2 br">Deaktywował</div>
              <div className="col-sm-2 p-2 br">Ważny do</div>
              <div className="col-sm-2 p-2">Aktywny</div>
            </div>
            {emails.map((p) => (
              <RowEditable isObjectToRemove key={p.id} itemId={p.id} onEdit={() => props.edit(formName, p)} onRemove={() => setObjectToRemove(p)}>
                <div className="col-sm-4 p-2 br">{p.value}</div>
                <div className="col-sm-2 p-2 br">{p.user_creator ? p.user_creator.full_name : '-'}</div>
                <div className="col-sm-2 p-2 br">{p.user_deactivator ? p.user_deactivator.full_name : '-'}</div>
                <div className="col-sm-2 p-2 br">{p.valid_until_date || '-'}</div>
                <div className="col-sm-2 p-2">
                  <BooleanCheck field={p.is_active} />

                </div>
              </RowEditable>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row bb grey">
              <div className="col-sm-4 p-2 br">
                <i className="fa fa-users mr-2" />
                Inne
              </div>
            </div>
            <RowEditable
              isObjectToRemove
              onEdit={skype ? () => props.edit(formName, skype) : null}
              onRemove={skype ? () => setObjectToRemove(skype) : null}
            >
              <div className="col-sm-4 p-2 br grey">
                <i className="fas fa-link mr-3" />
                Skype
              </div>
              <div className="col-sm-8 p-2">{skype ? skype.value : null}</div>
            </RowEditable>
            <RowEditable
              isObjectToRemove
              onEdit={wechat ? () => props.edit(formName, wechat) : null}
              onRemove={wechat ? () => setObjectToRemove(wechat) : null}
            >
              <div className="col-sm-4 p-2 br grey">
                <i className="fas fa-link mr-3" />
                WeeChat
              </div>
              <div className="col-sm-8 p-2">{wechat ? wechat.value : null}</div>
            </RowEditable>
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Kontakty</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <ContactsForm
          countryCallingCodes={props.countryCallingCodes}
          visibleOnMount={false}
          clear={props.clear}
          visible={props.formVisible(formName)}
          formName={formName}
          contactTypes={['skype', 'weechat'].includes(modalSelectedType) ? props.contactTypes : contactTypes}
          selectButtonDisabled
          selectButtonValue={modalSelectedType}
          typeSelected={modalSelectedType}
          withButtons
          submitForm={() => props.saveForm(formName)}
          onCancelButtonClick={() => props.toggleForm(formName)}
          canBeActive={modalSelectedType ? !['skype', 'weechat'].includes(modalSelectedType) : true}
        />
      </BaseModal>

      <RemovalModal
        visible={Object.keys(objectToRemove).length > 0}
        toggle={() => setObjectToRemove({})}
        remove={() => {
          props.remove(objectToRemove.id);
          setObjectToRemove({});
        }}
      >
        {objectToRemove && (
          <div className="container-fluid">
            <div className="row bb bt grey">
              <div className="col-sm-4 p-2 br">
                <i className="fa fa-envelope mr-2" />
                Kontakt
              </div>
              <div className={`${std(objectToRemove.type) ? 'col-sm-2 br' : 'col-sm-8'} p-2`}>Wprowadził</div>
              {std(objectToRemove.type) ? (
                <>
                  <div className="col-sm-2 p-2 br">Deaktywował</div>
                  <div className="col-sm-2 p-2 br">Ważny do</div>
                  <div className="col-sm-2 p-2">Aktywny</div>
                </>
              ) : null}
            </div>
            <div className="row bb">
              <div className="col-sm-4 p-2 br">{objectToRemove.value}</div>
              <div className={`${std(objectToRemove.type) ? 'col-sm-2 br' : 'col-sm-8'} p-2`}>
                {objectToRemove.user_creator ? objectToRemove.user_creator.full_name : 'brak'}
              </div>
              {std(objectToRemove.type) ? (
                <>
                  <div className="col-sm-2 p-2 br">
                    {objectToRemove.user_deactivator ? objectToRemove.user_deactivator.full_name : '-'}
                  </div>
                  <div className="col-sm-2 p-2 br">{objectToRemove.valid_until_date || '-'}</div>
                  <div className="col-sm-2 p-2">
                    <BooleanCheck field={objectToRemove.is_active} />

                  </div>
                </>
              ) : null}
            </div>
          </div>
        )}
      </RemovalModal>
    </Card>
  );
};
