import React from 'react';

import { Checkbox, Form, Input, Select } from '../../../../components/Forms';
import { RowEditable } from '../../../../components/Layout/RowEditable';

const DocumentsForm = props => {
  const dtbTypes = props.offerData && props.offerData.dtb_types ? props.offerData.dtb_types.map(i => ({ ...i, value: i.id })) : [];
  const dtbForms = props.offerData && props.offerData.dtb_forms ? props.offerData.dtb_forms.map(i => ({ ...i, value: i.id })) : [];

  const f = props.forms && props.forms[props.formName] ? props.forms[props.formName] : {};

  const formalExpectations =
    props.proposalData && props.proposalData.formal_expectations_data
      ? props.proposalData.formal_expectations_data.map(i => ({ ...i, value: i.id }))
      : [];

  return (
    <Form asDiv noErrors visibleOnMount className="form-horizontal" formName={props.formName}>
      <div className="row mt-3">
        <div className="col-sm-2 grey">Nazwa dokumentu</div>
        <div className="col-sm-2 grey">Forma dokumentu</div>
        <div className="col-sm-4 grey">Wymagania formalne</div>
        <div className="col-sm-1 grey">Wzór faktora</div>
        <div className="col-sm-3 grey">Komentarz</div>
      </div>
      {f.documentstobuy
        ? f.documentstobuy.map((d, k) => (
          <RowEditable className="row mb-1" key={d.id} onRemove={() => props.removeField(`documentstobuy.${k}`, props.formName)}>
            <div className="col-sm-2 grey">
              <Select
                disabled={props.selectDisabled || false}
                formName={props.formName}
                options={dtbTypes}
                name={`documentstobuy.${k}.dtb_type_id`}
              />
            </div>
            <div className="col-sm-2 grey">
              <Select
                disabled={props.selectDisabled || false}
                formName={props.formName}
                options={dtbForms}
                name={`documentstobuy.${k}.dtb_form_id`}
              />
            </div>
            <div className="col-sm-4 grey">
              <Select
                disabled={props.selectDisabled || false}
                formName={props.formName}
                options={formalExpectations}
                name={`documentstobuy.${k}.formal_expectation_id`}
              />
            </div>
            <div className="col-sm-1 grey">
              <Checkbox label=" " formName={props.formName} name={`documentstobuy.${k}.is_factor_pattern`} />
            </div>
            <div className="col-sm-3 grey">
              <Input formName={props.formName} type="text" name={`documentstobuy.${k}.comments`} />
            </div>
          </RowEditable>
        ))
        : null}
      {props.details ? null : (
        <button type="button" onClick={() => props.addField('documentstobuy', props.formName)} className="btn btn-sm">
          + Dodaj
        </button>
      )}
      {props.withButtons ? (
        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button
              type="button"
              onClick={props.onCancelButtonClick}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}>
              Anuluj
            </button>
            <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
              Zapisz
            </button>
          </div>
        </div>
      ) : null}
    </Form>
  );
};
export default DocumentsForm;
