import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import FormActions from '../actions/forms';

export const ModifiedFromFieldName = 'modified_date';

class OldListFilters extends React.Component {
  onBtnClick(type) {
    const modified = this.getCurrentModifiedFromValue();
    const value = this.getFormattedValueForButtonType(type);

    if (value === modified) {
      this.props.dispatch(FormActions.deleteField(this.props.formName, ModifiedFromFieldName));
    } else {
      this.props.dispatch(FormActions.storeInput(this.props.formName, ModifiedFromFieldName, value));
    }

    this.props.onSubmit();
  }

  getFormattedValueForButtonType = type =>
    moment()
      .subtract(1, type)
      .format('DD.MM.YYYY');

  getBtnClassNames(type) {
    const active = this.getCurrentModifiedFromValue() === this.getFormattedValueForButtonType(type) ? 'active' : '';
    return `btn btn-sm btn-outline-secondary pl-3 pr-3 mr-2 ${active}`;
  }

  getCurrentModifiedFromValue() {
    const form = this.props.forms.forms[this.props.formName] || {};
    return form[ModifiedFromFieldName] || null;
  }

  render() {
    const selectDisabled = !this.props.options || this.props.options.length === 0;

    return (
      <div className="row align-items-center">
        <div className="col-sm-4 form-group mb-0">
          <select className="form-control" disabled={selectDisabled}>
            {this.props.options ? this.props.options.map(o => <option value={o.value}>{o.name}</option>) : null}
          </select>
        </div>
        <div className="col-sm-8 text-center">
          <button type="button" className={this.getBtnClassNames('days')} onClick={() => this.onBtnClick('days')}>
            Dzień
          </button>
          <button type="button" className={this.getBtnClassNames('weeks')} onClick={() => this.onBtnClick('weeks')}>
            Tydzień
          </button>
          <button type="button" className={this.getBtnClassNames('months')} onClick={() => this.onBtnClick('months')}>
            Miesiąc
          </button>
          <button type="button" className={this.getBtnClassNames('years')} onClick={() => this.onBtnClick('years')}>
            Rok
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  forms: state.Forms
});

export default connect(
  mapStateToProps,
  null
)(OldListFilters);
