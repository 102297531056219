import React from 'react';
import { TextLink } from 'containers/Companies/Details/Components/Notes';

export const SidebarNotes = (props) => {
  const name = props.offer ? props.offer.identifier : 'brak';

  const notes = props.offer && props.offer.notes
    ? props.offer.notes.map((n) => ({
      date: n.created_date,
      avatar: n.user_creator ? n.user_creator.avatar_url : '',
      creator: n.user_creator ? n.user_creator.full_name : 'brak',
      comment: n.comment,
      name: n.name,
      id: n.id
    }))
    : [];

  return (
    <div>
      {notes.length === 0 && <span>Brak notatek.</span>}
      {notes.map((note) => (
        <div className="row mb-3" key={note.id}>
          <div className="col-sm-2 text-center">
            <img src={note.avatar} className="img image rounded-circle" style={{ width: '40%' }} alt="" />
            <div className="grey" style={{ fontSize: '80%' }}>
              <span className="d-block mt-2">Notatka</span>
              <span>{note.date}</span>
            </div>
          </div>
          <div className="col-sm-10">
            <a
              role="button"
              style={{ color: 'black' }}
              className="collapsed"
              data-toggle="collapse"
              href={`#notatka${note.id}`}
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <i className="fa fa-sort-down mr-2" />
              <span className="text-themecolor">{note.creator}</span>
              {' '}
              dodał notatkę dla
              {' '}
              <span className="text-themecolor">{name}</span>
            </a>

            <div className="collapse show" id={`notatka${note.id}`} style={{ color: 'grey' }}>
              <p className="small">
                13.03.2019 | Temat:
                {note.name}
              </p>
              <TextLink>{note.comment}</TextLink>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
