import CompanyActions from 'actions/companies';
import Loader from 'components/Loader';
import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import CommonActions from '../../../actions/common';
import FormActions from '../../../actions/forms';
import OfferActions from '../../../actions/offer';
import ProposalActions from '../../../actions/proposal';
import { Buttons } from '../../../components/Forms';
import { Container, MainContainer } from '../../../components/Layout';
import Constants from '../../../utilities/constants';
import Tabs from '../Tabs';
import ClientAssessment from './Tabs/ClientAssessment';
import Debtors from './Tabs/Debtors';
import DebtorsRisk from './Tabs/DebtorsRisk';
import Misc from './Tabs/Misc';

class Index extends React.Component {
  steps = [
    { id: 1, name: 'Ocena klienta' },
    { id: 2, name: 'Warunki handlowe' },
    { id: 3, name: 'Ocena dłużników' },
    { id: 4, name: 'Pozostałe' },
    { id: 5, name: 'Dokumenty' }
  ];

  constructor(props) {
    super(props);
    const { slug } = this.props.match.params;
    const id = slug ? slug.split('-').slice(-1)[0] : null;

    this.props.dispatch(ProposalActions.fetchDetails(id)).then(data => {
      this.props.dispatch(OfferActions.fetchOfferDetails(data.offer_id));
    });
    this.props.dispatch(CommonActions.changeView(Constants.Views.Creator));
  }

  componentDidMount() {
    if (this.props.factors.length === 0) {
      this.props.dispatch(ProposalActions.fetchFactors());
    }

    this.props.dispatch(CommonActions.setContextMenu());
  }

  componentWillUnmount() {
    this.props.dispatch(FormActions.clearAllForms());
  }

  getMainComponent() {
    switch (this.props.tab) {
      case 1:
        return (
          <ClientAssessment
            factors={this.props.factors}
            populateForm={(f, d) => this.props.dispatch(FormActions.initialize(f, { ...d }, {}))}
            forms={this.props.forms.forms}
            errors={this.props.forms.errors}
            toggleForm={(f) => this.toggleForm(f)}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            create={(f) => this.showForm(f, { calculationId: this.props.calculation.id })}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            edit={(obj, modal) => this.edit(obj, modal)}
            saveForm={(f, h) => this.save(f, h)}
            onCancel={(f) => this.toggleForm(f)}
            common={this.props.common}
            remove={(object, id) => this.removeFromVersion(object, id)}
            proposal={this.props.proposal}
            version={this.props.version}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
          />
        );
      case 2:
        return (
          <Debtors
            debtors={this.props.debtors}
            populateForm={(f, d) => this.props.dispatch(FormActions.initialize(f, { ...d }, {}))}
            forms={this.props.forms.forms}
            errors={this.props.forms.errors}
            toggleForm={(f) => this.toggleForm(f)}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            create={(f) => this.showForm(f, { calculationId: this.props.calculation.id })}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            edit={(obj, modal) => this.edit(obj, modal)}
            saveForm={(f, h) => this.save(f, h)}
            onCancel={(f) => this.toggleForm(f)}
            common={this.props.common}
            remove={(object, id) => this.removeFromVersion(object, id)}
            proposal={this.props.proposal}
            version={this.props.version}
            fetch={(t, f) => this.props.dispatch(ProposalActions.fetchForForm(t, f))}
            formData={this.props.formData}
            addField={(field, form) => this.addField(field, form)}
            removeField={(field, form) => this.removeField(field, form)}
            offerData={this.props.common.offerData}
            proposalData={this.props.common.proposalData}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            getFlatRate={(formName, k) => {
              const t = this.props.proposal.transaction_risk_category ? this.props.proposal.transaction_risk_category.id : null;
              const m = this.props.forms.forms[formName] ? this.props.forms.forms[formName].margin_based_on_tran_risk : null;
              this.props.dispatch(FormActions.storeInput(formName, `basicbids_with_intrestmargins.${k}.transaction_risk_category_id`, t));
              this.props.dispatch(FormActions.storeInput(formName, `basicbids_with_intrestmargins.${k}.margin_based_on_tran_risk`, m));
              this.props.dispatch(OfferActions.getFlatRate(`${formName}.basicbids_with_intrestmargins.${k}`));
            }}
            getMultilevelFlatRate={(formName, k) => {
              const t = this.props.proposal.transaction_risk_category ? this.props.proposal.transaction_risk_category.id : null;
              this.props.dispatch(FormActions.storeInput(formName, `multilevelbids.${k}.transaction_risk_category_id`, t));
              this.props.dispatch(OfferActions.getMultilevelFlatRate(`${formName}.multilevelbids.${k}`));
            }}
            getIntrestRates={(formName, field) => this.props.dispatch(OfferActions.getIntrestRates(formName, field))}
            setLastAvailableDateOfRate={(formName, field) => this.props.dispatch(OfferActions.setLastAvailableDateOfRate(formName, field))}
            intrestRates={this.props.intrestRates}
          />
        );
      case 3:
        return (
          <DebtorsRisk
            proposal={this.props.proposal}
            debtors={this.props.debtors}
            populateForm={(f, d) => this.props.dispatch(FormActions.initialize(f, { ...d }, {}))}
            forms={this.props.forms.forms}
            errors={this.props.forms.errors}
            toggleForm={(f) => this.toggleForm(f)}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            create={(f) => this.showForm(f, { calculationId: this.props.calculation.id })}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            edit={(obj, modal) => this.edit(obj, modal)}
            saveForm={(f, h) => this.save(f, h)}
            onCancel={(f) => this.toggleForm(f)}
            common={this.props.common}
            remove={(object, id) => this.removeFromVersion(object, id)}
            fetch={(t, f) => this.props.dispatch(ProposalActions.fetchForForm(t, f))}
            formData={this.props.formData}
            addField={(field, form) => this.addField(field, form)}
            removeField={(field, form) => this.removeField(field, form)}
            offerData={this.props.common.offerData}
            proposalData={this.props.common.proposalData}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
          />
        );
      case 4:
        return (
          <Misc
            offerData={this.props.common.offerData}
            factors={this.props.factors}
            populateForm={(f, d) => this.props.dispatch(FormActions.initialize(f, { ...d }, {}))}
            forms={this.props.forms.forms}
            errors={this.props.forms.errors}
            toggleForm={(f) => this.toggleForm(f)}
            formVisible={(f) => this.props.forms.options.visible.indexOf(f) !== -1}
            create={(f) => this.showForm(f, { calculationId: this.props.calculation.id })}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            edit={(obj, modal) => this.edit(obj, modal)}
            saveForm={(f, h) => this.save(f, h)}
            onCancel={(f) => this.toggleForm(f)}
            common={this.props.common}
            remove={(object, id) => this.removeFromVersion(object, id)}
            removeNote={(proposalId, id) => this.removeNote(proposalId, id)}
            proposal={this.props.proposal}
            version={this.props.version}
            storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
            objectTypes={[]}
            clearBeneficiaries={() => this.props.dispatch({ type: 'OFFER_CLEAR_BENEFICIARIES' })}
            fetchBeneficiaries={(f) => this.props.dispatch(OfferActions.fetchBeneficiaries(f))}
            beneficiaries={this.props.beneficiaries}
            types={this.props.common.commissions ? this.props.common.commissions.map((i) => ({ ...i, value: i.id })) : []}
            removeField={(field, form) => this.removeField(field, form)}
            fetchIssuers={(filtersForm) => this.props.dispatch(
              CompanyActions.fetch(this.props.forms?.forms?.[filtersForm], true)
            )}
            issuers={this.props.companies}
          />
        );
      default:
        return null;
    }
  }

  removeField(field, form) {
    const f = this.props.forms.forms[form] || {};
    const ff = field.split('.');
    const v = get(f, ff[0], []);

    v.splice(ff[1], 1);

    this.props.dispatch(FormActions.storeInput(form, ff[0], v));
  }

  addField(field, form) {
    const f = this.props.forms.forms[form] || {};
    const v = get(f, field, []);

    this.props.dispatch(FormActions.storeInput(form, `${field}.${v.length}`, {}));
  }

  create(name) {
    this.props.dispatch(FormActions.initialize(name, {}, {}));
  }

  edit(object, name) {
    this.props.dispatch(FormActions.initialize(name, object, {}));
    this.props.dispatch(FormActions.toggleForm(name));
  }

  toggleForm(formName) {
    this.props.dispatch(FormActions.toggleForm(formName));
  }

  removeFromVersion(object, id) {
    this.props.dispatch(ProposalActions.remove(this.props.proposal.id, object, id));
  }

  removeNote(proposalId, noteId) {
    this.props.dispatch(ProposalActions.removeNote(proposalId, noteId));
  }

  changeTab(tab) {
    this.props.dispatch(ProposalActions.changeTab(tab));
  }

  save(formName, hide = true) {
    this.props.dispatch(ProposalActions.saveForm(formName, this.props.proposal.id, this.props.version?.id, hide));
  }

  validateDebtors(tabNumber, callback) {
    if(tabNumber > 2) {
      const currentDebtors = this.props.proposal?.debtors;
      currentDebtors?.length ? callback(tabNumber) : toast.error('Wymagany jest przynajmniej jeden dłużnik.');
    } else {
      callback(tabNumber);
    }
  }

  handleChangeStep(tabNumber) {
    this.validateDebtors(tabNumber, () => {
      this.changeTab(tabNumber);
    });
  }

  runNextStep(tabNumber) {
    if(tabNumber === 5) {
      window.location.href = `/wnioski/${this.props.proposal.slug}`;
    } else {
      this.changeTab(tabNumber + 1);
    }
  }

  handleOnSubmit(tabNumber) {
    this.validateDebtors(tabNumber + 1, () => {
      this.runNextStep(tabNumber);
    });
  }

  render() {
    const isLoadingState = !this.props.proposal?.id || !this.props.version?.id;

    if(isLoadingState) {
      return <Loader />;
    }

    return (
      <Container className="mt-3">
        <MainContainer className='w-full max-w-1400 mx-auto'>
          <Tabs creator tab={this.props.tab} changeStep={tab => this.handleChangeStep(tab)} steps={this.steps} />

          {this.getMainComponent()}

          <Buttons
            className="mb-3"
            containerStyle={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center' }}
            submitButton={this.props.tab === 5 ? 'Zobacz wniosek' : 'Następny krok'}
            onSubmit={() => this.handleOnSubmit(this.props.tab)}
          />
        </MainContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  forms: state.Forms,
  factors: state.Proposal.factors,
  formData: state.Proposal.formData,
  proposal: state.Proposal.proposal,
  version: state.Proposal.version,
  common: state.Common,
  tab: state.Proposal.currentTab,
  intrestRates: state.Offer.intrestRates,
  beneficiaries: state.Offer.beneficiaries,
  companies: state.Company,
  user: state.Authorization.user
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Index)
);
