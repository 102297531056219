import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import { Attachments } from "./Components/Attachments";
import CommonActions from '../../../actions/common';
import CompanyActions from 'actions/companies';
import FormActions from '../../../actions/forms';
import PersonActions from '../../../actions/person';
import { Container, MainContainer } from '../../../components/Layout';
// import _ from "lodash";
import Loader from '../../../components/Loader';
import { SidebarDetails } from '../../../components/SidebarDetails';
import Constants from '../../../utilities/constants';
import { Addresses } from './Components/Addresses';
import { Commissions } from './Components/Commissions';
import { Consents } from './Components/Consents';
import { Contacts } from './Components/Contacts';
import { Header } from './Components/Header';
import { Metrics } from './Components/Metrics';
import { Roles } from './Components/Roles';
import { SocialMedia } from './Components/SocialMedia';
import LoaderBackdrop from 'components/Layout/LoaderBackdrop';
import { Notes } from 'containers/Companies/Details/Components/Notes';
import { optionsCountries } from 'utilities/helpers';

class Index extends React.Component {
  constructor(props) {
    super(props);
    const { slug } = this.props.match.params;
    const id = slug ? slug.split('-').slice(-1)[0] : null;
    this.props.dispatch(PersonActions.fetchPersonDetails(id));

    this.props.dispatch(CommonActions.changeView(Constants.Views.Details));

    this.companiesFiltersForm = 'CompanyFiltersForm';
  }

  componentDidMount() {
    this.props.dispatch(
      CommonActions.setContextMenu([
        { name: 'Lista osób', link: '/osoby' },
        {
          name: 'Dodaj',
          options: [
            { name: 'Dodaj adres osoby', onClick: () => this.showForm('AddressForm', { is_active: true }) },
            { name: 'Dodaj kontakt osoby', onClick: () => this.showForm('ContactForm', { is_active: true }) },
            { name: 'Dodaj zgodę RODO', onClick: () => this.showForm('ConsentForm', {}) }
          ]
        },
        { name: 'Metryka', onClick: () => this.showForm('PersonForm', this.props.person) },
        { name: 'Notatka', onClick: () => this.showForm('NotesForm', {}) },
        { name: 'Powiąż z firma', onClick: () => this.showForm('RolesForm', {}) },
        {
          name: 'Przeglądaj',
          options: [{ name: 'Przelewy wychodzące', link: '/przelewy?mode=wychodzace' }]
        },
        // { name: 'RODO', link: '', onClick: () => {}},
        { name: 'Prowizje', onClick: () => this.showForm('CommissionForm', {}) }
      ])
    );
  }

  componentWillUnmount() {
    this.props.dispatch(FormActions.clearAllForms());
  }

  save = formName => this.props.dispatch(PersonActions.saveForm(formName, this.props.person.id, true));

  showForm = (formName, object) => {
    this.props.dispatch(FormActions.initialize(formName, object, {}));
    this.props.dispatch(FormActions.toggleForm(formName));
  };

  remove = (o, object) => this.props.dispatch(PersonActions.removeObject(o.id, this.props.person.id, object));

  toggleForm = formName => this.props.dispatch(FormActions.toggleForm(formName));

  getCompanyFilters = () => {
    const currentFilters = this.props.forms.forms[this.companiesFiltersForm] || {};

    if (!currentFilters || Object.keys(currentFilters).length <= Object.keys(CommonActions.defaultFilters).length) {
      this.props.dispatch(FormActions.initialize(this.companiesFiltersForm, CommonActions.defaultFilters, {}));
    }

    return this.props.forms.forms[this.companiesFiltersForm];
  };

  fetchCompanies = () => {
    this.props.dispatch(CompanyActions.fetch(this.getCompanyFilters(), true));
  };

  render() {
    if (this.props.common.loading) {
      return <Loader />;
    }

    const { common } = this.props;

    const commission_types = common.commissions ? common.commissions.map(i => ({ ...i, value: i.id })) : [];
    const currencies = common.currencies ? common.currencies.map(i => ({ ...i, value: i.id })) : [];

    const roles = common.businessRoles ? common.businessRoles.map(i => ({ ...i, value: i.id })) : [];
    const systemRoles = common.systemRoles ? common.systemRoles.map(i => ({ ...i, value: i.id })) : [];

    const sources =
      common.consentData && common.consentData.consent_sources ? common.consentData.consent_sources.map(i => ({ value: i.id, name: i.name })) : [];
    const templates =
      common.consentData && common.consentData.consent_templates
        ? common.consentData.consent_templates.map(i => ({ value: i.id, name: i.text }))
        : [];
    const administrators = common.consentData ? common.consentData.consent_administrators : [];
    const representators = common.consentData ? common.consentData.consent_representators : [];
    const inspectors = common.consentData ? common.consentData.consent_data_inspectors : [];

    const addressTypes = common?.commonData?.person_address_types ?
      Object.entries(common?.commonData?.person_address_types)?.map(c => ({ value: String(c[0]), name: c[1] })) : [];
    const voivodeships = common?.commonData?.voivodeships ?
      Object.entries(common?.commonData?.voivodeships)?.map(c => ({ value: String(c[0]), name: c[1] })) : [];
    const contactTypes = this.props.common.contactTypes
      ? Object.entries(this.props.common.contactTypes).map(c => ({ value: String(c[0]), name: c[1] })) : [];

    const commissionCategories = [{ value: '0', name: 'Rola handlowa' }, { value: '1', name: 'Rola rozliczeniowa' }];
    const countryCallingCodes = optionsCountries(this.props.common.countryCallingCodes);

    return (
      <Container className="mt-3">
        <LoaderBackdrop />

        <MainContainer>
          <Header person={this.props.person} />

          {/* <SectionNavigation /> */}
          <Metrics
            person={this.props.person}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            edit={f => this.showForm(f, this.props.person)}
            saveForm={f => this.save(f)}
            onCancel={f => this.toggleForm(f)}
            toggleForm={this.toggleForm}
          />
          <Roles
            person={this.props.person}
            forms={this.props.forms.forms}
            errors={this.props.forms.errors}
            toggleForm={this.toggleForm}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            create={f => this.showForm(f, {})}
            saveForm={f => this.props.dispatch(PersonActions.addBusinessRole(f, 1))}
            onCancel={f => this.toggleForm(f)}
            remove={(role, company) => this.props.dispatch(PersonActions.removeBusinessRole(role, company))}
            companies={this.props.companies}
            roles={roles}
            systemRoles={systemRoles}
            fetchCompanies={this.fetchCompanies}
            filtersForm={this.companiesFiltersForm}
          />
          <Addresses
            person={this.props.person}
            forms={this.props.forms.forms}
            toggleForm={this.toggleForm}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            create={f => this.showForm(f, { is_active: true })}
            saveForm={f => this.save(f)}
            onCancel={f => this.toggleForm(f)}
            edit={(f, o) => this.showForm(f, o)}
            remove={objectId => this.props.dispatch(PersonActions.removeObject(objectId, this.props.person.id, 'addresses'))}
            toggleStatus={addressId => this.toggleStatus(addressId, 'addresses')}
            addressTypes={addressTypes}
            voivodeships={voivodeships}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            populate={(form, address) => {
              const original = this.props.forms.forms[form] || {};
              this.props.dispatch(FormActions.initialize(form, Object.assign(original, address), {}));
            }}
          />
          <Contacts
            countryCallingCodes={countryCallingCodes}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            contactTypes={contactTypes}
            person={this.props.person}
            forms={this.props.forms.forms}
            toggleForm={this.toggleForm}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            create={f => this.showForm(f, { is_active: true })}
            saveForm={f => this.save(f)}
            onCancel={f => this.toggleForm(f)}
            edit={(f, o) => this.showForm(f, { ...o, country_calling_code_id: o.country_calling_code?.id })}
            remove={objectId => this.props.dispatch(PersonActions.removeObject(objectId, this.props.person.id, 'contacts'))}
          />
          <SocialMedia
            contactTypes={contactTypes}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            person={this.props.person}
            forms={this.props.forms.forms}
            toggleForm={this.toggleForm}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            create={f => this.showForm(f, {})}
            saveForm={f => this.save(f)}
            onCancel={f => this.toggleForm(f)}
            edit={(f, o) => this.showForm(f, o)}
            remove={objectId => this.props.dispatch(PersonActions.removeObject(objectId, this.props.person.id, 'contacts'))}
          />
          <Consents
            person={this.props.person}
            forms={this.props.forms.forms}
            toggleForm={this.toggleForm}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            create={f => this.showForm(f, {})}
            saveForm={f => this.save(f)}
            onCancel={f => this.toggleForm(f)}
            edit={(f, o) => this.showForm(f, o)}
            sources={sources}
            templates={templates}
            administrators={administrators}
            representators={representators}
            inspectors={inspectors}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
            onConsentCancel={consent => this.props.dispatch(PersonActions.changeConsentStatus(consent))}
            toggleModal={form => this.toggleForm(form)}
          />
          {/* <Attachments person={this.props.person} addAttachment={file => this.props.dispatch(PersonActions.addAttachment(file))} /> */}
          <Commissions
            person={this.props.person}
            forms={this.props.forms.forms}
            toggleForm={this.toggleForm}
            formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
            remove={objectId => this.props.dispatch(PersonActions.removeObject(objectId, this.props.person.id, 'commissions'))}
            create={f => this.showForm(f, {})}
            saveForm={f => this.props.dispatch(PersonActions.saveCommission(f))}
            onCancel={f => this.toggleForm(f)}
            edit={(f, o) => this.showForm(f, o)}
            currencies={currencies}
            commission_types={commission_types}
            categories={commissionCategories}
            setField={(form, field, value) => this.props.dispatch(FormActions.storeInput(form, field, value))}
            clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
          />
        </MainContainer>
        <SidebarDetails
          person
          Notes={
            <Notes
              name={this.props.person?.full_name}
              notes={
                this.props.person?.notes?.map((n) => ({
                  created_date: n.created_date,
                  modified_date: n.modified_date,
                  avatar: n.user_creator ? n.user_creator.avatar_url : '',
                  creator: n.user_creator ? n.user_creator.full_name : 'brak',
                  comment: n.comment,
                  name: n.name,
                  id: n.id
                })) ?? []
              }
              forms={this.props.forms.forms}
              toggleForm={this.toggleForm}
              formVisible={f => this.props.forms.options.visible.indexOf(f) !== -1}
              create={f => this.showForm(f, {})}
              saveForm={f => this.save(f)}
              onCancel={f => this.toggleForm(f)}
              edit={(f, o) => this.showForm(f, o)}
              remove={objectId => this.props.dispatch(PersonActions.removeObject(objectId, this.props.person.id, 'notes'))}
            />
          }
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  forms: state.Forms,
  person: state.Person.person,
  common: state.Common,
  companies: state.Company
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Index)
);
