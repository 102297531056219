import clsx from 'clsx';
import moment from 'moment';
import React, { useState } from 'react';

export const FILTERS = { DAY: 0, WEEK: 1, MONTH: 2, YEAR: 3 };

export default function ListFilters({ onChange }) {
  const [activeFilter, setActiveFilter] = useState(FILTERS.YEAR);

  const handleFilters = name => () => {
    switch (name) {
      case FILTERS.DAY:
        setActiveFilter(FILTERS.DAY);
        onChange(
          moment()
            .subtract(1, 'days')
            .format('DD.MM.YYYY')
        );
        break;
      case FILTERS.WEEK:
        setActiveFilter(FILTERS.WEEK);
        onChange(
          moment()
            .subtract(1, 'weeks')
            .format('DD.MM.YYYY')
        );
        break;
      case FILTERS.MONTH:
        setActiveFilter(FILTERS.MONTH);
        onChange(
          moment()
            .subtract(1, 'months')
            .format('DD.MM.YYYY')
        );
        break;
      case FILTERS.YEAR:
        setActiveFilter(FILTERS.YEAR);
        onChange(
          moment()
            .subtract(1, 'years')
            .format('DD.MM.YYYY')
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className="text-center">
      <button
        className={clsx('btn btn-sm btn-outline-secondary pl-3 pr-3 mr-2', activeFilter === FILTERS.DAY && ' active')}
        onClick={handleFilters(FILTERS.DAY)}
        type="button">
        Dzień
      </button>
      <button
        className={clsx('btn btn-sm btn-outline-secondary pl-3 pr-3 mr-2', activeFilter === FILTERS.WEEK && ' active')}
        onClick={handleFilters(FILTERS.WEEK)}
        type="button">
        Tydzień
      </button>
      <button
        className={clsx('btn btn-sm btn-outline-secondary pl-3 pr-3 mr-2', activeFilter === FILTERS.MONTH && ' active')}
        onClick={handleFilters(FILTERS.MONTH)}
        type="button">
        Miesiąc
      </button>
      <button
        className={clsx('btn btn-sm btn-outline-secondary pl-3 pr-3 mr-2', activeFilter === FILTERS.YEAR && ' active')}
        onClick={handleFilters(FILTERS.YEAR)}
        type="button">
        Rok
      </button>
    </div>
  );
}
