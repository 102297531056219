import AgreementActions from 'actions/agreement';
import { BaseModal } from 'components/Layout/OldModal';
import Tooltip from 'components/Layout/Tooltip';
import { ENUM_DOKUMENTY_ZABEZPIECZEN } from 'containers/enums';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import BooleanCheck from '../../../components/BooleanCheck';
import { Checkbox, Datepicker, File, Form, Input, Select, Selectable, Textarea } from '../../../components/Forms';
import { RowEditable } from '../../../components/Layout/RowEditable';
import { formatNip, parseBan, parseMoney } from '../../../utilities/helpers';
import InsuranceClaimsForm from './InsuranceClaims';

const columns = {
  guarantor: [
    { name: 'Imię i nazwisko', getter: 'name', sortable: 1 },
    { name: 'NIP', getter: 'nip', sortable: 1 },
    { name: 'E-mail', getter: 'active_email', sortable: 1 }
  ],
  debtor: [
    { name: 'Imię i nazwisko', getter: 'name', sortable: 1 },
    {
      name: 'NIP',
      getter: 'nip',
      sortable: 1,
      mutator: formatNip
    }
  ],
  debtorAccounts: [{ name: 'Bank', getter: 'bank_name', sortable: 1 }, { name: 'Numer rachunku', getter: 'number', sortable: 1 }],
  clauses: [{ name: 'Nazwa klauzuli', getter: 'name', sortable: 1 }, { name: 'Opis', getter: 'description', sortable: 1 }]
};

const insuranceClaimForm = 'InsuranceClaimForm';
const guarantorForm = 'GuarantorForm';

export default props => {
  const dispatch = useDispatch();
  const f = props.forms?.[props.formName] ?? {};

  const handleSave = () => {
    dispatch(AgreementActions.saveDocuments({ formName: props.formName, id: props.agreement.id }));
  };

  const getTechBankAccounts = () => {
    if (f.debtor && f.debtor.id) {
      return props.version.agreement_debtors.find(x => x.id === f.debtor?.id)?.bank_accounts?.map(a => ({ ...a, number: parseBan(a) }));
    }
    return [];
  };

  const y = f.issuer?.addresses.find(x => x.is_active) ?? {};
  const middleContent = () => {
    let details;

    if(f.dts_type?.identifier === ENUM_DOKUMENTY_ZABEZPIECZEN.WEKSEL_IN_BLANCO_F) {
      details = (
        <>
          <div className="row align-items-center">
            <div className="col-sm-2 grey text-right" style={{ fontWeight: 600 }}>
              Ilość weksli
            </div>
            <div className="col-sm-2">
              <Select
                number
                formName={props.formName}
                options={Array(10)
                  .fill(0)
                  .map((x, z) => ({ value: z, name: z }))}
                name="bills_of_exchange_no"
              />
            </div>
            <div className="col-sm-3 grey text-right" style={{ fontWeight: 600 }}>
              Deklaracja wekslowa
            </div>
            <div className="col-sm-1">
              <Checkbox formName={props.formName} name="bill_declaration" label="" />
            </div>
            <div className="col-sm-1 grey text-right" style={{ fontWeight: 600 }}>
              Poręczyciele (osoby/firmy)
            </div>
            <div className="col-sm-3">
              {/* {f.guarantor?.name ?? 'brak'} */}
              <button
                type="button"
                className="ml-4 mr-auto"
                onClick={() => {
                  props.fetchGuarantors();
                  props.edit(guarantorForm, { secureId: f.id });
                }}>
                Dodaj
              </button>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 pl-5 pr-5">
              <div className="row bb bt ">
                <div className="col-2 grey p-2 br bl">Nazwa</div>
                <div className="col-2 grey p-2 br">Skrót</div>
                <div className="col-2 grey p-2 br">NIP</div>
                <div className="col-2 grey p-2 br">Miasto</div>
                <div className="col-2 grey p-2 br">Sposób reprezentacji</div>
                <div className="col-2 grey p-2 br">Rola biznesowa</div>
              </div>
              {f.guarantors?.map(g => (
                <RowEditable
                  onRemove={() => {
                    props.remove(`documents_to_secure/${f.id}/guarantors`, g.id);
                    props.storeInput(props.formName, 'guarantors', f.guarantors.filter(x => x.id !== g.id));
                  }}>
                  <div className="col-2 p-2 br bl">{g.object?.full_name || g.object?.name}</div>
                  <div className="col-2 p-2 br">{g.object?.full_name || g.object?.name}</div>
                  <div className="col-2 p-2 br">{formatNip(g.object?.nip)}</div>
                  <div className="col-2 p-2 br">{g.object?.city ?? '-'}</div>
                  <div className="col-2 p-2 br">{g.object?.representation?.name ?? '-'}</div>
                  <div className="col-2 p-2 br">{g.role?.name}</div>
                </RowEditable>
              ))}
            </div>
          </div>
        </>
      );
    } else if(f.dts_type?.identifier === ENUM_DOKUMENTY_ZABEZPIECZEN.CESJA_PRAW_Z_POLISY_F) {
      details = (
        <>
          <div className="row align-items-center">
            <div className="col-sm-3 grey text-right" style={{ fontWeight: 600 }}>
              Termin interwencji faktora
            </div>
            <div className="col-sm-3">
              <Select
                number
                formName={props.formName}
                options={Array(25)
                  .fill(0)
                  .map((x, z) => ({ value: (z + 1) * 5, name: (z + 1) * 5 }))}
                name="factor_intervention_period"
              />
            </div>
            <div className="col-sm-2 grey text-right" style={{ fontWeight: 600 }}>
              Składkę opłaca
            </div>
            <div className="col-sm-3">
              <Select
                number
                formName={props.formName}
                options={[{ value: 0, name: 'Faktorant' }, { value: 1, name: 'Faktor' }]}
                name="contributor"
              />
            </div>
          </div>

          <div className="row bb bt mt-4">
            <div className="col-2 grey p-2 br ">Nazwa firmy</div>
            <div className="col-2 grey p-2 br">NIP</div>
            <div className="col-2 grey p-2 br">Termin płatności</div>
            <div className="col-2 grey p-2 br">Przyznany limit</div>
            <div className="col-2 grey p-2 br">Od dnia</div>
            <div className="col-2 grey p-2">Udział własny</div>
          </div>
          {f.insurance_limits?.map(a => (
            <RowEditable
              onRemove={() => {
                props.remove(`documents_to_secure/${f.id}/insurancelimits`, a.id);
                props.storeInput(props.formName, 'insurance_limits', f.insurance_limits.filter(x => x.id !== a.id));
              }}
              onEdit={() => props.edit(insuranceClaimForm, { ...a, secureId: f.id })}>
              <div className="col-2 p-2 br ">{a.insured?.name}</div>
              <div className="col-2 p-2 br">{formatNip(a.insured?.nip)}</div>
              <div className="col-2 p-2 br">{a.date_of_payment}</div>
              <div className="col-2 p-2 br">{a.granted_limit}</div>
              <div className="col-2 p-2 br">{a.from_date}</div>
              <div className="col-2 p-2">{a.own_contribution}</div>
            </RowEditable>
          ))}
          <div className="row mt-3">
            <div className="col-12 p-2 ">
              <button onClick={() => props.edit(insuranceClaimForm, { secureId: f.id })} type="button">
                Dodaj limit ubezpieczeniowy
              </button>{' '}
            </div>
          </div>
        </>
      );
    } else if(f.dts_type?.identifier === ENUM_DOKUMENTY_ZABEZPIECZEN.CESJA_WIERZYTELNOSCI_F) {
      details = (
        <>
          <div className="row mb-2">
            <div className="col-sm-6">
              <div className="row align-items-center">
                <div className="col-sm-6 grey text-right" style={{ fontWeight: 600 }}>
                  Potwierdzenie cesji
                </div>
                <div className="col-sm-6">
                  <Checkbox formName={props.formName} name="confirmation_assignment" />
                </div>
              </div>
              <div className="row align-items-center mt-1">
                <div className="col-sm-6 grey text-right" style={{ fontWeight: 600 }}>
                  Data potwierdzenia
                </div>
                <div className="col-sm-6">
                  <Datepicker formName={props.formName} name="confirmation_date" />
                </div>
              </div>
              <div className="row align-items-center mt-1">
                <div className="col-sm-6 grey text-right" style={{ fontWeight: 600 }}>
                  {' '}
                </div>
                <div className="col-sm-6">
                  <BooleanCheck
                    asRadioButton
                    className="mr-2"
                    field={f.is_global_area}
                    onClick={() => {
                      props.storeInput(props.formName, 'is_global_area', true);
                      props.storeInput(props.formName, 'is_limited_area', false);
                    }}
                  />
                  <span className="mr-2">Globalna</span>
                  <BooleanCheck
                    asRadioButton
                    className="mr-2"
                    field={f.is_limited_area}
                    onClick={() => {
                      props.storeInput(props.formName, 'is_global_area', false);
                      props.storeInput(props.formName, 'is_limited_area', true);
                    }}
                  />
                  <span className="mr-2">Ograniczona</span>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <Textarea formName={props.formName} name="scope_assignment" placeholder="Wpisz zakres cesji" />
            </div>
          </div>
          <hr />
          <div className="row align-items-center mt-3">
            <div className="col-sm-3 grey text-right" style={{ fontWeight: 600 }}>
              Dłużnik
            </div>
            <div className="col-sm-6">
              {f.debtor?.name ?? 'nie wybrano'}
              <button
                type="button"
                className="ml-4 mr-auto"
                onClick={() => {
                  props.toggleForm('debtor');
                }}>
                Dodaj
              </button>
            </div>
          </div>
          <div className="row align-items-center mt-2 mb-2">
            <div className="col-sm-3 grey text-right" style={{ fontWeight: 600 }}>
              Rachunki cesyjne dłużnika
            </div>
            <div className="col-sm-6 grey" style={{ fontWeight: 600 }}>
              <button
                type="button"
                onClick={() => {
                  props.toggleForm('tech_debtor_bank_accounts');
                }}>
                Dodaj
              </button>
            </div>
          </div>
          {f.tech_debtor_bank_accounts?.map((a, b) => (
            <RowEditable index={b} onRemove={() => {
              props.storeInput(props.formName, 'tech_debtor_bank_accounts', f.tech_debtor_bank_accounts.filter(x => x.id !== a.id));
            }}>
              <div className={'p-2 col-sm-4 br bl'}>{parseBan(a)}</div>
              <div className="p-2 col-sm-1 br">{a.currency?.name}</div>
              <div className="p-2 col-sm-1 br">{a.bank_account_type?.name}</div>
              <div className="p-2 col-sm-6 br">{a.bank_name}</div>
            </RowEditable>
          ))}
        </>
      );
    }
    return (
      <>
        <div className="row mt-2 mb-3">
          <div className="col-sm-12">
            <span className="grey">Szczególne parametry dotyczące zabezpieczenia</span>
          </div>
        </div>
        {details}
      </>
    );
  };
  // const dts = { score: 100 };
  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      <div className="row mb-2 align-items-center">
        <div className="col-sm-2 grey text-right" style={{ fontWeight: 600 }}>
          Rodzaj
        </div>
        <div className="col-sm-3 " style={{ fontWeight: 600 }}>
          {f.dts_type?.name}
        </div>
        <div className="col-sm-3 grey  text-right" style={{ fontWeight: 600, marginTop: '-10px' }}>
          Okres obowiązywania
        </div>
        <div className="col-sm-4 d-flex align-items-center">
          <BooleanCheck className='mb-10' asRadioButton field={!!f.indefinite_period_of_validity} onClick={() => {
            props.storeInput(props.formName, 'indefinite_period_of_validity', true);
            props.storeInput(props.formName, 'period_of_validity_from', null);
            props.storeInput(props.formName, 'period_of_validity_to', null);
          }} />
          <span className="ml-1 mr-1 mb-10">bezterm.</span>
          <BooleanCheck className='mb-10' asRadioButton field={!f.indefinite_period_of_validity} onClick={() => props.storeInput(props.formName, 'indefinite_period_of_validity', false)} />
          <div className="ml-1 mr-1 mb-10">od</div>
          <Datepicker disabled={f.indefinite_period_of_validity} formName={props.formName} name="period_of_validity_from" />
          <div className="ml-1 mr-1 mb-10">do</div>
          <Datepicker disabled={f.indefinite_period_of_validity} formName={props.formName} name="period_of_validity_to" />
        </div>
      </div>
      <div className="row mb-2 align-items-center">
        <div className="col-sm-2 grey  text-right" style={{ fontWeight: 600, marginTop: '-40px' }}>
          Kwota / Wartość
        </div>
        <div className="col-sm-2 grey" style={{ marginTop: '-30px' }}>
          <Input formName={props.formName} type="text" name="current_debt" />
        </div>
        <div className="col-sm-1 grey p-0" style={{ marginTop: '-40px' }}>/ {parseMoney(f.collateral_value)} PLN</div>
        <div className="col-sm-3 grey  text-right" style={{ fontWeight: 600, marginTop: '-10px' }}>
          Forma dokumentu
        </div>
        <div className="col-sm-2 grey d-flex align-items-center">
          <Select
            number
            disabled={props.selectDisabled || false}
            formName={props.formName}
            options={props.common?.offerData?.dtb_forms?.map(i => ({ ...i, value: i.id })) ?? []}
            name="dts_form_id"
          />
        </div>
        <div className="col-sm-2 grey d-flex align-items-center">
          {f.expected_dtb_form ? <>/ {f.expected_dtb_form?.name || '-'}</> : ''}
        </div>
      </div>
      <div className="row mb-2 align-items-center">
        <div className="col-sm-2 grey  text-right" style={{ fontWeight: 600, marginTop: '-65px' }}>
          Nr umowy
        </div>
        <div className="col-sm-3" style={{ marginTop: '-65px' }}><Tooltip label={props.agreement?.identifier} /></div>
        <div className="col-sm-3 grey  text-right" style={{ fontWeight: 600, marginTop: '-10px' }}>
          Wzór Faktora
        </div>
        <div className="col-sm-2 grey">
          <Select
            number
            disabled={props.selectDisabled || false}
            formName={props.formName}
            options={[{ value: 1, name: 'tak' }, { value: 0, name: 'nie' }]}
            name="is_factor_pattern"
          />
        </div>
      </div>
      <div className="row mb-2 align-items-center">
        <div className="col-sm-2 grey text-right" style={{ fontWeight: 600, marginTop: '-60px' }}>
          Identyfikator / Status
        </div>
        <div className="flex flex-center justify-center" style={{ marginTop: '-60px' }}>
          <Tooltip label={f.identifier} />
          <div>&nbsp;/&nbsp;</div>
          {props.common?.agreementData?.secure_document_statuses?.[f.status]}
        </div>
      </div>

      <hr />

      <div className="row align-items-center mt-3 mb-1">
        <div className="col-sm-3 grey">Wystawiający</div>
        <div className="col-sm-3">
          <div className="row align-items-center">
            <div className="col-sm-6 grey text-right">Czy Faktorant</div>
            <div className="col-sm-6">
              <BooleanCheck field={f.issuer?.id === props.agreement?.client?.id} />
            </div>
          </div>
        </div>
        <div className="col-sm-1 grey">Klauzule</div>
        <div className="col-sm-5">
          {f.clauses?.map(c => c.name).join(', ')}

          <button type="button" className="ml-1" onClick={() => props.toggleForm('clauses')}>
            ...
          </button>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-sm-3">
          <div className="row">
            <div className="col-sm-12">{f.issuer?.name}</div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              {y.zipcode} {y.city}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              {y.street} {y.building_no}
              {y.local_no ? `/${y.local_no}` : ''}
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="row">
            <div className="col-sm-6 grey text-right">NIP</div>
            <div className="col-sm-6">{formatNip(f.issuer?.nip)}</div>
          </div>
          <div className="row">
            <div className="col-sm-6 grey text-right">REGON</div>
            <div className="col-sm-6">{f.issuer?.regon || '-'}</div>
          </div>
          <div className="row">
            <div className="col-sm-6 grey text-right">KRS/CEIDG</div>
            <div className="col-sm-6">{f.issuer?.krs ?? f.issuer?.ceidg}</div>
          </div>
        </div>
        <div className="col-sm-6 mt-2">
          <Textarea formName={props.formName} name="comment" />
        </div>
      </div>

      <hr />

      {f.dts_type?.has_middle_content ? middleContent() : null}

      <div className="row bb bt mt-4">
        <div className="flex-15 grey p-2 br ">Dokument</div>
        <div className="flex-4 grey p-2 br">Wygenerowano</div>
        <div className="flex-4 grey p-2 br">Zarejestrowano</div>
        <div className="flex-9 grey p-2 br">Nr szablonu / zabezpieczenia</div>
        <div className="flex-8 grey p-2 br">Zarejestrował</div>
        <div className="flex-4 grey p-2">Data rejestracji</div>
      </div>
      {f.attachments?.map(a => (
        <div className="row bb">
          <div className="flex-15 p-2 br ">{a.template ? `${a.name} - ${f.issuer?.name}` : a.name}</div>
          <div className="flex-4 p-2 br">
            {a.generated_file ? (
              <>
                <button
                  type="button"
                  className="mr-2"
                  onClick={() => {
                    window.open(process.env.REACT_APP_BACKEND_LINK + a.generated_file, '_blank');
                  }}>
                  Pobierz
                </button>
                <button type="button" onClick={() => props.generate(props.formName, 'documents_to_secure', a.id, 'generate', true)}>
                  Usuń
                </button>
              </>
            ) : a.template && f.is_factor_pattern ? (
              <button type="button" onClick={() => props.generate(props.formName, 'documents_to_secure', a.id, 'generate')}>
                Generuj
              </button>
            ) : null}
          </div>
          <div className="flex-4 p-2 br">
            {a.registered_file ? (
              <>
                <button
                  type="button"
                  className="mr-2"
                  onClick={() => {
                    window.open(process.env.REACT_APP_BACKEND_LINK + a.registered_file, '_blank');
                  }}>
                  Pobierz
                </button>{' '}
                <button
                  type="button"
                  onClick={() =>
                    a.template
                      ? props.generate(props.formName, 'documents_to_secure', a.id, 'register', true)
                      : props.removeAttachment(`documents_to_secure/${f.id}/attachments`, a.id)
                  }>
                  Usuń
                </button>
              </>
            ) : a.generated_file || !f.is_factor_pattern ? (
              <File
                text="Rejestruj"
                url={`agreements/${props.agreement?.id}/agreement_versions/${props.version?.id}/documents_to_secure/${f.id}/attachments/${
                  a.id
                }/register/`}
                objectId={a.id}
                field="attachments"
                formName={props.formName}
                noToast
                onSuccess={() => {
                  toast.success('Zabezpieczenie zarejestrowane.');
                }}
                method="PATCH"
              />
            ) : null}
          </div>
          <div className="flex-9 p-2 br justify-content-center" style={{ fontSize: '100%' }}>{`${a.template?.identifier ?? '-'} / ${
            a.identifier
          }`}</div>
          <div className="flex-8 p-2 br">{a.author?.full_name}</div>
          <div className="flex-4 p-2">{a.registered_date}</div>
        </div>
      ))}
      <div className="row mt-3">
        <div className="col-12 p-2 ">
          <File
            url={`agreements/${props.agreement?.id}/agreement_versions/${props.version?.id}/documents_to_secure/${f.id}/attachments/`}
            field="attachments"
            fileName="registered_file"
            formName={props.formName}
          />
        </div>
      </div>

      {props.withButtons ? (
        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button type="button" onClick={handleSave} className="btn btn-primary font-weight-light mr-4">
              Zapisz
            </button>
            <button
              type="button"
              onClick={props.onClose}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}>
              Zamknij
            </button>
          </div>
        </div>
      ) : null}

      <Selectable
        fetch={() => props.fetchGuarantors('guarantor_filters')}
        filtersForm="guarantor_filters"
        visible={props.formVisible(guarantorForm)}
        formName={guarantorForm}
        field="guarantor"
        columns={columns.guarantor}
        options={props.guarantors}
        onClickAway={() => props.toggleForm(guarantorForm)}
        header="Wybierz poręczyciela"
        footer="Aby zapisać wciśnij ZATWIERDŹ"
        onSelected={() => {
          props.saveForm(guarantorForm, false);
          props.toggleForm(guarantorForm);
        }}
      />
      <Selectable
        multiple
        fetch={() => {}}
        visible={props.formVisible('clauses')}
        formName={props.formName}
        field="clauses"
        columns={columns.clauses}
        options={props.common?.proposalData?.factor_secure_clauses_data}
        onClickAway={() => props.toggleForm('clauses')}
        header="Wybierz klauzule"
        footer="Aby zapisać wciśnij ZATWIERDŹ"
      />
      <Selectable
        fetch={() => {}}
        visible={props.formVisible('debtor')}
        formName={props.formName}
        field="debtor"
        columns={columns.debtor}
        options={props.version?.agreement_debtors}
        onClickAway={() => props.toggleForm('debtor')}
        header="Wybierz dłużnika"
        footer="Aby zapisać wciśnij ZATWIERDŹ"
        onSelected={(v) => {
          props.storeInput(props.formName, 'tech_debtor_bank_accounts', f.debtor.bank_accounts);
        }}
      />
      <Selectable
        multiple
        fetch={() => {}}
        visible={props.formVisible('tech_debtor_bank_accounts')}
        formName={props.formName}
        field="tech_debtor_bank_accounts"
        columns={columns.debtorAccounts}
        options={getTechBankAccounts()}
        onClickAway={() => props.toggleForm('tech_debtor_bank_accounts')}
        header="Wybierz rachunek techniczny dłużnika"
        footer="Aby zapisać wciśnij ZATWIERDŹ"
      />

      <Selectable
        defaultFilters={{ role_name: 'dłużnik' }}
        fetch={() => props.fetchDebtorsDict('guarantor_filters')}
        filtersForm={'guarantor_filters'}
        visible={props.formVisible('insured')}
        formName={insuranceClaimForm}
        field="insured"
        columns={columns.guarantor}
        options={props.debtorsDict}
        onClickAway={() => props.toggleForm('insured')}
        header="Wybierz ubezpieczonego"
        footer="Aby zapisać wciśnij ZATWIERDŹ"
      />

      <BaseModal
        visible={props.formVisible(insuranceClaimForm)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Dodaj limit ubezpieczeniowy</span>
          </div>
        }
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(insuranceClaimForm)}>
        <InsuranceClaimsForm
          issuers={props.debtorsDict}
          fetchIssuers={props.fetchDebtorsDict}
          forms={props.forms}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(insuranceClaimForm)}
          formVisible={props.formVisible}
          onCancelButtonClick={() => props.toggleForm(insuranceClaimForm)}
          toggleForm={props.toggleForm}
          formName={insuranceClaimForm}
          withButtons
          submitForm={() => props.saveForm(insuranceClaimForm)}
        />
      </BaseModal>
    </Form>
  );
};
