import Card from 'components/Layout/Card';
import React, { useEffect } from 'react';

import { ReadinessForm } from '../../Forms/Readiness';

export const Readiness = props => {
  const formName = 'ReadinessCreatorForm';

  useEffect(() => {
    if (props.populateForm) {
      props.populateForm(formName);
    }
    // eslint-disable-next-line
  }, props);

  const calculation = props.calculation || {};

  return (
    <Card mode='creator' header='+ Gotowość' onSaveClick={() => props.saveForm(formName)}>
      <div/>
      <div className="pr-5 pt-3">
        <ReadinessForm
          forms={props.forms}
          countingMethods={props.countingMethods}
          storeInput={props.storeInput}
          calculationId={calculation.id}
          populate={() => props.populateForm(formName)}
          offer={props.offer}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(formName)}
          formName={formName}
          saveForm={() => props.saveForm(formName, false)}
          onCancel={() => props.toggleForm(formName)}
        />
      </div>
    </Card>
  );
};
