import CommonActions from 'actions/common';
import FormActions from 'actions/forms';
import OfferActions from 'actions/offer';
import ProposalActions from 'actions/proposal';
import OrderBy from 'components/Forms/OldOrderBy';
import { Container, MainContainer, SidebarContainer } from 'components/Layout';
import { BaseModal } from 'components/Layout/OldModal';
import { Pagination } from 'components/Layout/Pagination';
import Tooltip from 'components/Layout/Tooltip';
import ListFilters from 'components/OldListFilters';
import { CreateOfferForm } from 'containers/Offers/Forms/Create';
import { CREATE_OFFER_FORM, FILTERS_FORM } from 'containers/Offers/List/Index';
import OfferSidebarView from 'containers/Offers/List/Sidebar';
import { OldSearchBar } from 'containers/OldSearchBar';
import Tab from 'containers/Tab';
import { t } from 'lang/pl';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Constants from 'utilities/constants';
import { parseMoney } from 'utilities/helpers';

export default function ListWrapper({ history, rows, offer, actors, common }) {
  const dispatch = useDispatch();
  const { limit } = useSelector(state => state.Options);
  const { forms, errors, options } = useSelector(state => state.Forms);
  // const isMountedRef = useIsMountedRef();

  useEffect(() => {
    dispatch(CommonActions.changeView(Constants.Views.List));
    dispatch(OfferActions.fetch({ page: 1, limit: limit }));
    dispatch(FormActions.storeInput(FILTERS_FORM, 'modified_date', null));
  }, []);

  const getFilters = () => {
    const currentFilters = forms[FILTERS_FORM] || {};
    let params = {};
    let form = {};

    if (history.location.search) {
      const urlParams = new URLSearchParams(history.location.search.replace('?', ''));
      params = Object.fromEntries(urlParams);
    }

    if (!currentFilters || Object.keys(currentFilters).length <= Object.keys(CommonActions.defaultFilters).length) {
      form = { ...CommonActions.defaultFilters, ...params, ...currentFilters };
    } else {
      form = { ...currentFilters, ...params };
      if (!params.status && form.status) {
        delete form.status;
      }
    }

    dispatch(FormActions.initialize(FILTERS_FORM, form, {}));
    return form;
  };

  const handleChangePage = (page, limit) => {
    dispatch(FormActions.storeInput(FILTERS_FORM, 'page', page));
    dispatch(FormActions.storeInput(FILTERS_FORM, 'limit', limit));
    fetchResults();
  };

  const fetchResults = () => dispatch(OfferActions.fetch(getFilters()));

  const createOffer = () => {
    dispatch(FormActions.initialize(CREATE_OFFER_FORM, {}, {}));
    dispatch(FormActions.toggleForm(CREATE_OFFER_FORM));
  };

  const selectOfferForPreview = Offer => {
    dispatch(OfferActions.selectForPreview(Offer));
  };

  const saveForm = (stayOnList = false) => {
    const { push } = history;
    dispatch(OfferActions.createOffer(CREATE_OFFER_FORM, stayOnList, push));
  };

  const remove = id => dispatch(OfferActions.removeOffer(id));

  const toggleForm = f => dispatch(FormActions.toggleForm(f));

  const formVisible = f => options?.visible?.indexOf(f) !== -1;

  const formVisibleTT = f => () => (options?.visible?.indexOf(f) !== -1);

  return (
    <Container>
      <MainContainer>
        <OldSearchBar title="Lista ofert" formName={FILTERS_FORM} onSubmit={fetchResults} records={rows.length} />

        <Tab name={Constants.Tabs.Offers.name}>
          <div className="row mb-4 mt-3 align-items-center">
            <div className="col-sm-9">
              <ListFilters formName={FILTERS_FORM} onSubmit={fetchResults} />
            </div>
            <div className="col-sm-3 text-right">
              <h4 className="text-themecolor" style={{ cursor: 'pointer' }}>
                <i className="fa fa-ellipsis-h mr-2" onClick={() => toast.error(t.error.toast.notImplemented)} />
                <i className="fa fa-plus" onClick={createOffer} />
              </h4>
            </div>
          </div>
          <div className="row bt bb">
            <div className="col-sm-12">
              <div className="row grey " style={{ fontSize: '90%' }}>
                <div className="flex-20 max-w-150 p-2 br ">
                  <OrderBy field="identifier" spanClassName="ml-0" column="ID" formName={FILTERS_FORM} onSubmit={fetchResults} />
                </div>
                <div className="flex-30 p-2 br">
                  <OrderBy field="client" spanClassName="ml-0" column="Klient" formName={FILTERS_FORM} onSubmit={fetchResults} />
                </div>
                <div className="flex-10 p-2 br">
                  <OrderBy field="product" spanClassName="ml-0" column="Produkt" formName={FILTERS_FORM} onSubmit={fetchResults} />
                </div>
                <div className="flex-10 p-2 br">
                  <OrderBy field="procedure" spanClassName="ml-0" column="Procedura" formName={FILTERS_FORM} onSubmit={fetchResults} />
                </div>
                <div className="flex-10 p-2 br">
                  <OrderBy field="amount" spanClassName="ml-0" column="Kwota" formName={FILTERS_FORM} onSubmit={fetchResults} />
                </div>
                <div className="flex-10 p-2 br f90">Waluta</div>
                <div className="flex-10 p-2 br f90">Status oferty</div>
                <div className="flex-20 max-w-180 p-2 f90">Data utworzenia</div>
                {/* <div className="flex-20 p-2">Wniosek</div> */}
              </div>
            </div>
          </div>
          {rows.length > 0 ? (
            rows.map(row => (
              <div
                key={row.id}
                className="row"
                onDoubleClick={() => history.push(`/oferty/${row.slug}`)}
                onClick={() => selectOfferForPreview(row)}>
                <div className="col-sm-12">
                  <div className="row bb data-row">
                    <div className="flex-20 max-w-150 p-2 br"><Tooltip label={row.identifier} /></div>
                    <div className="flex-30 p-2 br">{row.client?.name || 'brak'}</div>
                    <div className="flex-10 p-2 br">{row.product?.name || 'brak'}</div>
                    <div className="flex-10 p-2 br">{row.procedure?.name || 'brak'}</div>
                    <div className="flex-10 p-2 br">{parseMoney(row.active_limit_amount)}</div>
                    <div className="flex-10 p-2 br">{row.active_currency?.name || 'brak'}</div>
                    <div className="flex-10 p-2 br">
                      <span className="badge badge-warning pt-1 pb-1 pl-2 pr-2">{row.status ? 'zamknięta' : 'otwarta'}</span>
                    </div>
                    <div className="flex-20 max-w-180 p-2">{row.created_date}</div>
                    {/* <div className="flex-20 p-2">?</div> */}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="row">
              <div className="col-sm-12 mt-3">Brak wyników.</div>
            </div>
          )}
        </Tab>

        {rows && (
          <Pagination
            withSelect
            count={offer.count}
            currentPage={offer.currentPage}
            onChangePage={handleChangePage}
          />
        )}

        <BaseModal
          visible={formVisible(CREATE_OFFER_FORM)}
          header="Kreator dodawania oferty krok 1 z 2"
          footer="W celu utworzenia kalkulacji wciśnij utwórz kalkulację lub zapisz i zamknij bez tworzenia kalkulacji."
          onClickAway={() => toggleForm(CREATE_OFFER_FORM)}>
          <CreateOfferForm
            fetch={(t, f) => dispatch(ProposalActions.fetchForForm(t, f))}
            actors={actors}
            errors={errors[CREATE_OFFER_FORM]}
            form={forms[CREATE_OFFER_FORM]}
            forms={forms}
            clear={(form, field) => dispatch(FormActions.clearField(form, field))}
            visibleOnMount={false}
            visible={formVisibleTT(CREATE_OFFER_FORM)}
            toggleForm={form => toggleForm(form)}
            isVisible={formVisible}
            formName={CREATE_OFFER_FORM}
            withButtons
            submitForm={() => saveForm()}
            onSubmitAndLeave={() => saveForm(true)}
            onCancel={() => toggleForm(CREATE_OFFER_FORM)}
            offerData={common.offerData}
            currencies={common.currencies}
          />
        </BaseModal>
      </MainContainer>
      <SidebarContainer>
        <OfferSidebarView
          options={options}
          remove={remove}
          offer={offer.preview}
          loading={false}
          toggleForm={toggleForm}
          formVisible={formVisible}
          generatePdf={(id, form) => dispatch(OfferActions.generatePdf(id, form))}
          changeStatus={id => dispatch(OfferActions.changeStatus(id, getFilters()))}
        />
      </SidebarContainer>
    </Container>
  );
}
