import React from 'react';

import FinancialData from '../Components/FinancialData';
import Fraud from '../Components/Fraud';
import CreditLiabilities from '../Components/Liabilities/Credit';
import FactoringLiabilities from '../Components/Liabilities/Factoring';
import LeasingLiabilities from '../Components/Liabilities/Leasing';
import OtherLiabilities from '../Components/Liabilities/Other';
import PublicLiabilities from '../Components/Liabilities/Public';
import Quality from '../Components/Quality';
import Representation from '../Components/Representation';
import Risk from '../Components/Risk';
import Scoring from '../Components/Scoring';
import Secures from '../Components/Secures';

const populateQualities = (f, proposalVersion, data, store) => {
  const currentCriteria = proposalVersion.criteria_categories ? [...proposalVersion.criteria_categories] : [];
  const categoriesData = data.criteria_categories_data ? [...data.criteria_categories_data] : [];
  const criteriaAlreadyAdded = currentCriteria.length > 0;

  if (criteriaAlreadyAdded) {
    const proposalCriteria = currentCriteria.map(c => {
      c.definitions = c.proposal_criteria?.map(m => ({
        ...m,
        field_option_value: m?.field_option_value?.id,
        select_options: m.criteria_select_options,
        category_id: c.id
        // field_option_value: m.field_option_value.id || null,
      }));
      delete c.proposal_criteria;
      return c;
    });
    store(f, 'qualities', proposalCriteria);
  } else {
    const criteria = [
      ...categoriesData.map(i => ({ ...i, definitions: i.definitions.filter(j => !j.is_aml && j.is_required) })).filter(i => i.definitions.length)
    ].map(i => ({
      ...i,
      definitions: i.definitions.map(j => ({ ...j, category_id: i.id }))
    }));
    store(f, 'qualities', criteria);
  }
};

const populateAmlQualities = (f, proposalVersion, data, store) => {
  if (proposalVersion.criteria_categories_aml && proposalVersion.criteria_categories_aml.length > 0) {
    const proposalCriteria = proposalVersion.criteria_categories_aml
      .filter(c => c.proposal_criteria && c.proposal_criteria.length > 0)
      .map(c => {
        c.definitions = c.proposal_criteria.map(m => ({
          ...m,
          field_option_value: m?.field_option_value?.id,
          select_options: m.criteria_select_options,
          category_id: c.id
        }));
        delete c.proposal_criteria;
        return c;
      });
    store(f, 'qualities', proposalCriteria);
  } else {
    const categoriesData = data.criteria_categories_data ? [...data.criteria_categories_data] : [];

    const criteria = [
      ...categoriesData.map(i => ({ ...i, definitions: i.definitions.filter(j => j.is_aml && j.is_required) })).filter(i => i.definitions.length)
    ].map(i => ({
      ...i,
      definitions: i.definitions.map(j => ({ ...j, category_id: i.id }))
    }));
    store(f, 'qualities', criteria);
  }
};

const populateSecures = (f, data, store) => {
  const secures = data.factor_secure_documents || [];

  if (secures.length) {
    store(f, 'secure_action', 1);
    store(f, 'secures', secures.map(s => ({ ...s, dts_type_id: s.dts_type.id, dts_form_id: s.dts_form.id })));
  }
};

export default props => (
  <>
    {/* <Header proposal={props.proposal} calculation={props.calculation} /> */}

    <Representation
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.showForm}
      representations={props.common.companyData.representations || []}
      representants={props.proposal && props.proposal.client ? props.proposal.client.people : []}
      storeInput={props.storeInput}
      remove={props.remove}
      populateForm={f => props.storeInput(f, 'representation_id', props.proposal.client?.representation?.id)}
    />
    <FinancialData
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
      remove={props.remove}
    />
    <CreditLiabilities
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
      remove={props.remove}
      proposalData={props.common.proposalData}
      banks={props.common.banks}
    />
    <LeasingLiabilities
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
      remove={props.remove}
      proposalData={props.common.proposalData}
    />
    <FactoringLiabilities
      clear={props.clear}
      factors={props.factors}
      populateForm={props.populateForm}
      proposalData={props.common.proposalData}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
      remove={props.remove}
    />
    <OtherLiabilities
      clear={props.clear}
      proposalData={props.common.proposalData}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
      remove={props.remove}
    />
    <PublicLiabilities
      clear={props.clear}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
      remove={props.remove}
    />
    <Scoring
      populateForm={props.populateForm}
      clear={props.clear}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.showForm}
      companyData={props.common.companyData}
    />
    <Quality
      populateForm={formName =>
        populateQualities(formName, props.version, props?.common?.proposalData ?? {}, props.storeInput)
      }
      categories={props.common.proposalData ? props.common.proposalData.criteria_categories_data : []}
      fieldTypes={props.common.proposalData ? props.common.proposalData.criteria_field_types : []}
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      common={props.common}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.showForm}
      storeInput={props.storeInput}
    />
    <Fraud
      populateForm={formName =>
        populateAmlQualities(formName, props.version, props?.common?.proposalData ?? {}, props.storeInput)
      }
      categories={props.common.proposalData ? props.common.proposalData.criteria_categories_data : []}
      fieldTypes={props.common.proposalData ? props.common.proposalData.criteria_field_types : []}
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.showForm}
      storeInput={props.storeInput}
    />
    <Secures
      issuers={props.issuers}
      fetchIssuers={props.fetchIssuers}
      populateForm={formName => populateSecures(formName, props.proposal, props.storeInput)}
      clear={props.clear}
      proposal={props.proposal}
      version={props.version}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.edit}
      remove={props.remove}
      common={props.common}
      storeInput={props.storeInput}
    />
    <Risk
      clear={props.clear}
      proposal={props.proposal}
      forms={props.forms}
      toggleForm={props.toggleForm}
      formVisible={props.formVisible}
      saveForm={props.saveForm}
      edit={props.showForm}
      proposalData={props.common.proposalData}
    />
  </>
);
