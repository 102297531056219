import { BaseModal } from 'components/Layout/OldModal';
import React, { useState } from 'react';

import BooleanCheck from '../../../components/BooleanCheck';
import { Datepicker, Form, Input, Required, Select, Selectable, Textarea } from '../../../components/Forms';
import { RowEditable } from '../../../components/Layout/RowEditable';
import { parseBan, parseMoney } from '../../../utilities/helpers';
import { TransferForm } from '../../Claims/Forms/Transfer';

const AddItemToInvoice = 'AddItemToInvoice';
const transferForm = 'SettleCompensateTransferForm';
const claimsForm = 'SettleCompensateClaimForm';

export const CreateInvoice = props => {
  const [title, setTitle] = useState('');
  const [creatorEditing, setCreatorEditing] = useState(false);
  const [nameAsList, setNameAsList] = useState(false);
  const [tab, setTab] = useState(1);
  const [key, setKey] = React.useState(null);

  const f = props.forms?.[props.formName] ?? {};
  const items = f.items || [];
  const previous_items = f.previous_items || [];

  const details = f.mode === 'details';
  const isAutomat = f.is_auto;
  const isEdit = f.mode === 'edit';
  const isCorrection = f.mode === 'correction' || f.is_correction;

  const settle = (type) => {

    if (type === 1) {
      props.edit(transferForm, {
        'net_transfer_value': '150',
        'transfer_value_currency_id': 1
      });
    }

    if (type === 2) {
      // props.storeInput(props.docsFilters, 'context', f.context === 2 ? 1 : 2);
      props.storeInput(props.docsFilters, 'client_id', f.linked?.buyer);
      props.storeInput(props.docsFilters, 'is_settled', 0);

      props.fetchDocs();
      props.edit(claimsForm, {

      });
    }
  };

  const calculateItem = item => {
    const nettoWithoutDiscount = Number(item.amount) * Number(item.unit_net_price);
    const netto = item.discount ? nettoWithoutDiscount - (Number(item.discount) / 100) * nettoWithoutDiscount : nettoWithoutDiscount;

    const vatItem = props.common?.vat?.find(v => v.id === Number(item.vat_id));
    const v = Number(vatItem?.name?.replace('%', '')) || 0;
    const vat = (v / 100) * netto;

    const brutto = netto + vat;

    return { brutto, netto, vat };
  };

  const calculate = (a, b) => {
    const prev = a?.map(calculateItem) ?? [];
    const curr = b?.map(calculateItem) ?? [];

    const prevSum = {
      brutto: prev.reduce((acc, i) => acc + i.brutto, 0),
      netto: prev.reduce((acc, i) => acc + i.netto, 0),
      vat: prev.reduce((acc, i) => acc + i.vat, 0)
    };

    const currSum = {
      brutto: curr.reduce((acc, i) => acc + i.brutto, 0),
      netto: curr.reduce((acc, i) => acc + i.netto, 0),
      vat: curr.reduce((acc, i) => acc + i.vat, 0)
    };

    return {
      brutto: currSum.brutto - prevSum.brutto,
      netto: currSum.netto - prevSum.netto,
      vat: currSum.vat - prevSum.vat
    };
  };

  const addItem = (nal = false) => {
    setTitle('Dodawanie pozycji dokumentu');
    setNameAsList(nal);

    const newItemsList = [...items, {}];
    props.storeInput(props.formName, 'items', newItemsList);
    setKey(newItemsList.length - 1);

    props.toggleForm(AddItemToInvoice);
  };

  const editItem = (k) => {
    setCreatorEditing(true);
    // setTitle('Edycja pozycji dokumentu');
    // setNameAsList(nal);
    //
    // const newItemsList = [...items, {}];
    // props.storeInput(props.formName, 'items', newItemsList);
    setKey(k);
    //
    props.toggleForm(AddItemToInvoice);
  };

  const removeItem = k => {
    const newItemsList = items.filter((i, j) => j !== k);
    props.storeInput(props.formName, 'items', newItemsList);
    setKey(null);
  };

  const cancelItem = () => {
    if (creatorEditing) {
      setCreatorEditing(false);
    } else {
      removeItem(key);
    }
    props.toggleForm(AddItemToInvoice);
  };

  const saveItem = () => {
    props.toggleForm(AddItemToInvoice);
  };

  const value = o => {
    if (!o) return 0;
    const m = Number(o.amount) * Number(o.unit_net_price);
    return o.discount ? m - (Number(o.discount) / 100) * m : m;
  };

  const { brutto, netto, vat } = isCorrection ? calculate(f.previous_items, f.items) : calculate(null, f.items);

  return (
    <Form style={{ overflow: 'hidden' }} visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      <div className="row align-items-center" style={{ backgroundColor: '#E3DECC', borderBottom: '1px solid #7E6F41' }}>
        <div
          onClick={() => setTab(1)}
          className="col-sm-2 p-4"
          style={{ borderBottom: 'none', backgroundColor: tab === 1 ? 'white' : 'inherit', cursor: 'pointer' }}>
          Podstawowe
        </div>
        {(details || isEdit || isCorrection) && !f.is_settled ? (
          <div
            onClick={() => setTab(3)}
            className="col-sm-2 p-4"
            style={{ borderBottom: 'none', backgroundColor: tab === 3 ? 'white' : 'inherit', cursor: 'pointer' }}>
            Powiązania
          </div>
        ) : null}
        {(details || isEdit || isCorrection) ? (
          <div
            onClick={() => setTab(2)}
            className="col-sm-2 p-4"
            style={{ borderBottom: 'none', backgroundColor: tab === 2 ? 'white' : 'inherit', cursor: 'pointer' }}>
            Rozliczenia
          </div>
        ) : null}
        <div
          className={
            ((details || isEdit || isCorrection) ? 'col-sm-6' : 'col-sm-10') + ' p-3 text-right d-flex align-items-center justify-content-end'
          }>
          <span className="mr-3">{props.header}</span>
          <i className="fa fa-question-circle" style={{ fontSize: '28px' }} onClick={() => {}} />
        </div>
      </div>
      <div className="pl-3 pr-3">
        {tab === 1 && (
          <div className="p-1">
            <div className="row mt-3">
              <div className="col-sm-4">
                <div className="form-group mb-0 row  align-items-center">
                  <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                    Numer dokumentu
                  </label>
                  <div className="col-sm-6">{f.identifier || '-'}</div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group mb-0 row  align-items-center">
                  <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                    Umowa
                  </label>
                  <div className="col-sm-6">
                    {f.agreement?.identifier || ''}
                    {details || isAutomat || isCorrection ? (
                      ''
                    ) : (
                      <button
                        type="button"
                        className="ml-1"
                        onClick={() => {
                          props.fetchAgreements();
                          props.toggleForm('invoice_agreements');
                        }}>
                        Wybierz
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group mb-0 row  align-items-center">
                  <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                    MPP
                  </label>
                  <div className="col-sm-6">
                    <BooleanCheck
                      field={f.is_mpp}
                      onClick={details || isAutomat || isCorrection ? undefined : () => props.storeInput(props.formName, 'is_mpp', !f.is_mpp)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <div className="form-group mb-0 row  align-items-center">
                  <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                    Kontrahent (Nabywca)
                  </label>
                  <div className="col-sm-6">
                    {f.buyer?.name}
                    {details || isAutomat || isCorrection ? (
                      ''
                    ) : (
                      <button
                        type="button"
                        className="ml-1"
                        onClick={() => {
                          props.fetchClients();
                          props.toggleForm('invoice_clients');
                        }}>
                        Wybierz
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group mb-0 row  align-items-center">
                  <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                    Data wystawienia
                  </label>
                  <div className="col-sm-6">{details ? f.issue_date : <Datepicker formName={props.formName} name="issue_date" />}</div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group mb-0 row  align-items-center">
                  <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                    Sposób zapłaty
                  </label>
                  <div className="col-sm-6">
                    {details ? (
                      f.payment_method
                    ) : (
                      <Select
                        formName={props.formName}
                        options={Object.keys(props.common?.invoiceData?.payment_methods).map(k => ({ value: k, name: k }))}
                        name="payment_method"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <div className="form-group mb-0 row  align-items-center">
                  <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                    Faktor (Sprzedawca)
                  </label>
                  <div className="col-sm-6">
                    {f.seller?.name}
                    {details || isAutomat || isCorrection ? (
                      ''
                    ) : (
                      <button
                        type="button"
                        className="ml-1"
                        onClick={() => {
                          props.fetchFactors();
                          props.toggleForm('invoice_factors');
                        }}>
                        Wybierz
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {isCorrection ? (
                <div className="col-sm-4">
                  <div className="form-group mb-0 row  align-items-center">
                    <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                      Powód
                    </label>
                    <div className="col-sm-6">
                      {details ? f.correction_reason : <Input type="text" formName={props.formName} name="correction_reason" />}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-sm-4">
                  <div className="form-group mb-0 row  align-items-center">
                    <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                      Data sprzedaży
                    </label>
                    <div className="col-sm-6">{details ? f.sale_date : <Datepicker formName={props.formName} name="sale_date" />}</div>
                  </div>
                </div>
              )}
              <div className="col-sm-4">
                <div className="form-group mb-0 row  align-items-center">
                  <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                    Termin płatności
                  </label>
                  <div className="col-sm-6">{details ? f.payment_date : <Datepicker formName={props.formName} name="payment_date" />}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <div className="form-group mb-0 row  align-items-center">
                  <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                    Czy rozliczona
                  </label>
                  <div className="col-sm-6">
                    <BooleanCheck field={f.is_settled} />
                  </div>
                </div>
              </div>
              {f.is_correction ? <div className="col-sm-4">
                <div className="form-group mb-0 row  align-items-center">
                  <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                    {isCorrection ? 'Oryginalny dokument' : 'Czy posiada korektę'}
                  </label>
                  <div className="col-sm-6">
                    {isCorrection ? f.original_invoice?.identifier :<BooleanCheck field={f.has_correction} />}
                  </div>
                </div>
              </div> : null}
              {f.has_correction && !isCorrection ? <div className="col-sm-4">
                <div className="form-group mb-0 row  align-items-center">
                  <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
                    Numer korekty
                  </label>
                  <div className="col-sm-6">{f.correction?.name}</div>
                </div>
              </div> : null}
            </div>
            <div className="row mt-3 bt align-items-center">
              <div className="col-sm-8 p-2 grey">Pozycje dokumentu {isCorrection ? <span className="font-weight-bold"> - było</span> : ''}</div>
              <div className="col-sm-4 p-2">
                <div className="form-group mb-0 row  align-items-center">
                  <label htmlFor="example-text-input " className="col-sm-6 label-right">
                    Procedura podatkowa
                  </label>
                  <div className="col-sm-6">
                    {details ? (
                      props.common?.taxProcedureCodes?.find(x => x.id === f.tax_procedure_code)?.name || '-'
                    ) : (
                      <Select
                        isClearable
                        formName={props.formName}
                        name="tax_procedure_code"
                        options={props.common?.taxProcedureCodes?.map(x => ({ ...x, value: x.id }))}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {isCorrection ? (
              <>
                <div className="row bt bb">
                  <div className="col-3 p-2 br grey">Nazwa towaru / usługi</div>
                  <div className="col-1 p-2 br grey">CN / PKWiU</div>
                  <div className="col-1 p-2 br grey">GTU</div>
                  <div className="col-1 p-2 br grey">Jednostka</div>
                  <div className="col-1 p-2 br grey">Ilość</div>
                  <div className="col-2 p-2 br grey">Cena jednostkowa netto</div>
                  <div className="col-1 p-2 br grey">Rabat</div>
                  <div className="col-1 p-2 br grey">VAT</div>
                  <div className="col-1 p-2 grey">Wartość netto</div>
                </div>
                {previous_items?.map((item, k) => (
                  <RowEditable>
                    <div className="col-3 p-2 br ">{item.name}</div>
                    <div className="col-1 p-2 br ">{item.code || '-'}</div>
                    <div className="col-1 p-2 br ">{props.common?.gtuCodes?.find(x => x.id === item.gtu)?.name ?? '-'}</div>
                    <div className="col-1 p-2 br ">{item.unit}</div>
                    <div className="col-1 p-2 br ">{item.amount}</div>
                    <div className="col-2 p-2 br ">{item.unit_net_price}</div>
                    <div className="col-1 p-2 br ">{item.discount ? `${item.discount}%` : ''}</div>
                    <div className="col-1 p-2 br">{item.vat_value || props.common?.vat?.find(x => x.id === Number(item.vat_id))?.name || '-'}</div>
                    <div className="col-1 p-2">{parseMoney(value(item))}</div>
                  </RowEditable>
                ))}

                <div className="row mt-5 bt align-items-center">
                  <div className="col-sm-8 p-2 grey">
                    Pozycje dokumentu <span className="font-weight-bold"> - powinno być</span>
                  </div>
                </div>
              </>
            ) : null}

            <div className="row bt bb">
              <div className="col-3 p-2 br grey">Nazwa towaru / usługi</div>
              <div className="col-1 p-2 br grey">CN / PKWiU</div>
              <div className="col-1 p-2 br grey">GTU</div>
              <div className="col-1 p-2 br grey">Jednostka</div>
              <div className="col-1 p-2 br grey">Ilość</div>
              <div className="col-2 p-2 br grey">Cena jednostkowa netto</div>
              <div className="col-1 p-2 br grey">Rabat</div>
              <div className="col-1 p-2 br grey">VAT</div>
              <div className="col-1 p-2 grey">Wartość netto</div>
            </div>
            {items?.map((item, k) => (
              <RowEditable
                onEdit={isAutomat || details ? null : () => editItem(k)}
                onRemove={details || items.length === 1 ? undefined : () => removeItem(k)}>
                <div className="col-3 p-2 br ">{item.name}</div>
                <div className="col-1 p-2 br ">{item.code || '-'}</div>
                <div className="col-1 p-2 br ">{props.common?.gtuCodes?.find(x => x.id === Number(item.gtu))?.name ?? '-'}</div>
                <div className="col-1 p-2 br ">{item.unit}</div>
                <div className="col-1 p-2 br ">{item.amount}</div>
                <div className="col-2 p-2 br ">{item.unit_net_price}</div>
                <div className="col-1 p-2 br ">{item.discount ? `${item.discount}%` : ''}</div>
                <div className="col-1 p-2 br">{item.vat_value || props.common?.vat?.find(x => x.id === Number(item.vat_id))?.name || '-'}</div>
                <div className="col-1 p-2">{parseMoney(value(item))}</div>
              </RowEditable>
            ))}

            {details || isAutomat ? (
              <>
                <div className="row mt-3">
                  <div className="col-sm-12">
                    <div className="form-group mb-0 row  align-items-center">
                      <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
                        Rachunek nabywcy (kontrahenta)
                      </label>
                      <div className="col-sm-4">{parseBan(f.buyer_bank_account)}</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group mb-0 row  align-items-center">
                      <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
                        Rachunek sprzedawcy (faktora)
                      </label>
                      <div className="col-sm-4">{parseBan(f.seller_bank_account || f.manual_bank_account)}</div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row mt-3 ml-3">
                  <div className="col-sm-12">
                    <button disabled={isCorrection && isAutomat} type="button" className="mr-3" onClick={() => addItem(true)}>
                      Dodaj z listy
                    </button>
                    <button disabled={isCorrection && isAutomat} type="button" className="mr-3" onClick={() => addItem()}>
                      Dodaj ręcznie
                    </button>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-sm-12">
                    <div className="form-group mb-0 row  align-items-center">
                      <label htmlFor="example-text-input " className="col-sm-2 col-form-label label-right">
                        Wybierz numer konta
                      </label>
                      <div className="col-sm-10">
                        {parseBan(f.seller_bank_account || f.manual_bank_account)}

                        <button
                          className="ml-2"
                          type="button"
                          onClick={() => {
                            props.fetchAccounts();
                            props.toggleForm('invoice_accounts');
                          }}>
                          Wybierz
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="row mt-3 d-flex justify-content-center align-items-center">
              <div className="col-sm-4">
                {details ? (
                  <>
                    <div className="row">
                      <div className="col-sm-12">
                        <strong>Komentarz</strong>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <span>{f.comment || 'brak'}</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <Textarea name="comment" formName={props.formName} placeholder="Komentarz.." disabled={f.details} />
                )}
              </div>
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-12">
                    <span>Faktura bez podpisu odbiorcy</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <span>Osoba upoważniona do wystawienia FV</span>
                    <strong className="ml-2">{f.user_authorized?.full_name || props.user?.full_name}</strong>
                  </div>
                </div>
              </div>
              {isCorrection ? (
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-12">
                      <strong>Rozliczenie korekty</strong>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <span>Kwota {netto < 0 ? 'zmniejszająca' : 'zwiększająca'} podstawę opodatkowania</span>
                      <span style={{ float: 'right' }}>
                        {/*{parseMoney(f.net_total || netto)}*/}
                        {parseMoney(Math.abs(netto))} {f.currency?.name}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <span>Kwota {vat < 0 ? 'zmniejszająca' : 'zwiększająca'} podatek VAT</span>
                      <span style={{ float: 'right' }}>
                        {/*{parseMoney(f.vat_total || vat)}*/}
                        {parseMoney(Math.abs(vat))} {f.currency?.name}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <span>Do {brutto < 0 ? 'zwrotu' : 'zapłaty'}</span>
                      <strong style={{ float: 'right' }}>
                        {parseMoney(Math.abs(brutto))}
                        {/*{parseMoney(f.gross_total || brutto)}*/} {f.currency?.name}
                      </strong>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-12">
                      <strong>Podsumowanie faktury</strong>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <span>Razem netto</span>
                      <span style={{ float: 'right' }}>
                        {parseMoney(f.net_total || netto)} {f.currency?.name}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <span>VAT</span>
                      <span style={{ float: 'right' }}>
                        {parseMoney(f.vat_total || vat)} {f.currency?.name}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <span>Razem brutto</span>
                      <strong style={{ float: 'right' }}>
                        {parseMoney(f.gross_total || brutto)} {f.currency?.name}
                      </strong>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {tab === 2 ?  (
          <div className="p-1">
            <div className="row mt-3">
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Numer dokumentu</div>
                  <div className="col-sm-6">{f.settlements?.identifier}</div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Wartość brutto</div>
                  <div className="col-sm-6">{parseMoney(f.settlements?.gross_total)}  {f.currency?.name}</div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Status</div>
                  <div className="col-sm-6">{f.settlements?.is_settled ? 'Rozliczony' : 'Nierozliczony'}</div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Kontrahent (Nabywca)</div>
                  <div className="col-sm-6">{f.settlements?.buyer?.name}</div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Rozliczono</div>
                  <div className="col-sm-6">{parseMoney(f.settlements?.paid_value)} {f.currency?.name}</div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Data wystawienia</div>
                  <div className="col-sm-6">{f.settlements?.issue_date}</div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Faktor (Sprzedawca)</div>
                  <div className="col-sm-6">{f.settlements?.seller?.name}</div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Do rozliczenia</div>
                  <div className="col-sm-6">{parseMoney(f.settlements?.to_pay_value)} {f.currency?.name}</div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Data sprzedaży</div>
                  <div className="col-sm-6">{f.settlements?.sale_date}</div>
                </div>
              </div>
            </div>

            <div className="row mt-3 bt bb">
              <div className="col-sm-9 p-2 grey">Pozycje dokumentu</div>
              <div className="col-sm-3 p-2 grey float-right text-right">
                {isCorrection && !isAutomat && (f.status === 2 || f.status === 3) ? <>
                  <button type="button" className="mr-2" onClick={() => settle(1)}>
                    Dodaj przelew
                  </button>
                  <button type="button" onClick={() => settle(2)}>
                    Dokument do rozliczenia
                  </button>
                </> : null}
              </div>
            </div>

            <div className="row bb">
              <div className="col-sm-2 br ">
                <div className="row">
                  <div className="col-sm-12 p-2 grey">Należność</div>
                </div>
              </div>
              <div className="col-sm-1 br ">
                <div className="row">
                  <div className="col-sm-12 p-2 grey">Czy należność rozliczona?</div>
                </div>
              </div>
              <div className="col-sm-9">
                <div className="row">
                  <div className="col-sm-1 br p-2 grey">Metoda płatności</div>
                  <div className="col-sm-1 br p-2 grey">Data tytułu</div>
                  <div className="col-sm-1 br p-2 grey">Waluta tytułu</div>
                  <div className="col-sm-1 br p-2 grey">Data rozl.</div>
                  <div className="col-sm-1 br p-2 grey">Nal. brutto w wal.</div>
                  <div className="col-sm-1 br p-2 grey">Rozliczono</div>
                  <div className="col-sm-1 br p-2 grey">Do rozliczenia</div>
                  <div className="col-sm-1 br p-2 grey">Waluta rozl.</div>
                  <div className="col-sm-1 br p-2 grey">Rozliczono w wal. rozl.</div>
                  <div className="col-sm-1 br p-2 grey">Kurs dok.</div>
                  <div className="col-sm-1 br p-2 grey">Kurs rozl.</div>
                  <div className="col-sm-1 p-2 grey">Sposób rozl.</div>
                </div>
              </div>
            </div>
            {f.settlements?.cups?.map(charge => (
              <div className="row bb">
                {' '}
                <div className="col-sm-2 br">
                  <div className="row">
                    <div className="col-sm-12 p-2 ">{charge.title}</div>
                  </div>
                </div>
                <div className="col-sm-1">
                  <div className="row">
                    <div className="col-sm-12 p-2 ">{charge.status ? 'tak' : 'nie'}</div>
                  </div>
                </div>
                <div className="col-sm-9">
                  {charge?.settlements?.map(income => (
                    // <div className="row bb bl">
                    <RowEditable onRemove={f.status === 2 && !f.is_auto ? () => props.removeIncome(f.id, income) : undefined}>
                      <div className="col-sm-1 br p-2 ">{income.title}</div>
                      <div className="col-sm-1 br p-2 ">{income.title_date}</div>
                      <div className="col-sm-1 br p-2 ">{income.title_currency}</div>
                      <div className="col-sm-1 br p-2 ">{income.created_date}</div>
                      <div className="col-sm-1 br p-2 ">{parseMoney(income.value)}</div>
                      <div className="col-sm-1 br p-2 ">{parseMoney(income.paid_value)}</div>
                      <div className="col-sm-1 br p-2 ">{parseMoney(income.to_pay_value)}</div>
                      <div className="col-sm-1 br p-2 ">{income.value_currency}</div>
                      <div className="col-sm-1 br p-2 ">-</div>
                      <div className="col-sm-1 br p-2 ">-</div>
                      <div className="col-sm-1 br p-2 ">{income.exchange_rate}</div>
                      <div className="col-sm-1 p-2">{income.settlement_method}</div>
                    </RowEditable>
                    // </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : null}

        {tab === 3 ?  (
          <div className="p-1">
            <div className="row mt-3">
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Numer dokumentu</div>
                  <div className="col-sm-6">{f.linked?.identifier}</div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Wartość brutto</div>
                  <div className="col-sm-6">{parseMoney(f.linked?.gross_total)}  {f.currency?.name}</div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Status</div>
                  <div className="col-sm-6">{f.linked?.is_settled ? 'Rozliczony' : 'Nierozliczony'}</div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Kontrahent (Nabywca)</div>
                  <div className="col-sm-6">{f.linked?.buyer?.name}</div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Rozliczono</div>
                  <div className="col-sm-6">{parseMoney(f.linked?.paid_value)} {f.currency?.name}</div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Data wystawienia</div>
                  <div className="col-sm-6">{f.linked?.issue_date}</div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Faktor (Sprzedawca)</div>
                  <div className="col-sm-6">{f.linked?.seller?.name}</div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Do rozliczenia</div>
                  <div className="col-sm-6">{parseMoney(f.linked?.to_pay_value)} {f.currency?.name}</div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="row">
                  <div className="col-sm-6 font-weight-bold grey text-right">Data sprzedaży</div>
                  <div className="col-sm-6">{f.linked?.sale_date}</div>
                </div>
              </div>
            </div>

            {/*<div className="row mt-3 bt bb">*/}
            {/*  <div className="col-sm-9 p-2 grey">Pozycje dokumentu</div>*/}
            {/*  <div className="col-sm-3 p-2 grey float-right text-right">*/}
            {/*    {isCorrection && !isAutomat && (f.status === 2 || f.status === 3) ? <>*/}
            {/*      <button type="button" className="mr-2" onClick={() => settle(1)}>*/}
            {/*        Dodaj przelew*/}
            {/*      </button>*/}
            {/*      <button type="button" onClick={() => settle(2)}>*/}
            {/*        Dokument do rozliczenia*/}
            {/*      </button>*/}
            {/*    </> : null}*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className="row bt bb mt-4">
              <div className="col-sm-2 br p-2 grey">Identyfikator</div>
              <div className="col-sm-1 br p-2 grey">Kwota</div>
              <div className="col-sm-1 br p-2 grey">Rozliczono</div>
              <div className="col-sm-1 br p-2 grey">Do rozliczenia</div>
              <div className="col-sm-1 br p-2 grey">Czy rozliczony</div>
              <div className="col-sm-2 br p-2 grey">Data księgowania</div>
              <div className="col-sm-2 br p-2 grey">Data utworzenia</div>
              <div className="col-sm-2 p-2 grey">Status</div>
            </div>
            {f.linked?.subpayments?.map(link => (
              <RowEditable>
                <div className="col-sm-2 br p-2 ">{link.identifier}</div>
                <div className="col-sm-1 br p-2 ">{link.amount} {link.amount_currency?.name}</div>
                <div className="col-sm-1 br p-2 ">{link.paid_value}  {link.amount_currency?.name}</div>
                <div className="col-sm-1 br p-2 ">{link.to_pay_value}  {link.amount_currency?.name}</div>
                <div className="col-sm-1 br p-2 ">{link.is_settled ? 'tak' : 'nie'}</div>
                <div className="col-sm-2 br p-2 ">{link.posting_date}</div>
                <div className="col-sm-2 br p-2 ">{link.created_date}</div>
                <div className="col-sm-2 p-2 ">{props.common.paymentData?.payment_statuses?.[link.status]}</div>
              </RowEditable>
            ))}
          </div>
        ) : null}

        <div className="row mb-3">
          <div className="mt-3 col-sm-12 text-center">
            {details ? null :<button
              type="button"
              onClick={() => props.toggleForm(props.formName)}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}>
                Anuluj
            </button>}
            {isEdit && tab !== 1 ? null : <button
              type="button"
              onClick={() => (details ? props.toggleForm(props.formName) : props.submitForm())}
              className="btn btn-primary ml-2  font-weight-light">
              {details ? 'Zamknij' : 'Zatwierdź'}
            </button>}
            {f.status === 2 ? (
              <button type="button" onClick={() => props.print(f.id)} className="btn btn-primary-outline ml-2  font-weight-light">
                  Drukuj
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <BaseModal size="extralarge" visible={props.formVisible(AddItemToInvoice)} header={title} footer="" onClickAway={() => cancelItem()}>
        <div className="p-1">
          <div className="row bt bb mt-1">
            <div className="col-3 p-2 br grey">Nazwa towaru / usługi <Required /></div>
            <div className="col-1 p-2 br grey">CN / PKWiU</div>
            <div className="col-1 p-2 br grey">GTU</div>
            <div className="col-1 p-2 br grey">Jednostka <Required /></div>
            <div className="col-1 p-2 br grey">Ilość <Required /></div>
            <div className="col-2 p-2 br grey">Cena jednostkowa netto <Required /></div>
            <div className="col-1 p-2 br grey">Rabat</div>
            <div className="col-1 p-2 br grey">VAT <Required /></div>
            <div className="col-1 p-2 grey">Wartość netto</div>
          </div>
          <div className="row align-items-center">
            <div className="col-3 p-2 br ">
              {nameAsList ? (
                <Select
                  formName={props.formName}
                  name={`items.${key}.name`}
                  options={props.common?.invoiceTypes?.map(x => ({ ...x, value: x.name }))}
                />
              ) : (
                <Input type="text" formName={props.formName} name={`items.${key}.name`} />
              )}
            </div>
            <div className="col-1 p-2 br ">
              <Input type="text" formName={props.formName} name={`items.${key}.code`} />
            </div>
            <div className="col-1 p-2 br ">
              <Select isClearable formName={props.formName} name={`items.${key}.gtu`} options={props.common?.gtuCodes?.map(x => ({ ...x, value: x.id }))} />
            </div>
            <div className="col-1 p-2 br ">
              <Select
                formName={props.formName}
                name={`items.${key}.unit`}
                options={Object.keys(props.common?.invoiceData?.units).map(k => ({ value: k, name: k }))}
              />
            </div>
            <div className="col-1 p-2 br ">
              <Input type="text" formName={props.formName} name={`items.${key}.amount`} />
            </div>
            <div className="col-2 p-2 br ">
              <Input type="text" formName={props.formName} name={`items.${key}.unit_net_price`} />
            </div>
            <div className="col-1 p-2 br ">
              <Input type="text" formName={props.formName} name={`items.${key}.discount`} />
            </div>
            <div className="col-1 p-2 br">
              <Select formName={props.formName} name={`items.${key}.vat_id`} options={props.common?.vat?.map(v => ({ ...v, value: v.id }))} />
            </div>
            <div className="col-1 p-2">{parseMoney(value(f.items?.[key]))}</div>
          </div>
          <div className="row mt-3 align-items-center justify-content-center">
            <button type="button" onClick={() => cancelItem()} className="btn btn-primary-outline mr-2 font-weight-light">
              Anuluj
            </button>
            <button
              disabled={!f.items?.[key]?.name || f.items?.[key]?.name === ''}
              type="button"
              onClick={() => saveItem()}
              className="btn btn-primary btn-brown ml-2 font-weight-light">
              Zapisz
            </button>
          </div>
        </div>
      </BaseModal>

      <Selectable
        filtersForm={props.agreementFilters}
        fetch={props.fetchAgreements}
        visible={props.formVisible('invoice_agreements')}
        formName={props.formName}
        field="agreement"
        columns={[{ name: 'Identyfikator', getter: 'identifier', sortable: 1 }, { name: 'Klient', getter: 'client.name', sortable: 1 }]}
        options={props.agreements}
        onClickAway={() => props.toggleForm('invoice_agreements')}
        header="Wybierz umowę"
        onSelected={() => {
          props.clear(props.formName, 'manual_bank_account');
          props.clear(props.formName, 'seller_bank_account');
        }}
      />
      <Selectable
        filtersForm={props.factorFilters}
        fetch={props.fetchFactors}
        visible={props.formVisible('invoice_factors')}
        formName={props.formName}
        field="seller"
        columns={[{ name: 'Nazwa', getter: 'name', sortable: 1 }]}
        options={props.factors}
        onClickAway={() => props.toggleForm('invoice_factors')}
        header="Wybierz faktora"
      />
      <Selectable
        filtersForm={props.clientFilters}
        fetch={props.fetchClients}
        visible={props.formVisible('invoice_clients')}
        formName={props.formName}
        field="buyer"
        columns={[{ name: 'Nazwa', getter: 'name', sortable: 1 }]}
        options={props.clients}
        onClickAway={() => props.toggleForm('invoice_clients')}
        header="Wybierz klienta"
      />
      <Selectable
        filtersForm={props.accountsFilters}
        fetch={props.fetchAccounts}
        visible={props.formVisible('invoice_accounts')}
        formName={props.formName}
        field={f.agreement?.id ? 'seller_bank_account' : 'manual_bank_account'}
        columns={[
          { name: 'Numer rachunku', getter: 'number', sortable: 1 },
          { name: 'Waluta', getter: 'currency.name', sortable: 1 },
          { name: 'Typ rachunku', getter: 'bank_account_type.name', sortable: 1 },
          { name: 'Nazwa banku', getter: 'bank_name', sortable: 1 },
          { name: 'Data dodania', getter: 'created_date', sortable: 1 },
          { name: 'Dodał', getter: 'user_creator.full_name', sortable: 1 }
        ]}
        options={props.accounts}
        onClickAway={() => props.toggleForm('invoice_accounts')}
        header="Wybierz rachunek"
      />


      <BaseModal
        visible={props.formVisible(transferForm)}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" /> <span>Dodawanie przelewu</span>
          </div>
        }
        footer="Aby zapisać dokument wciśnij Zapisz."
        onClickAway={() => props.toggleForm(transferForm)}>
        <TransferForm
          submitForm={() => props.submitForm(transferForm)}
          withButtons
          formVisible={props.formVisible}
          toggleForm={props.toggleForm}
          formName={transferForm}
          forms={props.forms}
          onCancelButtonClick={() => props.toggleForm(transferForm)}
          common={props.common}
          clear={props.clear}
        />
      </BaseModal>

      <Selectable
        noTermSearching
        filtersForm={props.docsFilters}
        fetch={props.fetchDocs}
        visible={props.formVisible(claimsForm)}
        formName={claimsForm}
        field="docs"
        title={
          (
            <div className="row mt-1 align-items-center mb-1">
              <div className="col-sm-1 p-2 text-right grey">Klient</div>
              <div className="col-sm-9 p-2 d-flex align-items-center">
                {props.forms?.[props.docsFilters]?.client_id?.name ?? '-'}
                <button type="button" className="ml-2" onClick={() => {
                  props.fetchClients();
                  props.toggleForm('settlements_client');
                }}>Wybierz</button>
              </div>
              <div className="col-sm-2 p-2 text-right grey d-flex align-items-center">
                <Select className="w-100" formName={props.docsFilters} options={[
                  { value: null, name: 'Wszystkie' },
                  { value: 1, name: 'Tylko rozliczone' },
                  { value: 0, name: 'Tylko nierozliczone' }
                ]} name="is_settled"
                onChange={() => {props.fetchDocs();}}
                />
                {/*Tylko nierozliczone*/}
                {/*<BooleanCheck className="ml-2" field={!props.forms?.[props.docsFilters]?.is_settled} onClick={() => {*/}
                {/*  props.storeInput(props.docsFilters, 'is_settled', !props.forms?.[props.docsFilters]?.is_settled);*/}
                {/*  props.fetchDocs();*/}
                {/*}}/>*/}
              </div>
            </div>
          )
        }
        columns={[
          { name: 'Tytuł', getter: 'identifier', sortable: 1 },
          { name: 'Dokument', getter: 'test', sortable: 1 },
          // { name: 'Kategoria', getter: 'number', sortable: 1 },
          {
            name: 'Termin płatności',
            getter: 'payment_date',
            sortable: 1
          },
          // { name: 'Rozliczono', getter: 'number', sortable: 1 },
          { name: 'Kategoria', getter: 'context', sortable: 1 },
          {
            name: 'Wartość brutto',
            getter: 'gross_value',
            mutator: parseMoney,
            sortable: 1
          }, {
            name: 'Do rozliczenia',
            getter: 'to_pay_value',
            mutator: parseMoney,
            sortable: 1
          }, {
            name: 'Status',
            getter: 'status',
            sortable: 1
          }
        ]}
        options={props.docs}
        onClickAway={() => props.toggleForm(claimsForm)}
        header="Wybierz dokument do rozliczenia"
      />

      <Selectable
        filtersForm={props.clientFilters}
        fetch={props.fetchClients}
        visible={props.formVisible('settlements_client')}
        formName={props.docsFilters}
        field="client_id"
        columns={[{ name: 'Nazwa', getter: 'name', sortable: 1 }]}
        options={props.clients}
        onClickAway={() => props.toggleForm('settlements_client')}
        header="Wybierz klienta"
        onSelected={() => {
          props.fetchDocs();
        }}
      />
    </Form>
  );
};
