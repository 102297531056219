import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { formatValueToEurope } from '../../../../utilities/helpers';
import { ConditionsForm } from '../../Forms/Conditions';

export const Conditions = (props) => {
  const formName = 'ConditionsForm';
  const calculation = props.calculation || {};
  const calculationToEdit = {
    ...calculation,
    currency_id: calculation.currency ? calculation.currency.id : null,
    oa_lump_sum_currency: calculation.oa_lump_sum_currency || '',
    claim_limit_currency: calculation.claim_limit_currency || ''
  };
  delete calculationToEdit.currency;

  const repaymentOfCapital = props.offerData && props.offerData.repayment_of_capital ? props.offerData.repayment_of_capital.map((i) => ({ ...i, value: i.id })) : [];

  return (
    <Card header='Warunki dodatkowe' onEditClick={() => props.edit(formName, calculationToEdit)}>
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Max termin wymagalności</div>
              <div className="col-sm-9 p-2">
                {calculation.max_maturity
                  ? `${calculation.max_maturity} ${calculation.max_maturity === 1 ? 'dzień' : 'dni'}`
                  : 'brak'}
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Max okres finansowania</div>
              <div className="col-sm-9 p-2">
                {calculation.max_financing_period
                  ? `${calculation.max_financing_period} ${calculation.max_financing_period === 1 ? 'dzień' : 'dni'}`
                  : 'brak'}
                {' '}
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Limit na pojedynczą wierzytelność</div>
              <div className="col-sm-9 p-2">
                {formatValueToEurope(calculation.claim_limit, calculation.currency?.name)}
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Metoda spłaty kapitału</div>
              <div className="col-sm-9 p-2">
                {repaymentOfCapital.find((i) => i.id === calculation.repayment_of_capital)
                  ? repaymentOfCapital.find((i) => i.id === calculation.repayment_of_capital).name
                  : 'brak'}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3 p-2 br grey">Podzielona płatność</div>
              <div className="col-sm-9 p-2">
                <BooleanCheck field={calculation.split_payment} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Warunki dodatkowe</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <ConditionsForm
          repaymentOfCapital={repaymentOfCapital}
          forms={props.forms}
          currencies={props.currencies}
          calculationId={calculation.id}
          calculation={calculation}
          preloadedForm
          visibleOnMount={false}
          clear={props.clear}
          visible={props.formVisible(formName)}
          formName={formName}
          submitForm={() => props.saveForm(formName)}
          onCancelButtonClick={() => props.toggleForm(formName)}
          withButtons
        />
      </BaseModal>
    </Card>
  );
};
