import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import { FormattedAddress } from 'containers/Companies/Creator/Components/Addresses';
import React from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { RowEditable } from '../../../../components/Layout/RowEditable';
import { AddressesForm } from '../../Forms/Address';
import { TextLink } from 'containers/Companies/Details/Components/Notes';

export const Addresses = props => {
  const formName = 'AddressesCreatorForm';
  const modalFormName = 'AddressesCreatorEditForm';

  const selectedType = props.forms[formName] ? props.forms[formName].address_type : null;
  const modalSelectedType = props.forms[modalFormName] ? props.forms[modalFormName].address_type : null;

  const addresses = props.person && props.person.addresses ? props.person.addresses : [];

  return (
    <Card mode='creator' header='+ Adresy' onSaveClick={() => props.submitForm(formName)}>
      <AddressesForm
        visibleOnMount
        visible={props.formVisible(formName)}
        formName={formName}
        addressTypes={props.addressTypes}
        selectButtonDisabled={false}
        selectButtonValue={selectedType}
        typeSelected={selectedType}
        showIsActiveField={selectedType === 'email' || selectedType === 'phone'}
        voivodeships={props.voivodeships}
        populate={address => {
          delete address.id;
          delete address.address_type;
          props.populate(formName, address);
        }}
        buttonOnClick={() => props.clear(formName, 'address_type')}
        addresses={addresses}
        form={props.forms[formName]}
      />

      <div className="collapse show mt-3" id="adresy">
        {addresses.map((address, k) => (
          <div key={address.id} className="row">
            <div className="col-sm-12">
              <RowEditable
                index={k}
                key={`${address.type}-${address.id}`}
                itemId={address.id}
                onEdit={() => props.edit(address, modalFormName)}
                onRemove={() => {
                  props.remove(address.id);
                }}>
                <div className="col-sm-4 p-2 br">
                  <a
                    role="button"
                    style={{ color: 'black' }}
                    className="collapsed"
                    data-toggle="collapse"
                    href={`#adres${k}`}
                    aria-expanded="false"
                    aria-controls="collapseExample">
                    <i className="fa fa-sort-down mr-2" />
                    {address.name}
                  </a>
                </div>
                <div className="col-sm-6 p-2">
                  <a
                    role="button"
                    style={{ color: 'black' }}
                    className="collapsed"
                    data-toggle="collapse"
                    href={`#adres${k}`}
                    aria-expanded="false"
                    aria-controls="collapseExample">
                    <FormattedAddress address={address} />
                  </a>
                </div>
                <div className="col-sm-2 p-2">
                  <BooleanCheck field={address.is_active} />
                </div>
              </RowEditable>
            </div>

            <div className="col-sm-12">
              <div className="collapse" id={`adres${k}`}>
                <div className="row bb">
                  <div className="col-sm-12 p-2">
                    Gmina: {address.commune}, powiat: {address.county}, województwo: {address.voivodeship}, kraj: {address.country}
                  </div>
                </div>
                <div className="row bb grey">
                  <div className="col-sm-4 p-2 br">Wprowadził</div>
                  <div className="col-sm-4 p-2 br">Deaktywował</div>
                  <div className="col-sm-4 p-2">Ważny do</div>
                </div>
                <div className="row bb">
                  <div className="col-sm-4 p-2 br">{address.user_creator ? address.user_creator.full_name : '-'}</div>
                  <div className="col-sm-4 p-2 br">{address.user_deactivator ? address.user_deactivator.full_name : '-'}</div>
                  <div className="col-sm-4 p-2">{address.valid_until_date || '-'}</div>
                </div>
                <div className="row bb">
                  <div className="col-sm-4 p-2 br grey">Komentarz</div>
                  <div className="col-sm-8 p-2">
                    <TextLink>{address.comment}</TextLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <BaseModal
        visible={props.formVisible(modalFormName)}
        header="Adresy"
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}>
        <AddressesForm
          visibleOnMount={false}
          visible={props.formVisible(modalFormName)}
          formName={modalFormName}
          addressTypes={props.addressTypes}
          selectButtonDisabled={0}
          selectButtonValue={modalSelectedType || String(modalSelectedType)}
          typeSelected={modalSelectedType || String(modalSelectedType)}
          withButtons
          submitForm={() => props.submitForm(modalFormName, true)}
          onCancelButtonClick={() => props.toggleForm(modalFormName)}
          voivodeships={props.voivodeships}
          populate={address => {
            delete address.id;
            delete address.address_type;
            props.populate(formName, address);
          }}
          buttonOnClick={() => props.clear(formName, 'address_type')}
          addresses={addresses}
          form={props.forms[formName]}
        />
      </BaseModal>
    </Card>
  );
};
