import api from 'api';
import Col, { ColNameBig } from 'components/Forms/Col';
import Selectable from 'components/Forms/Selectable';
import { t } from 'lang/pl';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { storeInput } from 'reducers/forms';
import { isAnyEmpty } from 'utilities/helpers';

import {
  Buttons,   Form, Select
} from '../../../components/Forms';

export const RolesForm = (props) => {
  const { forms } = useSelector(state => state.Forms);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if(isAnyEmpty(forms[props.formName])) {
      toast.warn(t.error.toast.fieldWarning);
    } else {
      props.submitForm();
    }
  };

  return (
    <>
      <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
        <div className="t-row mt-3">
          <div className="flex-2 t-row">
            <ColNameBig>Rola biznesowa</ColNameBig>
            <Col className="flex-1">
              <Select formName={props.formName} options={props.roles} name="role" errorName='roles_ids' />
            </Col>
          </div>
          <div className="flex-5 mt-05">
            <div className="t-row align-items-center">
              <ColNameBig>W firmie</ColNameBig>
              <Col className="flex-1">
                <Selectable
                  title="Czy chcesz powiązać z firmą"
                  columns={['Nazwa firmy', 'NIP', 'Adres e-mail']}
                  names={['name', 'nip', 'active_email']}
                  formName={props.formName}
                  name='company'
                  apiFunction={api.companies.getList}
                />
              </Col>
            </div>
          </div>
        </div>
        {props.withButtons ? (
          <Buttons
            formName={props.formName}
            onCancel={props.onCancel}
            onSubmit={handleSubmit}
            onSubmitAndLeave={props.onSubmitAndLeave}
          />
        ) : null}
      </Form>
    </>
  );
};
