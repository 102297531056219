import { Pagination } from 'components/Layout/Pagination';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ClaimActions from '../../../actions/claim';
import CommonActions from '../../../actions/common';
import FormActions from '../../../actions/forms';
import OldOrderBy from 'components/Forms/OldOrderBy';
import { Container, MainContainer, SidebarContainer } from '../../../components/Layout';
import { BaseModal } from 'components/Layout/OldModal';
import { RowEditable } from '../../../components/Layout/RowEditable';
import Constants from '../../../utilities/constants';
import { parseMoney } from '../../../utilities/helpers';
import { OldSearchBar } from 'containers/OldSearchBar';
import Tab from '../../Tab';
import CreateClaimForm from '../Forms/Create';
import ClaimSidebarView from './Sidebar';
import Tooltip from 'components/Layout/Tooltip';
import { toast } from 'react-toastify';
import { t } from 'lang/pl';
import OldListFilters from 'components/OldListFilters';

class ClaimList extends React.Component {
  constructor(props) {
    super(props);

    this.filtersForm = 'ClaimsFilters';
    this.createClaimForm = 'CreateClaimForm';
    this.deleteClaimForm = 'DeleteClaimForm';

    this.props.dispatch(CommonActions.changeView(Constants.Views.List));
    this.props.dispatch(ClaimActions.fetch({ page: 1, limit: this.props.options.limit }));
  }

  componentDidMount() {
    this.props.dispatch(CommonActions.setContextMenu());
  }

  getFilters = () => {
    const currentFilters = this.props.forms.forms[this.filtersForm] || {};
    let params = {};
    let form = {};

    if (this.props.history.location.search) {
      const urlParams = new URLSearchParams(this.props.history.location.search.replace('?', ''));
      params = Object.fromEntries(urlParams);
    }

    if (!currentFilters || Object.keys(currentFilters).length <= Object.keys(CommonActions.defaultFilters).length) {
      form = { ...CommonActions.defaultFilters, ...params, ...currentFilters };
    } else {
      form = { ...currentFilters, ...params };
      if (!params.status && form.status) {
        delete form.status;
      }
    }

    this.props.dispatch(FormActions.initialize(this.filtersForm, form, {}));
    return form;
  };

  handleChangePage = (page, limit) => {
    this.props.dispatch(FormActions.storeInput(this.filtersForm, 'page', page));
    this.props.dispatch(FormActions.storeInput(this.filtersForm, 'limit', limit));
    this.fetchResults();
  };

  fetchResults() {
    this.props.dispatch(ClaimActions.fetch(this.getFilters()));
  }

  createClaim() {
    this.props.dispatch(FormActions.clearAllForms());
    this.props.dispatch(FormActions.initialize(this.createClaimForm, {}, {}));
    this.props.dispatch(FormActions.toggleForm(this.createClaimForm));
  }

  selectClaimForPreview(claim) {
    this.props.dispatch(ClaimActions.preview(claim));
  }

  saveForm(stay = false) {
    const { push } = this.props.history;
    this.props.dispatch(ClaimActions.create(this.createClaimForm, push, stay));
  }

  remove(id) {
    this.props.dispatch(ClaimActions.removeClaim(id));
  }

  toggleForm(f) {
    this.props.dispatch(FormActions.toggleForm(f));
  }

  formVisible(f) {
    return this.props.forms.options.visible.indexOf(f) !== -1;
  }

  render() {
    const rows = this.props.claim?.list || [{}];

    return (
      <Container>
        <MainContainer>
          <OldSearchBar title="Lista wierzytelności" formName={this.filtersForm} onSubmit={() => this.fetchResults()} records={this.props.claim.count} />

          <Tab name={Constants.Tabs.Claims.name}>
            <div className="row mb-4 mt-3 align-items-center">
              <div className="col-sm-9">
                <OldListFilters formName={this.filtersForm} onSubmit={() => this.fetchResults()} />
              </div>
              <div className="col-sm-3 text-right">
                <h4 className="text-themecolor" style={{ cursor: 'pointer' }}>
                  <i className="fa fa-ellipsis-h mr-2" onClick={() => toast.error(t.error.toast.notImplemented)} />
                  <i className="fa fa-plus" onClick={() => this.createClaim()} />
                </h4>
              </div>
            </div>
            <div className="row bt bb"  style={{ fontSize: '87%' }}>
              <div className="col-sm-2 p-2 br ">
                <OldOrderBy
                  field="identifier"
                  spanClassName="ml-0"
                  column="ID"
                  formName={this.filtersForm}
                  onSubmit={() => this.fetchResults()}
                />
              </div>
              <div className="col-sm-1 p-2 br ">
                <OldOrderBy
                  field="document_no"
                  spanClassName="ml-0"
                  column="Nr dokumentu"
                  formName={this.filtersForm}
                  onSubmit={() => this.fetchResults()}
                />
              </div>
              <div className="col-sm-1 p-2 br ">
                <OldOrderBy
                  field="identifier"
                  spanClassName="ml-0"
                  column="Dłużnik"
                  formName={this.filtersForm}
                  onSubmit={() => this.fetchResults()}
                />
              </div>
              <div className="col-sm-2 p-2 br">
                <OldOrderBy
                  field="client"
                  spanClassName="ml-0"
                  column="Nr umowy"
                  formName={this.filtersForm}
                  onSubmit={() => this.fetchResults()}
                />
              </div>
              <div className="col-sm-2 p-2 br">
                <OldOrderBy
                  field="amount"
                  spanClassName="ml-0"
                  column="Faktorant"
                  formName={this.filtersForm}
                  onSubmit={() => this.fetchResults()}
                />
              </div>
              <div className="col-sm-1 p-2 br">
                <OldOrderBy
                  field="currency"
                  spanClassName="ml-0"
                  column="Kwota"
                  formName={this.filtersForm}
                  onSubmit={() => this.fetchResults()}
                />
              </div>
              <div className="col-sm-1 p-2 br">
                <OldOrderBy
                  field="currency"
                  spanClassName="ml-0"
                  column="Waluta"
                  formName={this.filtersForm}
                  onSubmit={() => this.fetchResults()}
                />
              </div>
              <div className="col-sm-1 p-2 br">
                <OldOrderBy
                  field="purchase_date"
                  spanClassName="ml-0"
                  column="Data wykupu"
                  formName={this.filtersForm}
                  onSubmit={() => this.fetchResults()}
                />
              </div>
              <div className="col-sm-1 p-2">
                <OldOrderBy
                  field="status"
                  spanClassName="ml-0"
                  column="Status"
                  formName={this.filtersForm}
                  onSubmit={() => this.fetchResults()}
                />
              </div>
            </div>
            {rows.length > 0
              ? rows.map((row) => (
                <RowEditable
                  key={row.id}
                  onDoubleClick={() => {
                    this.props.dispatch(ClaimActions.changeTab(1));
                    this.props.history.push(`/wierzytelnosci/${row.slug ?? 'test'}`);
                  }}
                  onClick={() => this.selectClaimForPreview(row)}
                >
                  <div className="col-sm-2 p-2 br"><Tooltip label={row.identifier} /></div>
                  <div className="col-sm-1 p-2 br">{row.document_no || '-'}</div>
                  <div className="col-sm-1 p-2 br">{row.debtor?.name}</div>
                  <div className="col-sm-2 p-2 br"><Tooltip label={row.agreement?.identifier} /></div>
                  {/* <div className="col-sm-1 p-2 br">0</div> */}
                  <div className="col-sm-2 p-2 br">{row.client?.name}</div>
                  <div className="col-sm-1 p-2 br">
                    {parseMoney(row.gross_value)}
                  </div>
                  <div className="col-sm-1 p-2 br">
                    {row.gross_value_currency?.name}
                  </div>
                  <div className="col-sm-1 p-2 br">{row.purchase_date}</div>
                  <div className="col-sm-1 p-2">
                    <span className="badge badge-warning pt-1 pb-1 pl-2 pr-2">
                      {this.props.common?.claimData?.claim_statuses?.[row.status]}
                    </span>
                  </div>
                  {/* <div className='col-sm-2 p-2'>?</div> */}
                </RowEditable>
              )) : (
                <div className="row">
                  <div className="col-sm-12 mt-3">Brak wyników.</div>
                </div>
              )}
          </Tab>

          {rows && (
            <Pagination
              withSelect
              count={this.props.claim.count}
              currentPage={this.props.claim.currentPage}
              onChangePage={this.handleChangePage}
            />
          )}

          <BaseModal
            contentStyle={{ padding: 0 }}
            visible={this.formVisible(this.createClaimForm)}
            header={<><span className="fas fa-plus ml-05 mr-10" />Kreator tworzenia wierzytelności krok 1 z 2</>}
            footer="Zapisz zmiany."
            onClickAway={() => this.toggleForm(this.createClaimForm)}
          >
            <CreateClaimForm
              className="px-0 py-10"
              errors={this.props.forms.errors[this.createClaimForm]}
              form={this.props.forms.forms[this.createClaimForm]}
              agreement={this.props.agreement}
              forms={this.props.forms.forms}
              clear={(form, field) => this.props.dispatch(FormActions.clearField(form, field))}
              visibleOnMount={false}
              visible={this.formVisible(this.createClaimForm)}
              toggleForm={(form) => this.toggleForm(form)}
              isVisible={(form) => this.formVisible(form)}
              formName={this.createClaimForm}
              withButtons
              submitForm={() => this.saveForm()}
              onCancel={() => this.toggleForm(this.createClaimForm)}
              currencies={this.props.common.currencies}
              fetch={(t, f) => this.props.dispatch(ClaimActions.fetchForForm(t, f))}
              formData={this.props.claim?.formData}
              claimData={this.props.common?.claimData}
              agreementData={this.props.common?.agreementData}
              storeInput={(f, n, v) => this.props.dispatch(FormActions.storeInput(f, n, v))}
              representations={this.props.common?.companyData?.representations}
              onSubmitAndLeave={() => this.saveForm(true)}
            />
          </BaseModal>
        </MainContainer>
        <SidebarContainer>
          <ClaimSidebarView
            remove={(id) => this.remove(id)}
            claim={this.props.claim?.preview}
            loading={false}
            common={this.props.common}
          />
        </SidebarContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  forms: state.Forms,
  common: state.Common,
  agreement: state.Agreement,
  options: state.Options,
  claim: state.Claim
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(ClaimList)
);
