import Autocomplete from '@material-ui/lab/Autocomplete';
import { get } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeInput } from 'reducers/forms';

import { Form, Input, Select } from '../../../../components/Forms';
import { RowEditable } from '../../../../components/Layout/RowEditable';

const days_after_payment_date = [
  { value: '15', name: '15' },
  { value: '30', name: '30' },
  { value: '45', name: '45' },
  { value: '60', name: '60' },
  { value: '120', name: '120' },
  { value: '365', name: '365' }
];

const AdditionalRatesForm = (props) => {
  const bidTypes = props.bidTypes || [];
  const f = props.form || { additionalbids: [] };

  const { forms } = useSelector(state => state.Forms);
  const dispatch = useDispatch();

  const handleOnChange = name => (event, newValue) => {
    dispatch(storeInput(props.formName, name, newValue));
  };

  return (
    <Form
      noErrors
      asDiv
      visibleOnMount
      className="form-horizontal"
      formName={props.formName}
    >
      <div className="row mt-3">
        <div className="col-sm-3 grey">Nazwa stawki</div>
        <div className="col-sm-3 grey">Typ stawki</div>
        <div className="col-sm-3 grey">Przeterminowanie ponad (dni)</div>
        <div className="col-sm-3 grey">Wysokość stawki</div>
      </div>
      {f.additionalbids.map((bid, k) => (
        <RowEditable
          className="row mb-1"
          onRemove={() => props.removeField(`additionalbids.${k}`, props.formName)}
        >
          {/* <div className="row mt-1"> */}
          <div className="col-sm-3 grey">
            <Input formName={props.formName} type="text" name={`additionalbids.${k}.name`} />
          </div>
          <div className="col-sm-3 grey">
            <Select number disabled={props.selectDisabled || false} formName={props.formName} options={bidTypes} name={`additionalbids.${k}.bid_type`} />
          </div>
          <div className="col-sm-3 grey">
            <Autocomplete
              freeSolo
              value={get(forms[props.formName], `additionalbids.${k}.days_after_payment_date`, '')}
              onInputChange={handleOnChange(`additionalbids.${k}.days_after_payment_date`)}
              options={days_after_payment_date.map(item => item.name)}
              renderInput={(params) => (
                <div ref={params.InputProps.ref} className='form-group mb-0'>
                  <input {...params.inputProps} type="number" className='form-control' />
                </div>
              )}
            />
          </div>
          <div className="col-sm-3 grey">
            <Input formName={props.formName} type="text" name={`additionalbids.${k}.rate_amount`} />
          </div>
          {/* </div> */}
        </RowEditable>
      ))}

      <button type="button" onClick={() => props.addField('additionalbids', props.formName)} className="btn btn-sm">+ Dodaj</button>
    </Form>
  );
};
export default AdditionalRatesForm;
