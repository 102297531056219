import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../assets/scss/style.scss';
import '../assets/scss/colors/blue.scss';
import '../../node_modules/bootstrap/dist/js/bootstrap';
import '../assets/js/custom';

import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import AuthorizationActions from '../actions/authorization';
import CommonActions from '../actions/common';
import Constants from '../utilities/constants';
import ContextNavigation from './ContextNavigation';

const WithLayout = WrappedComponent => {
  class WL extends React.Component {
    componentDidMount() {
      this.props.dispatch(CommonActions.fetchDictionaries());
    }

    logout() {
      this.props.dispatch(AuthorizationActions.logout());
    }

    regenerateDictionaries() {
      this.props.dispatch(CommonActions.regenerateDictionaries());
    }

    render() {
      const avatar =
        this.props.authorization.user && this.props.authorization.user.avatar_url
          ? this.props.authorization.user.avatar_url
          : '/user.png';
      const userFullName = this.props.authorization.user ? this.props.authorization.user.full_name : '';
      const isDevelop = window.location.href.includes('dev');

      const [url] = this.props.match.path.split('/').filter(i => i);

      let view;
      if (this.props.common.view === Constants.Views.List) view = 'Lista';
      if (this.props.common.view === Constants.Views.Details) view = 'Szczegóły';
      if (this.props.common.view === Constants.Views.Creator) view = 'Kreator';


      return (
        <div id="main-wrapper">
          <header className="topbar">
            <nav className="navbar top-navbar fixed-top navbar-expand-md">
              <div className="navbar-collapse flex tems-center justify-between">
                <ul className="navbar-nav mt-md-0">
                  <strong onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                    <Link to="/">Home</Link>
                    <i className="fa fa-angle-right ml-2 mr-2" />
                    <Link to={`/${url}`} className="text-capitalize">
                      {url}
                    </Link>
                    <i className="fa fa-angle-right ml-2 mr-2" />
                    <span className="text-capitalize">{view}</span>
                  </strong>
                </ul>

                {isDevelop && <span className="text-20 font-bold text-red-0">DEVELOP</span>}

                <ul className="navbar-nav my-lg-0">
                  <li className="nav-item dropdown">
                    <Link
                      to="/"
                      role="button"
                      className="nav-link dropdown-toggle text-white waves-effect waves-dark"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                      <i className="fa fa-comment-alt" />
                      {/* <div className="notify">
                        <span className="heartbit" /> <span className="point" />
                    </div> */}
                    </Link>
                    <div className="dropdown-menu mailbox animated">
                      <ul>
                        <li>
                          <div className="drop-title">Powiadomienia</div>
                        </li>
                        {/*<li>*/}
                        {/*  <div className="message-center">*/}
                        {/*    <a role="button" href="/">*/}
                        {/*      <div className="btn btn-danger btn-circle">*/}
                        {/*        <i className="fa fa-link" />*/}
                        {/*      </div>*/}
                        {/*      <div className="mail-contnet">*/}
                        {/*        <h5>Luanch Admin</h5>*/}
                        {/*        <span className="mail-desc">Just see the my new admin!</span>*/}
                        {/*        <span className="time">9:30 AM</span>*/}
                        {/*      </div>*/}
                        {/*    </a>*/}
                        {/*  </div>*/}
                        {/*</li>*/}
                        <li>
                          <div className="drop-title">Brak</div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="nav-item dropdown d-flex align-self-center">
                    <Link to="/" role="button" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img src={avatar} width={25} height={25} alt="user" className="profile-pic" />
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                      <ul className="dropdown-user">
                        <li>
                          <div className="dw-user-box d-flex align-items-center">
                            <div className="u-img">
                              <img src={avatar} width={25} height={25} alt="user" />
                            </div>
                            <div className="u-text">
                              <h4>{userFullName}</h4>
                              {/* <h4 className="text-muted">email@domain.com</h4> */}
                            </div>
                          </div>
                        </li>
                        <li role="separator" className="divider" />
                        <li>
                          <Link role="button" to="#" onClick={() => this.regenerateDictionaries()}>
                            <i className="ti-user" />
                            Odśwież dane kategoryczne
                          </Link>
                        </li>
                        <li>
                          <Link role="button" to="#" onClick={() => this.logout()}>
                            <i className="ti-user" />
                            Wyloguj
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </header>
          <ContextNavigation menu={this.props.common.contextMenu} />

          <div className="page-wrapper mt-5 ">
            <WrappedComponent />
            <footer className="footer">
              © 2020 Smart Faktor
              <p className="small">
                <span className="mr-3">
                  Frontend:
                  {process.env.REACT_APP_FRONTEND_VERSION}
                </span>
                <span>
                  Backend:
                  {process.env.REACT_APP_BACKEND_VERSION}
                </span>
              </p>
            </footer>
          </div>
        </div>
      );
    }
  }

  const stateToProps = state => ({
    authorization: state.Authorization,
    common: state.Common
  });

  return withRouter(
    connect(
      stateToProps,
      null
    )(WL)
  );
};

export default WithLayout;
