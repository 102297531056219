const companies = service => ({
  getList: async ({ term, modifiedDate, orderBy, direction, page = 1, limit = 10 } = {}) =>
    service.get('companies/', {
      params: {
        page,
        limit,
        term,
        direction,
        order_by: orderBy,
        modified_date: modifiedDate
      }
    }),
  addRole: async (company_id, person_id, role_id) => service.post(`companies/${company_id}/person/`, {
    person_id: person_id,
    roles_ids: [role_id]
  }),
  checkId: async ({ identifier }) =>
    service.post('companies/check_company_id/', {
      identifier
    })
});

export default companies;
