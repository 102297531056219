import React from 'react';

import {
  DatepickerButton,
  Form
} from '../../../components/Forms';

export const GenerateNote = (props) => {
  const i = props.type && props.type === 'import';
  return (
    <Form className="form-horizontal" formName={props.formName}>

      <div className="row">
        <div className="col-sm-6">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
              Kontrahent (Nabywca)
            </label>
            <div className="col-sm-6">
              <span className="font-weight-bold">Coca Cola Sp. z o. o.</span>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Ilość pozycji
            </label>
            <div className="col-sm-6">
              2
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
              Faktor (Sprzedawca)
            </label>
            <div className="col-sm-6">
              <span className="font-weight-bold">Smart Faktor S.A.</span>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Kwota brutto
            </label>
            <div className="col-sm-6">
              10 950 PLN
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-6 col-form-label label-right">
              Termin płatności
            </label>
            <div className="col-sm-6">
              <span className="font-weight-bold">Kompensata</span>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group row  align-items-center">
            <label htmlFor="example-text-input " className="col-sm-4 col-form-label label-right">
              Data dokumentu
            </label>
            <div className="col-sm-6">
              <DatepickerButton name="datepicker" formName={props.formName} buttonOnClick={() => props.clear(props.formName, 'datepicker')} />
            </div>
          </div>
        </div>
      </div>

      {props.withButtons ? (
        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button
              type="button"
              onClick={() => props.toggleForm(props.formName)}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}
            >
              Anuluj
            </button>
            <button type="button" onClick={() => props.submitForm()} className={`btn btn-primary btn-brown ml-2  font-weight-light ${i ? 'btn-primary-blue' : 'btn-brown'}`}>
              Generuj dokument
            </button>
          </div>
        </div>
      ) : null}
    </Form>
  );
};
