import React from 'react';

import BooleanCheck from '../../../components/BooleanCheck';
import { Form } from '../../../components/Forms';

export const ClaimBidsForm = (props) => {
  const checkBid = (i, type) => {
    props.storeInput(props.formName, 'content_type', type === 1 ? props.claimData?.content_types?.basic_bid_id : props.claimData?.content_types?.multilevel_bid_id);
    props.storeInput(props.formName, 'object_id', i.id);
  };

  const getBidType = (id) => props.common?.offerData?.additional_bid_types?.find((x) => x.id === id).name ?? '-';

  return (
    <Form asDiv visibleOnMount={props.visibleOnMount} className="form-horizontal" divClasses={props.editMode ? 'container-fluid' : ''} formName={props.formName}>
      <div className="row bb" style={{ color: props.details ? '#007bff' : '#1DB393' }}>
        <div className="col-12 p-2">
          Stawki podstawowe
        </div>
      </div>
      <div className="row bb">
        <div className="col-sm-3 p-2 br grey">Nazwa stawki</div>
        <div className="col-sm-1 p-2 br grey">Max wysokość zaliczki</div>
        <div className="col-sm-1 p-2 br grey">Max termin płatności</div>
        <div className="col-sm-1 p-2 br grey">Stawka flat</div>
        <div className="col-sm-1 p-2  grey">Czy marża odsetkowa?</div>
        <div className="col-sm-1 p-2 bl br grey">Data stopy</div>
        <div className="col-sm-1 p-2 br grey">Stopa</div>
        <div className="col-sm-1 p-2 br grey">Czy zmienna</div>
        <div className="col-sm-1 p-2 grey">Marża odsetkowa</div>
      </div>
      {props.debtor?.basicbids_with_intrestmargins?.map((i) => (
        <div className="row bb d-flex align-items-center">
          <div className="col-sm-3 p-2 br d-flex align-items-center">
            <BooleanCheck className="mr-3" asRadioButton field={props.details ? props.claim?.object_id === i.id : props.form?.object_id === i.id} onClick={() => (props.details ? null : checkBid(i, 1))} />
            {i.name}
          </div>
          <div className="col-sm-1 p-2 br ">{i.max_advance_payment}</div>
          <div className="col-sm-1 p-2 br ">{i.max_payment_date}</div>
          <div className="col-sm-1 p-2 br ">{i.flat_rate}</div>
          <div className="col-sm-1 p-2 "><BooleanCheck field={i.is_intrest_margin} /></div>
          {i.date_of_rate && <div className="col-sm-1 p-2 bl br ">{i.date_of_rate}</div>}
          {i.intrest_rate && <div className="col-sm-1 p-2 br ">{i.intrest_rate?.name}</div>}
          {i.is_variable && <div className="col-sm-1 p-2 br "><BooleanCheck field={i.is_variable} /></div>}
          {i.value && <div className="col-sm-1 p-2 ">{i.value}</div>}
        </div>
      ))}
      <div className="row bb" style={{ color: props.details ? '#007bff' : '#1DB393' }}>
        <div className="col-12 p-2">
          Stawki złożone
        </div>
      </div>
      <div className="row bb">
        <div className="col-3 p-2 grey br">Nazwa stawki</div>
        <div className="col-1 p-2 grey br">Poziom</div>
        <div className="col-1 p-2 grey br">Max wysokość zaliczki</div>
        <div className="col-1 p-2 grey br">Max termin płatności</div>
        <div className="col-1 p-2 grey br">Stawka flat</div>
        <div className="col-3 p-2 grey">Nazwa dokumentu</div>
      </div>
      {props.debtor?.multilevelbids?.map((i) => i.levels.map((level, key) => (
        <div className="row bb">
          <div className="col-sm-3 p-2 br  d-flex align-items-center">{key === 0 ? <>
            <BooleanCheck className="mr-3" asRadioButton field={props.details ? props.claim?.object_id === i.id : props.form?.object_id === i.id} onClick={() => (props.details ? null : checkBid(i, 2))} />
            {i.name}
          </> : ''}</div>
          <div className="col-sm-1 p-2 br">{level.level_no}</div>
          <div className="col-sm-1 p-2 br">{level.max_advance_payment}</div>
          <div className="col-sm-1 p-2 br">{i.max_payment_date}</div>
          <div className="col-sm-1 p-2 br">{i.multilevel_flat_rate}</div>
          <div className="col-sm-3 p-2">{level.document_to_buy_types ? level.document_to_buy_types.map((x) => x.name).join(', ') : '-'}</div>
        </div>
      )))}

      {props.details && (
        <>
          <div className="row bb" style={{ color: props.details ? '#007bff' : '#1DB393' }}>
            <div className="col-12 p-2">
              Stawki dodatkowe
            </div>
          </div>
          <div className="row bb">
            <div className="col-sm-2 p-2 br grey">Nazwa stawki</div>
            <div className="col-sm-2 p-2 br grey">Typ stawki</div>
            <div className="col-sm-2 p-2 br grey">Przeterminowanie ponad (dni)</div>
            <div className="col-sm-2 p-2 grey">Stawka</div>
          </div>
          {props.debtor?.additionalbids?.map((i) => (
            <div className="row bb">
              <div className="col-sm-2 p-2 br ">{i.name}</div>
              <div className="col-sm-2 p-2 br ">{getBidType(i.bid_type)}</div>
              <div className="col-sm-2 p-2 br ">{i.days_after_payment_date}</div>
              <div className="col-sm-2 p-2 ">{i.rate_amount}</div>
            </div>
          ))}
        </>
      )}

      {props.withButtons ? (
        <div className="row">
          <div className="mt-4 col-sm-12 text-center">
            <button
              type="button"
              onClick={props.onCancel}
              className="btn btn-primary-outline mr-2 font-weight-light"
              style={{ color: '#4B4E55' }}
            >
              Anuluj
            </button>
            <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
              Zapisz
            </button>
          </div>
        </div>
      ) : null}
    </Form>
  );
};
