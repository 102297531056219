import Card from 'components/Layout/Card';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { populateForm } from 'reducers/forms';

import ScoringForm from '../../Forms/Scoring';

export default props => {
  const formName = 'ScoringCreatorForm';

  const { version } = useSelector(state => state.Proposal);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(populateForm(formName, {
      accounting_support_id: version.accounting_support?.id,
      accounting_type_id: version.accounting_type?.id,
      activity_range_id: version.activity_range?.id,
      company_started_date: version.company_started_date,
      first_invoice_date: version.first_invoice_date,
      insurance_claims: version.insurance_claims,
      main_pkd_id: version.main_pkd?.id,
      no_employees: version.no_employees,
      office_ownership_id: version.office_ownership?.id,
      recipients_number: version.recipients_number,
      suppliers_number: version.suppliers_number
    }));
    // eslint-disable-next-line
  }, [version]);

  return (
    <Card mode='creator' header='+ Faktorant dane scoringowe' onSaveClick={() => props.saveForm(formName, true)}>
      <div/>
      <ScoringForm
        companyData={props.companyData}
        visibleOnMount
        visible
        formName={formName}
        onSubmit={() => props.saveForm(formName)}
      />
    </Card>
  );
};
