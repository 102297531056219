import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import WithAuthorization from './components/WithAuthorization';
import WithRole from './components/WithRole';
import routes from './utilities/routes';

const App = () => (
  <Router>
    <main>
      {routes.map((route) => {
        let { component } = route;

        if (route.roles && route.roles.length > 0) {
          component = WithRole(route.roles)(component);
        }

        if (!route.guests) {
          component = WithAuthorization(component);
        }

        return <Route key={route.name} exact path={route.path} component={component} />;
      })}

      <ToastContainer
        className='toast-warning-container'
        position="bottom-left"
        autoClose={10000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </main>
  </Router>
);

export default App;
