import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useEffect } from 'react';

import { AdvanceForm } from '../../Forms/Advance';

const getMaxAdvancePayment = (claim) => {
  // @TODO fix agreement version
  const debtor = claim?.agreement?.agreement_versions?.[0]?.agreement_debtors?.find((x) => x.id === claim?.debtor?.id) ?? {};
  return [
    ...debtor.basicbids_with_intrestmargins ?? [],
    ...debtor.multilevelbids ?? []
  ].find((bid) => bid.id === claim.object_id)?.max_advance_payment ?? 0;
};

export const getForm = (claim) => ({
  percent_advance_payment: claim?.percent_advance_payment,
  advance_payment_value_custom: claim?.advance_payment_value_custom,
  advance_payment_vat_custom: claim?.advance_payment_vat_custom,
  currency: claim?.purchase_custom_value_currency?.name || claim?.purchase_currency?.name,
  vat_currency: claim?.purchase_custom_vat_value_currency?.name || claim?.purchase_currency?.name,
  max_advance_payment: getMaxAdvancePayment(claim),
  mpp: claim?.mpp,
  deducted_rates: claim?.deducted_rates,
  purchase_custom_value: claim?.purchase_custom_value,
  purchase_custom_vat_value: claim?.purchase_custom_vat_value,
  purchase_gross_value: claim?.purchase_gross_value,
  purchase_vat_value: claim?.purchase_vat_value,
  purchase_type: claim?.purchase_type,
  can_edit_advance_payment: claim?.can_edit_advance_payment
});

export default (props) => {
  const formName = 'ClaimAdvanceForm';

  useEffect(() => {
    if (props.populateForm) {
      const f = getForm(props.claim);

      if (!f.percent_advance_payment && f.advance_payment_value_custom === '0.00') {
        f.percent_advance_payment = Number(f.max_advance_payment);
      }

      props.populateForm(formName, f);
    }
    // eslint-disable-next-line
  }, [props.claim]);

  return (
    <Card
      mode={props.details ? '' : 'creator'}
      header={props.details ? 'Kalkulacja zaliczki' : '+ Kalkulacja zaliczki'}
      onEditClick={() => props.edit(formName, getForm(props.claim))}
      canEdit={!!(props.claim?.can_edit_advance_payment && props.edit && props.details)}
      onSaveClick={() => props.saveForm(formName, false)}
      canSave={!props.details}
    >
      <div className="collapse show" id="financialData">
        <AdvanceForm
          common={props.common}
          details={props.details}
          form={props.details ? getForm(props.claim) : props.forms?.forms?.[formName]}
          clear={props.clear}
          visibleOnMount={false}
          visible
          toggleForm={props.toggleForm}
          isVisible={() => false}
          formName={formName}
          submitForm={props.saveForm}
          onCancel={props.onCancel}
          currencies={props.common?.currencies}
          formData={props.claim?.formData}
          storeInput={(props.storeInput)}
          claim={props.claim}
        />
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        headerText="Kalkulacja zaliczki"
        footer=""
        onClickAway={() => props.toggleForm(formName)}
      >
        <AdvanceForm
          recalculateAdvance={props.recalculateAdvance}
          common={props.common}
          withButtons
          form={props.forms?.forms?.[formName]}
          clear={props.clear}
          visibleOnMount={false}
          visible
          toggleForm={props.toggleForm}
          isVisible={() => false}
          formName={formName}
          submitForm={() => props.saveForm(formName, true)}
          onCancel={() => props.toggleForm(formName)}
          currencies={props.common?.currencies}
          formData={props.claim?.formData}
          storeInput={props.storeInput}
          claim={props.claim}
        />
      </BaseModal>

    </Card>
  );
};
