import React from 'react';

import { Form, Input, Textarea } from '../../../components/Forms';

export const NoteForm = (props) => (
  <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
    {props.visible ? (
      <>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="form-group row  align-items-center">
              <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
                Temat notatki
              </label>
              <div className="col-sm-9">
                <Input formName={props.formName} type="text" name="name" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group row">
              <label htmlFor="example-text-input " className="col-sm-3 col-form-label label-right">
                Treść notatki
              </label>
              <div className="col-sm-9">
                <Textarea formName={props.formName} name="comment" />
              </div>
            </div>
          </div>
        </div>
        {props.withButtons ? (
          <div className="row">
            <div className="mt-4 col-sm-12 text-center">
              <button
                type="button"
                onClick={props.onCancelButtonClick}
                className="btn btn-primary-outline mr-2 font-weight-light"
                style={{ color: '#4B4E55' }}
              >
                Anuluj
              </button>
              <button type="button" onClick={() => props.submitForm()} className="btn btn-primary ml-2  font-weight-light">
                Zapisz
              </button>
            </div>
          </div>
        ) : null}
      </>
    ) : null}
  </Form>
);
