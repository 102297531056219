import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React, { useState } from 'react';

import { RowEditable } from '../../../../components/Layout/RowEditable';
import { AdditionalRatesForm } from '../../Forms/AdditionalRates';
import RemovalModal from '../../Forms/RemovalModal';

export const AdditionalRates = (props) => {
  const [objectToRemove, setObjectToRemove] = useState({});

  const formName = 'AdditionalRatesForm';
  const calculation = props.calculation || {};
  const bidTypes = props.offerData && props.offerData.additional_bid_types ? props.offerData.additional_bid_types.map((v) => ({ ...v, value: v.id })) : [];
  const bids = calculation.additional_bids
    ? calculation.additional_bids.map((b) => {
      const t = bidTypes.find((bb) => b.bid_type === bb.id);
      return { ...b, type: t ? t.name : '-' };
    })
    : [];

  return (
    <Card header='Stawki dodatkowe' onAddClick={() => props.create(formName)}>
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Nazwa stawki</div>
              <div className="col-sm-3 p-2 br grey">Typ stawki</div>
              <div className="col-sm-3 p-2 br grey">Przeterminowanie ponad (dni)</div>
              <div className="col-sm-3 p-2 grey">Stawka</div>
            </div>
            {bids.map((bid) => (
              <RowEditable
                key={bid.id}
                isObjectToRemove
                onEdit={() => props.edit({ ...bid, calculationId: calculation.id }, formName)}
                onRemove={() => {
                  setObjectToRemove(bid);
                }}
              >
                <div className="col-sm-3 p-2 br">{bid.name}</div>
                <div className="col-sm-3 p-2 br">{bid.type}</div>
                <div className="col-sm-3 p-2 br">{bid.days_after_payment_date}</div>
                <div className="col-sm-3 p-2">{bid.rate_amount}</div>
              </RowEditable>
            ))}
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Stawki dodatkowe</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <AdditionalRatesForm
          bidTypes={bidTypes}
          formName={formName}
          offer={props.offer}
          preloadedForm
          visibleOnMount={false}
          clear={props.clear}
          visible={props.formVisible(formName)}
          formVisible={props.formVisible}
          saveForm={() => props.saveForm(formName)}
          onCancel={() => props.onCancel(formName)}
          withButtons
          errors={props.errors[formName]}
          forms={props.forms}
          toggleForm={props.toggleForm}
        />
      </BaseModal>

      <RemovalModal
        visible={Object.keys(objectToRemove).length > 0}
        toggle={() => setObjectToRemove({})}
        remove={() => {
          props.remove(objectToRemove);
          setObjectToRemove({});
        }}
      >
        {objectToRemove && (
          <div className="container-fluid">
            <div className="row bb bt">
              <div className="col-sm-3 p-2 br grey">Nazwa stawki</div>
              <div className="col-sm-3 p-2 br grey">Typ stawki</div>
              <div className="col-sm-3 p-2 br grey">Przeterminowanie ponad (dni)</div>
              <div className="col-sm-3 p-2 grey">Stawka</div>
            </div>
            <div className="row bb">
              <div className="col-sm-3  p-2 br">{objectToRemove.name}</div>
              <div className="col-sm-3  p-2 br">{objectToRemove.type}</div>
              <div className="col-sm-3  p-2 br">{objectToRemove.days_after_payment_date}</div>
              <div className="col-sm-3  p-2">{objectToRemove.rate_amount}</div>
            </div>
          </div>
        )}
      </RemovalModal>
    </Card>
  );
};
