import qs from 'qs';

const authentication = service => ({
  signIn: async (username, password) =>
    service.post(
      'o/token/',
      qs.stringify({
        password,
        username,
        grant_type: 'password',
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET
      })
    ),

  getAccessToken: async refreshToken =>
    service.post(
      'o/token/',
      qs.stringify({
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    ),

  signOut: async token =>
    service.post(
      '',
      qs.stringify({
        client_id: process.env.REACT_APP_CLIENT_ID,
        token
      })
    )
});

export default authentication;
