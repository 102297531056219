import React from 'react';

export default (props) => {
  const mainClass = props.creator ? 'btn-primary' : 'btn-primary-blue';

  return (
    <div className="row align-items-center text-center">
      {props.steps.map((step) => (
        <div style={{ width: `${((1 / props.steps.length) * 100)}%`, padding: '1rem' }}>
          <button
            type="button"
            key={step.id}
            className={`btn tab-button ${props.tab === step.id ? `${mainClass}` : 'btn-grey'} btn-large pl-4 pr-4 pt-3 pb-3 w-100`}
            onClick={() => props.changeStep(step.id)}
          >
            {step.name}
          </button>
        </div>
      ))}
    </div>
  );
};
