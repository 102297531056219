import { BaseModal } from 'components/Layout/OldModal';
import { isEditableProposal } from 'containers/Proposals/Details/Index';
import { t } from 'lang/pl';
import React from 'react';
import { toast } from 'react-toastify';

import { RowEditable } from '../../../../../components/Layout/RowEditable';
import { parseMoney } from '../../../../../utilities/helpers';
import LiabilitiesForm from '../../../Forms/Liabilities/Other';
import Card from 'components/Layout/Card';

export default (props) => {
  const modalFormName = 'OtherLiabilitiesForm';

  const rows = props.version && props.version.other_obligations ? props.version.other_obligations : [];

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12 p-2 text-themecolor">
              Zobowiązania pozostałe

              <span className="float-right" style={{ cursor: 'pointer' }}>
                <i className="fa fa-plus ml-2" onClick={() => props.toggleForm(modalFormName)} />
              </span>
            </div>
          </div>
          <div className="row bt bb">
            <div className="col-sm-3 p-2 br grey">Rodzaj zadłużenia</div>
            <div className="col-sm-3 p-2 br grey">Wierzyciel</div>
            <div className="col-sm-2 p-2 br grey">Aktualne zadłużenie</div>
            <div className="col-sm-2 p-2 grey">Data spłaty</div>
          </div>
          {rows.length ? rows.map((row) => (
            <RowEditable
              key={row.identifier}
              isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
              onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
              onEdit={() => {
                const editableRow = {
                  type_of_dept_id: row.type_of_dept ? row.type_of_dept.id : null,
                  id: row.id,
                  current_debt: row.current_debt,
                  expiration_date: row.expiration_date,
                  creditor: row.creditor
                };
                delete editableRow.type_of_debt;
                props.edit(editableRow, modalFormName);
              }}
              onRemove={() => {
                props.remove('other_obligation', row.id);
              }}
            >
              <div className="col-sm-3 p-2 br ">{row.type_of_dept ? row.type_of_dept.name : '-'}</div>
              <div className="col-sm-3 p-2 br ">{row.creditor}</div>
              <div className="col-sm-2 p-2 br ">{parseMoney(row.current_debt)}</div>
              <div className="col-sm-2 p-2">{row.expiration_date}</div>
            </RowEditable>
          )) : <div className="row"><div className="col p-2"><span>Brak danych.</span></div></div>}
        </div>
      </div>

      <BaseModal
        isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
        onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
        visible={props.formVisible(modalFormName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>Zobowiązania pozostałe</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(modalFormName)}
      >
        <LiabilitiesForm
          withButtons
          proposalData={props.proposalData}
          populate={() => props.populateForm(modalFormName)}
          forms={props.forms}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(modalFormName)}
          formName={modalFormName}
          submitForm={() => props.saveForm(modalFormName)}
          onCancelButtonClick={() => props.toggleForm(modalFormName)}
        />

      </BaseModal>
    </>
  );
};
