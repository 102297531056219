import pl from 'date-fns/locale/pl';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { connect } from 'react-redux';

import FormActions from '../../actions/forms';

registerLocale('pl', pl);
setDefaultLocale('pl');

export default connect(
  (state) => ({ forms: state.Forms }),
  (dispatch) => ({
    storeInput: (f, n, v) => {
      v = moment(v).format('DD.MM.YYYY');
      dispatch(FormActions.storeInput(f, n, v));
    },
    deleteField: (form, field) => dispatch(FormActions.deleteField(form, field))
  })
)((props) => {
  useEffect(() => {
    if(props.defaultValue) {
      props.storeInput(props.formName, props.name, props.defaultValue);
    }
  }, []);

  const error = get(props.forms.errors[props.formName], props.name, props.error || null);
  let value = get(props.forms.forms[props.formName], props.name, props.defaultValue);
  value = value ? moment(value, 'DD.MM.YYYY')?.toDate() : value;

  return (
    <div className="form-group max-w-250 mb-0" style={props.style}>
      <DatePicker
        locale={pl}
        showMonthDropdown
        showYearDropdown
        selected={value}
        disabled={props.disabled || false}
        dateFormat="dd.MM.yyyy"
        className={`${props.className || ''} form-control ${error ? 'form-control-danger' : ''}`}
        onChange={(date) => {
          if(date) {
            props.storeInput(props.formName, props.name, date);
          } else {
            props.deleteField(props.formName, props.name);
          }

          if (props.onChange) {
            props.onChange();
          }
        }}
        style={{ zIndex: '99999' }}
        popperModifiers={{
          offset: {
            enabled: true,
            offset: props.offset || '-30px, 5px'
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: true,
            boundariesElement: 'viewport'
          }
        }}
        calendarContainer={props.calendarContainer}
      />
      <div className="form-control-feedback small min-h-15" style={{ color: 'red' }}>
        {error}
      </div>
    </div>
  );
});
