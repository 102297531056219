import React from 'react';
import { formatString } from 'utilities/helpers';

import { OldCard } from '../../../../components/Layout';

export const Header = ({ person }) => {
  const consent = person?.consents ? person?.consents[0] : {};

  const header = (
    <span className="mb-0 font-weight-light" style={{ color: '#006BFF', fontSize: '32px' }}>
      {`${person.first_name} ${person.second_name} ${person.last_name}`}
      {' '}
      (osoba)
    </span>
  );

  return (
    <OldCard header={header} contextMenu contextStyle={{ color: '#006BFF' }}>
      <div className="row mb-1 mt-3">
        <div className="col-sm-3 text-right grey">Utworzył</div>
        <div className="col-sm-3">{formatString(person?.user_creator?.full_name)}</div>
        <div className="col-sm-3 text-right grey">Telefon</div>
        <div className="col-sm-3">{formatString(person?.active_phone)}</div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-3 text-right grey">Utworzony dnia</div>
        <div className="col-sm-3">{formatString(person?.created_date)}</div>
        <div className="col-sm-3 text-right grey">ID obiektu</div>
        <div className="col-sm-3">{formatString(person?.identifier)}</div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-6 col-md-3 text-right grey">Ostatnia aktualizacja</div>
        <div className="col-sm-6 col-md-8">{formatString(person?.modified_date)}</div>
      </div>

      <div className="row mt-3 mb-1 pt-3" style={{ borderTop: '1px solid #DFE2EA' }}>
        <div className="col-sm-6 col-md-3 text-right grey">Data zgody</div>
        <div className="col-sm-6 col-md-8">{formatString(consent?.consent_logs?.slice(-1)[0]?.accepted_date)}</div>
      </div>
      <div className="row mb-1">
        <div className="col-sm-6 col-md-3 text-right grey">Rodzaj zgody</div>
        <div className="col-sm-6 col-md-8">{formatString(consent?.text)}</div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-6 col-md-3 text-right grey">Administrator danych</div>
        <div className="col-sm-6 col-md-8">{formatString(consent?.administrator?.name)}</div>
      </div>
    </OldCard>
  );
};
