import Button from 'components/Forms/Button';
import { BaseModal } from 'components/Layout/OldModal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function SelectModal({ register, name, setValue, title, options, children, ...props }) {
  const { user } = useSelector(state => state.Authorization);

  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if(options) {
      const factorName = user?.factor ? user?.factor[name] : null;
      const selectedOption = factorName ? factorName : options[0];

      setSelectedOption(selectedOption);
      setValue(name, selectedOption?.id);
    }
    // eslint-disable-next-line
  }, [options]);

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleSelectOption = option => () => {
    setSelectedOption(option);
  };

  const handleSubmit = () => {
    setOpen(false);
  };

  return (
    <div {...props}>
      <BaseModal
        visible={isOpen}
        onClickAway={handleModalClose}
        header={
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            <span>{title}</span>
          </div>
        }
      >
        {children && children}
        {options && (
          <table className="selectable">
            <thead>
              <tr>
                <th>
                  <span className="grey font-weight-light">Imię i nazwisko</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {options?.map(option => (
                  <td key={option.id} className={option.id === selectedOption?.id ? 'companyRowSelected' : ''} onClick={handleSelectOption(option)}>
                    <span className=" font-weight-light">{option.name}</span>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        )}

        <div className='flex justify-center mt-10'>
          <Button onClick={handleModalClose}>Anuluj</Button>
          <Button mode='primary' onClick={handleSubmit}>Zatwierdź</Button>
        </div>
      </BaseModal>

      <div className="flex items-center">
        <button type="button" className="float-right" onClick={handleModalOpen}>
          Zmień
        </button>
        <span className="ml-3" style={{ fontWeight: '600', color: '#4B4E55', fontSize: '14px' }}>
          <input className='hidden' name={name} ref={register} value={selectedOption?.id} />
          {selectedOption?.name ?? 'nie wybrano'}
        </span>
      </div>
      <div className='min-h-15' />
    </div>
  );
}

SelectModal.propTypes = {
  title: PropTypes.string.isRequired
};
