import agreements from 'api/agreements';
import authentication from 'api/authentication';
import claims from 'api/claims';
import companies from 'api/companies';
import offers from 'api/offers';
import persons from 'api/persons';
import proposal from 'api/proposal';
import user from 'api/user';
import HttpClient from 'utilities/http';

// navigator.language
export const getLanguage = () => 'pl';

export const defaultHeaders = {
  'Content-Type': 'application/json',
  'Accept-Language': getLanguage()
};

const api = {
  authentication: authentication(HttpClient),
  persons: persons(HttpClient),
  companies: companies(HttpClient),
  user: user(HttpClient),
  proposal: proposal(HttpClient),
  agreements: agreements(HttpClient),
  claims: claims(HttpClient),
  offers: offers(HttpClient)
};

export default api;
