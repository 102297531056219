import api from 'api';
import { ColNameBig } from 'components/Forms/Col';
import Selectable from 'components/Forms/Selectable';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withSelectOptions } from 'utilities/helpers';

import { Form, Input, Select, SelectButton } from '../../../components/Forms';

export const CommissionForm = props => {
  const [filteredTypes, setFilteredTypes] = useState([]);
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [hasMatchingCommissions, setHasMatchingCommissions] = useState(false);
  const { commissionRoles, commissions } = useSelector(state => state.Common);

  const f = props.form || {};

  useEffect(() => {
    updateBeneficiaryCommissions();
    // eslint-disable-next-line
  }, props);

  useEffect(() => {
    if (f.id && filteredUnits.length === 0) {
      updateFilteredUnits();
    }
    // eslint-disable-next-line
  }, [filteredTypes]);

  const updateBeneficiaryCommissions = () => {
    setFilteredTypes([]);
    setFilteredUnits([]);
    setHasMatchingCommissions(false);

    if (f.role_identifier && f.beneficiary?.id) {
      const parentName = commissionRoles.find(role => role.identifier === f.role_identifier)?.name;
      const parents = f.beneficiary?.commission_parents?.filter(i => i.category && i.category.name === parentName) ?? [];

      if (parents.length > 0) {
        const tmpTypes = [];
        const parentCommissions = parents.map(parent => parent.commissions).flat();

        parentCommissions.forEach(c => {
          tmpTypes.push({ type: c.commission_type, unit: c.unit });
        });

        const types = commissions
          .filter(commission => tmpTypes.find(t => t.type === commission.id))
          .map(type => ({ ...type, units: type.units.filter(u => tmpTypes.find(t => t.type === type.id && t.unit === u.id)) }));

        parentCommissions.length && types.length && setHasMatchingCommissions(true);
        setFilteredTypes(types);
      }
    }
  };

  const updateFilteredUnits = () => {
    const units = filteredTypes.find(item => item.id === Number(f.commission_type))?.units.map(u => ({ ...u, value: u.id })) ?? [];
    setFilteredUnits(units);
  };

  return (
    <Form visibleOnMount={props.visibleOnMount} className="form-horizontal" formName={props.formName}>
      {props.visible ? (
        <>
          <div className="row mt-3">
            <div className="col-sm-6">
              <div className="form-group row  align-items-center">
                <ColNameBig>Dodaj prowizję</ColNameBig>
                <div className="col-sm-6">
                  {f.id ? (
                    <span className="mr-3" style={{ fontWeight: 600, color: 'rgb(75, 78, 85)', fontSize: '14px' }}>
                      {f.role}
                    </span>
                  ) : (
                    <SelectButton
                      buttonDisabled={f.id}
                      buttonOnClick={() => props.clear(props.formName, 'role_identifier')}
                      formName={props.formName}
                      options={commissionRoles.map(role => ({ ...role, name: role.name, value: role.identifier }))}
                      name="role_identifier"
                      value={f.role_identifier}
                      selectOnChange={() => props.clear(props.formName, 'beneficiary')}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group row align-items-center">
                <ColNameBig>Beneficjent</ColNameBig>
                <Selectable
                  isEditState={props.isEditState}
                  title="Wybierz beneficjenta"
                  apiFunction={api.offers.getCompaniesOrPeopleList}
                  columns={['Nazwa', 'Typ']}
                  names={['name', 'type']}
                  formName={props.formName}
                  name='beneficiary'
                  filter='role_identifier'
                  onChange={updateBeneficiaryCommissions}
                />
              </div>
            </div>
          </div>
          {f.role_identifier && f.beneficiary ? (
            <>
              {hasMatchingCommissions || f.id ? (
                <>
                  <div className="row mt-3">
                    <div className="col-sm-2 font-weight-bold grey">Typ</div>
                    <div className="col-sm-2 font-weight-bold grey">Sposób liczenia</div>
                    <div className="col-sm-2 font-weight-bold grey">Wysokość</div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2 grey">
                      <Select
                        disabled={f.id}
                        formName={props.formName}
                        options={withSelectOptions(filteredTypes)}
                        name="commission_type"
                        onChange={() => {
                          props.clear(props.formName, 'unit');
                          updateFilteredUnits();
                        }}
                      />
                    </div>
                    <div className="col-sm-2 grey">
                      <Select disabled={f.id} formName={props.formName} options={filteredUnits} name="unit" />
                    </div>
                    <div className="col-sm-2 grey">
                      <Input formName={props.formName} type="text" name="max_value" />
                    </div>
                  </div>
                </>
              ) : (
                <div className="row mt-3">
                  <div className="col-12">Brak prowizji dla wybranego beneficjenta.</div>
                </div>
              )}
            </>
          ) : null}

          {props.withButtons ? (
            <div className="row">
              <div className="mt-4 col-sm-12 text-center">
                <button
                  type="button"
                  onClick={props.onCancel}
                  className="btn btn-primary-outline mr-2 font-weight-light"
                  style={{ color: '#4B4E55' }}>
                  Anuluj
                </button>
                <button
                  type="button"
                  onClick={() => props.saveForm()}
                  className="btn btn-primary ml-2  font-weight-light">
                  Zapisz
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Form>
  );
};
