import Card from 'components/Layout/Card';
import React from 'react';

export const Engagement = () => {
  return (
    <Card header='Zaangażowanie'>
      <div>
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Zaangażowanie aktualne</div>
              <div className="col-sm-9 p-2 ">?</div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Zaangażowanie planowane</div>
              <div className="col-sm-9 p-2 ">?</div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Zaangażowanie całkowite</div>
              <div className="col-sm-9 p-2">?</div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Według produktów</div>
              <div className="col-sm-9">
                <div className="row">
                  <div className="col-sm-3 p-2 br grey">Nazwa produktu</div>
                  <div className="col-sm-3 p-2 br grey">Aktualne</div>
                  <div className="col-sm-3 p-2 br grey">Całkowite</div>
                  <div className="col-sm-3 p-2 grey">Planowane</div>
                </div>
                <div className="row bt">
                  <div className="col-sm-3 p-2 br ">?</div>
                  <div className="col-sm-3 p-2 br ">?</div>
                  <div className="col-sm-3 p-2 br ">?</div>
                  <div className="col-sm-3 p-2 ">?</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3 p-2 br grey">Zaangażowanie w przychody</div>
              <div className="col-sm-9 p-2 ">?</div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
