import Card from 'components/Layout/Card';
import { BaseModal } from 'components/Layout/OldModal';
import React from 'react';

import BooleanCheck from '../../../../components/BooleanCheck';
import { ReadinessForm } from '../../Forms/Readiness';

export const Readiness = (props) => {
  const formName = 'ReadinessForm';

  const calculation = props.calculation || {};
  const calculationToEdit = {
    ...calculation,
    currency_id: calculation.currency ? calculation.currency.id : null,
    oa_lump_sum_currency: calculation.oa_lump_sum_currency || '',
    claim_limit_currency: calculation.claim_limit_currency || ''
  };
  delete calculationToEdit.currency;

  const getCountingMethod = (c) => {
    const method = props.countingMethods ? props.countingMethods.find((m) => m.value === c.counting_method) : null;
    return method ? method.name : 'brak';
  };

  return (
    <Card header='Gotowość' onEditClick={() => props.edit(formName, calculationToEdit)}>
      <div className="collapse show" id="gotowosc">
        <div className="row">
          <div className="col-sm-12">
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Deklarowane wykorzystanie</div>
              <div className="col-sm-9 p-2">{calculation.declared_use ? `${calculation.declared_use}%` : 'brak'}</div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Prowizja za gotowość</div>
              <div className="col-sm-9">
                <div className="row">
                  <div className="col-sm-6 br p-2">
                    Od kwoty limitu
                    <span className="float-right">
                      {calculation.preparation_commission_type === 0 ? calculation.limit_commission : '-'}
                    </span>
                  </div>
                  <div className="col-sm-6 p-2">
                    <BooleanCheck field={calculation.preparation_commission_type === 0} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 br p-2">
                    Od niewykorzystanej kwoty limitu
                    <span className="float-right">
                      {calculation.preparation_commission_type === 1 ? calculation.percentage_unused_amount_commission : '-'}
                    </span>
                  </div>
                  <div className="col-sm-6 p-2">
                    <BooleanCheck field={calculation.preparation_commission_type === 1} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 br p-2">
                    Ryczałt
                    <span className="float-right">
                      {calculation.preparation_commission_type === 2 ? calculation.unused_amount_commission : '-'}
                    </span>
                  </div>
                  <div className="col-sm-6 p-2">
                    <BooleanCheck field={calculation.preparation_commission_type === 2} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row bb">
              <div className="col-sm-3 p-2 br grey">Deklarowany czas rozruchu</div>
              <div className="col-sm-9 p-2">
                {calculation.start_up_contract
                  ? `${calculation.start_up_contract} ${calculation.start_up_contract === 1 ? 'dzień' : 'dni'}`
                  : 'brak'}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3 p-2 br grey">Okres rozliczeniowy</div>
              <div className="col-sm-3 p-2 br">
                {calculation.billing_period
                  ? `${calculation.billing_period} ${calculation.billing_period === 1 ? 'dzień' : 'dni'}`
                  : 'brak'}
              </div>
              <div className="col-sm-3 p-2 br grey">Metoda liczenia</div>
              <div className="col-sm-3 p-2">{getCountingMethod(calculation)}</div>
            </div>
          </div>
        </div>
      </div>

      <BaseModal
        visible={props.formVisible(formName)}
        header={(
          <div className="d-flex align-items-center">
            <i className="fas fa-pencil-alt mr-2" />
            {' '}
            <span>Gotowość</span>
          </div>
        )}
        footer="Aby zakończyć edycję wciśnij Zapisz."
        onClickAway={() => props.toggleForm(formName)}
      >
        <ReadinessForm
          countingMethods={props.countingMethods}
          offer={props.offer}
          forms={props.forms}
          populate={() => {}}
          preloadedForm
          visibleOnMount
          clear={props.clear}
          visible={props.formVisible(formName)}
          storeInput={props.storeInput}
          formName={formName}
          saveForm={() => props.saveForm(formName, false)}
          onCancel={() => props.toggleForm(formName)}
          withButtons
        />
      </BaseModal>
    </Card>
  );
};
