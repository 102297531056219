import DecisionString from 'components/Format/DecisionString';
import Card from 'components/Layout/Card';
import Tooltip from 'components/Layout/Tooltip';
import { isEditableProposal } from 'containers/Proposals/Details/Index';
import { t } from 'lang/pl';
import React from 'react';
import { toast } from 'react-toastify';

import { formatString, formatValueToEurope } from '../../../../utilities/helpers';

const getMonthFormat = number => {
  switch (number) {
    case 1: return `${number} miesiąc`;
    case 2: return `${number} miesiące`;
    case 3: return `${number} miesiące`;
    case 4: return `${number} miesiące`;
    default: return `${number} miesięcy`;
  }
};

export default (props) => {
  const proposalVersion = props.version || {};
  const proposal = props.proposal || {};
  const aml = proposalVersion.aml_decisions?.length ? proposalVersion.aml_decisions[0] : {};
  const financialData = proposalVersion.financial_data;
  const client = proposal.client ?? {};

  let visionSummary = {};
  let visionDate = {};

  if (proposalVersion.criteria_categories) {
    proposalVersion.criteria_categories.forEach((i) => {
      i.proposal_criteria.forEach((j) => {
        if (j.is_presented_in_decision) {
          if (j.field_date_value) {
            visionDate = j;
          } else {
            visionSummary = j;
          }
        }
      });
    });
  }

  const currentCriteria = props?.version?.criteria_categories
    ?.find(x => x?.identifier === 'ocena-wizji-lokalnej')?.proposal_criteria
    ?.find(criteria => criteria?.name?.toLowerCase()?.includes('rekomendacja na podstawie wizji'));

  return (
    <Card
      header='Faktorant ocena'
      isBlocked={!isEditableProposal(props.proposal.status, props.version.status)}
      onBlockedMessage={() => toast.error(t.error.toast.statusWarning)}
    >
      <div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">Wnioskowany limit</div>
          <div className="col-sm-3 p-2 br">{formatValueToEurope(proposal.limit_amount)}</div>
          <div className="col-sm-3 p-2 br grey">Kategoria Faktoranta</div>
          <div className="col-sm-3 p-2">{client?.category?.name ?? '-'}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">Dostępny limit / Wykorzystany</div>
          <div className="col-sm-3 p-2 br">
            {`${formatValueToEurope(proposalVersion.available_sublimit)} / ${formatValueToEurope(proposalVersion.used_sublimit)}`}
          </div>
          <div className="col-sm-3 p-2 br grey">Wiek firmy Faktoranta</div>
          <div className="col-sm-3 p-2">{client.company_age}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">Maksymalna ekspozycja F</div>
          <div className="col-sm-3 p-2 br">{formatValueToEurope(proposalVersion.max_exposure_f)}</div>
          <div className="col-sm-3 p-2 br grey">Współpraca z Faktorantem</div>
          <div className="col-sm-3 p-2">{getMonthFormat(client.coop_in_months)}</div>
        </div>

        <div className="row bb">
          <div className="col-sm-12 p-2 text-themecolor">
            Limity na Faktoranta
          </div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">Maksymalny limit</div>
          <div className="col-sm-3 p-2 br">{formatValueToEurope(proposalVersion.max_limit)}</div>
          <div className="col-sm-3 p-2 br grey">Przychody lub prognoza 12 m-cy</div>
          <div className="col-sm-3 p-2">{formatValueToEurope(proposalVersion.income_for_last_12_months)}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">Limit wywiadowni</div>
          <div className="col-sm-3 p-2 br">{formatValueToEurope(proposalVersion.information_agency_limit)}</div>
          <div className="col-sm-3 p-2 br grey">{proposalVersion.rating_type ? proposalVersion.rating_type.name : '-'}</div>
          <div className="col-sm-3 p-2">{proposalVersion.rating_class ? proposalVersion.rating_class.name : '-'}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">Wywiadownia / Rating / PMI</div>
          <div className="col-sm-3 p-2 br">
            {`${formatString(proposalVersion?.information_agency?.name)}
             / ${formatString(proposalVersion?.information_agency_class?.name)} 
             / ${formatString(proposalVersion?.information_agency_pmi)}`}
          </div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">Data raportu wywiadowni</div>
          <div className="col-sm-3 p-2 br">{proposalVersion.information_agency_report_date || '-'}</div>
        </div>

        <div className="row bb">
          <div className="col-sm-12 p-2 text-themecolor">
            Wizja
          </div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 br grey">Kryterium</div>
          <div className="col-sm-3 p-2 grey br">Pracownik</div>
          <div className="col-sm-3 p-2 grey">Rekomendacja</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 br " style={{ color: '#7E6F41' }}>{formatString(visionSummary?.name)}</div>
          <div className="col-sm-3 p-2  br">{formatString(visionSummary?.evaluative?.full_name)}</div>
          <div className="col-sm-3 p-2 "><DecisionString value={visionSummary?.field_option_value?.name}/></div>
        </div>
        <div className="row bb">
          <div className="col-sm-12 p-2 ">{formatString(visionSummary?.comment)}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 br grey">Kryterium</div>
          <div className="col-sm-3 p-2 grey br">Pracownik</div>
          <div className="col-sm-3 p-2 grey">Data wizji</div>
        </div>
        <div className="row bb">
          <div className="col-sm-6 p-2 br " style={{ color: '#7E6F41' }}>{visionDate.name || '-'}</div>
          <div className="col-sm-3 p-2  br">{visionDate.evaluative ? visionDate.evaluative.full_name : '-'}</div>
          <div className="col-sm-3 p-2 ">{visionDate.field_date_value || '-'}</div>
        </div>
        <div className="row bb">
          <div className="col-sm-12 p-2 ">{visionDate.comment || '-'}</div>
        </div>

        <div className="row bb">
          <div className="col-sm-12 p-2 text-themecolor">
            Ani-money laundering (AML)
          </div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">Data oceny AML</div>
          <div className="col-sm-3 p-2 grey br">Pracownik</div>
          <div className="col-sm-3 p-2 br grey">Ocena</div>
          <div className="col-sm-3 p-2 grey">Uwagi</div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br ">{aml.decision_date}</div>
          <div className="col-sm-3 p-2  br">{aml.decision_maker ? aml.decision_maker.full_name : '-'}</div>
          <div className="col-sm-3 p-2  br"><DecisionString value={aml.decision}/></div>
          <div className="col-sm-3 p-2 ">{aml.comments}</div>
        </div>

        <div className="row bb">
          <div className="col-sm-12 p-2 text-themecolor">
            Ryzyko fraudowe
          </div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">Data oceny</div>
          <div className="col-sm-3 p-2 grey br">Pracownik</div>
          <div className="col-sm-3 p-2 br grey">Ocena</div>
          <div className="col-sm-3 p-2 grey">Uwagi</div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br ">{aml.decision_date}</div>
          <div className="col-sm-3 p-2  br">{aml.decision_maker ? aml.decision_maker.full_name : '-'}</div>
          <div className="col-sm-3 p-2  br"><DecisionString value={aml.decision} /></div>
          <div className="col-sm-3 p-2 ">{aml.comments}</div>
        </div>

        <div className="row bb">
          <div className="col-sm-12 p-2 text-themecolor">
            Ocena scoringowa
          </div>
        </div>
        <div className="row bb">
          <div className="col-sm-3 p-2 br grey">Limit faktoranta</div>
          <div className="col-sm-3 p-2 grey br">Punktacja auto </div>
          <div className="col-sm-2 p-2 br grey">Wizja lokalna</div>
          <div className="col-sm-2 p-2 br grey">Ryzyko AML</div>
          <div className="col-sm-2 p-2 grey">Ryzyko Fraudowe</div>
        </div>
        <div className="row">
          <div className="col-sm-3 p-2 br "><Tooltip label='-' tip='Brak implementacji' /></div>
          <div className="col-sm-3 p-2  br"><Tooltip label='-' tip='Brak implementacji' /></div>
          <div className="col-sm-2 p-2 br "><DecisionString value={currentCriteria?.field_option_value?.name} /></div>
          <div className="col-sm-2 p-2 br "><DecisionString value={aml.decision} /></div>
          <div className="col-sm-2 p-2 "><DecisionString value={aml.decision} /></div>
        </div>

      </div>
    </Card>
  );
};
