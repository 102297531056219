import { cloneDeep, isObject, omit, pick } from 'lodash';
import moment from 'moment';

import HttpClient from '../utilities/http';

export const recalculate = (value, currency_id, is_nbp = false, is_mbank = false) =>
  HttpClient.post('/commons/exchange_rate/', {
    value,
    date: moment().format('DD.MM.YYYY'),
    currency_id,
    is_nbp,
    is_mbank
  });

export default {
  archive: (claimId, obj, objectId) => HttpClient.patch(`/claims/${claimId}/${obj}/${objectId}/archive/`),
  handleFile: (claimId, obj, objectId, attachmentId, action, del) =>
    HttpClient[del ? 'delete' : 'patch'](`/claims/${claimId}/${obj}/${objectId}/attachments/${attachmentId}/${action}/`),
  generateFinalPayment: claimId => HttpClient.post(`/claims/${claimId}/generate_final_payment/`),
  compensate: (subpaymentId, chargeId, c) => HttpClient.patch(c? `/payments/compensation/subpayment/${chargeId}/` : `/payments/subpayment/${subpaymentId}/income/${chargeId}/`),
  removeChargeFromPayment: (subpaymentId, chargeId) => HttpClient.delete(`/payments/subpayment/${subpaymentId}/income/${chargeId}/`),
  linkChargeWithPayment: (subpaymentId, id, isDocument) =>
    HttpClient.post(`/payments/subpayment/${subpaymentId}/create_link/`, {
      [isDocument ? 'invoice_id' : 'charge_id']: id
    }),
  fetchBuckets: (claim_id, filters) =>
    HttpClient.get('/claims/charges/', {
      params: {
        ...filters,
        claim_id,
        is_settled: 0
      }
    }),
  fetchDocuments: p => {
    return HttpClient.get('/invoices/', { params: { ...p, context: 1, is_settled: 0 } });
  },
  removeLink: (subpaymentId, paymentId) => HttpClient.delete(`/payments/${paymentId}/create_link/${subpaymentId}/`),
  resetDocumentsToBuy: claimId => HttpClient.put(`/claims/${claimId}/document_to_buy/reset/`),
  fetch: params => HttpClient.get('/claims/', { params }),
  fetchForForm: (resource, filters = {}) => {
    switch (resource) {
      case 'proposals':
        return HttpClient.get('/proposals/', { params: { ...filters, status: 1 } });
      case 'factors':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'faktor'
          }
        });
      case 'debtors':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'dłużnik'
          }
        });
      case 'clients':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'client'
          }
        });
      case 'trade_supervisors':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'handlowy'
          }
        });
      case 'operational_administrators':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'administrator operacyjny'
          }
        });
      case 'billing_managers':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'rozliczenia'
          }
        });
      case 'vindicators':
        return HttpClient.get('/offers/search_people_and_companies/', {
          params: {
            ...filters,
            role_name: 'windykacja'
          }
        });
      default:
        return Promise.resolve([]);
    }
  },
  create: data => HttpClient.post('/claims/creator/', data),
  saveForm: (form, data, id, compensation) => {
    let forcePut = false;
    data = cloneDeep(data);
    let u = '/claims/';

    const mutate = (x, excludedKeys = []) => {
      x = { ...x };
      Object.keys(x).forEach(key => {
        if (excludedKeys.indexOf(key) === -1 && (isObject(x[key]) || x[key] === null || x[key] === '')) {
          delete x[key];
        }
      });

      return x;
    };

    if (form.indexOf('.') !== -1) {
      const [a, b] = form.split('.');
      data.formId = b;
      form = a;
    }

    Object.keys(data).forEach(key => {
      if (data[key] === null) {
        delete data[key];
      }
    });

    switch (form) {
      case 'ClaimParamsForm':
        delete data.percent_advance_payment;
        delete data.advance_payment_value_custom;
        delete data.advance_payment_vat_custom;

        delete data.advance_payment_gross_value;
        delete data.advance_payment_net_value;
        delete data.advance_payment_vat_value;

        data = mutate({
          ...data,
          purchase_custom_value: data.purchase_custom_value || 0,
          purchase_custom_vat_value: data.purchase_custom_vat_value || 0
        });
        break;
      case 'ClaimAdvanceForm':
        if (data.advance_payment_value_custom === '') {
          data.advance_payment_value_custom = 0;
        }
        if (data.advance_payment_vat_custom === '') {
          data.advance_payment_vat_custom = 0;
        }
        if (data.percent_advance_payment === '') {
          data.percent_advance_payment = null;
        }
        data = pick(data, ['advance_payment_value_custom', 'advance_payment_vat_custom', 'percent_advance_payment']);
        return HttpClient.patch(`${u}${id}/`, data);
      case 'ClaimBidsForm':
        return HttpClient.patch(`${u}${id}/`, {
          content_type: data.content_type,
          object_id: data.object_id
        });
      case 'DocumentsManageForm':
        u = `${u + id}/document_to_buy/`;
        data = mutate({
          ...data,
          dtb_type_id: data.dtb_type?.id || data.dtb_type_id,
          expected_dtb_form_id: data.expected_dtb_form?.id,
          formal_expectation_id: data.formal_expectation?.id || data.formal_expectation_id,
          is_factor_pattern: Boolean(data.is_factor_pattern),
          indefinite_period_of_validity: Boolean(data.indefinite_period_of_validity)
        });
        break;
      case 'NotesForm':
        u = `${u + id}/notes/`;
        break;
      case 'ClaimValidateForm':
      case 'ClaimChangeStatus':
        u = `${u + id}/corr_proc/`;
        forcePut = true;
        break;
      case 'GenerateAdvanceForm':
        u = `${u + id}/generate_advance_payment/`;
        data = {};
        break;
      case 'CommitmentForm':
      case 'CommitmentTransferForm':
        u = '/payments/creator/';
        data = {
          ...data,
          agreement_id: data.claim?.agreement?.id,
          claim_id: data.claim?.id,
          commitment_id: data.commitment_id,
          recipient_id: data.claim?.client?.id,
          recipient_bank_account_id: data.bank_account?.id,
          mpp: data.claim?.mpp,
          transfer_value: data.net_transfer_value
        };
        data = mutate(data);
        break;
      case 'CommitmentEditTransferForm':
        u = data.identifier[0] === 'K' ? '/payments/compensation/':'/payments/';

        data = {
          id: data.id,
          transfer_value: data.net_transfer_value
        };
        break;
      case 'ExternalRegisterUpdateForm':
        u = '/payments/';
        data = mutate({
          ...data,
          sender_bank_account_id: data.sender_bank_account?.id
        });
        break;
      case 'GenerateTransfer':
        return HttpClient.patch(`/payments/${compensation? 'compensation/' : ''}${data.formId}/`);
      case 'ExternalRegisterForm':
        if (data.shouldUpdate) {
          delete data.shouldUpdate;
          const d = mutate({
            ...data,
            sender_bank_account_id: data.sender_bank_account?.id,
            recipient_bank_account_id: data.recipient_bank_account?.id
          });

          return HttpClient.put(`/payments/${data?.id}/`, d);
        }

        return HttpClient.patch(`/payments/${data?.id}/authorize/`);
      case 'SettleIncomeEditChargeForm':
        u = data.compensate ? `/payments/compensation/subpayment/${data.subpaymentId}/` : `/payments/subpayment/${data.subpaymentId}/income/${data.chargeId}/`;
        return HttpClient.put(u, {
          [data.compensate ? 'transfer_value' : 'income_value']: data.net_transfer_value
        });
      case 'RecalculateForm':
        if (data.shouldUpdate) {
          u = `/payments/subpayment/${data.id}/`;
          data = {
            amount: data.calc_value,
            amount_currency_id: 1
          };
          return HttpClient.patch(u, data);
        }
        u = '/commons/exchange_rate/';
        data = {
          value: data.amount,
          date: moment().format('DD.MM.YYYY'),
          currency_id: data.amount_currency?.id || data.currency_id,
          is_nbp: data.is_nbp || false,
          is_mbank: data.is_mbank || false
        };

        break;
      case 'RecalculateAdvance':
        return HttpClient.put(`/claims/${id}/generate_advance_payment/recalculate/`);
      default:
        break;
    }

    delete data.client;
    delete data.operational_administrator;
    delete data.factor;

    u = data.id ? `${u + data.id}/` : u;

    return data.id || forcePut ? HttpClient.put(u, omit(data, 'id')) : HttpClient.post(u, data);
  },
  fetchDetails: id => HttpClient.get(`/claims/${id}/`),
  fetchDetailsDebtors: async id => HttpClient.get(`/agreements/${id}/debtors/`).then(r => r.data),
  fetchDetailsDebtorsRisks: async id => HttpClient.get(`/agreements/${id}/risks/`).then(r => r.data),
  fetchAgreement: id => HttpClient.get(`/agreements/${id}/`).then(r => r?.data),
  fetchBucketsForDetails: claim_id => HttpClient.get('/claims/charges/', { params: { claim_id } }).then(r => r.data?.results),
  fetchClient: id => HttpClient.get(`/companies/${id}/`).then(r => r.data),
  fetchClientEmails: id => HttpClient.get(`/companies/${id}/contacts/emails/`).then(r => r.data),
  fetchProposalDebtors: id => HttpClient.get(`/proposals/${id}/debtors/`).then(r => r.data),
  remove: (claimId, uri, objectId) => HttpClient.delete(`/claims/${claimId}/${uri}/${objectId}/`),
  removeTransfer: (id, compensate) => HttpClient.delete(`/${compensate ? 'payments/compensation' : 'payments'}/${id}/`),
  removeClaim: id => HttpClient.delete(`/claims/${id}/`),
  fetchFromOffer: (proposalId, debtor, object) => {
    const request = {
      requested_sublimit: debtor.requested_sublimit,
      mpp: debtor.mpp,
      deducted_rates: debtor.deducted_rates,
      [object]: 1
    };

    return HttpClient.put(`/agreements/${proposalId}/debtor/${debtor.id}/`, request);
  },
  updateAmlDecision: (proposalId, decisionId) => HttpClient.put(`/agreements/${proposalId}/aml_decision/${decisionId}/`)
};
