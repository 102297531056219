import 'twin.macro';

import Select from 'components/Forms/Select';
import { get } from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';

import FormActions from '../../actions/forms';

export default connect(
  state => ({ forms: state.Forms }),
  dispatch => ({
    storeInput: (f, n, v) => dispatch(FormActions.storeInput(f, n, v))
  })
)(props => {
  const dispatch = useDispatch();

  let value = get(props.forms.forms[props.formName], props.name, props.value);
  let error = null;
  let errorName = props.errorName ?? props.name;
  const errors = props.forms.errors[props.formName];

  const valObj = props.options.find(
    i => value !== '' && (String(i.name) === String(value) || Number(i.value) === Number(value) || String(i.value) === String(value))
  );

  if (typeof value !== 'undefined' && typeof valObj !== 'undefined') {
    value = valObj.id || valObj.value;
  } else {
    value = '';
  }

  if (errors && errors[errorName]) {
    error = errors[errorName];
  }

  return value !== '' && typeof value !== 'undefined' ? (
    <div className={`flex flex-col min-w-70 pt-05 ${props.divClassName}`} {...props}>
      <div className='flex items-center'>
        <span style={{ fontWeight: '600' }}>
          {valObj.name || ''}
        </span>
        {!props.buttonDisabled ? (
          <button
            id={`btn-${props.name}`}
            type="button"
            className="ml-3"
            onClick={() => {
              props.buttonOnClick && props.buttonOnClick();
              props.selectOnChange && props.selectOnChange();

              dispatch(FormActions.clearField(props.formName, props.name));
              dispatch(FormActions.clearErrors(props.formName));
            }}>
            Zmień
          </button>
        ) : null}
      </div>
      <div className="form-control-feedback small min-h-15 text-center" style={{ color: 'red' }}>
        {error}
      </div>
    </div>
  ) : props.customElement ? (
    props.customElement
  ) : (
    <div className='pt-05' style={{ width: '100%' }}>
      <Select
        {...props}
        placeholder={props.placeholder}
        divClassName={props.divClassName}
        number={props.number || false}
        disabled={props.selectDisabled || false}
        formName={props.formName}
        options={props.options}
        name={props.name}
        onChange={props.selectOnChange}
      />
    </div>
  );
});
