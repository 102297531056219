import { DefaultMenu } from 'utilities/constants';
import { updateObject } from 'utilities/helpers';

const RESET = 'layout/RESET';
const UPDATE = 'layout/UPDATE';

// to maintain backward compatibility - actions from deprecated Reducer
const CONTEXT_MENU_SET = 'CONTEXT_MENU_SET';

const initialState = {
  contextMenu: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET:
      return updateObject(state, initialState);
    case UPDATE:
      return updateObject(state, { value: payload });
    default:
      return state;
  }
};

export const resetLayout = () => {
  return { type: RESET };
};

export const updateLayout = data => {
  return { type: UPDATE, payload: data };
};

export const setContextMenu = menu => {
  return { type: CONTEXT_MENU_SET, payload: menu ?? DefaultMenu };
};
