export const getKeyByType = (type) => {
  switch (type) {
    case 'intermediaries':
      return 'pośrednik';
    case 'brokers':
      return 'broker';
    case 'operational_administrator':
      return 'administrator operacyjny';
    case 'trade_supervisor':
      return 'handlowy';
    case 'billing_manager':
      return 'rozliczenia';
    case 'client':
      return 'Klient';
    case 'decision_makers':
      return 'decydent';
    default:
      return null;
  }
};

export const getCommissionsNameByType = (type) => {
  switch (type) {
    case 'intermediaries':
      return 'Pośrednik';
    case 'brokers':
      return 'Broker';
    case 'operational_administrator':
      return 'Administrator operacyjny';
    case 'trade_supervisor':
      return 'Opiekun handlowy';
    case 'billing_manager':
      return 'Opiekun ds. rozliczeń';
    case 'client':
      return 'Klient';
    case 'decision_makers':
      return 'Decydent';
    default:
      return '';
  }
};

export const getIdentifierByType = (type) => {
  switch (type) {
    case 'intermediaries':
      return 'posrednik';
    case 'brokers':
      return 'broker';
    case 'operational_administrator':
      return 'administrator-operacyjny';
    case 'trade_supervisor':
      return 'handlowy';
    case 'billing_manager':
      return 'rozliczenia';
    case 'client':
      return 'klient';
    case 'decision_makers':
      return 'decydent';
    default:
      return null;
  }
};
