import Card from 'components/Layout/Card';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import RemovalModal from '../../Forms/RemovalModal';
import { RolesForm } from '../../Forms/Roles';

export const Roles = (props) => {
  const { RoleCreatorForm: form } = useSelector(state => state.Forms.forms);

  const [objectToRemove, setObjectToRemove] = useState({});
  const system_roles = props.company.business_company || [];

  const formName = 'RoleCreatorForm';

  return (
    <Card mode='creator' header='+ Role' onSaveClick={() => props.submitForm(formName)} isBlocked={!form?.role}>
      <RolesForm
        visibleOnMount
        visible={props.formVisible(formName)}
        formName={formName}
        submitForm={() => props.saveForm(formName)}
        onCancel={() => props.toggleForm(formName)}
        roles={props.companyRoles}
      />
      <div className="collapse show" id="role">
        <div className="mt-3 mb-3">
          {system_roles.map((r) => (
            <span onClick={() => setObjectToRemove(r)} key={r.name} className="badge badge-warning mr-3 pt-2 pb-2 pl-3 pr-3">
              {r.name}
            </span>
          ))}
        </div>
      </div>

      <RemovalModal
        visible={Object.keys(objectToRemove).length > 0}
        toggle={() => setObjectToRemove({})}
        remove={() => {
          props.remove(objectToRemove.id);
          setObjectToRemove({});
        }}
      >
        {objectToRemove && (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 text-center mt-3">
                <span className="badge badge-warning pt-2 pb-2 pl-3 pr-3">{objectToRemove.name}</span>
              </div>
            </div>
          </div>
        )}
      </RemovalModal>
    </Card>
  );
};
